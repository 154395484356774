
const HeaderStyles = (theme) => ({
    headerContainer: {
        height: 50,
        background: theme.palette.containers.card,
        borderBottom: `1px solid ${theme.palette.primary.border9}`,
        padding: '0 20px',
        '& .MuiButton-outlined': {
            // border: '1px solid #fff'
        },
        '& .MuiTypography-root, & .MuiButton-label': {
            color: '#64748B'
        },
        '& svg': {
            // fill: theme.palette.primary.light
        },
        '& .icon': {
            color: '#64748B',
            fontSize: 18
        },
        '& .logoText': {
            // color: `${theme.palette.primary.textColor} !important`
        },
        '& .searchBoxGrid': {
            '& .MuiInputAdornment-positionEnd': {
                marginRight: '13px'
            }
        }
    },
    fullpagecolor: {
        background: theme.palette.containers.card
    },
    filterbutton: {
        '@media only screen and (min-width:899px)': {
            paddingTop: '10px'
        }
    },
    heading: {
        fontWeight: 500,
        fontSize: '18px !important',
        color: theme.palette.primary.main,
        borderBottom: '1px solid  #DCE4EC'
    },
    headinglabel: {
        fontWeight: 500,
        fontSize: '18px !important',
        color: theme.palette.primary.main,
        marginLeft: 'unset',
        marginRight: 'unset',
        '& .MuiSwitch-root': {
            marginRight: 10
        }
    },
    themepopup: {
        '& .MuiFormGroup-root': {
            flexWrap: 'nowrap'
        },
        '& .MuiFormControl-root': {
            height: 40
        }
    },
    themePopover: {
        '&.MuiPaper-root': {
            '&.MuiDialog-paper': {
                overflow: 'hidden !important'
            }
        }
    },
    settinglist: {
        paddingTop: 10,
        paddingBottom: 5,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        '& .MuiListItemIcon-root': {
            '& svg': {
                fill: theme.palette.text.text5
            },
            '& path': {
                fill: theme.palette.text.text5
            }
        },
    },
    createTheme: {
        // height:'200px',
        overflowY: 'hidden',
        overflowX: 'hidden',
        padding: '16px',
        maxWidth: 500,
        '& .MuiOutlinedInput-root': {
            height: '40px !important',
            paddingTop: '8.5px',
            paddingBottom: '8.5px'
        },
        '& .closeBtn': {
            borderRadius: '4px',
            width: '26px',
            height: '26px',
            background: theme.palette.containers.bg7,
            padding: '7px'
        },
        // '@media only screen and (max-width: 599px)':{
        //     width: 'calc(100% + 16px)'
        // }
    },
    saveBtn: {
        backgroundColor: '#f1f2f4'
    },
    themebutton: {
        paddingTop: '80px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    logo: {
        maxWidth: '100%',
        height: 34,
        width: 34,
        objectFit: 'cover',
        cursor: 'pointer',
    },
    publicLogo: {
        maxWidth: '100%',
        height: 32,
        width: 32,
        objectFit: 'cover',
        cursor: 'pointer',
        borderRadius: '100%',
    },
    TypeMessage: {
        fontWeight: 'normal !important',
        paddingLeft: 10
    },
    time: {
        fontSize: 10,
        marginTop: 5,

    },
    dropTitle: {
        padding: '12px 18px'
    },
    noMessageContainer: {
        height: '155px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '180px',
        margin: 'auto',
        '& .noMessageImg': {
            maxWidth: 55,
            filter: 'grayscale(0.8)'
        },
        '& .noTasks': {
            filter: 'grayscale(0.6)',
            marginBottom: '-16px !important',
            opacity: 0.7
        }
        // '&:before': {
        //     position: 'absolute',
        //     content: "aliceblue",
        //     backgroundPosition: '100%',
        //     backgroundImage: `url(${noMessageBg})`,
        //     backgroundRepeat: 'no - repeat',
        //     backgroundSize: '134%',
        //     filter: 'greyscale(0.6)',
        //     top: 0,
        //     left: 0,
        //     width: '100%',
        //     height: '100%',
        //     opacity: 0.3
        // }
    },

    //Settings
    settingsTitleAvatar: {
        width: 50,
        height: 50,
        // marginRight: 10
    },
    searchTitleAvatar: {
        width: 30,
        height: 30,
        marginRight: 10,
    },
    searchTitleAvatarIcon: {
        width: 30,
        height: 30,
        marginRight: 10,
        background: '#e0e0e0 !important',
        padding: '5px'
    },
    settingsTitle: {
        padding: '8px 18px 9px 18px'
    },
    settingsListAvatar: {
        width: 20,
        height: 20,
        fontSize: 14

    },
    settingsList: {
        background: theme.palette.containers.card,
        '& .MuiListItemIcon-root': {
            minWidth: 28,
            '& svg': {
                fill: theme.palette.text.text5
            },
            '& .fillPath': {
                fill: theme.palette.text.text5
            }
        },
        '& .MuiMenuItem-root': {
            paddingTop: 10,
            paddingBottom: 8
        },
        '@media only screen and (max-width:599px)': {
            overflowY: 'auto',
            padding: '0px'
        },

    },
    MenuTitle: {
        '@media only screen and (max-width:599px)': {
            position: 'sticky',
            top: '0px',
            background: theme.palette.containers.card,
            zIndex: 99
        },
    },
    refreshIcon: {
        position: 'absolute',
        bottom: '2px',
        right: '9px',
        fontSize: '14px',
        background: '#96ead3',
        padding: '1px',
        borderRadius: '30px',
        fill: '#252525',
        boxShadow: '0 0 2px #5f5f5f'
    },
    globalSearch: {
        '& .MuiInputBase-root': {
            borderRadius: 10
        },
        '& .MuiAutocomplete-clearIndicator svg, .MuiAutocomplete-popupIndicator svg': {
            fill: theme.palette.primary.light
        },
        '& .MuiAutocomplete-popupIndicatorOpen': {
            transform: 'none !important'
        },
        '& .bgNone .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.border1
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.border1
        },
        '& .MuiInputBase-input': {
            color: theme.palette.primary.main,
            '&::placeholder': {
                color: theme.palette.primary.light
            }
        },
        '& ::placeholder': {
            color: theme.palette.primary.light
        }
    },
    settingsPopover: {
        '& .MuiPopover-paper': {
            boxShadow: `${theme.palette.shadows.shadow7} !important`
        },
    },
    notificationPopUp: {
        '& .MuiList-root': {
            maxWidth: 420,
            maxHeight: 500,
            overflowY: 'auto',
            padding: 5
        },
        '& .MuiPopover-paper': {
            borderRadius: 10,
            boxShadow: `${theme.palette.shadows.shadow7} !important`
        },
        '& .MuiMenuItem-root': {
            paddingTop: 8,
            paddingBottom: 8
        },
        '@media only screen and (min-width: 768px)': {
            '& .MuiList-root': {
                minWidth: 320
            },
            settingsListAvatar: {
                width: "20px !important",
                height: "20px !important",
                fontSize: 14,
                marginRight: '10px'
            },
        }
    },
    navbarLinks: {
        justifyContent: 'center',
        '& .MuiButton-root': {
            padding: '10px 20px',
            borderRadius: 5,
            fontSize: 15,
            marginRight: '4px'
        },
        "& .MuiButton-label": {
            fontSize: '13px !important',
            fontWeight: 500
        },
        "& .active_menu": {
            fontWeight: 600,
            border: '1px solid #2563EB',
            background: theme.palette.type === "dark" ? '#2563EB' : theme.palette.containers.bg3,
            borderRadius: '12px',
            "& .MuiButton-label": {
                color: theme.palette.type === "dark" ? '#fff !important' : '#2563EB !important'
            },
            '& svg .fillPath': {
                fill: theme.palette.type === "dark" ? "#fff" : '#2563EB'
            },
            '& svg .strokeFill, & .fillStroke': {
                stroke: theme.palette.type === "dark" ? "#fff" : '#2563EB'
            },
        },
        '& .CalendarIconNew': {
            width: '18px',
            height: '18px'
        }
    },
    navBarIcons: {
        '& svg': {
            width: 18,
            height: 18
        },
        '& .MuiIconButton-root': {
            padding: 6
        },
        '& .MuiOutlinedInput-root': {
            height: 34
        },
        '& .borderWhite': {
            border: `1px solid ${theme.palette.primary.border4}`
        }
    },
    publicNavBarIcons: {
        '& svg': {
            width: 16,
            height: 16
        },
        '& .MuiIconButton-root': {
            padding: 6
        },
        '& .MuiOutlinedInput-root': {
            height: 34
        },
        '& .borderWhite': {
            border: `1px solid ${theme.palette.primary.border4}`
        }
    },
    link: {
        backgroundImage: 'linear-gradient(180deg, #5154F8 0%, #1AA5FB)'
    },
    notifiBadge: {
        '& .MuiBadge-root': {
            marginRight: '5px',
            marginTop: '5px'
        },
        '& .MuiBadge-anchorOriginTopRightRectangle': {
            top: '5px',
            right: '3px',
            background: '#F178B6'
        },
        '& .TaskIcon .strokeFill': {
            stroke: '#7e84a3'
        }
    },
    notifiMessage: {
        minWidth: 320,
        maxWidth: 320,
        paddingRight: 10,
        marginTop: '-5px'
    },
    notifiMessage2: {
        minWidth: 320,
        maxWidth: 320,
    },
    notifiBadgeSettings: {
        paddingLeft: "20px"
    },
    notifcationContainer: {
        position: 'absolute',
        right: '15px',
        zIndex: 999
    },
    groupIcon: {
        '& .TeamsIcon': {
            width: 22,
            color: "#fff"
        },
        textTransform: 'uppercase'
    },
    SupportIcon: {
        width: 20,
        height: 20
    },
    discordIcon: {
        width: 20,
        height: 20
    },
    mentorShipBar: {
        position: 'relative',
        background: `${theme.palette.primary.gradientBtn}`,
        color: '#fff',
        padding: '6px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: "space-between",
        cursor: 'pointer',
        zIndex: 9999,

        '& .MuiLink-underlineHover': {
            color: '#fff !important',
            cursor: 'pointer'
        },
        '& .MuiSvgIcon-root': {
            fontSize: 15,
            fill: '#fff'
        },
        '@media only screen and (max-width: 576px)': {
            '&.MuiGrid-root': {
                flexWrap: 'nowrap'
            },
            '& .MuiIconButton-root': {
                paddingLeft: 15
            }
        }
    },
    proScaleInfoBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    menuList: {
        maxHeight: 276,
        overflow: 'hidden',
    },
    menuListOpen: {
        height: 'auto',
        transition: 'all 0.5s'
    },
    showMoreLess: {
        cursor: 'pointer',
        textAlign: 'right',
        padding: '0px 14px 10px'
    },
    optionsTab: {
        minHeight: '44px'
    },
    titleWrapper: {
        padding: '16px 16px 8px',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        fontSize: '17px',
        '& .strokeFill': {
            stroke: '#fff'
        },
        '& .iconWrapper': {
            background: '#fb5eaf',
            width: '30px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '30px'
        },
        '& .nowtext': {
            marginLeft: '10px',
            background: '#FFEAE5',
            fontSize: '12px',
            padding: '1px 6px',
            borderRadius: '4px'
        }
    },
    closeBtn: {
        width: '30px',
        height: '30px'
    },
    globalSearchInput: {

        width: "250px"
    },
    Popovertabs: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    PopoverBtn: {
        fontSize: "13px",
        fontWeight: 500,
        color: "#64748B"
    },
    PopoverTabs: {
        '& .MuiButton-label': {
            fontSize: "13px",
            fontWeight: 500,
            color: "#64748B",
        },
        '& .PopoverActive': {
            background: "#E9F3FE",
            borderRadius: "8px",
        },
        '& .CalendarIconNew': {
            width: '18px',
            height: '18px'
        }
    },
    '@media only screen and (max-width:1432px)': {
        globalSearchInput: {
            width: "100px"
        }
    },
    navlinkContainer: {
        flexGrow: 1,
        display: 'flex',
        maxHeight: '100%',
        overflow: 'hidden',
        maxWidth: 'calc(100% - 145px)',
        position: 'relative'
    },
    referenceContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        overflow: "hidden",
        height: '0px'
    },
    onboardingBar: {
        background: 'linear-gradient(90deg, rgba(132, 97, 254, 0.26) 0%, rgba(38, 158, 255, 0.4) 100%)',
        padding: '10px',
        '& .MuiButton-root': {
            background: 'linear-gradient(90deg, #7764FD 0%, #2563EB 100%)',
            borderRadius: '30px !important',
            padding: '6px 20px'
        },
        position: "relative"
    },
    onboardingBarClose: {
        position: "absolute",
        right: "10px"
    },
    warningSnackBarStyles: {
        "& .MuiSnackbarContent-root": {
            backgroundColor: theme.palette.containers.card,
            borderRadius: '12px',
            paddingRight: '25px'
        }
    },
    warningIcon: {
        position: "absolute",
        bottom: '5px',
        left: '5px',
        zIndex: 999
    }
})

export default HeaderStyles