import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";

import { Button, Typography } from "../../components"
import { IconButton } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

const style = {
    height: "calc(100% - 25px)",
    background: "#fff",
    maxWidth: "1000px",
    width: "100%",
    border: "1.5px dashed #A2A2A2",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    padding: "20px"
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
    position: 'absolute',
    bottom: 25,
    left: 10
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
    position: 'relative'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    // overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};
const deleteIcon = {
    padding: '5px',
    width: '22px',
    height: '22px',
    position: 'absolute',
    right: '5px',
    top: '5px',
    background: '#ffffff9e'
};
const closeIcon = {
    fontSize: '18px'
}

const mediaGrpDropZone = {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 1
}


const DropZoneComponent = (props) => {
    // Get Props
    const { importFileData, accept, handleClose } = props;

    // Set State
    const [files, setFiles] = useState([])

    //delete preview
    const deleteFile = (index) => {
        let tmpFiles = [...files];
        tmpFiles.splice(index, 1)
        setFiles(tmpFiles)
    }

    const thumbs = files.map((file, index) => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    alt={"preview"}
                    src={file.preview}
                    style={img}
                />
                <IconButton style={deleteIcon} onClick={(e) => { deleteFile(index) }} ><CloseIcon style={closeIcon} /></IconButton>
            </div>
        </div>
    ));
    


    // get drop zone props
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => {
            setFiles([...files, ...acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) }))]);
        },
        accept: accept
    });

    return (
        <React.Fragment>
            <Grid style={mediaGrpDropZone}>
                <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <Grid>
                        <Grid container className="cursor-pointer">
                            <Typography
                                variant="h6"
                                className="colorGrey txtcenter txtMedium pr-1"
                            >
                                {"click here or drag files to Import"}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <aside style={thumbsContainer}>
                    {thumbs}
                </aside>
                <Grid className="pt-2">
                    <Button
                        disabled={false}
                        title={"Upload"}
                        color={"secondary"}
                        size={"small"}
                        className="mr-1"
                        onClick={(e) => { importFileData(files); }}
                    ></Button>
                    <Button
                        title={"Cancel"}
                        color={"secondary"}
                        size={"small"}
                        className="mr-1"
                        onClick={(e) => { handleClose() }}
                    ></Button>
                    <Button
                        title={"Clear All"}
                        color={"secondary"}
                        size={"small"}
                        className="mr-1"
                        onClick={(e) => { setFiles([]) }}
                    ></Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

DropZoneComponent.defaultProps = {
    importFileData: () => { },
    accept: "image/*, video/*"
};
DropZoneComponent.propTypes = {
    importFileData: PropTypes.func,
    accept: PropTypes.string
};

/** Export Component */
export const DropZoneR = DropZoneComponent;

