import { baseAction } from "./baseAction";
import {
  pageBuilderConstant,
  loaderConstant,
  alertConstant,
} from "../constants";
import { pageBuilderService } from "../services/pageBuilderService";
import history from "../../config/history";
import { alertAction } from "./alertAction";

const { request, success, failure } = baseAction;

export const pageBuilderAction = {
  createSite,
  listSites,
  getSite,
  updateSite,
  createPage,
  getPage,
  changePage,
  updatePage,
  updatePageContent,
  createTemplate,
  listTemplates,
  listCategory,
  publishSite,
  setLoading,
  deletePage,
  deleteSite,
  unPublishSite,
  listPageTemplates,
  getAnalyticsForSite,
  getPageAnalyticsChart,
  setPageAnalayticFilter,
  listActiveSite,
  updateSiteCustomCode,
  getSiteCustomCode,
  getFormList,
  getFormResponse,
  saveEmailConfig,
  getEmailConfig,
  testEmailConfig,
  getSiteSeoSettings,
  updateSiteSeoSettings,
  listCategory,
  compressThumbnail
};

function createSite(params) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.CREATE_SITE_R, params));
    pageBuilderService.createSite(params).then(
      (data) => {
        const {id, page} = data?.data;
        dispatch(success(pageBuilderConstant.CREATE_SITE_S, id));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(
          success(alertConstant.ALERT_SUCCESS, "Site Created Successfully")
        );
        history.push(`/page-builder/${id}/${page}`);
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.CREATE_SITE_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function listSites(searchKey) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.GET_SITES_LIST_R));
    pageBuilderService.listSites(searchKey).then(
      (data) => {
        dispatch(
          success(pageBuilderConstant.GET_SITES_LIST_S, data?.data || [])
        );
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.GET_SITES_LIST_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function getSite(id, navToPage = null) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.GET_SITE_DETAIL_R));
    pageBuilderService.getSite(id).then(
      (data) => {
        dispatch(
          success(pageBuilderConstant.GET_SITE_DETAIL_S, data?.data || {})
        );
        dispatch(request(loaderConstant.LOADING_FALSE));
        if (navToPage) {
          history.push(`/page-builder/${id}/${navToPage}`);
        }
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.GET_SITE_DETAIL_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function updateSite(site_id, params, publish = false, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.UPDATE_SITE_R, params));
    pageBuilderService.updateSite(site_id, params).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        const id = data?.data;
        if (publish) {
          dispatch(publishSite(site_id, 0, {}, false));
        } else {
          dispatch(success(pageBuilderConstant.UPDATE_SITE_S, id));
          dispatch(
            success(alertConstant.ALERT_SUCCESS, "Site Updated Successfully")
          );
          callBack()
        }
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.UPDATE_SITE_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function createPage(params) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.CREATE_PAGE_R));
    pageBuilderService.createPage(params).then(
      (data) => {
        dispatch(success(pageBuilderConstant.CREATE_PAGE_S, data?.data || {}));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(getSite(params.website_id, data?.data))
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.CREATE_PAGE_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function getPage(id) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.GET_PAGE_R));
    pageBuilderService.getPage(id).then(
      (data) => {
        dispatch(success(pageBuilderConstant.GET_PAGE_S, data?.data || {}));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.GET_PAGE_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function changePage(id) {
  return (dispatch) => {
    dispatch(success(pageBuilderConstant.CHANGE_PAGE, id));
  };
}

function updatePage(id, params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.UPDATE_PAGE_R));
    pageBuilderService.updatePage(id, params).then(
      (data) => {
        dispatch(success(pageBuilderConstant.UPDATE_PAGE_S, data?.data || {}));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack()
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.UPDATE_PAGE_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function updatePageContent(id, params) {
  return (dispatch) => {
    pageBuilderService.updatePage(id, params).then(
      () => {
        // console.log("updated");
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.UPDATE_PAGE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function createTemplate(params) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.CREATE_TEMPLATE_R));
    pageBuilderService.createTemplate(params).then(
      (data) => {
        dispatch(
          success(pageBuilderConstant.CREATE_TEMPLATE_S, data?.data || {})
        );
        dispatch(listCategory());
        dispatch(listTemplates());
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.CREATE_TEMPLATE_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function listPageTemplates(query, params) {
  return (dispatch, getState) => {
    // dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(loaderConstant.TEMPLATE_LOADING_TRUE));
    dispatch(request(pageBuilderConstant.LIST_PAGE_TEMPLATE_R));
    pageBuilderService.listTemplates(query).then(
      (data) => {
        const existingList = getState().pageBuilder.templates.list || [];
        const newList = params.skip === 0 ? data?.data || [] : [...existingList, ...data?.data || []];
        let templateDataLength = newList.length

        const payload = {
          list: newList,
          reset: params?.skip === 0,
          templateDataLength: templateDataLength
        };

        dispatch(success(pageBuilderConstant.LIST_PAGE_TEMPLATE_S, { ...payload }));
        // dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(request(loaderConstant.TEMPLATE_LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.LIST_PAGE_TEMPLATE_F, error));
        // dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(request(loaderConstant.TEMPLATE_LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function listTemplates(query = '') {
  return (dispatch, getState) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.LIST_TEMPLATE_R));
    pageBuilderService.listTemplates(query).then(
      (data) => {
        const oldData = getState().pageBuilder.templates || []; 
        const newData = data?.data || [];
        const mergedData = [...oldData, ...newData]; 

        dispatch(success(pageBuilderConstant.LIST_TEMPLATE_S, mergedData));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.LIST_TEMPLATE_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function listCategory(query = '') {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.LIST_CATEGORY_R));
    pageBuilderService.listCategory(query).then(
      (data) => {
        dispatch(
          success(pageBuilderConstant.LIST_CATEGORY_S, data?.data || {})
        );
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.LIST_CATEGORY_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function compressThumbnail(query = ''){
  return (dispatch) => {
    dispatch(request(loaderConstant.TEMPLATE_COMPRESSING_TRUE));
    dispatch(request(pageBuilderConstant.COMPRESS_THUMBNAIL_R));
    pageBuilderService.compressThumbnail(query).then(
      (data) => {
        dispatch(
          success(pageBuilderConstant.COMPRESS_THUMBNAIL_S, data?.data || {})
        );
        dispatch(request(loaderConstant.TEMPLATE_COMPRESSING_FALSE));
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.COMPRESS_THUMBNAIL_F, error));
        dispatch(request(loaderConstant.TEMPLATE_COMPRESSING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function publishSite(site_id, page_id, params, goback = false, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.PUBLISH_SITE_R));
    pageBuilderService.publishSite(site_id, page_id, params).then(
      (data) => {
        dispatch(success(pageBuilderConstant.PUBLISH_SITE_S, data?.data || {}));
        dispatch(listTemplates());
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(alertAction.successAlert('Published Successfully'))
        if (goback) {
          setTimeout(() => {
            history.goBack()
          }, 100)
        }
        callBack()
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.PUBLISH_SITE_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function setLoading(status) {
  return (dispatch) => {
    if (status) {
      dispatch(request(loaderConstant.LOADING_TRUE));
    } else {
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
  }
}

function deletePage(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.DELETE_PAGE_R));
    pageBuilderService.deletePage(params).then(()=>{
      dispatch(request(pageBuilderConstant.DELETE_PAGE_S));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack();
    }, (error) => {
      dispatch(failure(pageBuilderConstant.DELETE_PAGE_F, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    })
  }
}

function deleteSite(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.DELETE_SITE_R));
    pageBuilderService.deleteSite(params).then((data) => {
      dispatch(request(pageBuilderConstant.DELETE_SITE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack();
    }, (error) => {
      dispatch(failure(pageBuilderConstant.DELETE_SITE_F, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    })
  }
}


function unPublishSite(site_id, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    pageBuilderService.unPublishSite(site_id).then(() => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(alertAction.successAlert('Un-Published Successfully'))
      dispatch(getSite(site_id))
      callBack()
    }, (error) => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    })
  }
}

function getAnalyticsForSite(site_id) {
  return (dispatch) => {
    dispatch(success(pageBuilderConstant.GET_PAGE_ANALYTICS_S, { site_id }));
  };
}

function setPageAnalayticFilter(filter) {
  return (dispatch) => {
    dispatch(
      success(pageBuilderConstant.SET_PAGE_ANALYTICS_FILTER_S, { filter })
    );
  };
}

function getPageAnalyticsChart(site_id, params) {
  return (dispatch) => {
    dispatch(success(pageBuilderConstant.GET_PAGE_ANALYTICS_CHART_R));
    pageBuilderService.getPageAnalyticsChart(site_id, params).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(
          success(pageBuilderConstant.GET_PAGE_ANALYTICS_CHART_S, data?.data)
        );
      },
      (error) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(success(pageBuilderConstant.GET_PAGE_ANALYTICS_CHART_F));
      }
    );
  };
}

function listActiveSite(id) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.GET_ACTIVE_SITE_LIST_R));
    pageBuilderService.listActiveSite(id).then(
      (data) => {
        dispatch(
          success(pageBuilderConstant.GET_ACTIVE_SITE_LIST_S, data?.data || [])
        );
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.GET_ACTIVE_SITE_LIST_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function getSiteCustomCode(id) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.GET_CUSTOM_CODE_SITE_R));
    pageBuilderService.getSiteCustomCode(id).then(
      (data) => {
        dispatch(
          success(pageBuilderConstant.GET_CUSTOM_CODE_SITE_S, data?.data || [])
        );
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.GET_CUSTOM_CODE_SITE_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function updateSiteCustomCode(id, params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.UPDATE_CUSTOM_CODE_SITE_R));
    pageBuilderService.updateSiteCustomCode(id, params).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        
        if (data?.status === "error") {
          callBack(data?.status, data?.data)
          return
        };

        dispatch(success(pageBuilderConstant.UPDATE_CUSTOM_CODE_SITE_S, {}));
        dispatch(success(alertConstant.ALERT_SUCCESS, "Saved successfully"));
        callBack()
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.UPDATE_CUSTOM_CODE_SITE_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function saveEmailConfig(id, params, callBack = () => { }, fromSaveBtn) {
  return (dispatch) => {
    dispatch(request(pageBuilderConstant.SAVE_EMAIL_CONFIG_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    pageBuilderService.saveEmailConfig(id, { emailConfig: params }).then(data => {
      dispatch(success(pageBuilderConstant.SAVE_EMAIL_CONFIG_S, params));
      dispatch(success(pageBuilderConstant.UPDATE_EMAIL_CONFIG, params));
      dispatch(request(loaderConstant.LOADING_FALSE));

      if (fromSaveBtn) {
        dispatch(success(alertConstant.ALERT_SUCCESS, "Saved successfully"));
      }
      callBack()
    }, error => {
      dispatch(failure(pageBuilderConstant.SAVE_EMAIL_CONFIG_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function testEmailConfig(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(pageBuilderConstant.TEST_EMAIL_CONFIG_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    pageBuilderService.testEmailConfig({ emailConfig: params }).then(data => {
      dispatch(success(pageBuilderConstant.TEST_EMAIL_CONFIG_S, params));
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(alertConstant.ALERT_SUCCESS, "Mail send successfully"));
      callBack()
    }, error => {
      dispatch(failure(pageBuilderConstant.TEST_EMAIL_CONFIG_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error?.response || "Unable to send mail"));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getEmailConfig(id, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(pageBuilderConstant.GET_EMAIL_CONFIG_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    pageBuilderService.getEmailConfig(id, params).then(data => {
      if (params?.authType) {
        if (data?.data?.authentication_type === params?.authType) {
          dispatch(success(pageBuilderConstant.GET_EMAIL_CONFIG_S, data));
        }
      } else {
        dispatch(success(pageBuilderConstant.GET_EMAIL_CONFIG_S, data));
      }

      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(pageBuilderConstant.GET_EMAIL_CONFIG_F, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getSiteSeoSettings(id) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.GET_SITE_SEO_SETTINGS_R));
    pageBuilderService.getSiteSeoSettings(id).then(
      (data) => {
        dispatch(
          success(pageBuilderConstant.GET_SITE_SEO_SETTINGS_S, data?.data || {})
        );
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.GET_SITE_SEO_SETTINGS_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function updateSiteSeoSettings(id, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(pageBuilderConstant.UPDATE_CUSTOM_CODE_SITE_R));
    pageBuilderService.updateSiteSeoSettings(id, { seoSettings: params }).then(
      (data) => {
        dispatch(success(pageBuilderConstant.UPDATE_CUSTOM_CODE_SITE_S, {}));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(alertConstant.ALERT_SUCCESS, "Saved successfully"));
        callBack()
      },
      (error) => {
        dispatch(failure(pageBuilderConstant.UPDATE_CUSTOM_CODE_SITE_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function getFormList(site_id, body) {
  return (dispatch) => {
    dispatch(success(pageBuilderConstant.GET_FORM_LIST_R));
    pageBuilderService.getFormList(site_id, body).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(
          success(pageBuilderConstant.GET_FORM_LIST_S, data?.data)
        );
      },
      (error) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(success(pageBuilderConstant.GET_FORM_LIST_F));
      }
    );
  };
}

function getFormResponse(form_id, body) {
  return (dispatch) => {
    dispatch(success(pageBuilderConstant.GET_FORM_RESPONSE_R));
    pageBuilderService.getFormResponse(form_id, body).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(
          success(pageBuilderConstant.GET_FORM_RESPONSE_S, data?.data)
        );
      },
      (error) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(success(pageBuilderConstant.GET_FORM_RESPONSE_F));
      }
    );
  };
}