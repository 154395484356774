import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

//google login package

// import credential

//Import Component
import { Button } from '../Button';

// Imports Styles
import { GoogleIcon } from '../../assets/svg';
import styles from "./styles";


/**
 * Google Login Button Component
 * @param {*} props
 */
function GoogleLoginButtonComponent(props) {
    const { handleGoogleLogin, title } = props;

    return (
        <Button onClick={handleGoogleLogin} variant="outlined" color="secondary" size="small" className="mb-2 loginBaseBtns" startIcon={<GoogleIcon />}><span className={"loginBtnSpan"}>{title}</span></Button>
    );
}

// Default props
GoogleLoginButtonComponent.defaultProps = {
    type: "button",
    title: "LOGIN WITH GOOGLE",
    classes: "",
    className: "",
    href: "",
    fullWidth: false,
    size: "small",
    variant: "outlined",
    component: "button",
    disabled: false,
    disableElevation: false,
    onClick: () => { },
    handleGoogleLogin: () => { },
};

// Prop types
GoogleLoginButtonComponent.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    href: PropTypes.any,
    fullWidth: PropTypes.bool,
    variant: PropTypes.string,
    component: PropTypes.elementType,
    disabled: PropTypes.bool,
    disableElevation: PropTypes.bool,
    onClick: PropTypes.func,
    handleGoogleLogin: PropTypes.any,
};

/** Export Component */
export const GoogleLoginButton = withStyles(styles)(GoogleLoginButtonComponent);
