// Import API Helper
import { postRequest, getRequest, putRequest } from "../../utils/apiHelper";

export const chatHubService = {
    getProjects,
    getClients,
    getTeamUsers,
    getTeamUserSearchResult,

    getAllUsers,
    createNewGroup,
    chatProfileUserMessage,
    getNewMessages,

    updateChatDeliveredStatus,
    updateChatReadStatus,
    deleteTeamMember,
    updateGroupData,
    updateChatMessagesOnLoad,
    resetChatMessages,
    getAssociatedUsers,
    updateMuteNotification,
    updateReadStatusTrack,

    getMessageInfo,
    updateChatRoomReadStatus,
    getUnReadChat,
    onLoadMessageReadStatus
};

//get projects
async function getProjects() {
    const response = await getRequest(`chat_hub/projects`);
    return response;
}

//get clients
async function getClients() {
    const response = await getRequest(`chat_hub/clients`);
    return response;
}

//get team list
async function getTeamUsers() {
    const response = await getRequest(`chat_hub/team`);
    return response;
}

//get team list
async function getTeamUserSearchResult(params) {
    const response = await postRequest(`chat_hub/team/search`, params);
    return response;
}

//get team list
async function getAllUsers(params) {
    const response = await postRequest(`chat_hub/team/allUsers`, params);
    return response;
}

//create New Group
async function createNewGroup(params) {
    const response = await postRequest(`chat_hub/team/createNewGroup`, params);
    return response;
}

//chat profile user chat check
async function chatProfileUserMessage(params) {
    const response = await postRequest(`chat_hub/team/getUserChatRoom`, params);
    return response;
}

async function getNewMessages(params) {
    const response = await getRequest(`chat_hub/get_new_messages`);
    return response;
}

//update chat read status
async function updateChatDeliveredStatus(params) {
    const response = await putRequest(`chat_hub/update_status/delivered`, params);
    return response;
}

//update chat read status
async function updateChatReadStatus(params) {
    const response = await putRequest(`chat_hub/update_status/read`, params);
    return response;
}

//update chat read status
async function deleteTeamMember(params) {
    const response = await postRequest(`chat_hub/team/removeMember`, params);
    return response;
}

//Update Groupchat Data
async function updateGroupData(params) {
    const response = await postRequest(`chat_hub/team/updateGroup`, params);
    return response;
}

//update chat message status on load
async function updateChatMessagesOnLoad(params) {
    const response = await putRequest(`chat_hub/update_status/onload`, params);
    return response;
}

//reset chat messages
async function resetChatMessages(params) {
    const response = await postRequest(`chat_hub/team/reset`, params);
    return response;
}

// getClientAssiciatedUsers
async function getAssociatedUsers(params) {
    const response = await getRequest(`chat_hub/team/associated_user`);
    return response;
}

//update Mute Notification
async function updateMuteNotification(params) {
    const response = await postRequest(`chat_hub/update_mute_notification`, params);
    return response;
}

//update readStatus track
async function updateReadStatusTrack(params) {
    const response = await postRequest(`chat_hub/update-read-status`, params);
    return response;
}

//messageinfo
async function getMessageInfo(params){
    const response = await getRequest(`chat_hub/messageinfo?id=${params.message_id}&type=${params.type}`);
    return response;
}
//get unread chatroom
async function getUnReadChat(params) {
    const response = await postRequest(`chat_hub/get_unread_chat`);
    return response;
}

//update chatRoom read status
async function updateChatRoomReadStatus(params){
    const response = await postRequest(`chat_hub/update_chatroom_read_status`,params)
    return response;
}

//update chat read status bulk on load
async function onLoadMessageReadStatus(params){
    const response = await postRequest(`chat_hub/onload-read-status`, params)
    return response;
}

