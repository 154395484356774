const styles = (theme) => ({
    componentContainer:{
        display:'flex',
        alignItems:'center',
        padding:'5px',
        '& .defaultUser':{
            '& .MuiAvatar-root':{
                background:'none'
            },
        },
        '& .greenBadge':{
            '& .MuiBadge-badge':{
                background:"#24D164",
            }
        },
        '& .x-small':{
            '& .MuiAvatar-root':{
                width:34,
                height:34,
                background:'#316DF1'
            },
            '& .MuiTypography-root':{
                fontSize:'14px',
                fontWeight: '500',
            },
            '& .MuiBadge-badge':{
                height:"12px",
                width:"12px",
                background:"#CBD5E1",
                border:"2px solid white",
                borderRadius:"50px",
            }
        },
        '& .small':{
            '& .MuiAvatar-root':{
                width:38,
                height:38,
                background:'#316DF1'
            },
            '& .MuiTypography-root':{
                fontSize:'14px',
                fontWeight: '500',
            },
            '& .MuiBadge-badge':{
                height:"12px",
                width:"12px",
                background:"#CBD5E1",
                border:"2px solid white",
                borderRadius:"50px",
            }
        },
        '& .medium':{
            '& .MuiAvatar-root':{
                width:40,
                height:40,
                background:'#316DF1'
            },
            '& .MuiTypography-root':{
                fontSize:'14px',
                fontWeight: '500',
            },
            '& .MuiBadge-badge':{
                height:"12px",
                width:"12px",
                background:"#CBD5E1",
                border:"2px solid white",
                borderRadius:"50px",
            }
        },
        '& .large':{
            '& .MuiAvatar-root':{
                width:45,
                height:45,
                background:'#316DF1'
            },
            '& .MuiTypography-root':{
                fontSize:'16px',
                fontWeight: '500',
            },
            '& .MuiBadge-badge':{
                height:"12px",
                width:"12px",
                background:"#CBD5E1",
                border:"2px solid white",
                borderRadius:"50px",
            }
        },
        '& .x-large':{
            '& .MuiAvatar-root':{
                width:50,
                height:50,
                background:'#316DF1'
            },
            '& .MuiTypography-root':{
                fontSize:'16px',
                fontWeight: '500',
            },
            '& .MuiBadge-badge':{
                height:"15px",
                width:"15px",
                background:"#CBD5E1",
                border:"2px solid white",
                borderRadius:"50px",
            }
        }
    }
});

export default styles;
