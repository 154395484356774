import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { CloseIcon, YoutubePlayIcon } from "../../assets/svg";

const TutorialDialog = (props) => {
    const { classes, videoSrc, videoID, closeInfoDialog, customBackgroundImage } = props;
    const [open, setOpen] = useState(true);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    useEffect(() => {
        return () => {
          setIsVideoPlaying(false)
        };
      }, []);

    const handleClose = () => {
        setOpen(false);
        closeInfoDialog()
    };

    const toggleVideoPlay = () => {
        setIsVideoPlaying(true);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"lg"}
            className={classes.infoDialogVideo}
        >
            <DialogTitle className="closeBtn">
                <Tooltip title={"Close"} arrow>
                    <IconButton
                        onClick={closeInfoDialog}
                    ><CloseIcon /></IconButton>
                </Tooltip>
            </DialogTitle>
            <DialogContent>
                <Grid className='infoVideoContainer'>
                    <Grid className='infoVideo' style={{ maxWidth: '100%', maxHeight: '100vh' }}>
                        <iframe width="100%" height="100%" className="video-frame" src={isVideoPlaying ? `${videoSrc}&autoplay=1` : videoSrc} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

                        {!isVideoPlaying && (<Grid className='customPlayBtn' onClick={toggleVideoPlay}
                            style={{ backgroundImage: customBackgroundImage ? `url(${customBackgroundImage})` : `url(https://i.ytimg.com/vi/${videoID}/hqdefault.jpg)` }}
                        >
                            <YoutubePlayIcon />
                        </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default TutorialDialog;