import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, withStyles } from '@material-ui/core'
import { Close, Check, Refresh, RotateLeft, RotateRight } from '@material-ui/icons';

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import styles from "./styles";

import useWindowSize from '../../hooks/useWindowSize';

const CropperComponent = (props) => {
    const { classes, fileUrl, handleClose, handleSaveImage, aspectRatio = 1, layout = "circle" } = props;

    const [cropper, setCropper] = useState();
    const windowSize = useWindowSize();
    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            let cropped = cropper.getCroppedCanvas().toDataURL();
            // setCropData(cropped);
            handleSaveImage(cropped);
        }
    };

    const boxWidth = windowSize[0] < 800 ? windowSize[0] - 50 : 800;
    const boxHeight = windowSize[0] < 450 ? windowSize[0] - 50 : 450;

    return (
        <>
            {/* <div className={classes.topBar}>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <Close />
                </IconButton>
            </div> */}
            <div className={layout === "circle" ? classes.cropperGrid : classes.cropperGrid2}>
                <IconButton className={classes.cropperCloseButton} edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <Close />
                </IconButton>

                {
                    layout === "rectangle" ?
                        <Cropper
                            style={{ width: boxWidth, height: boxHeight, "max-width": "100%", "max-height": "100%" }}
                            guides={true}
                            src={fileUrl}
                            viewMode={1}
                            dragMode="move"
                            cropBoxMovable={false}
                            cropBoxResizable={false}
                            zoomTo={0}
                            initialAspectRatio={1}
                            // aspectRatio={1}
                            aspectRatio={5 / 1}
                            minCropBoxHeight={100}
                            minCropBoxWidth={100}
                            background={false}
                            responsive={true}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        /> :
                        <Cropper
                            style={{ width: boxWidth, height: boxHeight, "max-width": "100%", "max-height": "100%" }}
                            guides={true}
                            src={fileUrl}
                            viewMode={1}
                            dragMode="move"
                            cropBoxMovable={false}
                            cropBoxResizable={false}
                            zoomTo={0}
                            initialAspectRatio={aspectRatio}
                            // aspectRatio={1}
                            aspectRatio={aspectRatio}
                            minCropBoxHeight={100}
                            minCropBoxWidth={100}
                            background={false}
                            responsive={true}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                        />
                }
                <div className={classes.buttonBox}>
                    <IconButton onClick={() => cropper.reset()} >
                        <Refresh />
                    </IconButton>
                    <IconButton onClick={() => cropper.rotate(-90)}>
                        <RotateLeft />
                    </IconButton>
                    <IconButton onClick={() => cropper.rotate(90)}>
                        <RotateRight />
                    </IconButton>
                    <IconButton onClick={getCropData} >
                        <Check />
                    </IconButton>
                </div>
            </div>
        </>
    );
}

// Default props
CropperComponent.defaultProps = {
    classes: "",
    fileUrl: "",
    handleClose: () => { },
    handleSaveImage: () => { },
    circle: true,
    aspectRatio: 1
};

// Prop types
CropperComponent.propTypes = {
    classes: PropTypes.object,
    fileUrl: PropTypes.any,
    handleClose: PropTypes.func,
    handleSaveImage: PropTypes.func,
    circle: PropTypes.bool,
    aspectRatio: PropTypes.number
};

/** Export Component */
//  const CropperComponent = withStyles(styles)(ImageCropper);
export default withStyles(styles)(CropperComponent);

