// Import API Helper
import { postRequest } from "../../utils/apiHelper";

export const clientMediaService = {
    createFolder,
    getAll,
    updateFolderName,
    deleteFolder,
    deleteFile,
    updateFile,
    updateFolderFile,
    uploadFiles
};

//create new profile
async function createFolder(params) {
    const response = await postRequest("clients/media/createFolder", params);
    return response;
}

//create new profile
async function getAll(params) {
    const response = await postRequest("clients/media/getAll", params);
    return response;
}

//create new profile
async function updateFolderName(params) {
    const response = await postRequest("clients/media/updateFname", params);
    return response;
}

//delete profile
async function deleteFolder(params) {
    const response = await postRequest("clients/media/deleteFolder", params);
    return response;
}

//delete file
async function deleteFile(params) {
    const response = await postRequest("clients/media/deleteFile", params);
    return response;
}

//update file
async function updateFile(params) {
    const response = await postRequest("clients/media/updateFile", params);
    return response;
}

//update folder file
async function updateFolderFile(params) {
    const response = await postRequest("clients/media/updateFolderFile", params);
    return response;
}

//upload file
async function uploadFiles(params) {
    const response = await postRequest("clients/media/upload", params);
    return response;
}