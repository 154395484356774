const styles = (theme) => ({
    root: {
        display: 'flex',
        background: "transparent",
        boxShadow: "none !important",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiCardContent-root": {
            padding: "4px !important",
            paddingLeft: "10px !important",
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: "50px",
    },
    paper: {
        '@media only screen and (min-width: 900px)':{
            minWidth: 'fit-content',
        },
        right: 0
    }
});

export default styles;
