import React from "react";
import { Avatar, Grid, Typography, withStyles, LinearProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import AvatarGroup from '@material-ui/lab/AvatarGroup';

// Style
import Style from './style'
import { CalendarIconNew } from "../../assets/svg";
import { TitleAvatar } from "../TitleAvatar";
import { Button } from "../Button";

function ChatFlowsClientsComp(props) {

    const { classes } = props;
  return (
    <Grid className={`${classes.cardRoot} cardRoot`}>
      <Grid className="dflex alignCenter pb-2">
        <Grid className="cardLogo">
          <TitleAvatar />
        </Grid>
        <Grid className="pl-1">
        <Typography variant="body1" className="fw-700 pb8" align="left">Design Review</Typography>
        <Typography className="department" style={{ color: '#2563EB' }}><span style={{ background: '#2563EB21' }}><span style={{ background : '#2563EB' }}></span></span>Sales</Typography>
        </Grid>
      </Grid>
      <Typography variant="body1" className="fs-12 mb-2 secondary2 threeLineEllip" color="textSecondary">As a design team that is responsible for delivering final design for our end-users, you will be assigned to a</Typography>
          <Grid className="dflex alignCenter pb-1">
            <AvatarGroup max={3}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
              <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
            </AvatarGroup>
            <Typography variant="body1" className="fs-12 secondary2 oneLineEllip pl-1" color="textSecondary">Eight friends work here</Typography>
          </Grid>
          <LinearProgress variant="determinate" value={70} />
        <Grid className="dflex justifyBetween alignCenter pt-2">
            <Grid className="dateShow">
              <span className="pl5">Due on : </span>
                <CalendarIconNew />
              <span className="pl5">Nov 20</span>
            </Grid>
          <Grid className="statusChip" style={{ background: '#2563EB21', color: '#2563EB' }}>In progress</Grid>
        </Grid>
        <Button variant="contained" color="primary" className="clickHereBtn" fullWidth>Click Here</Button>
    </Grid>
  )
}

ChatFlowsClientsComp.defaultProps = {
};
ChatFlowsClientsComp.propTypes = {
    classes: PropTypes.object,
};

// export component
export const ChatFlowsClients = withStyles(Style)(ChatFlowsClientsComp);