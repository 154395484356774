export const JSONParse = (str, defaultValue = {}) => {
    try {
        if (typeof str !== "string") {
            return defaultValue;
        }
        return JSON.parse(str);
    } catch (error) {
        return defaultValue;
    }
}
export const JSONStringify = (obj, defaultValue = "") => {
    try {
        if (!(obj instanceof Object)) {
            return defaultValue;
        }
        return JSON.stringify(obj);
    } catch (error) {
        return defaultValue;
    }
}