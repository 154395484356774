export const clientMediaConstant = {

    //get all media
    GET_ALL_MEDIA_R: "GET_ALL_MEDIA_R",
    GET_ALL_MEDIA_S: "GET_ALL_MEDIA_S",
    GET_ALL_MEDIA_F: "GET_ALL_MEDIA_F",

    //create folder
    CREATE_FOLDER_R: "CREATE_FOLDER_R",
    CREATE_FOLDER_S: "CREATE_FOLDER_S",
    CREATE_FOLDER_F: "CREATE_FOLDER_F",

    //delete folder
    DELETE_FOLDER_R: "DELETE_FOLDER_R",
    DELETE_FOLDER_S: "DELETE_FOLDER_S",
    DELETE_FOLDER_F: "DELETE_FOLDER_F",

    //delete file
    DELETE_FILE_R: "DELETE_FILE_R",
    DELETE_FILE_S: "DELETE_FILE_S",
    DELETE_FILE_F: "DELETE_FILE_F",

    //update file
    UPDATE_FILE_R: "UPDATE_FILE_R",
    UPDATE_FILE_S: "UPDATE_FILE_S",
    UPDATE_FILE_F: "UPDATE_FILE_F",

    //update file
    UPLOAD_FILE_R: "UPLOAD_FILE_R",
    UPLOAD_FILE_S: "UPLOAD_FILE_S",
    UPLOAD_FILE_F: "UPLOAD_FILE_F",

    //update folder
    UPDATE_FOLDER_NAME_R: "UPDATE_FOLDER_NAME_R",
    UPDATE_FOLDER_NAME_S: "UPDATE_FOLDER_NAME_S",
    UPDATE_FOLDER_NAME_F: "UPDATE_FOLDER_NAME_F",

    //update folder files
    UPDATE_FOLDER_FILE_R: "UPDATE_FOLDER_FILE_R",
    UPDATE_FOLDER_FILE_S: "UPDATE_FOLDER_FILE_S",
    UPDATE_FOLDER_FILE_F: "UPDATE_FOLDER_FILE_F",


    //update folder view
    UPDATE_FOLDER_VIEW: "UPDATE_FOLDER_VIEW",

    //set file type selected
    UPDATE_FILE_TYPE: "UPDATE_FILE_TYPE",

    //update selected folder id
    UPDATE_FOLDER_ID: "UPDATE_FOLDER_ID",

    UPDATE_GROUP_VIEW: "UPDATE_GROUP_VIEW",

};
