import React from 'react';
import { withStyles,IconButton } from "@material-ui/core";

//Import Styles
import styles from "./styles";

//Import Audio Player Component
import { AudioPlayer } from "../AudioPlayer"
import { isAndroid, isIOS } from 'react-device-detect';
import { CircularProgress, Grid } from "@material-ui/core"
import { PlayCircleOutline } from "@material-ui/icons"
import Player from '../AudioPlayer/AudioPlayer';
import {AudioRecorder} from '../../components/AudioRecorder/index'
// import Player from '../AudioPlayer/AudioPlayer';

const VoiceNoteMessage = (props) => {
    const { classes,message, handleUndo, undo,message: { uploadPreviews} ,viewType, userId} = props;
    const media = message.message_file ? JSON.parse(message.message_file) : null;
    const { file_type, files_data } = uploadPreviews || {}

    const filesData = files_data && JSON.parse(files_data); 
    const thumbUrl =filesData && filesData[0]?.thumbUrl; 


    // if (isIOS || isAndroid) {
    //     // return <Player id={JSON.stringify(props)} src={media.url}></Player>
    //     return (
    //         <>
    //             {!undo ?
    //             <Player id={JSON.stringify(props)} src={media.url}></Player>
    //               :
    //                 <span>This message has been deleted
    //                     <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message.id)}>undo</a>
    //                 </span>
    //             }
    //         </>
    //     );
    // }
    if (file_type?.indexOf('audio') > -1 && !media && thumbUrl) {
        return (
            <div className={classes.uploadPreviewAudio}>
                <AudioPlayer 
                    audioFile={thumbUrl}
                    onuploading={true}
                    {...props}
                 />
            </div>
        )
    }
    else {
        return (
            <>
                {!undo ? (isIOS || isAndroid) ? <AudioPlayer audioFile={media?.url} {...props} /> :
                    <AudioPlayer audioFile={media?.url} {...props} />
                    :
                    <span className="deletedMessage">This message has been deleted
                        {message?.message_from === userId ? (
                            <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message.id)}>undo</a>
                        ) : null
                        }
                    </span>
                }
            </>
        )
     }
}

export default withStyles(styles)(VoiceNoteMessage)