import loadImage from "./loadImage";
import { aspectRatio } from "./utils";

function fitStageIntoParentContainer({ container, imageObj }) {

    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    const upScaleX = imageObj.width / containerWidth ;
    const upScaleY = imageObj.height / containerHeight;

    // but we also make the full scene visible
    // so we need to scale all objects on canvas
    var scale = containerWidth / imageObj.width;
    return {
        width: imageObj.width * scale,
        height: imageObj.height * scale,
        scale,
        upScale: Math.max(upScaleX, upScaleY)
    }
}

export const drawImage = ({ src, stage, ...other }) => {
    loadImage(src, async (imageObj) => {
        const container = other.canvasWrapperRef
        const layer = new window.Konva.Layer({ id: 'baseLayer' });
        const { widthT, heightT } = aspectRatio(imageObj, {
            width: imageObj.width < container.offsetWidth ? imageObj.width : container.offsetWidth,
            height: imageObj.height < container.offsetHeight ? imageObj.height : container.offsetHeight
        })

        const onChangeAttrs = (attrs, type = 'img') => {
            switch (type) {
                case 'img':
                    img.setAttrs({
                        image: attrs.img
                    })
                    break;
                case 'drawPen':
                    stage.attrs.drawProps = {
                        stroke: attrs?.stroke,
                        strokeWidth: attrs?.strokeWidth
                    }
                    stage.onUpdate(stage)
                    break;
                case 'crop':
                    stage.setAttrs({
                        ...attrs.stageProps
                    })
                    break
                default:
                    break;
            }

        }

        const img = new window.Konva.Image({
            image: imageObj,
            x: 0,
            y: 0,
            width: widthT,
            height: heightT,
            draggable: other.draggable,
            onChangeAttrs: onChangeAttrs
        });
        img.id('baseImage')
        stage.width(widthT)
        stage.height(heightT)

        const containerData = fitStageIntoParentContainer({
            stage,
            container: other.canvasWrapperRef,
            imageObj
        })
        stage.attrs.containerData = containerData

        const tr = new window.Konva.Transformer({
            rotateEnabled: false
        });
        tr.id('transformer')

        const blurImage = new window.Konva.Image({
            image: imageObj,
            draggable: true,
            width: widthT,
            height: heightT
        });
        blurImage.cache()
        blurImage.filters([window.Konva.Filters.Pixelate]);
        blurImage.pixelSize(25)
        blurImage.id('baseBlurImage')

        img.on('mouseover', function () {
            document.body.style.cursor = 'pointer';
        });
        img.on('mouseout', function () {
            document.body.style.cursor = 'default';
        });

        stage.on('click', function (e) {
            if (e?.target?.attrs?.name?.indexOf('need-tr') > -1) {
                stage.attrs.drawMode = false
                const selectedNode = stage.findOne(`#${e.target.attrs.id}`)
                if (selectedNode) {
                    tr.nodes([selectedNode])
                    tr.show()
                }
            } else {
                tr.nodes([])
                tr.hide()
            }
            stage.attrs.onUpdate(stage)
        })

        layer.add(blurImage)
        layer.add(img);
        layer.add(tr)
        stage.add(layer);
        stage.attrs.onUpdate(stage);
    })
}