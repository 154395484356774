import React from 'react';
import PropTypes from "prop-types";
import { useMemo } from 'react';
import { withStyles ,CircularProgress} from "@material-ui/core";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import Play from "./Play";
import Pause from "./Pause";
import Bar from "./Bar";
import FakeBar from "./FakeBar";
import WaveformComponent from './Waveform';
import styles from './styles';
import useAudioPlayer from './useAudioPlayer';
import _ from 'lodash';
const PropsCompare = ['elementId']
function Audio(props) {
    const { classes, audioFile, message, msgComponentType, fromCall,onuploading,viewType } = props;
    let elementId = `${fromCall || "nil"}_audio_${message?.moduleType}_${message?.id}`;
    let { curTime, duration, playing, setPlaying, setClickedTime, setPlaySpeed } = useAudioPlayer({ elementId });
    const media = message?.message_file ? JSON.parse(message.message_file) : null;
    duration = duration && duration != "Infinity" ? duration : (media && media.duration ? media.duration / 1000 : '0');

    const [speed, setSpeed] = React.useState(1);
    const handleChange = (event) => {
        let speed = event.target.value;
        setSpeed(speed);
        setPlaySpeed(speed);
    };

    return (
        <>
            <div className={classes[msgComponentType]}>
                <div className={`player`}>
                    <audio id={elementId} loop={false}>
                        <source src={audioFile} type="audio/mp3" />
                        Your browser does not support the <code>audio</code> element.
                    </audio>

                    <div className="controls">
                        {playing ?
                            <Pause handleClick={() => setPlaying(false)} /> :
                            <Play handleClick={() => setPlaying(true)} message={message?.message_file}/>
                        }
                        {(duration || curTime >= 0) && (message?.message_file) ? (
                                <WaveformComponent
                                    url={audioFile}
                                    curTime={curTime}
                                    duration={duration}
                                    playing={playing}
                                    onTimeUpdate={(time) => setClickedTime(time)}
                                    msgComponentType={msgComponentType}
                                    timing={true}
                                    viewType={viewType}
                                />
                        ) : (
                                <WaveformComponent 
                                    url={audioFile}
                                    timing={true} 
                                    curTime={'0'}
                                    duration={message?.duration}
                                    playing={playing}
                                    onTimeUpdate={'00:00'}
                                    msgComponentType={'sent'}
                                    viewType={viewType}
                                />
                        )}

                    </div>

                    <>
                    {message?.message_file ?
                        <FormControl variant="filled" className={`${classes.playerSpeed} playerSpeed`}>
                            <Select
                                labelId="demo-simple-select-filled-label"
                                id="demo-simple-select-filled"
                                value={speed}
                                onChange={handleChange}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                <MenuItem value={1}>1x</MenuItem>
                                <MenuItem value={1.5}>1.5x</MenuItem>
                                <MenuItem value={2}>2x</MenuItem>
                            </Select>
                        </FormControl>
                    :
                    (
                        <CircularProgress
                            size={30}
                            color={'white'}
                            className={`${classes.playerSpeed} playerSpeed`}
                        />
                    )}
                    </>

                </div>
            </div>
        </>
    );
}

// default props
Audio.defaultProps = {
    variant: "outlined",
    audioFile: null,
};

// prop types
Audio.propTypes = {
    variant: PropTypes.string,
    audioFile: PropTypes.string,
};
// React memo areEqual Function
function areEqual(prevProps, nextProps) {
    let isRerender = PropsCompare.reduce((a, b) => {
        if (!_.isEqual(prevProps[b], nextProps[b])) {
            a.push(b)
        }
        return a
    }, [])
    if (isRerender.length == 0) {
        return true; 
    }
    return false ;
}

// export with memo
export const AudioPlayer = withStyles(styles)(Audio);

React.memo(AudioPlayer, areEqual);

// export default withStyles(styles)(React.memo(AudioPlayer, areEqual))
// export component
// export const AudioPlayer = withStyles(styles)(Audio);
