import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Snackbar as Core } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

// Import Actions
import { alertAction } from "../../redux/actions";

import { removeToken } from "../../redux/localStorage";

import { CloseCircleIcon } from '../../assets/svg/index';

// Import Styles
import style from "./style";
import history from "../../config/history";
import { _envParams } from "../../config/env_params";
const isPROD = _envParams?.REACT_APP_BUILD_TO?.indexOf('PROD') > -1
const PROD_HIDE_MESSAGES = [
  'Network Error',
  'No Internet Connection',
  'Internal Server Error!',
  'AxiosError: Network Error',
  'Invalid or Missing Token or Key',
]

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

function SnackBarComponent(props) {
  /**
   * Redux Action and Reducer
   */
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  /**
   *
   * @param {*} event
   * @param {*} reason
   */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(alertAction.clear());
  };

  /**
   *
   */
  const handleExited = () => {
    dispatch(alertAction.clear());
  };

  /**
   *
   */
  const { classes } = props;
  const { type, message } = alert;
  const disableSnack = isPROD && PROD_HIDE_MESSAGES.indexOf(message) > -1


  if (message === "Invalid or Missing Token or Key") {
    removeToken()
    history.push("/login");
  }

  return !disableSnack ? (
    <Core
      className={classes.snackBar}
      key={type || undefined}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      ContentProps={{
        classes: {
          root: classes[type]
        }
      }}
      open={!!type}
      TransitionComponent={TransitionDown}
      autoHideDuration={3000}
      onClose={handleClose}
      onExited={handleExited}
      message={<span dangerouslySetInnerHTML={{ __html: message }}></span>}
      action={
        <React.Fragment>
          <IconButton
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseCircleIcon />
          </IconButton>
        </React.Fragment>
      }
    />
  ) : null
}

// default props
SnackBarComponent.defaultProps = {
  classes: {}
};

// prop types
SnackBarComponent.propTypes = {
  classes: PropTypes.object
};

// Export component
export const SnackBar = withStyles(style)(SnackBarComponent);
