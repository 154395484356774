
const NotificationListStyle = (theme) => ({
  NotificationContainer: {
    '& .NotificationContainerWrapper': {
      backgroundColor: theme.palette.containers.card
    },
    '& .ScrollContainer, .PopoverScrollContainer': {
      overflow: 'auto'
    },
    '& .ScrollContainer': {
      height: 'calc(100vh - 200px)',
    },
    '& .PopoverScrollContainer': {
      minHeight: '354px'
    },
    '& .MuiButton-text': {
      padding: '2px',
      borderRadius: 8
    },
    '& .MuiTabs-scroller': {
      borderBottom: 'none'
    },
    '& .MuiTabs-flexContainer': {
      justifyContent: 'space-between'
    },
    "@media only screen and (max-width: 899px)": {
      background: theme.palette.containers.body
    }
  },
  mobileTitleContainer: {
    '@media only screen and (max-width: 899px)': {
      background: theme.palette.containers.card,
      boxShadow: '0px 4px 4px 0px #00000017'
    },
  },
  NotificationContainerPadding: {
    padding: 'calc((100% - 94vw) / 2)',
    '& .headerContainer': {
      padding: '30px 12px 0px 12px',
      '@media only screen and (max-width: 899px)': {
        padding: '10px',
      }
    }
  },
  notificationButton: {
    '@media only screen and (max-width: 599px)': {
      padding: '6px'
    },
    '@media only screen and (min-width: 600px)': {
      padding: '15px'
    }

  },

  notidyhead: {
    // fontSize: "160%",
    color: theme.palette.primary.main,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "650",
    lineheight: "125%",
    letterSpacing: "0.2px",
    flex: 1
  },
  ReturnIcon: {
    border: "black",
    borderRadius: "18px",
    background: theme.palette.containers.bg7,
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
  },
  buttoncard2: {
    alignItems: "center",
    borderRadius: "12px",
    background: theme.palette.containers.bg44,
    overflow: "hidden",
    marginBottom: '10px',
    justifyContent: 'space-between'
  },
  Imagedot: {
    width: " 9px",
    height: " 9px",
    flexShrink: "0",
    fill: " #24D164",
    strokeWidth: " 1px",
    stroke: " #FFF",
    position: 'absolute',
    top: '0',
    left: '0'
  },
  unreadchat2: {
    cursor: "pointer",
    width: "100%",
    // marginTop: "1px",
    // height: " 72px",
    flexShrink: "0",
    backgroundColor: theme.palette.containers.bg46,
    padding: '10px',
    borderBottom: '1px solid #00000012'
  },
  chatbtn: {
    marginLeft: "4px",
    marginTop: "2px",
    height: " 32px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: " 0",
    borderRadius: "8px",
    background: theme.palette.containers.bg45,
    boxShadow: "0px 0px 12px 0px #6B72800A",
    color: theme.palette.text.text9,
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: " 700",
    lineHeight: "160%",
  },
  taskbtn: {
    width: "fit-content",
    height: "32px",
    padding: "6px 12px",
    marginTop: "3.5px",
    color: theme.palette.text.text10,
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: " 500",
    lineHeight: "160%",
  },
  customBadgeothers: {
    color: " #fff",
    marginRight: "58px",
    marginTop: "16%",
    width: " 15px",
    height: "20px",
    fontSize: " 10px",
    background: " #F178B6",
    alignItems: "center",
    borderRadius: "100%",
  },

  Imagedottothers2: {
    marginLeft: "-88px",
    marginTop: "-3%",
    width: "9px",
    height: "9px",
    flexShrink: "0",
    fill: "#24D164",
    strokeWidth: "1px",
    stroke: "#FFF",
  },
  messageothers2: {
    color: theme.palette.primary.main,
    fontFamily: "Inter",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "160%",
  },

  msgtimeothers2: {
    color: "#64748B",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "160%",
  },

  customBadge: {
    color: " #fff",
    marginRight: "45px",
    marginTop: "22%",
    width: " 15px",
    height: "20px",
    fontSize: " 10px",
    background: " #F178B6",
    alignItems: "center",
    borderRadius: "100%",
  },
  avatar: {
    border: `2px solid ${theme.palette.containers.card}`,
    // marginLeft: "-36px",
    // marginTop: "15px",
    width: "45px",
    height: "45px",
    borderRadius: "50%",
  },
  message: {
    color: theme.palette.primary.main,
    fontFamily: " Inter",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: " 160%",
  },
  msgtime: {
    color: " #64748B",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: " 160%",
    // marginLeft: "40px",
  },

  noNotificationText: {
    color: " #94A3B8",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
  },
  userDisplayName: {
    fontWeight: "bold",
    color: "600",
  },
  offlineDot: {
    backgroundColor: '#ccc',
    borderRadius: "5px",
    border: " 0.5px solid white",

  },
  primaryButton: {
    background: theme.palette.text.blueText,
    color: theme.palette.text.contrastText,
    fontWeight: 700,
    boxShadow: "0px 4px 10px 0px #00000026",
    borderRadius: "8px",
    fontSize: "14px",
    "&:hover": {
      background: theme.palette.text.blueText,
      color: theme.palette.text.contrastText,
      fontWeight: 700,
    },
    "&:active": {
      background: '#81A5F3'
    },
  },
  secondaryButton: {
    background: theme.palette.containers.arrowBg,
    color: theme.palette.text.blueText,
    border: `1px solid ${theme.palette.text.blueText}`,
    fontWeight: 600,
    "&:hover": {
      background: 'rgba(37, 99, 235, 0.14)',
      color: theme.palette.text.blueText,
      border: `1px solid ${theme.palette.text.blueText}`,
      fontWeight: 600,
    },
    "&:active": {
      background: 'rgba(233, 243, 254, 1)',
      border: `1px solid ${theme.palette.text.blueText}`,
    },
  },
  secondaryTwoButton: {
    background: theme.palette.containers.body,
    color: theme.palette.text.secondary,
    border: `1px solid ${theme.palette.primary.secondaryTwoBtn}`,
    fontWeight: 600,
    "&:hover": {
      background: 'rgba(37, 99, 235, 0.14)',
      color: theme.palette.text.secondary,
      border: `1px solid ${theme.palette.text.secondary}`,
      fontWeight: 600,
    },
    "&:active": {
      color: theme.palette.text.blueText,
      background: 'rgba(233, 243, 254, 1)',
      border: `1px solid ${theme.palette.text.blueText}`,
    },
  },
  smallSizeButton: {
    height: '32px'
  },
  notifybutton: {
    '& .MuiButton-label': {
      color: '#FFF !important'
    }
  },
  imageContent: {
    width: "max-content"
  },
  imageWidth: {
    width: '48px'
  },
  zapierEmoji: {
    display: "flex",
    fontSize: "1.75rem",
    width: "40px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default NotificationListStyle;
