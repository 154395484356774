import React, { Component } from 'react';
import { Tooltip } from '@material-ui/core';

class StyleButton extends Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }

    return (
      <Tooltip title={this.props.tooltip  ? this.props.tooltip  : ""}>
        <span className={className} onMouseDown={this.onToggle}>
          {this.props.icon ? <i className={this.props.icon} /> : this.props.label}
        </span>
      </Tooltip>
    );
  }
}

export default StyleButton;
