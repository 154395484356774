import React from "react";
import {
  Box
} from "@material-ui/core";
import VCRoom from "./VCRoom"

function VideoCall(props){
  const { id, match, type } = props
  return (
    <Box>
      <VCRoom 
        id={id}
        match={match}
        type={type}
      />
    </Box>
  )
}

export default VideoCall;