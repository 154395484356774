const styles = (theme) => ({
  cardRoot: {
    background: "#F2F5FA",
    boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.13)",
    // borderRadius: "0px 16px 16px 16px",
    padding: "10px 20px 30px",
    width: '100%',
    maxWidth: '290px',
    "& .boardImg": {
      borderRadius: "8px",
      height: "138px",
      width: "100%",
      objectFit: "cover",
      marginBottom: "10px",
    },
    "& .tagItem": {
      display: "flex",
      alignItems: "center",
      "& .dot": {
        height: "6px",
        width: "6px",
        borderRadius: "6px",
        marginRight: "4px",
      },
    },
    "& .footerContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderTop: '1px solid #DCDEE1',
      paddingTop: '17px',
      "& .dateShow": {
        background: theme.palette.containers.card,
        borderRadius: "8px",
        height: "32px",
        fontSize: '12px',
        fontWeight: '500',
        color: '#64748B',
        display: 'flex',
        alignItems: 'center',
        padding: '10px'
      },
    },
  },
});

export default styles;
