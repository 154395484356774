// Import API Helper
import { postRequest } from "../../utils/apiHelper";

export const taskService = {
    getAllTasks,
    getTaskFilter,
    completeTask,
    getTaskJobData,
    createNewTask,
};

//create new profile
async function getAllTasks(params) {
    const response = await postRequest("task/list", params);
    return response;
}

//create new profile
async function getTaskFilter(params) {
    const response = await postRequest("task/filter", params);
    return response;
}

//complete task
async function completeTask(params) {
    const response = await postRequest("task/complete", params);
    return response;
}

//get all job and client data
async function getTaskJobData(params) {
    const response = await postRequest("task/getJobData", params);
    return response;
}

//create new task
async function createNewTask(params) {
    const response = await postRequest("task/create", params);
    return response;
}