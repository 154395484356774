import React, { useState } from "react";
import PropTypes from "prop-types";
import { KeyboardTimePicker as TimePicker } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Icon, IconButton } from "@material-ui/core";

// Import Images
import CalendarIcon from "../../assets/images/svg/calendar.svg";

//SVG
import { ScheduleClock } from "../../assets/svg";

/**
 *TimePicker Component
 *
 * @class TimePicker
 * @extends {Component}
 */
function TimePickerComponent(props) {
    // Handle TextField Events

    const [open, setOpen] = useState(false);

    const handleChange = (event, value) => {
            if (event) {
                let d = event._d;
                props.handleChange({ name: props.name, value: d });
            }
        
    }

    /**
       *render html
       *
       * @returns
       * @memberof TimePicker
       */
    const { value, name, placeholder, label, disabled, margin, size, className, validators, validations, style, helperText, notRequired, panel } = props;
    const customClass = validators && validators.length > 0 && !notRequired ? className + " required" : className;
    // const warningClass = hasWarnings(fieldErrors, warningFieldName);

    return (
        <TimePicker
            label={label}
            name={name}
            value={value || null}
            onChange={handleChange}
            margin={margin}
            placeholder={placeholder}
            autoOk={true}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            className={`${customClass}`}
            disabled={disabled}
            InputProps={
                {
                    startAdornment: (
                        < InputAdornment position="start" style={{ cursor: "pointer" }}>
                            <IconButton onClick={e => { !disabled && setOpen(true) }} position="center" style={{}}>
                                <ScheduleClock />
                            </IconButton>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        < InputAdornment position="end" style={{ cursor: "pointer" }}>
                            <Icon position="end" style={{ padding: "5px" }}>
                                <img src={CalendarIcon} alt="calender" style={{ height: "16px", weight: "16px", position: "relative", top: "3px", left: "5px" }} />
                            </Icon>
                        </InputAdornment>
                    ),
                }
            }
            style={style}
            size={size}
            {...validations}
            panel={panel}
            open={open}
            helperText={helperText}
            keyboardIcon={false}
            KeyboardButtonProps={{
                "aria-label": "change time"
            }}
        />
    );
}

// default props
TimePickerComponent.defaultProps = {
    name: "",
    value: "",
    label: "",
    fullWidth: "",
    disabled: false,
    variant: '',
    inputVariant: "outlined",
    className: "cursor-pointer",
    errorMessages: [],
    validators: [],
    onOpen: () => { },
    onClose: () => { },
    handleChange: () => { },
    validations: {},
    onBlur: () => { },
    fieldErrors: [],
    warningFieldName: "",
    style: {},
    helperText: "",
    notRequired: false,
    margin: "dense",
    size: "small",
    placeholder: "--:-- --",
    mask: "--:-- --",
    disableFuture: false,
    disablePast: false,
};

// prop types
TimePickerComponent.propTypes = {
    name: PropTypes.any,
    value: PropTypes.any,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    variant: PropTypes.string,
    inputVariant: PropTypes.string,
    margin: PropTypes.string,
    className: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    errorMessages: PropTypes.array,
    validators: PropTypes.array,
    helperText: PropTypes.string,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    placeholder: PropTypes.string,
    mask: PropTypes.string,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
};

// Export Component
export const TimePickers = TimePickerComponent
