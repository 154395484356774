import {
  alertConstant,
  authConstant,
  clientConstant,
  loaderConstant,
  taskConstant,
  chatv2Constant
} from "../constants";
import { clientService } from "../services";
import { baseAction } from "./baseAction";

import history from "../../config/history";

import { validateUserPlan } from "../helpers/projectHelpers";

const { request, success, failure } = baseAction;

// All login based actions
export const clientAction = {
  createClient,
  getClients,
  getClientsCount,
  getClientsFilter,
  updateDND,
  getClientDetail,
  getAllTags,
  completeClient,
  deleteClient,
  deleteClientFromAgency,
  archiveClient,

  getBrief,
  updateBrief,
  updateBriefDescription,
  saveDuplicateClient,

  getAllNotes,
  getNote,
  addNote,
  updateNote,
  shareNote,
  deleteNote,

  getClientTeamUsers,
  inviteNewUsers,
  inviteExistingUsers,
  removeClientUser,
  getTeamMembers,
  getActiveTeamMembers,
  newUserInvitationAccept,
  getClientInviteData,

  createNewTask,
  getAllTasks,
  getCurrentTask,
  deleteTask,
  updatecurrentTask,
  getTaskFilter,
  completeTask,
  getCTaskTags,
  createTaskNote,
  getAllTaskNote,
  deleteTaskNote,
  updateNoteV2,
  updateBriefDescriptionV2,
  generateOpenInvite,
  deleteOpenInvite,
  clientOnboardingInviteUniqueName,
  clientOnboardingInviteLinks,
  getClientOnboardingInvite,
  clientOnboardingLogsAdd,
  clientOnboardingUsersAdd,
  getAgencyNameAndLogo,
  createNewOnboardingUser,
  getOnboardingFilters,
  clientPreference
};

/** create new client */
function createClient(params, callBack = () => {}) {
  return (dispatch, getState) => {
    dispatch(request(clientConstant.CREATE_CLIENT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    const validation = validateUserPlan(params, getState(), "client");
    if (validation.isValid) {
      clientService.createClient(params).then(
        (data) => {
          let message = data.message.split("_");
          dispatch(success(clientConstant.CREATE_CLIENT_S, data));
          dispatch(success(alertConstant.ALERT_SUCCESS, message[0]));
          dispatch(request(loaderConstant.LOADING_FALSE));
          callBack(message[1]);
        },
        (error) => {
          dispatch(failure(clientConstant.CREATE_CLIENT_F, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(loaderConstant.LOADING_FALSE));
        }
      );
    } else {
      dispatch(failure(alertConstant.ALERT_ERROR, validation.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
  };
}

/** get tags */
function getAllTags(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_TAGS1_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getAllTags(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_TAGS1_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.GET_TAGS1_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** get clients list */
function getClients(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_CLIENTS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getClients(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_CLIENTS_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.GET_CLIENTS_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** get clients count internal & external */
function getClientsCount(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_CLIENTS_COUNT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getClientsCount(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_CLIENTS_COUNT_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data);
      },
      (error) => {
        dispatch(failure(clientConstant.GET_CLIENTS_COUNT_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** get clients filter */
function getClientsFilter(params) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_CLIENTS_FILTER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getClientsFilter(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_CLIENTS_FILTER_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(clientConstant.GET_CLIENTS_FILTER_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** update client list order */
function updateDND(params) {
  return (dispatch) => {
    clientService
      .updateDND({ clients_ids: params.ids, type: params.type })
      .then(
        () => {
          dispatch(success(authConstant.UPDATE_CLIENTS_ORDER, params));
        },
        (error) => {
          dispatch(failure(clientConstant.UPDATE_C_DND_F, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
        }
      );
  };
}

/** get client detail */
function getClientDetail(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_CLIENT_DETAIL_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getClientDetail(params).then(
      (data) => {
        if (data.message === "failed") {
          history.push("/clients");
          dispatch(failure(alertConstant.ALERT_ERROR, data.data));
          dispatch(request(loaderConstant.LOADING_FALSE));
        } else {
          dispatch(success(clientConstant.GET_CLIENT_DETAIL_S, data));
          dispatch(request(loaderConstant.LOADING_FALSE));
          callBack(data.data);
        }
      },
      (error) => {
        dispatch(failure(clientConstant.GET_CLIENT_DETAIL_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

//complete client
function completeClient(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.COMPLETE_CLIENT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.completeClient(params).then(
      (data) => {
        dispatch(success(clientConstant.COMPLETE_CLIENT_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.COMPLETE_CLIENT_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

// archive Client
function archiveClient(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.ARCHIVE_CLIENT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.archiveClient(params).then(
      (data) => {
        dispatch(success(clientConstant.ARCHIVE_CLIENT_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.ARCHIVE_CLIENT_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

//deleteProject
function deleteClient(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.REMOVE_CLIENT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.deleteClient(params).then(
      (data) => {
        dispatch(success(clientConstant.REMOVE_CLIENT_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.REMOVE_CLIENT_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/****************************************************************BRIEF DETAILS************************************************************************************** */

/** get brief */
function getBrief(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_CLIENT_BRIEF_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getBrief(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_CLIENT_BRIEF_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data);
      },
      (error) => {
        dispatch(failure(clientConstant.GET_CLIENT_BRIEF_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** update brief */
function updateBrief(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.UPDATE_CLIENT_BRIEF_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.updateBrief(params).then(
      (data) => {
        dispatch(success(clientConstant.UPDATE_CLIENT_BRIEF_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch({type: chatv2Constant.UPDATE_CLIENT_LOGO_S,
          data: {
            room_id: data.data.id, 
            avatar: data.data.avatar,
            avatar_type: data.data.avatar_type
          }
        });
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.UPDATE_CLIENT_BRIEF_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}
/** update brief description*/
function updateBriefDescription(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.UPDATE_CLIENT_BRIEF_R, params));
    clientService.updateBrief(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_CLIENT_BRIEF_S, data));
        dispatch(success(clientConstant.UPDATE_CLIENT_BRIEF_S, data));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.UPDATE_CLIENT_BRIEF_F, error));
      }
    );
  };
}

/** update brief description*/
function updateBriefDescriptionV2(params) {
  return (dispatch) => {
    clientService.updateBrief(params).then(
      () => {
        // none to update
      },
      (error) => {
        dispatch(failure(clientConstant.UPDATE_CLIENT_BRIEF_F, error));
      }
    );
  };
}

/** create duplicate client */
function saveDuplicateClient(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.CREATE_CLONE_CLIENT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.saveDuplicateClient(params).then(
      (data) => {
        dispatch(success(clientConstant.CREATE_CLONE_CLIENT_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        history.push("/clients");
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.CREATE_CLONE_CLIENT_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/****************************************************************NOTES DETAILS************************************************************************************** */

/** get all notes */
function getAllNotes(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_ALL_C_NOTES_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getAllNotes(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_ALL_C_NOTES_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.GET_ALL_C_NOTES_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** get one notes */
function getNote(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_ONE_C_NOTES_R, params));
    if (!params["loading"]) {
      dispatch(request(loaderConstant.LOADING_TRUE));
    }
    clientService.getNote(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_ONE_C_NOTES_S, data));
        if (!params["loading"]) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        }
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.GET_ONE_C_NOTES_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        if (!params["loading"]) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        }
      }
    );
  };
}

/** add Note */
function addNote(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.ADD_C_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.addNote(params).then(
      (data) => {
        dispatch(success(clientConstant.ADD_C_NOTE_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.ADD_C_NOTE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** update Note */
function updateNote(params, callBack = () => {}) {
  return (dispatch, getState) => {
    const { newNote, note } = getState().clients;
    let params =
      (newNote && Object.keys(newNote).length > 0 && newNote) || note;
    if (note.id) {
      params = { ...params, id: note.id };
    }
    const noteText = params["notes_text"]
      ? JSON.parse(params.notes_text)
      : null;
    if (params === "new") {
      dispatch(request(loaderConstant.LOADING_TRUE));
    }
    if (
      (params && params["notes_title"]) ||
      (noteText && noteText.length > 0)
    ) {
      dispatch(request(clientConstant.UPDATE_C_NOTE_R, params));
      clientService.updateNote(params).then(
        (data) => {
          dispatch(success(clientConstant.UPDATE_C_NOTE_S, data));
          callBack(data);
          dispatch(request(loaderConstant.LOADING_FALSE));
        },
        (error) => {
          dispatch(failure(clientConstant.UPDATE_C_NOTE_F, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(loaderConstant.LOADING_FALSE));
        }
      );
    }
  };
}

/** update Note version 2 */
function updateNoteV2(params) {
  return (dispatch) => {
    if (params.newNote) {
      dispatch(request(loaderConstant.LOADING_TRUE));
    }
    clientService.updateNote(params).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        if (data && data?.data?.id && params.newNote) {
          history.push(
            `/clients/detail/${[params.client_id]}/notes/${data?.data?.id}`
          );
        }
      },
      (error) => {
        dispatch(failure(clientConstant.UPDATE_C_NOTE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/* delete Current Notes */
function deleteNote(params, callBack = () => {}) {
  return (dispatch, getState) => {
    dispatch(request(clientConstant.DEL_CURRENT_C_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.deleteNote(params).then(
      (data) => {
        dispatch(success(clientConstant.DEL_CURRENT_C_NOTE_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.DEL_CURRENT_C_NOTE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** update Note */
function shareNote(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.SHARE_C_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.shareNote(params).then(
      (data) => {
        dispatch(success(clientConstant.SHARE_C_NOTE_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data);
      },
      (error) => {
        dispatch(failure(clientConstant.SHARE_C_NOTE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/****************************************************************TEAMS DETAILS************************************************************************************** */

/* Get Client Team Members */
function getClientTeamUsers(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_C_TEAM_R, params));
    clientService.getClientTeamUsers(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_C_TEAM_S, data));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.GET_C_TEAM_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/* Invite new team members */
function inviteNewUsers(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.USER_C_NEW_INVITE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.inviteNewUsers(params).then(
      (data) => {
        dispatch(success(clientConstant.USER_C_NEW_INVITE_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.USER_C_NEW_INVITE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/* Invite old team members */
function inviteExistingUsers(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.USER_C_EXISTING_INVITE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.inviteExistingUsers(params).then(
      (data) => {
        dispatch(success(clientConstant.USER_C_EXISTING_INVITE_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.USER_C_EXISTING_INVITE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/* remove team members */
function removeClientUser(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.REMOVE_C_TEAM_USER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.removeClientUser(params).then(
      (data) => {
        dispatch(success(clientConstant.REMOVE_C_TEAM_USER_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.REMOVE_C_TEAM_USER_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/* delete client user from agency */
function deleteClientFromAgency(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.DELETE_C_USER_FROM_AGENCY_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.deleteClientUserFromAgency(params).then(
      (data) => {
        dispatch(success(clientConstant.DELETE_C_USER_FROM_AGENCY_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.DELETE_C_USER_FROM_AGENCY_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** GET TEAM MEMBERS */
function getTeamMembers(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_CA_TEAM_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getTeamMembers(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_CA_TEAM_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data);
      },
      (error) => {
        dispatch(failure(clientConstant.GET_CA_TEAM_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** GET ACTIVE TEAM MEMBERS */
function getActiveTeamMembers(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_CA_ACTIVE_TEAM_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getActiveTeamMembers(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_CA_ACTIVE_TEAM_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data);
      },
      (error) => {
        dispatch(failure(clientConstant.GET_CA_ACTIVE_TEAM_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** NEW USER INVITATION ACCEPTING */
function newUserInvitationAccept(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.NEW_C_USER_ACCEPT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.newUserInvitationAccept(params).then(
      (data) => {
        dispatch(success(clientConstant.NEW_C_USER_ACCEPT_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data);
      },
      (error) => {
        dispatch(failure(clientConstant.NEW_C_USER_ACCEPT_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

//getClientInviteData
function getClientInviteData(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.G_NEW_C_USER_ACCEPT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getClientInviteData(params).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));

        if (data.data && data.data.id) {
          dispatch(success(clientConstant.G_NEW_C_USER_ACCEPT_S, data));
          callBack(data.data);
        } else {
          history.push("/");
          dispatch(failure(alertConstant.ALERT_ERROR, data.message));
        }
      },
      (error) => {
        dispatch(failure(clientConstant.G_NEW_C_USER_ACCEPT_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/****************************************************************TASK DETAILS************************************************************************************** */

/* create New Task */
function createNewTask(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.CREATE_NEW_C_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.createNewTask(params).then(
      (data) => {
        dispatch(success(clientConstant.CREATE_NEW_C_TASK_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.CREATE_NEW_C_TASK_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/* get all Task */
function getAllTasks(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_ALL_C_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getAllTasks(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_ALL_C_TASK_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.GET_ALL_C_TASK_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/* get getCurrentTask Task */
function getCurrentTask(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_CURRENT_C_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getCurrentTask(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_CURRENT_C_TASK_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.GET_CURRENT_C_TASK_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/* delete Current Task */
function deleteTask(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.DEL_CURRENT_C_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.deleteTask(params).then(
      (data) => {
        dispatch(success(clientConstant.DEL_CURRENT_C_TASK_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.DEL_CURRENT_C_TASK_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/* delete Current Task */
function updatecurrentTask(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.UPDATE_CURRENT_C_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.updatecurrentTask(params).then(
      (data) => {
        dispatch(success(clientConstant.UPDATE_CURRENT_C_TASK_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.UPDATE_CURRENT_C_TASK_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

// /** get All Tasks */
function getTaskFilter(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_C_TASKS_FILTER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getTaskFilter(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_C_TASKS_FILTER_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.GET_C_TASKS_FILTER_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

// /** get All Tasks */
function completeTask(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.COMPLETE_TASK_R, params));
    clientService.completeTask(params).then(
      (data) => {
        dispatch(success(clientConstant.COMPLETE_TASK_S, data));
        dispatch(success(taskConstant.UPDATE_COMPLETED_TASK_COUNT_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        if (
          data.message &&
          data.message.toLowerCase() === "updated successfully"
        ) {
          callBack();
        }
      },
      (error) => {
        dispatch(failure(clientConstant.COMPLETE_TASK_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function getCTaskTags(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_C_TASK_TAGS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getCTaskTags(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_C_TASK_TAGS_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.GET_C_TASK_TAGS_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** create task note */
function createTaskNote(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.CREATE_C_TASK_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.createTaskNote(params).then(
      (data) => {
        dispatch(success(clientConstant.CREATE_C_TASK_NOTE_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.CREATE_C_TASK_NOTE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** get all note for a task*/
function getAllTaskNote(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_C_TASK_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getAllTaskNote(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_C_TASK_NOTE_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.GET_C_TASK_NOTE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** delete task note */
function deleteTaskNote(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.DELETE_TASK_NOTE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.deleteTaskNote(params).then(
      (data) => {
        dispatch(success(clientConstant.DELETE_TASK_NOTE_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(clientConstant.DELETE_TASK_NOTE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** Generate Client Onboarding Invites */
function generateOpenInvite(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.CREATE_CLIENT_INVITE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.generateOpenLink(params).then(
      (data) => {
        dispatch(success(clientConstant.CREATE_CLIENT_INVITE_S, data));
        if(!params.is_funnel) dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data);
      },
      (error) => {
        dispatch(failure(clientConstant.CREATE_CLIENT_INVITE_F, error));
        if(!params.is_funnel)  dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** Delete Client Onboarding Invites */
function deleteOpenInvite(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.deleteOpenInvite(params).then(
      (data) => {
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      (error) => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** Check for unique name in Client Onboarding Invites */
function clientOnboardingInviteUniqueName(name, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.clientOnboardingInviteUniqueName(name).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data?.data);
      },
      (error) => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** Get Client Onboarding Invites List*/
function clientOnboardingInviteLinks(params, callBack = () => {}) {
  return (dispatch) => {
    if (!params.appendData) {
    dispatch(request(clientConstant.GET_CLIENT_INVITE_LIST_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    }
    clientService.clientOnboardingInviteLinks(params).then(
      (data) => {
        if (!params.appendData) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        }
        dispatch(success(clientConstant.GET_CLIENT_INVITE_LIST_S, data));
        callBack()
      },
      (error) => {
        dispatch(failure(clientConstant.GET_CLIENT_INVITE_LIST_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        if (!params.appendData) {
          dispatch(request(loaderConstant.LOADING_FALSE));
        }
        callBack()
      }

    );
  };
}

/** Get Selected Client Onboarding Invite*/
function getClientOnboardingInvite(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_CLIENT_INVITE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getClientOnboardingInvite(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_CLIENT_INVITE_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data?.data);
      },
      (error) => {
        dispatch(failure(clientConstant.GET_CLIENT_INVITE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** Add Client Onboarding Invite Logs*/
function clientOnboardingLogsAdd(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.clientOnboardingLogsAdd(params).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data?.data);
      },
      (error) => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**Add Client Onboarding Users*/
function clientOnboardingUsersAdd(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.clientOnboardingUsersAdd(params).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data);
      },
      (error) => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**Add Client Onboarding Users*/
function getAgencyNameAndLogo(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(clientConstant.GET_INVITE_AGENCY_INFO_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getAgencyNameAndLogo(params).then(
      (data) => {
        dispatch(success(clientConstant.GET_INVITE_AGENCY_INFO_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data?.data);
      },
      (error) => {
        dispatch(request(clientConstant.GET_INVITE_AGENCY_INFO_F, params));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**Add Client Onboarding Users*/
function createNewOnboardingUser(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.createNewOnboardingUser(params).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data);
      },
      (error) => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** Client Onboarding Filters **/
function getOnboardingFilters(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(clientConstant.GET_FILTER_LIST_R, params));
    clientService.getOnboardingFilters().then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(clientConstant.GET_FILTER_LIST_S, data));
        callBack(data?.data);
      },
      (error) => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(clientConstant.GET_FILTER_LIST_F, error));
      }
    );
  };
}

function clientPreference (params, callBack = () => { }) {
  return(dispatch) =>{
    // dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(clientConstant.PREFERANCE_R, params));
    clientService.clientPreference(params).then(data =>{
      dispatch(success(clientConstant.PREFERANCE_S, data))
      dispatch({
        type: authConstant.UPDATE_USER_PREFERENCE,
        data: data.data.preference
      }, error =>{
        // dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(failure(clientConstant.GET_FILTER_LIST_F, error));
      }
    )
    })
  }
}
