const PublicLayoutStyles = (theme) => ({
  root: {
    height: "100%!important"
  },
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
    // background: '#F4F5F8'
  },
  publicLayoutHeader: {
    flexBasis: 'auto',
    background: theme.palette.containers.card,
    '& .headerContainer': {
      borderBottom: 'none !important'
    },
    '&.page-publicdoc': {
      '& .headerContainer': {
        height: 40,
      }
    }
  },
  publicPageBody: {
    overflowY: 'auto',
    background: theme.palette.containers.bg1
  }
});

export default PublicLayoutStyles;