const style = (theme) => ({

    LeavePageContainer: {
        width: '100%',
        height: '100%',
        position: "absolute",
        background: 'black',
        top: 0,
        '@media only screen and (max-width: 768px)' :{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }

    },
    HomescreenText: {
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: 'flex-end',
        gap: '10px',
        marginTop: '5px',
        flexDirection: 'column-reverse'
    },
    LeftMeeting: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '10%',
        gap: '10px',
        width: '523px',
        height: '243px',
        justifyContent: 'center',
        borderRadius: '21px',
        background: '#363636',
        padding: '12px',
        '@media only screen and (max-width: 425px)' :{
            borderRadius: '0px',
            background: 'black',
            width: '100%'
        }
    },
    JoinButton: {
        display: 'flex',
        gap: '10px',
        flexDirection: 'column-reverse'
    },
    RejoinBtn: {
        borderRadius: '8px',
        padding: '10px 80px 10px 80px',
        color: '#94A3B8',
        fontSize: '14px',
        fontWeight: 700,


    },
    HomeScreenBtn: {
        borderRadius: '8px',
        padding: '10px 80px 10px 80px',
        background: '#2563EB',
        color: 'white',
        fontSize: '14px',
        fontWeight: 700
    },
    HomescreenTextContainer: {
        display: 'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
        '@media only screen and (max-width: 768px)' :{
            display: 'none'
        }
    },
    LeftMeetText: {
        fontSize: '27px',
        fontWeight: 700,
        color: 'white',
        textAlign: 'center'
    },
    LeftMeetingContainer: {
        display: 'flex', 
        justifyContent: 'center', 
        flexDirection: 'row', 
        gap: '18px'
    },

    
})

export default style