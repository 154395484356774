import { baseAction } from "./baseAction";
import { workflowConstants, alertConstant } from "../constants";
import { workflowService } from "../services";
import { workflowHandler } from "../../utils/workflowHelper";

const { request, success, failure } = baseAction;

export const workflowActions = {
  getWorkflow,
  getWorkflows,
  createWorkflow,
  updateWorkflow,
  deleteWorkflow,
  executeWorkflows,
  clearWorkflows,
  getWorkflowsPublic,
  addWorkflowDraft,
  clearWorkflowDraft
};

/** get workflows */
function getWorkflow(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(workflowConstants.GET_WORKFLOW_R, params));
      workflowService.getWorkflows(params).then(data => {
        dispatch(success(workflowConstants.GET_WORKFLOW_S, data));
        callBack()
      }, error => {
        dispatch(failure(workflowConstants.GET_WORKFLOW_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function clearWorkflows() {
    return (dispatch) => {
      dispatch(request(workflowConstants.CLEAR_WORKFLOW_R, []));
    };
}

/** get workflows */
function getWorkflows(body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(workflowConstants.GET_WORKFLOWS_R, body));
      workflowService.getWorkflows(body).then(response => {
        dispatch(success(workflowConstants.GET_WORKFLOWS_S, response.data ? response.data : []));
        callBack(response)
      }, error => {
        dispatch(failure(workflowConstants.GET_WORKFLOWS_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/** get workflows */
function createWorkflow(body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(workflowConstants.CREATE_WORKFLOW_R, {data: body}));
      workflowService.createWorkflow(body).then(response => {
        dispatch(success(workflowConstants.CREATE_WORKFLOW_S, response.data));
        callBack(response.data)
      }, error => {
        dispatch(failure(workflowConstants.CREATE_WORKFLOW_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/** get workflows */
function updateWorkflow(params, body, customSave = false, callBack = () => { }) {
  return (dispatch) => {
    if(params['id']){
      if(customSave){
        dispatch(success(workflowConstants.UPDATE_WORKFLOW_S, { id: params['id'], ...body }));
        callBack()
      } else {
        dispatch(request(workflowConstants.UPDATE_WORKFLOW_R, {data: {params, body}}));
          workflowService.updateWorkflow(params, body).then(response => {
            dispatch(success(workflowConstants.UPDATE_WORKFLOW_S, response.data));
            callBack()
          }, error => {
            dispatch(failure(workflowConstants.UPDATE_WORKFLOW_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
          }
        );
      }
    } else {
      dispatch(success(workflowConstants.UPDATE_DRAFT_WORKFLOW_S, body));
      callBack()
    }
  };
}

/** get workflows */
function deleteWorkflow(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(workflowConstants.DELETE_WORKFLOW_R, {params}));
      workflowService.deleteWorkflow(params).then(response => {
        dispatch(success(workflowConstants.DELETE_WORKFLOW_S, response.data));
        callBack()
      }, error => {
        dispatch(failure(workflowConstants.DELETE_WORKFLOW_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/** get workflows */
function executeWorkflows(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(workflowConstants.EXECUTE_WORKFLOW_R, {params}));
      workflowService.executeWorkflow(params).then(response => {
        dispatch(success(workflowConstants.EXECUTE_WORKFLOW_S, response.data));
        workflowHandler(response.data)
        callBack()
      }, error => {
        dispatch(failure(workflowConstants.EXECUTE_WORKFLOW_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/** get workflows public route */
function getWorkflowsPublic(body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(workflowConstants.GET_WORKFLOWS_R, body));
      workflowService.getWorkflowsPublic(body).then(response => {
        dispatch(success(workflowConstants.GET_WORKFLOWS_S, response.data ? response.data : []));
        callBack()
      }, error => {
        dispatch(failure(workflowConstants.GET_WORKFLOWS_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

function addWorkflowDraft(draft) {
  return (dispatch) => {
    dispatch(success(workflowConstants.SAVE_WORKFLOW_DRAFT_S, draft));
  };
}

function clearWorkflowDraft() {
  return (dispatch) => {
    dispatch(success(workflowConstants.CLEAR_WORKFLOW_DRAFT_S));
  };
}

