import {
  CircularProgress,
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import style from "./style";

import AgoraRTC from "agora-rtc-sdk-ng";
import React, { useEffect, useRef, useState } from "react";
import {
  CallDurationIcon,
  CarouselIconLeft,
  CarouselIconRight,
  VideoCallCloseIcon,
  ScreenSharePopup,
  WarningIcon
  
} from "../../../assets/svg/videocall";
import VCLobby from "../VCLobby";
import VCMiniParticipantList from "../VCMiniParticipantList";
import VCParticipant from "../VCParticipant";
import history from "../../../config/history";
import { authAction, chatHubAction, chatv2Action, profileAction, userAgencyAction, meetingAction } from "../../../redux/actions";
import { videoCallAction } from "../../../redux/actions/videoCallAction";
import { alertAction } from "../../../redux/actions/alertAction";
import { joinVideoCall, muteAudio, muteVideo, reconnect, shareScreen, stopScreenShare, stopShareScreen ,startRecording, activeChannel } from "../../../utils/socketv2/index";
import { getDimentions } from "../../../utils/videoHelper";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { app_credentials } from "../../../config/app_credentials";
import { _api } from "../../../config/environment";
import VCMenu from "../VCMenu"
import VCLeavePage from "../VCLeave";
import VideoCallRecordingPopUp from "../VCRecordPopup/index"
import StopRecordPopup from "../VCRecordStopPopUp"
import { isMobile } from 'react-device-detect';
import moment from "moment";
import { PageLoader } from "../../../components";

// Do not mutate or spread(...) any objects from the agora client maually. Always use the exsisting agora client methods to do any actions.
const agoraClient = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});

function VideoCall(props) {
  const { classes, id, match, type } = props;
  const [joined, setJoined] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [talkingAttendees, setTalkingAttendees] = useState([])
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [localVideoTracks, setLocalVideoTracks] = useState({ videoTrack: null, audioTrack: null });
  const [videoElements, setVideoElements] = useState({});
  const [pinnedUserVideo, setPinnedUserVideo] = useState(false);
  const [requestSend, setRequestSend] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [screenShare, setScreenShare] = useState(null);
  const [screenTrack, setScreenTrack] = useState([]);
  const { videoCallRequest, remoteUsers,videoCallRecordStartResponse,isRecording } = useSelector((state) => state.videocall);
  const [screenShareUsers, setScreenShareUsers] = useState([]);
  const [screenVideo, setScreenVideo] = useState(false);
  const [mutedUsers, setMutedUsers] = useState([]);
  const [screen, setScreen] = useState(null)
  const [pinnedUsers, setPinnedUsers] = useState([])
  const [unpinnedUsers, setUnpinnedUsers] = useState([])
  const [unpinnedUserHide, setUnpinnedUserHide] = useState(true)
  const [token, setToken] = useState()
  const [optimalVideoDimention, setOptimalVideoDimention] = useState({ width: "100", height: "100" })
  const [showChat, setShowChat] = useState(false);
  const [userId, setUserId] = useState()
  const [username, setUsername] = useState('')
  const [avatar, setAvatar] = useState('')
  const [localUser, setLocalUser] = useState([])
  const [error, setError] = useState('');
  const [showStopSharing, setShowStopSharing] = useState(false);
  const [primaryAttendeeList, setPrimaryAttendeeList] = useState([])
  const [secondaryAttendeeList, setSecondaryAttendeeList] = useState([])
  const [validationTime, setValidationTime] = useState(null);
  const [showVCLeavePage, setShowVCLeavePage] = useState(false);
  const [networkErrPage, setNetworkErrorPage] = useState(false);
  const [isOpenRecordDialog,setIsOpenRecordDialog] = useState(false)
  const [openDialog,setOpenDialog] = useState(false)
  const [waitingShareResponse,setWaitingShareResponse] = useState(false)
  const [attempt,setAttempt] = useState(null)
  const [tryReconnecting, setTryReconnecting] = useState(null)
  const [volume, setVolume] = useState()
  const [screenLock, setScreenLock] = useState()
  const [screenSharePopup, setScreenSharePopup] = useState(false)
  const [cameraPermission, setCameraPermission] =useState(false)
  const [microphonePermission, setMicrophonePermission] = useState(false)
  const [checkCameraActive, setCheckCameraActive] = useState(false)
  const [checkMicrophoneActive, setCheckMicrophoneActive] = useState(false)
  const [activePopup, setActivePopup] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const {
    VideoCall: { APP_ID, APP_CERTIFICATE, CUSTOMER_KEY, CUSTOMER_SECRET },
  } = app_credentials;

  const { team } = useSelector((state) => state.chatHub);
  const { project } = useSelector((state) => state.chat);
  const { client } = useSelector((state) => state.chat);
  const {id:user_id,email} = useSelector((s) => s.auth.loginUser);
  const dispatch = useDispatch();
  const { requestId, channelStatus,videoCallStartState } = useSelector((state) => state.videocall);
  const { profile } = useSelector((s) => s.profile);
  const { channel } = useSelector((state) => state.chatv2);
  const { remoteuser } = useSelector((state) => state.videocall.remoteUsers);
  const screenShareVideo = useSelector(
    (state) => state.videocall.screenShareVideo
  );
  const { planDetails } = useSelector(s => (s.userAgency))
  const { selected_channel } = useSelector((state) => state.chatv2);

  const video = useSelector((state) => state.videocall.screenTrack);
  const { allUsers } = useSelector((s) => s.chatv2);
  const { stopShareScreen } = useSelector((s) => s.videocall)
  let userName = '';
  let userAvatar = ''
  const Location =   window.location.href
  const hasToken = localStorage.getItem("key");
  const userAgent = navigator.userAgent
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

  // Debounce Implementation
  let debounceTimer = null;

  const setDebounceValidator = () => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setValidationTime(new Date());
    }, 5000);
  }

  function generateThreeDigitId() {
    return (Math.floor(Math.random() * 900) + 100)
  }

useEffect(() => {
  const interval = setInterval(() => {
    activeChannel({
      message: "channel is active",
      userId: `${userId}`,
      channel : CHANNEL
    })
  }, 120000);

  return () => clearInterval(interval);
}, [attendees, channelStatus]);

  window['resetChannelState'] = (channelName, data) => {
    dispatch(meetingAction.updateChannelStatus({channelName, data}))
  };

  useEffect(() => {
    getChannelInfo();
    dispatch(chatv2Action.setChannel({ type, ChannelId }));
    if (hasToken) {
      const userTimeZone = moment.tz.guess();
      const params = {
          time_zone: userTimeZone
      }
      dispatch(authAction.getProfileData(params));
      dispatch(profileAction.getProfile())
    }
  }, [dispatch, type]);

  useEffect(() => {
    if (user_id) {
      setUserId(user_id);
      dispatch(chatHubAction.getAllUsers());
    } else {
      const newId = `public_${generateThreeDigitId()}`
      dispatch(videoCallAction.setPublicUserId(newId));
      setUserId(newId);
    }
  }, [user_id]);

  const generateAgoraToken = async () => {
    try {
      let uid = user_id
      if (!user_id) {
        uid = userId;
      }
      const response = await axios.post(_api.url + "/videocall/generateAgoraToken", {
        channelName: CHANNEL,
        uid,
        APP_ID: APP_ID,
        APP_CERTIFICATE: APP_CERTIFICATE,
        role: "publisher",
        tokentype: "userAccount",
      });
      const token = response.data.message.rtcToken;
      setToken(token)

      return token;
    } catch (error) {
      return null;
    }
  };

  //getInfo for join channel
  const channelId = () => {
    const idInfo = {
      teams: team.activeProject,
      clients: client.activeClient,
      projects: project.activeProject,
    };
    return idInfo[selected_channel.type] || "";
  };
  const ChannelId = channelId();

  const getChannelInfo = () => {
    const channelInfo = {
      teams: team.channelName && team.channelName[0]?.uuid,
      clients: client.channelName && client.channelName[0]?.uuid,
      projects: project.channelName && project.channelName[0]?.uuid,
    };
    return channelInfo[selected_channel.type] || "";
  };

  const getGroupName = () => {
    const groupNames = {
      teams: team.channelName && team.channelName[0]?.name,
      clients: client.channelName && client.channelName[0]?.client_name,
      projects: project.channelName && project.channelName[0]?.job_title,
    };
    return groupNames[selected_channel.type] || "";
  };

  const call_type = "meeting"
  const groupName = getGroupName();



  const channelInfo = channel[type] && channel[type];

  let CHANNEL = match.params.meetingId

  if (type) {
    CHANNEL = getChannelInfo()
  }

  const chatId = id;

  let matchingUserIds = [];
  let users = [];

  if (channel && channelInfo?.length > 0) {
    const matchingChannel = channelInfo.find(
      (item) => String(item.id) === String(chatId)
    );

    if (matchingChannel) {
      matchingUserIds = matchingChannel.users?.map((user) => user.id) || [];

      matchingUserIds = matchingChannel.users.map((user) => user.id) || [];
      users = matchingUserIds.filter((id) => id !== user_id);
    }
  }

  const [waitingMessageDisplayed, setWaitingMessageDisplayed] = useState(false);
  
  // Removing create channels as this should happen only in calander
  // useEffect(() => {
  //   if(Location){
  //     dispatch(videoCallAction.createFlozyRoom({channel_name:CHANNEL, videocall_link: Location }))
  //   }
  // },[Location])

  //user joined
  const handleUserJoined = async (user, mediaType) => {

    if (attendees.some((attendee) => attendee.user.uid === user.uid)) {
      return;
    }
    if ((userId || user_id) !== user.uid && mediaType) {
      await agoraClient.subscribe(user, mediaType);
    }

    let localAudioTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'audio');
    let localVideoTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'video');

    const currentUser = {
      uid: user_id || userId,
      videoTrack: localVideoTrack,
      audioTrack: localAudioTrack,
      hasVideo: localVideoTrack?.enabled,
      hasAudio: localAudioTrack?.enabled
    };

    setAttendees([...agoraClient.remoteUsers.filter(u => u.uid !== user_id && u.uid !== userId).map(u => {
      return {
        user: u,
        metadata: {}
      }
    }), {
      user: currentUser,
      metadata: {}
    }]);

    if (mediaType === "video") {
      if (!videoElements[user.uid]) {
        setVideoElements((prevVideoElements) => ({
          ...prevVideoElements,
        }));
      }
    }

    if (mediaType === "audio") {
      if (user.hasAudio) {
        if (!user.audioTrack) {
          await agoraClient.subscribe(user, 'audio');
        }
        user.audioTrack.play();
      }
      user.audioTrack.play();
    }

    if (mediaType === "video" && user?.videoTrack?._enabled === false) {
      setIsVideoMuted(true);
    }
  };


  const handleUserLeft = (user, reason) => {
    if (user) {
      setAttendees((previousAttendees) =>
        previousAttendees.filter((attendee) => attendee?.user?.uid !== user.uid)
      );
    }
    if (user?.videoTrack && user?.videoTrack.isScreen) {
      setScreenShareUsers((previousAttendees) =>
        previousAttendees.filter((attendee) => attendee?.user?.uid !== user.uid)
      );
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      if (!e.target.value.trim()) {
        setError('Please enter your name.');
      } else if (validateInput(e.target.value)) {
        setUsername(e.target.value);
        await joinCall();
      }
    }
  };

  
  const handleChange = (e) => {
    let value = e.target.value;
    if (value.length > 60) {
      value = value.slice(0, 60);
      setError('Please enter 60 characters only.');
    } else {
      setError('');
      setUsername(value);
    }
  };


  function isScreenLockSupported() {
    return ('wakeLock' in navigator);
  }

  async function getScreenLock() {
    if(isScreenLockSupported() && !isSafari){
        const  screenLock = await navigator.wakeLock.request();
        setScreenLock(screenLock)
      return screenLock;
    }
  }

  const validateInput = (input) => {
    const regex = /^[\w\s\S]{1,60}$/;
    return regex.test(input);
  };
 
  const checkCameraPermission =async () => {
    try{

      const devices = await AgoraRTC.getCameras();
      const videoDevices = devices.filter(device => device.kind === "videoinput");
      const selectedCameraId = videoDevices[0]?.deviceId;

      const [videoTrack] = await Promise.all([
        AgoraRTC.createCameraVideoTrack({
              cameraId: selectedCameraId,
              encoderConfig: "720p_3"
            })
        ])  

        await AgoraRTC.checkVideoTrackIsActive(videoTrack).then(result => {
          if(result === false){
            setCheckCameraActive(true)
              videoTrack.setEnabled(false)
            setIsVideoMuted(true)
          }else{
            setIsVideoMuted(false)
          }
        }).catch(e => {
          console.log("check video track error!", e);
        });
      
        setLocalVideoTracks(prevTracks => ({ ...prevTracks, videoTrack: videoTrack })); 
        setIsLoading(false)
    }catch(err){
      if(err.name === "NotFoundError" || err.message.includes("Requested device not found")){
        setCheckCameraActive(true)
        setIsVideoMuted(true)
        setIsLoading(false)
        }else{
         setCameraPermission(true)
         setIsVideoMuted(true)
         setIsLoading(false)
        }
      }

  }

  const checkMicrophonePermission =async () => {
    try{
      const devices = await AgoraRTC.getMicrophones();
      const audioDevices = devices.filter(device => device.kind === "audioinput");
      let selectedMicrophoneId = "default";
       if(!audioDevices.find(device => device.deviceId === "default")){
        selectedMicrophoneId = audioDevices[0]?.deviceId
      }

      const [audioTrack] = await Promise.all([
        AgoraRTC.createMicrophoneAudioTrack({
              microphoneId: selectedMicrophoneId,
              encoderConfig: "high_quality_stereo"
            })
          ])

      await AgoraRTC.checkAudioTrackIsActive(audioTrack).then(result => {
        if(result === false){
          setCheckMicrophoneActive(true)
          audioTrack.setEnabled(false)
          setAudioMuted(true)
        }else{
          setAudioMuted(false)
        }
      }).catch(e => {
        console.log("check audio track error!", e);
      });
    
        setLocalVideoTracks(prevTracks => ({ ...prevTracks, audioTrack: audioTrack })); 
        setIsLoading(false)
    }catch(err){
      if(err.name === "NotFoundError" || err.message.includes("Requested device not found")){
      setCheckMicrophoneActive(true)
      setAudioMuted(true)
      setIsLoading(false)
      }else{
        setMicrophonePermission(true)
        setAudioMuted(true)
       setIsLoading(false)
      }
    }

  }

  const agoraTracks = async () => {
      await checkMicrophonePermission()
      await checkCameraPermission()
  };

  useEffect(() => {
    getScreenLock()
    AgoraRTC.checkSystemRequirements()
    agoraTracks();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      checkChannelState(attendees, channelStatus);
    }, 6000);
  
    return () => clearInterval(interval); // Clean up on unmount
  }, [attendees, channelStatus]);
  
  const checkChannelState = (attendees, channelStatus) => {
    let shouldReconnect = false;
    attendees.forEach(attendee => {
      const userId = attendee.user.uid;
      if (joined && channelStatus[CHANNEL] && channelStatus[CHANNEL][userId]) {
      } else {
        shouldReconnect = true;
      }
    });
    if (shouldReconnect) {
      reconnect({
        channelName: CHANNEL,
        userId: userId || user_id
      })
    }
  }

  const addPublicUser = (userDetails) => {
    return new Promise(async (resolve, reject) => {
      dispatch(videoCallAction.addPublicUser(userDetails, (err) => err ? reject() : resolve()))
    });
  }

  useEffect(() =>{
    if(screenLock === 'undefined'){
      getScreenLock()
    }
  }, [])

  //request send
  const joinCall = async () => {
    dispatch(videoCallAction.checkUsers({channelName: CHANNEL, appid: APP_ID, customerkey: CUSTOMER_KEY, customersecret: CUSTOMER_SECRET, user_id: userId || user_id}, async(data) => {
      let { users, occurance } = data;
      let targetIndex = users?.findIndex(id => `${id}` === `${user_id}`)
      if (targetIndex > -1 || occurance > 0) {
        dispatch(alertAction.successAlert("You have already joined from a different device."))
      } else {

        if(!user_id && username.length > 0){
          await addPublicUser({
            userId: user_id || userId,
            channelId: CHANNEL,
            username: username
          });
        }

        if (!user_id && username.length === 0) {
          setError('Please enter your name.');
          return;
        }

        if (!user_id && username.length > 60) {
          setError('Please enter 60 characters only.');
          return;
        } else {

          setJoined(true);
          setRequestSend(true);

          await agoraClient.join(APP_ID, CHANNEL, token, `${user_id || userId}`);


          if (audioMuted && isVideoMuted) {
            // await agoraClient.publish()
          } else if (isVideoMuted) {
            await agoraClient.publish([localVideoTracks.audioTrack])
          } else if (audioMuted) {
            await agoraClient.publish([localVideoTracks.videoTrack])
          } else {
            await agoraClient.publish([localVideoTracks.audioTrack, localVideoTracks.videoTrack]);
          }

          const localVideoTrack = localVideoTracks.videoTrack;
          const localAudioTrack = localVideoTracks.audioTrack;

          if (user_id && profile) {
            userName = profile?.username;
            userAvatar = profile?.avatar_filename
          } else if (username) {
            userName = username;
          }

          setAttendees((previousAttendees) => [
            ...previousAttendees.filter(attendee => attendee.user.uid !== user_id && attendee.user.uid !== userId),
            {
              user: {
                uid: user_id || userId,
                videoTrack: localVideoTrack,
                audioTrack: localAudioTrack,
                hasVideo: localVideoTrack ? localVideoTrack.enabled : false,
                hasAudio: localAudioTrack ? localAudioTrack?.enabled : false
              },
              metadata: {
                videoMuted: localVideoTrack?.enabled,
                audioMuted: localAudioTrack?.enabled
              }
            },
          ]);

          dispatch(
            videoCallAction.setRemoteUsers([
              {
                uid: userId || userId,
                videoTrack: localVideoTrack,
                audioTrack: localAudioTrack,
                videoMuted: localVideoTrack ? localVideoTrack.enabled : false,
                audioMuted: localAudioTrack ? localAudioTrack?.enabled : false
              },
            ])
          );

          dispatch(
            videoCallAction.joinRequestSuccess(requestId)
          );

          joinVideoCall({
            userId: user_id || userId,
            channelName: CHANNEL,
            type: type,
            callType: "meeting",
            isScreenShared: false,
            username: userName,
            avatar: userAvatar,
            // 1 - private 2 - public
            userType: user_id ? 1 : 2
          });

          dispatch(
            videoCallAction.setRemoteUsers([
              {
                uid: userId || userId,
                videoTrack: localVideoTrack,
                audioTrack: localAudioTrack,
                videoMuted: localVideoTrack?.enabled,
                audioMuted: localAudioTrack?.enabled
              },
            ])
          );
        }
      }
    }));
  };

  const handleException = async (evt) => {
    if (evt.downlinkNetworkQuality === 6 && evt.uplinkNetworkQuality === 6) {
      setTryReconnecting(null)
      setTimeout(async () => {
        agoraClient.localTracks.forEach((track) => {
          if (track.trackMediaType === 'video' || track.trackMediaType === 'audio') {
            track.setEnabled(false);
          }
        });

        // Unsubscribe from remote users
        agoraClient.remoteUsers.forEach(async (user) => {
          if (user?.audioTrack) {
            user.audioTrack.stop();
          }
          await agoraClient.unsubscribe(user);
        });

        setNetworkErrorPage(true);
        setShowVCLeavePage(true)
      }, 5000)
      
    }else if (evt.downlinkNetworkQuality >=4 && evt.uplinkNetworkQuality >= 4){
      setTryReconnecting(evt.downlinkNetworkQuality)
      reconnect({
        uid: userId || user_id,
        channelName : CHANNEL
      })
    } else {
      setTryReconnecting(null)
    }

  }
  window.onpopstate = function(event) {
    if(isRecording && parseInt(videoCallRecordStartResponse?.recordStartedUid) === parseInt(user_id)){
      stopRecording()
    }
    handleLeave()
  }

 
  useEffect(() => {

    if (CHANNEL && userId) {
      generateAgoraToken()
    }

    agoraClient.on('user-joined', handleUserJoined)
    agoraClient.on("user-published", handleUserJoined);
    agoraClient.on("user-left", handleUserLeft);
    agoraClient.on("user-unpublished", handleUnPublish);
    agoraClient.on('network-quality', handleException);

    return () => {
      agoraClient.off('user-joined', handleUserJoined)
      agoraClient.off("user-published", handleUserJoined);
      agoraClient.off("user-left", handleUserLeft);
      agoraClient.off("user-unpublished", handleUnPublish);
      agoraClient.off('network-quality', handleException);
    };
  }, [CHANNEL, userId ]);

  useEffect(() => {
    if (profile && profile.username) {
      setUsername(profile?.username)
    }
  }, [profile])

  useEffect(() => {

    // Using this to find the person talking
    agoraClient.enableAudioVolumeIndicator();

    agoraClient.on("volume-indicator", volumes => {
      setVolume(volumes)
    })
    return () => {
      agoraClient.off("volume-indicator");
    };
  },[audioMuted])

  const handleUnPublish = (user) => {
    updateUsers();
  };

  const updateUsers = () => {
    const localVideoTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'video');
    const localAudioTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'audio');
    const currentUser = {
      uid: user_id || userId,
      videoTrack: localVideoTrack,
      audioTrack: localAudioTrack,
      hasVideo: localVideoTrack?.enabled,
      hasAudio: localAudioTrack?.enabled
    };

    const updatedAttendees = [...agoraClient.remoteUsers.filter(u => u.uid !== user_id && u.uid !== userId).map(u => {
      return {
        user: u,
        metadata: {}
      }
    }), {
      user: currentUser,
      metadata: {
        videoMuted: !localVideoTrack?.enabled,
        audioMuted: !localAudioTrack?.enabled
      }
    }]
    setAttendees(updatedAttendees);
  }

  function release() { 
    screenLock?.release()
  }

  //leave call
  const handleLeave = async () => {

    if (joined) {
     await release()

     if( isRecording && parseInt(videoCallRecordStartResponse?.recordStartedUid) === parseInt(user_id) ){
      recordDialogHandler()
     }else{
      
        if(screenShare){
        await handleStopSharing()

      }

      // if(attendees.length === 1) {
      //   await dispatch(videoCallAction.deleteFlozyRoom({ channel_name: CHANNEL }))
      // }

      agoraClient.localTracks.forEach((track) => {
        if (track.trackMediaType === 'video' || track.trackMediaType === 'audio') {
          track.setEnabled(false);
        }
      });

      if (localVideoTracks?.audioTrack?._enabled === true) {
        localVideoTracks.audioTrack.setEnabled(false)
      }

      if (localVideoTracks?.videoTrack?._enabled === true) {
        localVideoTracks.videoTrack.setEnabled(false)
      }


      const user_type = !user_id ? 1 : 0
      await dispatch(videoCallAction.deleteUser({userId : userId, channelId : CHANNEL, user_type}))

      if(agoraClient.localTracks.length > 0){
        await agoraClient.unpublish(agoraClient.localTracks)
      }
      await agoraClient.leave()
     
      const filteredAttendees = attendees.filter(attendee => attendee?.user?.uid !== user_id && attendee?.user?.uid !== userId);
      setAttendees(filteredAttendees);

      setLocalUser([])
      setShowVCLeavePage(true)
    }
    } else {  

      if(!isSafari){
        await release()
      }

      localUser?.videoTrack?.close()
      localUser?.audioTrack?.close()
      setJoined(false)

      if (user_id) {
        history.push('/calendar');
        window.location.reload()
      } else {
        history.push('/login')
      }
    }



  };

  // mute and unmute video
  const handleVideoToggle = async () => {
    if(checkCameraActive){
    setActivePopup(true)
     await recordDialogHandler()
    }else if(!joined){
      if(localVideoTracks.videoTrack !== null){
      await localVideoTracks.videoTrack.setEnabled(!localVideoTracks.videoTrack.enabled);
      setIsVideoMuted(!localVideoTracks.videoTrack.enabled);
      }else{
      const cameraPermission = await navigator.permissions.query({ name: 'camera' });
      if (cameraPermission.state === 'denied') {
        setCameraPermission(true)
      } else {
        setCameraPermission(false);
      }
    }
    }else{
    if (localVideoTracks?.videoTrack !== null) {
      if (!screenShare) {
      if(localVideoTracks?.videoTrack?.enabled === false){
      setIsVideoMuted(false)
      }else{
        setIsVideoMuted(true)
      }
     
        let localVideoTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'video') || localVideoTracks.videoTrack
        await localVideoTrack?.setEnabled(!localVideoTrack?.enabled)
        const updatedRemoteUsers = attendees.map(attendee => {
          let user = attendee.user;
          if (user.uid === user_id || user.uid === userId) {
            let modifiedAttendee = attendee;
            modifiedAttendee.metadata = {
              ...modifiedAttendee.metadata,
              videoMuted: !localVideoTrack?.enabled
            }
            if (!user.videoTrack) {
              modifiedAttendee.user = {
                ...modifiedAttendee.user,
                videoTrack: localVideoTrack
              }
              if (!user.videoTrack && joined) {
                agoraClient.publish([localVideoTrack]);
              }
            }
            modifiedAttendee.user['hasVideo'] = localVideoTrack?.enabled
            return modifiedAttendee;
          }
          return attendee;
        });
        setAttendees(updatedRemoteUsers);

      }
    } else {
      const cameraPermission = await navigator.permissions.query({ name: 'camera' });
      if (cameraPermission.state === 'denied') {
        setCameraPermission(true)
      } else {
        setCameraPermission(false);
        const videoTrack = await AgoraRTC.createCameraVideoTrack()
        await agoraClient.publish([videoTrack])
        setLocalVideoTracks(prevTracks => ({ ...prevTracks, videoTrack: videoTrack })); 
        setIsVideoMuted(false)
        setAttendees((attendees) => {
          return attendees.map((attendee) => {
            if (attendee.user.uid === user_id) {
              return {
                ...attendee,
                user: {
                  ...attendee.user,
                  videoTrack: videoTrack,
                  hasVideo: videoTrack.enabled,
                },
                metadata: {
                  ...attendee.metadata,
                  videoMuted: !videoTrack.enabled,
                }
              };
            } else {
              return attendee;
            }
          })
        })
      }
    }
  };
}

  //mute and unmute audio
  const handleAudioMute = async () => {
    if(checkMicrophoneActive){
      setActivePopup(true)
      await recordDialogHandler()
      return
     }else if(!joined){
      if(localVideoTracks.audioTrack !== null){
        await localVideoTracks.audioTrack.setEnabled(!localVideoTracks.audioTrack.enabled);
        setAudioMuted(!localVideoTracks.audioTrack?.enabled);
        }else{
        const microphonePermission = await navigator.permissions.query({ name: 'camera' });
        if (microphonePermission.state === 'denied') {
          setMicrophonePermission(true)
        } else {
          setMicrophonePermission(false);

        }
      }  
    }else{
    if (localVideoTracks?.audioTrack !== null) {
      if(localVideoTracks?.audioTrack?.enabled === false){
        setAudioMuted(false)
        }else{
          setAudioMuted(true)
        }
        let localAudioTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'audio') || localVideoTracks.audioTrack
        await localAudioTrack?.setEnabled(!localAudioTrack.enabled)
        if (localAudioTrack?.enabled && !localAudioTrack?._published && joined) {
          await agoraClient.publish([localAudioTrack]);
        }
        const updatedRemoteUsers = attendees.map(attendee => {
          let user = attendee.user;
          if (user.uid === user_id || user.uid === userId) {
            let modifiedAttendee = attendee;
            modifiedAttendee.user = {
              ...modifiedAttendee.user,
              hasAudio: localAudioTrack?.enabled
            };
            if (!user.audioTrack) {
              modifiedAttendee.user = {
                ...modifiedAttendee.user,
                audioTrack: localAudioTrack
              }
              // if (!user.audioTrack && joined) {
              //   agoraClient.publish([localAudioTrack]);
              // }
            }
            modifiedAttendee.metadata['audioMuted'] = localAudioTrack?.enabled;
            return modifiedAttendee;
          }
          return attendee;
        });
        setAttendees(updatedRemoteUsers);
    } else {
      const microphonePermission = await navigator.permissions.query({ name: 'microphone' });
      if (microphonePermission.state === 'denied') {
        setMicrophonePermission(true)
      } else {
        setMicrophonePermission(false)
        const audioTrack = await AgoraRTC.createMicrophoneAudioTrack()
        await agoraClient.publish([audioTrack])
        setLocalVideoTracks(prevTracks => ({ ...prevTracks, audioTrack: audioTrack })); 
        setAudioMuted(false)
        setAttendees((attendees) => {
          return attendees.map((attendee) => {
            if (attendee.user.uid === user_id) {
              return {
                ...attendee,
                user: {
                  ...attendee.user,
                  audioTrack: audioTrack,
                  hasAudio: audioTrack.enabled,
                },
                metadata: {
                  ...attendee.metadata,
                  audioMuted: !audioTrack.enabled,
                }
              };
            } else {
              return attendee;
            }
          })
        })
      }
    }
  };
}


  useEffect(() => {
    if (joined && attendees.length >= 2 && !startTime) {
      setStartTime(new Date());

      const id = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);

      setIntervalId(id);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [joined, startTime, intervalId, attendees]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleSharingSuccess = () => {
    setWaitingShareResponse(false); 
  }

  const retryScreenShareEmit = (attempt) => {
    if(screenShare && waitingShareResponse && (attempt < 3)){
      let scObject = {
        uid: user_id || userId,
        receiver_id: null,
        channelName: CHANNEL,
        type,
        call_type,
        isScreenShared: true
      };
      shareScreen(scObject, handleSharingSuccess);
      setTimeout(() => {
        setAttempt((prev) => prev + 1)
      }, 6000)
    } else {
      setAttempt(null)
    }
  }

  useEffect(() => {
    if(attempt !== null){
      retryScreenShareEmit(attempt);
    }
  }, [attempt])

  useEffect(() => {
    if(screenShare && waitingShareResponse){
      setAttempt(0)
    }
  }, [waitingShareResponse, screenShare])

  const handleScreenShare = async (uid) => {
    if(isMobile){
      setScreenSharePopup(!screenSharePopup)
    }else{
    if (screenShare) {
      handleStopSharing()
    } else {
      setScreenShare(true);
      setPinnedUserVideo(true)

      const existingVideoTrack = localVideoTracks.videoTrack

      if (existingVideoTrack) {
        await agoraClient.unpublish(existingVideoTrack);
      }

      try {
        const screenShareTrack = await AgoraRTC.createScreenVideoTrack();
        let scObj;
        await agoraClient.publish([screenShareTrack]);

        if (screen === false && stopShareScreen !== null && video !== null) {
          setScreen(null)
        }

        let localScreenTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'video')

        localScreenTrack.on('track-ended', handleStopSharing)

        scObj = {
          uid: user_id || userId,
          receiver_id: null,
          channelName: CHANNEL,
          type,
          call_type,
          isScreenShared: true
        }

        setWaitingShareResponse(true); 
        shareScreen(scObj, handleSharingSuccess);

        setScreenTrack([screenShareTrack]);

        dispatch(
          videoCallAction.setScreenShareVideo([
            {
              uid: user_id || userId,
              screenTrack: screenShareTrack,
            },
          ])
        );


        const stopSharingButton = Array.from(document.querySelectorAll("button")).find(
          (button) => button.textContent.trim() === "Stop Sharing"
        );

        if (stopSharingButton) {
          stopSharingButton.addEventListener("click", handleStopSharing);
        }

        screenShareTrack.getMediaStreamTrack().addEventListener("ended", handleStopSharing);
        handleUserJoined({ uid: user_id || userId, screenTrack: screenShareTrack }, "video");

      } catch (err) {
        if (err.message.includes("Permission denied")) {
          setScreenShare(false);
          if (!isVideoMuted) {
            agoraClient.publish(existingVideoTrack);
          }
        }
      }
    }
  }
  };

  useEffect(() => {
    const handleClickOutside = () => {
      const popup = document.getElementsByClassName('ScreenSharePopupContainer')
      if (popup) {
        setScreenSharePopup(false);
      }

      const PermissionPopup = document.getElementsByClassName('PermissionPopup')
      if (PermissionPopup) {
        setCameraPermission(false);
        setMicrophonePermission(false)
      }
    };

    setTimeout(() =>{
      if(cameraPermission){
        setCameraPermission(false);
      }
     if(microphonePermission){
      setMicrophonePermission(false)
     }
    },5000)

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [screenSharePopup, cameraPermission, microphonePermission]);

  
  const handleStopSharing = async () => {
    let localScreenTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'video') || localVideoTracks.videoTrack
    localScreenTrack.off('track-ended', handleStopSharing)

    setScreenShare(false)

    if (stopShareScreen !== null) {
      setScreen(false);
    }

    if (localScreenTrack) {
      await localScreenTrack.close();
      await agoraClient.unpublish(localScreenTrack);
    }

    await stopScreenShare({
      sender_id: user_id || userId,
      receiver: users,
      channelName: CHANNEL,
      call_type
    })

    if (!isVideoMuted) {
      await agoraClient.publish([localVideoTracks.videoTrack])
    }

    UnPinUser(user_id || userId);

    updateUsers();
  };


  useEffect(() => {

    if (video !== null) {
      PinUser(video.uid)
    }

    if (stopShareScreen) {
      setPinnedUserVideo(false);
    }


  }, [stopShareScreen, video,]);



  let userAvatars = [];

  if (attendees && attendees.length > 0) {
    attendees.filter((participant) => {
      const matchedUser = allUsers.find(
        (user) => user.id === participant.uid
      );
      if (matchedUser) {
        userAvatars.push({ avatar: matchedUser.avatar_filename, id: matchedUser.id });
      }
    })

  }
  const [parentWidth, setParentWidth] = useState()

  // Hard coded should be dynamically calculated in future
  let itemsPerPage ;
  parentWidth > 768 ? itemsPerPage = 25 : itemsPerPage = 8

  const  totalPages = Math.ceil(attendees.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(0);

  const [secondaryCurrentPage, setSecondaryCurrentPage] = useState(0);
  const [newItemsPerPage, setNewItemsPerPage] = useState(1)

  const videoContainer = useRef(null);
  const parentContainer = useRef(null);
  const attendeeListContainer = useRef(null);

  const setItemsPerPage = () => {
    if (attendeeListContainer.current) {
      const containerWidth = attendeeListContainer.current.offsetWidth;
      // Added additional pixels to compensate paddings
      const elementWidth = 190;
      const itemsPerPage = Math.floor(containerWidth / elementWidth);
      setNewItemsPerPage(itemsPerPage)
    }
  }

  useEffect(() => {
    const observer = new ResizeObserver(setItemsPerPage);
    if (attendeeListContainer.current) {
      observer.observe(attendeeListContainer.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);


  function showPage(page) {
    let start, end
    if (secondaryAttendeeList.length > 0) {
      setCurrentPage(0);
      start = 0;
      end = itemsPerPage;
    } else {
      start = page * itemsPerPage;
      end = start + itemsPerPage;
    }
    if(videoContainer?.current?.children){
      const videoContainerArray = Array.from(videoContainer?.current?.children);
      videoContainerArray.forEach((video, index) => {
        if (index >= start && index < end) {
          video.style.display = 'block';
        } 
      });
    }
  }

  const start = currentPage * itemsPerPage;
  const end = Math.min((currentPage + 1) * itemsPerPage, attendees.length);


  const handleNextPage = () => {
    const nextPage = currentPage + 1 >= totalPages ? 0 : currentPage + 1;
    setCurrentPage(nextPage);
    showPage(nextPage);
  };

  const handlePrevPage = () => {
    const prevPage = currentPage - 1 < 0 ? totalPages - 1 : currentPage - 1;
    setCurrentPage(prevPage);
    showPage(prevPage);
  };

  useEffect(() => {
    showPage(currentPage);
  }, [secondaryAttendeeList, attendees]);

 // start record acquire function

 const recordDialogHandler = ()=>{
  setIsOpenRecordDialog(true)
  setOpenDialog(true)
 }

 function handleRecordSocketEvent(){
  if(channelStatus[CHANNEL] &&
    channelStatus[CHANNEL][user_id])
    acquireStateValues = channelStatus[CHANNEL][user_id]
    startRecording({channelName:acquireStateValues?.channelId})
 }

 let acquireStateValues;
 const createRecordAcquire = ()=>{
  if(channelStatus[CHANNEL] &&
    channelStatus[CHANNEL][user_id])
    acquireStateValues = channelStatus[CHANNEL][user_id]
    dispatch(videoCallAction.getRecordingAcquire({ cname: acquireStateValues?.channelId, uid: acquireStateValues?.userId, token: token, app_id: APP_ID, appCertificate :  APP_CERTIFICATE,apiUrl: _api.appUrl, key: CUSTOMER_KEY, secret: CUSTOMER_SECRET }, () => {
      handleRecordSocketEvent()
    }))
  setIsOpenRecordDialog(false)
 }

 const stopRecording = ()=>{
  let acquireStateValues
   if(videoCallRecordStartResponse){
    
    acquireStateValues = channelStatus[CHANNEL][user_id]
    dispatch(videoCallAction.stopRecording(
      {authorizationField:videoCallRecordStartResponse?.authorizationField,
        app_id:APP_ID,
        resourceId:videoCallRecordStartResponse?.start?.resourceId,
        sid:videoCallRecordStartResponse?.start?.sid,
        cname:acquireStateValues?.channelId,
        uid:'786',
        email,
        tb_record_user_id:user_id
      }, ()=>{handleRecordSocketEvent()}))
      dispatch(videoCallAction.stopVideocallRecording())
   }
   setIsOpenRecordDialog(false)
 }
  function renderPaginationButtonRight() {
    return (
      attendees.length > itemsPerPage && currentPage !== totalPages - 1 && secondaryAttendeeList.length === 0 && (
        <div className={classes.pagination} id="pagination" onClick={handleNextPage}>
         <CarouselIconRight />
        </div>
      )
    );
  }

  function renderPaginationButtonLeft() {
    return (
      attendees.length > itemsPerPage && currentPage !== 0 && secondaryAttendeeList.length === 0 &&(
        <div className={classes.paginationLeft} id="pagination" onClick={handlePrevPage}>
          <CarouselIconLeft /> 
        </div>
      )
    );
  }

  const PinUser = (uid) => {
    setPinnedUserVideo(true);
    if (pinnedUsers.includes(uid)) {
      // Remove the user from the pinned list
      setPinnedUsers((prevPinnedUsers) => prevPinnedUsers.filter((user) => user !== uid));
    } else {
      // Pin the user by adding them to the pinned list
      setPinnedUsers((prevPinnedUsers) => [...prevPinnedUsers, uid]);
    }

    setUnpinnedUsers((prevUnpinnedUsers) => {
      // Remove the pinned user from the unpinned list
      const updatedUnpinnedUsers = prevUnpinnedUsers.filter((user) => user !== uid);
      // Add all other remote users who are not pinned to the unpinned list
      const otherRemoteUsers = attendees.filter((user) => user.uid !== uid).map((user) => user.uid);

      return [...updatedUnpinnedUsers, ...otherRemoteUsers];
    });
  };

  const UnPinUser = (uid) => {
    setPinnedUsers((prevPinnedUsers) => prevPinnedUsers.filter((user) => user !== uid));
    if (pinnedUsers.length <= 1) {
      setPinnedUserVideo(false)
    }
  }
  function expandUsers() {
    setUnpinnedUserHide(!unpinnedUserHide);
  }

  const resubscribe = async (uid, mediaType = 'video') => {
    const targetUser = agoraClient.remoteUsers.find((user) => user.uid === uid)
    await agoraClient.subscribe(targetUser, mediaType);
    updateUsers();
  }

  const handleResize = () => {
    if (parentContainer.current?.offsetWidth) {
      let attendeeSize = 0;
      const parentDimentions = {
        width: parentContainer.current?.offsetWidth,
        height: parentContainer.current?.offsetHeight - attendeeSize,
      }

      setParentWidth(parentDimentions.width)
      if(parentDimentions.width > 768){
        const { width, height } = getDimentions(parentDimentions, "16:9", videoContainer.current.children.length || 0);
        setOptimalVideoDimention({ width, height });
      }else if(parentWidth < 768 && videoContainer.current.children.length === 1){
        const { width, height } = getDimentions(parentDimentions, "1:2", videoContainer.current.children.length || 0);
        setOptimalVideoDimention({ width, height });
      }else if(parentWidth === 768){
        const { width, height } = getDimentions(parentDimentions, "4:3", videoContainer.current.children.length || 0);
        setOptimalVideoDimention({ width, height });
      } else{
        const { width, height } = getDimentions(parentDimentions, "1:1", videoContainer.current.children.length || 0);
        setOptimalVideoDimention({ width, height });
      }

    }
  }

  useEffect(() => {
    const observer = new ResizeObserver(() => { handleResize() });
    const attendeeObserver = new ResizeObserver(() => { handleResize() });
    observer.observe(parentContainer.current)
    attendeeObserver.observe(attendeeListContainer.current)
    return () => {
      observer.disconnect();
      attendeeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    handleResize()
  }, [attendees, joined, primaryAttendeeList, secondaryAttendeeList, totalPages, parentWidth, currentPage, !showVCLeavePage])

  useEffect(() => {
    let primaryList = [];
    let secondaryList = [];
    attendees.forEach((attendee) => {
      let user = attendee.user;
      let modifiedAttendee = attendee;
      let screenShareProps = {
        screenShare: true
      }
      if (channelStatus[CHANNEL] &&
        channelStatus[CHANNEL][user.uid] &&
        channelStatus[CHANNEL][user.uid]['isScreenShared']
      ) {
        if (user.uid === user_id || user.uid === userId) {
          let targetScreenShare = screenShareVideo.filter(u => u.uid === user_id || u.uid === userId);
          targetScreenShare = targetScreenShare[0];
          if (!user.screenTrack && targetScreenShare['screenTrack']) {
            screenShareProps['screenTrack'] = targetScreenShare['screenTrack'];
          }
          modifiedAttendee.metadata = { ...modifiedAttendee.metadata, ...screenShareProps }
          primaryList.push(modifiedAttendee);
        } else {
          modifiedAttendee.metadata = { ...modifiedAttendee.metadata, ...screenShareProps }
          primaryList.push(modifiedAttendee);
        }
      } else {
        screenShareProps.screenShare = false;
        screenShareProps['screenTrack'] = null;
        modifiedAttendee.metadata = { ...modifiedAttendee.metadata, ...screenShareProps }
        secondaryList.push(attendee);
      }
    })

    if (primaryList.length === 0) {
      primaryList = [...secondaryList];
      secondaryList = [];
    }
    setPrimaryAttendeeList(()=>{
      return primaryList?.filter((list)=>{
        return  !list?.user?.uid.toString().includes('recording')
       })
     });
     setSecondaryAttendeeList(()=>{
      return secondaryList?.filter((list)=>{
         return  !list?.user?.uid.toString().toLowerCase().includes('recording')
        })
     });

    // check attendee status after 5mins
    setDebounceValidator();
  }, [channelStatus, attendees])

  window.addEventListener("beforeunload", function(event) {
  
});


  return (
    <>
      {isLoading && <PageLoader isLoading={true}/>}
      {!showVCLeavePage && <Grid
        container
        className={classes.VideoCallContainer}
      >
          {tryReconnecting === 5 &&
            <Grid item className={classes.CallHeaderWithLoader}>
                <Grid container >
                  <Grid item className="relative">
                      <CircularProgress 
                        variant="determinate"
                        className={classes.bottomLoader}
                        size={20}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress 
                        variant="indeterminate"
                        disableShrink
                        className={classes.topLoader}
                        classes={{
                          circle: classes.circleLoader,
                        }}
                        size={20}
                        thickness={4}
                      />
                  </Grid>
                  <Grid item className={'pl-1'}>
                    <Typography variant="body1" className={classes.NameText}>{parentWidth > 768 ? "You lost your network connection. Trying to reconnect." : "No internet. Try to reconnect"}</Typography>
                  </Grid>
                </Grid>
            </Grid>
          } 
        {
          (cameraPermission || microphonePermission) && <Grid item className={classes.PermissionPopup}>
            <WarningIcon /> <Typography variant="body1" className={classes.NameText}>{cameraPermission ? "Enable camera access in your browser." : "Enable microphone access in your browser"}</Typography>
          </Grid>
        }
        <Grid className={classes.CallHeader}>
          <Typography variant="h4" className={classes.userName}>
            {videoCallRequest?.groupName || groupName}
          </Typography>
          <Grid style={{width:"250px",height:"80px"}}>
          {isRecording
             ? 
            <StopRecordPopup
            openDialog = {openDialog}
            onStopRecording={stopRecording}
            videoCallStartState={videoCallStartState}
            videoCallRecordStartResponse={videoCallRecordStartResponse}
            user_id={user_id}
            isRecording={isRecording}
            joined={joined}
            /> : null
          }
           </Grid>
        </Grid>
       
        <Grid
          className={`${classes.participantWrapper} ${secondaryAttendeeList.length > 0 ? (unpinnedUserHide ? classes.listExpandHeight : classes.listCollapseHeight) : classes.fullHeight}`}
          ref={parentContainer}
        >
          <Grid
            item
            className={classes.VideoContainer}
            ref={videoContainer}
          >
            {!joined && (
              <>
                {!hasToken ? <Grid className={classes.JoinRequesInputBox}>
                  <Typography className={classes.NameText}>What's your name?</Typography>
                  <input className={classes.InputBox} placeholder="Your name"
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    maxLength={61} 
                    value={username}
                    />
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: "100%" }}>
                    {error && <div style={{ color: 'red', width: "100%", fontSize: "12px" }}>{ error}</div>}
                    <Typography className={classes.userNameLengthContainer}>{username.length} / 60</Typography>
                  </div>
                </Grid> : <Grid className={classes.JoinTextContainer}><Typography style={{ color: 'white', fontSize: '20px', fontWeight: 800 }}>Ready to join?</Typography></Grid>}
                <VCLobby
                  optimalVideoDimention={optimalVideoDimention}
                  AgoraRTC={AgoraRTC}
                  isVideoMuted={isVideoMuted}
                  user_id={user_id}
                  setLocalUser={setLocalUser}
                  profile={profile}
                  localUser={localUser}
                  audioMuted={audioMuted}
                  setIsVideoMuted={setIsVideoMuted}
                  username={username}
                  // audioTrack={audioTrack}
                  // videoTrack={videoTrack}
                  localVideoTracks={localVideoTracks}
                  parentWidth={parentWidth}
                />
              </>
            )}
            {joined ? (
              <>
                {primaryAttendeeList.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage).map((attendee, i) => (
                  <VCParticipant
                    key={`${attendee.user.uid}_${i}`}
                    user={attendee.user}
                    userMetaData={attendee.metadata}
                    ref={(ref) => (videoElements[attendee.user.uid] = ref)}
                    isVideoMuted={attendee.user.videoMuted}
                    user_id={user_id || userId}
                    joined={joined}
                    avatar={profile.avatar_filename}
                    mutedUsers={mutedUsers}
                    userAvatar={userAvatars && userAvatars[0] && userAvatars[0].id === attendee.user.uid ? userAvatars[0].avatar : ""}
                    allUsers={allUsers}
                    attendees={attendees}
                    profile={profile}
                    videoWidth={optimalVideoDimention.width}
                    videoHeight={optimalVideoDimention.height}
                    PinUser={PinUser}
                    audioMuted={attendee.user.audioMuted}
                    screenTrack={attendee.metadata['screenTrack']}
                    screenShare={attendee.metadata['screenShare']}
                    channelStatus={channelStatus}
                    talkingAttendees={talkingAttendees}
                    validationTime={validationTime}
                    channelName={CHANNEL}
                    resubscribe={resubscribe}
                    parentWidth={parentWidth}
                    volume={volume}
                  />
                ))}
              </>
            ) : null}{" "}
          </Grid>
          <VCMenu
            handleVideoToggle={handleVideoToggle}
            isVideoMuted={isVideoMuted}
            handleLeave={handleLeave}
            handleAudioMute={handleAudioMute}
            handleScreenShare={handleScreenShare}
            audioMuted={audioMuted}
            user_id={userId}
            joined={joined}
            joinCall={joinCall}
            remoteUser={attendees?.users?.find((user) => user.uid === userId)}
            localUser={localUser}
            screenShare={screenShare}
            getAcquireFun={recordDialogHandler}
            stopRecording= {stopRecording}
            openDialog={isRecording}
            checkCameraActive={checkCameraActive}
            checkMicrophoneActive={checkMicrophoneActive}
          />
        </Grid>
        <Grid
          className={secondaryAttendeeList.length > 0 && `${classes.attendeeListContainer} ${classes.attendeeListContainer}${!unpinnedUserHide ? "__hide" : "__open"}`}
          ref={attendeeListContainer}
        >
          {/* Bottom mini list when screen is shared or user is pinned */}
          {secondaryAttendeeList.length > 0 && (
            <VCMiniParticipantList
              showChat={showChat}
              unpinnedUserHide={unpinnedUserHide}
              expandUsers={expandUsers}
              remoteAttendee={secondaryAttendeeList}
              unpinnedUsers={unpinnedUsers}
              videoElements={videoElements}
              joined={joined}
              profile={profile}
              mutedUsers={mutedUsers}
              allUsers={allUsers}
              PinUser={PinUser}
              UnPinUser={UnPinUser}
              user_id={userId}
              channelStatus={channelStatus}
              itemsPerPage={newItemsPerPage}
              channelName={CHANNEL}
              resubscribe={resubscribe}
              parentWidth={parentWidth}
              volume={volume}
            />
          )}
        </Grid>
        {renderPaginationButtonRight()}
        {renderPaginationButtonLeft()}
      </Grid>}
      {screenSharePopup &&
        <Grid className={classes.ScreenSharePopupContainer}>
          <Typography>Screen Sharing</Typography>
          <Typography className={classes.ScreenSharePopupContent}><ScreenSharePopup />Currently not supported in mobile</Typography>
        </Grid>
      }

      {showVCLeavePage &&
        <VCLeavePage
          user_id={user_id}
          classes={classes}
          setShowVCLeave={setShowVCLeavePage}
          showVCLeave={showVCLeavePage}
          handleUserLeft={handleUserLeft}
          isVideoMuted={isVideoMuted}
          setIsVideoMuted={setIsVideoMuted}
          audioMuted={audioMuted}
          setAudioMuted={setAudioMuted}
          agoraClient={agoraClient}
          networkErrPage={networkErrPage}
          joinCall={joinCall}
          channel={CHANNEL}
          setNetworkErrorPage={setNetworkErrorPage}
          localVideoTracks={localVideoTracks}
        />}

            <VideoCallRecordingPopUp
                open={isOpenRecordDialog}
                handleStartAction={createRecordAcquire}
                handleCloseAction={()=>{
                  setActivePopup(false)
                  setIsOpenRecordDialog(false)

    
                }}
                activePopup={activePopup}
                startRecordResponse = {videoCallRecordStartResponse}
                isRecording={isRecording}
                stopRecording={stopRecording }
                user_id = {user_id}
                checkCameraActive={checkCameraActive}
                checkMicrophoneActive={checkMicrophoneActive}
            />

    </>
  );
}

export default withStyles(style)(VideoCall);