import React from "react";
import { withStyles } from "@material-ui/core";
import style from "./style";


function AvatarRipple(props) {

    const { classes, remoteAttendees } = props

    return (
        <div className={remoteAttendees ? classes.remoteAttendeesCircle : classes.circle}> </div>
    )
}

export default withStyles(style)(AvatarRipple);
