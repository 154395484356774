import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
import { Grid, withStyles } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';

import style from './style'
import { CloseIcon, MessageIcon } from '../../assets/svg/index'
import { Typography } from '../../components/index'

import { updateAction } from "../../redux/actions";

import { profileConstant } from "../../redux/constants";

/**
 * NotificationTile Component
 * @param {*} props
 */
function NotificationTileComponent(props) {

    const { classes, viewMessageClick } = props;

    const dispatch = useDispatch();


    const { newMessage } = useSelector((state) => state.profile);
    const { activeHub } = useSelector(s => s.chatHub);
    const columnName = newMessage.moduleType === "project" ? "job_id" : newMessage.moduleType === "client" ? "client_id" : "group_id";
    const isActiveJob = newMessage.moduleType === activeHub.moduleType && newMessage[`${columnName}`] === activeHub.id ? true : false;

    const closeMessage = () => {
        dispatch(updateAction.update(profileConstant.UPDATE_NEW_MESSAGE, {}))
    }

    useEffect(() => {
        if (newMessage["id"]) {
            setTimeout(() => {
                closeMessage()
            }, 15000);
        }
    }, [newMessage])

    const switchAndOpenMessage = () => {
        viewMessageClick(newMessage)
    }

    if (newMessage && newMessage["id"] && !isActiveJob) {
        let fileUrl = null; let fileDoc = null; let fileIcon = null;
        if (newMessage.message_file) {
            let fileObj = JSON.parse(newMessage.message_file);
            if (newMessage.message_category === 1) {
                fileUrl = fileObj && fileObj.url ? fileObj.url : null;
            }
            else if (newMessage.message_category === 2) {
                fileDoc = fileObj && fileObj.name ? fileObj.name : null;
                fileIcon = fileObj && fileObj.type ? fileObj.type.split("/")[1] : "docs";
            } else if (newMessage.message_category === 3) {
                fileUrl = fileObj && fileObj[0].url ? fileObj[0].url : null;
            }
            else if (newMessage.message_category === 4) {
                fileDoc = fileObj && fileObj.name ? fileObj.name : null;
                fileIcon = fileObj && fileObj.name ? "voice note" : "";
            }
        }
        return (
            <Card className={classes.notificationCard}>
                <Grid container className={classes.cardHeader}>
                    <Grid className="growOne dflex alignflexEnd">
                        <IconButton className="p-0 mr-1"><MessageIcon /></IconButton>
                        <Typography variant="h5">Messages</Typography>
                        <Typography variant="body2" color="textSecondary" className="ml-2">now</Typography>
                    </Grid>
                    <IconButton onClick={() => { closeMessage() }} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Grid>

                <CardActionArea onClick={() => { switchAndOpenMessage() }}>
                    <CardContent className="dflex alignCenter">
                        <Grid className="growOne">
                            <Typography gutterBottom variant="h5" component="h2">
                                {newMessage ? `${newMessage["first_name"] ? newMessage["first_name"] : ""} ${newMessage["last_name"] ? newMessage["last_name"] : ""}` : ""}
                            </Typography>

                            {newMessage.message_text ? <Typography variant="body1" color="textSecondary" component="p" className="tasksText threeLineEllip">{newMessage.message_text ? newMessage.message_text : ""}</Typography> : null}
                            {newMessage.message_file ? <Typography variant="body1" color="textSecondary" component="p" className="tasksText threeLineEllip">{newMessage.message_text ? newMessage.message_text : ""}</Typography> : null}
                            {fileUrl ? <img alt={"imagethumb"} src={fileUrl} className={classes.replyThumb} /> : null}
                            {fileDoc ? <Typography variant="body2" className="tasksText threeLineEllip">{fileIcon} {fileDoc}</Typography> : null}

                        </Grid>
                        <Grid className="pl-3">
                            <Avatar aria-label="recipe" size="small" className={classes.avatar}>
                                {newMessage && newMessage["first_name"] && newMessage["first_name"][0] ? newMessage["first_name"][0] : ""}
                            </Avatar>
                        </Grid>
                    </CardContent>
                </CardActionArea>
                {/* 
                <CardActions>
                    <Button size="small" color="primary">
                    REPLY
                    </Button>
                </CardActions> 
                */}
            </Card>
        );
    } else {
        return null
    }
}

// default props
NotificationTileComponent.defaultProps = {
    classes: {},
    children: "",
    className: "",
    onClick: () => { },
    viewMessageClick: () => { }
};

// prop types
NotificationTileComponent.propTypes = {
    children: PropTypes.any,
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    component: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    classes: PropTypes.object,
    className: PropTypes.string,
    viewMessageClick: PropTypes.func
};

/** Export Component */
export const NotificationTile = withStyles(style)(NotificationTileComponent);
