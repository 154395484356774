import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML, convertFromHTML } from "draft-convert";
import { Grid } from "@material-ui/core";
import PropTypes from 'prop-types';

// Import Draft Style
import './styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function MyEditor(props) {

  const { value, onChange } = props;

  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(convertFromHTML(value)))

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  useEffect(() => {
    if (value) {
      setEditorState(() => EditorState.createWithContent(convertFromHTML(value)))
    } else {
      setEditorState(() => EditorState.createWithContent(convertFromHTML("")))
    }
  }, [value])

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    onChange(currentContentAsHTML)
  };


  return (
    <Grid
      container
      // alignContent="baseline"
      >
      <Grid item xs={12}>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={{
            options: ['inline', 'blockType', 'list', 'link','colorPicker'],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['bold', 'italic'],
              // bold: { icon: BoldIcon, className: undefined },
              // italic: { icon: ItalicIcon, className: undefined },
            },
            blockType: {
              inDropdown: true,
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
              className: "blockType",
              component: undefined,
              dropdownClassName: undefined,
            },
            list: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['unordered', 'ordered'],
              // unordered: { icon: ListIcon, className: undefined },
              // ordered: { icon: OrderListIcon, className: undefined },
            },
            link: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              dropdownClassName: undefined,
              showOpenOptionOnHover: true,
              defaultTargetOption: '_self',
              options: ['link', 'unlink'],
              // link: { icon: LinkIcon, className: undefined },
              // unlink: { icon: MinusIcon, className: undefined },
              linkCallback: undefined
            },
            colorPicker: {
              // icon: ColorIcon,
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
            },
          }
          }
        />
      </Grid>
    </Grid>
  );
}

MyEditor.defaultProps = {
  value: "",
  onChange: () => { }
}
MyEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func
}



export const RichTextEdit = MyEditor;
