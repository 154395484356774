import { AttachImages, AttachDocument, AttachSticker } from "../../assets/svg"

export const ChatChannels = [
    {
        label: 'Flows',
        value: 'projects',
        isVisible: (data) => {
            return data.is_client == 0
        }
    },
    {
        label: 'Clients',
        value: 'clients',
        isVisible: () => {
            return true
        }
    },
    {
        label: 'Direct',
        value: 'teams',
        isVisible: () => {
            return true
        }
    }
]

export const MODULE_SEARCH_LABEL = {
    'projects': 'Search Projects',
    'clients': 'Search Clients',
    'teams': 'Search or Start New Chat'
}

export const PARAM_KEY = {
    'projects': 'job_id',
    'clients': 'client_id',
    'teams': 'group_id',
    'project': 'job_id',
    'client': 'client_id',
    'team': 'group_id'
}

export const PARAM_MODULE_URL = {
    'project': 'projects',
    'client': 'clients',
    'team': 'teams'
}

export const PARAM_MODULE_KEY = {
    'projects': 'project',
    'clients': 'client',
    'teams': 'team'
}

export const PARAM_TYPE = {
    'projects': 'project',
    'clients': 'client',
    'teams': 'groupId'
}

// API services names
export const FILE_API_SERVICE_NAME = {
    'projects': 'getMessageMedia',
    'clients': 'getClientChatMedia',
    'teams': 'getTeamChatMedia'
}

export const SEARCH_API_SERVICE_NAME = {
    'projects': 'getSearchMessages',
    'clients': 'getClientChatSearchMessages',
    'teams': 'getTeamChatSearchMessages'
}

export const LIST_API_SERVICE_NAME = {
    'projects': 'getProjects',
    'clients': 'getClients',
    'teams': 'getTeamUsers'
}

export const SENT_MESSAGE_API_SERVICE_NAME = {
    'projects': 'saveNewMessage',
    'clients': 'saveClientNewMessage',
    'teams': 'saveTeamNewMessage',
    'files_projects': 'uploadJobFiles',
    'files_clients': 'uploadClientChatFiles',
    'files_teams': 'uploadTeamChatFiles',
    'edit_projects': 'editProjectTextMessage',
    'edit_clients': 'editClientTextMessage',
    'edit_teams': 'editTeamTextMessage',
}

export const ATTACHMENT_MENUS = [

    {
        label: 'Document',
        value: 'doc',
        // Icon: AttachDocument
        Icon: AttachSticker
    },
    {
        label: 'Multiple Files',
        value: 'group',
        // Icon: AttachSticker
        Icon: AttachDocument
    },
    {
        label: 'Images & Videos',
        value: 'img',
        Icon: AttachImages
    }
]

export const AVATAR_KEY = {
    'projects': 'avatar_type'
}

export const NOTIFICATION_KEY = {
    'projects': 'jobMsgCount',
    'clients': 'clientMsgCount',
    'teams': 'teamMsgCount'
}

export const FORWARD_TYPE = {
    "projects": "1",
    "clients": "2",
    "teams": "3"
}