import React from "react";
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

//Import Component
import { Typography, Link } from '../../components'

//Icons
import { CircleIcon } from '../../assets/svg'

// Imports Styles
import styles from "./styles";

const Card = (props) => {
  const { classes, key, projectData } = props;
  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
        <Link href={`#/projects/detail/${projectData.job_id}`}>
          <Grid className={classes.projectsTile}>
            <Typography variant="subtitle1" className="pb-1">{projectData.job_title}</Typography>
            <Typography variant="body1" className="tasksText threeLineEllip">{projectData.job_description}</Typography>
            <Typography variant="body1" className="pb-1 pt-1 dflex">
              <CircleIcon style={{ fill: projectData.job_tag_colour }} />
              {projectData.job_tag}
            </Typography>
            <Typography variant="overline"><span className={"dueDate"}>Due Date:</span> OnGoing</Typography>
            <Divider className="mt-2 mb-2" />
            <Avatar alt="Name" src="/static/images/avatar/1.jpg" />
            <Typography className="pt-2 pb-1" variant="subtitle1"> Status: <b>Work in progress</b></Typography>
            <Typography variant="subtitle2" color="textSecondary"> 1 month ago</Typography>
          </Grid>
        </Link>
      </Grid>
    </>
  );
};

// Default props
Card.defaultProps = {
  classes: "",
  disabled: false,
  disableElevation: false,
  projectData: null
};

// Prop types
Card.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  disableElevation: PropTypes.bool,
  projectData: PropTypes.object
};

/** Export Component */
export const CardProjects = withStyles(styles)(Card);
