import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import moment from 'moment';
import 'moment-duration-format';

export default function WaveformComponent(props) {
  const { msgComponentType, duration, curTime = 0, onTimeUpdate, url, playing, timing ,border,viewType} = props;
  const waveformRef = useRef(null);
  const waveSurferRef = useRef(null);
  const [waveformReady, setWaveformReady] = useState(false);
  const [savedUrl, setSavedUrl] = useState(null); 

  useEffect(() => {
    if (url) {
      setSavedUrl(url);
    }
  }, [url]);

  useEffect(() => {
    if (savedUrl) {
      try {
        waveSurferRef.current = WaveSurfer.create({
          container: waveformRef.current,
          waveColor: msgComponentType? msgComponentType === 'sent' && viewType != 'listView' ? '#f1f1f1' : '#3182FF' : '#64748B',
          progressColor:msgComponentType ?  msgComponentType === 'sent' ? '#f1f1f161' : '#c5c5c5' :'white',
          cursorColor: 'transparent',
          barWidth: 2.5,
          height: 35,
          responsive: true,
          url: savedUrl,
          backend: 'MediaElement',
        });

        waveSurferRef.current.on('ready', () => {

          setWaveformReady(true);
          if (curTime) {
            waveSurferRef.current.seekTo(curTime / duration);
          }
        });

        waveSurferRef.current.on('seek', (progress) => {
          const newTime = progress * duration;
          onTimeUpdate(newTime);
        });
      } catch (err) {
        console.error('Error initializing WaveSurfer: ', err);
      }
    }

    return () => {
      if (waveSurferRef.current) {
        waveSurferRef.current.destroy();
        waveSurferRef.current = null;
        setWaveformReady(false);
      }
    };
  }, [savedUrl]);

  useEffect(() => {
    if (waveSurferRef.current && curTime !== undefined && duration !== undefined && !isNaN(curTime) && !isNaN(duration)) {
      waveSurferRef.current.seekTo(curTime / duration);
    }
  }, [curTime, duration, waveformReady]);

  function formatDuration(duration) {
    return moment.duration(duration, 'seconds').format('mm:ss', { trim: false });
  }

  return (
    <div className="waveform" style={{
       minWidth: '120px',
        minHeight: '48px', 
        marginBottom: '-9px',
        ...(border && {
          backgroundColor: '#F5F9FF', 
          padding: '0 10px',
          minHeight:'36px',
          marginBottom:'0px',
          borderRadius: '15px',
        }) }}>
      <div ref={waveformRef} className="waveform__canvas" style={{ height: '30px', display: waveformReady ? 'block' : 'none' }} />
      {timing && (
        <span className="waveform__time start">
          {playing ? formatDuration(curTime) : formatDuration(duration)}
        </span>
      )}
    </div>
  );
}