const Style = (theme) => ({
    popUpBox:{
        "& .MuiDialog-paper":{
            borderRadius: '12px',
            border: `${theme.palette.type === "dark" ? "1px solid #E4E8F11A" : "none"}`,
        }
    },
    actionsWrapper:{
        '& .MuiButton-outlinedSecondary':{
            '& .MuiButton-label':{
                color:'#64748B',
            }
        }
    }
})
export default Style;
