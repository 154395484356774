import React, { useEffect, useState, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover, MenuItem, Typography, IconButton, Grid, Badge, Tabs, Tab, useMediaQuery } from "@material-ui/core";
import { MoreIcon, ReturnIcon, MarkasreadIcon, NoNotificationIcon1 } from "../../assets/svg";
import { withStyles } from "@material-ui/core/styles";
import NotificationListStyle from "../Notification/NotificationListStyle";
import { profileAction, } from "../../redux/actions";
import history from "../../config/history";
import { Button, DeleteDialogComponent, } from "../../components";
import NotificationItem from "./NotificationItem";
import { MobileActionSheet } from "../../components/MobileComponents/MobileActionSheet";
import PropTypes from "prop-types";

const NotificationList = (props) => {
  const {
    classes,
    setAnchorEl,
    hideHeaderElements,
    openDialog,
    handleCloseAction,
    maxNotifications = 1000,
    isPopover = false
  } = props;
  const { location: { pathname } } = history;
  const dispatch = useDispatch();
  const { notifications_n, tasks_n, invites_n, notifications_count, invoice_n,funnel_n } =
    useSelector((s) => s.profile);
  const [tabValue, setTabValue] = React.useState(0);
  const [notification_list, setNotification_list] = React.useState([]);
  const [showCheckbox, setShowCheckbox] = React.useState(false);
  const [menuPopoverOpen, setMenuPopoverOpen] = React.useState(false);
  const [menuPopover, setMenuPopover] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const isMobile = useMediaQuery('(max-width: 899px)');

  let deleteConfirmation = false

  const enableDelete = (notification_list.findIndex((ele) => ele.isChecked === true) > -1)
  if (openDialog) {
    if (notification_list.length) {
      deleteConfirmation = true
    } else {
      handleCloseAction()
    }
  }
  useEffect(() => {
    if (tabValue === 0) {
      setNotification_list(
        notifications_n.slice(
          0,
          Math.min(maxNotifications, notifications_n.length)
        )
      );
    } else if (tabValue === 1) {
      setNotification_list(
        tasks_n.slice(0, Math.min(maxNotifications, tasks_n.length))
      );
    } else {
      let notificationArr = invites_n.concat(invoice_n,funnel_n)
      setNotification_list(
        notificationArr.slice(0, Math.min(maxNotifications, notificationArr.length))
      );
    }
  }, [notifications_n, tasks_n, invites_n, tabValue, maxNotifications, invoice_n,funnel_n]);
  const handleScroll = useCallback(
    (e) => {
      const calc_bottom = e.target.scrollHeight - e.target.scrollTop;
      const isBottom = Math.round(
        Math.abs(calc_bottom - e.target.getBoundingClientRect().height)
      );

      if (
        Math.round(isBottom) <= 4 &&
        notification_list.length < notifications_count?.allNewMsgCount
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [setPage, notification_list, maxNotifications,]
  );
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    dispatch(profileAction.getNotifications({ page, limit: 20 }));
  }, [page]);

  // Update Notification API call
  const updateNotification = (notification, redirect = true) => {
    handleCloseAction && handleCloseAction();
    const data = notification;
    const type = notification.notification_type;
    const params = {
      id: notification.id,
      job_id: notification.job_id,
      item_id: notification.item_id,
      invite_type:
        notification.invite_type === 1
          ? "project"
          : notification.invite_type === 2
            ? "client"
            : notification.invite_type === 3
              ? "team"
              : "new_user",
      type,
      notification_sub_type: notification?.notification_sub_type,
      redirect: redirect,
      data: data,
    };
    dispatch(
      profileAction.updateNotification(params, () => {
        if (type === "message" && !redirect) {
          dispatch(profileAction.getNotifications());
        } else if (type === "task") {
          dispatch(profileAction.getTasks());
        } else if (type === "invite") {
          dispatch(profileAction.getInvites());
        } else if (type === 'invoice') {
          dispatch(profileAction.getInvoiceNotification())
        }else if (type === 'funnel') {
          dispatch(profileAction.getFunnelNotification())
        }

        if (hideHeaderElements) {
          setAnchorEl(null);
        }
      })
    );
  };
  const viewcheckbox = () => {
    setShowCheckbox(!showCheckbox);
    setMenuPopoverOpen(false);
  };
  const handleSelect = (value) => (event) => {
    event.stopPropagation();
    let updated_nList = notification_list;
    updated_nList = updated_nList.map((n) => {
      if (n.id === value.id) {
        n["isChecked"] = !n["isChecked"];
      }
      return n;
    });
    setNotification_list(updated_nList);
  };
  const NoNotificationImage = ({ tabValue = 0 }) => {
    const messages = {
      0: "chat",
      1: "task",
      2: "other",
    };
    return (
      <Grid container className="textCenter h-100" alignContent="center">
        <Grid item xs={12}>
          <NoNotificationIcon1 alt={`No ${messages[tabValue]?.charAt(0)?.toUpperCase() + messages[tabValue]?.slice(1)} notifications`} />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.noNotificationText}>
            {`${messages[tabValue]?.charAt(0)?.toUpperCase() + messages[tabValue]?.slice(1)} notification is empty`}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const handlebackButton = () => {
    setShowCheckbox(false);
    history.goBack();
  };
  const updateNotificationStatus = (selectecIds = [], redirect = true) => {
    const type = tabValue === 0 ? "message" : tabValue === 1 ? "task" : "invite";
    const data = { id: "all", type };
    if (selectecIds.length) {
      data["id"] = "selected";
      data["ids"] = selectecIds;
    }
    data["redirect"] = redirect;
    handleCloseAction && handleCloseAction();
    dispatch(
      profileAction.updateNotification(data, () => {
        if (tabValue === 0) {
          dispatch(profileAction.clearNotifications('message'));
        } else if (tabValue === 1) {
          dispatch(profileAction.clearNotifications('task'));
        } else {
          dispatch(profileAction.clearNotifications('invite'));
          dispatch(profileAction.clearNotifications('invoice'));
        }
        dispatch(
          profileAction.getNotifications({}, () => {
            if (hideHeaderElements) {
              setAnchorEl(null);
              handleCloseAction();
            }
            setOpen(false);
          })
        );
        if (type === "task") {
          dispatch(profileAction.getTasks());
        } else if (type === "invite") {
          dispatch(profileAction.getInvites());
        } else if (type === "invoice") {
          dispatch(profileAction.getInvoiceNotification());
        } else if (type === "invoice") {
          dispatch(profileAction.getFunnelNotification());
        }

      })
    );
  };

  const handleCheckRead = () => {
    const selectedIds = [];
    notification_list.forEach((notification) => {
      if (notification.isChecked) {
        selectedIds.push(notification.id);
        updateNotification(notification, false, true);
      }
    });

    setShowCheckbox(false);
  };

  const handleCheckDelete = (id) => {
    const selectedIds = [];
    if (notification_list.some((notification) => notification.isChecked)) {
      notification_list.forEach((notification) => {
        if (notification.isChecked) {
          selectedIds.push(notification.id);
        }
      });
      dispatch(
        profileAction.deleteNotification({ id: null, ids: selectedIds }, () => {
          dispatch(profileAction.getNotifications());
          setShowCheckbox(false);
          if (tabValue === 1) {
            dispatch(profileAction.getTasks());
          }
          if (tabValue === 2) {
            dispatch(profileAction.getInvites());
          }
        })
      );
    } else if (id) {
      dispatch(
        profileAction.deleteNotification({ id, ids: [] }, () => {
          dispatch(profileAction.getNotifications());
          setShowCheckbox(false);
          if (tabValue === 1) {
            dispatch(profileAction.getTasks());
          }
          if (tabValue === 2) {
            dispatch(profileAction.getInvites());
          }
        })
      );
    }
  };

  const cutMessage = (message, charLimit) => {
    if (message.length > charLimit) {
      return message.substring(0, charLimit - 3) + "...";
    } else {
      return message;
    }
  };
  const handleMenuClick = (event) => {
    setMenuPopover(event.currentTarget);
    setMenuPopoverOpen(true);
  };
  const handleMenuClose = () => {
    setMenuPopover(null);
    setMenuPopoverOpen(false);
  };
  const handleMarkAllAsRead = () => {
    if (notification_list.length > 0) {
      setOpen(true);
    }
  };

  const handleViewAllNotifications = () => {
    setAnchorEl(null);
    setTimeout(() => {
      history.push("/notification");
    }, [500])
  };
  const handleCloseMarkRead = () => {
    setOpen(false);
    setMenuPopoverOpen(false);
    if (hideHeaderElements) {
      handleCloseAction();
    }
  }


  return (
    <Grid container className={`${classes.NotificationContainer} ${isPopover ? '' : classes.NotificationContainerPadding}`}>
      <Grid item className={`NotificationContainerWrapper mauto br-8 ${isMobile && pathname === "/notification" ? "full-page-container" : ""} ${!isMobile ? " deActive" : " mobileActive"}`} xs={12} md={isPopover ? 12 : 7} lg={isPopover ? 12 : 6} xl={isPopover ? 12 : 5}>

        {/* <Grid item className={`NotificationContainerWrapper mauto br-8 ${isMobile && pathname === "/notification" ? "full-page-container": "" } ${!isMobile ? " deActive" : " mobileActive"}`} xs={12} md={isPopover ? 12 : 7} lg={isPopover ? 12 : 6} xl={isPopover ? 12 : 5}>
        {(isMobile) &&
          (<Grid item className={`${classes.mobileTitleContainer} dflex alignCenter client-header w-100 page-spacing`}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                {hideHeaderElements && (
                  <IconButton className={`${classes.ReturnIcon}`} onClick={() => { history.goBack(); }}>
                    <ReturnIcon />
                  </IconButton>
                 )}
              </Grid>
              <Grid item>
                {hideHeaderElements && (
                  <Typography className={classes.notidyhead} variant="h3">
                    Notifications
                  </Typography>
                )} 
              </Grid>
            </Grid>
          </Grid>)
        } */}
        <Grid container >
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="space-between" className={`headerContainer`}>
              <Grid item>
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    {!hideHeaderElements && (
                      <IconButton className={`${classes.ReturnIcon}`} onClick={handlebackButton}>
                        <ReturnIcon />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item>
                    {!hideHeaderElements && (
                      <Typography className={classes.notidyhead} variant="h3">
                        Notifications
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    {!showCheckbox && !hideHeaderElements && notification_list.length > 0 && (
                      <Fragment>
                        <IconButton
                          className={classes.MoreIconnotify}
                          onClick={handleMenuClick}
                        >
                          <MoreIcon />
                        </IconButton>
                      </Fragment>
                    )}
                  </Grid>
                  <Grid item>
                    {
                      showCheckbox && !hideHeaderElements && (
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <Button variant={"outlined"} className={`${classes.secondaryTwoButton} ${classes.smallSizeButton}`} onClick={() => setShowCheckbox(false)} title={"Cancel"} />
                          </Grid>
                          <Grid item>
                            <Button className={`${classes.secondaryButton} ${classes.smallSizeButton}`} variant={"outlined"} onClick={handleCheckDelete} disabled={!enableDelete} title={"Delete"} />
                          </Grid>
                          <Grid item>
                            <Button className={`${classes.primaryButton} ${classes.smallSizeButton}`} variant={"contained"} onClick={handleCheckRead} title={"Mark as read"} />
                          </Grid>
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className={`${isPopover ? 'p16 pb-0' : "pr12 pl12 pt-1 "}`}>
                <Tabs
                  className={classes.buttoncard2}
                  value={tabValue}
                  onChange={handleTabChange}
                  indicatorColor="primary"
                  textColor="inherit"
                  TabIndicatorProps={{
                    style: { background: "none" },
                  }}
                >
                  <Tab
                    className={tabValue === 0 ? classes.chatbtn : classes.taskbtn}
                    style={{ minWidth: "33%" }}
                    label={
                      <Badge
                        classes={{ badge: classes.customBadge }}
                        badgeContent={notifications_count?.allNewMsgCount || 0}
                      >
                        Chat
                      </Badge>
                    }
                  />
                  <Tab
                    className={tabValue === 1 ? classes.chatbtn : classes.taskbtn}
                    style={{ minWidth: "33%" }}
                    label={
                      <Badge
                        classes={{ badge: classes.customBadge }}
                        badgeContent={tasks_n?.length || 0}
                      >
                        Task
                      </Badge>
                    }
                  />
                  <Tab
                    className={`${tabValue === 2 ? classes.chatbtn : classes.taskbtn} mr4`}
                    style={{ minWidth: "31.5%" }}
                    label={
                      <Badge
                        classes={{ badge: classes.customBadgeothers }}
                        badgeContent={invites_n.concat(invoice_n,funnel_n)?.length || 0}
                      >
                        Others
                      </Badge>
                    }
                  />
                </Tabs>
              </Grid>
              <Grid item className={`${isPopover ? 'PopoverScrollContainer' : 'ScrollContainer'}`} xs={12} onScroll={handleScroll}>
                {notification_list.length > 0 ? (
                  <Grid container>
                    {notification_list.map((notification, index) => (
                      <NotificationItem
                        notification={notification}
                        index={index}
                        type={tabValue === 0 ? "message" : tabValue === 1 ? "task" : "other"}
                        tabValue={tabValue}
                        updateNotification={updateNotification}
                        setNotification_list={setNotification_list}
                        showCheckbox={showCheckbox}
                        handleSelect={handleSelect}
                        cutMessage={cutMessage}
                        hideHeaderElements={hideHeaderElements}
                        handleCheckDelete={handleCheckDelete}
                      />
                    ))}
                  </Grid>
                ) : <NoNotificationImage tabValue={tabValue} />}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {
        isPopover &&
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className={classes.notificationButton}
          >
            <Grid item>
              {
                notification_list.length > 0 &&
                <Button
                  className={classes.read}
                  color="primary"
                  onClick={() => {
                    handleMarkAllAsRead();
                  }}
                  startIcon={<MarkasreadIcon />}
                >
                  Mark all as read
                </Button>
              }
            </Grid>
            <Grid item>
              <Button
                className={classes.notifybutton}
                color="primary"
                variant="contained"
                onClick={() => handleViewAllNotifications()}
              >
                View all Notification
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
      {isMobile ?
        <MobileActionSheet
          open={menuPopoverOpen}
          title=""
          handleCloseAction={handleMenuClose}>
          <MenuItem
            className="p-1"
            style={{ color: "#64748B" }}
            onClick={() => {
              handleMarkAllAsRead();
            }}
          >
            Mark all as read
          </MenuItem>
          <MenuItem
            className="p-1"
            style={{ color: "#64748B" }}
            onClick={viewcheckbox}>
            Select
          </MenuItem>
        </MobileActionSheet>
        :
        <Popover
          open={menuPopoverOpen}
          id="menu-popover"
          anchorEl={menuPopover}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem
            className="p-1"
            style={{ color: "#64748B" }}
            onClick={() => {
              handleMarkAllAsRead();
            }}
          >
            Mark all as read
          </MenuItem>
          <MenuItem
            className="p-1"
            style={{ color: "#64748B" }}
            onClick={viewcheckbox}>
            Select
          </MenuItem>
        </Popover>
      }
      <DeleteDialogComponent
        open={deleteConfirmation || open}
        deleteButtonTitle={"Yes"}
        closeButtonTitle={"Cancel"}
        handleCloseAction={() => { handleCloseMarkRead() }}
        handleDeleteAction={() => {
          handleCloseMarkRead();
          updateNotificationStatus([], false);
        }}
        content={"Are you sure you want to Mark all as read on chat?"}
      />
    </Grid >
  )
};

// default props
NotificationList.defaultProps = {
  handleCloseAction: () => { },
};

// prop types
NotificationList.propTypes = {
  handleCloseAction: PropTypes.bool,
};

export default withStyles(NotificationListStyle)(NotificationList);
