import {postRequest} from "../../utils/apiHelper";
export const aiService = {
    speechToText,
    askAssistant
};

async function speechToText(body) {
    const response = await postRequest("poc/speechToText", body, true, null, 'ai');
    return response;
}

async function askAssistant(body) {
    const response = await postRequest("poc/", body, false, null, 'ai');
    return response;
}