
import { App } from '@capacitor/app'
import { Browser } from "@capacitor/browser";
import moment from "moment";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authAction, eventAction, profileAction, updateAction } from '../../redux/actions';
import { profileConstant } from '../../redux/constants';

const AppUrlListener = () => {
    // Define Dispatch
    const dispatch = useDispatch();
    const { eventView, dateRange } = useSelector(s => s.event)

    useEffect(() => {
        App.addListener('appUrlOpen', async (data) => {
            if (!data?.url?.startsWith('flozy://')) {
                return;
            }
            if (data?.url?.includes('calendar-callback')) {
                const response = extractResponseFromURL(data?.url)
                await Browser.close()
                const eventData = typeof (response) === "string" ? JSON.parse(response) : response;
                if (eventData && eventData?.hint === "googlOauthCallBackCalendar") {
                    const params = {
                        email: eventData?.userInfo?.email,
                        type: 'connect'
                    }
                    dispatch(eventAction.getGoogleEmail(params))
                    const param = {
                        userInfo: eventData?.userInfo,
                        type: 'connect',
                        token: eventData?.tokens,
                        app: "google"
                    }
                    dispatch(eventAction.saveAppAuthTokens(param, (data) => {
                        dispatch(profileAction.getUserConfiguredApps())
                        if (data && data?.length > 0) {
                            let updateData = { data: data };
                            dispatch(updateAction.update(profileConstant.GET_USER_CONFIGURED_APPS_S, updateData))
                        }
                    }))
                    const currentDate = new Date();
                    const first = currentDate.getDate() - currentDate.getDay();
                    const last = first + 6;
                    const firstWeekDay = new Date(currentDate.setDate(first)).toUTCString();
                    const lastWeekDay = new Date(currentDate);
                    lastWeekDay.setDate(last);
                    if (moment(lastWeekDay).format('yyyy-MM-DD') < moment(firstWeekDay).format('yyyy-MM-DD')) {
                        lastWeekDay.setUTCMonth(lastWeekDay.getUTCMonth() + 1);
                    }
                    const firstMonthDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                    const lastMonthDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
                    localStorage?.setItem('emailtoken', JSON.stringify(eventData.tokens))
                    switch (eventView) {
                        case 'day':
                            return dispatch(eventAction.getAppEvents({
                                dateRange: {
                                    start_date: dateRange.start_date || moment(new Date()).format('yyyy-MM-DD'),
                                    end_date: dateRange.end_date || moment(new Date()).format('yyyy-MM-DD')
                                },
                                type: 'label',
                                tokens: eventData?.tokens
                            }, () => {
                                // setGoogleEventLoading(false)
                            }))
                        case 'week':
                            return dispatch(eventAction.getAppEvents({
                                dateRange: {
                                    start_date: dateRange.start_date || moment(firstWeekDay).format('yyyy-MM-DD'),
                                    end_date: dateRange.end_date || moment(lastWeekDay).format('yyyy-MM-DD')
                                },
                                type: 'label',
                                tokens: eventData?.tokens
                            }, () => {
                                // setGoogleEventLoading(false)
                            }))
                        case 'month':
                            return dispatch(eventAction.getAppEvents({
                                dateRange: {
                                    start_date: dateRange.start_date || moment(firstMonthDay).format('yyyy-MM-DD'),
                                    end_date: dateRange.end_date || moment(lastMonthDay).format('yyyy-MM-DD')
                                },
                                type: 'label',
                                tokens: eventData?.tokens
                            }, () => {
                                // setGoogleEventLoading(false)
                            }))
                        default: return
                    }

                } else if (eventData && eventData?.hint === "zoomOauthCallBackCalendar") {
                    if (eventData.message) {
                        dispatch(eventAction.userAccessFailed(eventData.message))
                        return
                    } else {
                        const params = {
                            userInfo: eventData?.userInfo,
                            type: 'connect',
                            token: eventData?.tokens,
                            app: "zoom"
                        }
                        // save zoom token
                        dispatch(eventAction.saveAppAuthTokens(params, (data) => {
                            dispatch(profileAction.getUserConfiguredApps())
                            dispatch(eventAction.getZoomLink())
                            if (data && data?.length > 0) {
                                let updateData = { data: data };
                                dispatch(updateAction.update(profileConstant.GET_USER_CONFIGURED_APPS_S, updateData))
                            }
                        }))
                    }
                }
            } else if (data?.url?.includes('login-callback')) {
                const response = extractResponseFromURL(data?.url)
                await Browser.close()
                const eventData = typeof (response) === "string" ? JSON.parse(response) : response;

                if (eventData && eventData?.hint === "googlOauthCallBack") {
                    let params = { app: "google" };
                    if (eventData?.tokens) {
                        params = { ...params, ...eventData?.tokens }
                    }
                    if (eventData?.userInfo) {
                        params = { ...params, ...eventData?.userInfo, googleId: eventData?.userInfo?.id }
                    }
                    dispatch(authAction.socialLogin(params))
                }
            } else if (data?.url?.includes('signup-callback')) {
                const response = extractResponseFromURL(data?.url)
                await Browser.close()
                const eventData = typeof (response) === "string" ? JSON.parse(response) : response;

                if (eventData && eventData?.hint === "googlOauthCallBack") {
                    let params = { app: "google" };
                    if (eventData?.tokens) {
                        params = { ...params, ...eventData?.tokens }
                    }
                    if (eventData?.userInfo) {
                        params = { ...params, ...eventData?.userInfo, googleId: eventData?.userInfo?.id }
                    }
                    dispatch(authAction.socialSignUp(params))
                }
            } else if (data?.url?.includes('app_integration')) {
                const response = extractResponseFromURL(data?.url)
                await Browser.close()
                const eventData = typeof (response) === "string" ? JSON.parse(response) : response;
                if (eventData && eventData?.hint === "googlOauthCallBackCalendar") {
                    const params = {
                        email: eventData?.userInfo?.email,
                        type: 'connect'
                    }
                    dispatch(eventAction.getGoogleEmail(params))
                    const param = {
                        userInfo: eventData?.userInfo,
                        type: 'connect',
                        token: eventData?.tokens,
                        app: "google"
                    }
                    dispatch(eventAction.saveAppAuthTokens(param, (data) => {
                        dispatch(profileAction.getUserConfiguredApps())
                        if (data && data.length > 0) {
                            let updateData = { data: data };
                            dispatch(updateAction.update(profileConstant.GET_USER_CONFIGURED_APPS_S, updateData))
                        }
                    }))

                    localStorage.setItem('emailtoken', JSON.stringify(eventData?.tokens))


                } else if (eventData && eventData?.hint === "zoomOauthCallBackCalendar") {
                    if (eventData.message) {
                        dispatch(eventAction.userAccessFailed(eventData.message))
                        return
                    } else {
                        const params = {
                            userInfo: eventData?.userInfo,
                            type: 'connect',
                            token: eventData?.tokens,
                            app: "zoom"
                        }
                        // save zoom token
                        dispatch(eventAction.saveAppAuthTokens(params, (data) => {
                            dispatch(profileAction.getUserConfiguredApps())
                            dispatch(eventAction.getZoomLink())
                            if (data && data.length > 0) {
                                let updateData = { data: data };
                                dispatch(updateAction.update(profileConstant.GET_USER_CONFIGURED_APPS_S, updateData))
                            }
                        }))
                    }
                }
            } else if (data?.url?.includes('create_booking')) {
                const response = extractResponseFromURL(data?.url)
                await Browser.close()
                const eventData = typeof (response) === "string" ? JSON.parse(response) : response;
                if (eventData && eventData?.hint === "googlOauthCallBackCalendar") {
                    const params = {
                        userInfo: eventData?.userInfo,
                        type: 'connect',
                        token: eventData?.tokens,
                        app: "google"
                    }
                    dispatch(eventAction.getGoogleEmail({
                        email: eventData?.userInfo?.email,
                        type: 'connect'
                    }))
                    localStorage.setItem('emailtoken', JSON.stringify(eventData?.tokens))
                    // save google token
                    dispatch(eventAction.saveAppAuthTokens(params, (data) => {
                        dispatch(profileAction.getUserConfiguredApps())
                        if (data && data.length > 0) {
                            dispatch(updateAction.update(profileConstant.GET_USER_CONFIGURED_APPS_S, data))
                        }
                    }))
                } else if (eventData && eventData?.hint === "zoomOauthCallBackCalendar") {
                    const params = {
                        userInfo: eventData?.userInfo,
                        type: 'connect',
                        token: eventData?.tokens,
                        app: "zoom"
                    }
                    // save zoom token
                    dispatch(eventAction.saveAppAuthTokens(params, (data) => {
                        if (data && data.length > 0) {
                            let updateData = { data: data };
                            dispatch(updateAction.update(profileConstant.GET_USER_CONFIGURED_APPS_S, updateData))
                        }
                    }))
                }
            }
        })
    }, []);

    const extractResponseFromURL = (url) => {
        const urlObj = new URL(url);
        const response = urlObj?.searchParams?.get('response');
        return response;
    }

    return null
}

export default AppUrlListener