import { IconButton, withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { Button } from "../../../components/index.js";
import style from "./style";
import { AudioMuteIcon, AudioUnmuteIcon, CallEndIcon, ScreenShareIcon, StopScreenSharingIcon, VideoCallSettingsIcon, VideoMuteIcon, VideoUnmuteIcon,RecordingOnIcon,StartRecordIcon, ErrorIcon } from "../../../assets/svg/videocall/index.jsx";


function VCMenu({
    classes,
    handleVideoToggle,
    isVideoMuted,
    handleLeave,
    handleAudioMute,
    handleScreenShare,
    audioMuted,
    user_id,
    joined,
    joinCall,
    remoteUsers,
    localUser,
    screenShare,
    getAcquireFun,
    stopRecording,
    openDialog,
    token,
    checkCameraActive,
    checkMicrophoneActive
}) {

    const [showCallOption, setShowCalloption] = useState(true);
    const [startRecord , setStartRecord] = useState(true)

    const handleShowOption = () => {
        setShowCalloption(!showCallOption);
    };

    // useEffect(()=>{
    //     if(token){
    //         setTimeout(()=>{
    //             joinCall()
    //     },[10000])
    //     }
    // },[token])
   
    return (
        <>
            {
                joined &&
                <div className={classes.VCOptions}>
                    <div className={classes.CallOptionsColumn}>
                        {/* <IconButton className={`${classes.optionIcon} ${showCallOption ? 'vcMoveUp300' : 'vcMoveDown300'}`} onClick={() => stopRecording()}>
                            {startRecord ? <AudioMuteIcon/>:<AudioUnmuteIcon/>}
                        </IconButton> */}

                        {/* ----hide recording icon for public user---- */}

                        {
                             !user_id?.toString().includes('public') &&

                             <IconButton className={`${classes.optionIcon} ${showCallOption ? 'vcMoveUp240' : 'vcMoveDown240'}`} onClick={() => getAcquireFun({})}>
                             {openDialog  ? <RecordingOnIcon/>:<StartRecordIcon/>}
                         </IconButton>
                        }
                        <IconButton className={`${classes.optionIcon} ${showCallOption ? 'vcMoveUp180' : 'vcMoveDown180'}`} onClick={() => handleVideoToggle(user_id)}>
                        {isVideoMuted  ? <><VideoUnmuteIcon />{checkCameraActive && <div className={classes.ErrorIconContainer}><ErrorIcon/></div>}</> :<VideoMuteIcon />  }
                        </IconButton>
                        <IconButton className={`${classes.optionIcon} ${showCallOption ? 'vcMoveUp120' : 'vcMoveDown120'}`} onClick={handleLeave}>
                            <CallEndIcon />
                        </IconButton>
                        <IconButton className={`${classes.optionIcon} ${showCallOption ? 'vcMoveUp60' : 'vcMoveDown60'}`} onClick={() => handleAudioMute(user_id)}>
                        {audioMuted ? <><AudioMuteIcon />{checkMicrophoneActive && <div className={classes.ErrorIconContainer}><ErrorIcon/></div>}</> : <AudioUnmuteIcon />}
                        </IconButton>
                        <IconButton className={`${classes.optionIcon} ${showCallOption ? 'vcMoveUp0' : 'vcMoveDown0'}`} onClick={() => handleScreenShare(user_id)}>
                            {screenShare ? <StopScreenSharingIcon/> :<ScreenShareIcon />}
                        </IconButton>
                        
                    </div>
                    <IconButton className={`${classes.iconZ2} ${showCallOption ? "vcMenuOpen" : "vcMenuClose"}`} onClick={handleShowOption}>
                        <VideoCallSettingsIcon active={showCallOption} />
                    </IconButton>
                </div>

            }
            {
                !joined &&
                <div className={classes.CallOptionsContainer}>
                    <div className={classes.CallOptionsRow}>
                        <div className={classes.CallOptions}>
                            <IconButton onClick={handleVideoToggle} className={classes.LobbyOptionIcon}>
                                {isVideoMuted  ? <><VideoUnmuteIcon />{checkCameraActive && <div className={classes.ErrorIconContainer}><ErrorIcon/></div>}</> :<VideoMuteIcon />  }
                            </IconButton>
                            <IconButton onClick={handleAudioMute} className={classes.LobbyOptionIcon}>
                                {audioMuted ? <><AudioMuteIcon />{checkMicrophoneActive && <div className={classes.ErrorIconContainer}><ErrorIcon/></div>}</> : <AudioUnmuteIcon />}
                            </IconButton>
                            <IconButton onClick={handleLeave} className={classes.LobbyOptionIcon}>
                                <CallEndIcon />
                            </IconButton>
                        </div>
                        <div>
                            <IconButton onClick={joinCall}>
                                <Button 
                                    variant={"contained"} 
                                    color={"primary"} 
                                    size={"small"} 
                                    className={"blueBtn fw-600"}
                                >
                                    Join
                                </Button>
                            </IconButton>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default withStyles(style)(VCMenu);
