import React, { useEffect, useState } from "react"
import { Popover, Slider } from "@material-ui/core";
import { BlockPicker } from "react-color";

export const BrushNodeOptions = (props) => {
    const { classes, selectedNode, onChange } = props
    const { id } = selectedNode?.attrs || {}
    const [attrs, setAttrs] = useState({
        colorPicker: false,
        pickerEle: null,
        fill: '#df4b26',
        strokeWidth: 5
    })

    useEffect(() => {
        if (id) {
            setAttrs({
                ...attrs
            })
        }
    }, [id])

    const toggleColorPicker = (e) => {
        setAttrs({
            ...attrs,
            colorPicker: !attrs.colorPicker,
            pickerEle: attrs.colorPicker ? null : e.currentTarget
        })
    }

    const handleChange = (val) => {
        const updatedAttrs = {
            ...attrs,
            ...val
        }
        setAttrs(updatedAttrs)
        onChange('drawPen', updatedAttrs)
    }

    const renderColorPicker = () => {
        return (
            <Popover
                open
                anchorEl={attrs.pickerEle}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={toggleColorPicker}
            >
                <BlockPicker
                    color={attrs?.fill}
                    onChangeComplete={(c) => {
                        handleChange({ 'fill': c?.hex })
                    }}
                />
            </Popover>
        )
    }

    return (
        <div className={classes.toolOptions}>
            <Slider 
                style={{ width: '200px' }}
                value={attrs?.strokeWidth || 5} 
                min={5} 
                max={20} 
                onChange={(e, val) => handleChange({ strokeWidth: val })} 
            />
            <div className={classes.optionsWrpr}>
                <div
                    role='button'
                    className={classes.colorBox}
                    onClick={toggleColorPicker}
                    style={{
                        backgroundColor: attrs?.fill
                    }}
                />
                {/* <div className={classes.colorHex}>{attrs?.fill}</div> */}
            </div>
            {attrs.colorPicker ? renderColorPicker() : null}
        </div>
    )
}