import { paymentConstant } from "../constants";

const initialState = {
    invoiceData: {
        invoice_logo: "",
        invoice_title: "",
        invoice_date: "",
        invoice_number: "",
        customer_name: "",
        invoice_description: "",
        invoice_discount: "",
        invoice_discount_price: "",
        invoice_tax_name: "",
        invoice_tax: "",
        invoice_tax_price: "",
        invoice_item_total: "",
        invoice_sub_total: "",
        invoice_total: "",
    },
    paymentResponse: null,
    agencyData: null,
    accountId: null,
    loading: false,
    error: null,
}

export default function users(state = initialState, action) {
    switch (action.type) {
        case paymentConstant.GET_INVOICE_PAYMENT_R:
            return {
                ...state,
                loading: true,
            }
        case paymentConstant.GET_INVOICE_PAYMENT_S:
            return {
                ...state,
                loading: false,
                ...action.data.data
            }
        case paymentConstant.GET_INVOICE_PAYMENT_F:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        case paymentConstant.UPDATE_INVOICE_PAYMENT_R:
            return {
                ...state,
                loading: true,
            }
        case paymentConstant.UPDATE_INVOICE_PAYMENT_S:
            return {
                ...state,
                loading: false,
                ...action.data.data
            }
        case paymentConstant.UPDATE_INVOICE_PAYMENT_F:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        case paymentConstant.UPDATE_INVOICE_PAYMENT_ERROR_R:
            return {
                ...state,
                loading: true,
            }
        case paymentConstant.UPDATE_INVOICE_PAYMENT_ERROR_S:
            return {
                ...state,
                loading: false,
                ...action.data.data
            }
        case paymentConstant.UPDATE_INVOICE_PAYMENT_ERROR_F:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        case paymentConstant.UPDATE_INVOICE_PAYMENT_STATUS_R:
            return {
                ...state,
                loading: true,
            }
        case paymentConstant.UPDATE_INVOICE_PAYMENT_STATUS_S:
            return {
                ...state,
                loading: false,
                paymentResponse:{...action.data.data.data}
            }
        case paymentConstant.UPDATE_INVOICE_PAYMENT_STATUS_F:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}
