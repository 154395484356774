import { URL_PROTOCOL_REGEX } from '../config/constants';

const manualRedirect = (url) => {
    const regEx = new RegExp(URL_PROTOCOL_REGEX);
    const hasProtocol = regEx.test(url)
    if(url && url != ''){
        if(hasProtocol){
            window.location.href = url
        } else {
            window.location.href = `http://${url}`
        }
    }
}

export const _triggers_values = {
    "DOC_SIGNED": {
        value: "DOC_SIGNED",
        label: "Dos signed"
    },
    "INVOICE_PAID": {
        value: "INVOICE_PAID",
        label: "Invoice paid"
    },
    "CLIENT_ONBOARDED": {
        value: "CLIENT_ONBOARDED",
        label: "Client Onboarded"
    },
    "FUNNEL_STEP_COMPLETED":{
         value: "FUNNEL_STEP_COMPLETED",
        label: "Funnel Step Completed"
    },
}

export const _actions_values = {
    REDIRECT: {
        value: "REDIRECT",
        label: "Redirect"
    },
    SEND_MESSAGE:{
        value: "SEND_MESSAGE",
        label: "Send message"
    },
    SEND_EMAIL:{
        value: "SEND_EMAIL",
        label: "Send Email"
    },
    SEND_NOTIFICATION:{
        value: "SEND_NOTIFICATION",
        label: "Send Notification"
    }
}

export const _triggers = {
    "DOC_SIGNED":{
      title: "Contract is signed",
      description: "When contract signed"
    },
    "INVOICE_PAID":{
      title: "Invoice paid",
      description: "Invoice is paid"
    },
    "CLIENT_ONBOARDED": {
        title: "Client onboarded",
        description: "When new client onboards"
    },
    "FUNNEL_STEP_COMPLETED":{
        title:"When user completes this funnel step",
        description:""
    }

}

export const _actions = {
    "REDIRECT":{
      title: "Redirect to link",
      description: "Open this link in the same tab"
    },
    "SEND_MESSAGE": {
        title: "Send message",
        description: "Send message to the selected chats"
    },
    "SEND_NOTIFICATION":{
        title:"Send Notification",
        description:"Sends a notification"
    },
    "SEND_EMAIL":{
        title:"Send an Email",
        description:" "
    }
}

export const workflowHandler = (workflow) => {
    const { workflow_execution_type, action_data } = workflow || {};
    switch(workflow_execution_type){
        case "MANUAL_REDIRECT":
            manualRedirect(action_data)
            break;
    }
}

export const _default_action_data = {
    "CLIENT_ONBOARDED": '{"scheduleType":1,"interval":1,"intervalType":1}'
}

