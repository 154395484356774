import { historyAttrs } from "./historyAttrs"

export const getLastNode = ({ stage }) => {
    const layer = stage.findOne('#baseLayer')
    const nodes = layer.getChildren()
    const historyElements = nodes.filter(f => f.attrs.history)
    return historyElements[historyElements.length - 1]?.attrs?.id
}

export const pushHistory = (node, type) => {
    node.attrs.history = [
        ...node.attrs.history,
        (historyAttrs[type] || []).reduce((a, b) => {
            a[b] = node[b]()
            return a
        }, {})
    ]
    node.attrs.history_id = node.attrs.history.length - 1
}

export const customNodeProps = ({ stage }) => {
    return {
        mdt: new Date().getTime(),
        history: [],
        history_id: 0,
        prevNode: getLastNode({ stage }) || null,
    }
}

export const hasUndo = ({ stage }) => {
    if (stage) {
        const layer = stage.findOne('#baseLayer')
        const nodes = layer?.getChildren() || []
        const historyElements = nodes.filter(f =>
            f.attrs.history
            && f.attrs.visible != false
        )
        return historyElements[0] ? false : true
    }
    return true
}

export const hasRedo = ({ stage }) => {
    if (stage) {
        return stage.attrs.lastUndo ? false : true
    }
    return true
}

export const moveUndoPointer = ({ stage, lastElement }) => {
    const layer = stage.findOne('#baseLayer')
    if (lastElement?.attrs?.history_id >= (lastElement?.attrs?.history.length - 1)) {
        stage.attrs.lastUndo = null
        // find next element
        const nodes = layer.getChildren()
        const nextElement = nodes.filter(f =>
            f.attrs.history &&
            f.attrs.prevNode == lastElement?.attrs?.id
        )
        if (nextElement[0] && lastElement?.attrs?.visible == true) {
            return nextElement[0]
        }
    }
    return lastElement
}

export const getSelectedNode = ({ stage }) => {
    const tr = stage.findOne('#transformer')
    return tr.nodes()[0] || { attrs: { nodeType: stage?.attrs?.drawMode } }
}

export const hideAllTransforms = ({ stage }) => {
    const tr = stage.findOne('#transformer')
    tr.nodes([])
    tr.hide()
}

export const aspectRatio = (originalWH, maxWH) => {
    const aspectRatioW = originalWH.width / originalWH.height
    const aspectRatioH = originalWH.height / originalWH.width
    let widthT = maxWH.height * aspectRatioW
    let heightT = widthT * aspectRatioH
    if (heightT > maxWH.height) {
        heightT = maxWH.height
        widthT = heightT * aspectRatioW
    }

    if (widthT > maxWH.width) {
        widthT = maxWH.width
        heightT = widthT * aspectRatioH
    }


    return {
        widthT,
        heightT
    }
}