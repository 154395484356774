import { alertConstant, loaderConstant } from "../constants";
import { fileHubConstants } from "../constants/fileHubConstant";
import fileHubServices from "../services/fileHubService";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

export const filehubActions = {
  getMetricsAndRecentMedia:
    (id, type, cb = () => {}) =>
    (dispatch, getState) => {
      // dispatch(request(loaderConstant.LOADING_TRUE));
      fileHubServices
        .getMetricsAndRecents(id, type)
        .then((data) => {
          dispatch(success(fileHubConstants.GET_METRICS_S, data.data));
          // dispatch(request(loaderConstant.LOADING_FALSE));
        })
        .catch((err) => {
          dispatch(failure(fileHubConstants.GET_METRICS_F, err));
          // dispatch(request(loaderConstant.LOADING_FALSE));
        });
    },
  resetMetrics: () => (dispatch, getState) => {
    dispatch(success(fileHubConstants.RESET_METRICS_S));
  },
  getAllfiles:
    ({ id, offset, search, flush = false, category, type }, cb = () => {}) =>
    (dispatch, getState) => {
      fileHubServices
        .getAllFiles({ id, offset, search, category, type })
        .then((data) => {
          dispatch(
            success(fileHubConstants.GET_ALLFILES_S, { ...data.data, flush })
          );
          cb();
        })
        .catch((err) => {
          dispatch(failure(fileHubConstants.GET_ALLFILES_F, err));
        });
    },
  resetAllfiles: () => (dispatch) => {
    dispatch(success(fileHubConstants.RESET_ALLFILES_S));
  },
  getFolderContents:
    ({ id, offset }, cb = () => {}) =>
    (dispatch, getState) => {
      fileHubServices
        .getFolderContents(id, offset)
        .then((data) => {
          dispatch(success(fileHubConstants.GET_FOLDER_CONTENTS_S, data.data));
        })
        .catch((err) => {
          dispatch(failure(fileHubConstants.GET_FOLDER_CONTENTS_F, err));
        });
    },
  createFolder:
    (data, cb = () => {}) =>
    (dispatch, getState) => {
      fileHubServices
        .createFolder(data)
        .then((data) => {
          dispatch(success(fileHubConstants.CREATE_FOLDER_S, data.data));
          dispatch(request(loaderConstant.LOADING_FALSE));
          cb();
        })
        .catch((err) => {
          dispatch(failure(fileHubConstants.CREATE_FOLDER_F, err));
          dispatch(request(loaderConstant.LOADING_FALSE));
        });
    },
  resetFolderContents:
    (cb = () => {}) =>
    (dispatch) => {
      dispatch(success(fileHubConstants.RESET_FOLDER_CONTENTS_S));
    },
  deleteFolder:
    (folderId, cb = () => {}) =>
    (dispatch, getState) => {
      dispatch(request(loaderConstant.LOADING_TRUE));
      fileHubServices
        .deleteFolder(folderId)
        .then(() => {
          dispatch(success(fileHubConstants.DELETE_FOLDER_S, folderId));
          dispatch(request(loaderConstant.LOADING_FALSE));
          cb();
        })
        .catch((err) => {
          dispatch(failure(fileHubConstants.DELETE_FOLDER_F, err));
          dispatch(request(loaderConstant.LOADING_FALSE));
        });
    },

  deleteFile:
    (fileId, cb = () => {}) =>
    (dispatch, getState) => {
      dispatch(request(loaderConstant.LOADING_TRUE));
      fileHubServices
        .deleteFile(fileId)
        .then(() => {
          dispatch(success(fileHubConstants.DELETE_FILE_S, fileId));
          dispatch(request(loaderConstant.LOADING_FALSE));
          cb();
        })
        .catch((err) => {
          dispatch(failure(fileHubConstants.DELETE_FILE_F, err));
          dispatch(request(loaderConstant.LOADING_FALSE));
        });
    },

  renameFolder:
    (folderId, newName, cb = () => {}) =>
    (dispatch, getState) => {
      dispatch(request(loaderConstant.LOADING_TRUE));
      fileHubServices
        .renameFolder(folderId, newName)
        .then(() => {
          dispatch(
            success(fileHubConstants.RENAME_FOLDER_S, { folderId, newName })
          );
          dispatch(request(loaderConstant.LOADING_FALSE));
          cb();
        })
        .catch((err) => {
          dispatch(failure(fileHubConstants.RENAME_FOLDER_F, err));
          dispatch(request(loaderConstant.LOADING_FALSE));
        });
    },

  renameFile:
    (fileId, newName, cb = () => {}) =>
    (dispatch, getState) => {
      dispatch(request(loaderConstant.LOADING_TRUE));
      fileHubServices
        .renameFile(fileId, newName)
        .then(() => {
          dispatch(
            success(fileHubConstants.RENAME_FILE_S, { fileId, newName })
          );
          dispatch(request(loaderConstant.LOADING_FALSE));
          cb();
        })
        .catch((err) => {
          dispatch(failure(fileHubConstants.RENAME_FILE_F, err));
          dispatch(request(loaderConstant.LOADING_FALSE));
        });
    },
  uploadFiles:
    (data, cb = () => {}) =>
    (dispatch, getState) => {
      dispatch(request(loaderConstant.LOADING_TRUE));
      fileHubServices
        .uploadFiles(data)
        .then(() => {
          dispatch(request(loaderConstant.LOADING_FALSE));
          cb();
        })
        .catch((err) => {
          dispatch(request(loaderConstant.LOADING_FALSE));
          dispatch(failure(fileHubConstants.RENAME_FILE_F, err));
        });
    },
  reset: () => (dispatch, getState) => {
    dispatch(success(fileHubConstants.RESET));
  },
};
