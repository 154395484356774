import { moveUndoPointer } from "./utils"

export const redo = ({ stage }) => {
    const tr = stage.findOne('#transformer')
    if (stage.attrs.lastUndo?.id) {
        const lastElement = stage.findOne(`#${stage.attrs.lastUndo?.id}`)
        const updatedEle = moveUndoPointer({ stage, lastElement })
        if (updatedEle) {
            if (updatedEle.attrs.visible == false) {
                updatedEle.show()
                if (updatedEle?.attrs?.name?.indexOf('need-tr') >= 0) {
                    tr.show()
                    tr.nodes([lastElement])
                }
            } else {
                updatedEle.attrs.undo(-1)
            }
            stage.attrs.lastUndo = {
                id: updatedEle.attrs.id
            }
        }
    }
    stage.attrs.onUpdate(stage)
}
