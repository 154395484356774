import React from 'react';
import PropTypes from 'prop-types';

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={isDanger ? 'countdown danger' : 'countdown'}>
      <p>{value}</p>
      <span>{type}</span>
    </div>
  );
};

// default props
DateTimeDisplay.defaultProps = {
  classes: {},
};

// prop types
DateTimeDisplay.propTypes = {
  classes: PropTypes.object,
};

export default DateTimeDisplay;