import { getRequest, postRequest, deleteRequest } from '../../utils/apiHelper';

export const scheduleService ={
    getScheduleMessageList,
    getSearchFlowList,
    getSearchClientList,
    getSearchTeamList,
    getMessageDetail,
    saveScheduleGroup,
    saveSchedule,
    getSelectedGroupIds,
    getAddedMessage,
    getScheduleFilter,
    saveAddMessageInput,
    deleteAddedMessage,
    updateScheduleMessage,
    updateMessageStatus,
    uploadMessageFiles,
    deleteMessageItem
}

//Schedule Message List
async function getScheduleMessageList(params){
    const response = await postRequest("schedule/getMessageList", params)
    return response;
}

//Search Flow List
async function getSearchFlowList(params) {
    const response = await getRequest("schedule/getSearchFlowList")
    return response;
}

//Search Client List
async function getSearchClientList(params) {
    const response = await getRequest("schedule/getSearchClientList")
    return response;
}

//Search Teams List
async function getSearchTeamList() {
    const response = await getRequest("schedule/getSearchTeamList")
    return response;
}

//Save Flows, Clients, Direct Schedule Group
async function saveScheduleGroup(body) {
    const response = await postRequest("schedule/saveScheduleGroup", body)
    return response;
}

//Save Schedule
async function saveSchedule(params) {
    const response = await postRequest(`schedule/saveSchedule`, params)
    return response;
}

/** Get Selected Schedule Group Ids */
async function getSelectedGroupIds(params) {
    let mode = params.mode ? `${params.mode}` : 'default';
    const response = await getRequest(`schedule/getSelectedGroup/${params.id}/${mode}`)
    return response;
}

/** Get Added Message */
async function getAddedMessage(params) {
    let mode = params.isWorkflow ? '/workflow' : ''
    const response = await getRequest(`schedule/getAddedMessage/${params.id}${mode}`)
    return response;
}

/** Get Message Detail */
async function getMessageDetail(params) {
    let mode = params.mode ? `${params.mode}` : 'default';
    const response = await getRequest(`schedule/getMessageDetail/${params.scheduleId}/${params.messageId}/${mode}`,)
    return response;
}

//Search Teams List
async function getScheduleFilter(params) {
    const response = await getRequest(`schedule/getScheduleFilter?isWorkflow=${params}`)
    return response;
}

// Save Added Message
async function saveAddMessageInput(params) {
    const response = await postRequest(`schedule/saveAddMessageInput`, params)
    return response;
}

// Update Schedule Message
async function updateScheduleMessage(params) {
    const {scheduleDetail, scheduleId, messageId} = params;
    const response = await postRequest(`schedule/updateScheduleMessage/${scheduleId}/${messageId}`, scheduleDetail)
    return response;
}

// Update Schedule Message Status
async function updateMessageStatus(params) {
    const { scheduleId, messageId,isWorkflow } = params;
    const response = await postRequest(`schedule/updateMessageStatus/${scheduleId}/${messageId}`,{isWorkflow})
    return response;
}

// Upload Message File
async function uploadMessageFiles(params, paramsIds) {
    const mode = paramsIds['mode'] ? paramsIds['mode'] : "default";
    const response = await postRequest(`schedule/uploadMessageFiles/${paramsIds.scheduleId}/${paramsIds.messageId}/${mode}`, params)
    return response;
}

// Delete Added Message
async function deleteAddedMessage(params) {
    const response = await deleteRequest(`schedule/deleteAddedMessage`, params)
    return response;
}

// Delete Message Item
async function deleteMessageItem(params) {
    const response = await deleteRequest(`schedule/deleteMessageItem`, params)
    return response;
}