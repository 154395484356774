import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import { withStyles, TextField, Typography } from "@material-ui/core";

//import styles
import styles from "./styles";

function AutoCompleteComponent(props) {

  const {
    suggestions,
    name,
    disabled,
    value,
    classes,
    showValidate,
    validationMsg,
    ...rest
  } = props;

  const filter = createFilterOptions()
  return (
    <Fragment >
      <Autocomplete
        value={value}
        classes={{ ...classes, paper: classes.paper }}
        onChange={(event, newValue) => { props.handleAutoComplete(newValue || { label: '', value: '' }, name) }}
        onInputChange={(value, eValue, reason) => { props.onInputChange(value, eValue, reason) }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              label: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={suggestions}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        style={{ width: 300 }}
        freeSolo
        renderOption={option => {
          return (
            <Fragment>
              <FiberManualRecordOutlinedIcon style={{ fill: option.color ? option.color : "#000000" }} />
              <Typography variant="subtitle1" color="textSecondary">
                {option.label}
              </Typography>
            </Fragment>
          );
        }
        }
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              value={value}
              variant="outlined"
              {...rest}
              className={classes.text}
              error={showValidate ? true : false}
              helperText={showValidate ? validationMsg : ""}
            />
          )
        }}
      />
    </Fragment>
  );
}

// default props
AutoCompleteComponent.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  name: "autocomplete",
  handleAutoComplete: () => { },
  onInputChange: () => { },
  showValidate: false,
  validationMsg: ''
};

// prop types
AutoCompleteComponent.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  handleAutoComplete: PropTypes.func,
  onInputChange: PropTypes.func,
  showValidate: PropTypes.bool,
  validationMsg: PropTypes.string
};

/** Export Component */
export const AutoCompleteChip = withStyles(styles)(AutoCompleteComponent);
