import { useState, useEffect } from "react";

function useAudioPlayer(props) {
  const { elementId } = props;
  const [duration, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState();
  const [stop, setStop] = useState(false);
  const [playNow, setPlayNow] = useState('');
  const [playSpeed, setPlaySpeed] = useState(1)

  useEffect(() => {
    const audio = document.getElementById(`${elementId}`);
    const setPlayStop = () => {
      setPlaying(false);
      setPlayNow('');
    }

    // const checkPlay = () => {
    //   if(playNow !== ''){
    //     let player = document.getElementById(playNow);
    //   }
    //   setPlayNow(audio1.id);
    // }

    audio.addEventListener("ended", setPlayStop);
    // audio.addEventListener("play", checkPlay);
    // audio.addEventListener("timeupdate", durationchange);
    return () => {
      audio.removeEventListener("ended", setPlayStop);
      // audio.removeEventListener("play", checkPlay);
    }
  }, [elementId]);

  useEffect(() => {
    const audio = document.getElementById(`${elementId}`);
    audio.playbackRate = playSpeed;

    // state setters wrappers
    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    }

    const setAudioTime = () => {
      setCurTime(audio.currentTime)
      setDuration(audio.duration);
      if (curTime === duration) {
        setPlaying(false);
      }
    };

    // DOM listeners: update React state on DOM events
    audio.addEventListener("loadeddata", setAudioData);
    audio.addEventListener("timeupdate", setAudioTime);

    // React state listeners: update DOM on React state changes
    playing ? audio.play() : audio.pause();

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    // effect cleanup
    return () => {
      audio.removeEventListener("loadeddata", setAudioData);
      audio.removeEventListener("timeupdate", setAudioTime);
    }
  });

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    setPlaySpeed,
    stop
  }
}

export default useAudioPlayer;