export const pageBuilderConstant = {
  GET_SITES_LIST_R: "GET_SITES_LIST_R",
  GET_SITES_LIST_S: "GET_SITES_LIST_S",
  GET_SITES_LIST_F: "GET_SITES_LIST_F",

  GET_SITE_DETAIL_R: "GET_SITE_DETAIL_R",
  GET_SITE_DETAIL_S: "GET_SITE_DETAIL_S",
  GET_SITE_DETAIL_F: "GET_SITE_DETAIL_F",

  CREATE_SITE_R: 'CREATE_SITE_R',
  CREATE_SITE_S: 'CREATE_SITE_S',
  CREATE_SITE_F: 'CREATE_SITE_F',

  UPDATE_SITE_R: 'UPDATE_SITE_R',
  UPDATE_SITE_S: 'UPDATE_SITE_S',
  UPDATE_SITE_F: 'UPDATE_SITE_F',

  PUBLISH_SITE_R: 'PUBLISH_SITE_R',
  PUBLISH_SITE_S: 'PUBLISH_SITE_S',
  PUBLISH_SITE_F: 'PUBLISH_SITE_F',

  DELETE_SITE_R: 'DELETE_SITE_R',
  DELETE_SITE_S: 'DELETE_SITE_S',
  DELETE_SITE_F: 'DELETE_SITE_F',

  GET_PAGE_R: "GET_PAGE_R",
  GET_PAGE_S: "GET_PAGE_S",
  GET_PAGE_F: "GET_PAGE_F",

  CREATE_PAGE_R: 'CREATE_PAGE_R',
  CREATE_PAGE_S: 'CREATE_PAGE_S',
  CREATE_PAGE_F: 'CREATE_PAGE_F',

  UPDATE_PAGE_R: "UPDATE_PAGE_R",
  UPDATE_PAGE_S: "UPDATE_PAGE_S",
  UPDATE_PAGE_F: "UPDATE_PAGE_F",

  DELETE_PAGE_R: "DELETE_PAGE_R",
  DELETE_PAGE_S: "DELETE_PAGE_S",
  DELETE_PAGE_F: "DELETE_PAGE_F",

  CHANGE_PAGE: 'CHANGE_PAGE',

  CREATE_TEMPLATE_R: 'CREATE_TEMPLATE_R',
  CREATE_TEMPLATE_S: 'CREATE_TEMPLATE_S',
  CREATE_TEMPLATE_F: 'CREATE_TEMPLATE_F',

  LIST_TEMPLATE_R: 'LIST_TEMPLATE_R',
  LIST_TEMPLATE_S: 'LIST_TEMPLATE_S',
  LIST_TEMPLATE_F: 'LIST_TEMPLATE_F',

  LIST_CATEGORY_R: 'LIST_CATEGORY_R',
  LIST_CATEGORY_S: 'LIST_CATEGORY_S',
  LIST_CATEGORY_F: 'LIST_CATEGORY_F',

  LIST_PAGE_TEMPLATE_R: 'LIST_PAGE_TEMPLATE_R',
  LIST_PAGE_TEMPLATE_S: 'LIST_PAGE_TEMPLATE_S',
  LIST_PAGE_TEMPLATE_F: 'LIST_PAGE_TEMPLATE_F',

  CLEAR_DOMAIN_DATA_ON_UNPUBLISH: 'CLEAR_DOMAIN_DATA_ON_UNPUBLISH',

  GET_PAGE_ANALYTICS_R: 'GET_PAGE_ANALYTICS_R',
  GET_PAGE_ANALYTICS_S: 'GET_PAGE_ANALYTICS_S',
  GET_PAGE_ANALYTICS_F: 'GET_PAGE_ANALYTICS_F',

  GET_PAGE_ANALYTICS_CHART_R: 'GET_PAGE_ANALYTICS_CHART_R',
  GET_PAGE_ANALYTICS_CHART_S: 'GET_PAGE_ANALYTICS_CHART_S',
  GET_PAGE_ANALYTICS_CHART_F: 'GET_PAGE_ANALYTICS_CHART_F',

  GET_FORM_LIST_R: 'GET_FORM_LIST_R',
  GET_FORM_LIST_S: 'GET_FORM_LIST_S',
  GET_FORM_LIST_F: 'GET_FORM_LIST_F',

  GET_FORM_RESPONSE_R: 'GET_FORM_RESPONSE_R',
  GET_FORM_RESPONSE_S: 'GET_FORM_RESPONSE_S',
  GET_FORM_RESPONSE_F: 'GET_FORM_RESPONSE_F',

  SET_PAGE_ANALYTICS_FILTER_S: 'SET_PAGE_ANALYTICS_FILTER_S',

  GET_ACTIVE_SITE_LIST_R: 'GET_ACTIVE_SITE_LIST_R',
  GET_ACTIVE_SITE_LIST_S: 'GET_ACTIVE_SITE_LIST_S',
  GET_ACTIVE_SITE_LIST_F: 'GET_ACTIVE_SITE_LIST_F',

  GET_CUSTOM_CODE_SITE_R: 'GET_CUSTOM_CODE_SITE_R',
  GET_CUSTOM_CODE_SITE_S: 'GET_CUSTOM_CODE_SITE_S',
  GET_CUSTOM_CODE_SITE_F: 'GET_CUSTOM_CODE_SITE_F',

  UPDATE_CUSTOM_CODE_SITE_R: 'UPDATE_CUSTOM_CODE_SITE_R',
  UPDATE_CUSTOM_CODE_SITE_S: 'UPDATE_CUSTOM_CODE_SITE_S',
  UPDATE_CUSTOM_CODE_SITE_F: 'UPDATE_CUSTOM_CODE_SITE_F',

  SAVE_EMAIL_CONFIG_R: "SAVE_EMAIL_CONFIG_R",
  SAVE_EMAIL_CONFIG_S: "SAVE_EMAIL_CONFIG_S",
  SAVE_EMAIL_CONFIG_F: "SAVE_EMAIL_CONFIG_F",

  TEST_EMAIL_CONFIG_R: "TEST_EMAIL_CONFIG_R",
  TEST_EMAIL_CONFIG_S: "TEST_EMAIL_CONFIG_S",
  TEST_EMAIL_CONFIG_F: "TEST_EMAIL_CONFIG_F",

  GET_EMAIL_CONFIG_R: "GET_EMAIL_CONFIG_R",
  GET_EMAIL_CONFIG_S: "GET_EMAIL_CONFIG_S",
  GET_EMAIL_CONFIG_F: "GET_EMAIL_CONFIG_F",
  UPDATE_EMAIL_CONFIG: "UPDATE_EMAIL_CONFIG",

  GET_SITE_SEO_SETTINGS_R: 'GET_SITE_SEO_SETTINGS_R',
  GET_SITE_SEO_SETTINGS_S: 'GET_SITE_SEO_SETTINGS_S',
  GET_SITE_SEO_SETTINGS_F: 'GET_SITE_SEO_SETTINGS_F',

  UPDATE_SITE_SEO_SETTINGS_R: 'UPDATE_SITE_SEO_SETTINGS_R',
  UPDATE_SITE_SEO_SETTINGS_S: 'UPDATE_SITE_SEO_SETTINGS_S',
  UPDATE_SITE_SEO_SETTINGS_F: 'UPDATE_SITE_SEO_SETTINGS_F',

  COMPRESS_THUMBNAIL_R: 'COMPRESS_THUMBNAIL_R',
  COMPRESS_THUMBNAIL_S: 'COMPRESS_THUMBNAIL_S',
  COMPRESS_THUMBNAIL_F: 'COMPRESS_THUMBNAIL_F',

  UPDATE_COMPRESSED_DATA: 'UPDATE_COMPRESSED_DATA'
};
