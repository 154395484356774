// Import API Helper
import { postRequest } from "../../utils/apiHelper";

export const agencyLoginService = {
    agencySignup,
    getEmailAgencies,
    agencySignupProcess,
    getPlanDetails,
    getPlanAddonDetails,
    getReferralLinkData
};

//agencySignup
async function agencySignup(params) {
    const response = await postRequest("agencyLogin/signup", params);
    return response;
}

//getEmailAgencies
async function getEmailAgencies(params) {
    const response = await postRequest("agencyLogin/emailAgencies", params);
    return response;
}

//agencySignupProcess
async function agencySignupProcess(params) {
    const response = await postRequest("agencyLogin/signupProcess", params);
    return response;
}

//agencySignupProcess
async function getPlanDetails(params) {
    const response = await postRequest("agencyLogin/getPlainDetails", params);
    return response;
}

async function getPlanAddonDetails(params){
    const response = await postRequest("agencyLogin/getPlanAddonDetails", params);
    return response;
}


// get referral link data for signup
async function getReferralLinkData(params) {
    const response = await postRequest("agencyLogin/getReferralLinkData", params);
    return response;
}