/**
 * Convert amount value to k value terms
 * @param {Amount:Number} value
 * @returns amount value in k terms in string
 */

export const convertToKTerms = (value) => {
  let valueInString = String(value);
  let result;
  if (valueInString.length > 3) {
    let endVal = valueInString.substring(valueInString.length - 3);
    let startVal = valueInString.substring(0, valueInString.length - 3);
    if (endVal[0] === "0") {
      result = startVal + "K";
    } else {
      result = `${startVal}.${endVal[0]} K`;
    }
  } else {
    result = valueInString;
  }
  return result;
};
