import { videoCallConstants } from "../constants/videoCallConstant";

export const meetingAction = {
  updateChannelStatus,
  recordStartedStatus,
  recordingStopped,
  activateChannel
}

function updateChannelStatus(msgData) {

  return {
    type: videoCallConstants.UPDATE_CHANNEL_STATUS,
    payload: msgData,
  };
}

function recordStartedStatus(data){
  console.log(data,"record started see here--------")
  return {
    type: videoCallConstants.RECORDING_STATUS_UPDATE,
    payload:data
  }
}

function recordingStopped(data){
  return {
    type:videoCallConstants.RECORDING_STOPPED_S,
    payload:data
  }
}

function activateChannel(data){
  return{
    type:videoCallConstants.ACTIVE_CHANNEL_S,
    payload:data
  }
}