const styles = (theme) => ({
    paper: {
        minWidth: '300px !important'
    },
    text:{
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child':{
        marginLeft:'10px !important'
        },
    }
});

export default styles;
