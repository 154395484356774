const styles = (theme) => ({
  emojiButton: {
    background: 'none',
    border: 'none'
  },
  mobileEmojiPicker: {
    '& em-emoji-picker': {
      width: '100%'
    }
  }
});

export default styles;
