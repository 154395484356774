const styles = (theme) => ({
  roots: {
    height: 36,
    '& .MuiOutlinedInput-root': {
      height: 36,
      minHeight: 36
    }
  },
  notesToolBar: {
    display: 'flex',
    paddingTop: '10px',
    justifyContent: 'center',
    marginTop: 20,
    position: 'sticky',
    bottom: 0,
    flexWrap: 'wrap',

    paddingBottom: '10px',
    width: '100%',
    background: '#fff',
    zIndex: 99,
    '& .editor-controls': {
      display: 'flex'
    },
    '& .RichEditor-styleButton': {
      width: '25px',
      height: '25px',
      marginBottom: 5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #ddd',
      borderRadius: '3px',
      marginRight: '10px',
      color: '#999',
      cursor: 'pointer',
      '& i': {
        color: '#999'
      },
      "&.RichEditor-activeButton": {
        background: "#ddd",
        borderColor: "#222"
      }
    }
  },
  notesEditArea: {
    // height: "calc(100vh - 378px) !important",
    // overflowY: "scroll",
    // overflowX: "hidden"
  },
  customButtons: {
    fontSize: "12px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #ddd",
    borderRadius: "3px",
  },
  deleteButton:{
    "&:hover":{
      background: "#eda3a4",
      border: "1px solid #eda3a4",
    }
  }

});

export default styles;
