export const tasksConstant = {

    // get all task groups
    GET_TASKS_GROUPS_R: "GET_TASKS_GROUPS_R",
    GET_TASKS_GROUPS_S: "GET_TASKS_GROUPS_S",
    GET_TASKS_GROUPS_F: "GET_TASKS_GROUPS_F",

    // save task preference
    SAVE_TASK_PREFERENCE_R: "SAVE_TASK_PREFERENCE_R",
    SAVE_TASK_PREFERENCE_S: "SAVE_TASK_PREFERENCE_S",
    SAVE_TASK_PREFERENCE_F: "SAVE_TASK_PREFERENCE_F",

    // get all task groupings
    GET_TASK_GROUP_TYPE_R: "GET_TASK_GROUP_TYPE_R",
    GET_TASK_GROUP_TYPE_S: "GET_TASK_GROUP_TYPE_S",
    GET_TASK_GROUP_TYPE_F: "GET_TASK_GROUP_TYPE_F",

    GET_TASK_FILTERS_R: "GET_TASK_FILTERS_R",
    GET_TASK_FILTERS_S: "GET_TASK_FILTERS_S",
    GET_TASK_FILTERS_F: "GET_TASK_FILTERS_F",

    UPDATE_TASK_FILTER: "UPDATE_TASK_FILTER",

    CLEAR_FILTER_DATA:"CLEAR_FILTER_DATA",

    GET_TASK_DETAILS_R: "GET_TASK_DETAILS_R",
    GET_TASK_DETAILS_S: "GET_TASK_DETAILS_S",
    GET_TASK_DETAILS_F: "GET_TASK_DETAILS_D",

    COMPLETE_TASKS_R: "COMPLETE_TASKS_R",
    COMPLETE_TASKS_S: "COMPLETE_TASKS_S",
    COMPLETE_TASKS_F: "COMPLETE_TASKS_F",

    COMPLETE_TASK_REDUCER: "COMPLETE_TASK_REDUCER",

    GET_TASK_LIST_R: "GET_TASK_LIST_R",
    GET_TASK_LIST_S: "GET_TASK_LIST_S",
    GET_TASK_LIST_F: "GET_TASK_LIST_F",

    UPDATE_TASK_REDUCER: "UPDATE_TASK_REDUCER",

    DELETE_COMMENT_LIST: "DELETE_COMMENT_REDUCER",

    GET_TASK_COMMENTS_COUNT_R: "GET_TASK_COMMENTS_COUNT_R",
    GET_TASK_COMMENTS_COUNT_S: "GET_TASK_COMMENTS_COUNT_S",
    GET_TASK_COMMENTS_COUNT_F: "GET_TASK_COMMENTS_COUNT_F",

    GET_TASK_COMMENTS_LIST_R: "GET_TASK_COMMENTS_LIST_R",
    GET_TASK_COMMENTS_LIST_S: "GET_TASK_COMMENTS_LIST_S",
    GET_TASK_COMMENTS_LIST_F: "GET_TASK_COMMENTS_LIST_F",

    UPDATE_COMMENT_COUNT: 'UPDATE_COMMENT_COUNT',

    SAVE_TASK_R: "SAVE_TASK_R",
    SAVE_TASK_S: "SAVE_TASK_S",
    SAVE_TASK_F: "SAVE_TASK_F",

    DELETE_TASKS_R: 'DELETE_TASKS_R',
    DELETE_TASKS_S: 'DELETE_TASKS_S',
    DELETE_TASKS_F: 'DELETE_TASKS_F',

    SAVE_COMMENT_R: 'SAVE_COMMENT_R',
    SAVE_COMMENT_S: 'SAVE_COMMENT_S',
    SAVE_COMMENT_F: 'SAVE_COMMENT_F',

    EDIT_COMMENT_R: 'EDIT_COMMENT_R',
    EDIT_COMMENT_S: 'EDIT_COMMENT_S',
    EDIT_COMMENT_F: 'EDIT_COMMENT_F',

    CLEAR_COMMENT_S: 'CLEAR_COMMENT_S',

    DELETE_COMMENT_R: 'DELETE_COMMENT_R',
    DELETE_COMMENT_S: 'DELETE_COMMENT_S',
    DELETE_COMMENT_F: 'DELETE_COMMENT_F',

    RESET_TASK_REDUCER: "RESET_TASK_REDUCER",

    GET_DEFAULT_LIST_R: "GET_DEFAULT_LIST_R",
    GET_DEFAULT_LIST_S: "GET_DEFAULT_LIST_S",
    GET_DEFAULT_LIST_F: "GET_DEFAULT_LIST_F",

    GET_DEFAULT_COUNT_R: "GET_DEFAULT_COUNT_R",
    GET_DEFAULT_COUNT_S: "GET_DEFAULT_COUNT_S",
    GET_DEFAULT_COUNT_F: "GET_DEFAULT_COUNT_F",

    UPDATE_SHOW_HIDE_SUBTASK: "UPDATE_SHOW_HIDE_SUBTASK",

    UPDATE_INLINE_TASK_R: "UPDATE_INLINE_TASK_R",
    UPDATE_INLINE_TASK_S: "UPDATE_INLINE_TASK_S",
    UPDATE_INLINE_TASK_F: "UPDATE_INLINE_TASK_F",

    GET_TASK_ACTIVITY_R: "GET_TASK_ACTIVITY_R",
    GET_TASK_ACTIVITY_S: "GET_TASK_ACTIVITY_S",
    GET_TASK_ACTIVITY_F: "GET_TASK_ACTIVITY_F",

    SET_TODO_STATUS_S: "SET_TODO_STATUS_S",

    UPDATE_SELECTED_TASK: "UPDATE_SELECTED_TASK",

    UPDATE_TASK_TEMPLATE_DATA: "UPDATE_TASK_TEMPLATE_DATA",

    SAVE_TASK_TEMPLATE_R: "SAVE_TASK_TEMPLATE_R",
    SAVE_TASK_TEMPLATE_S: "SAVE_TASK_TEMPLATE_S",
    SAVE_TASK_TEMPLATE_F: "SAVE_TASK_TEMPLATE_F",

    GET_TEMPLATE_LIST_R: "GET_TEMPLATE_LIST_R",
    GET_TEMPLATE_LIST_S: "GET_TEMPLATE_LIST_S",
    GET_TEMPLATE_LIST_F: "GET_TEMPLATE_LIST_F",

    RESET_TASK_TEMPLATE_DATA: "RESET_TASK_TEMPLATE_DATA",

    GET_TEMPLATE_DETAIL_R: "GET_TEMPLATE_DETAIL_R",
    GET_TEMPLATE_DETAIL_S: "GET_TEMPLATE_DETAIL_S",
    GET_TEMPLATE_DETAIL_F: "GET_TEMPLATE_DETAIL_F",

    GET_TEMPLATE_TITLE_LIST_R: "GET_TEMPLATE_TITLE_LIST_R",
    GET_TEMPLATE_TITLE_LIST_S: "GET_TEMPLATE_TITLE_LIST_S",
    GET_TEMPLATE_TITLE_LIST_F: "GET_TEMPLATE_TITLE_LIST_F",

    ADD_TASK_TO_TEMPLATE_R: "ADD_TASK_TO_TEMPLATE_R",
    ADD_TASK_TO_TEMPLATE_S: "ADD_TASK_TO_TEMPLATE_S",
    ADD_TASK_TO_TEMPLATE_F: "ADD_TASK_TO_TEMPLATE_F",

    CREATE_TASK_FROM_TEMPLATE_R: "CREATE_TASK_FROM_TEMPLATE_R",
    CREATE_TASK_FROM_TEMPLATE_S: "CREATE_TASK_FROM_TEMPLATE_S",
    CREATE_TASK_FROM_TEMPLATE_F: "CREATE_TASK_FROM_TEMPLATE_F",

    UPDATE_TEMPLATE_FILTER: "UPDATE_TEMPLATE_FILTER",

    UPDATE_TASK_ORDER_R: "UPDATE_TASK_ORDER_R",
    UPDATE_TASK_ORDER_S: "UPDATE_TASK_ORDER_S",
    UPDATE_TASK_ORDER_F: "UPDATE_TASK_ORDER_F",

    UPDATE_GROUPING_TASK_ORDER_R: "UPDATE_GROUPING_TASK_ORDER_R",
    UPDATE_GROUPING_TASK_ORDER_S: "UPDATE_GROUPING_TASK_ORDER_S",
    UPDATE_GROUPING_TASK_ORDER_F: "UPDATE_GROUPING_TASK_ORDER_F",

}
