import React from 'react';
import PropTypes from 'prop-types';

//Import Components
import Realistic from './Realistic';
import SchoolPride from './SchoolPride';
import FireWorks from './FireWorks';
import Snow from './Snow';

const ConfettiComponent = (props) => {
    const { taskCount } = props;
    
    /* 
    const [audio] = useState(new Audio(audioUrl));
    const [playing, setPlaying] = useState(true);


    useEffect(() => {
        playing ? audio.play() : audio.pause();
    },
        [playing]
    );

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);
    */
    return (
        <>
            {
                taskCount === 1 ?
                    <Realistic text_message={"Yay, you've completed your first task today!"} /> :
                    taskCount === 3 ?
                        <SchoolPride text_message={"Wow, 3 tasks already! Today looks like a good day"} /> :
                        taskCount === 5 ?
                            <FireWorks text_message={"FIVE! That's amazing, you should be proud"} /> :
                            taskCount === 10 ?
                                <Snow text_message={"Ok... tell us your secrets. 10 tasks in one day, wow!"} /> :
                                null
            }
        </>
    )
}

// default props
ConfettiComponent.defaultProps = {
    classes: {},
    taskCount: 1,
};

// prop types
ConfettiComponent.propTypes = {
    classes: PropTypes.object,
    taskCount: PropTypes.any,
};

/** Export Component */
export const Confetti = ConfettiComponent;
