import React from 'react';
import PropTypes from 'prop-types';
import { useCountdown } from './useCountdown';

import ShowCounter from './ShowCounter';
import ExpiredNote from './ExpiredNote';

const CountdownTimerComponent = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNote />;
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};


// default props
CountdownTimerComponent.defaultProps = {
    classes: {},
};

// prop types
CountdownTimerComponent.propTypes = {
    classes: PropTypes.object,
};

/** Export Component */
export const CountdownTimer = CountdownTimerComponent;