import React from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import iconImage from "../../assets/images/emoji/face-with-party-horn-and-party-hat.png";

import styles from './styles';

function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
}

const canvasStyles = {
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 99,
}

class Fireworks extends React.Component {
    constructor(props) {
        super(props);
        this.isAnimationEnabled = false;
        this.animationInstance = null;
        this.intervalId = null;
    }

    componentDidMount() {
        this.startAnimation();

        this.timer = setInterval(
            () => this.stopAnimation(),
            10000,
        );
    }

    getAnimationSettings(originXA, originXB) {
        return {
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            particleCount: 150,
            origin: {
                x: randomInRange(originXA, originXB),
                y: Math.random() - 0.2
            },
            zIndex: 9,
        }
    }

    nextTickAnimation = () => {
        this.animationInstance && this.animationInstance(this.getAnimationSettings(0.1, 0.3));
        this.animationInstance && this.animationInstance(this.getAnimationSettings(0.7, 0.9));
    }

    startAnimation() {
        if (!this.isAnimationEnabled) {
            this.isAnimationEnabled = true;
            this.intervalId = setInterval(this.nextTickAnimation, 400);
        }
    }

    pauseAnimation() {
        this.isAnimationEnabled = false;
        return this.intervalId && clearInterval(this.intervalId);
    }

    stopAnimation() {
        this.isAnimationEnabled = false;
        this.animationInstance && this.animationInstance.reset();
        return this.intervalId && clearInterval(this.intervalId);
    }

    handlerClickStart = () => {
        this.startAnimation();
    }

    handlerClickPause = () => {
        this.pauseAnimation();
    }

    handlerClickStop = () => {
        this.stopAnimation();
    }

    componentWillUnmount() {
        this.isAnimationEnabled = false;
        this.intervalId && clearInterval(this.intervalId);
    }

    getInstance = (instance) => {
        this.animationInstance = instance
    }

    render() {
        const { classes, text_message } = this.props;
        return (
            <>
                <div className={classes.completeMsgContainer}>
                    <span className={classes.completeBox}>
                        {text_message}
                        <img src={iconImage} alt={text_message} />
                    </span>
                </div>
                <ReactCanvasConfetti refConfetti={this.getInstance} style={canvasStyles} />
            </>
        );
    }
}


// default props
Fireworks.defaultProps = {
    classes: {},
    text_message: "",
};

// prop types
Fireworks.propTypes = {
    classes: PropTypes.object,
    text_message: PropTypes.string,
};

export default withStyles(styles)(Fireworks)