import { Grid } from "@material-ui/core";
import React from "react";
import ImageNotFound from '../../../assets/images/404.jpg'

function Page404() {
  return (
    <Grid container alignItems="center" justifyContent="center" style={{background: '#fff'}}>
      <img src={ImageNotFound} alt="image" style={{ maxWidth: "400px" }} />
    </Grid>
  )
}

export default Page404;
