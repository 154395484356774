import { mediaConstant } from "../constants";

const initialState = {
    loading: false,
    media: {
        folders: [],
        messages: []
    },
    folderView: false,
    fileType: null,
    groupView: false,

}

export default function media(state = initialState, action) {
    switch (action.type) {

        case mediaConstant.GET_ALL_MEDIA_R:
            return {
                ...state,
            }
        case mediaConstant.GET_ALL_MEDIA_S:
            return {
                ...state,
                media: action.data.data,
                folderView: false
            }
        case mediaConstant.GET_ALL_MEDIA_F:
            return {
                ...state,
                media: initialState.media
            }

        case mediaConstant.UPDATE_FOLDER_VIEW:
            return {
                ...state,
                folderView: action.data
            }

        case mediaConstant.UPDATE_GROUP_VIEW:
            return {
                ...state,
                groupView: action.data
            }

        case mediaConstant.UPDATE_FILE_TYPE:
            return {
                ...state,
                fileType: action.data
            }
        case mediaConstant.CREATE_FOLDER_R:
        case mediaConstant.CREATE_FOLDER_S:
        case mediaConstant.CREATE_FOLDER_F:
            return state

        case mediaConstant.UPDATE_FOLDER_NAME_R:
        case mediaConstant.UPDATE_FOLDER_NAME_S:
        case mediaConstant.UPDATE_FOLDER_NAME_F:
            return state

        case mediaConstant.DELETE_FOLDER_R:
        case mediaConstant.DELETE_FOLDER_S:
        case mediaConstant.DELETE_FOLDER_F:
            return state

        case mediaConstant.DELETE_FILE_R:
        case mediaConstant.DELETE_FILE_S:
        case mediaConstant.DELETE_FILE_F:
            return state

        case mediaConstant.UPDATE_FILE_R:
        case mediaConstant.UPDATE_FILE_S:
        case mediaConstant.UPDATE_FILE_F:
            return state

        case mediaConstant.UPLOAD_FILE_R:
        case mediaConstant.UPLOAD_FILE_S:
        case mediaConstant.UPLOAD_FILE_F:
            return state

        case mediaConstant.UPDATE_FOLDER_FILE_R:
        case mediaConstant.UPDATE_FOLDER_FILE_S:
        case mediaConstant.UPDATE_FOLDER_FILE_F:
            return state

        default:
            return state
    }
}
