export const authConstant = {

    //sign up through email
    USER_LOGIN_R: "USER_LOGIN_R",
    USER_LOGIN_S: "USER_LOGIN_S",
    USER_LOGIN_F: "USER_LOGIN_F",

    //forget password
    FORGET_PASSWORD_R: "FORGET_PASSWORD_R",
    FORGET_PASSWORD_S: "FORGET_PASSWORD_S",
    FORGET_PASSWORD_F: "FORGET_PASSWORD_F",

    //reset password
    RESET_PASSWORD_R: "RESET_PASSWORD_R",
    RESET_PASSWORD_S: "RESET_PASSWORD_S",
    RESET_PASSWORD_F: "RESET_PASSWORD_F",

    //logout 
    LOGOUT_R: "LOGOUT_R",
    LOGOUT_S: "LOGOUT_S",
    LOGOUT_F: "LOGOUT_F",

    //create user 
    SIGNUP_R: "SIGNUP_R",
    SIGNUP_S: "SIGNUP_S",
    SIGNUP_F: "SIGNUP_F",

    //update create user  data
    UPDATE_SIGNUP_DATA_R: "UPDATE_SIGNUP_DATA_R",
    UPDATE_SIGNUP_DATA_S: "UPDATE_SIGNUP_DATA_S",
    UPDATE_SIGNUP_DATA_F: "UPDATE_SIGNUP_DATA_F",

    //update payment user  data
    UPDATE_PAYMENT_DATA_R: "UPDATE_PAYMENT_DATA_R",
    UPDATE_PAYMENT_DATA_S: "UPDATE_PAYMENT_DATA_S",
    UPDATE_PAYMENT_DATA_F: "UPDATE_PAYMENT_DATA_F",

    GET_PROFILE_R:"GET_PROFILE_R",
    GET_PROFILE_S:"GET_PROFILE_S",
    GET_PROFILE_F:"GET_PROFILE_F",

    UPDATE_STEP_2:"UPDATE_STEP_2",
    UPDATE_STEP_3:"UPDATE_STEP_3",

    SOCIAL_LOGIN_EMAIL_REQ: "SOCIAL_LOGIN_EMAIL_REQ",
    SOCIAL_SIGNUP_EMAIL_REQ: "SOCIAL_SIGNUP_EMAIL_REQ",

    SHOW_POPUP_LOGIN : "SHOW_POPUP_LOGIN",

    GET_UPDATED_STATUS_R:"GET_UPDATED_STATUS_R",
    GET_UPDATED_STATUS_S:"GET_UPDATED_STATUS_S",
    GET_UPDATED_STATUS_F:"GET_UPDATED_STATUS_F",

    GET_PRODUCTS_LIST_R:"GET_PRODUCTS_LIST_R",
    GET_PRODUCTS_LIST_S:"GET_PRODUCTS_LIST_S",
    GET_PRODUCTS_LIST_F:"GET_PRODUCTS_LIST_F",


    UPDATE_FLOWS_ORDER: "UPDATE_FLOWS_ORDER",
    UPDATE_CLIENTS_ORDER:"UPDATE_CLIENTS_ORDER",

    SAVE_TASK_PREFERENCE: "SAVE_TASK_PREFERENCE",
    UPDATE_USER_PREFERENCE: "UPDATE_USER_PREFERENCE",

    GET_GOOGLE_AUTH_URL_R: 'GET_GOOGLE_AUTH_URL_R',
    GET_GOOGLE_AUTH_URL_S: 'GET_GOOGLE_AUTH_URL_S',
    GET_GOOGLE_AUTH_URL_F: 'GET_GOOGLE_AUTH_URL_F',

    UPDATE_PAYMENT_STATUS_S : 'UPDATE_PAYMENT_STATUS_S',

    GET_PAYMENT_STATUS_R : 'GET_PAYMENT_STATUS_R',
    GET_PAYMENT_STATUS_S : 'GET_PAYMENT_STATUS_S',
    GET_PAYMENT_STATUS_F : 'GET_PAYMENT_STATUS_F',

    AGENCY_LOGIN_SIGNUP_S:"AGENCY_LOGIN_SIGNUP_S",
    CLEAR_STATE_VALUES_S:"CLEAR_STATE_VALUES_S"

};
