
import { commonService } from "../services";


// All login based actions
export const commonAction = {
  getUrlMetadata
};

/**
 * Request Login
 */
function getUrlMetadata(params, successCallback = () => { }, errorCallback = () => { }) {
  return () => {
    commonService.getUrlMetadata(params).then((data) => {
      successCallback(data?.data || null);
    }, (error) => {
      errorCallback(error);
    }
    );
  };
}
