// import constants
import { alertConstant } from "../constants";
import { baseAction } from "./baseAction";
const { success, failure } = baseAction;

// Export All Alert Actions
export const alertAction = {
  successAlert,
  info,
  error,
  clear
};

/**
 *success message for any alert
 *
 * @param {*} message
 * @returns
 */
function successAlert(message) {
    return success(alertConstant.ALERT_SUCCESS, message);

};

/**
 *any alert information
 *
 * @param {*} message
 * @returns
 */
function info(message) {
    return success(alertConstant.ALERT_INFO, message);
};

/**
 *Error message for any alert
 *
 * @param {*} message
 * @returns
 */
function error(message) {
   return failure(alertConstant.ALERT_ERROR, message)
};

/**
 *Clear alert
 *
 * @returns
 */
function clear() {
  return { type: alertConstant.ALERT_CLEAR };
};
