import React from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Grid, Typography, withStyles } from "@material-ui/core";
// import styles from "./styles";

// Import Button component
import { Button } from "../../../components/Button";

/**
 * VideoCallRecordingPopUp Component
 *
 * @function VideoCallRecordingPopUp
 */
function VideoCallRecordingPopUp(props) {
    const { classes, open, title, content, handleStartAction, handleCloseAction, startButtonTitle, closeButtonTitle,startRecordResponse,isRecording=false,stopRecording,user_id, checkCameraActive, checkMicrophoneActive, activePopup, } = props;

    const handleClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleCloseAction();
    };

    const handleStart = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleStartAction();
    };
    return (
        <>
         {!activePopup && <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="video-call-recording-title"
            aria-describedby="video-call-recording-description"
            style={{ borderRadius: "12px !important", zIndex: 9999 }}
            // className={`${classes.MuiBackdropRoot} ${classes.MuiPaperRounded}`}
        >
          <><DialogTitle id="video-call-recording-title">
                <Typography color="primary" style={{ textAlign: 'left' }} variant="h4">
                   {isRecording ? "Stop recording this meeting?" :title}
                </Typography>
            </DialogTitle>
            <DialogContent style={{textAlign: 'left'}}>
                <Typography color="textPrimary">
                    {
                         parseInt(startRecordResponse?.recordStartedUid) === parseInt(user_id) && isRecording ? "The recording will be saved and send to your email" : isRecording ?  "Recording already in process" : content
                    }
                   
                </Typography>
            </DialogContent>
            <DialogActions className={"justifyLeft pb16"} style={{paddingRight:"20px"}}>
                <Button
                    onClick={handleClose}
                    disableElevation
                    autoFocus
                    className='pl-3 pr-3 pt-1 pb-1'
                    variant="contained"
                    style={{ fontSize: '14px !important' ,fontWeight:"bold"}}
                    color='secondary'
                >
                    {closeButtonTitle}
                </Button>
                {
                  !isRecording &&
                    <Button
                    // onClick={ stopRecording}
                    onClick={handleStart}
                    disableElevation
                    className={`${isRecording ? 'blueBtn pl-3 pr-3 pt-1 pb-1' :'blueBtn pl-3 pr-3 pt-1 pb-1'}`}
                    variant="contained"
                    style={{ fontSize: '22px !important',fontWeight:"bold" }}
                    color='primary'
                    >
                    {!isRecording ? startButtonTitle : "Stop Recording"}
                </Button>
                    
                }
                {
                    isRecording &&  parseInt(startRecordResponse?.recordStartedUid) === parseInt(user_id)  &&

                    <Button
                    // onClick={ stopRecording}
                    onClick={ stopRecording}
                    disableElevation
                    className={`${isRecording ? 'blueBtn pl-3 pr-3 pt-1 pb-1' :'blueBtn pl-3 pr-3 pt-1 pb-1'}`}
                    variant="contained"
                    style={{ fontSize: '22px !important',fontWeight:"bold" }}
                    color='primary'
                    >
                    {"Stop Recording"}
                </Button>
                }
            </DialogActions></> 
        </Dialog>}
        {activePopup && 
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="video-call-recording-title"
            aria-describedby="video-call-recording-description"
            style={{ borderRadius: "12px !important", zIndex: 9999 }}>
             <DialogContent><Grid style={{ padding: "15px", gap: "5px" }}>
                <Typography color="textPrimary" variant="h6" style={{ fontSize: "16px", fontWeight: 500 }}>
                {`It seems that your device does not have access to ${checkCameraActive && checkMicrophoneActive ? 'a camera and microphone' : checkCameraActive ? 'a camera' : checkMicrophoneActive ? 'a microphone' : ''}, or they are not available.`}</Typography> 
                <DialogActions>
                <Button onClick={handleClose}
                    disableElevation
                    className="blueBtn pl-3 pr-3 pt-1 pb-1"
                    variant="contained"
                    style={{ fontSize: '22px !important', fontWeight: "bold" }}
                    color='primary'>Close</Button></DialogActions></Grid> </DialogContent>
            </Dialog>
            }
        </>
    );
}

// Default props
VideoCallRecordingPopUp.defaultProps = {
    open: false,
    title: 'Make sure everyone is ready',
    content: "Recording a meeting without the consent of all participants may be illegal and actionable. You should obtain consent to record this meeting from all participants, including external guests who join late.",
    startButtonTitle: 'Start',
    closeButtonTitle: 'Cancel',
    handleStartAction: () => { },
    handleCloseAction: () => { }
};

// Prop types
VideoCallRecordingPopUp.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string,
    content: PropTypes.string,
    startButtonTitle: PropTypes.string,
    closeButtonTitle: PropTypes.string,
    handleStartAction: PropTypes.func.isRequired,
    handleCloseAction: PropTypes.func.isRequired
};

/** Export Component */
export default VideoCallRecordingPopUp
