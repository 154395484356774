import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from "moment-timezone";
import { isMobile } from "react-device-detect";
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';
import { Typography } from '../Typography';
import styles from './styles'
import './styles.css'

function ClockComp({ cityName, dayTime, tZone, classes, country }) {

  const [value, setValue] = useState(moment(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)).tz(tZone) ? moment(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)).tz(tZone)["_d"] : 0);

  useEffect(() => {
    const interval = setInterval(
      () => {
        setValue(moment(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)).tz(tZone) ? moment(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)).tz(tZone)["_d"] : 0)
      },
      1000);

    return () => {
      clearInterval(interval);
    }
  }, [tZone]);

  let hours = moment(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)).tz(tZone) ? moment(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)).tz(tZone)["_d"].getHours() : 0;
  /*   hours = (hours + 24 - 2) % 24;
    let mid = 'am';
    if (hours == 0) {
      hours = 12;
    }
    else if (hours > 12) {
      hours = hours % 12;
      mid = 'pm';
    } */
  let day = ""
  try {
    const toDate = moment(new Date()).format("YYYY-MM-DD")
    const tzDate = moment(new Date(value)).format("YYYY-MM-DD")
    day = toDate == tzDate ? "Today" : toDate > tzDate ? "Yesterday" : "Tomorrow"
  } catch (err) {
    console.error(err)
  }

  return (
    <React.Fragment>

      {
        isMobile ?
          <>
            <div className={classes.clockListContainer}>
              <div className='cityInfo'>
                <Typography variant="h2" align="left">{cityName}</Typography>
                <Typography variant="subtitle1" color="textSecondary" align="left">{country}</Typography>
              </div>
              <div className='timeInfo'>
                <Typography variant="h2" align="left">{moment(new Date(value)).format("hh:mm a")}</Typography>
                <Typography variant="subtitle2" color="textSecondary" align="left">{moment(new Date(value)).format("ddd, Do MMMM")}</Typography>
              </div>
            </div>
          </> :
          <>
            <div className={`clockContainer ${hours >= 6 && hours < 18 ? "" : "nightTime"}`}>
              <Clock value={value} renderNumbers={true} size="92" secondHandLength="60" minuteHandLength="55" hourHandLength="40" hourMarksWidth="0" hourHandWidth="3" renderMinuteMarks={false} />
              <Typography variant="h8" className="pt-1" align="center">{cityName}</Typography>
              <Typography variant="body2" color="textSecondary" align="center">{day}</Typography>
            </div>
            <div className="borderLineClock"></div>
          </>
      }

    </React.Fragment>
  )
}


export const ClockComponent = withStyles(styles)(ClockComp)