import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import { Grid, IconButton, withStyles, CardMedia, CircularProgress } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import { formatFileSize } from '../../utils/chatHelper';
import { isFlozyMobileApp } from "../../utils"


//Import Images
import { DownloadCircleIcon } from '../../assets/svg'
//Import Styles
import styles from "./styles";
import { saveAs } from 'file-saver';
import { Editor, EditorState } from 'draft-js';
import { createEditorStateWithText } from '@draft-js-plugins/editor';
import ChatEditor from "../FlozyEditor/ChatEditor/chatEditor";


const styleMap = {
    CODE: {
        backgroundColor: '#111',
        color: '#fff',
        fontSize: '1.5rem',
        padding: '2px',
    },
};


const DocsMessage = (props) => {
    const { classes, message, handleUndo, undo, fromCall, msgComponentType, isSender, userId, type } = props;
    const { progress_bar } = useSelector(s => s.chatv2)
    const uploadProgress = Object?.values(progress_bar?.[type] || {})?.[0] || 0
    const media = message?.message_file ? JSON.parse(message.message_file) : null;
    const previewFile = message?.uploadPreviews?.file
    let fileName = typeof media?.name === 'string' ? media?.name : 'null.jpg';
    let fileExtension = fileName ? fileName.split('.').pop() : 'null';
    let previewType = message?.uploadPreviews?.file ? previewFile?.type?.split('/').pop() : 'null'
    const [imageSrc, setImageSrc] = useState(`./icons/${previewType}.svg`);

    // const handleImageError = () => {
    //     setImageSrc(`./icons/jpg.svg`);
    // };
    // useEffect(() => {
    //     if (fileExtension == 'mov') {
    //         setImageSrc(`./icons/mp4.svg`)
    //     }
    //     else if (fileExtension === 'xls' || fileExtension === 'xlsx') {
    //         setImageSrc(`./icons/xls.svg`)
    //     }
    //     else if (fileExtension == 'm4a' || fileExtension == 'mp3') {
    //         setImageSrc(`./icons/doc.svg`)
    //     }
    //     else if (fileExtension == 'txt') {
    //         setImageSrc(`./icons/txt.svg`)
    //     }
    //     else if (fileExtension == 'pdf') {
    //         setImageSrc(`./icons/pdf.svg`)
    //     }
    // }, [media])

    useEffect(() => {
        const extensionMapping = {
            'mov': './icons/mp4.svg',
            'xls': './icons/xls.svg',
            'xlsx': './icons/xls.svg',
            'm4a': './icons/doc.svg',
            'mp3': './icons/doc.svg',
            'txt': './icons/txt.svg',
            'pdf': './icons/pdf.svg'
        };
        setImageSrc(extensionMapping[fileExtension] || './icons/jpg.svg');
    }, [media]);

    const editor = useRef(null);

    // const initialState = notesContent;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        let notesContent = "";
        try {
            notesContent = message?.message_text ? createEditorStateWithText(message?.message_text?.trim()) : EditorState.createEmpty();
        } catch (err) {
            notesContent = EditorState.createEmpty()
        }
        setEditorState(notesContent);
    }, [message?.message_text])

    const onChange = (editorState) => {
        setEditorState(editorState);
    };


    return (
        <>
            {(!undo && !message?.deleted && message?.message_file) ? (
                <Grid container className={`${isFlozyMobileApp() ? classes.ChatdocumentListApp : classes.ChatdocumentList} ${message?.message_text?.trim() ? classes.documentText : ''}`} alignItems="center" wrap="nowrap">
                    <Grid className='dflex alignCenter'>
                    <CardMedia
                        className={classes.chatBoxFileIcon}
                        component="img"
                        alt="FT"
                        src={imageSrc}
                    // onError={handleImageError}
                    />
                    <Grid className={`${isFlozyMobileApp() ? classes.documenTitleApp : classes.documenTitle} ${message?.message_text?.trim() ? 'isText' : ''}`}>
                        {media && media?.url ? (
                            <>
                                <div className="oneLineEllip">
                                    {media?.name?.length > 25
                                        ? `${media?.name.substring(0, 25)}...`
                                        : media?.name}
                                </div>
                                <div className="oneLineEllip">
                                    {media?.size && formatFileSize(media?.size)} {fileExtension}
                                </div>
                            </>
                        ) : (
                            <Skeleton variant="rect" width={210} height={118} />
                        )}
                    </Grid>
                    </Grid>
                    <Grid>
                    {fromCall !== 'star_messages' && (
                        <IconButton className={msgComponentType === 'sent' ? '' : classes.downloadIcon} onClick={() => { saveAs(media?.url, media?.name) }}>
                            <DownloadCircleIcon fill={msgComponentType === 'sent'  && message.message_text== ""  ? '#fff' : '#64748B'} />
                        </IconButton>
                    )}
                </Grid>
                </Grid>
            ) : message?.uploadPreviews && !message?.deleted ? (
                <Grid container className={`${isFlozyMobileApp() ? classes.ChatdocumentListApp : classes.ChatdocumentList} ${message?.message_text?.trim() ? classes.documentText : ''}`} alignItems="center" wrap="nowrap">
                    <Grid item className='dflex alignCenter'>
                        <CardMedia
                            className={classes.chatBoxFileIcon}
                            component="img"
                            alt="FT"
                            src={imageSrc}
                        // onError={handleImageError}
                        />
                        <Grid className={`${isFlozyMobileApp() ? classes.documentTitleApp : classes.documenTitle} ${message?.message_text?.trim() ? 'isText' : ''}`}>
                            {message && message?.uploadPreviews ? (
                                <>
                                    <div className="oneLineEllip">
                                        {previewFile?.name?.length > 20
                                            ? `${previewFile.name.substring(0, 20)}...`
                                            : previewFile?.name}
                                    </div>
                                    <div className="oneLineEllip">
                                        {previewFile?.size && formatFileSize(previewFile?.size)} {previewType}
                                    </div>
                                </>
                            ) : (
                                <Skeleton variant="rect" width={210} height={118} />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item className="progress-container">
                        {fromCall !== 'star_messages' && (
                            <>
                                <CircularProgress
                                    size={30}
                                    color={'white'}
                                    variant="determinate"
                                    value={100}
                                    className="circular-progress background"
                                    style={{ bottom: message?.message_text?.trim() ? '30px' : '0' }}
                                />
                                <CircularProgress
                                    size={30}
                                    color={'black'}
                                    variant="determinate"
                                    value={uploadProgress}
                                    className="circular-progress foreground"
                                    style={{ bottom: message?.message_text?.trim() ? '30px' : '0' }}
                                />
                            </>
                        )}
                    </Grid>
                </Grid>
            ) : (
                <span className="deletedMessage">
                    This message has been deleted
                    {message?.message_from === userId ? (
                        <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className="cursorPointer" onClick={() => handleUndo(message.id)}>
                            undo
                        </a>
                    ) : null}
                </span>
            )}

            {message?.message_text?.trim() && !message?.deleted && (
                <Grid>
                    <div className={`${message.star && userId === message.star_user_id ? classes.textMsgBubbleStar : classes.textMsgBubble} disable-user-select p5`}>
                        {/* <Editor
                            customStyleMap={styleMap}
                            editorState={editorState}
                            onChange={onChange}
                            ref={editor}
                            readOnly={true}
                        /> */}
                        <ChatEditor
                            className={'chatEditorRoot'}
                            id={message?.id || 1}
                            content_status={'loaded'}
                            content={message?.message_raw_text || message?.message_text || ""}
                            readOnly={true}
                            msgComponentType={msgComponentType}
                            isSender={isSender}
                        />
                    </div>
                </Grid>
            )}
        </>
    )

}


DocsMessage.defaultProps = {
    classes: {},
    message: null,
    handleHashTagClick: () => { }
};

// Prop types
DocsMessage.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.object,
    handleHashTagClick: PropTypes.func,
};

export default withStyles(styles)(DocsMessage)