import React, { useState, useEffect, useMemo } from "react";
import PropTypes from 'prop-types';
import { AppBar, Dialog, Toolbar, Grid, IconButton, withStyles, MenuItem, Popover, Grow, Typography, useMediaQuery } from '@material-ui/core';
import { saveAs } from 'file-saver';
import Picker from 'emoji-picker-react';

//Import Component
// import { Typography } from "../Typography";
import Carousel from "./Carousel";

//Import Icons 
import CloseIcon from '@material-ui/icons/Close';

// Imports Styles
import { useDispatch, useSelector } from "react-redux";
import { BackArrow, DownloadLine, ForwardOutline, MoreIcon, ReplyOutline, SmileyIcon2, StarFilled, StarOutline } from "../../assets/svg";
import { _api } from "../../config/environment";
import { PARAM_TYPE } from "../../containers/Chatv2/Chatv2Constants";
import { chatv2Action, updateAction } from "../../redux/actions";
import { slideShowConstant } from "../../redux/constants";
import { TitleAvatar } from "../TitleAvatar";
import styles from "./styles";
import { MobileActionSheet } from "../MobileComponents/MobileActionSheet";
import { EmojiPicker } from "@flozy/editor";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow in={true} ref={ref} {...props} />;
});

const SlideShow = (props) => {
    const { classes, loading, open, items, index, title, handleClose, handleClickOpen, star, userId, data, previewData, userAvatar, isFromMedia, mediaFiles, directDownload, inVoicePreviewItem, newAttachments, invoicePreview = false, ...others } = props;
    let selectedItem = data || items.find((item, key) => key === (index ? index : 0));
    const uniqueItems = useMemo(() => {
        return items.map((item, index) => {
            return {
                ...item,
                unique_id: index
            }
        })
    }, [items])

    const [activeIndex, setActiveIndex] = useState(0);
    const [activeItem, setActiveItem] = useState(selectedItem);
    const [currentChatData, setCurrentChatData] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null)
    const [emojiPicker, setEmojiPicker] = useState(false)
    const dispatch = useDispatch()
    const { notifications, channel, selected_channel, replyOpen, ...chatData } = useSelector(s => s.chatv2)
    const { agency_pref } = useSelector(s => s.auth.loginUser)
    const { avatar_filename, first_name, last_name, username } = useSelector(s => s.profile?.profile)
    const [isGroup, setIsGroup] = useState(1);
    let userPrefTheme = localStorage.getItem('themeType');

    let type = ''
    if (data?.moduleType == 'team') {
        type = 'teams'
    } else if (data?.moduleType == 'client') {
        type = 'clients'
    } else {
        type = 'projects'
    }



    useEffect(() => {
        let chat_data = data
        if (!chat_data) {
            chat_data = (chatData?.media?.[selected_channel?.type || type]?.[chat_id] || []).find(e => e.id == activeItem?.id || data?.id)
        }
        setCurrentChatData(chat_data)

        if (type === 'teams') {
            const groupData = channel[type].filter(c => c.id == chat_id)?.[0] || {};
            setIsGroup(groupData.isGroup);
        }

    }, [activeItem, data])

    const chat_id = data?.group_id || data?.client_id || data?.job_id
    const activeHubData = channel[selected_channel?.type || type].find(f => f.id == chat_id)

    const isMobile = useMediaQuery('(max-width:899px)')


    useEffect(() => {
        setActiveIndex(index);
        setActiveItem(selectedItem)
    }, [index])

    const getCurrentItem = (itemKey) => {

        let selectedItem = uniqueItems[itemKey];
        if (selectedItem?.id) {
            const currentData = mediaFiles?.find((f) => f.id === selectedItem.id)
            dispatch(updateAction.update(slideShowConstant.SLIDE_SHOW_DATA_UPDATE, { data: currentData, index: itemKey }))
        }
        if (selectedItem?.url) {
            const currentElement = mediaFiles[0]?.message_file;
            const files = JSON?.parse(currentElement);
            let photos = [];

            if (Array.isArray(files)) {
                for (const file of files) {
                    photos.push(file?.url);
                }
            } {
                photos = [files?.url];
            }

            if (photos.includes(selectedItem?.url)) {
                const elementToScrollId = selectedItem?.unique_id;
                const elementToScroll = document.getElementById(elementToScrollId);
                if (elementToScroll) {
                    elementToScroll.scrollIntoView({ behavior: "smooth", inline: "center" });
                } else {
                    console.log("Element with the specified ID not found.");
                }
            }
        }

        setActiveIndex(itemKey)
        setActiveItem(selectedItem);
    }

    const downloadData = () => {

        const url = activeItem && activeItem.url ? activeItem.url : selectedItem ? selectedItem.url : null;
        if (url) {
            // const fullURL = `${_api.url}/chat_hub/download?url=${url}`
            saveAs(url)
        }
    }


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleReply = () => {
        if (openMenu) {
            handleMenuClose();
        }
        handleClose()
        if (isFromMedia) {
            dispatch(chatv2Action.setReplyMessage(data, previewData[activeIndex]?.message_id))
            // replyOpen()
        } else {
            dispatch(chatv2Action.setReplyMessage(data))
            // replyOpen()
        }

    }

    const handleForwardMessage = () => {
        if (openMenu) {
            handleMenuClose();
        }
        if (isFromMedia) {
            dispatch(chatv2Action.handleForwardMessage(data, previewData[activeIndex]?.message_id))
        } else {
            dispatch(chatv2Action.handleForwardMessage(data))
        }
    }

    const handleStarMessage = () => {
        if (openMenu) {
            handleMenuClose();
        }
        const id = data?.group_id || data?.client_id || data?.job_id || selected_channel?.id
        const messageFile = data?.message_file ? JSON.parse(data?.message_file) : null;
        const payload = {
            job_id: id,
            message_id: activeItem?.id || data?.id,
            star: data?.star == 1 ? 0 : 1,
            type: PARAM_TYPE[selected_channel?.type || type],
            message: data,
        }
        const updatedData = { ...data }
        updatedData["star"] = payload.star
        setCurrentChatData(updatedData)
        dispatch(updateAction.update(slideShowConstant.SLIDE_SHOW_STAR_UPDATE, { data: data }))

        dispatch(chatv2Action.updateStarredMessage(payload, {
            params: {
                room_id: id,
                cur_type: selected_channel?.type || type
            }
        }, isFromMedia ? previewData[activeIndex]?.message_id : null))
    }

    const onClickReactions = (emojiObject) => {
        const { emoji, actionType } = emojiObject;
        let actiontype = actionType ? actionType : 'add'
        const id = data.group_id || data.client_id || data.job_id
        const payload = {
            emoji: emoji,
            actionType: actiontype,
            jobId: id,
            message_id: data?.id,
            userId,
            agencyId: agency_pref,
            moduleType: PARAM_TYPE[selected_channel?.type || type],
            message_text: "Image"
        }
        dispatch(updateAction.update(slideShowConstant.SLIDE_SHOW_EMOJI_UPDATE, { ...payload, avatar_filename: avatar_filename, name: `${first_name || ''} ${last_name || ''}`, username: username }))

        dispatch(chatv2Action.updateEmojiReactions(payload, {
            params: {
                room_id: id,
                cur_type: selected_channel?.type || type
            },
            users: activeHubData?.users
        }))
        setEmojiPicker(false);
        setAnchorEl2(null);
    };


    const onEmojiClick = (emoji) => {
        emoji = emoji.native;
        let actiontype = 'add'
        const id = data.group_id || data.client_id || data.job_id
        const payload = {
            emoji: emoji,
            actionType: actiontype,
            jobId: id,
            message_id: data?.id,
            userId,
            agencyId: agency_pref,
            moduleType: PARAM_TYPE[selected_channel?.type || type],
            message_text: "Image"
        }
        dispatch(updateAction.update(slideShowConstant.SLIDE_SHOW_EMOJI_UPDATE, { ...payload, avatar_filename: avatar_filename, name: `${first_name || ''} ${last_name || ''}`, username: username }))

        dispatch(chatv2Action.updateEmojiReactions(payload, {
            params: {
                room_id: id,
                cur_type: selected_channel?.type || type
            },
            users: activeHubData?.users
        }))
        setEmojiPicker(false);
        setAnchorEl2(null);
    };


    const openMenu = Boolean(anchorEl);
    const id = openMenu || emojiPicker ? 'simple-popover' : undefined;

    const handleEmojiCLose = () => {
        setEmojiPicker(false)
    }

    const handleSlideShowClose = () => {
        setActiveIndex(0)
        handleClose()
    }


    const getProps = (selectedItem) => {
        if (invoicePreview === true) {
            return { href: `${_api.url}/chat_hub/download?url=${inVoicePreviewItem?.url}` }
        }
        const msg_file = selectedItem?.url ? selectedItem : (activeItem?.url ? activeItem : JSON.parse(selectedItem?.message_file || null) || {})
        const url = msg_file?.url || null
        const is_gif = msg_file?.type ? msg_file?.type?.includes("gif") : true
        return (!is_gif && !directDownload) ? { href: `${_api.url}/chat_hub/download?url=${msg_file?.url || activeItem?.url || inVoicePreviewItem?.url}` } :
            {
                onClick: () => {
                    saveAs(url ? url : newAttachments[activeIndex]?.previewFile, msg_file?.name ? msg_file?.name : newAttachments[index] ? newAttachments[index]?.name : "chat_image")
                }
            }
    }

    const memoActionSheet = useMemo(() => (
        <MobileActionSheet
            open={emojiPicker}
            title=""
            handleCloseAction={() => { handleEmojiCLose() }}
            swipableDrawer={false}
        >
            <div className={classes.mobileEmojiPicker}>
                <EmojiPicker
                    theme={userPrefTheme}
                    onEmojiSelect={onEmojiClick}
                    dynamicWidth={true}
                    previewPosition='none'
                />
            </div>
        </MobileActionSheet>
    ), [emojiPicker, userPrefTheme])

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleSlideShowClose}
                TransitionComponent={Transition}
                TransitionProps={{
                    timeout: {
                        enter: 550, // Delay for entering transition in milliseconds
                        exit: 550,  // Delay for exiting transition in milliseconds
                    },
                }}
                {...others}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
                className={classes.slideShow}
            >
                <AppBar className={classes.appBar} position="relative">
                    <Toolbar>
                        <Grid container justifyContent="space-between" wrap="nowrap">
                            <Grid className="dflex alignCenter headerLeft">
                                {isMobile ? <IconButton className="mr5" onClick={handleSlideShowClose}><BackArrow /></IconButton> : ""}
                                {previewData &&
                                    <>
                                        <TitleAvatar
                                            type={'avatar'}
                                            value={previewData[activeIndex]?.avatar}
                                            title={previewData[activeIndex]?.name}
                                        />
                                        <Grid className={`pb4  dflex flexColumn ml-1 ${isMobile ? 'headerTextMobile' : 'headerText'}`}>
                                            <span className="primary1 fw-700 w-100 oneLineEllipNowrap">
                                                {isGroup !== 1 ? previewData[activeIndex]?.name : `${previewData[activeIndex]?.name}@${previewData[activeIndex]?.job_name}`}
                                            </span>
                                            <span className="secondaryColor fontSize12 w-100 oneLineEllipNowrap">{previewData[activeIndex]?.time}</span>
                                            {/* <Typography ></Typography>
                                            <Typography >{previewData[activeIndex]?.time}</Typography> */}
                                        </Grid>
                                    </>
                                }

                            </Grid>

                            <Grid className="dflex alignCenter headerAction">
                                {previewData && isFromMedia &&

                                    <>
                                        <IconButton className="mr5 dShow" onClick={handleReply}><ReplyOutline /></IconButton>

                                        {currentChatData && currentChatData.star ? (
                                            <IconButton className="mr5 dShow" onClick={handleStarMessage}><StarFilled /></IconButton>
                                        ) : (
                                            <IconButton className="mr5 dShow" onClick={handleStarMessage}><StarOutline /></IconButton>
                                        )}

                                        <IconButton className="mr5" onClick={handleForwardMessage}><ForwardOutline /></IconButton>
                                        <IconButton className="mr5" onClick={(event) => { setEmojiPicker(true); setAnchorEl2(event.currentTarget) }}><SmileyIcon2 /></IconButton>
                                        {
                                            isMobile ?
                                                memoActionSheet
                                                :
                                                <Popover
                                                    id={id}
                                                    open={emojiPicker}
                                                    anchorEl={anchorEl2}
                                                    onClose={handleEmojiCLose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <div className={classes.mobileEmojiPicker}>
                                                        <EmojiPicker
                                                            theme={userPrefTheme}
                                                            onEmojiSelect={onEmojiClick}
                                                            previewPosition='none'
                                                        />
                                                    </div>
                                                </Popover>
                                        }
                                    </>
                                }

                                <>
                                    <IconButton
                                        {...getProps(selectedItem)}
                                        download
                                        className="mr5 dShow"
                                    >
                                        <DownloadLine />
                                    </IconButton>

                                    <IconButton className="dShow" color="inherit" onClick={handleSlideShowClose} aria-label="close">
                                        <CloseIcon className="closeIcon" />
                                    </IconButton>
                                </>


                                {previewData &&
                                    <>
                                        {/* Desktop action items */}

                                        {/* Mobile view more */}
                                        <IconButton className="mShow" onClick={handleClick}><MoreIcon /></IconButton>

                                        <Popover
                                            id={id}
                                            open={openMenu}
                                            anchorEl={anchorEl}
                                            onClose={handleMenuClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                            className={classes.prevpopupContainer}
                                        >
                                            <MenuItem onClick={handleReply}><Typography variant="body1" color="textSecondary">Reply</Typography></MenuItem>
                                            <MenuItem onClick={handleStarMessage}>
                                                {
                                                    currentChatData && currentChatData.star ?
                                                        <Typography variant="body1" color="textSecondary">UnStar Message</Typography> :
                                                        <Typography variant="body1" color="textSecondary">Star Message</Typography>
                                                }
                                            </MenuItem>
                                            <MenuItem onClick={() => { downloadData(); handleMenuClose(); }}><Typography variant="body1" color="textSecondary">Download</Typography></MenuItem>
                                        </Popover>
                                    </>
                                }
                                {/* Mobile view more */}

                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>


                <Grid container className={`${classes.sliderSection} ${uniqueItems.length < 15 ? classes.sliderSectionCenter : classes.sliderSectionBase}`}>
                    <Carousel

                        {...props}
                        loading={loading}
                        items={uniqueItems} index={activeIndex ? activeIndex : index} getCurrentItem={getCurrentItem} handleClose={handleClose} previewData={previewData ? previewData : ''} userId={userId} chatData={currentChatData ? currentChatData : ''} onClickReactions={onClickReactions}
                        inVoicePreviewItem={inVoicePreviewItem}
                        newAttachments={newAttachments}
                    />
                </Grid>
                {/* <Grid style={{position:"absolute", left:'0px'}}>
                    {emojiPicker ? <Picker onEmojiClick={onEmojiClick} pickerStyle={{ width: '100%', left: "100%", bottom: "100%", zIndex: 9999 }} /> : null}
                </Grid> */}

            </Dialog>
        </>
    );
};

// Default props
SlideShow.defaultProps = {
    classes: "",
    className: "",
    open: false,
    items: [],
    index: 0,
    title: "",
    handleClose: () => { },
    handleClickOpen: () => { },
    directDownload: false,
    loading: false
};

// Prop types
SlideShow.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    open: PropTypes.bool,
    items: PropTypes.array.isRequired,
    index: PropTypes.number,
    title: PropTypes.string,
    handleClose: PropTypes.func,
    handleClickOpen: PropTypes.func,
    directDownload: PropTypes.bool,
    loading: PropTypes.bool
};

/** Export Component */
export const SlideShowComponent = withStyles(styles)(SlideShow);
