import { Redirect } from "react-router-dom"

const RedirectTo = (props) => {
    const { location } = props
    const searchPrams = new URLSearchParams(location.search)
    const toPath = searchPrams?.get('path')
    if (toPath) {
        return <Redirect to={toPath} />
    }
    return null
}

export default RedirectTo