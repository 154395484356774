/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, TextField as Core } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import { ChromePicker } from "react-color";
import RemoveIcon from '@material-ui/icons/Remove';

// Imports Styles
import styles from "./styles";
/**
 * TextField Component
 * @param {*} props
 */
function TextFieldComponent({ isBar, colorValue, className, classes, id, name, placeholder, autoComplete, margin, min, variant, fullWidth, withImage, imageType, passShow, handleTagColor, ...rest }) {

  const popover = {
    position: "absolute",
    zIndex: "9",
    bottom: "3rem",
    right: "0rem"
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };

  //set state
  const [isOpen, setIsOpen] = useState(false)
  const [color, setColor] = useState(colorValue ? colorValue : "#000000")
  const [tagColor, setTagColor] = useState(colorValue ? colorValue : "#000000")

  const handleColorPicker = () => {
    setIsOpen(!isOpen)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleChange = (color, evt) => {
    setColor(color.rgb);
    setTagColor(color.hex);
    handleTagColor(color.hex);
  }

  return (
    <>
      <Grid style={{ position: "relative" }}>
        <Core
          id={id}
          placeholder={placeholder}
          name={name}
          autoComplete={autoComplete}
          margin={margin}
          variant={variant}
          fullWidth={fullWidth}
          // onClick={()=>{isBar && alert("") }}
          className= {classes.colorInputPicker}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleColorPicker}
                edge="end"
              >
                {isBar ? <RemoveIcon style={{ fill: tagColor,transform: "rotate(90deg)" }} fontSize="large" /> : <FiberManualRecordOutlinedIcon style={{ fill: tagColor }} />}
              </IconButton>
            </InputAdornment>
          }}
          {...rest}
        />
        {isOpen ? (
          <div style={popover}>
            <div style={cover} onClick={handleClose} />
            <ChromePicker
              color={color}
              onChangeComplete={handleChange}
              disableAlpha={true}
            />
          </div>
        ) : null}
      </Grid>
    </>
  );
}

// default props
TextFieldComponent.defaultProps = {
  id: "",
  name: "",
  type: "text",
  autoComplete: "off",
  min: "0",
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  withImage: false,
  passShow: false,
  colorValue: "#000000",
  isBar:false
};

// prop types
TextFieldComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  min: PropTypes.string,
  autoComplete: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  withImage: PropTypes.bool,
  passShow: PropTypes.bool,
  colorValue: PropTypes.string,
  isBar:PropTypes.bool,
};

// export component
export const ColorPickerTextField = withStyles(styles)(TextFieldComponent);
