
import { getRequest, postRequest, putRequest } from "../../utils/apiHelper";

async function getReferrals() {
    const response = await getRequest("referrals");
    return response;
}
async function createReferral(params){
    const response = await postRequest("referrals/create",params);
    return response
}
async function getPaymentCode(params){
    const response = await postRequest("referrals/get_paymentCode",params);
    return response
}

async function getReferralData(params) {
    const id  = params;
    const response = await getRequest(`referrals/${id}`);
    return response;
}

async function deleteReferral(params) {
    const response = await postRequest("referrals/deleteReferral", params);
    return response;
}

export const referralsService = {
    createReferral,
    getReferrals,  
    getReferralData,
    getPaymentCode,
    deleteReferral
};