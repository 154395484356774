import React, { useState, useEffect } from "react";

const Timer = () => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const timerFunc = setInterval(() => {
      setTimer((timer) => parseInt(timer) + 1000);
    }, 1000);
    return () => clearInterval(timerFunc);
  }, []);

  const formatTime = (timer) => {
    const getMinutes = `0${Math.floor((timer / 60000) % 60)}`.slice(-2);
    const getSeconds = `0${Math.floor((timer / 1000) % 60)}`.slice(-2);

    return (<div>
      <span className="digits">
        {getMinutes}:
      </span>
      <span className="digits">
        {getSeconds}
      </span>
    </div>)
  }
  return (
    <>
      <div className="timerSection">
        {formatTime(timer)}
      </div>
    </>
  );
};

export default Timer;