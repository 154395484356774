import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

//import history
import history from "../../config/history";

import { userAgencyAction } from '../../redux/actions';

import { withStyles } from '@material-ui/styles';

import PlanCardStyle from './PlanCardStyles.jsx';

function ExcessUsrPopup(props) {


    // Define Dispatch
    const dispatch = useDispatch();

    const { location: { pathname } } = history;

    const { userAgency: { userLimits }, auth: { loginUser } } = useSelector(s => (s))

    useEffect(() => {
        if (loginUser && loginUser.agency_pref) {
            dispatch(userAgencyAction.getAgencyUserLimits())
        }
    }, [dispatch, loginUser.agency_pref])



    const userRole = loginUser.user_role
    

    return null
    // if (nonTeamPage && loginUser.id === loginUser.ag_owner && ((userLimits.projectUsers &&  projectCount >=0 && userLimits.projectUsers.length > projectCount) || (userLimits.clientUsers && userLimits.planData.client_user_count && userLimits.clientUsers.length > clientCount))) {
    //     return (
    //         <React.Fragment>
    //             <Dialog
    //                 open={true}
    //                 onClose={() => { }}
    //                 aria-labelledby="alert-dialog-title"
    //                 aria-describedby="alert-dialog-description"
    //                 className="alertDialogue"
    //                 fullWidth
    //                 maxWidth={"sm"}
    //             >
    //                 <Grid className={classes.popupContainer}>
    //                     <Typography variant="h5" className="pb-2">{`Number of users is greater than the users in your plan please upgrade your plan`}</Typography>
    //                     <Grid className="dflex alignCenter pb-2">
    //                         <Typography variant="body1" className="pr-2">Max Users : <b>{projectCount}</b></Typography>
    //                         <Typography variant="body1">Max Clients :  <b>{clientCount}</b></Typography>
    //                     </Grid>
    //                     <Grid item align="right">
    //                         <Button variant='contained' color='secondary' className='m5' onClick={() => { history.push("/teams") }}>Delete Users</Button>
    //                         <Button variant='contained' color='primary' className='m5' onClick={() => { history.push("/upgradePlan") }}>Upgrade Plan</Button>
    //                     </Grid>
    //                 </Grid>
    //             </Dialog>
    //         </React.Fragment>
    //     )

    // } else if (nonTeamPage && hasProjectAccess && (userLimits.projectUsers && userLimits.projectUsers.length > projectCount && userLimits.projectUsers.indexOf(loginUser.id) > projectCount)) {
    //     return (
    //         <React.Fragment>
    //             <Dialog
    //                 open={true}
    //                 onClose={() => { }}
    //                 aria-labelledby="alert-dialog-title"
    //                 aria-describedby="alert-dialog-description"
    //                 className="alertDialogue"
    //                 fullWidth
    //                 maxWidth={"sm"}
    //             >
    //                 <Grid className={classes.popupContainer}>
    //                     <Typography variant="h5" className="pb-3" align="center">Please contact your agency owner</Typography>
    //                     <Grid item align="center">
    //                         <Button variant='contained' color='primary' className='m5' onClick={() => { history.push("/profile") }}>My Profile / switch agencies</Button>
    //                     </Grid>
    //                 </Grid>
    //             </Dialog>
    //         </React.Fragment>
    //     )
    // } else if (nonTeamPage && hasClientAccess && (userLimits.clientUsers && userLimits.clientUsers.length > clientCount && userLimits.clientUsers.indexOf(loginUser.id) > clientCount)) {
    //     return (
    //         <React.Fragment>
    //             <Dialog
    //                 open={true}
    //                 onClose={() => { }}
    //                 aria-labelledby="alert-dialog-title"
    //                 aria-describedby="alert-dialog-description"
    //                 className="alertDialogue"
    //                 fullWidth
    //                 maxWidth={"sm"}
    //             >
    //                 <Grid className={classes.popupContainer}>
    //                     <Typography variant="h5" className="pb-3" align="center">Please contact your agency owner</Typography>
    //                     <Grid item align="center">
    //                         <Button variant='contained' color='primary' className='m5' onClick={() => { history.push("/profile") }}>My Profile / switch agencies</Button>
    //                     </Grid>
    //                 </Grid>
    //             </Dialog>
    //         </React.Fragment>
    //     )
    // } else {
    //     return null
    // }
}

// default props
ExcessUsrPopup.defaultProps = {
    classes: {}
};

// prop types
ExcessUsrPopup.propTypes = {
    classes: PropTypes.object
};

export default withStyles(PlanCardStyle)(ExcessUsrPopup)
