import React from "react";
import PropTypes from "prop-types";
import { Snackbar as Core } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from '@material-ui/core/styles';


import { Button } from '../index'
/**
 * Link Component
 * @param {*} props
 */

const useStyles = makeStyles((theme) => ({
    root: {
        width: '70%',
        '& .MuiSnackbarContent-root': {
            width: '50%',
            background: theme.palette.primary.gradientBtn,
            borderRadius: 0,
            height: 165,
            flexWrap: 'nowrap'
        },
        '& .MuiSnackbar-anchorOriginBottomCenter': {
            bottom: 0,
            transform: 'unset !important',
            left: '0px !important',
            right: '0px !important'
        },
    },
}));


function NotificationPermission({ open, handleClose, openStatus, message, actionMessage, actionMessage2, href, children, className, onActionClick, onActionClick2, ...rest }) {

    function SlideTransition(props) {
        return <Slide {...props} direction="down" />;
      }
    const classes = useStyles();

    return (
        <div className={classes.root}>
                <Core
                    className={className}
                    classes={classes}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={openStatus}
                    TransitionComponent={SlideTransition}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message={message}
                    action={
                        <React.Fragment>
                            <Button className="enableNotification mr-1" color="secondary" size="small" variant="outlined" onClick={onActionClick} title={actionMessage}></Button>
                            <Button className="enableNotification mr-1" color="secondary" size="small" variant="outlined" onClick={onActionClick2} title={actionMessage2}></Button>
                        </React.Fragment>
                    }
                />
        </div>
    );
}

// default props
NotificationPermission.defaultProps = {
    classes: {},
    message: "",
    openStatus: false,
    actionMessage: "",
    className: "",
    onActionClick: () => { },
    onNotificationClose: () => { }
};

// prop types
NotificationPermission.propTypes = {
    children: PropTypes.any,
    openStatus: PropTypes.bool,
    onActionClick: PropTypes.func,
    onNotificationClose: PropTypes.func,
    classes: PropTypes.object,
    className: PropTypes.string,
    message: PropTypes.string,
    actionMessage: PropTypes.string,
};

/** Export Component */
export default NotificationPermission;
