import { BorderRight } from "@material-ui/icons";

const styles = (theme) => ({
    messageComponent: {
        "& a": {
            color: "#fff",
        }
    },

    messageHashTag: {
        color: "#fff",
        // color: "#222",
        fontWeight: "blod",
        cursor: "pointer",
    },
    messageLink: {
        color: "#f1f1f1",
        cursor: "pointer",
        "& span": {
            "&::after": {
                display: 'inline !important'
            }
        }
    },
    downloadIcon: {
        '&:hover': {
            background: `#dfdfdf !important`,
        }
    },
    messageMention: {
        color: "#ddefff",
        cursor: "pointer",
        fontSize: '13px !important',
        fontWeight: '500 !important',
        '&.sent': {
            color: '#fff62d !important'
        },
        '&.received': {
            color: '#2563EB !important'
        }
    },

    received: {
        "& a": {
            color: "#2463eb !important",
            // paddingRight: '44px'
        }
    },
    send: {
        "& a": {
            color: "#fff !important",
        }
    },
    //Link
    linkListDiv: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px',
        background: theme.palette.containers.bg13,
        boxShadow: theme.palette.shadows.shadow1,
        borderRadius: '4px',
        marginBottom: 10,
        wordBreak: 'break-word'
    },
    linkListImgDiv: {
        flexGrow: 1,
        display: 'flex',
        background: '#F0F0F0',
        alignItems: 'center',
        borderRadius: '7px',
        backgroundColor: theme.palette.containers.bg13,
    },
    linkListImg: {
        width: 80,
        height: 82,
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        flexShrink: 0,
        background: '#e3e3e3',
        borderRadius: '7px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'scale-down'
        }
    },
    linkListDesc: {
        padding: 8
    },
    linkListLink: {
        color: '#2196f3 !important',
        fontSize: '10px',
        paddingTop: '2px'
    },
    linkTitle: {
        fontSize: "0.75rem",
        fontFamily: "Inter, sans-serif",
        fontWeight: 600,
        lineHeight: "1rem",
        height: "1rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal"
    },
    linkDesc: {},

    //Media Component (Single Image)
    chatSingleMedia: {
        // width: "15rem",
        // height: "auto",
        cursor: "pointer",
        cssFloat: "left",
        width: "100%",
        marginTop: -3,
        marginBottom: -4,
        padding: "6px 3px 0px 3px",
        position: 'relative',
        '& .time_ago': {
            bottom: '3px !important'
        },
        "& .react-thumbnail-generator": {
            width: "100%",
            height: "100%",
            "& img": {
                width: "100%",
                overFlow: "hidden",
                borderRadius: "10px",
                objectFit: "cover"
            }
        },
        "&.chatSingleMedia::after": {
            position: 'absolute',
            content: '" "',
            left: "3px",
            // background: 'linear-gradient(0deg, #000000a6, #0000005c, transparent)',
            background: "linear-gradient(356deg, rgba(35,35,39,0.5) 15%, transparent 60%)",

            // background: 'linear-gradient(0deg, #3a3939, transparent)',
            bottom: "5.4px",
            borderRadius: "0px 0px 6px 6px",
            width: 'calc(100% - 6px)',
            height: '28px',
        },
        '&.progress-container': {
            position: 'relative',
            display: 'inline-block',
        },
        '& .circular-progress': {
            position: 'absolute',
            margin: 'auto',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
        '& .background': {
            color: '#b3b3b3'
        },
        '& .foreground': {
            color: '#2563EB'
        }

    },
    ChatdocumentList: {
        '& .circular-progress': {
            position: 'absolute',
            margin: 'auto',
            right: 0,
            top: 0,
            bottom: 0,
            color: '#2563EB'
        }
    },
    ChatdocumentListApp: {
        width: '100%',
        justifyContent: 'space-evenly',
        '& .circular-progress': {
            position: 'absolute',
            margin: 'auto',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            color: '#2563EB',
            '@media only screen and (max-width:599px)':{
                display:'flex !important',
                justifyContent:'flex-end !important',
                left:'76% !important'
            }
        }
    },
    videoThumb: {
        width: "auto",
        cursor: "pointer",
        height: "auto",
        maxWidth: "100%",
        boxShadow: "none",
        maxHeight: "650px",
        objectFit: "cover",
        borderRadius: "3px",
        overflow: "hidden",
        minHeight: 40
    },
    videoThumbPlayBtnContainer: {
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        background: "transparent",
        top: 0,
    },
    videoThumbPlayBtn: {
        // padding: "1rem",
        background: "linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)",
        borderRadius: "50%",
        position: "absolute",
        fontSize: "3rem",
        zIndex: 1,
        width: '36px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiSvgIcon-root': {
            fill: '#fff'
        }
    },
    chatImage: {
        width: "100%",
        cursor: "pointer",
        height: "auto",
        maxWidth: "250px",
        maxHeight: "250px",
        objectFit: "cover",
        borderRadius: "8px",
        // minHeight: '40px',
        backgroundColor: 'white',
        '@media only screen and (max-width:599px)': {
            maxWidth: '170px',
            maxHeight: "370px !important",
        },
        '@media only screen and (min-width:600px)': {
            maxWidth: '305px'
        }
    },

    groupImgContainerMain: {
        position: 'relative',
        maxWidth: "218px",
        '& .circular-progress': {
            position: 'absolute',
            margin: 'auto',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            color: '#2563EB'
        }
    },

    groupImgContainer: {
        minWidth:'218px',
        display: 'flex',
        flexWrap: "wrap",
        '& .time_ago': {
            bottom: '3px !important'
        },
        // maxWidth:"218px",
        // flexFlow: 'row wrap',
        '&.onlyMedia::after': {
            background: "linear-gradient(352deg, rgba(35,35,39,0.5) 10%, transparent 50%)",
            position: 'absolute',
            content: `" " !important`,
            height: '30px',
            bottom: "4.4px",
            borderRadius: "6",
            width: 'calc(100% - 7px)',
            left: 3,
            borderRadius: ' 0px 0px 6px 6px'
        },
        '& .ChatGroupImages': {
            width: 100,
            height: 100,
            borderRadius: '8px'
        },
        '& .groupImgContainer_img': {
            padding: '0px',
            borderRadius: '8px',
            position: 'relative',
            height: 100,
            width: 100,
            margin: '4px'
        },
        '& .overlay': {
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#ffffffab',
            cursor: "pointer",
            color: '#353535',
            fontWeight: 500,
            borderRadius: '12px',
            fontSize: 16
        },
        '@media only screen and (max-width: 900px)': {
            '&.MuiGrid-root': {
                flexFlow: 'row wrap;'
            },
            '& .groupImgContainer_img': {
                maxWidth: 'calc(50% - 20px)'
            }
        },
    },

    textMsgBubble: {
        marginRight: 0,
        '& .deletedMessage': {
            minWidth: 'max-content'
        }
    },

    textMsgEditor: {
        marginRight: 0,
        '& .public-DraftEditor-content .public-DraftStyleDefault-block span': {
            fontSize: '0.875rem',
            fontWeight: 400,
        },
        '&::after': {
            content: "''",
            width: '65px',
            height: "5px",
            display: "inline-block",
            verticalAlign: "baseline",
        }
    },

    textMsgBubbleStar: {
        marginRight: 0,
        '& .public-DraftEditor-content .public-DraftStyleDefault-block span': {
            fontSize: '0.875rem',
            fontWeight: 400,
        },
        // '& .public-DraftEditor-content .public-DraftStyleDefault-block span>span::after': {
        //     content: "''",
        //     width: '50px',
        //     height: "5px",
        //     display: "inline-block",
        //     verticalAlign: "baseline",
        // },
        '& .public-DraftEditor-content .public-DraftStyleDefault-block span:last-child>span::after': {
            content: "''",
            width: '75px',
            height: "5px",
            display: "inline-block",
            verticalAlign: "baseline",
        }
    },

    //Media Group Component 
    chatGroupImage: {
        display: "flex"
    },


    //Document
    documenTitle: {
        maxWidth: 240,
        minWidth: 240,
        minHeight: 50,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    documenTitleApp: {
        maxWidth: 'max-content',
        minWidth: '200px',
        minHeight: 50,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        '@media only screen and (max-width: 900px)':{
            minWidth: '175px !important',
        }
    },
    ChatdocumentList: {
        '& .isText': {
            color: '#94A3B8',
        },
        '& .DocumentIcon': {
            fill: theme.palette.primary.border1,
            width: '24px',
            marginRight: '7px',
            marginLeft: '8px',
            background: '#fff'
        },
        '& .DownloadCircleIcon': {
            marginLeft: 8,
            marginRight: 6,
            width: '18px',
        },
        '& .downloadIcon': {
            marginLeft: 8,
            marginRight: 6,
        },
        '& .circular-progress': {
            position: 'absolute',
            margin: 'auto',
            right: 0,
            paddingRight:'10px',
            top: 0,
            bottom: '30px',
        },
        '& .background': {
            color: '#2563EB'
        },
        '& .foreground': {
            color: '#b3b3b3'
        }

    },
    documentText: {
        display:'flex',
        justifyContent:'space-between',
        backgroundColor:'#fff',
        borderRadius:'10px 10px 10px 10px',
        position: 'relative',
    },
    chatBoxFileIcon: {
        maxWidth: 18,
        marginRight: 8,
        marginLeft: 8,
    },
    // Upload Preview
    uploadPreviewAudio: {
        display: 'flex',
        minHeight: '20px',
        position: 'relative',
        alignItems: 'center',
        '& .audio-player-dummy': {
            minWidth: '165px',
            height: '46px',
        },
        '& .player__button': {
            color: '#f5f5f5',
            fontSize: '2.3em',
            marginRight: '8px'
        },
        '& .circular-progress': {
            position: 'absolute',
            width: '20px !important',
            height: '20px !important',
            top: '2px',
            left: '2px',
            color: '#2563EB'
        }
    },
    imageContainer: {
        width: "max-content",
        // minWidth:"200px"
    },
    uploadPreviewImage: {
        display: 'flex',
        // maxWidth: '220px',
        flexWrap: 'wrap',
        position: 'realative',
        '& .pre_upload_thumb': {
            width: 100,
            height: 'auto',
            margin: '4px',
            display: 'flex',
            flex: 1,
            objectFit: 'contain'
        },
        '& .circular-progress': {
            position: 'absolute',
            margin: 'auto',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            color: '#2563EB'
        }
    },
    imageContainer: {
        width: "max-content",
        // minWidth:"200px"
    },
    containerWidth: {
        width: "min-content"
    },
    multipleImage: {
        display: "flex",
        flexDirection: "column",
        gap: "3px"
    }

});

export default styles;
