const styles = (theme) => ({
  cardRoot: {
    background: "#F2F5FA",
    boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.13)",
    // borderRadius: "0px 16px 16px 16px",
    padding: "10px 20px 10px",
    width: "100%",
    maxWidth: "290px",
    position: "relative",
    textAlign: "left",
    "& .dateShow": {
      background: "#FFFFFF",
      borderRadius: "8px",
      height: "32px",
      fontSize: "12px",
      fontWeight: "500",
      color: "#64748B",
      display: "inline-flex",
      alignItems: "center",
      padding: "10px",
      marginBottom: "16px",
    },
    "& .tagColor": {
      height: "26px",
      width: "2px",
      borderRadius: "4px",
      position: "absolute",
      left: "0px",
      top: "35px",
    },
    "& .statusChip": {
      height: "22px",
      borderRadius: "8px",
      marginBottom: "18px",
      fontSize: "11px",
      fontWeight: "600",
      display: "inline-flex",
      alignItems: "center",
      padding: "0px 12px",
    },
    "& .priorityChip": {
      height: "26px",
      borderRadius: "8px",
      marginBottom: "18px",
      fontSize: "12px",
      fontWeight: "700",
      display: "inline-flex",
      alignItems: "center",
      padding: "0px 12px",
      color: "#fff",
    },
    "& .clickHereBtn": {
      color: "#2563EB !important",
      background: "#fff !important",
      height: "30px",
      boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.13)",
      borderRadius: "12px",
    },
    "& .avatarComp": {
      height: "30px",
      width: "30px",
    },
  },
});

export default styles;
