import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, withStyles } from '@material-ui/core';
import style from './style'
import { CloseCircleIcon } from '../../assets/svg';
import { ReactionList } from './ReactionList';

/**
 * Checkbox Component
 * @param {*} props
 */

function ChatReactionComponent(props) {

    const { classes, reactionRight, messageReactions, userId, onClickReactions, message_id } = props;
    const msgEmojis = messageReactions ? Object.keys(messageReactions || {}) : [];
    const emojiRef = useRef(null)

    // const open1 = Boolean(anchorEl1);

    // handleclickmore
    const [moreItem, setmoreItem] = React.useState(false);
    const [openReactionInfo, setOpenReactionInfo] = React.useState(false);

    const handleClickMore = () => {
        setmoreItem(!moreItem)
    }

    const handleReaction = (emoji, actionType) => {
        onClickReactions({ emoji, actionType })
    }

    const handleReactionInfo = () => {
        if (emojiRef?.current) {
            setOpenReactionInfo(emojiRef?.current)
        }
    }

    const handleClose = () => {
        setOpenReactionInfo(false)
    }

    const handleDoubleClick = (e, { emoji, action }) => {
        e.preventDefault();
        handleReaction(emoji, action)
    }

    const handleRightClick = (e, { emoji, action }) => {
        e.preventDefault();
        handleReaction(emoji, action)
    }


    return (
        <React.Fragment>
            {msgEmojis.length > 0 &&
                <Grid className={`selectedEmojiDivContainer ${classes.selectedEmojiDivContainer} ${moreItem && 'selectedMoreEmojiActive'}`} ref={emojiRef}>
                    <Grid className={`${classes.selectedEmojiDiv} ${reactionRight ? "senderEmoji" : "receiverEmoji"} ${moreItem && "showmoreRection"}`}>
                        {moreItem && <IconButton onClick={handleClickMore} className="moreEmojiClose"><CloseCircleIcon /></IconButton>}
                        {
                            msgEmojis.slice(0, 3).map((emoji, key) => {
                                const dt = (messageReactions[emoji] || []).filter((val) => val.user_id === userId);
                                const action = dt.length > 0 ? 'delete' : "add";
                                return (
                                    <Grid className={`selectedEmojis ${dt.length > 0 ? 'active' : null}`}
                                        aria-haspopup="true"
                                        onClick={handleReactionInfo}
                                        onDoubleClick={(e) => handleDoubleClick(e, { emoji, action })}
                                        onContextMenu={(e) => handleRightClick(e, { emoji, action })}
                                        key={`${message_id}_${emoji}_${key}`}
                                    >
                                        {emoji}
                                        {/* {messageReactions[emoji] && messageReactions[emoji].length > 1 ?
                                        <span className='emojiCount '>{messageReactions[emoji].length}</span> : null
                                    } */}
                                    </Grid>
                                )
                            })
                        }

                        {msgEmojis && msgEmojis.length > 3 ?
                            <Grid className={`visibile moreemojis cursorPointer`} onClick={handleReactionInfo} handleClose={() => handleClose()}>{msgEmojis.length > 3 ? `${msgEmojis.length - 3}+` : msgEmojis.length}</Grid> : null
                        }
                        {openReactionInfo ? <ReactionList msgEmojis={msgEmojis}  {...props} openReactionInfo={openReactionInfo} handleClose={() => handleClose()} /> : null}
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    )
}

// default props
ChatReactionComponent.defaultProps = {
    classes: '',
    className: "mr-1",
    handleChange: () => { }
};

// prop types
ChatReactionComponent.propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object,
    handleChange: PropTypes.func,
    reactionRight: PropTypes.bool
};

export const ChatReaction = withStyles(style)(ChatReactionComponent)