import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";

/**
 *Delete Dialog Component
 *
 * @class InfoDialog
 * @extends {React.Component}
 */
function InfoDialog(props) {
    /** Handle Dialog close Action  */

    const { open, content, handleCloseAction } = props;

    const handleClose = () => {
        handleCloseAction();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="p15"
        >
            <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    disableElevation
                    size="small"
                    color="primary">
                    Ok, Got It
                </Button>
            </DialogActions>
        </Dialog>
    );
}

// default props
InfoDialog.defaultProps = {
    open: false,
    id: null,
    content: "",
    handleCloseAction: () => { }
};

// prop types
InfoDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    id: PropTypes.any,
    handleCloseAction: PropTypes.func.isRequired,
};

/** Export Component */
export const InfoDialogComponent = InfoDialog;