import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Import Actions

// Import Styles
import style from "./style";


function TaskSkeleton(props) {

    const { classes } = props;

    return (
        <div className="pr-1">
            <Box width="100%" margin={1}>
                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" width="82%">
                    <Skeleton variant="circle" width={20} height={20} className="mr-2" />
                    <Skeleton width="40%" variant="text" />
                    </Box>
                    <Skeleton width="8%" variant="text" height={50} className={classes.br30} />
                    <Skeleton variant="circle" width={30} height={30} />
                </Box>
            </Box>
        </div>
    )
}

// default props
TaskSkeleton.defaultProps = {
    classes: {}
};

// prop types
TaskSkeleton.propTypes = {
    classes: PropTypes.object
};

// Export component
export default withStyles(style)(TaskSkeleton)
