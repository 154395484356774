import { baseAction } from "./baseAction";
import { alertConstant, loaderConstant, workspaceConstant } from "../constants";
import { workspaceService } from "../services";
import { alertAction } from "./alertAction";

const { request, success, failure } = baseAction;

// All login based actions
export const wsmAction = {
    getWorkspaces,
    acceptShareInvite,
    rejectShareInvite,
    searchAgencies,
    inviteAgency,
    getSelectedUsers,
    stopSharing,
    cancelShareInvite,
    showMessage,
    updateFlowUsers,
    deleteUsers
};

/** create new project */
function getWorkspaces(callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(workspaceConstant.GET_SHARED_RESOURCES_R));
    dispatch(request(loaderConstant.LOADING_TRUE));

    workspaceService.getWorkspaces().then(response => {
      dispatch(success(workspaceConstant.GET_SHARED_RESOURCES_S,response['data']));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack();
    }, error => {
      dispatch(failure(workspaceConstant.GET_SHARED_RESOURCES_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function showMessage(data){
  return(dispatch)=>{
    dispatch(failure(alertConstant.ALERT_ERROR, data.data));
  }
}

function deleteUsers(userId,invitation_id){
  return(dispatch)=>{
    workspaceService.deleteUsers(userId,invitation_id).then(response=>{
    dispatch(success(workspaceConstant.GET_SHARED_RESOURCES_S,response['data']));
  })
 }
}

/** accept share invite */
function acceptShareInvite(invite_id, body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(workspaceConstant.ACCEPT_SHARE_INVITE_R));
    dispatch(request(loaderConstant.LOADING_TRUE));

    workspaceService.acceptShareInvite(invite_id, body).then(response => {
      dispatch(success(workspaceConstant.ACCEPT_SHARE_INVITE_S, {
        invite_id
      }));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (response.data) {
        dispatch(success(alertConstant.ALERT_SUCCESS, response.data.message));
      }
      callBack();
    }, error => {
      dispatch(failure(workspaceConstant.ACCEPT_SHARE_INVITE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** reject share invite */
function rejectShareInvite(invite_id, body, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(workspaceConstant.REJECT_SHARE_INVITE_R));
    dispatch(request(loaderConstant.LOADING_TRUE));

    workspaceService.rejectShareInvite(invite_id, body).then(response => {
      dispatch(success(workspaceConstant.REJECT_SHARE_INVITE_S,response['data']));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack();
    }, error => {
      dispatch(failure(workspaceConstant.REJECT_SHARE_INVITE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getSelectedUsers(invite_id, shared_id, shared_type, callBack = () => { }) {
  let body={invite_id}
  return (dispatch) => {
    workspaceService.getSharedUsers(shared_id, shared_type, body).then(response => {
      dispatch(success(workspaceConstant.GET_SHARE_INVITE_S,response['data']));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(response);
    }, error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


// Search agencies with workID
function searchAgencies(params, callBack = () => {}) {
  return dispatch => {
    dispatch(request(workspaceConstant.SEARCH_AGENCY_R));
    dispatch(request(loaderConstant.LOADING_TRUE));

    workspaceService.searchAgencies(params).then(
      response => {
        const { data } = response || {}
        if (data.length === 0) {
          dispatch(success(alertConstant.ALERT_SUCCESS, `Agency Not Found`));
        } 
        // else if (data?.status) {
        //   const istatus = data?.status == 1 ? 'Invited' : 'Responded'
        //   dispatch(success(alertConstant.ALERT_SUCCESS, `Request Already ${istatus}`));
        // } 
        else {
          // if (data?.id) {
            // response.data["need_users"] = 0
            response["key"] = params.id
          // }
          dispatch(success(workspaceConstant.SEARCH_AGENCY_S, response));
          callBack();
        }
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      error => {
        dispatch(failure(workspaceConstant.SEARCH_AGENCY_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

// Invite agencies from flows
function inviteAgency(params, callBack = () => {}) {
  return dispatch => {
    dispatch(request(workspaceConstant.INVITE_AGENCY_R));
    dispatch(request(loaderConstant.LOADING_TRUE));

    workspaceService.inviteAgency(params).then(
      response => {
        dispatch(success(workspaceConstant.INVITE_AGENCY_S, response));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      },
      error => {
        dispatch(failure(workspaceConstant.INVITE_AGENCY_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

// Update Flow users Permission
function updateFlowUsers(invite_id, body, callBack = () => { }) {
  return dispatch => {
    dispatch(request(workspaceConstant.UPDATE_SHARING_FLOW_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    workspaceService.updateFlowUsers(invite_id, body).then(
      response => {
        dispatch(success(workspaceConstant.UPDATE_SHARING_FLOW_S, response));
        dispatch(alertAction.successAlert(response.message))
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack()
      },
      error => {
        dispatch(failure(workspaceConstant.UPDATE_SHARING_FLOW_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE))
      }
    )
  }
}

// stop sharing flows
function stopSharing(invite_id,body,callBack=()=>{}){
  return dispatch =>{
    dispatch(request(workspaceConstant.STOP_SHARING_FLOW_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    workspaceService.stopSharing(invite_id,body).then(
      response => {
        dispatch(success(workspaceConstant.STOP_SHARING_FLOW_S,response));
        dispatch(alertAction.successAlert(response.message))
        dispatch(wsmAction.getWorkspaces())
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack()
      },
      error => {
        dispatch(failure(workspaceConstant.STOP_SHARING_FLOW_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR,error));
        dispatch(request(loaderConstant.LOADING_FALSE))
      }
    )
  }
}

//cancel share Invite
function cancelShareInvite(invite_id,callBack=()=>{}){
  return dispatch =>{
    dispatch(request(workspaceConstant.CANCEL_SHARE_INVITE_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    workspaceService.cancelShareInvite(invite_id).then(
      response => {
        dispatch(success(workspaceConstant.CANCEL_SHARE_INVITE_S,response));
        dispatch(alertAction.successAlert(response.message))
        dispatch(wsmAction.getWorkspaces())
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack()
      },
      error => {
        dispatch(failure(workspaceConstant.CANCEL_SHARE_INVITE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR,error));
        dispatch(request(loaderConstant.LOADING_FALSE))
      }
    )
  }
}
