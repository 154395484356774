import { baseAction } from "./baseAction";
import { dashboardConstant, alertConstant, loaderConstant } from "../constants";
import { dashboardService, taskService, projectService, chatService } from "../services";

const { request, success, failure } = baseAction;

// All login based actions
export const dashboardAction = {
  updateRtext,
  getChats,
  getProjects,
  getTasks,
  getWordClockData,
  updateWordClockData,
  getCityTimeZones,
  getQuickNote,
  updateQuickNote
};

/** udate text */
function updateRtext(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(dashboardConstant.UPDATE_R_TEXT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    dashboardService.updateRtext(params).then(data => {
      dispatch(success(dashboardConstant.UPDATE_R_TEXT_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(dashboardConstant.UPDATE_R_TEXT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**get projects */
function getTasks(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(dashboardConstant.GET_D_TASKS_R, params));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    taskService.getAllTasks(params).then(data => {
      dispatch(success(dashboardConstant.GET_D_TASKS_S, data));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(dashboardConstant.GET_D_TASKS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** GET tasks */
function getProjects(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(dashboardConstant.GET_D_PROJECTS_R, params));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getProjects(params).then(data => {
      dispatch(success(dashboardConstant.GET_D_PROJECTS_S, data));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(dashboardConstant.GET_D_PROJECTS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** GET chats */
function getChats(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(dashboardConstant.GET_D_CHATS_R, params));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    chatService.getAllChatRooms().then(data => {
      dispatch(success(dashboardConstant.GET_D_CHATS_S, data));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(dashboardConstant.GET_D_CHATS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** GET chats */
function getWordClockData(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(dashboardConstant.GET_D_WORLD_CLOCK_R, params));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    dashboardService.getWordClockData().then(data => {
      dispatch(success(dashboardConstant.GET_D_WORLD_CLOCK_S, data));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(dashboardConstant.GET_D_WORLD_CLOCK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** SAVE world clock */
function updateWordClockData(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(dashboardConstant.GET_D_SAVE_WORLD_CLOCK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    dashboardService.updateWordClockData(params).then(data => {
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(success(dashboardConstant.GET_D_SAVE_WORLD_CLOCK_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(dashboardConstant.GET_D_SAVE_WORLD_CLOCK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** Get world clock time zone */
function getCityTimeZones(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(dashboardConstant.GET_C_TIME_ZONE_OPTIONS_R, params));
    // hiding loader as causign rerenders in popups
    if (params.showLoader !== false) {
      dispatch(request(loaderConstant.LOADING_TRUE));
    }
    dashboardService.getCityTimeZones(params).then(data => {
      dispatch(success(dashboardConstant.GET_C_TIME_ZONE_OPTIONS_S, data));
      if (params.showLoader !== false) {
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
      callBack()
    }, error => {
      dispatch(failure(dashboardConstant.GET_C_TIME_ZONE_OPTIONS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getQuickNote() {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(success(dashboardConstant.GET_QUICK_NOTE_R));
    dashboardService.getQuickNote().then(
      (data) => {
        dispatch(success(dashboardConstant.GET_QUICK_NOTE_S, data?.data));
        dispatch(request(loaderConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

function updateQuickNote(doc_id, params) {
  return (dispatch) => {
    dashboardService.updateQuickNote(doc_id, params).then(
      () => {
        // console.log(data)
      },
      (error) => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}