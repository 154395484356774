

const WorkSpaceLobbyStyles = (theme) => ({
    previewDialogContainer: {
        zIndex: '9999 !important',
        '& .MuiCircularProgress-root': {
            position: 'relative',
            '&.backgroundFrame': {
                color: '#e0e0e0', // Gray background
                zIndex: 0,
                position: "absolute"
            },
            '&.mainGround': {
                color: '#2563EB', // Blue main progress
                zIndex: 1,  // Foreground progress higher z-index
            },
            '&.tooltipMain': {
                zIndex: '9999 !important',
            }
        },
    },
    galleryContainer: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "& video": {
            width: "950px",
            height: "auto",
            maxHeight: "600px",
            // maxWidth: "0px",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px"
        },
        "@media only screen and (max-width: 768px)": {
            "&.container": {
                width: "100%",
                "& img": {
                    display: "none",
                    height: "auto",
                    width: "100%",
                    objectFit: "fill",
                    borderRadius: "0px",
                },
            },
        },
    },
    videoContainer: {
        borderRadius: "18px",
        backgroundColor: theme.palette.containers.bg1,
        position: "relative",
    },
    videoFooter: {
        padding: "15px",
        display: "flex",
        height: "80px",
        position:"relative",
        justifyContent: "spaceBetween",
        "& .MuiGrid-root": {
            "& .MuiTypography-root": {
                background: "linear-gradient(107.56deg, #2563EB 16.43%, #8360FD 76.16%)", // Gradient background
                WebkitBackgroundClip: "text",  // Ensures background is clipped to text
                WebkitTextFillColor: "transparent", // Makes the actual text color transparent to reveal gradient
                backgroundClip: "text",
                fontSize: "16px" // Fallback for non-webkit browsers
            }
        }
    },
    header: {
        display: "flex",
        flexDirection: "column",
        gap:"10px"
    },
    percentage: {
        color: "#2563EB",
        fontSize: "12px"
    },
    closeButton: {
        position: "absolute",
        top: 5,
        right: 5,
        display: "block",
        "& .MuiIconButton-label": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        "& .MuiIconButton-label div": {
            display: "block",
        },
        color: "white",
        zIndex: 9999,
        '& .customTooltip': {
            color: "#FFFFFF",
            zIndex: 9999,
            backgroundColor: "#d9d9d9b8",
            borderRadius: "6px",
            paddingLeft: "10px",
            position: "absolute",
            top: 33,
            right: 3,
            fontSize: "14px",
            width: "128px",
            height: "40px",
            padding: "5px"
        }

    },
    previewSmallContainer: {
        position: "absolute",
        bottom: 90,
        left: 20,
        width: "450px",
        maxWidth: "450px",
        zIndex: 9999,
        padding: "2px",
        backgroundColor: "#FFFFFF",
        WebkitBoxShadow: "0px 0px 75px 0px rgba(0, 0, 0, 0.3)",
        MozBoxShadow: "0px 0px 75px 0px rgba(0, 0, 0, 0.3)",
        boxShadow: "0px 0px 75px 0px rgba(0, 0, 0, 0.3)",
        borderRadius: "12px",
        opacity: 1, // Start hidden
        transform: 'translateY(20px)', // Move down for a smoother effect
        transition: 'opacity 0.5s ease, transform 0.5s ease', // Animate opacity and transform
        // "&.visible": {
        //     opacity: 1, // Fully visible
        //     transform: 'translateY(0)', // Move to original position
        // },
        '& .MuiCircularProgress-root': {
            position: 'relative',
            '&.backgroundFrame': {
                color: '#e0e0e0', // Gray background
                zIndex: 0,
                position: "absolute"
            },
            '&.mainGround': {
                color: '#2563EB', // Blue main progress
                zIndex: 1,  // Foreground progress higher z-index
            },
        },

        // "& .closeButtonPreview":{
        //     position:"absolute",
        //     top:10,right:10
        // }
    },
    closeButtonPreview: {
        position: "absolute",
        top: -11,
        right: -7,
        backgroundColor: "#00000061", // Optional: semi-transparent white background for better visibility
        borderRadius: "50%",
        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)", // Add box shadow for better visibility
        '&:hover': {
            backgroundColor: "#00000061", // Op
        },
    },
    tooltipContainer: {
        position: 'relative',
        display: 'inline-block',
        cursor: 'pointer',
        '&:hover $customTooltip': {  // Show the tooltip on hover
            opacity: 1,
            visibility: 'visible',
            transform: 'translateY(0)',
        },
    },
    customTooltip: {
        transform: 'translateX(-50%) translateY(10px)', // Center horizontally and offset by 10px initially
        backgroundColor: 'red', // Tooltip background color
        color: '#fff', // Tooltip text color
        padding: '6px 12px',
        borderRadius: '4px',
        fontSize: '12px',
        whiteSpace: 'nowrap',
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity 0.3s ease, transform 0.3s ease', // Smooth fade-in and move-up
        zIndex: 10, // Ensure tooltip appears above other elements
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
    },
    loader: {
        border: '4px solid #f3f3f3',
        borderRadius: '50%',
        borderTop: '4px solid blue',
        width: '45px',
        height: '45px',
        animation: `$spin 1.5s linear infinite`,
        position:"absolute"
        
    },
    text:{
       display:"flex",
       justifyContent:"center",
       alignItems:"center"
    },
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' },
    },
})

export default WorkSpaceLobbyStyles