import React from "react"
import { CircularProgress, Grid } from "@material-ui/core"
import { PlayCircleOutline } from "@material-ui/icons"
import doc from "../../assets/icons/doc.svg"
import { getUploadPreviewFiles } from "../../utils"
import ChatEditor from "../FlozyEditor/ChatEditor/chatEditor";

export const UploadingFiles = (props) => {
    const { classes, message: { uploadPreviews}, msgComponentType, isSender } = props || { message: {} }
    const { file_type, files_data } = uploadPreviews || {}
    const message_text= files_data ? JSON?.parse(files_data)[0]?.message_text : ""
    
    const renderThumb = (m) => {
        let src = doc;

        if (m.thumb) {
            src = m.thumb;
        } else if (m.preview) {
            src = m.preview
        }
        return (
            <img
                className={classes.chatImage}
                src={src}
                alt={m?.name}
            />
        )
    }

    if (file_type?.indexOf('audio') > -1) {
        return (
            <div className={classes.uploadPreviewAudio}>
                <PlayCircleOutline className='play-image' color="#ccc" />
                <div className="audio-player-dummy" />
                <CircularProgress className="circular-progress" />
            </div>
        )
    } else {
        let previewFiles = getUploadPreviewFiles(files_data) || []
        return (
            <div>
                <div className={classes.uploadPreviewImage}>
                    {
                        previewFiles?.map((m) => {
                            return renderThumb(m)
                        })
                    }
                    <CircularProgress className="circular-progress" />
                </div>
                {
                    message_text?.trim() &&
                    <Grid>
                        <div className={`${classes.textMsgBubble} disable-user-select p5`}>
                        <ChatEditor
                            className={'chatEditorRoot'}
                            id={1}
                            content_status={'loaded'}
                            content={message_text}
                            readOnly={true}
                            msgComponentType={msgComponentType}
                            isSender={isSender}
                        />
                        </div>
                    </Grid>
                }
            </div>
        )
    }
}