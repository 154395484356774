// Import API Helper
import { postRequest } from "../../utils/apiHelper";

export const mediaService = {
    createFolder,
    getAll,
    updateFolderName,
    deleteFolder,
    deleteFile,
    updateFile,
    updateFolderFile,
    uploadFiles,
    editorUploadFiles,
    editorUploadDocuments
};

//create new profile
async function createFolder(params) {
    const response = await postRequest("project/media/createFolder", params);
    return response;
}

//create new profile
async function getAll(params) {
    const response = await postRequest("project/media/getAll", params);
    return response;
}

//create new profile
async function updateFolderName(params) {
    const response = await postRequest("project/media/updateFname", params);
    return response;
}

//delete profile
async function deleteFolder(params) {
    const response = await postRequest("project/media/deleteFolder", params);
    return response;
}

//delete file
async function deleteFile(params) {
    const response = await postRequest("project/media/deleteFile", params);
    return response;
}

//update file
async function updateFile(params) {
    const response = await postRequest("project/media/updateFile", params);
    return response;
}

//update folder file
async function updateFolderFile(params) {
    const response = await postRequest("project/media/updateFolderFile", params);
    return response;
}

//upload file
async function uploadFiles(params) {
    const response = await postRequest("project/media/upload", params);
    return response;
}

//upload file for editor
async function editorUploadFiles(params) {
    const response = await postRequest("general/notes/file_upload", params);
    return response;
}

async function editorUploadDocuments(params, fileName) {
    const response = await postRequest("general/notes/document_upload", params);
    return response;
}