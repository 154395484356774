import { baseAction } from "./baseAction";
import { tasksConstant, loaderConstant, alertConstant, authConstant } from "../constants";
import { tasksService } from "../services";
import { eventsConstant } from "../constants/eventConstant";
import { eventsService } from "../services/eventService";
import moment from "moment";
import { sendEventData } from "../../utils/socketv2";
import { updateAction } from "./updateAction";

const { request, success, failure } = baseAction;

// get all the groups in the task
const getEvents = (params) => {
    const gmtValue = moment().format('Z');
    return (dispatch) => {
        dispatch(request(eventsConstant.GET_EVENT_R, { ...params, timezone: gmtValue, type: 'calendar' }));
        dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.getEvent({ ...params, timezone: gmtValue, type: 'calendar' }).then(data => {
            dispatch(success(eventsConstant.GET_EVENT_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(eventsConstant.GET_EVENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

const getAgendaEvents = (params, callBack = () => { }) => {
    const gmtValue = moment().format('Z');
    return (dispatch) => {
        dispatch(request(eventsConstant.GET_AGENDA_EVENT_R, { ...params, timezone: gmtValue, type: 'agenda' }));
        // dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.getAgendaEvents({ ...params, timezone: gmtValue, type: 'agenda' }).then(data => {
            callBack()
            dispatch(success(eventsConstant.GET_AGENDA_EVENT_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(eventsConstant.GET_AGENDA_EVENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

const getSelectedEvent = (params, callBack = () => { }) => {
    const gmtValue = moment().format('Z');
    return (dispatch) => {
        dispatch(request(eventsConstant.GET_SELECTED_EVENT_R, { ...params, timezone: gmtValue }));
        dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.getSelectedEvent({ id:params, timezone: gmtValue }).then(data => {
            dispatch(success(eventsConstant.GET_SELECTED_EVENT_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack(data?.data)
        }, error => {
            dispatch(failure(eventsConstant.GET_SELECTED_EVENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

function saveEvents(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(eventsConstant.SAVE_EVENT_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.saveEvent(params).then(data => {
            dispatch(success(eventsConstant.SAVE_EVENT_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack(data?.data)
            sendEventData({
                users: params?.members,
                emitType: 'event:notify'
            })
            dispatch(updateAction.update(eventsConstant.GET_AGENDA_EVENT_TITLE,data))
        }, error => {
            dispatch(failure(eventsConstant.SAVE_EVENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function editEvents(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(eventsConstant.SET_EVENT_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.editEvent(params).then(data => {
            dispatch(success(eventsConstant.SET_EVENT_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack()
        }, error => {
            dispatch(failure(eventsConstant.SET_EVENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function deleteEvents(params, occurence, dateRange, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(eventsConstant.DELETE_EVENT_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.deleteEvent(params, occurence, dateRange).then(data => {
            dispatch(success(eventsConstant.DELETE_EVENT_S, params));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack(data?.data)
        }, error => {
            dispatch(failure(eventsConstant.DELETE_EVENT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error))
            dispatch(request(loaderConstant.LOADING_FALSE));
        })
    }
}

function getAppEvents(params,callback= () => { }) {
    return (dispatch) => {
        dispatch(request(eventsConstant.GET_GOOGLE_ACCOUNT_R, params));
        if (params?.type === 'label')
            dispatch(request(eventsConstant.GET_GOOGLE_TOKEN_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(loaderConstant.GOOGLE_EVENTS_LOADING_TRUE));
        eventsService.getAppEvents({ ...params, timeZone: moment.tz.guess() }).then(data => {
            dispatch(success(eventsConstant.GET_GOOGLE_ACCOUNT_S, data));
            if (params?.type === 'label')
                dispatch(request(eventsConstant.GET_GOOGLE_TOKEN_S, params));
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(request(loaderConstant.GOOGLE_EVENTS_LOADING_FALSE));
            callback(data)
        }, error => {
            dispatch(failure(eventsConstant.GET_GOOGLE_ACCOUNT_F, error));
            if (params?.type === 'label')
                dispatch(request(eventsConstant.GET_GOOGLE_TOKEN_F, params));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(request(loaderConstant.GOOGLE_EVENTS_LOADING_FALSE));
        });
    };
}

function getAgendaGoogleEvents(params,callback= () => { }) {
    return (dispatch) => {
        dispatch(request(eventsConstant.GET_AGENDA_GOOGLE_EVENTS_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.getAgendaGoogleEvents({ ...params, timeZone: moment.tz.guess() }).then(data => {
            dispatch(success(eventsConstant.GET_AGENDA_GOOGLE_EVENTS_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));                        
            callback()
        }, error => {
            dispatch(failure(eventsConstant.GET_AGENDA_GOOGLE_EVENTS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function getAgendaSingleGoogleEvents(params,callback= () => { }) {
    return (dispatch) => {
        dispatch(request(eventsConstant.GET_AGENDA_GOOGLE_EVENTS_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.getAgendaGoogleEvents({ ...params, timeZone: moment.tz.guess() }).then(data => {
            dispatch(success(eventsConstant.GET_AGENDA_GOOGLE_EVENTS_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));   
            callback(data)
        }, error => {
            dispatch(failure(eventsConstant.GET_AGENDA_GOOGLE_EVENTS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function getAuthorized(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(eventsConstant.GET_GOOGLE_EMAIL_R, params));
        eventsService.getAuthorized(params).then(data => {
            // dispatch(success(eventsConstant.GET_GOOGLE_EMAIL_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack(data)
        }, error => {
            dispatch(failure(eventsConstant.GET_GOOGLE_EMAIL_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function getAuthUrl(params, pageType) {
    return (dispatch) => {
        dispatch(request(eventsConstant.GET_GOOGLE_AUTH_URL_R, params));
        eventsService.getAuthUrl(params, pageType).then(data => {
            dispatch(success(eventsConstant.GET_GOOGLE_AUTH_URL_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(eventsConstant.GET_GOOGLE_AUTH_URL_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function getGoogleEmail(params) {
    return (dispatch) => {
        if (params?.type === 'connect') {
            dispatch(request(eventsConstant.GET_GOOGLE_EMAIL_R, params));
            dispatch(success(eventsConstant.GET_GOOGLE_EMAIL_S, params?.email));
            localStorage.setItem('emailAddress',params?.email)
        } else if (params?.type === 'disconnect') {
            dispatch(request(eventsConstant.DISCONNECT_GOOGLE_ACCOUNT_R, params));
            dispatch(success(eventsConstant.DISCONNECT_GOOGLE_ACCOUNT_S, params?.email));
            localStorage.setItem('emailAddress','')
        }
    };
}

const getEventView = (callBack = () => { }) => {
    return (dispatch) => {
        dispatch(request(eventsConstant.GET_EVENTVIEW_R));
        dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.getEventView().then(data => {
            dispatch(success(eventsConstant.GET_EVENTVIEW_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack()
        }, error => {
            dispatch(failure(eventsConstant.GET_EVENTVIEW_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

function setCalendarView(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(eventsConstant.SAVE_EVENTVIEW_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.setCalendarView(params).then(data => {
            dispatch(success(eventsConstant.SAVE_EVENTVIEW_S));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack()
        }, error => {
            dispatch(failure(eventsConstant.SAVE_EVENTVIEW_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error))
            dispatch(request(loaderConstant.LOADING_FALSE));
        })
    }
}

function getGoogleMeetLink(){
    return (dispatch) => {
        eventsService.getGoogleMeetLink().then(data => {
            dispatch(success(eventsConstant.GET_MEET_LINK, data.data));
            if (data.message && !data.data) {
                dispatch(failure(alertConstant.ALERT_ERROR, data.message));
                return
            }
            else dispatch(success(eventsConstant.GET_MEET_LINK, data.data));
        }, error => {
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    };
}

function saveAppAuthTokens(params,callback=()=>{}) {
    return (dispatch) => {
        dispatch(request(eventsConstant.SAVE_APP_AUTH_TOKENS_R));
        dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.saveAppAuthTokens(params).then(data => {
            callback(data)
            dispatch(success(eventsConstant.SAVE_APP_AUTH_TOKENS_S));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(eventsConstant.SAVE_APP_AUTH_TOKENS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error))
            dispatch(request(loaderConstant.LOADING_FALSE));
        })
    }
}

function getZoomLink(){
    return (dispatch) => {
        eventsService.getZoomLink().then(data => {
            dispatch(success(eventsConstant.GET_MEET_LINK,data.data));
            if (data.message && !data.data) {
                dispatch(failure(alertConstant.ALERT_ERROR, data.message));
                return
            }
        }, error => {
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    };
}

function disconnectZoom(callback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        eventsService.disconnectZoom().then(data => {
            callback()
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    };
}


function userAccessFailed(msgData) {
    return (dispatch) => {
        dispatch(failure(alertConstant.ALERT_ERROR, msgData));
    }
}

function deleteBooking(id, callback = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE))
        eventsService.deleteBooking(id).then(data => {
            dispatch(request(loaderConstant.LOADING_FALSE))
            callback()
        }, error => {
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

function postWatch(){
    return (dispatch)=>{
        eventsService.postWatch().then(data=>{
            console.log(data)
        })
    }
}



// export all event actions
export const eventAction = {
    saveEvents,
    getEvents,
    getAgendaEvents,
    getSelectedEvent,
    editEvents,
    deleteEvents,
    getGoogleEmail,
    getAppEvents,
    getEventView,
    setCalendarView,
    getAuthorized,
    getAuthUrl,
    getGoogleMeetLink,
    saveAppAuthTokens,
    getZoomLink,
    userAccessFailed,
    disconnectZoom,
    deleteBooking,
    postWatch,
    getAgendaGoogleEvents,
    getAgendaSingleGoogleEvents
};
