// Alert reducer types
export const chatHubConstant = {
    //GET PROJECTS
    GET_CHAT_PROJECTS_R: "GET_CHAT_PROJECTS_R",
    GET_CHAT_PROJECTS_S: "GET_CHAT_PROJECTS_S",
    GET_CHAT_PROJECTS_F: "GET_CHAT_PROJECTS_F",

    //GET CLIENTS
    GET_CHAT_CLIENTS_R: "GET_CHAT_CLIENTS_R",
    GET_CHAT_CLIENTS_S: "GET_CHAT_CLIENTS_S",
    GET_CHAT_CLIENTS_F: "GET_CHAT_CLIENTS_F",

    //GET TEAMS
    GET_CHAT_TEAM_R: "GET_CHAT_TEAM_R",
    GET_CHAT_TEAM_S: "GET_CHAT_TEAM_S",
    GET_CHAT_TEAM_F: "GET_CHAT_TEAM_F",

    //GET TEAMS SEARCH
    GET_CHAT_TEAM_USER_SEARCH_R: "GET_CHAT_TEAM_USER_SEARCH_R",
    GET_CHAT_TEAM_USER_SEARCH_S: "GET_CHAT_TEAM_USER_SEARCH_S",
    GET_CHAT_TEAM_USER_SEARCH_F: "GET_CHAT_TEAM_USER_SEARCH_F",

    //Update chat hub type
    UPDATE_CHAT_HUB_TYPE: "UPDATE_CHAT_HUB_TYPE",

    //Update chat hub room
    UPDATE_CHAT_HUB_ROOM: "UPDATE_CHAT_HUB_ROOM",

    UPDATE_CHAT_HUB_ROOM_R: "UPDATE_CHAT_HUB_ROOM_R",
    UPDATE_CHAT_HUB_ROOM_S: "UPDATE_CHAT_HUB_ROOM_S",

    UPDATE_NEW_TEAM: "UPDATE_NEW_TEAM",

    GET_ALL_USERS_R: "GET_ALL_USERS_R",
    GET_ALL_USERS_S: "GET_ALL_USERS_S",
    GET_ALL_USERS_F: "GET_ALL_USERS_F",

    CREATE_NEW_GROUP_R: "CREATE_NEW_GROUP_R",
    CREATE_NEW_GROUP_S: "CREATE_NEW_GROUP_S",
    CREATE_NEW_GROUP_F: "CREATE_NEW_GROUP_F",

    //Get All chat rooms
    GET_ALL_CHAT_ROOMS_R: "GET_ALL_CHAT_ROOMS_R",
    GET_ALL_CHAT_ROOMS_S: "GET_ALL_CHAT_ROOMS_S",
    GET_ALL_CHAT_ROOMS_F: "GET_ALL_CHAT_ROOMS_F",

    UPDATE_MOBILE_CHAT_SCREEN: "UPDATE_MOBILE_CHAT_SCREEN",


    //Get new messages
    GET_NEW_CHAT_MESSAGE_R: "GET_NEW_CHAT_MESSAGE_R",
    GET_NEW_CHAT_MESSAGE_S: "GET_NEW_CHAT_MESSAGE_S",
    GET_NEW_CHAT_MESSAGE_F: "GET_NEW_CHAT_MESSAGE_F",

    UPDATE_NEW_CHAT_MESSAGE_S: "UPDATE_NEW_CHAT_MESSAGE_S",

    UPDATE_PROJECT_SORT: "UPDATE_PROJECT_SORT",
    UPDATE_CLIENT_SORT: "UPDATE_CLIENT_SORT",
    UPDATE_TEAM_SORT: "UPDATE_TEAM_SORT",

    UPDATE_GROUP_IMAGE: "UPDATE_GROUP_IMAGE",

    //Update Group info content
    UPDATE_GROUP_DATA_R: "UPDATE_GROUP_DATA_R",
    UPDATE_GROUP_DATA_S: "UPDATE_GROUP_DATA_S",
    UPDATE_GROUP_DATA_F: "UPDATE_GROUP_DATA_F",

    // reset chat Messages
    RESET_CHAT_DATA_R: "RESET_CHAT_DATA_R",
    RESET_CHAT_DATA_S: "RESET_CHAT_DATA_S",
    RESET_CHAT_DATA_F: "RESET_CHAT_DATA_F",

    GET_CLIENT_ASSOC_USERS_R: "GET_CLIENT_ASSOC_USERS_R",
    GET_CLIENT_ASSOC_USERS_S: "GET_CLIENT_ASSOC_USERS_S",
    GET_CLIENT_ASSOC_USERS_F: "GET_CLIENT_ASSOC_USERS_F",

    // Update Mute Notification
    UPDATE_MUTE_NOTIFICATION_R: "UPDATE_MUTE_NOTIFICATION_R",
    UPDATE_MUTE_NOTIFICATION_S: "UPDATE_MUTE_NOTIFICATION_S",
    UPDATE_MUTE_NOTIFICATION_F: "UPDATE_MUTE_NOTIFICATION_F",

    // Get Muted Chat List
    GET_ALL_MUTED_CHAT_R: "GET_ALL_MUTED_CHAT_R",
    GET_ALL_MUTED_CHAT_S: "GET_ALL_MUTED_CHAT_S",
    GET_ALL_MUTED_CHAT_F: "GET_ALL_MUTED_CHAT_F",

    // update latest message in chatHub using message id
    UPDATE_CHATHUB_MESSAGE: "UPDATE_CHATHUB_MESSAGE"
};
