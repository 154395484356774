import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHotkeys } from 'react-hotkeys-hook';
import moment from 'moment';


// Import Public Layout
import PrivateLayout from "../layouts/PrivateLayout/PrivateLayout";

//import actions
import { authAction, userAgencyAction, profileAction } from "./../redux/actions";

//import history
import history from "../config/history";

//import service worker
import { registerServiceWorker } from '../serviceWorker';
import Socketv2 from "../containers/Socketv2";
import { getDefaultChatRoomPreference } from "../utils";

// Routes the page without any authentication by the user
const PrivateRoute = ({ component: Component, ...rest }) => {


  // Define Dispatch and State
  const dispatch = useDispatch();

  const { user_role, ag_owner, id: id1, agency_pref, signupData } = useSelector(s => s.auth.loginUser);
  const [key, setKey] = useState("");
  const hasKey = localStorage.getItem('key');

  // Get Logged In User Details
  useEffect(() => {
    if (
      user_role &&
      (user_role.type === "emp" || user_role.type === "client") &&
      (
        rest.path == '/mybills' || rest.path == '/mybillsnew' ||
        rest?.location?.pathname.includes("/chat/schedule") || rest?.location?.pathname.includes("/chat/workflow")
      )
    ) {
      history.goBack()
    }

  }, [rest.path, rest?.location?.pathname])

  useEffect(() => {
    if (signupData && signupData?.step_3 !== 1 && (rest.path !== '/process_validate' && rest.path !== '/agencyAdd')) {
      history.push('/process_validate');
    }
  }, [signupData])

  useEffect(() => {
    // Get users timezone
    const userTimeZone = moment.tz.guess();
    const params = {
      time_zone: userTimeZone
    }
    if (hasKey) {
      dispatch(authAction.getProfileData(params, () => { }));
      dispatch(userAgencyAction.getUserAgencies());
      dispatch(userAgencyAction.getPlanDetails())
      dispatch(profileAction.getNotifications());
      dispatch(profileAction.getInvites());
      dispatch(profileAction.getTasks());
    }
    return () => {
      dispatch(userAgencyAction.clearUserAgencies());
    };
  }, [dispatch, hasKey]);

  const getDefaultChatRoom = () => {
    const { type } = getDefaultChatRoomPreference()
    return type
  }
  useEffect(() => {
    if (key === "d") {
      if (user_role && user_role["dash"]) {
        history.push("/dashboard")
      }
    } else if (key === "c") {
      if (user_role && user_role["cli"]) {
        history.push("/clients")
      }
    } else if (key === "f") {
      if (user_role && user_role["pjt"]) {
        history.push("/projects")
      }
    } else if (key === "h") {
      if (user_role && user_role["cHub"]) {
        history.push(`/chat/${getDefaultChatRoom()}`)
      }
    } else if (key === "i") {
      if (user_role && user_role["invoice"]) {
        history.push("/invoices")
      }
    } else if (key === "p") {
      if (user_role && user_role["profile"]) {
        history.push("/settings/profile")
      }
    } else if (key === "t" && user_role.type !== "client") {
      history.push("/tasks")
    } else if (key === "a" && user_role.type !== "client") {
      if (ag_owner === id1) {
        history.push("/teams")
      }
    }
  }, [key])

  //keyboard routes
  useHotkeys('g+d', () => { setKey("d"); })
  useHotkeys('g+c', () => { setKey("c"); })
  useHotkeys('g+f', () => { setKey("f"); })
  useHotkeys('g+h', () => { setKey("h"); })
  useHotkeys('g+i', () => { setKey("i"); })
  useHotkeys('g+p', () => { setKey("p"); })
  useHotkeys('g+t', () => { setKey("t"); })
  useHotkeys('g+a', () => { setKey("a"); })

  return (
    <Route {...rest} render={props => (
      <PrivateLayout {...props}>
        <Component {...props} />
        <Socketv2 />
      </PrivateLayout>
    )} />)
};

registerServiceWorker();

// Define Props Types
PrivateRoute.propTypes = {
  component: PropTypes.elementType
};

export default PrivateRoute;
