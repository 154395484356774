import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { profileAction } from "../../../redux/actions";


function ScriptComponent() {
    const { userEmail, first_name, last_name, settings } = useSelector(s => (s.profile.profile));
    const restore_id = settings?.hasOwnProperty('fresh_restore_id') ? settings?.fresh_restore_id : null;
    const [restoreId, setRestoreId] = useState(restore_id && restore_id !== "undefined" ? restore_id : null);

    // Define Dispatch
    const dispatch = useDispatch();

    useEffect(() => {
        if (restoreId && userEmail) {
            window.fcWidgetMessengerConfig = {
                externalId: `${userEmail}`,
                restoreId: restoreId ? restoreId : null
            }
        }
    }, [userEmail, restoreId]);


    useEffect(() => {
        window.fcSettings = {
            onInit: function () {
                window.fcWidget.on('widget:loaded', function () {
                    // console.log("Widget Loaded..!")
                    // window.fcWidget.hide();
                    window.fcWidget.user.get(function (resp) {
                        var status = resp && resp.status;

                        if (status !== 200) {
                            window.fcWidget.user.setProperties({
                                firstName: `${first_name}`,
                                lastName: `${last_name}`,
                                email: `${userEmail}`
                            });

                            window.fcWidget.on('user:created', function (resp) {
                                window.fcWidget.setExternalId(`${userEmail}`);
                                window.fcWidget.user.setFirstName(`${first_name}`);
                                window.fcWidget.user.setLastName(`${last_name}`);
                                window.fcWidget.user.setEmail(`${userEmail}`);

                                var status = resp && resp.status,
                                    data = resp && resp.data;
                                if (status === 200) {
                                    if (data.restoreId) {
                                        // Update restoreId in your database
                                        dispatch(profileAction.updateSupportRestoreId({ restoreId: data.restoreId }, () => {
                                            setRestoreId(data?.restoreId);
                                        }))
                                    }
                                }
                            });
                        }
                    });
                });

                window.fcWidget.on("widget:closed", function (resp) {
                    // window.fcWidget.hide();
                });
            }
        }
    }, []);


    return (
        <Helmet>
            <script src='//au.fw-cdn.com/20773982/232339.js' chat='true' widgetId='ecf3a3e6-542a-4690-8deb-4e05c50172ae'></script>
        </Helmet>
    )
}

export default ScriptComponent;