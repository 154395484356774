// Alert reducer types
export const clientConstant = {
    //GET CLIENTS
    CREATE_CLIENT_R: "CREATE_CLIENT_R",
    CREATE_CLIENT_S: "CREATE_CLIENT_S",
    CREATE_CLIENT_F: "CREATE_CLIENT_F",

    //update CLIENT
    UPDATE_CREATE_CLIENT: "UPDATE_CREATE_CLIENT",

    GET_TAGS1_R: "GET_TAGS1_R",
    GET_TAGS1_S: "GET_TAGS1_S",
    GET_TAGS1_F: "GET_TAGS1_F",

    //GET CLIENTS
    GET_CLIENTS_R: "GET_CLIENTS_R",
    GET_CLIENTS_S: "GET_CLIENTS_S",
    GET_CLIENTS_F: "GET_CLIENTS_F",

    //GET CLIENTS
    GET_CLIENTS_COUNT_R: "GET_CLIENTS_COUNT_R",
    GET_CLIENTS_COUNT_S: "GET_CLIENTS_COUNT_S",
    GET_CLIENTS_COUNT_F: "GET_CLIENTS_COUNT_F",

    //GET CLIENTS FILTER
    GET_CLIENTS_FILTER_R: "GET_CLIENTS_FILTER_R",
    GET_CLIENTS_FILTER_S: "GET_CLIENTS_FILTER_S",
    GET_CLIENTS_FILTER_F: "GET_CLIENTS_FILTER_F",

    UPATE_C_FILTER_DATA: "UPATE_C_FILTER_DATA",

    //update drag and drop
    UPDATE_C_DND_R: "UPDATE_C_DND_R",
    UPDATE_C_DND_S: "UPDATE_C_DND_S",
    UPDATE_C_DND_F: "UPDATE_C_DND_F",

    //get client detail
    GET_CLIENT_DETAIL_R: "GET_CLIENT_DETAIL_R",
    GET_CLIENT_DETAIL_S: "GET_CLIENT_DETAIL_S",
    GET_CLIENT_DETAIL_F: "GET_CLIENT_DETAIL_F",

    //complete client
    COMPLETE_CLIENT_R: "COMPLETE_CLIENT_R",
    COMPLETE_CLIENT_S: "COMPLETE_CLIENT_S",
    COMPLETE_CLIENT_F: "COMPLETE_CLIENT_F",

    //archive client
    ARCHIVE_CLIENT_R: "ARCHIVE_CLIENT_R",
    ARCHIVE_CLIENT_S: "ARCHIVE_CLIENT_S",
    ARCHIVE_CLIENT_F: "ARCHIVE_CLIENT_F",

    //remove project
    REMOVE_CLIENT_R: "REMOVE_CLIENT_R",
    REMOVE_CLIENT_S: "REMOVE_CLIENT_S",
    REMOVE_CLIENT_F: "REMOVE_CLIENT_F",

    /********************************BRIEF DETAILS**************************************************************** */

    //get  client brief
    GET_CLIENT_BRIEF_R: "GET_CLIENT_BRIEF_R",
    GET_CLIENT_BRIEF_S: "GET_CLIENT_BRIEF_S",
    GET_CLIENT_BRIEF_F: "GET_CLIENT_BRIEF_F",

    //update reducer
    UPDATE_CLIENT_BRIEF_REDUCER: "UPDATE_CLIENT_BRIEF_REDUCER",

    //update brief
    UPDATE_CLIENT_BRIEF_R: "UPDATE_CLIENT_BRIEF_R",
    UPDATE_CLIENT_BRIEF_S: "UPDATE_CLIENT_BRIEF_S",
    UPDATE_CLIENT_BRIEF_F: "UPDATE_CLIENT_BRIEF_F",

    UPDATE_CLIENT_CLONE_REDUCER: "UPDATE_CLIENT_CLONE_REDUCER",

    //create duplicate project
    CREATE_CLONE_CLIENT_R: "CREATE_CLONE_CLIENT_R",
    CREATE_CLONE_CLIENT_S: "CREATE_CLONE_CLIENT_S",
    CREATE_CLONE_CLIENT_F: "CREATE_CLONE_CLIENT_F",

    //update client clone active team users
    UPDATE_CLIENT_CLONE_ACTIVE_TEAM_S: "UPDATE_CLIENT_CLONE_ACTIVE_TEAM_S",

    /********************************NOTES DETAILS**************************************************************** */

    //UPDATE NOTE
    UPDATE_C_NOTE_R: "UPDATE_C_NOTE_R",
    UPDATE_C_NOTE_S: "UPDATE_C_NOTE_S",
    UPDATE_C_NOTE_F: "UPDATE_C_NOTE_F",

    //ADD NOTE
    ADD_C_NOTE_R: "ADD_C_NOTE_R",
    ADD_C_NOTE_S: "ADD_C_NOTE_S",
    ADD_C_NOTE_F: "ADD_C_NOTE_F",

    //update new note data 
    UPDATE_C_NOTE_REDUCER: "UPDATE_C_NOTE_REDUCER",

    //clear selected note when close editor
    CLEAR_C_SELECTED_NOTE: "CLEAR_C_SELECTED_NOTE",

    //GET ALL NOTES
    GET_ALL_C_NOTES_R: "GET_ALL_C_NOTES_R",
    GET_ALL_C_NOTES_S: "GET_ALL_C_NOTES_S",
    GET_ALL_C_NOTES_F: "GET_ALL_C_NOTES_F",

    //GET ONE NOTES
    GET_ONE_C_NOTES_R: "GET_ONE_C_NOTES_R",
    GET_ONE_C_NOTES_S: "GET_ONE_C_NOTES_S",
    GET_ONE_C_NOTES_F: "GET_ONE_C_NOTES_F",

    //UPDATE NOTE
    SHARE_C_NOTE_R: "SHARE_C_NOTE_R",
    SHARE_C_NOTE_S: "SHARE_C_NOTE_S",
    SHARE_C_NOTE_F: "SHARE_C_NOTE_F",

    //delete current task
    DEL_CURRENT_C_NOTE_R: "DEL_CURRENT_C_NOTE_R",
    DEL_CURRENT_C_NOTE_S: "DEL_CURRENT_C_NOTE_S",
    DEL_CURRENT_C_NOTE_F: "DEL_CURRENT_C_NOTE_F",


    UPDATE_C_NOTE_REDUCER_TITLE: "UPDATE_C_NOTE_REDUCER_TITLE",

    /************************************TEAMS DETAILS*********************************************************** */

    //GET PROJECT TEAM USERS
    GET_CA_TEAM_R: "GET_CA_TEAM_R",
    GET_CA_TEAM_S: "GET_CA_TEAM_S",
    GET_CA_TEAM_F: "GET_CA_TEAM_F",

    //GET PROJECT TEAM USERS
    GET_CA_ACTIVE_TEAM_R: "GET_CA_ACTIVE_TEAM_R",
    GET_CA_ACTIVE_TEAM_S: "GET_CA_ACTIVE_TEAM_S",
    GET_CA_ACTIVE_TEAM_F: "GET_CA_ACTIVE_TEAM_F",

    UPDATE_C_TEAM_USERS: "UPDATE_C_TEAM_USERS",

    USER_C_EXISTING_INVITE_R: "USER_C_EXISTING_INVITE_R",
    USER_C_EXISTING_INVITE_S: "USER_C_EXISTING_INVITE_S",
    USER_C_EXISTING_INVITE_F: "USER_C_EXISTING_INVITE_F",

    USER_C_NEW_INVITE_R: "USER_C_NEW_INVITE_R",
    USER_C_NEW_INVITE_S: "USER_C_NEW_INVITE_S",
    USER_C_NEW_INVITE_F: "USER_C_NEW_INVITE_F",

    REMOVE_C_TEAM_USER_R: "REMOVE_C_TEAM_USER_R",
    REMOVE_C_TEAM_USER_S: "REMOVE_C_TEAM_USER_S",
    REMOVE_C_TEAM_USER_F: "REMOVE_C_TEAM_USER_F",

    DELETE_C_USER_FROM_AGENCY_R: "DELETE_C_USER_FROM_AGENCY_R",
    DELETE_C_USER_FROM_AGENCY_S: "DELETE_C_USER_FROM_AGENCY_S",
    DELETE_C_USER_FROM_AGENCY_F: "DELETE_C_USER_FROM_AGENCY_F",

    //GET ALL TEAM MEMBERS
    GET_C_TEAM_R: "GET_C_TEAM_R",
    GET_C_TEAM_S: "GET_C_TEAM_S",
    GET_C_TEAM_F: "GET_C_TEAM_F",

    //NEW_C_USER_ACCEPT_INVITATION
    NEW_C_USER_ACCEPT_R: "NEW_C_USER_ACCEPT_R",
    NEW_C_USER_ACCEPT_S: "NEW_C_USER_ACCEPT_S",
    NEW_C_USER_ACCEPT_F: "NEW_C_USER_ACCEPT_F",

    G_NEW_C_USER_ACCEPT_R: "G_NEW_C_USER_ACCEPT_R",
    G_NEW_C_USER_ACCEPT_S: "G_NEW_C_USER_ACCEPT_S",
    G_NEW_C_USER_ACCEPT_F: "G_NEW_C_USER_ACCEPT_F",

    /***************************************TASK DETAILS****************************************************** */

    //create new task
    CREATE_NEW_C_TASK_R: "CREATE_NEW_C_TASK_R",
    CREATE_NEW_C_TASK_S: "CREATE_NEW_C_TASK_S",
    CREATE_NEW_C_TASK_F: "CREATE_NEW_C_TASK_F",

    //get all task
    GET_ALL_C_TASK_R: "GET_ALL_C_TASK_R",
    GET_ALL_C_TASK_S: "GET_ALL_C_TASK_S",
    GET_ALL_C_TASK_F: "GET_ALL_C_TASK_F",

    //get current task
    GET_CURRENT_C_TASK_R: "GET_CURRENT_C_TASK_R",
    GET_CURRENT_C_TASK_S: "GET_CURRENT_C_TASK_S",
    GET_CURRENT_C_TASK_F: "GET_CURRENT_C_TASK_F",

    //delete current task
    DEL_CURRENT_C_TASK_R: "DEL_CURRENT_C_TASK_R",
    DEL_CURRENT_C_TASK_S: "DEL_CURRENT_C_TASK_S",
    DEL_CURRENT_C_TASK_F: "DEL_CURRENT_C_TASK_F",

    //update current task
    UPDATE_CURRENT_C_TASK: "UPDATE_CURRENT_C_TASK",

    UPATE_CARD_VIEW: "UPATE_CARD_VIEW",

    UPATE_CLIENT_TYPE: "UPATE_CLIENT_TYPE",


    //update task
    UPDATE_CURRENT_C_TASK_R: "UPDATE_CURRENT_C_TASK_R",
    UPDATE_CURRENT_C_TASK_S: "UPDATE_CURRENT_C_TASK_S",
    UPDATE_CURRENT_C_TASK_F: "UPDATE_CURRENT_C_TASK_F",

    UPDATE_C_CREATE_TASK: "UPDATE_C_CREATE_TASK",

    //filter
    GET_C_TASKS_FILTER_R: "GET_C_TASKS_FILTER_R",
    GET_C_TASKS_FILTER_S: "GET_C_TASKS_FILTER_S",
    GET_C_TASKS_FILTER_F: "GET_C_TASKS_FILTER_F",

    //complete task
    COMPLETE_TASK_R: "COMPLETE_TASK_R",
    COMPLETE_TASK_S: "COMPLETE_TASK_S",
    COMPLETE_TASK_F: "COMPLETE_TASK_F",

    //GET_C_TASK_TAGS
    GET_C_TASK_TAGS_R: "GET_C_TASK_TAGS_R",
    GET_C_TASK_TAGS_S: "GET_C_TASK_TAGS_S",
    GET_C_TASK_TAGS_F: "GET_C_TASK_TAGS_F",

    //create task notes
    GET_C_TASK_NOTE_R: "GET_C_TASK_NOTE_R",
    GET_C_TASK_NOTE_S: "GET_C_TASK_NOTE_S",
    GET_C_TASK_NOTE_F: "GET_C_TASK_NOTE_F",

    //create task notes
    CREATE_C_TASK_NOTE_R: "CREATE_C_TASK_NOTE_R",
    CREATE_C_TASK_NOTE_S: "CREATE_C_TASK_NOTE_S",
    CREATE_C_TASK_NOTE_F: "CREATE_C_TASK_NOTE_F",


    //delete task notes
    DELETE_TASK_NOTE_R: "DELETE_TASK_NOTE_R",
    DELETE_TASK_NOTE_S: "DELETE_TASK_NOTE_S",
    DELETE_TASK_NOTE_F: "DELETE_TASK_NOTE_F",

    // Create Client Onboarding invites
    CREATE_CLIENT_INVITE_R: 'CREATE_CLIENT_INVITE_R',
    CREATE_CLIENT_INVITE_S: 'CREATE_CLIENT_INVITE_S',
    CREATE_CLIENT_INVITE_F: 'CREATE_CLIENT_INVITE_F',

    // Get Client Invite List
    GET_CLIENT_INVITE_LIST_R: "GET_CLIENT_INVITE_LIST_R",
    GET_CLIENT_INVITE_LIST_S: "GET_CLIENT_INVITE_LIST_S",
    GET_CLIENT_INVITE_LIST_F: "GET_CLIENT_INVITE_LIST_F",

    //Get Selected Invite
    GET_CLIENT_INVITE_R: "GET_CLIENT_INVITE_R",
    GET_CLIENT_INVITE_S: "GET_CLIENT_INVITE_S",
    GET_CLIENT_INVITE_F: "GET_CLIENT_INVITE_F",

    //Get Agency Info Invite
    GET_INVITE_AGENCY_INFO_R: "GET_INVITE_AGENCY_INFO_R",
    GET_INVITE_AGENCY_INFO_S: "GET_INVITE_AGENCY_INFO_S",
    GET_INVITE_AGENCY_INFO_F: "GET_INVITE_AGENCY_INFO_F",

    //Get Filter List
    GET_FILTER_LIST_R: "GET_FILTER_LIST_R",
    GET_FILTER_LIST_S: "GET_FILTER_LIST_S",
    GET_FILTER_LIST_F: "GET_FILTER_LIST_F",

    PREFERANCE_R: "PREFERANCE_R",
    PREFERANCE_S: "PREFERANCE_S",
    PREFERANCE_F: "PREFERANCE_F",
};
