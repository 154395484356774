const styles = (theme) => ({
    recording: {
        position: "absolute",
        top: "0",
        bottom: "0",
        left: "0",
        right: "0",
        background: "transparent",
        borderRadius: "50%",
        border: "2px dashed #000",
        animationName: '$rotate',
        animationDuration: '3s',
        animationIterationCount: 'infinite',
        animationTimingFunction: 'linear',
    },
    recordingGrid: {
        right: "1rem",
        bottom: "105%",
        position: "absolute",
        borderRadius: "2rem",
        background: "#F5F5F7",
        height: "auto",
        width: "auto",
        zIndex: "99",
        "& .recordingContainer": {
            display: "flex",
            padding: "0.5rem",
            alignItems: "center",
            borderRadius: "2rem",
            flexDirection: "column",
            justifyContent: "space-around",
            height: "100%",
        },
        "& .iconBtn": {
            color: "#000",
            background: "#FFFFFF 0% 0% no-repeat padding-box",
            boxShadow: "0px 3px 6px #0000001A",
        },
        "& .recordIcon": {
            color: "red",
        },
        "& .micIcon": {
            width: "10rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "6rem"
        },
        "& .timerSection": {

        },
        "& .playerControls": {
            width: "100%",
            justifyContent: "space-evenly",
            display: "flex",
            alignItems: "center"
        },
        "& audio": {
            // visibility: "hidden",
        },
    },
    audioContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& .btn-box": {
            display: "flex",
            justifyContent: "space-evenly",
            "& button": {
                borderRadius: "50%",
            }
        },
        "& audio": {
            display: "none"
        }
    },
    hideAudioContainer: {
        display: "none"
    }
});

export default styles;
