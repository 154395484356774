import { useMemo } from "react";
import { useSelector } from "react-redux";
import { editorBoardHideTools, editorBrainHideTools, editorBriefHideTools, editorFunnelHideTools } from "../utils";

const MAP_DEFAULT_HIDE_TOOLS = {
    brain: editorBrainHideTools,
    board: editorBoardHideTools,
    brief: editorBriefHideTools,
    funnel: editorFunnelHideTools,
    pageBuilder: ["nextTrigger","prevTrigger", "addElement"],
    chat: ["slash"]
}

function useEditorHideTools(type) {
    const { planDetails } = useSelector( s => s.userAgency);
    const { flags } = useSelector(s => (s.featureFlags));
    

    const hideTools = useMemo(() => {
        const hide = [...(MAP_DEFAULT_HIDE_TOOLS[type] || [])]

        if (!( planDetails.plan_code?.includes("educate") || planDetails.plan_code === 'pro_plan_v2'|| planDetails.plan_code === 'pro_plan')) {
            hide.push("infinityAI")
        }

        return hide;
    }, [type, flags])

    return hideTools;
}

export default useEditorHideTools