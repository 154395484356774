import { isMobile } from "react-device-detect"
const Styles = (theme) => ({
    ProjectsMenChatbodyuBody: {
        height: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        // position: 'relative'
    },
    chatMessageComponent: {
        "& .chatSingle": {
            marginBottom: "0px",
            display: "block",
            "& .MuiChip-root": {
                color: '#94A3B8 !important',
                background: 'transparent',
                fontWeight: 500,
                fontSize: '12px',
                // boxShadow: '0 1px 0.5px rgb(0 0 0 / 8%)',
                borderRadius: '6px',
                height: '24px',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            '& .MuiChip-labelSmall': {
                paddingLeft: '15px',
                paddingRight: '15px'
            },
            '&.timeLabel': {
                display: "flex",
                alignItems: 'center',
                justifyContent: 'center',
                padding: '18px 0px',
                paddingRight: "40px",
                paddingLeft: "40px",
            },
            // '& hr': {
            //     flex: 1,
            //     height: '0.5px',
            //     borderColor: 'white',
            //     background: theme.palette.primary.border3,
            //     border: '0px solid !important'
            // }
        }
    },
    ChatContainer: {
        // background: '#fff',
        flex: '1 1 50%',
        position: 'relative',
        display: 'flex',
        '& .chatMessageRoot': {
            display: 'flex',
            height: '100%',
            width: '100%',
        },
        "& .chatMessageContainer": {
            background: '#fff',
            borderRadius: '12px',
            "&:focus": {
                border: "0px !important",
                outline: "0px !important",
            }
        },
        '@media only screen and (min-width: 769px)': {
            '& .chatMessageRoot': {
                // paddingLeft: '20px',
            },
        }
    },
    ChatHeader: {
        padding: '5px 10px',
        // borderBottom: `1px solid ${palette.primary.border2}`,
        maxHeight: '56px',
        '& .MoreIcon': {
            height: 30,
            width: 30
        },
        '& .chatHeaderAction': {
            background: theme.palette.containers.bg7,
            borderRadius: '12px',
            padding: '4px 10px',
            '& .CloseIcon': {
                width: 13,
                height: 13
            }
        }
    },
    textTypeIcon: {
        position: 'absolute',
        left: 24,
        top: 8,
        height: 50,
        width: 50,
        borderRight: `1px solid ${theme.palette.primary.border1}`,
        paddingRight: 15
    },
    ChatTypingArea: {
        // borderTop: '1px solid #cdcdcd'
        color: theme.palette.primary.main,
        width: '100%',
        position: "relative",
        backgroundColor: theme.palette.containers.card,
        padding: '0px 10px 15px',
        borderRadius: '12px',
        "& .MuiIconButton-root": {
            padding: isMobile ? '6px !important' : '12px'
        },
        '@media only screen and (max-width: 899px)': {
            borderRadius: '30px',
            '&>.MuiGrid-container': {
                boxShadow: 'none !important',
                borderRadius: '12px',
                padding: '0px !important',
                backgroundColor: theme.palette.containers.bg29,
            },
            '& .chatInputBox': {
                borderRadius: '8px',
                // background: '#F0F0F0'
            },
            '& .textOverallCon': {
                background: 'transparent',
                // borderRadius: '8px'
            },
            '& #chat-msg-editor': {
                justifyContent: 'center',
                // paddingTop: '0px !important',
                // '&:before': {
                //     height: '42px',
                //     padding: '8px 0px'
                // }
            }
        },
        '&>.MuiGrid-container': {
            boxShadow: '0px 0px 8px rgb(0 0 0 / 5%)',
            borderRadius: '12px',
            backgroundColor: theme.palette.containers.bg29,
            border: `1px solid ${theme.palette.primary.border2}`,
            '@media only screen and (max-width: 899px)': {
                borderRadius: '30px',
            }
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#F5F5F7'
        },
        '& .chatInputBox': {
            // width: 'calc(100% - 175px)',
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            // maxHeight: 150,
            // overflowY: 'auto',
            // padding: '8px 5px'
            padding: '0px 5px',
            margin: '2px 0 2px 2px',
        },
        '& .DraftEditor-root': {
            display: 'flex',
            flexGrow: 1,
            alignItems: 'center',
            // background: palette.primary.bgColorGray,
            // borderLeft: `1px solid ${palette.primary.border1}`,
            // borderRadius: '10px',
            padding: '10px',
            // marginTop: '5px',
            // marginBottom: '5px',
            minHeight: 50,
            // paddingLeft: 75,
            '& .DraftEditor-editorContainer': {
                width: "100%",
                height: "auto",
                overflowY: 'auto',
                maxHeight: '100px',
                '& .public-DraftStyleDefault-block': {
                    wordBreak: 'break-word',
                    margin: '0px !important',
                    // overflowY: 'auto'
                }
            }
        }
    },
    infinityMode: {
        '&.active': {
            borderRadius: '12px',
            backgroundImage: `linear-gradient(${theme.palette.containers.card}, ${theme.palette.containers.card}), linear-gradient(180deg, #8D53F2 , #9D3EDF,#2140FF )`,
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
            padding: '1px !important',
            border: '1px'
        }
    },
    chatTypeField: {
        maxWidth: 'calc(100% - 75px)'
    },
    ChatBodyContainer: {
        flex: '1 1 0',
        position: 'relative',
        overflowY: 'auto',
        display: 'flex',
        "& .scrollChatLine": {
            background: '#F5F5F7 !important'
        },
        "&:focus": {
            border: "0px !important",
            outline: "0px !important",
        },
        '& .MuiAvatar-root': {
            height: 30,
            width: 30,
            fontSize: 15
        },
        "& .chatName": {
            fontSize: "13px",
            fontWeight: 500
        },
    },
    Chatbody: {
        // height: 'calc(100vh - 343px)',
        width: '100%',
        padding: '0px',
        overflowY: 'auto',
        position: 'relative',
        '& .chat-online': {
            position: 'absolute',
            width: '12px',
            height: '12px',
            background: '#35ac19',
            borderRadius: '50%',
            bottom: '0',
            zIndex: 1,
            border: '2px solid #fff',
        },

    },
    chatTile: {
        width: 'auto',
        maxWidth: '65%',
        // minWidth: '15%',
        minWidth: '7.5rem',
        display: 'inline-block',
        padding: '8px 6px',
        paddingBottom: "12px",
        borderRadius: '0 10px 10px 20px',
        lineHeight: '20px',
        verticalAlign: 'middle',
        textAlign: 'left',
        wordBreak: 'break-word',
        position: 'relative',

        '& .time_ago': {
            position: 'absolute',
            padding: '0px',
            bottom: 0,
            right: 3,
            fontSize: '10px',
            lineHeight: "0.9rem",
            color: theme.palette.primary.light,
            opacity: '.6',
            zIndex: 1,
            display: 'block',
            textAlign: 'right',
            // marginBottom: 5,
            // marginRight: 5
        },
        '& .chat-content': {
            display: 'block',
            fontSize: 12
        },
        '& .reply-content': {
            display: 'block',
            marginTop: 0,
            marginBottom: 1,
            fontSize: 12,
            marginRight: 0,
        },
        '& .repliedPerson': {
            fontSize: 12,
            fontWeight: 600
        }
    },
    repliedMSg: {
        background: '#e8e8e8',
        padding: '2px 9px',
        borderRadius: '10px',
        cursor: 'pointer',
    },
    repliedMsgRt: {
        background: '#fff',
        padding: '2px 9px',
        borderRadius: '10px',
        cursor: 'pointer',
        borderLeft: "5px solid #198cbf",
    },

    chatTileContainer: {
        display: 'block !important',
        width: '100%',
        // maxWidth: 'max-content',
        '@media only screen and (min-width: 900px)': {
            '&:hover': {
                '& .chatTileSendMenu': {
                    // display: 'inline-flex',
                    visibility: 'visible'
                },
                '& .chatTileStarMenu': {
                    // visibility: 'visible',
                    // padding: '3px',
                    // marginTop: "-2px"
                },
            }
        },
        '& .chatTileSendMenu': {
            // display: 'none'
            visibility: 'hidden',
            padding: 3,
            width: '32px',
            height: '32px',
        },
        '& .chatTileStarMenu': {
            visibility: 'visible',
            // padding: '3px',
            marginTop: "-2px"
        },
        '& .noStarMenu': {
            visibility: 'hidden',
        },
        '& .time_ago': {
            fontSize: '9px',
            lineHeight: "0.9rem",
            marginRight: 0,
            marginBottom: -3,
        }
    },
    chatTileSend: {
        background: '#495efc',
        // backgroundImage: 'linear-gradient(rgb(46 52 216), rgb(25 169 252 / 72%))',
        // backgroundSize: '100% 100%',
        // backgroundAttachment: 'fixed',
        marginRight: 40,
        // border: '1px solid #ddd',
        borderRadius: '10px 0px 10px 10px',
        paddingRight: 6,
        '& .chatTileSendMenu': {
            float: 'right',
            fontSize: '8px',
            marginTop: 3
        },
        '& .reply-content': {
            color: theme.palette.text.contrastText,
        },
        '& .time_ago': {
            color: theme.palette.text.contrastText,
            marginRight: '4px !important'
        },
        '& svg.bi.bi-star-fill': {
            fill: '#fff'
        },
        '& .selectedEmojis, & .moreemojis': {
            background: '#DEE5F3'
        }
    },
    chatTileReceive: {
        background: '#F6F7FB',
        marginLeft: 40,
        // border: '1px solid #e5f1f0',
        borderRadius: '0px 10px 10px 10px',
        boxShadow: '0 1px 0.5px rgb(0 0 0 / 8%)',
        '& .chatTileReceiveMenu': {
            float: 'right',
            fontSize: '8px',
            marginTop: '3px'
        },
        '& .time_ago': {
            right: 8,
            opacity: 0.5
        },
        '& .selectedEmojis, & .moreemojis': {
            background: '#fff',
            boxShadow: '0px 1px 1px 0px #00000021'
        }
    },

    nameProfileImgRt: {
        display: 'flex',
        paddingTop: '10px',
        marginBottom: '-14px',
        justifyContent: 'end',
        flexDirection: 'row-reverse',
        alignItems: 'flex-start',
        '& .chatName': {
            marginTop: 0
        }
    },

    nameProfileImgLt: {
        display: 'flex',
        paddingTop: '10px',
        marginBottom: '-10px',
        justifyContent: 'start',
        flexDirection: 'unset',
        alignItems: 'flex-start'
    },

    scrollDownBtn: {
        display: 'inline-block',
        background: theme.palette.containers.bg29,
        color: theme.palette.primary.main,
        width: '27px',
        height: '27px',
        boxShadow: theme.palette.shadows.shadow2,
        padding: '4px',
        borderRadius: '30px',
        position: 'absolute',
        right: '15px',
        bottom: '10px',
        cursor: 'pointer',
        minWidth: 'auto !important',
        '&:hover': {
            background: `${theme.palette.containers.bg26} !important`,
        }
    },
    chatAttachList: {
        padding: '10px 20px 10px 0px',
        '& .MuiListItemIcon-root': {
            minWidth: 40
        },
        '& .MuiMenuItem-root': {
            paddingBottom: '6px',
            paddingTop: '6px',
            // padding: '0px',
            // marginTop: 10,
            // borderRadius: '100%'
        },
        '& .MuiPopover-paper': {
            marginTop: '-20px',
            boxShadow: '15px 20px 50px 0px #0F172A26',
        },

    },

    dragIcon: {
        position: 'absolute',
        left: '-18px',
        top: '45%',
        background: '#fff',
        borderRadius: '50%',
        boxShadow: theme.palette.shadows.shadow2,
        zIndex: 99,
        '& .arrow-left': {
            color: '#000',
            display: 'inline-block',
            lineHeight: 0,
            padding: '10px 2px',
            cursor: 'pointer'
        },
        '& .arrow-right': {
            color: '#000',
            display: 'inline-block',
            lineHeight: 0,
            padding: '10px 2px',
            cursor: 'pointer'
        }
    },
    closedDragIcon: {
        position: 'absolute',
        right: '0',
        top: '45%',
        background: '#fff',
        borderRadius: '50% 0 0 50%',
        boxShadow: theme.palette.shadows.shadow2,
        zIndex: 99,
        '& .arrow-left': {
            color: '#000',
            display: 'inline-block',
            lineHeight: 0,
            padding: '10px 5px',
            cursor: 'pointer'
        }
    },
    mobileChatIcon: {
        width: 40,
        height: 40,
        background: '#fff'
    },

    DragFilesPreviewBox: {
        width: "100%",
        height: "100%",
        // height: "calc(100% - 65px)",
        background: "transparent",
        // paddingLeft: '10px',
        // border: '2px dashed #ddd',
        position: "absolute",
        zIndex: 1200,
        top: '0px',
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '18px',
        color: '#0F172A !important',
        flexDirection: "column",
        "& .dnd-header": {
            width: "100%",
            height: "2rem",
            display: "flex",
        },
        "& .dnd-body": {
            width: "100%",
            flexGrow: "100",
            display: "flex",
        },
        '@media only screen and (max-width: 768px)': {
            '&.MuiGrid-root': {
                paddingLeft: '0px'
            }
        }
    },

    //Media
    mediaImg: {
        maxWidth: '100%',
        objectFit: 'cover',
        boxShadow: theme.palette.shadows.shadow1,
        cursor: "pointer",
        borderRadius: 6,
        border: `2px solid #E9E9E9`
    },
    mediaThumbBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #333",
        cursor: "pointer",
        height: "6rem",
    },
    optionsBack: {
        background: '#fff'
    },

    //Link
    linkListDiv: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        background: theme.palette.containers.bg1,
        boxShadow: theme.palette.shadows.shadow1,
        borderRadius: '4px',
        marginBottom: 10,
        wordBreak: 'break-word'
    },
    linkListImgDiv: {
        flexGrow: 1,
        display: 'flex',
        background: theme.palette.containers.bg9,
        borderRadius: '7px'
    },
    linkListImg: {
        width: '86px',
        height: '86px',
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        flexShrink: 0,
        background: theme.palette.containers.bg8,
        borderRadius: '7px',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'scale-down'
        }
    },
    linkListDesc: {
        padding: 12
    },
    linkListLink: {
        color: '#2196f3',
        fontSize: '12px',
        paddingTop: '4px'
    },

    //SearchResults
    searchBoxResults: {
        // maxWidth: 'calc(100% - 90px)'
        flexGrow: 1,
        width: 'auto'
    },
    searchResultTop: {
        height: 56,
        padding: '0px 10px',
        // background: theme.palette.text.contrastText,
        borderBottom: `1px solid ${theme.palette.primary.border1}`,
        '& svg': {
            fill: '#7e84a3'
        }
    },
    searchResultTopInput: {
        height: 45,
        borderBottom: `1px solid ${theme.palette.primary.border1}`,
        display: "flex",
        alignItems: "center",
        padding: '0px 5px',
        flexWrap: 'nowrap',
        '& svg': {
            width: '17px',
            height: '17px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '9px 5px'
        }
    },
    searchResultLists: {
        padding: 15,
        borderBottom: '1px solid #ddd',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F6F7FB',
        },
        "& .search-match": {
            color: "#111  !important",
            fontWeight: "bolder  !important",
            backgroundColor: "none !important"
        },
        "& p": {
            "& .search-match": {
                color: "#111  !important",
                fontWeight: "bolder  !important",
                backgroundColor: "none !important"
            },
        }
    },
    searchResultListsDiv: {
        maxHeight: 'calc(100vh - 172px)',
        overflowY: 'auto',
        "& .searchEmpty": {
            // color: "#111  !important",
            fontWeight: "bolder  !important",
            backgroundColor: "none !important",
            margin: '110px',
        },
    },

    SearchResultsContainerRoot: {
        width: 500,
        '@media only screen and (min-with:768px)': {
            '&.MuiGrid-root': {
                // paddingLeft: '20px !important'
            }
        },
        borderLeft: `1px solid ${theme.palette.primary.border5}`,
        '& .CloseIcon': {
            width: 13,
            height: 13
        }
    },
    SearchResultsContainer: {
        height: '100%',
        background: theme.palette.containers.card,
        borderRadius: '12px'
    },

    //Documents 
    docList: {
        '& .MuiListItem-gutters': {
            boxShadow: theme.palette.shadows.shadow1,
            background: theme.palette.containers.bg1,
            marginBottom: '8px',
            paddingLeft: '8px',
            borderRadius: 7
        },
        '& .DownloadIcon': {
            width: 14,
            height: 14,
            fill: '#64748B'
        }
    },
    DocfileTypeIcon: {
        maxWidth: '100%',
        minWidth: '0px',
        objectFit: 'cover',
        // marginRight: '10px'
    },

    //Star Messages
    starMsgContainerRoot: {
        width: 500,
        '@media only screen and (min-width: 900px)': {
            '&.MuiGrid-root': {
                paddingLeft: 10
            }
        }
    },
    starMsgContainer: {
        background: theme.palette.containers.card,
        padding: '10px',
        borderRadius: '12px',
        // width: 500,
        "& .MuiAvatar-root": {
            width: '38px !important',
            height: '38px !important',
            fontSize: 15
        },
        "& .starMsgHeader": {
            // background: '#F4F6FC'
        }
    },
    startMsgList: {
        flexDirection: 'column',
        display: 'flex',
        flex: 1,
        overflowY: 'auto',
        padding: 10,
        '& .chatTileStarDiv': {
            maxWidth: '100% !important'
        },
        '& .chatName': {
            fontSize: '13px !important',
            fontWeight: 500
        }
    },

    starMsgNamediv: {
        display: 'flex',
        // marginTop: '-20px',
        justifyContent: 'start',
        flexDirection: 'unset',
        alignItems: 'flex-end'
    },
    chatBoxFileIcon: {
        maxWidth: 18,
        marginRight: 8
    },
    typingText: {
        fontStyle: "italic",
        paddingLeft: "10px"
    },
    attachpopover: {
        '& .MuiPaper-root': {
            borderRadius: '10px',
            boxShadow: '15px 20px 50px 0px rgb(0 0 0 / 8%)',
            // boxShadow: 'none !important',
            // background: 'transparent',
            margin: '0px',
            padding: '0px'
        },
        '& .MuiPopover-paper': {
            marginTop: '-20px',
            boxShadow: '15px 20px 50px 0px #0F172A26',
        },

    },

})

export default Styles