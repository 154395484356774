import {ClientCloneIcon,TaskCloneIcon,FunnelCloneIcon,FlowCloneIcon,BoardsCloneIcon,BookingsCloneIcon,PagesClonIcon,BrainClonIcon} from "../../assets/svg/index"


const svgIcon = {
    clients: ClientCloneIcon,
    task: TaskCloneIcon,
    funnel: FunnelCloneIcon,
    flows: FlowCloneIcon,
    boards: BoardsCloneIcon,
    booking:BookingsCloneIcon,
    pages:PagesClonIcon,
    brain:BrainClonIcon
  };
  
  // Function to return the correct SVG component
  export const returnSvg = (icon) => {
    return svgIcon[icon] || null; // Safeguard if icon is not found
  };