export const workflowConstants = {

    GET_WORKFLOW_R: "GET_WORKFLOW_R",
    GET_WORKFLOW_S: "GET_WORKFLOW_S",
    GET_WORKFLOW_F: "GET_WORKFLOW_F",

    GET_WORKFLOWS_R: "GET_WORKFLOWS_R",
    GET_WORKFLOWS_S: "GET_WORKFLOWS_S",
    GET_WORKFLOWS_F: "GET_WORKFLOWS_F",

    CREATE_WORKFLOW_R: "CREATE_WORKFLOW_R",
    CREATE_WORKFLOW_S: "CREATE_WORKFLOW_S",
    CREATE_WORKFLOW_F: "CREATE_WORKFLOW_F",

    UPDATE_WORKFLOW_R: "UPDATE_WORKFLOW_R",
    UPDATE_WORKFLOW_S: "UPDATE_WORKFLOW_S",
    UPDATE_WORKFLOW_F: "UPDATE_WORKFLOW_F",

    DELETE_WORKFLOW_R: "DELETE_WORKFLOW_R",
    DELETE_WORKFLOW_S: "DELETE_WORKFLOW_S",
    DELETE_WORKFLOW_F: "DELETE_WORKFLOW_F",

    EXECUTE_WORKFLOW_R: "EXECUTE_WORKFLOW_R",
    EXECUTE_WORKFLOW_S: "EXECUTE_WORKFLOW_S",
    EXECUTE_WORKFLOW_F: "EXECUTE_WORKFLOW_F",

    CLEAR_WORKFLOW_R: "CLEAR_WORKFLOW_R",
    SAVE_WORKFLOW_DRAFT_S: "SAVE_WORKFLOW_DRAFT_S",
    CLEAR_WORKFLOW_DRAFT_S: "CLEAR_WORKFLOW_DRAFT_S",
    UPDATE_DRAFT_WORKFLOW_S: "UPDATE_DRAFT_WORKFLOW_S"
};
