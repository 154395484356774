const loadImage = (src, callback) => {
    const image = new Image()
    image.onload = function () {
        callback(this)
    }
    image.onerror = function () {
        callback(null)
    }
    image.src = src
}

export const loadImageAsync = (src) => {
    return new Promise((resolve, reject) => {
        loadImage(src, (image) => {
            if (image) {
                resolve(image)
            } else {
                reject(image)
            }
        })
    })
}

export default loadImage