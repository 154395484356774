// Import API Helper
import { postRequest } from "../../utils/apiHelper";

export const commonService = {
    getUrlMetadata
};

//login
async function getUrlMetadata(params) {
    const response = await postRequest("general/getMeta", params);
    return response;
}