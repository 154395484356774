import { useEffect, useState } from 'react';
import moment from "moment-timezone";

const useClock = (tZone) => {
  const [value, setValue] = useState(moment(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)).tz(tZone) ? moment(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)).tz(tZone)["_d"] : 0);

  useEffect(() => {
    const interval = setInterval(
      () => {
        setValue(moment(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)).tz(tZone) ? moment(new Date(new Date().getTime() + new Date().getTimezoneOffset() * 60000)).tz(tZone)["_d"] : 0)
      },
      1000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return getReturnValues(value);
};

const getReturnValues = (dateTime) => {
  const hours = moment(new Date(dateTime)).format("hh");//Math.floor((dateTime % (1000 * 60 * 60 * 14)) / (1000 * 60 * 60));
  const minutes = moment(new Date(dateTime)).format("mm");
  const seconds = moment(new Date(dateTime)).format("ss");
  const meridian = moment(new Date(dateTime)).format("a");

  return [dateTime, hours, minutes, seconds, meridian];
};

export { useClock };