export const historyAttrs = {
    'drawBlur': [
        'x',
        'y',
        'cropX',
        'cropY',
        'cropWidth',
        'cropHeight',
        'scaleX',
        'scaleY',
        'width',
        'height'
    ],
    'textNode': [
        'x',
        'y',
        'width',
        'height',
        'text',
        'fill',
        'fontSize'
    ],
    'crop': [
        'x',
        'y',
        'cropX',
        'cropY',
        'cropWidth',
        'cropHeight',
        'scaleX',
        'scaleY',
        'width',
        'height'
    ],
}