import React from "react";
import PropTypes from 'prop-types';
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";

import './styles.css';

class ContentEditableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            html: this.props.html,
            maxLength: this.props.maxLength
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.html !== prevProps.html) {
            this.setState({ html: this.props.html })
        }
    }

    handleChange = (evt) => {
        const { value } = evt.target;
        if (value.replace(/<[^>]+>/g, '').length <= this.state.maxLength) {
            const sanHtml = sanitizeHtml(value, this.sanitizeConf);
            this.setState({ html: sanHtml }, () => {
                this.props.handleContentChange(this.state.html);
            });
        }
    };

    sanitizeConf = {
        allowedTags: ["b", "i", "em", "strong", "a", "p", "div", "span", "u", "br", "h1", "h2", "h3", "h4", "h5", "h6", "hr", "li", "ul", "ol"],
        allowedAttributes: { a: ["href"] },
        selfClosing: ['br', 'hr'],
    };

    handleBlur = () => {
        // this.props.handleContentUpdate(this.state.html);
        const sanHtml = sanitizeHtml(this.state.html, this.sanitizeConf);
        this.setState({ html: sanHtml }, () => {
            this.props.handleContentUpdate(this.state.html);
        });
    };

    componentDidMount(){
        setTimeout(()=> { this.setState({
            html : this.props.html
         }) 
        }, 1500);
     }

    render = () => {
        const { disabled, placeholder } = this.props;
        return (
            <ContentEditable
                className={"boxContentEditable"}
                tagName="div"
                html={this.state.html} // innerHTML of the editable div
                disabled={disabled} // use true to disable edition
                onChange={this.handleChange} // handle innerHTML change
                onBlur={this.handleBlur}
                placeholder={placeholder ? placeholder : "Enter Here"}
                spellCheck={true}
                autocomplete="off"
            />
        )
    };
};

// default props
ContentEditableComponent.defaultProps = {
    classes: {},
    html: "",
    disabled: false,
    handleContentChange: () => { },
    handleContentUpdate: () => { },
    maxLength: 100000000000
};

// prop types
ContentEditableComponent.propTypes = {
    classes: PropTypes.object,
    html: PropTypes.string,
    disabled: PropTypes.bool,
    handleContentChange: PropTypes.func,
    handleContentUpdate: PropTypes.func,
    maxLength: PropTypes.number,
    width: PropTypes.string 
};

export const ReactContentEditableComponent = ContentEditableComponent;