import { clientMediaConstant } from "../constants";

const initialState = {
    loading: false,
    media: {
        folders: [],
        messages: []
    },
    folderView: false,
    fileType: null,
    groupView: false,

}

export default function clientMedia(state = initialState, action) {
    switch (action.type) {

        case clientMediaConstant.GET_ALL_MEDIA_R:
            return {
                ...state,
            }
        case clientMediaConstant.GET_ALL_MEDIA_S:
            return {
                ...state,
                media: action.data.data,
                folderView: false
            }
        case clientMediaConstant.GET_ALL_MEDIA_F:
            return {
                ...state,
                media: initialState.media
            }

        case clientMediaConstant.UPDATE_FOLDER_VIEW:
            return {
                ...state,
                folderView: action.data
            }

        case clientMediaConstant.UPDATE_GROUP_VIEW:
            return {
                ...state,
                groupView: action.data
            }

        case clientMediaConstant.UPDATE_FILE_TYPE:
            return {
                ...state,
                fileType: action.data
            }
        case clientMediaConstant.CREATE_FOLDER_R:
        case clientMediaConstant.CREATE_FOLDER_S:
        case clientMediaConstant.CREATE_FOLDER_F:
            return state

        case clientMediaConstant.UPDATE_FOLDER_NAME_R:
        case clientMediaConstant.UPDATE_FOLDER_NAME_S:
        case clientMediaConstant.UPDATE_FOLDER_NAME_F:
            return state

        case clientMediaConstant.DELETE_FOLDER_R:
        case clientMediaConstant.DELETE_FOLDER_S:
        case clientMediaConstant.DELETE_FOLDER_F:
            return state

        case clientMediaConstant.DELETE_FILE_R:
        case clientMediaConstant.DELETE_FILE_S:
        case clientMediaConstant.DELETE_FILE_F:
            return state

        case clientMediaConstant.UPDATE_FILE_R:
        case clientMediaConstant.UPDATE_FILE_S:
        case clientMediaConstant.UPDATE_FILE_F:
            return state

        case clientMediaConstant.UPLOAD_FILE_R:
        case clientMediaConstant.UPLOAD_FILE_S:
        case clientMediaConstant.UPLOAD_FILE_F:
            return state

        case clientMediaConstant.UPDATE_FOLDER_FILE_R:
        case clientMediaConstant.UPDATE_FOLDER_FILE_S:
        case clientMediaConstant.UPDATE_FOLDER_FILE_F:
            return state

        default:
            return state
    }
}
