import React, { useState, useEffect, useRef, forwardRef } from 'react';
import PropTypes from "prop-types";
import { ChatEditor as Editor } from "@flozy/editor";
import createLinkifyPlugin, { extractLinks } from '@draft-js-plugins/linkify';
import createHashtagPlugin, { extractHashtagsWithIndices } from '@draft-js-plugins/hashtag';
import { Grid, IconButton, withStyles, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { isMobile } from 'react-device-detect';
import { useLocation } from "react-router-dom";
import { EmojiPickerComponent } from "../../FlozyEmojiPicker";

import '../../../../node_modules/@draft-js-plugins/mention/lib/plugin.css'
import useCapacitorKeyboard from "../../../hooks/keyboard_listener";


// Imports Styles
import styles from "./styles";
// import "./tenor.css";

//Icons
import { InputPlusIcon, EditTick } from '../../../assets/svg'

const hashtagPlugin = createHashtagPlugin({
  theme: {
    hashtag: 'chatHashTag'
  }
});

/**
 * TextField Component
 * @param {*} props
 */
const TextEditor = forwardRef((props, ref) => {
  const { id, type, classes, content = '', handleChatSend, handleAttachment, userSuggestions, handleTyping, placeHolder, infinityModeHandler, typingUsers, userId, unsendMessages, updateDraftMessages, handleChatEditMessage, actionIcons, editChatData, backgroundColor, otherProps } = props;
  const theme = useTheme()

  const [tinyUrl, setTinyUrl] = useState(null);
  const [tinySelectedUrl, setTinySelectedUrl] = useState(null)
  const [tinyUrlMeta, setTinyUrlMeta] = useState([]);
  const [tinyFetchedUrls, setTinyFetchedUrls] = useState([]);
  const [chatHashTags, setChatHashTags] = useState([]);

  const [chatInputText, setChatInputText] = useState("");
  const [chatRawContent, setChatRawContent] = useState(content);
  const [contentStatus, setContentStatus] = useState(true)
  const editorRef = useRef(null);

  const isKeyboardVisible = useCapacitorKeyboard();

  const linkifyPlugin = createLinkifyPlugin({
    target: '_blank',
    component: (props) => {
      return <a {...props} onClick={() => window.open(props.href, '_blank')} />
    },
  });

  useEffect(() => {
    if (!isMobile) {
      // editorRef?.current?.clearAll(false);
    }
  }, [id])

  useEffect(() => {
    const handlePaste = async (event) => {
      const items = event.clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file' && item.type.includes('image')) {
          const blob = item.getAsFile();
          const url = URL.createObjectURL(blob);
          handleAttachment({ isPaste: true, blob: blob, url: url });
        }
      }
    };
    window.addEventListener('paste', handlePaste);
    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);



  useEffect(() => {
    if (chatInputText && chatInputText.length > 0) {
      let urls = extractLinks(chatInputText)
      let hashTags = extractHashtagsWithIndices(chatInputText);
      if (urls && tinyUrl !== urls) {
        setTinyUrl([...urls])
      }
      else {
        setTinyUrl(null)
      }

      if (hashTags !== chatHashTags) {
        setChatHashTags(hashTags)
      }
    }
  }, [chatInputText])

  useEffect(() => {
    if (tinyUrl && tinyUrl.length > 0) {
      let url = tinyUrl.filter((val) => val.schema === "https:");
      if (url.length > 0) {

        let urlSelect = url[0]["url"];
        if (urlSelect !== tinySelectedUrl) {
          setTinySelectedUrl(urlSelect)
        }
      }
    } else {
      setTinySelectedUrl(null);
      setTinyUrlMeta([]);
    }
  }, [tinyUrl])


  const onChange = (rawTxt, text_message) => {
    let newContentTextLength = text_message?.text?.length;
    if (!typingUsers?.[`${type}_${id}_${userId}`] && newContentTextLength >= 1) {
      handleTyping(true)
    }
    setChatRawContent(JSON.parse(rawTxt))
    setChatInputText(text_message?.text || "")
  };

  // Edit message
  const handleEditChat = (event, chatContent = null) => {
    const urlMetaData = tinyUrlMeta && tinyUrlMeta.length > 0 ? tinyUrlMeta.filter(val => val && val.hasOwnProperty("url")) : []
  
    const mentions = JSON.parse(chatContent?.strVal);
    const hasMention = mentions.some(paragraph => 
      paragraph.children.some(child => child.type === "mention")
    );

    const hasContent = chatContent && ((chatContent.restVal?.text?.trim() !== "") || hasMention);

    if (hasContent) {
      let chatText = chatInputText
      let chat_rawContent = chatRawContent
      if (chatContent) {
        const { strVal, restVal } = chatContent
        chat_rawContent = JSON.parse(strVal)
        chatText = restVal?.text || ""
      }
      let dataObj = {
        message_id: editChatData?.id,
        chatText: chatText,
        chatRawContent: chat_rawContent,
        hashtags: [],
        mentions: [],
        links: {
          url: tinyUrl,
          urlData: urlMetaData
          // urlData: tinyUrlMeta
        }
      }

      setTinySelectedUrl(null);
      setTinyUrlMeta([]);
      setTinyFetchedUrls([]);
      dataObj["isEdited"] = false
      if (editChatData.message_text != chatText) {
        dataObj["isEdited"] = true
      }
      handleChatEditMessage(dataObj)
      handleTyping(false)

    }
    else
      return false;
  }

  const onEmojiSelect = (emoji) => {
    editorRef?.current?.emojiClick(emoji)
  };

  return (
    <>
      <Grid className={`chatInputBox ${classes.chatInputBox}`} ref={ref}>
        <Grid container alignItems="center" className={`${classes.chatEditorRoot}`} style={{ background: backgroundColor }}>
          {
            actionIcons.includes('plus') &&
            <IconButton onClick={handleAttachment} className="mShow"><InputPlusIcon /></IconButton>
          }
          {
              contentStatus ?
                <Editor
                  ref={editorRef}
                  theme={theme}
                  id={id}
                  className={'chatEditorRoot'}
                  toolBar={false}
                  otherProps={otherProps}
                  content_status={contentStatus}
                  content={chatRawContent}
                  onSave={onChange}
                  onsubmit={handleEditChat}
                />
                : null
          }
        </Grid>
      </Grid>
      <Grid className={`${classes.chatTypAttach} chatTypAttach`}>
        {
          !isMobile && actionIcons.includes('emoji') &&
          // <EmojiSelect className="emojiIcon floatOption ml-1" />
          <EmojiPickerComponent
          onEmojiClck={onEmojiSelect}
          fromInput = {true}
        />
        }
        {
          actionIcons.includes('edit') &&
          <>
            <IconButton onClick={() => editorRef?.current?.submitChat()} className='pl-1'>
              <EditTick />
            </IconButton>
          </>
        }
      </Grid>
    </>
  );
})

// default props
TextEditor.defaultProps = {
  variant: "outlined",
  editChatData: {},
  // notesText: ``,
  // handleChatText: () => { },
  handleChatSend: () => { },
  handleAttachment: () => { },
  handleChatGif: () => { },
  handleTyping: () => { },
  infinityModeHandler: () => { },
  activateInfinity: false,
  placeHolder: "Start Typing...",
  unsendMessages: {},
  actionIcons: ['plus', 'audio', 'emoji', 'gif', 'send', 'infinity'],
  backgroundColor: 'F0F0F0'
};

// prop types
TextEditor.propTypes = {
  variant: PropTypes.string,
  editChatData: PropTypes.object,
  // notesText: PropTypes.string,
  // handleChatText: PropTypes.func,
  handleChatSend: PropTypes.func,
  handleAttachment: PropTypes.func,
  infinityModeHandler: PropTypes.func,
  handleChatGif: PropTypes.func,
  handleTyping: PropTypes.func,
  placeHolder: PropTypes.string,
  activateInfinity: PropTypes.bool,
  unsendMessages: PropTypes.object,
  actionIcons: PropTypes.array
};

// export component
export const EditChatInput = withStyles(styles)(TextEditor);
