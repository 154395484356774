
const Style = (theme) => ({
    CSContainerWrapper: {
        height: "100%"
    },
    CSTableContainer: {
        height: "calc(100%)",
        padding: "0px 20px 20px 20px",
        overflowY: 'auto'
    },
    blockbutton: {
        background: '#2563EB30',
        fontSize: '14px',
        borderRadius: '5px',
        color: theme.palette.text.text9
    },
    filtercount:{
        // background:' #F178B6 !important',
        '& .MuiBadge-colorPrimary':{
            background: '#F178B6 !important'

        }
    },
    CSTableWrapper: {
        flexGrow: "1",
        background: theme.palette.containers.card,
        height: "100%",
        "@media only screen and (min-width: 600px) and (max-width: 899px)": {
            height: "100%"
        },
        "@media only screen and (max-width: 599px)": {
            height: "100%"
        }
    },
    CTTableHeaderWrapper: {
        height: "16px",
    },
    CSShowRow: {
        display: "flex",
        alignItems: "center",
    },
    CSCell: {
        borderBottom: "0px",
        cursor: "default",
        padding: "16px 20px",
        color: theme.palette.primary.main,
        "& .MuiSkeleton-root": {
            // background: theme.palette.greyshades.light5
        },
        whiteSpace: 'nowrap'
    },
    CSCellDisabled: {
        borderBottom: "0px",
        cursor: "default",
        padding: "16px 20px",
        color: 'var(--Greyscale-400, #94A3B8)'
    },
    CSColumnHeader: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "22px",
        letterSpacing: "0px",
        textAlign: "left",
        color: "#64748B",
        zIndex: 4,
        whiteSpace: 'nowrap'
    },
    CSRow: {
        cursor: "default",
        padding: "0px 20px",
    },
    CSRowHover: {
        '&:hover': {
            background: theme.palette.containers.bg33,
            '& .showOnRowHover': {
                visibility: 'visible'
            }
        },
    },
    CSPaginationWrapper: {
        background: theme.palette.containers.card,
        padding: "16px 20px",
        "& .MuiPaginationItem-page": {
            color: theme.palette.primary.main,
        },
    },
    CSTableOptions: {
        display: "flex",
        justifyContent: "spaceBetween",
        alignItems: "center",
    },
    CSTableOption: {
        marginRight: "10px",
    },
    CSTableBackBtn: {
        '& .MuiButton-root': {
            height: '40px',
            padding: '8px, 12px, 8px, 12px',
            borderRadius: '8px',
            background: theme.palette.containers.bg7,
        },
        '& .MuiButton-label': {
            fontSize: '14px',
            fontWeight: 600,
            color: theme.palette.text.seocondary,
        }
    },
    CSTableSearchClickIcon: {
        cursor: 'pointer',
        '&.hide': {
            display: 'none !important'
        }
    },
    CSTableSearchWrapper: {
        display: "flex",
        alignItems: "center",
        "&.show-mobile": {
            transition: "height .3s ease",
            WebkitTransition: "height .3s ease",
            marginBottom: "4px"
        },
        "@media only screen and (max-width: 599px)": {
            "&.hidden-icon": {
                display: "none"
            },
        }
    },
    CSTableSearch: {
        width: "204px",
        height: "30px",
        borderRadius: "8px",
        border: `1px solid ${theme.palette.primary.border1}`,
        background: theme.palette.containers.card,
        display: "flex",
        alignItems: "center",
        padding: "10px",
        justifyContent: "space-between",
        "& input": {
            background: theme.palette.containers.card,
            width: '100%'
        },
        "@media only screen and (max-width: 900px)": {
            width: '100%',
            justifyContent: "flex-start"
        }
    },
    CSTableSearcIcon: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
    },
    CSTableSearchInput: {
        color: theme.palette.primary.main,
        border: "none",
        outline: "none",
        marginLeft: "10px",
        transition: "color 0.3s, background-color 0.3s",
        "&:hover": {
            border: "none",
            outline: "none",
        },
    },
    CSTableSearchCloseIcon: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
    },
    CTHeaderWrapper: {
        padding: "20px",
        "& .MuiGrid-spacing-xs-2 > .MuiGrid-item": {
            padding: '6px',
        },
        '@media only screen and (max-width: 899px)': {
            boxShadow: '0px 4px 4px 0px #00000017',
            zIndex: 9
        }
    },
    CSMobileCard: {
        display: "none"
    },
    H_100: {
        height: "100%",
        background: theme.palette.containers.card
    },
    PaginationCenter: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    sortPopup: {
        width: "100%",
        position: "absolute",
        bottom: 0,
        left: 0,
        borderRadius: "10px 10px 0 0",
    },
    cardContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 4px",
        alignItems: "start",
        borderBottom: `1px solid ${theme.palette.primary.border1}`,
        zIndex: 9999,
    },
    cardWrapper: {
        padding: "0 10px",
    },

    editAndDelete: {
        display: "flex",
        width: "21px",
        height: "21px",
        borderRadius: "50%",
        background: "#DFE9FF",
        alignItems: "center",
        justifyContent: "center",
        color: "#2563EB",
        fontSize: "17px",
        padding: '5px'
    },
    resEditDelIcon: {
        display: "flex",
        gap: "7px",
        justifyContent: "flex-end",
        textAlign: "right",
    },
    headerOptions: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    },
    headerWrapper: {
        display: "flex",
    },
    invoiceNum: {
        color: theme.palette.text.text1,
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: "600",
        letterSpacing: "0.2px",
        margin: "8px 4px 8px 0",
        maxWidth: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    customerName: {
        color: theme.palette.text.text1,
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: "400",
        letterSpacing: "0.2px",
        maxWidth: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin: "5px 0",
    },
    customerMail: {
        color: "#94A3B8",
        fontFamily: "Inter",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: "400",
        letterSpacing: "0.2px",
        margin: "5px 0",
        maxWidth: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },

    invoiceAmount: {
        color: theme.palette.text.text1,
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: "600",
        letterSpacing: "0.2px",
        margin: "5px 0",
        justifyContent: "flex-end",
        textAlign: "right",
        display: "flex",
    },
    statusInvoice: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "600",
        margin: "0 4px 10px 0",
    },
    totalInvoice: {
        display: "flex",
        justifyContent: "flex-end",
        textAlign: "right",
        color: "#94A3B8",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        margin: "0 0 5px 0",
    },
    InvoiceNumberPrefix: {
        display: "flex",
        marginRight: "8px",
    },
    invoiceNumberContainer: {
        display: "flex",
        alignItems: "center",
    },
    footerContainer: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-evenly",
        textAlign: "center",
        margin: "10px",
        flexWrap: "wrap",
        zIndex: 9999,
    },
    footerWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "6px",
        padding: "4px 10px",
    },
    footerContent: {
        color: "#94A3B8",
        fontWeight: "600",
        paddingLeft: "10px",
        fontSize: "12px",
    },

    scheduleInfo: {
        color: "#94A3B8",
        fontSize: "12px",
        fontWeight: "400",
        "& div": {
            fontSize: "12px",
            letterSpacing: "0.2px",
        },
    },
    subEditAndDel: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        textAlign: "right",
        color: "#2563EB",
        fontSize: "13px",
        gap: "6px",
    },
    subIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "21px",
        height: "21px",
        borderRadius: "50%",
        background: "#DFE9FF",
    },
    subName: {
        color: theme.palette.text.text1,
        fontFamily: "Inter",
        fontSize: "12px",
        fontWeight: "600",
        letterSpacing: "0.2px",
        maxWidth: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    button_responsive: {
        width: "10px",
        height: "10px",
    },
    popupWrapper: {
        display: "flex",
        width: "100%",
    },
    popupContainer1: {
        display: "flex",
        padding: "15px",
        justifyContent: "space-between",
        width: "100%",
    },
    popupContainer: {
        display: "flex",
        padding: "15px",
        justifyContent: "space-between",
        width: "100%",
    },
    popupCard: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    detailSummary: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
    },
    popupDetail: {
        display: "flex",
        color: "#64748B",
        fontSize: "12px",
        fontWeight: 400
    },
    popupValue: {
        display: "flex",
        color: "#10182B",
        fontSize: "12px !important",
        fontWeight: "600",
        textAlign: "right",

        "& div": {
            color: "#10182B",
            fontSize: "12px !important",
            fontWeight: "600",
        },
    },

    // Sort Styles
    sortBox: {
        width: "382px",
        height: "430px",
        flexShrink: 0,
        borderRadius: "10px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "var(--additional-white, #FFF)",
        boxShadow: "0px 4px 55px 55px rgba(0, 0, 0, 0.06) ",
    },
    sortHeader: {
        display: "flex",
        height: "50px",
        justifyContent: "space-between",
        color: "var(--greyscale-900, #0F172A)",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "140%" /* 19.6px */,
        letterSpacing: "0.2px",
        textTransform: "uppercase",
        marginTop: "20px",
        marginLeft: "18px",
    },
    sortCloseIcon: {
        width: "9.899px",
        height: " 9.899px",
        flexShrink: 0,
        marginRight: "30px",
        cursor: "pointer",
    },
    sortIcon: {
        display: 'flex',
        cursor: "pointer",
        '& svg': {
            width: '18px',
            height: '18px'
        }
    },
    sortOptionSelected: {
        background: "#2563EB",
        padding: "12px 20px",
        color: "#FFFFFF",
        cursor: "pointer",
        borderRadius: '4px',
        '& .MuiTypography-colorPrimary': {
            color: "#FFFFFF",
        }
    },
    sortOption: {
        background: theme.palette.containers.card,
        padding: "10px 20px",
        cursor: "pointer",
        '&:hover': {
            background: theme.palette.containers.bg3
        }
    },
    sortChevron: {
        paddingLeft: "8px",
    },
    rotateChevron: {
        paddingRight: "8px",
        transform: "rotate(180deg)",
    },
    CTMoreIcon: {
        boxShadow: "unset",
        minWidth: "40px",
        "& svg": {
            width: "16px",
            height: "24px"
        },
        "& .fillPath": {
            fill: theme.palette.text.secondary,
        },
        "&:hover": {
            boxShadow: "unset",
        },
        '&.MuiButton-containedSecondary': {
            backgroundColor: `${theme.palette.containers.bg2} !important`
        },
        '&.MuiButton-containedSizeSmall': {
            padding: '7px 12px'
        }
    },
    moreOptionsContainer: {
        padding: "16px",
    },
    moreOptionButton: {
        margin: "4px 0px",
        color: theme.palette.primary.main,
        textAlign: "left",
        justifyContent: "start",

        "@media only screen and (max-width: 599px)": {
            "&.MuiMenuItem-root": {
                minHeight: "20px"
            }
        }
    },
    CSSelect: {
        "& .MuiInputBase-input": {
            paddingBottom: "0px",
            paddingTop: "0px",
            paddingLeft: "8px",
            color: theme.palette.primary.main,
        },
    },
    CSSortHeader: {
        padding: "8px 16px 0px 16px",
        display: "flex",
        justifyContent: "space-between",
    },
    SortCloseContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.containers.bg7,
        borderRadius: "6px",
        height: "24px",
        width: "24px",
        cursor: "pointer",
        "& .CloseIcon": {
            height: "10px",
            width: "10px"
        }
    },
    CreateWFPopover: {
        "& .MuiPaper-root": {
            boxShadow: theme.palette.primary.border1,
        },
        "& .MuiPaper-rounded": {
            borderRadius: "12px",
        },
    },
    SortTitle: {
        fontSize: "14px",
        fontWeight: "700",
        lineHeight: "20px",
        display: "flex",
        alignItems: "center",
        color: theme.palette.primary.main,
    },
    SortHeader: {
        justifyContent: "space-between",
        padding: "8px 20px",
    },
    Divider: {
        backgroundColor: theme.palette.primary.border5
    },
    DividerContainer: {
        justifyContent: "center",
    },
    "@media only screen and (max-width: 998px)": {
        CSRowHover: {
            "& .showOnRowHover": {
                visibility: "visible",
            },
        },
    },

    popupColon: {
        display: "flex",
        color: "#64748B",
        fontSize: "12px",
        fontWeight: 400,
        margin: " 0 20px",
    },
    popupColon1: {
        display: "flex",
        color: "#64748B",
        fontSize: "12px",
        fontWeight: 400,
        margin: " 0 15px",
    },
    paginationArrow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
        marginTop: "20px"
    },
    pagination: {
        border: `1px solid ${theme.palette.primary.border3}`,
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "12px 15px",
    },
    sortBarLine: {
        height: "7px",
        borderRadius: "6px",
        background: "#D1D4DB",
        width: "60px",
        margin: "12px",
    },
    skeletonContainer: {
        width: "100%",
    },
    sortCardContainer: {
        position: "relative",
        height: "100%",
        width: "100%",
    },
    sortBackdrop: {
        position: "fixed",
        background: "rgb(0 0 0 / 39%)",
        height: "100%",
        top: "0",
        width: "100%",
        left: "0",
        zIndex: "1000",
        animation: `$slideEffect 0.5s ease-in-out`,
    },
    sortCard: {
        zIndex: "1001",
        position: "fixed",
        bottom: "0px",
        padding: "10px 0",
        background: theme.palette.containers.card,
        left: "0px",
        borderRadius: "18px 18px 0px 0px",
        width: "100%",
        height: "max-content",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        textAlign: "left",
        animation: `$slide 0.5s ease-in-out`,
    },
    sortTabCard: {
        zIndex: "10",
        position: "fixed",
        top: "55px",
        width: "30%",
        padding: "10px 0",
        background: theme.palette.containers.card,
        right: "0px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        animation: `$tabSlide 0.5s ease-in-out`,
        boxShadow: "0px 4px 55px 55px #0000000F",

    },
    "@keyframes tabSlide": {
        "0%": {
            opacity: "1",
            right: "-100%",
        },
        "100%": {
            opacity: 1,
            right: "0",
        },
    },

    "@keyframes slide": {
        "0%": {
            opacity: "1",
            bottom: "-100%",
        },
        "100%": {
            opacity: 1,
            bottom: "0",
        },
    },
    "@keyframes slideEffect": {
        "0%": {
            opacity: "0",
            bottom: "-100px",
        },
        "100%": {
            opacity: 1,
            bottom: "0",
        },
    },

    tabs: {
        width: "100%",
        alignItems: "center",
        marginBottom: "0px",
        "& .MuiTabs-flexContainer": {
            justifyContent: "space-around",
            background: theme.palette.containers.bg1,
            borderRadius: "12px",
            alignItems: "center",
            height: '40px',
            padding: '8px 5px',
            "@media only screen and (max-width: 768px)": {
                padding: "1px",
                background: 'transparent',
                '& .MuiTab-wrapper': {
                    fontSize: '12px',
                }
            },
        },
        "& .MuiTabs-scroller": {
            borderBottom: "none",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "transparent",
        },
        "& .labelText": {
            lineHeight: "24px",
            color: "#64748B",
            fontSize: '12px'
        },
        "& .Mui-selected": {
            '& .MuiTab-wrapper': {
                fontSize: '12px',
                background: '#2563eb1a',
                boxShadow:
                    "0px 0px 1px rgba(75, 85, 99, 0.04), 0px 0px 12px rgba(107, 114, 128, 0.04)",
                borderRadius: "8px",
                color: "#2563EB",
                padding: '8px',
                fontWeight: 700,
                "@media only screen and (max-width: 768px)": {
                    background: 'transparent',
                    boxShadow: '0px 0px 0px'
                },
            },
        },
        '& .MuiTab-wrapper': {
            fontSize: '12px',
            color: '#64748B',
            paddingLeft: '8px',
            paddingRight: '8px'
        },
        '& .MuiButtonBase-root': {
            padding: "1px",
        },
        '& .MuiTab-root': {
            minWidth: '100px',
            "@media only screen and (max-width: 768px)": {
                minWidth: '50px',
            },
        }
    },
    invoiceSummaryHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    TableFilterAutoComplete: {
        "& .MuiOutlinedInput-root": {
            height: "34px",
            padding: "0 39px 2px 6px !important",
        },
        "& .MuiAutocomplete-input": {
            padding: 0,
            fontSize: "12px",
        },
        "& .MuiChip-sizeSmall": {
            height: "20px",
            "@media only screen and (max-width: 899px)": {
                height: "40px !important"
            }
        },
        "& .MuiAutocomplete-root": {
            boxShadow: " 0px 4px 16px 0px #0000000D",
        },
        '& .optionLabel': {
            color: theme.palette.text.text3
        }
    },
    hide: {
        display: "none",
    },
    MobileSort: {
        display: "none",
    },
    //Invoice summary sybscription and client responsive styles
    InvoiceAddbtnMobile: {
        display: "none"
    },
    InvoiceAddbtnDesktop: {
        display: "flex"
    },
    InvoiceCircularProgress: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    CSTableSearchContainer:{
     flexWrap:"noWrap"
    },
    "@media only screen and (max-width: 1024px)": {
        InvoiceAddbtnMobile: {
            display: "block", padding: "8px",
            background: "#406FFC",
            borderRadius: "8px"
        },
        InvoiceAddbtnDesktop: {
            display: "none"
        },
        CSDesktop: {
            display: "none"
        },
        PaginationCenter: {
            justifyContent: "center",
        },
        showRowCount: {
            display: "none"
        },
        responsiveBtn: {
            background: "#406FFC",
            width: "27px",
            height: "27px",
            display: "flex",
            // padding: "8px 24px",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            marginLeft: "5px",
        },
        SortIcon: {
            display: "none",
        },
        responsiveHeader: {
            display: "flex",
            textAlign: "left",
            paddingTop: "10px",
            paddingBottom: "20px",
        },
        responsiveContainer: {
            display: "flex",
            // flexDirection: "column",
            width: "100%",
            background: "#F4F6F9 !important",
            padding: "8px 20px 0px 20px",
        },
        CSTableOptions: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        CSTableSearchInput: {
            color: theme.palette.primary.main,
            fontFamily: "Inter",
            fontSize: "12px",
            fontWeight: 400,
        },
        CSTableSearcIcon: {
            width: "13px",
            height: "13px",
            color: "#778599",
        },
        CSMobileCard: {
            display: "block",
            overflow: "hidden"
        },
        MobileSort: {
            display: "block",
        },
        footerContainer: {
            display: "none",
        },
        amntcard: {
            justifyContent: "flex-end",
            textAlign: "right",
        },
        invoiceMoreIcon: {
            display: "flex",
            justifyContent: "flex-end"
        },
        tableFooter: {
            marginTop: "10px"
        },
        menuItems: {
            '& .MuiMenuItem-root': {
                minHeight: "auto",
                color: "#64748B",
                fontWeight: 500
            }
        },
        filterBtnTab: {
            position: "absolute",
            bottom: "10%",
            right: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }
    },
    CSTableAddBtn: {
        '& .MuiIconButton-root:hover': {
            boxShadow: '0px 8px 24px 0px #1E40AF26'
        },
        '& .MuiButtonBase-root': {
            padding: 0
        }
    },
    CSTableBackBtn: {
        background: theme.palette.containers.bg7,
        border: 'none',
        padding: '8px, 12px, 8px, 12px',
        height: '40px',
        '& .MuiButton-label': {
            fontSize: '14px',
            fontWeight: 600,
            color: theme.palette.text.text3,
        },
    },
    // CSTableBackBtnMob: {
    //     borderRadius: '8px',
    //     background: theme.palette.containers.bg7,
    // },
    CSPagination: {
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: theme.palette.containers.card,
            color: '#2563EB !important'
        },
        '& .MuiPaginationItem-outlined': {
            border: `1px solid ${theme.palette.primary.border1} !important`,
            fontWeight: 700,
            color: '#94A3B8'
        }
    },
    filterCard: {
        '& .MuiCheckbox-colorPrimary.Mui-checked': {
            color: '#2563EB'
        },
    },
    // filterBtnMob: {
    //     position: "fixed",
    //     paddingBottom:"max(env(safe-area-inset-bottom), 10px)",
    //     bottom: "0",
    //     display: "flex",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     margin: "10px 0",
    //     width: '100%',
    //     flexWrap: 'nowrap'
    // },
    filterContainer: {
        maxHeight: '350px',
        // marginBottom: "20px",
        padding: "20px",
        overflowY: "auto",
        paddingTop: '0px',
        paddingBottom: '0px !important'
    },
    filterTab: {
        maxHeight: "300px",
        overflowY: "scroll",
    },
    filterCardPopup: {
        maxWidth: 420,
        maxHeight: 500

    },
    '@media only screen and (max-width:540px)': {
        headerContainers: {
            // justifyContent: "space-between",
            flexDirection: 'column',
            alignItems: 'start'
        },
        headerContainer: {
            width: "100%"
        },
        tabsContainer: {
            marginRight: "20px"
        },
        CSTableOptions: {
            width: "100%",
            // marginTop: "10px"
        },
        CSMobileCard: {
            overflow: "hidden"
        },
    },
    filterCheckmenu: {
        fontSize: "12px",
        // color: "#0F172A",
        fontWeight: 400
    },
    filterSlider: {
        width: "250px",
        color: '#2563EB',
        '@media only screen and (max-width:899px)': {
            width: '200px'
        }
    },
    currencyList: {
        margin: "10px 0"
    },
    filterbutton: {
        '@media only screen and (min-width:899px)': {
            paddingTop: '10px'
        },
    },
    '@media only screen and (max-width:599px)': {
        filterBtnMob: {
            // position:"absolute",
            bottom: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: '0 14px'
            // margin:"10px 0"
        },
    },
    CSChangeToggle: {
        marginLeft: 20,
        background: theme.palette.containers.toggleBtn5.bg,
        height: '42px',
        padding: '5px',
        borderRadius: '12px',
        overflowX: 'scroll',
        overflowY: 'hidden',
        maxWidth: "650px",
        '& .MuiToggleButton-root': {
            border: 'none',
            textTransform: 'unset !important',
            fontWeight: '400 !important',
            padding: '0px 15px',
            background: theme.palette.containers.toggleBtn5.bg,
            height: '32px',
            borderRadius: '8px',
            '& .MuiTypography-root': {
                color: theme.palette.containers.toggleBtn5.color,
                fontSize: '12px',
                textAlign: 'center'
            },
            '& .ToggleBorderBtm': {
                display: 'none'
            },
            "@media only screen and (max-width:540px)": {
                padding: '0px 8px',
                width: '100%'
            }
        },
        '& .Mui-selected': {
            background: theme.palette.containers.toggleBtn5.activeBg,
            '& .MuiTypography-root': {
                color: `${theme.palette.containers.toggleBtn5.activeColor} !important`,
                fontSize: '12px',
                fontWeight: '700'
            },
            '& .ToggleBorderBtm': {
                // display: 'block !important'
            },
            '& .MuiToggleButton-label': {
                flexDirection: 'column'
            }
        },
        "@media only screen and (max-width:540px)": {
            marginLeft: 0,
            width: '100%'
        }
    },

    datePicker: {
        paddingLeft: 200,
        marginTop: -30,
        width: 550,
        height: 30
    },
    dateDisplay: {
        position: 'relative',
        // marginLeft: 15
    },
    heading: {
        color: theme.palette.primary.main,
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '160%', /* 25.6px */
        textTransform: 'capitalize'
    },
    blockUserListPopup: {
        // padding: "10px 10px",
        borderRadius: "12px",
        background: theme.palette.containers.card,
        maxHeight: "535px",
        minHeight: "200px",
        maxWidth: '509px',
        '& .header': {
            alignItems: "center",
            padding: 20
        },
        '& .closeBtn': {
            borderRadius: '4px',
            width: '26px',
            height: '26px',
            background: theme.palette.containers.bg7,
            padding: '7px'
        },
        '& .seperationLine': {
            background: theme.palette.primary.border5,
            height: "1px",
            width: "100%"
        },
        '& .content': {
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            '& .row': {
                padding: '10px 5px',
                alignItems: 'center',
                '& .names': {
                    padding: "2px 10px",
                    '& .username': {
                        color: theme.palette.primary.main,
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '160%',
                        letterSpacing: '0.2px'
                    },
                    '& .email': {
                        color: '#94A3B8',
                        fontFamily: 'Inter',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '160%', /* 22.4px */
                        letterSpacing: '0.2px'
                    }
                },
                '& .button': {
                    padding: '3px 8px 3px 8px',
                    alignItems: 'center',
                    borderRadius: '4px',
                    background: theme.palette.containers.bg3,
                    color: '#2563EB',
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "18px"
                }
            }
        }
    },
    blockListButton: {
        padding: '10px 20px',
        background: theme.palette.containers.body,
        border: `1px solid ${theme.palette.primary.border1}`,
        color: theme.palette.secondary.main,
        whiteSpace: 'nowrap'
    },
    option: {
        color: theme.palette.primary.main,
    },
    CSTableContainerWithTab: {
        height: "calc(100% - 74px)",
        padding: "0px 20px 20px 20px",
        "@media only screen and (max-width: 425px)": {
            height: "calc(100% - 108px)"
        },
        "@media only screen and (max-width: 900px)": {
            height: "calc(100% - 150px)"
        },
    },
    filterSliderValue: {
        display: 'none',
        width: "250px",
        '@media only screen and (max-width:899px)': {
            display: 'flex',
            justifyContent: 'space-between',
            width: '200px'
        }
    },
    NodataDescription: {
        '&.blockuserslist': {
            height: '60vh',
            '& svg': {
                height: '98px !important'
            }
        },
        '& .cusomDescription': {
            fontSize: "14px"
        },
        '& .customTitle': {
            // fontSize:"20px"
        }
    },
    editAndDeleteDisabled: {
        display: "flex",
        width: "21px",
        height: "21px",
        borderRadius: "50%",
        background: "#DFE9FF",
        alignItems: "center",
        justifyContent: "center",
        color: "#2563EB",
        fontSize: "17px",
        padding: '5px',
        opacity: '0.5'
    },
    CSTablePopover: {
        "&.MuiPaper-root, .MuiMenu-paper, .MuiPopover-paper, .MuiPaper-elevation8, .MuiPaper-rounded": {
            background: `${theme.palette.containers.toggleBtn3.bg}`
        },
    },
    CStableName: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: '5px',
        '& .MuiTypography-root': {
            textWrap: 'nowrap',
            maxWidth: '400px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        "@media only screen and (max-width: 599px)": {
            '& .MuiTypography-root': {
                maxWidth: '150px'
            },
            // paddingLeft:'10px !important'
        },
        "@media only screen and (max-width: 899px)": {
            '& .MuiTypography-root': {
                maxWidth: '300px'
            },
        }
    },
    dateAlign: {
        whiteSpace: 'nowrap'
    },
    webconferencestyle: {
        marginLeft: "180px",
        width: "400px"
    },
    subscriptionStatusInvoice: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "600",
        textAlign: "right",
    },
    formGroupContainer: {
        borderRadius: "3px",
        marginBottom:'10px',
        '& .MuiSlider-rail': {
            height: '6px',
            borderRadius: '6px'
        },
        '& .MuiSlider-track': {
            height: '6px'
        },
        '& .MuiSlider-thumb':{
            height: '15px',
            width: '15px',
            boxShadow: '2.24px 2.24px 8.38px 0px #2563EB54',
            border: `2px solid ${theme.palette.containers.card}`
        }
    },
    filterChipContainer:{
        maxWidth:"180px",
        marginTop:"6px"
    }
})

export default Style;
