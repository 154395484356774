const styles = (theme) => ({
  popupContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "15px",
    backgroundColor: "#000000BF",
    borderRadius: "28px",
    padding: "3px 8px",
    width: "70px",
    transition: "width 1s", 
    userSelect: "none",
    overflow:"hidden"
  },
  popupContainer_active: {
    cursor: "pointer",
    width: "175px",
    transition: "width 1s", 
    userSelect: "none", 
    overflow:"none"
  },
  recording_icon: {
    marginTop: "5px",
    transition: "opacity 3s ease",
    opacity: 1, 
  },
  timer_con: {
    opacity: 1, 
    marginRight: "10px",
  },
  timer: {
    color: "#fff",
    fontWeight: "bold",
  },
  stopButton: {
    backgroundColor: "#848484",
    color: "#FFFFFF",
    borderRadius: "20px",
    marginLeft: "8px",
    padding: "5px 10px",
    width: "100%",
    transition: "width 1s",
   },
  stopButtonVisible: {
    backgroundColor: "#848484",
    color: "#FFFFFF",
    borderRadius: "20px",
    marginLeft: "8px",
    padding: "5px 10px",
    width: "100%",
    transition: "width 1s", 
  },
  expandIcon:{
    opacity: 1, 
    width: "100%",
    transition: "width 1.5s, opacity 1.5s",
  },
  expandIconActive:{
    opacity: 0, 
    width: "0px",
    transition: "width 1.5s, opacity 1.5s",
  }
});

export default styles;
