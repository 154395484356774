import { createTheme } from "@material-ui/core";
import { createContext, useContext } from "react";
import { getPalette } from "./index";
import typography from "./typography";
import overrides from "./overrides";
import { useColorTheme } from "./useColorTheme";
import { getSystemThemePref } from "../../utils";

const mode=localStorage.getItem('themeType') || 'system';

export const ThemeContext = createContext({
  mode,
  toggleColorMode: () => {},
  theme: createTheme({
    palette: getPalette(mode === 'system' ? getSystemThemePref() : mode),
    overrides,
    typography,
  }),
});

export const ThemeContextProvider = ({ children }) => {
  const value = useColorTheme();
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};
