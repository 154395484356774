import React from 'react';
import PropTypes from "prop-types";
import ReactGiphySearchbox from "react-giphy-searchbox";

function GiphyComponent(props) {
    const { handleSelect } = props;
    return (
        <>
            <div className="searchboxWrapper p5">
                <ReactGiphySearchbox
                    apiKey="9Ixlv3DWC1biJRI57RanyL7RTbfzz0o7"
                    onSelect={(item) => handleSelect(item)}
                    gifListHeight={"400px"}
                    masonryConfig={[
                        { columns: 2, imageWidth: 140, gutter: 5 },
                        { mq: '480px', columns: 3, imageWidth: 120, gutter: 5 },
                        { mq: '640px', columns: 3, imageWidth: 150, gutter: 5 }
                    ]}
                />
            </div>
        </>)
}

GiphyComponent.defaultProps = {
    handleSelect: () => { },
};

// prop types
GiphyComponent.propTypes = {
    handleSelect: PropTypes.func,
};

export default GiphyComponent