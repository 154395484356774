import { chatConstant } from "../constants";

// import chatPage constants
import {chatPageConstants} from '../../config/constants';

const initialState = {
    loading: false,
    project: {
        chat: {},
        chat_room: {},
        projects: {},
        projectLinks: {},
        projectMedias: {},
        projectDocs: {},
        activeProject: null,
        searchResult: null,
        page: { skip: 0, limit: chatPageConstants.MESSAGE_LIMIT_COUNT, count: 0 },
        newChat: {},
        online: {},
        channelName : {},
        readMessagesOnLoad: null,
    },
    client: {
        chat: {},
        chat_room: {},
        clients: {},
        clientLinks: {},
        clientMedias: {},
        clientDocs: {},
        activeClient: null,
        searchResult: null,
        page: { skip: 0, limit: chatPageConstants.MESSAGE_LIMIT_COUNT, count: 0 },
        newChat: {},
        online: {},
        readMessagesOnLoad: null,
    },
    teamChat: {
        chat: {},
        chat_room: {},
        teams: {},
        teamLinks: {},
        teamMedias: {},
        teamDocs: {},
        activeTeam: null,
        searchResult: null,
        page: { skip: 0, limit: chatPageConstants.MESSAGE_LIMIT_COUNT, count: 0 },
        newChat: {},
        online: {},
        readMessagesOnLoad: null,
    },
};

export default function chat(state = initialState, action) {
    switch (action.type) {
        //Job Chat Section
        case chatConstant.GET_JOB_CHAT_R:
            return {
                ...state,
                project: {
                    ...state.project,
                    searchResult: null,
                }
            };
        case chatConstant.GET_JOB_CHAT_S:

            if (action.data && action.data.page && action.data.page.skip > 0) {

                const activePjt = action.data.activeProject ? action.data.activeProject : "0"
                const stateChatArr = state.project && state.project.chat && state.project.chat[activePjt] ? state.project.chat[activePjt] : [];
                const actionChatArr = action.data && action.data.chat && action.data.chat[activePjt] ? action.data.chat[activePjt] : [];

                const chatArr = [...stateChatArr, ...actionChatArr]

                return {
                    ...state,
                    project: {
                        ...state.project,
                        ...action.data,
                        chat: {
                            ...action.data,
                            [activePjt]: chatArr
                        }
                    }
                };
            } else {
                return {
                    ...state,
                    project: {
                        ...state.project,
                        ...action.data
                    }
                };
            }
        case chatConstant.GET_JOB_CHAT_F:
            return {
                ...state,
            };
        case chatConstant.UPDATE_NEW_MESSAGE_S:
            return {
                ...state,
                project: {
                    ...state.project,
                    chat: {
                        ...state.project.chat,
                        ...action.data.chat
                    },
                    activeProject: action.data.job_id
                }
            }
        case chatConstant.GET_JOB_SEARCH_MESSAGES_R:
            return {
                ...state,
                project: {
                    ...state.project,
                    searchResult: initialState.project.searchResult
                }
            }
        case chatConstant.GET_JOB_SEARCH_MESSAGES_S:
            return {
                ...state,
                project: {
                    ...state.project,
                    searchResult: action.data.data ? [...action.data.data] : []
                }
            }
        case chatConstant.GET_JOB_SEARCH_MESSAGES_F:
            return {
                ...state,
                project: {
                    ...state.project,
                    searchResult: initialState.project.searchResult
                }
            }
        case chatConstant.GET_JOB_MEDIA_MESSAGES_F:
            return {
                ...state,
                project: {
                    ...state.project
                }
            }
        case chatConstant.GET_JOB_MEDIA_MESSAGES_S:
            return {
                ...state,
                project: {
                    ...state.project,
                    ...action.data
                }
            }


        // Client Chat Section 
        case chatConstant.GET_CLIENT_CHAT_R:
            return {
                ...state,
                client: {
                    ...state.client,
                    searchResult: null,
                }
            }
        case chatConstant.GET_CLIENT_CHAT_S:
            if (action.data && action.data.page && action.data.page.skip > 0) {

                const activeCli = action.data.activeClient ? action.data.activeClient : "0"
                const stateChatArr = state.client && state.client.chat && state.client.chat[activeCli] ? state.client.chat[activeCli] : [];
                const actionChatArr = action.data && action.data.chat && action.data.chat[activeCli] ? action.data.chat[activeCli] : [];

                const chatArr = [...stateChatArr, ...actionChatArr]

                return {
                    ...state,
                    client: {
                        ...state.client,
                        ...action.data,
                        chat: {
                            ...action.data,
                            [activeCli]: chatArr
                        }
                    }
                };
            } else {
                return {
                    ...state,
                    client: {
                        ...state.client,
                        ...action.data
                    }
                };
            }
        case chatConstant.GET_CLIENT_CHAT_F:
            return {
                ...state,
            }
        case chatConstant.UPDATE_CLIENT_NEW_MESSAGE_S:
            return {
                ...state,
                client: {
                    ...state.client,
                    chat: {
                        ...state.client.chat,
                        ...action.data.chat
                    },
                    activeClient: action.data.client_id
                }
            }
        case chatConstant.GET_CLIENT_SEARCH_MESSAGES_R:
            return {
                ...state,
                client: {
                    ...state.client,
                    searchResult: initialState.client.searchResult
                }
            }
        case chatConstant.GET_CLIENT_SEARCH_MESSAGES_S:
            return {
                ...state,
                client: {
                    ...state.client,
                    searchResult: action.data.data ? [...action.data.data] : []
                }
            }
        case chatConstant.GET_CLIENT_SEARCH_MESSAGES_F:
            return {
                ...state,
                client: {
                    ...state.client,
                    searchResult: initialState.client.searchResult
                }
            }

        case chatConstant.GET_CLIENT_MEDIA_MESSAGES_S:
            return {
                ...state,
                client: {
                    ...state.client,
                    ...action.data
                }
            }
        case chatConstant.GET_CLIENT_MEDIA_MESSAGES_F:
            return {
                ...state,
                client: {
                    ...state.client,
                }
            }

        // Team Chat Section
        case chatConstant.GET_TEAM_CHAT_R:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat,
                    searchResult: null
                }
            }
        case chatConstant.GET_TEAM_CHAT_S:
            if (action.data && action.data.page && action.data.page.skip > 0) {

                const activeCli = action.data.activeProject ? action.data.activeProject : "0"
                const stateChatArr = state.teamChat && state.teamChat.chat && state.teamChat.chat[activeCli] ? state.teamChat.chat[activeCli] : [];
                const actionChatArr = action.data && action.data.chat && action.data.chat[activeCli] ? action.data.chat[activeCli] : [];

                const chatArr = [...stateChatArr, ...actionChatArr];

                return {
                    ...state,
                    teamChat: {
                        ...state.teamChat,
                        ...action.data,
                        chat: {
                            ...action.data,
                            [activeCli]: chatArr
                        }
                    }
                };
            } else {
                return {
                    ...state,
                    teamChat: {
                        ...state.teamChat,
                        ...action.data
                    }
                };
            }
        case chatConstant.GET_TEAM_CHAT_F:
            return {
                ...state,
            }
        case chatConstant.UPDATE_TEAM_NEW_MESSAGE_S:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat,
                    chat: {
                        ...state.teamChat.chat, // [action.data.job_id]: action.data.message
                        ...action.data.chat
                        // [action.data.job_id]: action.data.message
                    },
                    activeTeam: action.data.job_id
                }
            }
        case chatConstant.GET_TEAM_SEARCH_MESSAGES_R:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat,
                    searchResult: initialState.teamChat.searchResult
                }
            }
        case chatConstant.GET_TEAM_SEARCH_MESSAGES_S:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat,
                    searchResult: action.data.data ? [...action.data.data] : []
                }
            }
        case chatConstant.GET_TEAM_SEARCH_MESSAGES_F:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat,
                    searchResult: initialState.teamChat.searchResult
                }
            }
        case chatConstant.GET_TEAM_MEDIA_MESSAGES_S:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat,
                    ...action.data
                }
            }
        case chatConstant.GET_TEAM_MEDIA_MESSAGES_F:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat,
                }
            }
        case chatConstant.UPDATE_PROJECT_CHAT_DELETE:
            return {
                ...state,
                project: {
                    ...state.project,
                    chat: { ...action.data.updatedChatList }
                }
            }
        case chatConstant.UPDATE_CLIENT_CHAT_DELETE:
            return {
                ...state,
                client: {
                    ...state.client,
                    chat: { ...action.data.updatedChatList }
                }
            }
        case chatConstant.UPDATE_TEAM_CHAT_DELETE:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat,
                    chat: { ...action.data.updatedchatlist }
                }
            }
        case chatConstant.UPDATE_PROJECT_ONLINE_USER_S:
            return {
                ...state,
                project: {
                    ...state.project,
                    online: { ...state.project.online, ...action.data }
                }
            }
        case chatConstant.UPDATE_CLIENT_ONLINE_USER_S:
            return {
                ...state,
                client: {
                    ...state.client,
                    online: { ...state.client.online, ...action.data }
                }
            }
        case chatConstant.UPDATE_TEAM_ONLINE_USER_S:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat,
                    online: { ...state.teamChat.online, ...action.data }
                }
            }
        // Star Chat for team client and project
        case chatConstant.STAR_CHAT_T_MESSAGES_R:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat
                }
            };
        case chatConstant.STAR_CHAT_T_MESSAGES_S:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat,
                    ...action.data
                }
            };
        case chatConstant.STAR_CHAT_T_MESSAGES_F:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat
                }
            };
        case chatConstant.STAR_CHAT_P_MESSAGES_R:
            return {
                ...state,
                project: {
                    ...state.project
                }
            };
        case chatConstant.STAR_CHAT_P_MESSAGES_S:
            return {
                ...state,
                project: {
                    ...state.project,
                    ...action.data
                }
            };
        case chatConstant.STAR_CHAT_P_MESSAGES_F:
            return {
                ...state,
                project: {
                    ...state.project
                }
            };
        case chatConstant.STAR_CHAT_C_MESSAGES_R:
            return {
                ...state,
                client: {
                    ...state.client
                }
            };
        case chatConstant.STAR_CHAT_C_MESSAGES_S:
            return {
                ...state,
                client: {
                    ...state.client,
                    ...action.data
                }
            };
        case chatConstant.STAR_CHAT_C_MESSAGES_F:
            return {
                ...state,
                client: {
                    ...state.client
                }
            };
        case chatConstant.UPDATE_JOB_READ_MESSAGE_ON_LOAD:
            return {
                ...state,
                project: {
                    ...state.project,
                    readMessagesOnLoad: action.data
                }
            };
        case chatConstant.UPDATE_CLIENT_READ_MESSAGE_ON_LOAD:
            return {
                ...state,
                client: {
                    ...state.client,
                    readMessagesOnLoad: action.data
                }
            };
        case chatConstant.UPDATE_TEAM_READ_MESSAGE_ON_LOAD:
            return {
                ...state,
                teamChat: {
                    ...state.teamChat,
                    readMessagesOnLoad: action.data
                }
            };

        default:
            return state
    }
}