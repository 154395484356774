import { Button, Grid, IconButton, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import history from "../../../config/history";
import { ReturnHomeScreenIcon } from '../../../assets/svg/videocall';
import style from './style';

function VCLeavePage(props) {
    const { user_id, classes, setShowVCLeave, isVideoMuted, setIsVideoMuted, audioMuted, setAudioMuted, setJoined, networkErrPage, joinCall, channel, setNetworkErrorPage, agoraClient, localVideoTracks } = props;

    const [timeLeft, setTimeLeft] = useState(30);

    const handleRejoin = async () => {
        setNetworkErrorPage(false);
        setShowVCLeave(false);
        if(!isVideoMuted){
          await localVideoTracks.videoTrack.setEnabled(true)
        }
        if(!audioMuted){
           await localVideoTracks.audioTrack.setEnabled(true)
        }
        await joinCall()
    };

    const handleNewtworkErr = () => {
        if (!isVideoMuted) {
            agoraClient.localTracks.forEach((track) => {
                if (track.trackMediaType === 'video') {
                    track.setEnabled(true);
                }
            });
        }
        if (!audioMuted) {
            agoraClient.localTracks.forEach((track) => {
                if (track.trackMediaType === 'audio') {
                    track.setEnabled(true);
                }
            });
        }
        agoraClient.remoteUsers.forEach(async (user) => {
            await agoraClient.subscribe(user, 'audio');
            user?.audioTrack?.play();
        });
        setShowVCLeave(false);
        setNetworkErrorPage(false)
    }

    const homeScreen = () => {
        if (user_id) {
            history.push('/calendar');
            window.location.reload()
        } else {
            history.push('/login')
            window.location.reload()
        }


    };

    useEffect(() => {
        if (networkErrPage) {
            return;
        }

        if (timeLeft <= 0) {
            if (user_id) {
                history.push('/calendar');
                window.location.reload()
            } else {
                history.push('/login')
                window.location.reload()
            }
        }

        const timer = setInterval(() => {
            setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    return (
        <Grid className={classes.LeavePageContainer}>
            {networkErrPage ?
                <Grid className={classes.LeftMeetingContainer}>
                    <Grid className={classes.LeftMeeting}>
                        <Typography className={classes.LeftMeetText}>
                            Your network is having problems. Check
                            your network and try again.
                        </Typography>
                        <Grid className={classes.JoinButton}>
                            <Button className={classes.RejoinBtn} onClick={handleNewtworkErr}>Join Again</Button>
                            <Button variant={"contained"} color={"primary"} size={"small"} className={"blueBtn fw-600"} onClick={homeScreen}>Return to homescreen</Button>
                        </Grid>
                    </Grid>
                </Grid>
                :
                <>
                    <Grid className={classes.HomescreenText}>
                        <Grid className={classes.HomescreenTextContainer}>
                            <Typography style={{ fontSize: '14px', fontWeight: 500, color: 'white' }}>
                                Returning to home screen
                            </Typography>
                            <Grid>
                                <ReturnHomeScreenIcon />
                            </Grid>
                            <Typography style={{ position: 'fixed', top: '34px', color: 'white' }}>{timeLeft}</Typography>
                        </Grid>
                    </Grid>

                    <Grid className={classes.LeftMeetingContainer}>
                        <Grid className={classes.LeftMeeting}>
                            <Typography className={classes.LeftMeetText}>
                                You left the meeting
                            </Typography>
                            <Grid className={classes.JoinButton}>
                                <Button className={classes.RejoinBtn} onClick={handleRejoin}>Join Again</Button>
                                <Button variant={"contained"} color={"primary"} size={"small"} className={"blueBtn fw-600"} onClick={homeScreen}>Return to homescreen</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </>}
        </Grid>
    );
}

export default withStyles(style)(VCLeavePage);
