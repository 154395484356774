import React from "react";
import { withStyles } from "@material-ui/core";
import style from "./style";


function SoundWaveSticks(props) {

    const { classes } = props

    return (
        <div className={classes.boxContainer}>
            <div className={`${classes.box} ${classes.box1}`}></div>
            <div className={`${classes.box} ${classes.box2}`}></div>
            <div className={`${classes.box} ${classes.box3}`}></div>
        </div>
    )
}

export default withStyles(style)(SoundWaveSticks);
