import React, { useState, forwardRef, useImperativeHandle, useMemo } from "react";
import { Paper, Popper, Popover, IconButton, Button } from "@mui/material";
import { withStyles, Box, Backdrop } from '@material-ui/core';
import { EmojiPicker } from "@flozy/editor";
import { ChatEmojiIcon } from "../../assets/svg/chatIcons";
import styles from "./styles";
import { MobileActionSheet } from "../MobileComponents/MobileActionSheet";
const EmojiButton = forwardRef((props, ref) => {
  const { onEmojiClck, classes, fromInput } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  let userPrefTheme = localStorage.getItem('themeType');
  const isMobile = window.matchMedia("(max-width: 899px)")?.matches || false;

  useImperativeHandle(ref, () => ({
    onClose: () => {
      setAnchorEl(null);
    }
  }));

  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onEmojiSelect = (emoji) => {
    onEmojiClck(emoji)
  };

  const handleClose = (event) => {
    if (!event?.target?.dataset?.emo) {
      setAnchorEl(null)
    }
  };

  const closeDrawer = () => {
    setAnchorEl(null);
  }

  const memoActionSheet = useMemo(() => (
    <MobileActionSheet
      open={Boolean(anchorEl)}
      title=""
      handleCloseAction={() => { closeDrawer() }}
      swipableDrawer={false}
    >
      <div className={classes.mobileEmojiPicker}>
        <EmojiPicker
          theme={userPrefTheme}
          onEmojiSelect={onEmojiSelect}
          onClose={handleClose}
          dynamicWidth={true}
          previewPosition='none'
        />
      </div>
    </MobileActionSheet>
  ), [anchorEl, userPrefTheme])

  if (fromInput) {
    return (
      <>
        <Box className={`p0 ${classes.emojiButton}`} data-emo="true" component={"button"} onClick={onClick}>
          <ChatEmojiIcon style={{ pointerEvents: "none" }} />
        </Box>
        {open && (
          <Backdrop
            open={open}
            style={{
              zIndex: 1000,
              backgroundColor: 'rgba(0, 0, 0, 0.0)',
            }}
            onClick={() => setAnchorEl(null)}
          />
        )}
        <Popper
          style={{ zIndex: 10001 }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Paper>
            <EmojiPicker
              theme={userPrefTheme}
              onEmojiSelect={onEmojiSelect}
              onClose={handleClose}
              previewPosition='none'
            />
          </Paper>
        </Popper>
      </>
    );
  }
  else {
    return (
      <>
        <div>
          <Button
            data-emo="true"
            onClick={onClick}
            aria-label="Open emoji picker"
            sx={{
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <ChatEmojiIcon style={{ pointerEvents: "none" }} />
          </Button>
          {
            isMobile ?
              memoActionSheet
              :
              <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <EmojiPicker
                  theme={userPrefTheme}
                  onEmojiSelect={onEmojiSelect}
                  onClose={handleClose}
                  previewPosition='none'
                />
              </Popover>
          }
        </div>
      </>
    );
  }
});

export const EmojiPickerComponent = withStyles(styles)(EmojiButton);

