// Import API Helper
import { postRequest, getRequest, putRequest } from "../../utils/apiHelper";

export const paymentService = {
    getInvoicePayment,
    updateViewStatus,
    updatePaymentResponse,
    updatePaymentErrorLog,
    updatePaymentStatus
};

async function getInvoicePayment(params) {
    const response = await getRequest(`invoice/payment/${params.id}`);
    return response;
}

async function updateViewStatus(params) {
    const response = await putRequest(`invoice/payment/viewStatus`, params);
    return response;
}

async function updatePaymentResponse(params) {
    const response = await putRequest(`invoice/payment`, params);
    return response;
}

async function updatePaymentErrorLog(params) {
    const response = await postRequest(`invoice/payment/error`, params);
    return response;
}

async function updatePaymentStatus(params){
    const response = await postRequest(`invoice/payment/updatePaymentStatus`, params);
    return response;
}
