export const userAgencyConstant = {

    //get all user agencies
    GET_USER_AGENCY_R: "GET_USER_AGENCY_R",
    GET_USER_AGENCY_S: "GET_USER_AGENCY_S",
    GET_USER_AGENCY_F: "GET_USER_AGENCY_F",

    //update user agencies
    UPDATE_USER_AGENCY_R: "UPDATE_USER_AGENCY_R",
    UPDATE_USER_AGENCY_S: "UPDATE_USER_AGENCY_S",
    UPDATE_USER_AGENCY_F: "UPDATE_USER_AGENCY_F",

    UPDATE_AG_DATA_R: "UPDATE_AG_DATA_R",
    UPDATE_AG_DATA_S: "UPDATE_AG_DATA_S",
    UPDATE_AG_DATA_F: "UPDATE_AG_DATA_F",

    UPDATE_PAYMENT_AG_R: "UPDATE_PAYMENT_AG_R",
    UPDATE_PAYMENT_AG_S: "UPDATE_PAYMENT_AG_S",
    UPDATE_PAYMENT_AG_F: "UPDATE_PAYMENT_AG_F",

    /****************************************************************MY BILLS************************************************************************************** */


    DOWNLOAD_INVOICE_R: "DOWNLOAD_INVOICE_R",
    DOWNLOAD_INVOICE_S: "DOWNLOAD_INVOICE_S",
    DOWNLOAD_INVOICE_F: "DOWNLOAD_INVOICE_F",

    GET_PLAN_DETAILS_R: "GET_PLAN_DETAILS_R",
    GET_PLAN_DETAILS_S: "GET_PLAN_DETAILS_S",
    GET_PLAN_DETAILS_F: "GET_PLAN_DETAILS_F",

    GET_PAYMENT_HISTORY_R: "GET_PAYMENT_HISTORY_R",
    GET_PAYMENT_HISTORY_S: "GET_PAYMENT_HISTORY_S",
    GET_PAYMENT_HISTORY_F: "GET_PAYMENT_HISTORY_F",

    GET_INV_DETAILS_R: "GET_INV_DETAILS_R",
    GET_INV_DETAILS_S: "GET_INV_DETAILS_S",
    GET_INV_DETAILS_F: "GET_INV_DETAILS_F",

    NEXT_INVOICE_DATE_R: "NEXT_INVOICE_DATE_R",
    NEXT_INVOICE_DATE_S: "NEXT_INVOICE_DATE_S",
    NEXT_INVOICE_DATE_F: "NEXT_INVOICE_DATE_F",

    GET_AUTO_PAY_R: "GET_AUTO_PAY_R",
    GET_AUTO_PAY_S: "GET_AUTO_PAY_S",
    GET_AUTO_PAY_F: "GET_AUTO_PAY_F",

    UPDATE_AUTO_PAY_R: "UPDATE_AUTO_PAY_R",
    UPDATE_AUTO_PAY_S: "UPDATE_AUTO_PAY_S",
    UPDATE_AUTO_PAY_F: "UPDATE_AUTO_PAY_F",

    UPDATE_PLAN_A_R: "UPDATE_PLAN_A_R",
    UPDATE_PLAN_A_S: "UPDATE_PLAN_A_S",
    UPDATE_PLAN_A_F: "UPDATE_PLAN_A_F",

    GET_MYBILLS_USER_INFO_R: "GET_MYBILLS_USER_INFO_R",
    GET_MYBILLS_USER_INFO_S: "GET_MYBILLS_USER_INFO_S",
    GET_MYBILLS_USER_INFO_F: "GET_MYBILLS_USER_INFO_F",

    GET_CARD_DETAILS_R: "GET_CARD_DETAILS_R",
    GET_CARD_DETAILS_S: "GET_CARD_DETAILS_S",
    GET_CARD_DETAILS_F: "GET_CARD_DETAILS_F",

    UPDATE_CARD_DETAILS_R: "UPDATE_CARD_DETAILS_R",
    UPDATE_CARD_DETAILS_S: "UPDATE_CARD_DETAILS_S",
    UPDATE_CARD_DETAILS_F: "UPDATE_CARD_DETAILS_F",

    REMOVE_PAYMENT_DATA_R: "REMOVE_PAYMENT_DATA_R",
    REMOVE_PAYMENT_DATA_S: "REMOVE_PAYMENT_DATA_S",
    REMOVE_PAYMENT_DATA_F: "REMOVE_PAYMENT_DATA_F",

    GET_AG_NAME_LOGO_R: 'GET_AG_NAME_LOGO_R',
    GET_AG_NAME_LOGO_S: 'GET_AG_NAME_LOGO_S',
    GET_AG_NAME_LOGO_F: 'GET_AG_NAME_LOGO_F',


    GET_FEEDBACK_FORM_R: "GET_FEEDBACK_FORM_R",
    GET_FEEDBACK_FORM_S: "GET_FEEDBACK_FORM_S",
    GET_FEEDBACK_FORM_F: "GET_FEEDBACK_FORM_F",

    CANCEL_AGENCY_FEEDBACK_R: "CANCEL_AGENCY_FEEDBACK_R",
    CANCEL_AGENCY_FEEDBACK_S: "CANCEL_AGENCY_FEEDBACK_S",
    CANCEL_AGENCY_FEEDBACK_F: "CANCEL_AGENCY_FEEDBACK_F",

    UPDATE_TO_SOLO_R: "UPDATE_TO_SOLO_R",
    UPDATE_TO_SOLO_S: "UPDATE_TO_SOLO_S",
    UPDATE_TO_SOLO_F: "UPDATE_TO_SOLO_F",

    PAY_UNPAID_R: "PAY_UNPAID_R",
    PAY_UNPAID_S: "PAY_UNPAID_S",
    PAY_UNPAID_F: "PAY_UNPAID_F",

    AGENCY_USER_LIMITS_R: "AGENCY_USER_LIMITS_R",
    AGENCY_USER_LIMITS_S: "AGENCY_USER_LIMITS_S",
    AGENCY_USER_LIMITS_F: "AGENCY_USER_LIMITS_F",

    AGENCY_PLANS_R: "AGENCY_PLANS_R",
    AGENCY_PLANS_S: "AGENCY_PLANS_S",
    AGENCY_PLANS_F: "AGENCY_PLANS_F",

    UPGRADE_PLAN_R: "UPGRADE_PLAN_R",
    UPGRADE_PLAN_S: "UPGRADE_PLAN_S",
    UPGRADE_PLAN_F: "UPGRADE_PLAN_F",

    CREATE_SUB_ON_ERR_R: "CREATE_SUB_ON_ERR_R",
    CREATE_SUB_ON_ERR_S: "CREATE_SUB_ON_ERR_S",
    CREATE_SUB_ON_ERR_F: "CREATE_SUB_ON_ERR_F",

    GET_AVAILABLE_PLAN_DETAILS_R: "GET_AVAILABLE_PLAN_DETAILS_R",
    GET_AVAILABLE_PLAN_DETAILS_S: "GET_AVAILABLE_PLAN_DETAILS_S",
    GET_AVAILABLE_PLAN_DETAILS_F: "GET_AVAILABLE_PLAN_DETAILS_F",

    PLANS_LIST_R: "PLANS_LIST_R",
    PLANS_LIST_S: "PLANS_LIST_S",
    PLANS_LIST_F: "PLANS_LIST_F",

    PLANS_MEMBERS_R: "PLANS_MEMBERS_R",
    PLANS_MEMBERS_S: "PLANS_MEMBERS_S",
    PLANS_MEMBERS_F: "PLANS_MEMBERS_F",

    PLANS_ADD_ON_LIST_R: "PLANS_ADD_ON_LIST_R",
    PLANS_ADD_ON_LIST_S: "PLANS_ADD_ON_LIST_S",
    PLANS_ADD_ON_LIST_F: "PLANS_ADD_ON_LIST_F",

    UPDATE_PLAN_TYPE: "UPDATE_PLAN_TYPE",

    UPGRADE_PLANS_NEW_R: "UPGRADE_PLANS_NEW_R",
    UPGRADE_PLANS_NEW_S: "UPGRADE_PLANS_NEW_S",
    UPGRADE_PLANS_NEW_F: "UPGRADE_PLANS_NEW_F",

    DELETE_USER_AGENCY_S: "DELETE_USER_AGENCY_S",
};
