import { videoCallConstants } from "../constants/videoCallConstant";

const initialState = {
  token: [],
  videocall: [],
  socketId: [],
  videoCallRequest: null,
  remoteUsers: [],
  rejectData: [],
  screenTrack: null,
  callHistory: [],
  rejectCallHistory: [],
  notifyUsers: [],
  channel: null,
  screenShareVideo: [],
  stopShareScreen: null,
  receivedVideoCallRequest: null,
  rejectCall: [],
  updateCallHistory: [],
  channelStatus: {},
  callInitialize: [],
  muteVideo: [],
  remoteUsername: [],
  videoCallRecordStartResponse:[],
  videoCallRecordStopResponse:[],
  videoCallStartState:false,
  isRecording:false,
  publicUserId: null,
  recorderId : null

};

export default function videocall(state = initialState, action) {
  switch (action.type) {
    case videoCallConstants.GET_VIDEOCALL_TOKEN_S:
      return {
        ...state,
        token: [...state.videocall, action.data.message.rtcToken],
      };
    case videoCallConstants.JOIN_REQUEST_S:
      return {
        ...state,
        requestId: [...state.videocall, action.data.message.requestId],
      };
    case videoCallConstants.SEND_REQUEST_S:
      return {
        ...state,
        socketId: [...state.videocall, action.data.msgData],
      };

    case videoCallConstants.REMOTE_USERS_S:
      return {
        ...state,
        remoteUsers: action.data,
      };
    case videoCallConstants.RECEIVE_VIDEO_CALL_REQUEST_S:
      return {
        ...state,
        videoCallRequest: action.payload,
      };
    case videoCallConstants.SAVE_CALL_HISTORY_S:
      return {
        ...state,
        callHistory: action.payload,
      };
    case videoCallConstants.GET_CALL_HISTORY_S:
      return {
        ...state,
        callHistory: [...state.videocall, action.data],
      };
    case videoCallConstants.REJECT_REQUEST_S:
      return {
        ...state,
        rejectData: action.payload,
      };
    case videoCallConstants.DELETE_VIDEOCALL_S:
      return {
        ...state,
        callHistory: state.callHistory.filter(
          (e) => `${e.id}` !== `${action.data.id}`
        ),
      };
    case videoCallConstants.UPDATE_VIDEOCALL_S:
      return {
        ...state,
        updateCallHistory: [action.data],
      };
    case videoCallConstants.UPDATE_CALLTYPE_S:
      return {
        ...state,
        rejectCallHistory: [action.data],
      };

    case videoCallConstants.NOTIFY_USER_S:
      return {
        ...state,
        notifyUsers: action.payload,
      };
    case videoCallConstants.GET_CHANNEL_S:
      return {
        ...state,
        channel: action.data,
      };
    case videoCallConstants.SCREEN_SHARE_S:
      return {
        ...state,
        screenShareVideo: action.payload,
      };
    case videoCallConstants.GET_SCREEN_TRACK_S:
      return {
        ...state,
        screenTrack: action.data,
      };
    case videoCallConstants.STOP_SCREEN_SHARE_S:
      return {
        ...state,
        screenTrack: null,
      };
    case videoCallConstants.REJECT_CALL_S:
      return {
        ...state,
        rejectCall: action.payload
      }
    case videoCallConstants.UPDATE_CHANNEL_STATUS:
      // Added consoles to test in QA
      const { data, channelName } = action.payload;
      let newChannelStatus = { ...state.channelStatus }
      newChannelStatus[channelName] = data;
      return {
        ...state,
        channelStatus: newChannelStatus
      }
    case videoCallConstants.CALL_INITIALIZE_S:
      return {
        ...state,
        callInitialize: [action.data]
      }
    case videoCallConstants.CALL_END_S:
      return {
        ...state,
        callInitialize: [action.data]
      }
    case videoCallConstants.MUTE_USER_S:
      return {
        ...state,
        muteVideo: action.payload
      }
    case videoCallConstants.GET_REMOTEUSERS_S:
      return {
        ...state,
        remoteUsername: action.data
      };
      case videoCallConstants.CHECK_FLOZY_ROOM_S:
      return {
        ...state,
        channelData: action.data
      }
    case videoCallConstants.GET_RECORDING_ACQUIRE_S:
      return {
        ...state,
        videoCallRecordStartResponse:action.data,
        videoCallStartState:true
      }
      case videoCallConstants.GET_RECORD_START_S:
        return {
          ...state,
          videoCallRecordStartResponse:action.data,
        }
      case videoCallConstants.GET_RECORD_STOP_S:
        return {
          ...state,
          videoCallRecordStopResponse:action.data,
          videoCallStartState:false
        }
      case videoCallConstants.RECORDING_STATUS_UPDATE:
        return {
          ...state,
          isRecording:action.payload.status === 1 ? true :action.payload.status === 0 ? false : false
        }
      case videoCallConstants.RECORDING_STOPPED_S:
        return {
          ...state,
          isRecording:false
        }

    case videoCallConstants.SET_PUBLIC_USER_ID:
      return {
        ...state,
        publicUserId: action.data
      }
    case videoCallConstants.SET_RECORDER_USER_ID:
        return {
          ...state,
          recorderId: action.data
        }
    default:
      return state;
  }
}
