import { Button, Grid, Typography, useTheme } from "@material-ui/core";
import React from "react";
import {
  DashboardICon2,
  RefreshIconBlue,
  SomethingtWrong,
} from "../../../assets/svg";
import { removeLocalStorage } from "../../../utils/appHelper";
// import theme from "../../../assets/theme";

function SomethingWentWrong() {
  const theme = useTheme();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        background: theme?.palette?.containers?.bg1 ?? "white",
        height: "100%",
        padding: "20px",
      }}
    >
      <Grid align="center">
        <Grid style={{ maxWidth: "300px" }}>
          <SomethingtWrong />
        </Grid>
        <Typography color="primary" variant="h5" className="fw-600 mt-4">
          Something Went Wrong
        </Typography>
        <Typography
          variant="body1"
          className="fs-15 mt-2"
          color="textSecondary"
        >
          We are working on fixing the problem,
          <br /> Kindly refresh the application to continue
        </Typography>
        <Grid className="dflex justifyCenter mt-3" align="center">
          <Button
            variant="contained"
            className="blueBtn mr-1"
            color="primary"
            size="small"
            disableElevation
            href="/"
            // onClick={() => history.push('/dashboard')}
            startIcon={<DashboardICon2 />}
          >
            Dashboard
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            className="blueOutlineBtn"
            disableElevation
            onClick={() => {
              removeLocalStorage('persistStore')
              window.location.reload()
            }}
            startIcon={<RefreshIconBlue />}
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SomethingWentWrong;
