const PublicLayoutStyles = (theme) => ({
  root: {
    minHeight: "100% !important",
  },
  paymentRootContainer: {
    display: "flex",
    height: "100%",
    // alignItems: "center",
    justifyContent: "center",
    overflowY: 'auto',
    overflowX: 'hidden',
    width: "100%",
    background: theme.palette.containers.card
  },
  container: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  poweredBy: {
    fontSize: ".5rem",
    position:"relative",
    bottom: 'calc(env(safe-area-inset-bottom) + 20px)',
    paddingBottom: "calc(env(safe-area-inset-bottom) + 10px)",
    "& b": {
      fontWeight: "700"
    }
  }
});

export default PublicLayoutStyles;