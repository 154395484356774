import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import PropTypes from "prop-types";

// Imports Styles
import styles from "./style";
import { Typography } from "../Typography";
import { IconButton } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

function CalendarComponent(props) {

    // props
    const { classes, selectedDates, setSelectedDates, multiSelect, selectedMonth, availableDates, clickRestrictions, nonAvailableDates } = props;

    console.log('availableDates', availableDates, 'nonAvailableDates', nonAvailableDates)

    // state
    const [currentDate, setCurrentDate] = useState(new Date());

    // to go to first index month on load
    useEffect(() => {
        if (selectedMonth) {
            setCurrentDate(new Date(selectedMonth))
        }
    }, [selectedMonth])

    const handleDateClick = (day) => {
        day = moment(day).format("YYYY-MM-DD")
        const startOfMonth = moment(currentDate).startOf('month').format('YYYY-MM-DD');
        const endOfMonth = moment(currentDate).endOf('month').format('YYYY-MM-DD');

        // disable past and future months
        if ((day < startOfMonth) || (day > endOfMonth)) return;
        const newSelectedDates = selectedDates.includes(day) ? selectedDates.filter((date) => (date !== day)) : [...(multiSelect ? selectedDates : []), day];
        setSelectedDates(newSelectedDates);
    };

    const isDateSelected = (day) => {
        day = moment(day).format("YYYY-MM-DD")
        return selectedDates.some((date) => date === day);
    }

    const hasNotAvailableSlots = (day) => {
        return nonAvailableDates?.includes(moment(day).format("YYYY-MM-DD"))
    }

    const goToPreviousMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };

    const goToNextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };

    const renderCalendar = () => {
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth();
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);
        const startingDayOfWeek = firstDay.getDay();
        const daysInMonth = lastDay.getDate();
        const prevMonthDays = startingDayOfWeek === 0 ? 6 : startingDayOfWeek - 1;
        const nextMonthDays = lastDay.getDay() === 0 ? 0 : 7 - lastDay.getDay();
        const days = [];

        // Previous month days
        for (let i = prevMonthDays; i > 0; i--) {
            const prevMonthDay = new Date(year, month, -i + 1);
            days.push(
                <div
                    key={`prev-${i}`}
                    className={`${classes.day} dayField ${classes.disabledField}`}
                >
                    {prevMonthDay.getDate()}
                </div>
            );
        }

        // Current month days
        for (let i = 1; i <= daysInMonth; i++) {
            const currentDay = new Date(year, month, i);
            const hasEvents = availableDates.includes(moment(currentDay).format("YYYY-MM-DD"))           
            const isToday = moment(new Date()).format('YYYY-MM-DD') === moment(currentDay).format('YYYY-MM-DD')
            days.push(
                <div
                    key={i}
                    className={`${classes.day} ${hasEvents && 'hasEvent'} ${isToday && 'currentDayCls'} dayField ${isDateSelected(currentDay) ? `${classes.selected} selected` : hasNotAvailableSlots(currentDay) ? `${classes.noEvents}` : ""}`}
                    onClick={(!clickRestrictions || (clickRestrictions && hasEvents)) ? () => { handleDateClick(currentDay) } : () => { }}
                >
                    <div>{i}</div>
                    <>{hasEvents && <div className={`${classes.dot} dotItem`}></div>}</>
                </div>
            );
        }

        // Next month days
        for (let i = 1; i <= nextMonthDays; i++) {
            const nextMonthDay = new Date(year, month + 1, i);
            days.push(
                <div
                    key={`next-${i}`}
                    className={`${classes.day} dayField ${classes.disabledField}`}
                >
                    {nextMonthDay.getDate()}
                </div>
            );
        }

        return days;
    };

    return (
        <div className={classes.calendar}>
            <div className={classes.headerCont}>
                <Typography variant="body1" className="fw-700">{moment(currentDate).format("MMMM YYYY")}</Typography>
                <div className="dispFlex embedPageIcons">
                    <IconButton onClick={() => { goToPreviousMonth() }}><ChevronLeft /></IconButton>
                    <IconButton onClick={() => { goToNextMonth() }}><ChevronRight /></IconButton>
                </div>
            </div>
            <div className={`${classes.days} daysField daysFieldRoot`}>
                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((item, index) => (
                    <div key={index} className={`${classes.day} dayField`}>{item}</div>
                ))}
                {renderCalendar()}
            </div>
        </div>
    );
}

CalendarComponent.defaultProps = {
    classes: {},
    selectedDates: [],
    setSelectedDates: () => { },
    multiSelect: false,
    selectedMonth: '',
    availableDates: [],
    clickRestrictions: false
};

// prop types
CalendarComponent.propTypes = {
    classes: PropTypes.object,
    selectedDates: PropTypes.array,
    setSelectedDates: PropTypes.func,
    multiSelect: PropTypes.bool,
    selectedMonth: PropTypes.string,
    availableDates: PropTypes.array,
    clickRestrictions: PropTypes.bool
};

export const Calendar = withStyles(styles)(CalendarComponent);