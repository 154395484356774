import React, { useState, useEffect } from "react";
import { Grid, Typography, withStyles } from "@material-ui/core";
import { IsRecordingIcon, ExpandIcon } from "../../../assets/svg/videocall";
import PropTypes from "prop-types";
import style from "./style";

const StopRecordPopup = ({
  classes,
  onStopRecording,
  videoCallStartState,
  videoCallRecordStartResponse,
  user_id,
  isRecording,
  joined
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [showStopButton, setShowStopButton] = useState(false);

  useEffect(() => {
    let timer;
    if (isRecording) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isRecording]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const handleStopRecording = (e) => {
    if (isRecording && parseInt(videoCallRecordStartResponse?.recordStartedUid) === parseInt(user_id)) {
    e.stopPropagation();
    if (onStopRecording) {
      onStopRecording();
    }
  }
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let timeout;
    if (isOpen) {
      timeout = setTimeout(() => {
        setShowStopButton(true);
      },500); 
    } else {
      setShowStopButton(false);
    }
    return () => clearTimeout(timeout);
  }, [isOpen]);

  return (
    <>
    {joined && <Grid
      className={`${classes.popupContainer} ${isOpen ?  classes.popupContainer_active : ''}`}
      onClick={handleOpen}
    >
      <Grid className={classes.recording_icon}>
        <IsRecordingIcon />
      </Grid>
      {/* <Grid className={`${classes.timer_con}`}>
        <Typography className={classes.timer}>
          {formatTime(seconds)}
        </Typography>
      </Grid> */}
       <Grid className={`${classes.expandIcon} ${isOpen ? classes.expandIconActive : ""}`}>

        <ExpandIcon/>

        </Grid>
        <Grid
          variant="contained"
          color="secondary"
          className={ isOpen ? classes.stopButtonVisible :classes.stopButton}
          onClick={handleStopRecording}
        >
          Recording
        </Grid>
      <Grid className={`${classes.expandIcon} ${classes.expandIconActive}`}>

      <ExpandIcon/>

      </Grid>
    </Grid>}
    </>
  );
};

StopRecordPopup.propTypes = {
  classes: PropTypes.object.isRequired,
  onStopRecording: PropTypes.func,
  openDialog: PropTypes.bool.isRequired,
};

export default withStyles(style)(StopRecordPopup);
