const Styles = (theme) => ({
    noNetworkContainer: {
        height: "100vh",
        width: "100vw",
        padding:"30px",
        position: "absolute",
        top: 0,
        right: 0,
        background: "#f5f6f9",
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 999999

    }
})

export default Styles