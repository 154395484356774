import React from "react";
import PropTypes from "prop-types";
import { Snackbar, SnackbarContent } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';


import { Button } from '../index'
/**
 * Link Component
 * @param {*} props
 */

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .MuiSnackbarContent-root': {
            width: '100%',
            background: theme.palette.primary.gradientBtn,
            borderRadius: 0,
            height: 65,
            flexWrap: 'nowrap'
        },
        '& .MuiSnackbar-anchorOriginBottomCenter': {
            bottom: 0,
            transform: 'unset !important',
            left: '0px !important',
            right: '0px !important'
        },
    },
}));

function CookiesComponent({ open, handleClose, openStatus, message, actionMessage, href, children, className, onActionClick, ...rest }) {

    const classes = useStyles();

    return (
        <div className={`${classes.root} cookiesNotification`}>
            <Snackbar
                className={className}
                classes={classes}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openStatus}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <SnackbarContent
                    message={message}
                    action={
                        <React.Fragment>
                            <Button className="enableNotification mr-1" color="secondary" size="small" variant="outlined" onClick={onActionClick} title={actionMessage}></Button>
                        </React.Fragment>
                    }
                />
            </Snackbar>
        </div>
    );
}

// default props
CookiesComponent.defaultProps = {
    classes: {},
    message: "",
    openStatus: false,
    actionMessage: "",
    className: "",
    onActionClick: () => { },
    onNotificationClose: () => { }
};

// prop types
CookiesComponent.propTypes = {
    children: PropTypes.any,
    openStatus: PropTypes.bool,
    onActionClick: PropTypes.func,
    onNotificationClose: PropTypes.func,
    classes: PropTypes.object,
    className: PropTypes.string,
    message: PropTypes.string,
    actionMessage: PropTypes.string,
};

/** Export Component */
export default CookiesComponent;
