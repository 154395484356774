import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// Import Public Layout
import PublicLayout from "../layouts/PublicLayout/PublicLayout";
import Socketv2 from "../containers/Socketv2";

// Routes the page without any authentication by the user
const PublicRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    <PublicLayout {...props} {...rest}>
      <Component {...props} />
      <Socketv2 />
    </PublicLayout>
  )} />
);

// Define Props Types
PublicRoute.propTypes = {
  component: PropTypes.elementType
};

export default PublicRoute;
