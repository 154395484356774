import React from "react";
import { PlayCircleOutline } from "@material-ui/icons";

export default function Play(props) {
  const { handleClick,message } = props;

  return (
    <>
      {message ? (
        <button className="player__button" onClick={() => handleClick()}>
          <PlayCircleOutline />
        </button>
      ) : (
        <button className="player__button"
        style={{ fontSize: '13px' }} 
        >
          <PlayCircleOutline />
        </button>
      )}
    </>
  );
}
