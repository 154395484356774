// Import API Helper
import { postRequest, getRequest, putRequest } from "../../utils/apiHelper";

export const dashboardService = {
    updateRtext,
    getWordClockData,
    updateWordClockData,
    getCityTimeZones,
    getQuickNote,
    updateQuickNote
};

async function updateRtext(params) {
    const response = await postRequest(`dashboard/rText`,params);
    return response;
}


/** Get world clock data */
async function getWordClockData() {
    const response = await getRequest(`dashboard/world_clock`);
     return response;
}


/** Update world clock data */
async function updateWordClockData(params) {
    const response = await postRequest(`dashboard/save_world_clock`,params);
     return response;
}


/** Update world clock data */
async function getCityTimeZones(params) {
    const response = await postRequest(`dashboard/get_timezones`, params);
     return response;
}

async function getQuickNote() {
    const response = await getRequest(`docs/quick-note/get`);
     return response;
}

async function updateQuickNote(doc_id, params) {
    const response = await putRequest(`docs/quick-note/${doc_id}`, params, false);
     return response;
}