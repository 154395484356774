const styles = (theme) => ({

  customSelectBox: {
    width: "100%",
    '& .MuiSelect-outlined': {
      padding: 10,
      '&:focus': {
        background: 'transparent'
      }
    },
    '& .MuiOutlinedInput-root': {
      height: 40,
      minHeight: 40,
      marginTop: 0
    },
    '& legend': {
      maxWidth: '0 !important'
    },
    "& .option_links": {
      display: "none !important"
    },
    "& .item_status_dot": {
      display: "none !important"
    },
    "& .option_connect_btn": {
      display: "none !important"
    }
  },
  popverClass: {
    boxShadow: `${theme.palette.shadows.shadow7} !important`,
    maxHeight: "300px",
    overflowY: "auto",
    marginTop:'10px !important',
    background: theme.palette.type === "dark" && `#202330 !important`,
    '& .MuiMenuItem-root': {
      color: theme.palette.primary.main
    },
    "& ul": {
      padding: "0px",
      "& li": {
        padding: "8px 12px"
      }
    }
  },
  // '& .MuiOutlinedInput-notchedOutline legend': {
  //   display: "none !important"
  // },
  // '& .MuiOutlinedInput-notchedOutline ': {
  //   '&legend': {
  //     display: "none !important",
  //   },
  //     marginTop: '5px !important'
  // },
  iconClass: {
    width: "15px",
    height: "15px",
    marginRight: "10px",
    objectFit: 'contain'
  },
  customSelectBoxOptions: {
    padding: "0px"
  },
  selectOptionBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    flexWrap: "wrap",
    "& .option_content": {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      justifyContent: "flex-start",
      alignItems: "center",
      padding: '4px 0px',
      "& .item_icon": {
        display: "flex"
      },
      "& .item_name": {
        display: "flex",
        alignItems: "flex-end",
        "& span": {
          color: "#595959", fontSize: "10px", padding: "0px 5px",
        }
      },
      "& .item_status_dot": {
        width: "0.25rem",
        height: "0.25rem",
        borderRadius: "100%",
        marginLeft: "5px",
        "&.online": {
          background: "#0E8E2A",
        },
        "&.offline": {
          background: "#E1425E",
        }
      }
    },
    "& .option_links": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      fontSize: "0.75rem",
      color: "#E1425E",
      padding: '4px 0px',
      "& span": {
        color: "#2563EB",
        marginLeft: "5px",
        marginRight: "5px",
        cursor: "pointer"
      }
    },
    "& .option_coming_soon": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      fontSize: "0.75rem",
      color: theme.palette.type === "dark" ? "" : "#2563EB",
      fontStyle: "italic",
      background: theme.palette.containers.bg29,
      borderRadius: '4px',
      padding: '5px'
    },
    "& .option_connect_btn": {
      "& button": {
        padding: "4px 8px",
        fontSize: "12px",
        cursor: "pointer"
      }
    }
  }

})
export default styles