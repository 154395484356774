/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";
import { isMobile, isIOS, isBrowser, mobileModel } from 'react-device-detect';

// Layout Style
import PaymentLayoutStyles from "./PaymentLayoutStyles";

//Import Component
import { Typography } from '../../components'


const PaymentLayout = (props) => {
  const { classes } = props;
  let safeAreaTopHeight = getComputedStyle(document.documentElement).getPropertyValue("--sat");
  if (isIOS && isMobile && !isBrowser && mobileModel === "iPhone") {
    // let height = height - parseInt(safeAreaTopHeight.replace("px", ""));
  }
  /**
 * Handle Header Menu Action
 */
  return (
    <Grid container className={classes.paymentRootContainer}>
      {
        isIOS && isMobile && !isBrowser && mobileModel === "iPhone" ?
          <Grid item xs={12} style={{ height: `${safeAreaTopHeight}` }} className="notchGrid flexBasis0 " /> : null
      }
      <Grid className="w-100">
        <Grid item xs={12}>
          <Grid
            container
            className={`${classes.container}`}
            // alignContent="baseline"
            >
            {props.children}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            className={`${classes.poweredBy}`}
            alignContent="center"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="title" justifyContent="center" alignItems="center" className="fs-14 mt-1" >
              Powered by <b>Flozy</b>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

// Define Props Types
PaymentLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired
};

// Default props
PaymentLayout.defaultProps = {
  classes: {},
  children: {},
  user: {},
  access: false
};

export default withStyles(PaymentLayoutStyles)(PaymentLayout);
