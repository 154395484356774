import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles } from "@material-ui/core";
import { Button } from "../Button";
import CloseIcon from '@material-ui/icons/Close';
import { useHotkeys } from 'react-hotkeys-hook';

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: '18px',
    top: '12px'
  },
  keyboardDialog: {
    '$ .MuiDialogContent-dividers': {
      padding: '6px 6px'
    },
    '& .MuiListItemSecondaryAction-root': {
      display: 'flex'
    }
  },
  dialogueTitle: {
    '& .MuiTypography-root': {
      fontSize: '22px !important'
    }
  },
  keyBtns: {
    '& .keyBtn': {
      boxShadow: 'rgb(196 207 214) 0px -1px 0px inset',
      width: '26px',
      height: '26px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgb(247, 249, 250)',
      border: '1px solid rgb(235, 238, 240)',
      borderRadius: '3px',
      color: '#101010'
    },
    '& .keyBtnPlus': {
      padding: '3px 6px'
    }
  },
  keyboardText: {
    '$ .MuiTypography-body1': {
      fontSize: '15px !important',
      color: '#101010',
      fontFamily: 'Inter, sans-serif'
    }
  },
  dilogueBtn: {
    display: 'none'
  }
});

/**
 * Link Component
 * @param {*} props
 */

const Shortcuts = (props) => {

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // combination of keys example
  // useHotkeys('/+s', () => { handleClickOpen() })
 
  useHotkeys('shift+/', () => { handleClickOpen() })

  
  const shortcutData = [
    // { key: "d", func: "Dashboard", combi: false },
    // { key: "c", func: "Clients", combi: false },
    // { key: "f", func: "Flows", combi: false },
    // { key: "m", func: "Chat Hub", combi: false },
    // { key: "i", func: "Invoice", combi: false },
    // { key: "u", func: "Profile", combi: false },
    // { key: "t", func: "Tasks", combi: false },
    // { key: "a", func: "Teams", combi: false },
    // { key: "?", func: "Shortcut Help", combi: false },
   
    { key: "g", key1: "+", key2: "d", func: "Dashboard", combi: true },
    { key: "g", key1: "+", key2: "c", func: "Clients", combi: true },
    { key: "g", key1: "+", key2: "f", func: "Flows", combi: true },
    { key: "g", key1: "+", key2: "h", func: "Chat Hub", combi: true },
    { key: "g", key1: "+", key2: "i", func: "Invoice", combi: true },
    { key: "g", key1: "+", key2: "p", func: "Profile", combi: true },
    { key: "g", key1: "+", key2: "t", func: "Tasks", combi: true },
    { key: "g", key1: "+", key2: "a", func: "Teams", combi: true },
    { key: "?", func: "Shortcut Help", combi: false },
    // { key: "/", key1: "+", key2: "s", func: "Shortcut Help", combi: true },
  ]

  return (
    <div>
      <Button id="openDialogue" onClick={handleClickOpen} className={classes.dilogueBtn}>
      </Button>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.keyboardDialog}
      >
        <DialogTitle className={classes.dialogueTitle} id="customized-dialog-title">
          Keyboard shortcuts
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <List>
            {shortcutData && shortcutData.map((item, index) => (
              <React.Fragment key={index}>
                {item.combi ?
                  <ListItem key={index}>
                    <ListItemText className={classes.keyboardText}>{item["func"]}</ListItemText>
                    <ListItemSecondaryAction className={classes.keyBtns}>
                      <Grid className={"keyBtn"}>{item["key"]}</Grid>
                      <Grid className={"keyBtnPlus"}>{item["key1"]}</Grid>
                      <Grid className={"keyBtn"}>{item["key2"]}</Grid>
                    </ListItemSecondaryAction>
                  </ListItem>
                  :
                  <ListItem key={index}>
                    <ListItemText className={classes.keyboardText}>{item["func"]}</ListItemText>
                    <ListItemSecondaryAction className={classes.keyBtns}>
                      <Grid className={"keyBtn"}>{item["key"]}</Grid>
                    </ListItemSecondaryAction>
                  </ListItem>
                }
              </React.Fragment>
            ))}

          </List>
        </DialogContent>
      </Dialog>
    </div>
  )
}
// default props
Shortcuts.defaultProps = {
  classes: {},
  className: "",
  onClick: () => { }
};

// prop types
Shortcuts.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string
};

/** Export Component */
export default Shortcuts;
