import React from "react";

export const GifLoader = (props) => {
    const { classes, file, onClick } = props
    return (
        <img
            className={`${classes.chatImage}`}
            src={file?.url || file}
            onClick={onClick}
            style={{
                width: '210px',
                maxHeight: "none"
            }}
        />
    )
}