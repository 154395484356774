/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Grid, withStyles, useTheme } from "@material-ui/core";
import { isMobile, isIOS, isBrowser, mobileModel } from 'react-device-detect';

// Layout Style
import PublicLayoutStyles from "./PublicLayoutStyles";

// Import Child Container
import Header from "../../containers/Header/Header";


import CookiesComponent from "../../components/CookiesComponent";

// Import Config Layout
import { getContainerHeight } from "../../config/layout";
import { useSelector } from "react-redux";
import { useThemeContext } from "../../assets/theme/ThemeContextProvider";

const PublicLayout = (props) => {

  const { changeMode } = useThemeContext()

  const { publicDoc } = useSelector(p => (p.docs))

  const properties = publicDoc?.properties || {}

  const { match, classes } = props;
  const theme = useTheme()
  let safeAreaTopHeight = getComputedStyle(document.documentElement).getPropertyValue("--sat");

  // Set Container Height
  let height = getContainerHeight();
  if (isIOS && isMobile && !isBrowser && mobileModel === "iPhone") {
    height = height - parseInt(safeAreaTopHeight.replace("px", ""));
  }

  //Notification
  const [allowCookie, setAllowCookie] = React.useState(false);

  let cookie = localStorage.getItem('myPolicy')
  if (cookie !== "PrivacyPolicy") {
    setTimeout(() => {
      setAllowCookie(true);
    }, 2000);
  }

  const onAcceptCookie = () => {
    localStorage.setItem('myPolicy', 'PrivacyPolicy', { path: '/' });
    setAllowCookie(false);
  }

  const sectionStyle = properties?.pageColor ? { background: properties.pageColor } : { background: theme.palette.containers.bg1 }
  const showHeader = match && match.path && (match.path === "/bookings/:event_url" || match.path === "/bookings/:event_url/:type/:booking_id" || (match.path.includes('enroll'))) ? false : true;
  const showMenu = match && match.path && match.path.includes("signup") ? false : true;
  const isPublicDoc = match && match.path && match.path.includes("document/") ? 'page-publicdoc' : '';
  /**
 * Handle Header Menu Action
 */

  useEffect(() => {
    changeMode("light")
  }, [])

  return (
    <Grid container className={`${isIOS && isMobile && !isBrowser && mobileModel === "iPhone" ? "notchContainer" : null} h-100`} direction="column" wrap="nowrap" style={sectionStyle}>
      {
        isIOS && isMobile && !isBrowser && mobileModel === "iPhone" ?
          <Grid item xs={12} style={{ height: `${safeAreaTopHeight}` }} className="notchGrid flexBasis0 " /> : null
      }
      {showHeader && <Grid item xs={12} className={`${classes.publicLayoutHeader} ${isPublicDoc}`}>
        <Header match={match} {...props} isPublic={true} showMenu={showMenu} />
      </Grid>}
      <Grid item xs={12} className={classes.publicPageBody}>
        <Grid
          container
          className={`${classes.container}`}
          style={{ height: showHeader ? height : height > 0 ? `calc(${height}px + 50px)` : "100vh" }}
        // alignContent="baseline"
        >
          {props.children}
        </Grid>
      </Grid>
      {/* {
        !isMobile &&
        <CookiesComponent
          openStatus={allowCookie}
          message={
            <>
              <span>
                By clicking “ACCEPT”, you agree to the storing of first- and third-party cookies.{' '}
                <a href="https://www.flozy.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                  Read our cookie policy
                </a>
              </span>
            </>
          }
          actionMessage="Accept"
          onActionClick={onAcceptCookie} />
      } */}
    </Grid>
  );
}

// Define Props Types
PublicLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired
};

// Default props
PublicLayout.defaultProps = {
  classes: {},
  children: {},
  user: {},
  access: false
};

export default withStyles(PublicLayoutStyles)(PublicLayout);
