// import { postRequest} from "../../utils/apiHelper";

// async function createPdf(body) {
//     const response = await postRequest("generate-pdf", body,undefined,undefined,'puppeter');
//     return response;
//   }

// export const pdfService = {
//     createPdf
// }
import { getToken } from "../../utils";

import axios from 'axios';
const SERVER_POINT = process.env.REACT_APP_BUILD_TO || "LOCAL"; // QA, UAT, PROD, LOCAL

const createPdf = async (params) => {
  try {
    const response = await axios({
      url: SERVER_POINT === 'LOCAL' ? 'http://localhost:8090/generate-pdf' :' https://puppeterserver.agenciflow.com/generate-pdf', // Make sure this URL is reachable from the client
      method: 'POST',
      data: params,
      responseType: 'blob',
      headers: {
        Authorization: "Bearer " + getToken(),
      }// Important for handling binary data
    });
    return response;
  } catch (error) {
    console.error('Error fetching PDF:', error);
    throw error; // Re-throw to be caught in Redux action
  }
};


export const pdfService = {
  createPdf,
};
