import { baseAction } from "./baseAction";
import {
  invoiceConstant,
  alertConstant,
  loaderConstant,
  workflowConstants,
  invoiceUpdateConstants
} from "../constants";
import { invoiceService } from "../services";

// Import History
import history from "../../config/history";
import { alertAction } from "./alertAction";

const { request, success, failure } = baseAction;

// All login based actions
export const invoiceAction = {
  getUserConnectStatus,
  stripeInvoiceActivation,
  getInvoiceInfo,
  getInvoiceData,
  getInvoiceClients,
  getInvoiceSettings,
  updateInvoiceData,
  getSettings,
  updateSettings,
  saveInvoice,
  getInvoiceSummary,
  getPaymentChart,
  sendInvoiceReminder,
  getSubscriptions,
  handleStripeDisconnect,
  deleteInvoice,
  getInvoiceErrors,
  closeInvoiceErrors,
  getSubscriptionLogs,
  getArchiveInvoiceSummary,
  updateInvoiceStatus, // for offline payments
  clearInvoiceData,
  createInvoiceFromPublicInvoice,
  getPublicInvoiceSummary,
  getPublicInvoiceData,
  getInvoiceTimeline,
  updatePaymentStatus,
  createNewClient,
  updateClientCustomer,
  deleteClientCustomer,
  getInvoiceAllReports,
  getInvoiceReports,
  getPaymentsReports,
  getInvoiceNewNotification,
  removeNotification,
  refundSingleInvoice,
  getcurrencyReports
};

/** stripe activation after connect account created */
function stripeInvoiceActivation(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.INVOICE_STRIPE_ACTIVATION_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.stripeInvoiceActivation(params).then(
      (data) => {
        dispatch(success(invoiceConstant.INVOICE_STRIPE_ACTIVATION_S, ""));
        dispatch(request(loaderConstant.LOADING_FALSE));
        history.push(`/invoices`);
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.INVOICE_STRIPE_ACTIVATION_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/** get user stripe connect status */
function getUserConnectStatus(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.GET_INVOICE_CONNECT_STATUS_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.getUserConnectStatus(params).then(
      (data) => {
        dispatch(
          success(invoiceConstant.GET_INVOICE_CONNECT_STATUS_S, data.data)
        );
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_INVOICE_CONNECT_STATUS_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** get user stripe connect status */
function handleStripeDisconnect(cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.INVOICE_STRIPE_DISCONNECT_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.handleStripeDisconnect().then(
      (data) => {
        dispatch(
          success(invoiceConstant.INVOICE_STRIPE_DISCONNECT_S, data.data)
        );
        dispatch(request(loaderConstant.LOADING_FALSE));
        if (data.message === "success") {
          cb();
        } else {
          dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        }
      },
      (error) => {
        dispatch(failure(invoiceConstant.INVOICE_STRIPE_DISCONNECT_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** get Invoice Info (Default Invoice Settings and Clients For Invoice Form) */
function getInvoiceInfo(params, cb = () => { }) {
    return (dispatch) => {
        dispatch(request(invoiceConstant.GET_INVOICE_INFO_R, ""));
        dispatch(request(loaderConstant.LOADING_TRUE));
        invoiceService.getInvoiceInfo(params).then(data => {
            dispatch(success(invoiceConstant.GET_INVOICE_INFO_S, data.data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            cb()
        }, error => {
            dispatch(failure(invoiceConstant.GET_INVOICE_INFO_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function clearInvoiceData(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.CLEAR_INVOICE_DATA, ""));
  };
}

/** get Invoice Data For Edit Invoice */
function getInvoiceData(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.GET_INVOICE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.getInvoiceData(params).then(
      (data) => {
        if (data && data.data && data.data.form_status) {
          dispatch(success(invoiceConstant.GET_INVOICE_S, data.data));
          dispatch(request(loaderConstant.LOADING_FALSE));
        } else {
          dispatch(failure(alertConstant.ALERT_ERROR, "Invalid parameter"));
          dispatch(request(loaderConstant.LOADING_FALSE));
          history.push(`/invoices`);
        }
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_INVOICE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** get Invoice Users */
function getInvoiceClients(params, cb = () => {}) {
  return (dispatch) => {
    invoiceService.getInvoiceClients(params).then(
      (data) => {
        if (!params?.preserveData) {
          dispatch(request(invoiceConstant.GET_INVOICE_CLIENTS_R, ""));
        }
        dispatch(
          success(invoiceConstant.GET_INVOICE_CLIENTS_S, {
            ...data.data,
            params,
          })
        );
        cb({...data.data});
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_INVOICE_CLIENTS_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        cb();
      }
    );
  };
}

/** get Invoice Info to create invoices */
function getInvoiceSettings(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.GET_INVOICE_SETTING_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.getInvoiceSettings(params).then(
      (data) => {
        dispatch(success(invoiceConstant.GET_INVOICE_SETTING_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_INVOICE_SETTING_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** update invoice data*/
function updateInvoiceData(data, cb = () => {}) {
  return (dispatch) => {
    dispatch(success(invoiceConstant.UPDATE_INVOICE_DATA_S, data));
  };
}

/** get Invoice Settings */
function getSettings(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.GET_IN_SETTING_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.getSettings(params).then(
      (data) => {
        dispatch(success(invoiceConstant.GET_IN_SETTING_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_IN_SETTING_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** update Invoice Settings */
function updateSettings(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.UPDATE_IN_SETTING_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.updateSettings(params).then(
      (data) => {
        dispatch(success(invoiceConstant.UPDATE_IN_SETTING_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.UPDATE_IN_SETTING_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** Save Invoice */
function saveInvoice(params, cb = () => { }, openInvoice = false) {
    return (dispatch) => {
        dispatch(request(invoiceConstant.SAVE_INVOICE_R, ""));
        dispatch(request(loaderConstant.LOADING_TRUE));
        invoiceService.saveInvoice(params).then(data => {
            dispatch(success(invoiceConstant.SAVE_INVOICE_S, data));
            dispatch(success(workflowConstants.CLEAR_WORKFLOW_DRAFT_S));
            dispatch(request(loaderConstant.LOADING_FALSE));
          if(!data.data.errorMessage){
            let mmsg = ""
            if (data.data) {
                mmsg = data.data.text ? data.data.text : data.data.message ? data.data.message : ""
            } else if (data) {
                mmsg = data.text ? data.text : data.message ? data.message : ""
            }
            dispatch(success(alertConstant.ALERT_SUCCESS, mmsg));
            if(!("redirect" in params)){
              if(openInvoice){
                  if (data.data.invoice_id) {
                      history.push(`/invoices/edit/${data.data.invoice_id}`)
                  }
              } else{
                  if (data.page) {
                      history.push(`/invoices/${data.page}`)
                  } else if (data.data && data.data.page) {
                      history.push(`/invoices/${data.data.page}`)
                  }
              }
            }
          }
            cb(data.data);
        }, error => {
            dispatch(failure(invoiceConstant.SAVE_INVOICE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

/** get Invoice Summary */
function getInvoiceSummary(params, cb = () => {}) {
  return (dispatch) => {
    invoiceService.getInvoiceSummary(params).then(
      (data) => {
        if (!params?.preserveData) {
          dispatch(request(invoiceConstant.GET_INVOICE_SUMMARY_R, ""));
        }
        dispatch(
          success(invoiceConstant.GET_INVOICE_SUMMARY_S, {
            ...data.data,
            params,
          })
        );
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb({...data.data});
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_INVOICE_SUMMARY_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/** get Invoice Summary */
function getArchiveInvoiceSummary(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.GET_ARCHIVE_INVOICE_SUMMARY_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.getArchiveInvoiceSummary(params).then(
      (data) => {
        dispatch(
          success(invoiceConstant.GET_ARCHIVE_INVOICE_SUMMARY_S, data.data)
        );
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_ARCHIVE_INVOICE_SUMMARY_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** getPaymentChart */
function getPaymentChart(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.GET_PAYMENT_CHART_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.getPaymentChart(params).then(
      (data) => {
        dispatch(success(invoiceConstant.GET_PAYMENT_CHART_S, data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_PAYMENT_CHART_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/* Send Invoice Reminder */
function sendInvoiceReminder(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.INVOICE_REMINDER_MAIL_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.sendInvoiceReminder(params).then(
      (data) => {
        dispatch(success(invoiceConstant.INVOICE_REMINDER_MAIL_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.INVOICE_REMINDER_MAIL_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/** get Invoice Summary */
function getSubscriptions(params, cb = () => {}) {
  return (dispatch) => {
    invoiceService.getSubscriptions(params).then(
      (data) => {
        if (!params?.preserveData) {
          dispatch(request(invoiceConstant.GET_SUBSCRIPTION_R, ""));
        }
        dispatch(
          success(invoiceConstant.GET_SUBSCRIPTION_S, { ...data.data, params })
        );
        cb({ ...data.data});
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_SUBSCRIPTION_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

//deleteInvoice
function deleteInvoice(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.DELETE_INVOICE_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.deleteInvoice(params).then(
      (data) => {
        dispatch(success(invoiceConstant.DELETE_INVOICE_S, data.data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.DELETE_INVOICE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

function getInvoiceErrors(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.GET_INVOICE_ERRORS_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.getInvoiceErrors(params).then(
      (data) => {
        dispatch(success(invoiceConstant.GET_INVOICE_ERRORS_S, data.data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_INVOICE_ERRORS_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

// closeInvoiceErrors
function closeInvoiceErrors(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.CLOSE_INVOICE_ERRORS_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.closeInvoiceErrors(params).then(
      (data) => {
        dispatch(success(invoiceConstant.CLOSE_INVOICE_ERRORS_S, data.data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.CLOSE_INVOICE_ERRORS_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

//getSubscriptionLogs
function getSubscriptionLogs(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.SUBSCRIPTION_LOGS_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.getSubscriptionLogs(params).then(
      (data) => {
        dispatch(success(invoiceConstant.SUBSCRIPTION_LOGS_S, data.data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.SUBSCRIPTION_LOGS_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

//update invoice status for offline non payable invoices
function updateInvoiceStatus(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.UPDATE_INVOICE_STATUS_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.updateInvoiceStatus(params).then(
      (data) => {
        dispatch(success(invoiceConstant.UPDATE_INVOICE_STATUS_S, data.data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb(data.data)
        dispatch(success(alertConstant.ALERT_SUCCESS,"Invoice Updated"));
      },
      (error) => {
        dispatch(failure(invoiceConstant.UPDATE_INVOICE_STATUS_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(alertConstant.ALERT_SUCCESS,"Error while updating"));

      }
    );
  };
}



function getInvoiceReports (params, cb = () => { }) {
    return (dispatch) => {
        dispatch(request(invoiceConstant.GET_INVOICE_REPORTS_R, ""));
        dispatch(request(loaderConstant.LOADING_TRUE));
        invoiceService.getInvoiceReports(params).then(
            (data) => {
                dispatch(success(invoiceConstant.GET_INVOICE_REPORTS_S, data.data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                cb();
            },
            (error) => {
                dispatch(failure(invoiceConstant.GET_INVOICE_REPORTS_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}

function getInvoiceAllReports (params, cb = () => { }) {
    return (dispatch) => {
        dispatch(request(invoiceConstant.GET_INVOICE_ALL_REPORTS_R, ""));
        dispatch(request(loaderConstant.LOADING_TRUE));
        invoiceService.getInvoiceAllReports(params).then(
            (data) => {
                dispatch(success(invoiceConstant.GET_INVOICE_ALL_REPORTS_S, data.data));
                dispatch(request(loaderConstant.LOADING_FALSE));
                cb();
            },
            (error) => {
                dispatch(failure(invoiceConstant.GET_INVOICE_ALL_REPORTS_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        );
    };
}


//create invoice from public invoice 
function createInvoiceFromPublicInvoice(params, cb=()=>{}){
    return(dispatch) =>{
        dispatch(request(invoiceConstant.CREATE_INVOICE_FROM_PUBLIC_INVOICE_R,''));
        dispatch(request(loaderConstant.LOADING_TRUE));
        invoiceService.createInvoiceFromPublic(params).then(data => {
            dispatch(success(invoiceConstant.CREATE_INVOICE_FROM_PUBLIC_INVOICE_S))
            dispatch(request(loaderConstant.LOADING_FALSE));
            cb(data.data)
        },error => {
            dispatch(failure(invoiceConstant.CREATE_INVOICE_FROM_PUBLIC_INVOICE_F ,error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        })
    }
}

/** get Invoice Summary */
function getPublicInvoiceSummary(params, cb = () => {}) {
  return (dispatch) => {
    invoiceService.getPublicInvoiceSummary(params).then(
      (data) => {
        if(!params?.preserveData){
          dispatch(request(invoiceConstant.GET_PUBLIC_INVOICE_SUMMARY_R, ""));
        }
        dispatch(
          success(invoiceConstant.GET_PUBLIC_INVOICE_SUMMARY_S, { ...data.data, params })
        );
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb({...data.data});
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_PUBLIC_INVOICE_SUMMARY_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/* get public invoice data */

function getPublicInvoiceData(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.GET_PUBLIC_INVOICE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    invoiceService.getPublicInvoiceData(params).then(
      (data) => {
        if (data && data.data && data.data.form_status) {
          dispatch(success(invoiceConstant.GET_PUBLIC_INVOICE_S, data.data));
          dispatch(request(loaderConstant.LOADING_FALSE));
        } else {
          dispatch(failure(alertConstant.ALERT_ERROR, "Invalid parameter"));
          dispatch(request(loaderConstant.LOADING_FALSE));
          history.push(`/invoices`);
        }
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_PUBLIC_INVOICE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * get invoice timeline
 */

function getInvoiceTimeline(params, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(invoiceConstant.GET_INVOICE_TIMELINE_R, params));
    invoiceService.getInvoiceTimeline(params).then(
      (data) => {
        if (data.data) {
          dispatch(success(invoiceConstant.GET_INVOICE_TIMELINE_S, data.data));
        }
        cb();
      },
      (error) => {
        dispatch(failure(invoiceConstant.GET_INVOICE_TIMELINE_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/**
 * update invoice payment status
 */

function updatePaymentStatus(updateData) {
  return (dispatch, getState) => {
    let invoiceSummary = getState()?.invoice.invoiceSummary;
    let { invoiceId, status,invoice_status } = updateData;
    const { data } = invoiceSummary;
    let filteredData = data.filter((item) => item.invoice_id === invoiceId);
    if (filteredData.length > 0) {
      let invoiceSummaryData = data.map((item) => {
        if (item.invoice_id === invoiceId) {
          return { ...item, inv__status: status,invoice_status:invoice_status };
        }
        return { ...item };
      });
      dispatch(
        success(invoiceConstant.UPDATE_PAYMENT_STATUS_S, invoiceSummaryData)
      );
    }
  };
}

/*
create new client using invoice functions
**/

function createNewClient(params,cb = () => {}) {
  
  return (dispatch, getState) => {
    // dispatch(request(invoiceConstant.ADD_NEW_CLIENT_R, ""));
    dispatch(request(loaderConstant.LOADING_TRUE));

    invoiceService.createNewClient(params)
      .then((addNewClient) => {
       
        dispatch(success(invoiceConstant.ADD_NEW_CLIENT_S, addNewClient));
        dispatch(request(loaderConstant.LOADING_FALSE));
        const response = addNewClient.data   
        dispatch(success(alertConstant.ALERT_SUCCESS, response.message));
        cb()
    
      })
      .catch((error) => {
        // Dispatch any failure action or handle error appropriately
        dispatch(failure(invoiceConstant.ADD_NEW_CLIENT_F, error))
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(alertAction.error,error.data.message))
      });
    
  }; 
}

function updateClientCustomer(params,cb = () => {}){
  return(dispatch,getState)=>{
    dispatch(request(loaderConstant.LOADING_TRUE));
dispatch(request(invoiceConstant.UPDATE_CLIENT_CUSTOMER_R))
invoiceService.updateClientCustomer(params).then((response)=>{
        dispatch(success(invoiceConstant.UPDATE_CLIENT_CUSTOMER_S))
        dispatch(request(loaderConstant.LOADING_FALSE)); 
        dispatch(success(alertConstant.ALERT_SUCCESS, response?.data?.message));
        cb()
  
}).catch((error)=>{
  // Dispatch any failure action or handle error appropriately
  dispatch(failure(invoiceConstant.UPDATE_CLIENT_CUSTOMER_F, error))
  dispatch(request(loaderConstant.LOADING_FALSE));
  dispatch(failure(alertAction.error,error.data.message))
})
  }
}

/* 
delete client customer
*/

function deleteClientCustomer (params,cb=()=>{}){
  return(dispatch,getState)=>{
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(invoiceConstant.DELETE_CLIENT_CUSTOMER_R));
     invoiceService.deleteClientCustomer(params).then((response)=>{
      dispatch(success(invoiceConstant.DELETE_CLIENT_CUSTOMER_S))
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(alertConstant.ALERT_SUCCESS, response.data.message));
      cb()

     }).catch((err)=>{
      dispatch(failure(invoiceConstant.DELETE_CLIENT_CUSTOMER_F, err))
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(alertAction.error,err.data.message))
     })
  }
}

function getPaymentsReports (params, cb = () => { }) {
  return (dispatch) => {
      dispatch(request(invoiceConstant.GET_PAYMENT_REPORTS_R, ""));
      dispatch(request(loaderConstant.LOADING_TRUE));
      invoiceService.getPaymentsReports(params).then(
          (data) => {
              dispatch(success(invoiceConstant.GET_PAYMENT_REPORTS_S, data.data));
              dispatch(request(loaderConstant.LOADING_FALSE));
              cb();
          },
          (error) => {
              dispatch(failure(invoiceConstant.GET_PAYMENT_REPORTS_F, error));
              dispatch(failure(alertConstant.ALERT_ERROR, error));
              dispatch(request(loaderConstant.LOADING_FALSE));
          }
      );
  };
}
/**
 * getting invoice notification 
 */

function getInvoiceNewNotification (params,cb=()=>{}){
  return(dispatch,getState)=>{
    dispatch(request(invoiceConstant.GET_NOTIFICATION_R));
     invoiceService.getInvoiceNewNotification(params).then((data)=>{
      dispatch(success(invoiceConstant.GET_NOTIFICATION_S, data))
      cb()
     }).catch((err)=>{
      dispatch(failure(invoiceConstant.GET_NOTIFICATION_F, err))
      dispatch(failure(alertAction.error,err.data.message))
     })
  }
}

// remove notification
function removeNotification(data){
  return (dispatch) => {
    dispatch(success(invoiceConstant.REMOVE_NOTIFICATIONS_S, data))
  }
}

 /*
 * refund single Invoice
 */

function refundSingleInvoice (params,cb=()=>{}){
  return(dispatch)=>{
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(invoiceConstant.INVOICE_REFUND_R));
     invoiceService.refundSingleInvoice(params).then((data)=>{
      dispatch(updatePaymentStatus(data.data))
      dispatch(success(invoiceConstant.INVOICE_REFUND_S))
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.data.message));
      cb()
     }).catch((err)=>{
      dispatch(failure(invoiceConstant.INVOICE_REFUND_F, err))
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(alertAction.error,err.data.message))
     })
  }
}

function getcurrencyReports (params, cb = () => { }) {
  return (dispatch) => {
      dispatch(request(invoiceConstant.GET_CURRENCY_REPORTS_R, ""));
      dispatch(request(loaderConstant.LOADING_TRUE));
      invoiceService.getcurrencyReports(params).then(
          (data) => {
              dispatch(success(invoiceConstant.GET_CURRENCY_REPORTS_S, data.data));
              dispatch(request(loaderConstant.LOADING_FALSE));
              cb();
          },
          (error) => {
              dispatch(failure(invoiceConstant.GET_CURRENCY_REPORTS_F, error));
              dispatch(failure(alertConstant.ALERT_ERROR, error));
              dispatch(request(loaderConstant.LOADING_FALSE));
          }
      );
  };
}
