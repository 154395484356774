import React, { useState, useMemo, useCallback, useRef } from "react";
import PropTypes from "prop-types";
import { isMobile } from 'react-device-detect';
import { useHotkeys } from 'react-hotkeys-hook';
//Mui Imports
import { Grid, IconButton, Popover } from "@material-ui/core";

//Draft-js Imports
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import { EditorState, RichUtils, convertToRaw, getDefaultKeyBinding, KeyBindingUtil } from 'draft-js';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter, defaultTheme as mentionDefaultTheme } from '@draft-js-plugins/mention';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';

//Import Styles
// import style from './style';

//Import Svg
import { ChatGifNew, ChatMicIcon, ChatEmojiIcon } from '../../assets/svg';

//Import Component
import { OutsideClickHandler } from '../../hoc';
import GiphyComponent from '../GiphyComponent';
import EntryComponent from "./plugins/EntryComponent";

import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';

import useCapacitorKeyboard from "../../hooks/keyboard_listener";

function MediaInput(props) {

    //props
    const { classes,
        className,
        editorState,
        onChange,
        placeholder,
        editorClassName,
        handleAttachment,
        handleChatGif,
        userSuggestions,
        attachmentClassName,
        mentionsClassName,
        setEditorState,
        audio,
        gif,
        onsend,
        emoji,
        showSendIcon,
        hideAttachment
    } = props;

    //State
    const [recordingStatus, setRecordingStatus] = useState(false);
    const [gifOpen, setGifOpen] = useState(false);
    const [suggestions, setSuggestions] = useState(userSuggestions);
    const [open, setOpen] = useState(false);
    const [allMentions, setAllMentions] = useState([]);
    const [emojiSelectOpen, setEmojiSelectOpen] = useState(false)
    const [rawContent, setRawContent] = useState('')
    const editorRef = useRef(null);

    const isKeyboardVisible = useCapacitorKeyboard();

    //hotkeys 
    useHotkeys('ctrl+m', () => {
        editorRef.current.focus()
    })

    // emoji plugin
    const emojiTheme = {
        ...defaultTheme,
        emoji: 'chatEmoji',
        emojiSuggestions: `${defaultTheme.emojiSuggestions} chatEmojiSuggestions`,
        emojiSuggestionsEntry: `${defaultTheme.emojiSuggestionsEntry} chatEmojiSuggestionsEntry`,
        emojiSelect: `${defaultTheme.emojiSelect} chatEmojiSelect ml-1 floatOption`,
        emojiSelectButton: `${defaultTheme.emojiSelectButton} chatEmojiSelectButton MuiButtonBase-root MuiIconButton-root`,
        emojiSelectButtonPressed: `${defaultTheme.emojiSelectButtonPressed} chatEmojiSelectButtonPressed MuiButtonBase-root MuiIconButton-root`,
        emojiSelectPopover: `${defaultTheme.emojiSelectPopover} chatEmojiSelectPopover`,
        emojiSelectPopoverClosed: `${defaultTheme.emojiSelectPopoverClosed} chatEmojiSelectPopoverClosed`,
    }

    const emojiPlugin = createEmojiPlugin({
        imagePath: 'http://cdn.jsdelivr.net/emojione/assets/svg/',
        theme: emojiTheme,
        selectButtonContent: <div id="myChickooo"><ChatEmojiIcon /></div>,
    });

    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;


    //mention plugin
    const mentionTheme = {
        ...mentionDefaultTheme,
        mention: 'chatMention',
        mentionSuggestions: 'chatMentionsSuggestions',
        mentionSuggestionsEntry: 'chatMentionSuggestionsEntry',
        mentionSuggestionsEntryFocused: 'chatMentionSuggestionsEntryFocused',
        mentionSuggestionsEntryText: 'chatMentionSuggestionsEntryText',
        mentionSuggestionsEntryAvatar: 'chatMentionSuggestionsEntryAvatar',
        mentionSuggestionsEntryContainer: 'chatMentionSuggestionsEntryContainer',
        mentionSuggestionsEntryContainerLeft: 'chatMentionSuggestionsEntryContainerLeft',
        mentionSuggestionsEntryContainerRight: 'chatMentionSuggestionsEntryContainerRight'
    }


    const { MentionSuggestions, plugins } = useMemo(() => {
        const mentionPlugin = createMentionPlugin({
            theme: mentionTheme,
            supportWhitespace: true,
            mentionPrefix: '@',
            positionSuggestions: (settings) => {

                return {
                    left: settings.decoratorRect.left + 'px',
                    top: settings.decoratorRect.top - 40 + 'px', // change this value (40) for manage the distance between cursor and bottom edge of popover
                    display: 'block',
                    transform: 'scale(1) translateY(-100%)', // transition popover on the value of its height
                    transformOrigin: '1em 0% 0px',
                    transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)',
                    position: 'fixed',
                    maxWidth: 'max-content',
                    minWidth: 200
                };
            },
        });
        const { MentionSuggestions } = mentionPlugin;
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    }, []);

    async function getMedia() {
        try {
            if (isMobile) {
                // setRecordingStatus(true);
            } else {
                const audioMedia = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
                /* use the stream */
                if (audioMedia.active && audioMedia.id) {

                    // setAudioDevice(true)
                    // setAudioPermission(true)
                    // setRecordingStatus(true);
                } else {

                    // setAudioDevice(true)
                    // setAudioPermission(false)
                }
            }
        } catch (err) {
            // setAudioDevice(false)
            // setAudioDeviceWarning(true)
        }
    }

    const keyBindingFn = (e) => {
        e.stopPropagation();
        if (KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 66) { return 'bBold'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 85) { return 'uLine'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 73) { return 'iItalic'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 55) { return 'oLIST'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 56) { return 'uLIST'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 57) { return 'cBLOCK'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 48) { return 'bQUOTE'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 49) { return 'hONE'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 50) { return 'hTWO'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 51) { return 'hTHREE'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 52) { return 'hFOUR'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 53) { return 'hFIVE'; }
        else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 54) { return 'hSIX'; }
        else if (e.keyCode === 13) {
            if (e.nativeEvent.shiftKey) {
                return "shift-enter"
            } else {
                if (isKeyboardVisible || isMobile) {
                    return "split-block"
                }
                return "handle-submit"
            }
        }

        return getDefaultKeyBinding(e);
    }

    const handleKeyCommand = (command) => {
        let newState;
        if (command === 'bBold') {
            newState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
        }
        else if (command === 'uLine') {
            newState = RichUtils.toggleInlineStyle(editorState, 'UNDERLINE');
        }
        else if (command === 'iItalic') {
            newState = RichUtils.toggleInlineStyle(editorState, 'ITALIC');
        }
        else if (command === 'oLIST') {
            newState = RichUtils.toggleBlockType(editorState, 'ordered-list-item');
        }
        else if (command === 'uLIST') {
            newState = RichUtils.toggleBlockType(editorState, 'unordered-list-item');
        }
        else if (command === 'cBLOCK') {
            newState = RichUtils.toggleBlockType(editorState, 'code-block');
        }
        else if (command === 'bQUOTE') {
            newState = RichUtils.toggleBlockType(editorState, 'blockquote');
        }
        else if (command === 'hONE') {
            newState = RichUtils.toggleBlockType(editorState, 'header-one');
        }
        else if (command === 'hTWO') {
            newState = RichUtils.toggleBlockType(editorState, 'header-two');
        }
        else if (command === 'hTHREE') {
            newState = RichUtils.toggleBlockType(editorState, 'header-three');
        }
        else if (command === 'hFOUR') {
            newState = RichUtils.toggleBlockType(editorState, 'header-four');
        }
        else if (command === 'hFIVE') {
            newState = RichUtils.toggleBlockType(editorState, 'header-five');
        }
        else if (command === 'hSIX') {
            newState = RichUtils.toggleBlockType(editorState, 'header-six');
        }
        else if (command === 'shift-enter') {
            newState = RichUtils.insertSoftNewline(editorState);
        } else if (command === "handle-submit") {
            // localStorage.setItem("id", "")
            handleSend()
        }

        if (newState) {
            onChange(newState);
            return 'handled';
        }

        return 'not-handled';
    }

    // const onChange = useCallback((_editorState) => {
    //     setEditorState(_editorState);
    // }, []);

    const onOpenChange = useCallback((_open) => {
        setOpen(_open);
    }, []);

    const handleClose = () => {
        setGifOpen(false)
    };

    const onSearchChange = useCallback(({ value }) => {
        setSuggestions(defaultSuggestionsFilter(value, userSuggestions));
    }, [userSuggestions]);

    const onGifSelect = (data) => {
        handleChatGif(data)
        setGifOpen(false)
    }

    const onAddMention = (val) => {
        setAllMentions([...allMentions, val])
    };

    const styleMap = {
        CODE: {
            backgroundColor: '#111',
            color: '#fff',
            fontSize: '1.5rem',
            padding: '2px',
        },
    };
    const hashtagPlugin = createHashtagPlugin({
        theme: {
            hashtag: 'chatHashTag'
        }
    });
    const editorPlugins = [emojiPlugin, ...plugins, hashtagPlugin]

    /** Editor on change function */

    const EditoronChange = async (newEditorState) => {
        const rawContent = await convertToRaw(newEditorState.getCurrentContent());
        const blockContent = rawContent.blocks;
        const chatMsgText = await blockContent.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
        const currentContentTextLength = editorState?.getCurrentContent().getPlainText().length;
        const newContentTextLength = newEditorState.getCurrentContent().getPlainText().length;

        if (((currentContentTextLength > 0 || newContentTextLength > 0) && newEditorState._immutable._map.size == 10 && currentContentTextLength === 0) || (currentContentTextLength === 0 && newContentTextLength !== 0)) {
            // WORKAROUND: listens to input changes and focuses/moves cursor to back after typing in first character
            setEditorState(EditorState.moveFocusToEnd(newEditorState));
        } else {
            if (currentContentTextLength > 0 || chatMsgText == '@' || (chatMsgText == '\n')) {
                setEditorState(newEditorState);
            }
        }
        setRawContent(rawContent)
    };

    /** send function */
    const handleSend = () => {
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        const MsgBlocks = blocks.map(block => (!block.text.trim() && '\n') || block.text);
        const MsgText = MsgBlocks.length > 0 && MsgBlocks[0] !== "\n" ? MsgBlocks.join('\n') : "";
        onsend(MsgText, rawContent)
        setEditorState(createEditorStateWithText(""));
    }


    return (
        <>
            <Grid className={`${className}`}>
                <Grid className={editorClassName}>
                    <Editor
                        customStyleMap={styleMap}
                        editorState={editorState || createEditorStateWithText("")}
                        onChange={EditoronChange}
                        placeholder={placeholder}
                        spellCheck={true}
                        plugins={[...editorPlugins]}
                        keyBindingFn={!open && keyBindingFn}
                        handleKeyCommand={handleKeyCommand}
                        ref={editorRef}
                    />

                    <EmojiSuggestions className="emojiToggle" />

                    <MentionSuggestions
                        className={mentionsClassName}
                        open={open}
                        suggestions={suggestions}
                        onOpenChange={onOpenChange}
                        onSearchChange={onSearchChange}
                        onAddMention={onAddMention}
                        entryComponent={EntryComponent}
                    />
                </Grid>

                <Grid className={attachmentClassName}>
                    {hideAttachment ? null :
                        <OutsideClickHandler>
                            <IconButton onClick={() => { }} variant="contained" component="label" className="floatOption ml-1 p0" >
                                <AttachFileIcon className={"attachmentStyle"}/>
                                <input
                                    type="file"
                                    id="notesAttachment"
                                    name="notesAttachments[]"
                                    onChange={(e) => { handleAttachment(e) }}
                                    style={{ display: 'none' }}
                                    value={""}
                                    multiple
                                />
                            </IconButton>
                        </OutsideClickHandler>
                    }


                    {showSendIcon &&
                        <IconButton onClick={() => handleSend()} variant="contained" component="label" className="floatOption ml-1 p0" >
                            <SendIcon className={"attachmentStyle"}/>
                        </IconButton>}


                    {audio &&
                        <IconButton onClick={() => getMedia()} className="floatOption ml-1 p0">
                            <ChatMicIcon />
                            {recordingStatus && <span className={classes.recording}></span>}
                        </IconButton>
                    }

                    {emoji &&
                        <OutsideClickHandler >
                            <EmojiSelect
                                onOpen={() => setEmojiSelectOpen(true)}
                                onClose={() => setEmojiSelectOpen(false)}
                                className="emojiIcon floatOption ml-1"
                            />
                        </OutsideClickHandler>}

                    {/* Gif Container Start */}
                    <>
                        {gif &&
                            <IconButton onClick={() => setGifOpen(!gifOpen)} className="floatOption gif ml-1 p0"><ChatGifNew /></IconButton>}
                        <Grid>
                            <Popover
                                id={"gif"}
                                className={''}
                                open={gifOpen}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                            >
                                <GiphyComponent
                                    handleSelect={onGifSelect}
                                />
                            </Popover>
                        </Grid>
                    </>
                    {/* Gif Container  End */}

                </Grid>
                {/* <Attachment
                classes={classes}
                id={0}
                type={type}
                userId={userId}
                open={openAttachment !== null}
                anchorEl={openAttachment}
                onClose={handleAttachment}
                onUpload={onUpload}
                isUploading={isUploading}
                gifSelected={gifSelected}
                isDnD={isDnD}
                onMenuClick={onMenuClick}
                handleUploadFiles={handleUploadFiles}
                dragFiles={dragFiles}
                setDragFiles={setDragFiles}
                setOpenUpload={setOpenUpload}
            /> */}

            </Grid>
        </>
    )
}


// default props
MediaInput.defaultProps = {
    handleAttachment: () => { },
    handleChatGif: () => { },
    placeHolder: "Start Typing...",
    audio: true,
    gif: true,
    emoji: true
};

// prop types
MediaInput.propTypes = {
    handleAttachment: PropTypes.func,
    handleChatGif: PropTypes.func,
    placeHolder: PropTypes.string,
    audio: PropTypes.bool,
    gif: PropTypes.bool,
    emoji: PropTypes.bool
};

export const MediaInputComponent = (MediaInput)