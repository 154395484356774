import { makeStyles } from "@material-ui/core"

const customButtonStyles = makeStyles((theme) => ({
    primaryButton: {
        background: theme.palette.text.blueText,
        color: theme.palette.text.contrastText,
        fontWeight: 700,
        boxShadow: "0px 4px 10px 0px #00000026",
        borderRadius: "8px",
        fontSize: "14px",
        '& .MuiButton-label':{
            color: `${theme.palette.text.contrastText} !important`,
        },
        "&:hover": {
            background: theme.palette.text.blueText,
            color: theme.palette.text.contrastText,
            fontWeight: 700,
        },
        "&:active": {
            background: '#81A5F3'
        },
    },
    secondaryButton: {
        background: theme.palette.containers.arrowBg,
        color: theme.palette.text.blueText,
        border: `1px solid ${theme.palette.text.blueText}`,
        fontWeight: 600,
        "&:hover": {
            background: 'rgba(37, 99, 235, 0.14)',
            color: theme.palette.text.blueText,
            border: `1px solid ${theme.palette.text.blueText}`,
            fontWeight: 600,
        },
        "&:active": {
            background: 'rgba(233, 243, 254, 1)',
            border: `1px solid ${theme.palette.text.blueText}`,
        },
    },
    secondaryTwoButton: {
        background: theme.palette.containers.body,
        color: theme.palette.text.secondary,
        border: `1px solid ${theme.palette.primary.secondaryTwoBtn}`,
        fontWeight: 600,
        "&:hover": {
            background: 'rgba(37, 99, 235, 0.14)',
            color: theme.palette.text.secondary,
            border: `1px solid ${theme.palette.text.secondary}`,
            fontWeight: 600,
        },
        "&:active": {
            color: theme.palette.text.blueText,
            background: 'rgba(233, 243, 254, 1)',
            border: `1px solid ${theme.palette.text.blueText}`,
        },
    },
    mediumSizeButton: {
        height: '36px'
    },
    smallSizeButton: {
        height: '32px'
    },
}));

export default customButtonStyles