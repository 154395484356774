import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { videoCallAction } from "../../redux/actions/videoCallAction";
import Page404 from "../ErrorPage/Page404/Page404";
import { PageLoader } from "../../components";
import RecordingRoom from './VCRoom/recordingRoom';

function RecordingRoomRoute(props) {
    const { match } = props;
    const dispatch = useDispatch();
    const channelData = useSelector((state) => state.videocall.channelData);
    const [isLoading, setIsLoading] = useState(true);

    // Removing create room as this should be done only inside calander
    // useEffect(() => {
    //     dispatch(videoCallAction.createFlozyRoom({ channel_name: match.params.meetingId }));
    // }, [dispatch, match.params.meetingId]);

    useEffect(() => {

        if (channelData) {
            setIsLoading(false);
        }
    }, [channelData]);

    // if (isLoading) {
    //     return <PageLoader isLoading={true} />;
    // }

    // if (channelData?.is_deleted === 1) {
    //     return <Page404 />;
    // } else if (channelData) {
    //     return <RecordingRoom match={match} />;
    // } else {
    //     return <PageLoader isLoading={true} />;
    
    // }

    return (
<>

<RecordingRoom match={match} />
</>

    )
    
}


export default RecordingRoomRoute;
