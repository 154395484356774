import { postRequest, putRequest, deleteRequest, getRequest } from "../../utils";


async function saveCallHistory(body){
    const response = await postRequest(`videocall/save`, body)
    return response
}

async function getHistory(body){
    const response = await postRequest( `videocall/list`, body)
    return response
}


async function updateHistory(id, body){
    const response = await putRequest(`videocall/update/${id}`, body)
    return response
}

async function callInitalize(id, body){
    const response = await putRequest(`videocall/call/${id}`, body)
    console.log("🚀 ~ deleteHistory ~ id:", id)
    return response
}

async function callEnded(id, body){
    const response = await putRequest(`videocall/callEnd/${id}`, body)
    console.log("🚀 ~ deleteHistory ~ id:", id)
    return response
}

async function createFlozyRoom(body){
    const response = await postRequest(`videocall/create`, body)
    return response
}

async function deleteFlozyRoom(body){
    const response = await putRequest(`videocall/delete`, body)
    return response
}

async function getChannelStatus(params){
    const response = await getRequest(`videocall/channelStatus/${params}`)
    return response
}
async function getRecordingAcquire(body){
    const response = await postRequest(`videocall/acquire`,body)
    return response.data
}
async function startRecording(body){
    const response = await postRequest(`videocall/start`,body)
    return response.data
}
async function stopRecording(body){
    const response = await postRequest(`videocall/stop`,body)
    return response.data
}

async function addPublicUser(body){
    const response = await postRequest(`videocall/addPublicUser`, body)
    return response
}

async function deleteUser(body){
    const response = await deleteRequest(`videocall/deleteUser`, body)
    return response
}

async function checkUsers(body) {
    const response = await postRequest(`videocall/checkUsers`, body)
    return response
}

async function checkFlozyRoom(body) {
    const response = await postRequest(`videocall/checkRoom`, body)
    return response
}

export const videoCallService = {
    saveCallHistory,
    getHistory,
    updateHistory,
    callInitalize,
    callEnded,
    createFlozyRoom,
    deleteFlozyRoom,
    getChannelStatus,
    getRecordingAcquire,
    startRecording,
    stopRecording,
    addPublicUser,
    deleteUser,
    checkUsers,
    checkFlozyRoom
}