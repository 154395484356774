const SkipButtonStyles = (theme) => ({
  skipButtonContainer: {
    position: "absolute",
    top: "12px",
    right: "12px",
    zIndex: 1000
  },
  skipButton: {
    color: "#333",
    fontSize: "14px",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    border: "1px solid rgba(255, 255, 255, 0.5)",
    padding: "5px 10px",
    borderRadius: "25px",
    fontFamily: "Arial, sans-serif",
    "&:hover": {
      color: "#fff",
      border: "1px solid rgba(255, 255, 255, 0.5)",
    }
  }
})

export default SkipButtonStyles;