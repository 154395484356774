const Style = (theme) => ({
    dialogWidth: {
        '& .MuiDialog-paperWidthSm': {
        maxWidth: '752px'
        }
    },
    titleContainer:{
        display: "flex",
        justifyContent: "flex-end",
        paddingBottom: 0,
        "& svg": {
            width: "20px"
        },
    },
    urlContainer: {
        "& .MuiInputBase-root":{
            fontWeight: 500,
            fontSize: "16px",
            "& input":{
                maxWidth: '450px',
                paddingRight: '23px',
                textOverflow: 'ellipsis',
                fontSize: '16px'
            },
        },
        "& .MuiOutlinedInput-root": {
            backgroundColor: '#EBF1FF',
            height: '50px'
        },
        "& .MuiOutlinedInput-adornedEnd": {
            padding: "8px 12px"
        },
        '& legend': {
            lineHeight: '0'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.border1,
            top: 0
        },
    },
    dialogActions:{
        padding: "0px 65px 24px 65px"
    }
})

export default Style;