// Import API Helper
import { postRequest, getRequest } from "../../utils/apiHelper";

export const userAgencyService = {
    getUserAgencies,
    updateUserAgencies,
    updateAgencyData,
    updatePayment,
    removePaymentData,

    //billls
    getPlanDetails,
    getPaymentHistory,
    getInvoiceDetails,
    nextInvoiceDate,
    dowloadInvoice,
    getAutoPay,
    updateAutoPay,
    updatePlan,
    getCardDetails,
    getMybillsUserInfo,
    updateCardDetails,
    getAgencyNameAndLogo,

    getFeedbackForm,
    cancelAgencyWithFeedback,
    updateToSoloPlan,
    payUnpaidInvoices,
    getAgencyUserLimits,
    getAllPlans,
    upgradePlan,
    createSubscriptionOnError,

    getAllAvailablePlans,

    planList,
    getPlanMembers,
    getPlanAddOnsList,
    updatePlanNew
};

//create new profile
async function getUserAgencies(params) {
    const response = await postRequest("userAgency/get", params);
    return response;
}

//create new profile
async function updateUserAgencies(params) {
    const response = await postRequest("userAgency/update", params);
    return response;
}

//updateSignUpUserData
async function updateAgencyData(params) {
    const response = await postRequest("userAgency/newAgency", params);
    return response;
}

//update paymemt
async function updatePayment(params) {
    const response = await postRequest("userAgency/updatePayment", params);
    return response;
}

/****************************************************************MY BILLS************************************************************************************** */

//getPlanDetails
async function getPlanDetails(params) {
    const response = await postRequest("userAgency/getPlanDetails", params);
    return response;
}

//getPaymentHistory
async function getPaymentHistory(params) {
    const response = await postRequest("userAgency/getPaymentHistory", params);
    return response;
}

//getInvoiceDetails
async function getInvoiceDetails(params) {
    const response = await postRequest("userAgency/getInvoiceDetails", params);
    return response;
}

//nextInvoiceDate
async function nextInvoiceDate(params) {
    const response = await postRequest("userAgency/nextInvoiceDate", params);
    return response;
}

//update paymemt
async function dowloadInvoice(params) {
    const response = await postRequest("userAgency/dowloadInvoice", params);
    return response;
}

//getAutoPay
async function getAutoPay(params) {
    const response = await postRequest("userAgency/getAutoPay", params);
    return response;
}

//update Auto pay
async function updateAutoPay(params) {
    const response = await postRequest("userAgency/updateAutoPay", params);
    return response;
}

//updatePlan
async function updatePlan(params) {
    const response = await postRequest("userAgency/updatePlan", params);
    return response;
}

//getCardDetails
async function getCardDetails(params) {
    const response = await postRequest("userAgency/getCard", params);
    return response;
}

//getMybillsUserInfo
async function getMybillsUserInfo(params) {
    const response = await postRequest("userAgency/getMybillsUserInfo", params);
    return response;
}

//updateCardDetails
async function updateCardDetails(params) {
    const response = await postRequest("userAgency/updateCard", params);
    return response;
}

//removePaymentData
async function removePaymentData(params) {
    const response = await postRequest("userAgency/removePaymentData", params);
    return response;
}

//getAgencyNameAndLogo
async function getAgencyNameAndLogo(params) {
    const response = await postRequest("userAgency/getAgencyNameAndLogo", params);
    return response;
}

// getFeedbackForm
async function getFeedbackForm() {
    const response = await getRequest("userAgency/getFeedbackForm");
    return response;
}

// cancelAgencyWithFeedback
async function cancelAgencyWithFeedback(params) {
    const response = await postRequest("userAgency/cancelAgency", params);
    return response;
}

// update to solo plan
async function updateToSoloPlan(params) {
    const response = await postRequest("userAgency/updateToSolo", params);
    return response;
}

//payUnpaidInvoices
async function payUnpaidInvoices(params) {
    const response = await postRequest("userAgency/payUnpaidInvoice", params);
    return response;
}

// getAgencyUserLimits
async function getAgencyUserLimits(params) {
    const response = await postRequest("userAgency/userLimits", params);
    return response;
}

// getAllPlans
async function getAllPlans(params) {
    const response = await postRequest("userAgency/plans", params);
    return response;
}

// getAllPlans
async function getAllAvailablePlans(params) {
    const response = await postRequest("userAgency/availablePlans", params);
    return response;
}

// upgradePlan
async function upgradePlan(params) {
    const response = await postRequest("userAgency/upgradePlan", params);
    return response;
}

// createSubscriptionOnError
async function createSubscriptionOnError(params) {
    const response = await postRequest("userAgency/createSubscriptionOnError", params);
    return response;
}

// get all plans latest
async function planList(params) {
    const response = await postRequest("userAgency/planList", params);
    return response;
}

// get all plans members
async function getPlanMembers(params) {
    const response = await postRequest("userAgency/planMembers", params);
    return response;
}

// get all plans add on
async function getPlanAddOnsList(params) {
    const response = await postRequest("userAgency/addOn", params);
    return response;
}

// update user plan
async function updatePlanNew(params) {
    const response = await postRequest("userAgency/updatePlanNew", params);
    return response;
}