/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TextareaAutosize as Core } from '@material-ui/core';

// Imports Styles
import styles from "./styles";
/**
 * TextareaAutosize Component
 * @param {*} props
 */
function TextAreaComponent({ id, name, placeholder, autoComplete, margin, min, variant, fullWidth,withImage,imageType, passShow, ...rest }) {
 
 
  return (
    <Core
      id={id}
      placeholder={placeholder}
      name={name}
      {...rest}
    />
  );
}

// default props
TextAreaComponent.defaultProps = {
  id: "",
  name: "",
  type: "text",
  autoComplete: "off",
  min: "0",
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  withImage:false,
  passShow: false,
};

// prop types
TextAreaComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  min: PropTypes.string,
  autoComplete: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  withImage: PropTypes.bool,
  passShow: PropTypes.bool
};

// export component
export const TextareaAutosize = withStyles(styles)(TextAreaComponent);
