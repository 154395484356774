export const workspaceConstant = {
    GET_WORKSPACES_R: "GET_WORKSPACES_R",
    GET_WORKSPACES_S: "GET_WORKSPACES_S",
    GET_WORKSPACES_F: "GET_WORKSPACES_F",

    SEARCH_AGENCY_R: "SEARCH_AGENCY_R",
    SEARCH_AGENCY_S: "SEARCH_AGENCY_S",
    SEARCH_AGENCY_F: "SEARCH_AGENCY_F",
    SEARCH_AGENCY_RESET: "SEARCH_AGENCY_RESET",
    SEARCH_AGENCY_REMOVE: "SEARCH_AGENCY_REMOVE",

    INVITE_AGENCY_R: "INVITE_AGENCY_R",
    INVITE_AGENCY_S: "INVITE_AGENCY_S",
    INVITE_AGENCY_F: "INVITE_AGENCY_F",
    GET_SHARED_RESOURCES_R: "GET_SHARED_RESOURCES_R",
    GET_SHARED_RESOURCES_S: "GET_SHARED_RESOURCES_S",
    GET_SHARED_RESOURCES_F: "GET_SHARED_RESOURCES_F",

    ACCEPT_SHARE_INVITE_R: "ACCEPT_SHARE_INVITE_R",
    ACCEPT_SHARE_INVITE_S: "ACCEPT_SHARE_INVITE_S",
    ACCEPT_SHARE_INVITE_F: "ACCEPT_SHARE_INVITE_F",

    REJECT_SHARE_INVITE_R: "REJECT_SHARE_INVITE_R",
    REJECT_SHARE_INVITE_S: "REJECT_SHARE_INVITE_S",
    REJECT_SHARE_INVITE_F: "REJECT_SHARE_INVITE_F",

    GET_SHARE_INVITE_S:'GET_SHARE_INVITE_S',

    UPDATE_SHARING_FLOW_R: "UPDATE_SHARING_FLOW_R",
    UPDATE_SHARING_FLOW_S: "UPDATE_SHARING_FLOW_S",
    UPDATE_SHARING_FLOW_F: "UPDATE_SHARING_FLOW_F",
    
    STOP_SHARING_FLOW_R : "STOP_SHARING_FLOW_R",
    STOP_SHARING_FLOW_S : "STOP_SHARING_FLOW_S",
    STOP_SHARING_FLOW_F : "STOP_SHARING_FLOW_F",

    GET_SHARED_USERS_R : 'GET_SHARED_USERS_R',
    GET_SHARED_USERS_S : 'GET_SHARED_USERS_S',
    GET_SHARED_USERS_F : 'GET_SHARED_USERS_F',

    CANCEL_SHARE_INVITE_R: "CANCEL_SHARE_INVITE_R",
    CANCEL_SHARE_INVITE_S: "CANCEL_SHARE_INVITE_S",
    CANCEL_SHARE_INVITE_F: "CANCEL_SHARE_INVITE_F",
};
