const styles = (theme) => ({
    received: {
        "& .player": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 0",
            backgroundColor: "transparent",
            minWidth: "210px"
        },
        "& .player .song": { userSelect: "none", margin: "0 20px" },
        "& .player .song .song__title": {
            textAlign: "left",
            margin: "0",
            color: "#aaa",
            fontFamily: '"Permanent Marker", cursive',
            fontWeight: "normal",
            fontSize: "3.5em"
        },
        "& .player .song .song__title:hover": { color: "#3182FF" },
        "& .player .song .song__artist": {
            margin: "0",
            color: "#aaa",
            fontFamily: '"Rock Salt", cursive',
            fontWeight: "normal",
            fontSize: "1em"
        },
        "& .player .song .song__artist:hover": { color: "#3182FF", cursor: "pointer" },
        "& .player .controls": {
            flexGrow: 1,
            margin: "0px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        },
        "& .player .player__button": {
            width: "fit-content",
            backgroundColor: "transparent",
            border: "none"
        },
        "& .player .player__button:focus": { outline: "none" },
        "& .player .player__button:hover": { cursor: "pointer" },
        "& .player .player__button:hover svg": { color: "#3182FF" },
        "& .player .player__button svg": { fontSize: "2.5em", color: "#3182FF", marginRight: '8px' },
        "& .player .bar": {
            userSelect: "none",
            width: "100%",
            display: "flex",
            alignItems: "center",
            position: 'relative',
            marginBottom: '7px',
            paddingRight: '7px'
        },
        "& .player .bar .bar__time": { color: "#3182FF", fontSize: "14px" },
        '& .player .bar .bar__time.start': {
            position: 'absolute',
            left: 0,
            bottom: '-18px',
            fontSize: '10px !important'
        },
        '& .player .bar .bar__time.end': {
            position: 'absolute',
            right: 7,
            bottom: '-18px',
            fontSize: '10px !important'
        },
        "& .player .bar .bar__progress": {
            flex: 1,
            borderRadius: "5px",
            margin: "0px",
            height: "5px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: 4,
            background: 'rgb(227, 228, 232) !important'
        },
        "& .player .bar .bar__progress .bar__progress__knob": {
            position: "relative",
            height: "12px",
            width: "12px",
            border: "1.5px solid transparent",
            borderRadius: "50%",
            backgroundColor: "#3182FF"
        },
        "@media screen and (max-width: 800px)": {
            "& .player": { flexDirection: "row" },
            "& .player .controls": { width: "100%", marginTop: "5px" },
            "& .player .bar": { width: "90%", paddingRight: "10px !important" },
            "& .player .bar .bar__time.end": { paddingRight: "4px !important" }
        },
        "@media screen and (max-width: 500px)": {
            "& .player .song .song__title": { fontSize: "2.5em" },
            "& .player .song .song__artist": { fontSize: "0.8em" }
        }
    },
    sent: {
        "& .player": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 0",
            backgroundColor: "transparent",
            minWidth: "210px"
        },
        "& .player .song": { userSelect: "none", margin: "0 20px" },
        "& .player .song .song__title": {
            textAlign: "left",
            margin: "0",
            color: "#aaa",
            fontFamily: '"Permanent Marker", cursive',
            fontWeight: "normal",
            fontSize: "3.5em"
        },
        "& .player .song .song__title:hover": { color: "#f1f1f1" },
        "& .player .song .song__artist": {
            margin: "0",
            color: "#aaa",
            fontFamily: '"Rock Salt", cursive',
            fontWeight: "normal",
            fontSize: "1em"
        },
        "& .player .song .song__artist:hover": { color: "#f1f1f1", cursor: "pointer" },
        "& .player .controls": {
            flexGrow: 1,
            margin: "0 0px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        },
        "& .player .player__button": {
            width: "fit-content",
            backgroundColor: "transparent",
            border: "none"
        },
        "& .player .player__button:focus": { outline: "none" },
        "& .player .player__button:hover": { cursor: "pointer" },
        "& .player .player__button:hover svg": { color: "#f1f1f1" },
        "& .player .player__button svg": { fontSize: "2.3em", color: "#f5f5f5", marginRight: '8px' },
        "& .player .bar": {
            userSelect: "none",
            width: "100%",
            display: "flex",
            alignItems: "center",
            position: 'relative',
            marginBottom: '7px',
            paddingRight: '7px'
        },
        "& .player .bar .bar__time": { color: "#f1f1f1", fontSize: "14px" },
        '& .player .bar .bar__time.start': {
            position: 'absolute',
            left: 0,
            bottom: '-18px',
            fontSize: '10px !important'
        },
        '& .player .bar .bar__time.end': {
            position: 'absolute',
            right: 7,
            bottom: '-18px',
            fontSize: '10px !important'
        },
        "& .player .bar .bar__progress": {
            flex: 1,
            borderRadius: "5px",
            margin: "0px",
            height: "5px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: 4
        },
        "& .player .bar .bar__progress .bar__progress__knob": {
            position: "relative",
            height: "12px",
            width: "12px",
            border: "1.5px solid transparent",
            borderRadius: "50%",
            backgroundColor: "#f1f1f1"
        },
        "@media screen and (max-width: 800px)": {
            "& .player": { flexDirection: "row" },
            "& .player .controls": { width: "100%", marginTop: "5px" },
            "& .player .bar": { width: "90%", paddingRight: "10px !important" },
            "& .player .bar .bar__time.end": { paddingRight: "4px !important" }
        },
        "@media screen and (max-width: 500px)": {
            "& .player .song .song__title": { fontSize: "2.5em" },
            "& .player .song .song__artist": { fontSize: "0.8em" }
        }
    },
    playerSpeed: {
        marginLeft: '2px',
        '& .MuiSelect-root': {
            padding: '1px 2px !important',
            fontSize: '12px',
            background: 'rgb(244 246 251 / 50%)',
            color: '#fff',
            borderRadius: '4px',
            width: '24px',
            textAlign: 'center'
        },
        '& .MuiFilledInput-underline:after': {
            display: 'none'
        },
        '& .MuiSelect-icon, .MuiFilledInput-underline:before': {
            display: 'none !important'
        },
        '& .MuiFilledInput-root': {
            borderRadius: '4px !important'
        }
    },
        schedule: {
            padding: '10px',
        "& .player": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px 0",
            backgroundColor: "transparent",
            minWidth: "210px"
        },
        "& .player .song": { userSelect: "none", margin: "0 20px" },
        "& .player .song .song__title": {
            textAlign: "left",
            margin: "0",
            color: "#aaa",
            fontFamily: '"Permanent Marker", cursive',
            fontWeight: "normal",
            fontSize: "3.5em"
        },
        "& .player .song .song__title:hover": { color: theme.palette.primary.bgColor },
        "& .player .song .song__artist": {
            margin: "0",
            color: "#aaa",
            fontFamily: '"Rock Salt", cursive',
            fontWeight: "normal",
            fontSize: "1em"
        },
            "& .player .song .song__artist:hover": { color: theme.palette.primary.bgColor, cursor: "pointer" },
        "& .player .controls": {
            flexGrow: 1,
            margin: "0px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
        },
        "& .player .player__button": {
            width: "fit-content",
            backgroundColor: "transparent",
            border: "none"
        },
        "& .player .player__button:focus": { outline: "none" },
        "& .player .player__button:hover": { cursor: "pointer" },
            "& .player .player__button:hover svg": { color: theme.palette.primary.bgColor },
            "& .player .player__button svg": { fontSize: "2.5em", color: theme.palette.primary.bgColor, marginRight: '8px' },
        "& .player .bar": {
            userSelect: "none",
            width: "100%",
            display: "flex",
            alignItems: "center",
            position: 'relative',
            marginBottom: '7px',
            paddingRight: '7px'
        },
            "& .player .bar .bar__time": { color: theme.palette.primary.bgColor, fontSize: "14px" },
        '& .player .bar .bar__time.start': {
            position: 'absolute',
            left: 0,
            bottom: '-18px',
            fontSize: '10px !important'
        },
        '& .player .bar .bar__time.end': {
            position: 'absolute',
            right: 7,
            bottom: '-18px',
            fontSize: '10px !important'
        },
        "& .player .bar .bar__progress": {
            flex: 1,
            borderRadius: "5px",
            margin: "0px",
            height: "5px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            marginBottom: 4,
            background: 'rgb(227, 228, 232) !important'
        },
        "& .player .bar .bar__progress .bar__progress__knob": {
            position: "relative",
            height: "12px",
            width: "12px",
            border: "1.5px solid transparent",
            borderRadius: "50%",
            backgroundColor: theme.palette.primary.bgColor
        },
        "@media screen and (max-width: 800px)": {
            "& .player": { flexDirection: "row" },
            "& .player .controls": { width: "100%", marginTop: "5px" },
            "& .player .bar": { width: "90%", paddingRight: "10px !important" },
            "& .player .bar .bar__time.end": { paddingRight: "4px !important" }
        },
        "@media screen and (max-width: 500px)": {
            "& .player .song .song__title": { fontSize: "2.5em" },
            "& .player .song .song__artist": { fontSize: "0.8em" }
        }
    },


});

export default styles;
