const styles = (theme) => ({
    autoCompleteRoot: {
        '& .MuiAutocomplete-endAdornment': {
            display: 'none',
            '& .MuiSvgIcon-root': {
                fill: theme.palette.secondary.main
            }
        },
        '& .MuiAutocomplete-inputRoot': {
            paddingRight: '10px !important'
        },
        '& .MuiSvgIcon-root': {
            fill: theme.palette.text.secondary2,
            width: '20px',
            height: '20px'
        }
    },
    root: {
        display: 'flex',
        background: "transparent",
        boxShadow: "none !important",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiCardContent-root": {
            padding: "4px !important",
            paddingLeft: "10px !important",
        },
        '& .MuiAutocomplete-endAdornment': {
            display: 'none'
        }
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: "50px",
    },
});

export default styles;
