import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// Import Public Layout
import AuthenticationLayout from "../layouts/AuthenticationLayout";
import history from "../config/history";

// Routes the page without any authentication by the user
const AuthRoute = ({ component: Component, ...rest }) => {

  const isSignedIn = localStorage.getItem('staySignInAG');
  const hasToken = localStorage.getItem("key");

  useEffect(() => {
      if((isSignedIn && hasToken) || hasToken){
          history.push('/dashboard')
      }
  }, [])

  return(
    <Route {...rest} render={props => (
      <AuthenticationLayout>
        <Component {...props} />
      </AuthenticationLayout>
    )} />
  )
}

// Define Props Types
AuthRoute.propTypes = {
  component: PropTypes.elementType
};

export default AuthRoute;
