import { customNodeProps } from "./utils";

export const drawPen = ({ stage }) => {
    const layer = stage.findOne('#baseLayer')
    const tr = stage.findOne('#transformer')
    let circlePointer = stage.findOne('#circlePointer')
    tr.nodes([])
    tr.hide()
    let isPaint = false;
    let mode = 'brush';
    let lastLine;
    if (!circlePointer) {
        circlePointer = new window.Konva.Circle({
            fill: stage?.attrs?.drawProps?.stroke || '#df4b26',
            radius: stage?.attrs?.drawProps?.strokeWidth || 5,
        })
        layer.add(circlePointer)
    }

    const onUndo = () => {}

    const isExists = layer.findOne('.brushAdded')
    if (!isExists) {
        stage.on('mousedown touchstart', function (e) {
            if (stage?.attrs?.drawMode) {
                e.evt.preventDefault();
                isPaint = true;
                const pos = stage.getPointerPosition();
                lastLine = new window.Konva.Line({
                    stroke: '#df4b26',
                    strokeWidth: 5,
                    ...(stage?.attrs?.drawProps || {}),
                    nodeType: 'drawPen',
                    globalCompositeOperation:
                        mode === 'brush' ? 'source-over' : 'destination-out',
                    // round cap for smoother lines
                    lineCap: 'round',
                    lineJoin: 'round',
                    // add point twice, so we have some drawings even on a simple click
                    points: [pos.x, pos.y, pos.x, pos.y],
                    history: [],
                    history_id: 0,
                    id: `pen_${new Date().getTime()}`,
                    ...customNodeProps({ stage }),
                    undo: onUndo
                });
                lastLine.name('brushAdded')
                layer.add(lastLine);
                stage.attrs.onUpdate(stage)
            }
        });

        stage.on('mouseup touchend', function () {
            isPaint = false;
            circlePointer?.hide()
        });

        // and core function - drawing
        stage.on('mousemove touchmove', function (e) {
            let pos = null
            if (stage?.attrs?.drawMode) {
                pos = stage.getPointerPosition();
                circlePointer?.show()
                circlePointer?.setAttrs({
                    fill: stage?.attrs?.drawProps?.stroke || '#df4b26',
                    radius: stage?.attrs?.drawProps?.strokeWidth || 5,
                    x: pos.x,
                    y: pos.y
                })
            }
            if (!isPaint || !stage?.attrs?.drawMode) {
                return;
            }
            // prevent scrolling on touch devices
            e.evt.preventDefault();
            const newPoints = lastLine.points().concat([pos.x, pos.y]);
            lastLine.points(newPoints);
        });

        stage.on('mouseleave', function (e) {
            circlePointer?.hide()
        })
    }
    stage.attrs.onUpdate(stage, { drawMode: true })
    stage.attrs.drawMode = true
}