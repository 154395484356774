const style = (theme) => ({
    VCOptions: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        left: '10px',
        bottom: '10px',
        zIndex: 2,
        '@media only screen and (max-width: 768px)':{
            width : '50px',
            zIndex: 999
        }
        
    },
    CallOptionsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        top: '90%',
        bottom: '10px',
        '@media only screen and (max-width: 768px)':{
            width: '100%',

        }
    },
    CallOptionsColumn: {
        display: 'flex',
        alignItems: "center",
        flexDirection: "column-reverse",
        position: "relative",
        height: "300px",
        '@media only screen and (max-width: 768px)':{
            width : '50px',
            height: 'auto'
        }
        
    },
    CallOptionsRow:{
        display: 'flex',
        alignItems: "center"
    },
    iconZ2:{
        zIndex: '2',
        '@media only screen and (max-width: 768px)':{
            width: "54px"
        }
    },
    optionIcon:{
        width: "60px",
        height: "60px",
        position: "absolute",
        '@media only screen and (max-width: 768px)':{
            width: "54px"
        }

    },
    hide:{
        display: "none"
    },
    LobbyOptionIcon:{
        width: '60px',
        height : '60px',
        '@media only screen and (max-width: 768px)':{
            width: '55px',
            height: '55px'
            
        }
    },
    ErrorIconContainer:{
        display : 'flex',
        position: 'absolute',
        top: '5px',
        right: 0
    }
});

export default style