// Import API Helper
import { postRequest, getRequest, deleteRequest } from "../../utils/apiHelper";

export const projectService = {
    createProject,
    getProjects,
    updateDND,
    removeProjectUser,
    deleteUserFromAgency,
    getProjectDetail,
    getProjectsFilter,
    getAllTags,
    completeProject,
    deleteProject,
    archiveProject,

    getBrief,
    updateBrief,
    saveDuplicateProject,

    getAllNotes,
    getNote,
    addNote,
    updateNote,
    shareNote,
    deleteNote,


    getTeamMembers,
    getActiveTeamMembers,
    getProjectTeamUsers,
    newUserInvitationAccept,
    inviteNewUsers,
    inviteExistingUsers,
    getProjectInviteData,

    createNewTask,
    getAllTasks,
    getCurrentTask,
    deleteTask,
    updatecurrentTask,
    getTaskFilter,
    completeTask,
    getPTaskTags,
    createTaskNote,
    getAllTaskNote,
    deleteTaskNote,
};

//create new project
async function createProject(params) {
    const response = await postRequest("project/create", params);
    return response;
}

//get projects
async function getProjects(params) {
    const response = await postRequest("project/", params);
    return response;
}

async function getAllTags(params) {
    const response = await postRequest("project/getAllTags", params);
    return response;
}

//get projects
async function getProjectsFilter(params) {
    const response = await postRequest("project/filter", params);
    return response;
}

//get project detail
async function getProjectDetail(params) {
    const response = await getRequest(`project/${params.id}`);
    return response;
}

//add note
async function updateDND(params) {
    const response = await postRequest("project/updateDND", params);
    return response;
}

//add note
async function removeProjectUser(params) {
    const response = await postRequest("project/removeProjectUser", params);
    return response;
}

// delete user from agency
async function deleteUserFromAgency(params) {
    const response = await postRequest("profile/deleteUser", params);
    return response;
}

//complete Project
async function completeProject(params) {
    const response = await postRequest("project/completeProject", params);
    return response;
}

//delete Project
async function deleteProject(params) {
    const response = await postRequest("project/remove", params);
    return response;
}

//archive Project
async function archiveProject(params) {
    const response = await postRequest("project/archive", params);
    return response;
}

//getProjectInviteData
async function getProjectInviteData(params) {
    const response = await postRequest("project/projectInviteData", params);
    return response;
}

/********************************BRIEF DETAILS**************************************************************** */

//get brief
async function getBrief(params) {
    const response = await postRequest("project/brief/get", params);
    return response;
}

//update brief
async function updateBrief(params) {
    const response = await postRequest("project/brief/update", params);
    return response;
}

//create duplicate project
async function saveDuplicateProject(params) {
    const response = await postRequest("project/create_clone", params);
    return response;
}

/********************************NOTES DETAILS**************************************************************** */


//get all notes
async function getAllNotes(params) {
    const response = await postRequest("project/note/getAll", params);
    return response;
}

//get one note
async function getNote(params) {
    const response = await postRequest("project/note/getOne", params);
    return response;
}

//add note
async function addNote(params) {
    const response = await postRequest("project/note/add", params);
    return response;
}

//update note
async function updateNote(params) {
    const response = await postRequest("project/note/update", params);
    return response;
}

//share note
async function shareNote(params) {
    const response = await postRequest("project/note/share", params);
    return response;
}

//Delete current Notes
async function deleteNote(params) {
    const response = await postRequest("project/note/del", params);
    return response;
}

/************************************TEAMS DETAILS*********************************************************** */

//get project team users
async function getProjectTeamUsers(params) {
    const response = await getRequest(`project/team/${params.id}`);
    return response;
}


//add note
async function inviteExistingUsers(params) {
    const response = await postRequest("project/existingInvite", params);
    return response;
}

//get Team Members
async function getTeamMembers(params) {
    const response = await postRequest("project/getTeamMembers", params);
    return response;
}

//get Team Members
async function getActiveTeamMembers(params) {
    const response = await postRequest("project/getActiveTeamMembers", params);
    return response;
}

//get Team Members
async function newUserInvitationAccept(params) {
    const response = await postRequest("project/inviteAccept", params);
    return response;
}

//add note
async function inviteNewUsers(params) {
    const response = await postRequest("project/newInvite", params);
    return response;
}

/***************************************TASK DETAILS****************************************************** */

//create new task
async function createNewTask(params) {
    const response = await postRequest("project/task/createNew", params);
    return response;
}

//get all task
async function getAllTasks(params) {
    const response = await postRequest("project/task/getAll", params);
    return response;
}

//get current task
async function getCurrentTask(params) {
    const response = await postRequest("project/task/getOne", params);
    return response;
}

//get current task
async function deleteTask(params) {
    const response = await postRequest("project/task/del", params);
    return response;
}

//get current task
async function updatecurrentTask(params) {
    const response = await postRequest("project/task/update", params);
    return response;
}

//create new profile
async function getTaskFilter(params) {
    const response = await postRequest("project/task/filter", params);
    return response;
}

//complete task
async function completeTask(params) {
    const response = await postRequest("project/task/complete", params);
    return response;
}

//task tags get
async function getPTaskTags(params) {
    const response = await postRequest("project/task/tags", params);
    return response;
}

//create task note
async function createTaskNote(params) {
    const response = await postRequest("task/notes/create", params);
    return response;
}

//get project task note
async function getAllTaskNote(params) {
    const response = await postRequest("task/notes", params);
    return response;
}

//delete task note
async function deleteTaskNote(params) {
    const response = await deleteRequest("task/notes", params);
    return response;
}