import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core";
import ChatEditor from "../FlozyEditor/ChatEditor/chatEditor";

//Import Styles
import styles from "./styles";

//import component 
import TinyUrlMessage from "./TinyUrlMessage";

const TextMessage = (props) => {
    const { classes, message, msgComponentType, handleUndo, undo, userId, isSender ,viewType} = props;

    const [urlData, setUrlData] = useState(null);
    const [messageUrl, setMessageUrl] = useState(null);

    useEffect(() => {
        const messageLinks = message.message_links ? JSON.parse(message.message_links) : null;

        if(messageLinks){
            if(messageLinks?.urlData?.[0]){
                setUrlData(messageLinks?.urlData?.[0]);
            }
            if(messageLinks?.url?.[0]?.url){
                setMessageUrl(messageLinks?.url?.[0]?.url);
            } else if(messageLinks?.url?.[0]){
                setMessageUrl(messageLinks?.url?.[0])
            }
        }
    }, [message])

    return (
        <>
            {
                messageUrl && urlData && messageUrl?.startsWith("https:") && message.deleted == 0  ?
                    <TinyUrlMessage classes={classes} urlData={urlData} messageUrl={messageUrl} /> : null
            }
            <div className={`${message.star && userId == message.star_user_id ? classes.textMsgBubbleStar : classes.textMsgBubble} ${messageUrl ? 'mb-1' : ''} disable-user-select`}>
                {!undo ?
                    <ChatEditor
                        className={'chatEditorRoot'}
                        id={message?.id || 1}
                        content_status={'loaded'}
                        content={message?.message_raw_text || message?.message_text ||  ""}
                        readOnly={true}
                        msgComponentType={msgComponentType}
                        isSender={isSender}
                        viewType={viewType}
                    /> :
                    <span className="deletedMessage">This message has been deleted
                        {message?.message_from === userId ? (
                            <a style={{ paddingLeft: "10px", color: "blue", fontWeight: "bold" }} className={"cursorPointer"} onClick={() => handleUndo(message.id)}>undo</a>
                        ) : null
                        }
                    </span>
                }
            </div>
        </>

    )

}


// Default props
TextMessage.defaultProps = {
    classes: {},
    message: null,
    undo: false,
    handleHashTagClick: () => { },
    handleMentionClick: () => { }
};

// Prop types
TextMessage.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.object,
    undo: PropTypes.bool,
    handleHashTagClick: PropTypes.func,
    handleMentionClick: PropTypes.func,
};

export default withStyles(styles)(TextMessage)
