import { taskConstant } from "../constants";

const initialState = {
    tasks: { data: [], page: {} },
    filters: { completed: 0, inProgress: 0, dueDate: [], projects: [], total: 0, assigned: [] },
    userTaskData: {
        completedTaskCount: 0,
        showTaskAnimation: false,
    },
    newTaskForm: false,
    newTask: {},
    taskTags: [],
    teamMembers: [],
    activeMembers: [],
    jobList: []
}

export default function tasks(state = initialState, action) {
    switch (action.type) {
        case taskConstant.GET_ALL_TASKS_R:
            return {
                ...state,
            }
        case taskConstant.GET_ALL_TASKS_S:
            return {
                ...state,
                tasks: action.data.data
            }
        case taskConstant.GET_ALL_TASKS_F:
            return {
                ...state,
                tasks: initialState.tasks
            }

        case taskConstant.GET_ALL_TASKS_FILTER_R:
            return {
                ...state,
            }
        case taskConstant.GET_ALL_TASKS_FILTER_S:
            return {
                ...state,
                filters: action.data.data
            }
        case taskConstant.GET_ALL_TASKS_FILTER_F:
            return {
                ...state,
                filters: initialState.filters
            }

        case taskConstant.COMPLETE_TASK_R:
        case taskConstant.COMPLETE_TASK_S:
        case taskConstant.COMPLETE_TASK_F:
            return state
        case taskConstant.UPDATE_COMPLETED_TASK_COUNT_S:
            return {
                ...state,
                userTaskData: {
                    ...action.data.data
                }
            }
        case taskConstant.UPDATE_TASK_ANIMATION_STATUS_S:
            return {
                ...state,
                userTaskData: {
                    ...state.userTaskData,
                    showTaskAnimation: action.data,
                }
            }
        case taskConstant.UPDATE_TASK_FORM_STATUS_S:
            return {
                ...state,
                newTaskForm: action.data
            }
        case taskConstant.GET_ALL_JOB_DATA_R:
            return {
                ...state
            }
        case taskConstant.GET_ALL_JOB_DATA_S:
            return {
                ...state,
                ...action.data.data
            }
        case taskConstant.GET_ALL_JOB_DATA_F:
            return state
        case taskConstant.UPDATE_T_CREATE_TASK:
            return {
                ...state,
                newTask: action.data
            }
        case taskConstant.CREATE_ONE_NEW_TASK_R:
            return {
                ...state
            }
        case taskConstant.CREATE_ONE_NEW_TASK_S:
            return {
                ...state,
                newTaskForm: initialState.newTaskForm,
                newTask: initialState.newTask,
                taskTags: initialState.taskTags,
                teamMembers: initialState.teamMembers,
                jobList: initialState.jobList
            }
        case taskConstant.CREATE_ONE_NEW_TASK_F:
            return state
        case taskConstant.GET_ACTIVE_MEMBERS_R:
            return {
                ...state
            }
        case taskConstant.GET_ACTIVE_MEMBERS_S:
            return {
                ...state,
                activeMembers: action.data.data
            }
        case taskConstant.GET_ACTIVE_MEMBERS_F:
            return state
        default:
            return state
    }
}
