import { baseAction } from "./baseAction";
import { chatHubConstant, chatConstant, alertConstant, loaderConstant, uiConstant } from "../constants";
import { chatHubService, chatService } from "../services";

// import chatPage constants
import { chatPageConstants } from '../../config/constants';

// Import History
import history from "../../config/history";

const { request, success, failure } = baseAction;

// All login based actions
export const chatHubAction = {
    updateChatHubType,
    updateChatRoom,

    getProjects,
    getJobChat,
    getClients,

    getTeamUsers,
    getTeamUserSearchResult,
    getAllUsers,
    createNewGroup,
    chatProfileUserMessage,

    getTeamChat,
    saveTeamNewMessage,
    updateTeamNewTempMessage,
    updateTeamNewOwnMessage,
    updateTeamNewMessage,
    updateTeamMessageStatus,
    updateTeamOnLoadMessageStatus,
    updateTeamMessageReactions,
    uploadTeamChatFiles,
    getTeamChatSearchMessages,
    getTeamChatMedia,

    getAllChatRooms,
    getMutedChats,
    mobileChatScreen,
    getNewMessages,

    updateChatDeliveredStatus,
    updateChatReadStatus,

    deleteTeamMember,
    updateGroupData,
    updateChatMessagesOnLoad,
    resetChatMessages,
    getAssociatedUsers,
    updateMuteNotification
};



// Update Chat Hub Type
function updateChatHubType(page) {
    return (dispatch, getState) => {
        const existingType = getState().chatHub.activeHub.type;
        if (page && page !== existingType) {
            dispatch(success(chatHubConstant.UPDATE_CHAT_HUB_TYPE, page));
        }
    };
}

/* Update Chat Hub Active Chat Room */
function updateChatRoom(data) {
    return (dispatch) => {
        dispatch(success(chatHubConstant.UPDATE_CHAT_HUB_ROOM, data));
    };
}


/* 
  Project Chat Section
*/

/** get users project list */
function getProjects() {
    return (dispatch) => {
        dispatch(request(chatHubConstant.GET_CHAT_PROJECTS_R, ""));
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.getProjects().then(data => {
            dispatch(success(chatHubConstant.GET_CHAT_PROJECTS_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(chatHubConstant.GET_CHAT_PROJECTS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

// get job chat on load inside project detail page
function getJobChat(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id, chatRoomData, userId } = params;
        let { chat, chat_room } = getState().chat.project;
        dispatch(request(uiConstant.LAZY_LOADING_TRUE))
        dispatch(request(chatConstant.GET_JOB_CHAT_R, params));
        chatService.getJobChat(params).then(data => {
            const page = data.data && data.data.page ? data.data.page : { skip: 0, limit: 20, count: 0 }
            chat = { ...chat, [`${job_id}`]: data.data && data.data.data ? data.data.data : [], userId: userId };
            chat_room = { ...chat_room, [`${job_id}`]: { ...chatRoomData }, [`${userId}`]: userId }
            const result = {
                chat,
                chat_room,
                activeProject: job_id,
                page: page,
                channelName : data.data && data.data.channelName,
            };

            dispatch(success(chatConstant.GET_JOB_CHAT_S, result));
            dispatch(request(uiConstant.LAZY_LOADING_FALSE))
            callBack()
        }, error => {
            dispatch(failure(chatConstant.GET_JOB_CHAT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}
/*
  Project Chat Section
*/

/*
  Client Chat Section Start
*/

/** get users clients list */
function getClients() {
    return (dispatch) => {
        dispatch(request(chatHubConstant.GET_CHAT_CLIENTS_R, ""));
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.getClients().then(data => {
            dispatch(success(chatHubConstant.GET_CHAT_CLIENTS_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(chatHubConstant.GET_CHAT_CLIENTS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

/*
  Client Chat Section End
*/



/*
  Team Chat Group List Section Start
*/
/** get users teams list */
function getTeamUsers(CB = () => { }) {
    return (dispatch) => {
        dispatch(request(chatHubConstant.GET_CHAT_TEAM_R, ""));
        chatHubService.getTeamUsers().then(data => {
            dispatch(success(chatHubConstant.GET_CHAT_TEAM_S, data.data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            CB(data.data)
        }, error => {
            dispatch(failure(chatHubConstant.GET_CHAT_TEAM_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

/** get users clients list */
function getTeamUserSearchResult(params) {
    return (dispatch) => {
        dispatch(request(chatHubConstant.GET_CHAT_TEAM_USER_SEARCH_R, params));
        chatHubService.getTeamUserSearchResult(params).then(data => {
            dispatch(success(chatHubConstant.GET_CHAT_TEAM_USER_SEARCH_S, data));
        }, error => {
            dispatch(failure(chatHubConstant.GET_CHAT_TEAM_USER_SEARCH_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

/** get users */
function getAllUsers(params) {
    return (dispatch) => {
        dispatch(request(chatHubConstant.GET_ALL_USERS_R, ""));
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.getAllUsers(params).then(data => {
            dispatch(success(chatHubConstant.GET_ALL_USERS_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(chatHubConstant.GET_ALL_USERS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

/**create new Group */
function createNewGroup(params, CB = () => { }) {
    return (dispatch) => {
        dispatch(request(chatHubConstant.CREATE_NEW_GROUP_R, ""));
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.createNewGroup(params).then(data => {
            dispatch(success(chatHubConstant.CREATE_NEW_GROUP_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            CB(data.data)
        }, error => {
            dispatch(failure(chatHubConstant.CREATE_NEW_GROUP_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}

function chatProfileUserMessage(params, CB = () => { }) {
    return (dispatch) => {
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.chatProfileUserMessage(params).then(data => {
            const { grpID } = data.data;
            if (data.data && data.isNewGroup) {
                dispatch(success(chatHubConstant.CREATE_NEW_GROUP_S, data));
                CB(data.data)
            } else {
                history.push(`/chat/team${grpID ? `?id=${grpID}` : ''}`)
            }
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            // dispatch(failure(chatHubConstant.CREATE_NEW_GROUP_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        });
    };
}


/*
  Team Chat List Section End
*/

/*
  Team Chat Messages Section Starts
*/

function getTeamChat(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { group_id, chatRoomData } = params;
        let { chat, chat_room } = getState().chat.teamChat;
        dispatch(request(uiConstant.LAZY_LOADING_TRUE))
        dispatch(request(chatHubConstant.GET_CHAT_TEAM_R, params));
        chatService.getTeamChat(params).then(data => {
            const page = data.data && data.data.page ? data.data.page : { skip: 0, limit: chatPageConstants.MESSAGE_LIMIT_COUNT, count: 0 }
            chat = { ...chat, [`${group_id}`]: data.data && data.data.data ? data.data.data : [] };
            chat_room = { ...chat_room, [`${group_id}`]: { ...chatRoomData } }
            const result = {
                chat,
                chat_room,
                activeProject: group_id,
                page: page,
                readMessagesOnLoad: data.data && data.data.readMessagesOnLoad ? data.data.readMessagesOnLoad : null,
                channelName: data.data && data.data.channelName,
            
            };

            dispatch(success(chatHubConstant.GET_CHAT_TEAM_S, result));
            dispatch(request(uiConstant.LAZY_LOADING_FALSE))
            callBack(data.data.readMessagesOnLoad)
        }, error => {
            dispatch(failure(chatConstant.GET_TEAM_CHAT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// Save Direct & Group Messages in to database
function saveTeamNewMessage(params, callBack = () => { }) {
    return (dispatch) => {
        let { chatMessageObj } = params;
        chatService.saveTeamNewMessage(chatMessageObj).then(data => {
            // successfully saved messages 
            callBack(data.data)
            // Note: Need to handle if messages not saved successfully
        }, error => {
            console.error("🚀 ~ file: chatAction.js ~ line 85 ~ chatService.saveNewMessage ~ error", error)
        });
    }
}

// when chat message received on project message
function updateTeamNewTempMessage(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { group_id, message } = params;

        let { chat, chat_room, teams } = getState().chat.teamChat;
        let preChatList = chat && chat.hasOwnProperty(group_id) ? chat[group_id] : [];
        let jobChat = [message, ...preChatList];
        chat[`${group_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            teams,
            group_id
        };

        callBack();

        dispatch(success(chatConstant.UPDATE_TEAM_NEW_MESSAGE_S, result));
    };
}

// when chat message received own message from socket on direct message
function updateTeamNewOwnMessage(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { group_id, message, tempMessageId } = params;

        let { chat, chat_room, teams } = getState().chat.teamChat;
        let preChatList = chat && chat.hasOwnProperty(group_id) ? chat[group_id] : [];

        const findIndex = preChatList.findIndex(msg => msg.id === tempMessageId);
        const msgFoundIndex = preChatList.findIndex(msg => msg.id === message.id)
        const getChatData = () => {
            preChatList[findIndex] = message
            return preChatList
        }
        chat[`${group_id}`] = preChatList.length > 0 ? msgFoundIndex >= 0 ? preChatList : getChatData() : [message];

        const result = {
            chat,
            chat_room,
            teams,
            group_id
        };
        dispatch(success(chatConstant.UPDATE_TEAM_NEW_MESSAGE_S, result));
        callBack(params)
    };
}

// when chat message received other chatroom users message from socket on direct message
function updateTeamNewMessage(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { group_id, message } = params;

        let { chat, chat_room, teams } = getState().chat.teamChat;
        let preChatList = chat && chat.hasOwnProperty(group_id) ? chat[group_id] : [];

        if (preChatList.filter(msg => msg.id === message.id).length > 0) {
            // no need to update the message may be its second time or receiving own message
            callBack(params)
        } else {
            chat[`${group_id}`] = preChatList.length > 0 ? [message, ...preChatList] : [message];

            const result = {
                chat,
                chat_room,
                teams,
                group_id
            };
            dispatch(success(chatConstant.UPDATE_TEAM_NEW_MESSAGE_S, result));
            callBack(params)
        }
    };
}


// when chat message received on project message
function updateTeamOnLoadMessageStatus(params, callBack = () => { }) {
    return (dispatch, getState) => {

        let { job_id, messageIds } = params;
        let { chat, chat_room, teams } = getState().chat.teamChat;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];

        let jobChat = preChatList.map((obj) => {
            if (messageIds.includes(String(obj.id))) {
                obj = {
                    ...obj,
                    delivered_status: 1,
                    read_status: 1
                }
            }
            return obj;
        })

        chat[`${job_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            teams,
            job_id: job_id
        };
        dispatch(success(chatConstant.UPDATE_TEAM_NEW_MESSAGE_S, result));
        callBack(params)
    };
}

// update team message delivery and read status
function updateTeamMessageStatus(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id, message } = params;
        let { chat, chat_room, clients } = getState().chat.teamChat;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];
        let jobChat = preChatList.length > 0 ? preChatList.map(obj => obj.id === message.id ? message : obj) : [message];
        chat[`${job_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            clients,
            job_id: job_id
        };
        dispatch(success(chatConstant.UPDATE_TEAM_NEW_MESSAGE_S, result));
        callBack(params)
    };
}

// update message reactions
function updateTeamMessageReactions(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id, message } = params;
        let { chat, chat_room, clients } = getState().chat.teamChat;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];
        let jobChat = preChatList.length > 0 ? preChatList.map(obj => obj.id === message.id ? message : obj) : [message];
        chat[`${job_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            clients,
            job_id: job_id
        };
        dispatch(success(chatConstant.UPDATE_TEAM_NEW_MESSAGE_S, result));
        callBack(params)
    };
}

/** upload chat files*/
function uploadTeamChatFiles(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(chatConstant.UPLOAD_TEAM_FILE_R, params));
        chatService.uploadTeamChatFiles(params).then(data => {
            dispatch(success(chatConstant.UPLOAD_TEAM_FILE_S, data));
            callBack(data.data)
        }, error => {
            dispatch(failure(chatConstant.UPLOAD_TEAM_FILE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// get job chat on load inside project detail page
function getTeamChatSearchMessages(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let groupId = getState().chatHub.activeHub.id;
        let searchData = { search_query: params, group_id: groupId };
        dispatch(request(chatConstant.GET_TEAM_SEARCH_MESSAGES_R, searchData));
        chatService.getTeamChatSearchMessages(searchData).then(data => {
            dispatch(success(chatConstant.GET_TEAM_SEARCH_MESSAGES_S, data));
            callBack()
        }, error => {
            dispatch(failure(chatConstant.GET_TEAM_SEARCH_MESSAGES_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// get job Medias (link, docs, media_files)
function getTeamChatMedia(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { teamLinks, teamMedias, teamDocs } = getState().chat.teamChat;
        let { group_id } = params;

        dispatch(request(chatConstant.GET_TEAM_MEDIA_MESSAGES_R, params));
        chatService.getTeamChatMedia(params).then(data => {
            let result = '';
            if (data.data.media_type === "links") {
                result = { teamLinks: { ...teamLinks, [`${group_id}`]: data.data.result } }
            } else if (data.data.media_type === "docs") {
                result = { teamDocs: { ...teamDocs, [`${group_id}`]: data.data.result } }
            } else {
                result = { teamMedias: { ...teamMedias, [`${group_id}`]: data.data.result } }
            }

            dispatch(success(chatConstant.GET_TEAM_MEDIA_MESSAGES_S, result));
        }, error => {
            dispatch(failure(chatConstant.GET_TEAM_MEDIA_MESSAGES_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

/*
  Team Chat Messages Section End
*/


// get all chat rooms of the logged in user
function getAllChatRooms(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(chatHubConstant.GET_ALL_CHAT_ROOMS_R));
        chatService.getAllChatRooms().then(data => {
            const result = data.data;
            dispatch(success(chatHubConstant.GET_ALL_CHAT_ROOMS_S, result));
            callBack()
        }, error => {
            dispatch(failure(chatHubConstant.GET_ALL_CHAT_ROOMS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// get all muted notification chat list
function getMutedChats(params) {
    return (dispatch) => {
        dispatch(request(chatHubConstant.GET_ALL_MUTED_CHAT_R));
        chatService.getMutedChats(params).then(data => {
            const result = data.data;
            dispatch(success(chatHubConstant.GET_ALL_MUTED_CHAT_S, result));
        }, error => {
            dispatch(failure(chatHubConstant.GET_ALL_MUTED_CHAT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// for mobile chat container show and hide 
function mobileChatScreen(status) {
    return (dispatch) => {
        dispatch(success(chatHubConstant.UPDATE_MOBILE_CHAT_SCREEN, status));
        // history.push('/chat')
    };
}

function getNewMessages(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(chatHubConstant.GET_NEW_CHAT_MESSAGE_R, params));
        chatHubService.getNewMessages(params).then(data => {
            dispatch(success(chatHubConstant.GET_NEW_CHAT_MESSAGE_S, data));
            callBack()
        }, error => {
            dispatch(failure(chatHubConstant.GET_NEW_CHAT_MESSAGE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// when chat message received on project message
function updateChatDeliveredStatus(params, callBack = () => { }) {
    return (dispatch) => {
        chatHubService.updateChatDeliveredStatus(params).then(data => {
            callBack(data.data)
        }, error => {
            console.error("chat update read status =>", error)
        });
    };
}

// when chat message received on project message
function updateChatReadStatus(params, callBack = () => { }) {
    return (dispatch) => {
        chatHubService.updateChatReadStatus(params).then(data => {
            callBack(data.data)
        }, error => {
            console.error(error)
        });
    };
}

// when Team member is deleted from the group
function deleteTeamMember(params, callBack = () => { }) {
    return (dispatch, getState) => {
        chatHubService.deleteTeamMember(params).then(data => {
            callBack(data.data)
        }, error => {
            console.error("delete teammember status =>", error)
        });
    };
}

// Update Groupchat Data
function updateGroupData(params, callBack = () => { }) {
    return (dispatch, getState) => {

        dispatch(request(chatHubConstant.UPDATE_GROUP_DATA_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.updateGroupData(params).then(data => {
            dispatch(success(chatHubConstant.UPDATE_GROUP_DATA_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack()
        }, error => {
            dispatch(failure(chatHubConstant.UPDATE_GROUP_DATA_F, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// update chat message status on load chat messages first time
function updateChatMessagesOnLoad(params, callBack = () => { }) {
    return (dispatch) => {
        chatHubService.updateChatMessagesOnLoad(params).then(data => {
            if (params.type === 'project') {
                dispatch(success(chatConstant.UPDATE_JOB_READ_MESSAGE_ON_LOAD, data.data.readMessagesOnLoad));
            } else if (params.type === 'client') {
                dispatch(success(chatConstant.UPDATE_CLIENT_READ_MESSAGE_ON_LOAD, data.data.readMessagesOnLoad));
            } else {
                dispatch(success(chatConstant.UPDATE_TEAM_READ_MESSAGE_ON_LOAD, data.data.readMessagesOnLoad));
            }
            callBack()
        }, error => {
            console.error("chat update read status =>", error)
        });
    };
}

// reset chat messages
function resetChatMessages(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(chatHubConstant.RESET_CHAT_DATA_R, params));
        chatHubService.resetChatMessages(params).then(data => {
            dispatch(success(chatHubConstant.RESET_CHAT_DATA_S, data));
            callBack()
        }, error => {
            dispatch(failure(chatHubConstant.RESET_CHAT_DATA_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// getClientAssociatedUsers
function getAssociatedUsers(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(chatHubConstant.GET_CLIENT_ASSOC_USERS_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.getAssociatedUsers(params).then(data => {
            dispatch(success(chatHubConstant.GET_CLIENT_ASSOC_USERS_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack()
        }, error => {
            dispatch(failure(chatHubConstant.GET_CLIENT_ASSOC_USERS_F, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}


// Update Mute Notifications
function updateMuteNotification(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(chatHubConstant.UPDATE_MUTE_NOTIFICATION_R, params));
        // dispatch(request(loaderConstant.LOADING_TRUE));
        chatHubService.updateMuteNotification(params).then(data => {
            dispatch(success(chatHubConstant.UPDATE_MUTE_NOTIFICATION_S, data));
            // dispatch(request(loaderConstant.LOADING_FALSE));
            callBack()
        }, error => {
            dispatch(failure(chatHubConstant.UPDATE_MUTE_NOTIFICATION_F, error));
            // dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}