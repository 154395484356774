// Import API Helper
import { postRequest, getRequest, deleteRequest } from "../../utils/apiHelper";
import { _api } from "../../config/environment";

export const chatService = {
    getJobChat,
    saveNewMessage,
    getSearchMessages,
    getMessageMedia,
    editProjectTextMessage,

    getClientChat,
    saveClientNewMessage,
    uploadClientChatFiles,
    getClientChatSearchMessages,
    getClientChatMedia,
    editClientTextMessage,

    getStarChat,

    getTeamChat,
    saveTeamNewMessage,
    uploadTeamChatFiles,
    getTeamChatSearchMessages,
    getTeamChatMedia,
    editTeamTextMessage,
    gets3Url,

    getAllChatRooms,
    getMutedChats,
    deleteChatMessage,
    starMessage,
    uploadFiles,
    onUploadingData,
    updateEmojiReactions,
    forwardMultipleMesssage
};

/* 
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
    Project Chat Section Start
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
//get project (job) Chats
async function getJobChat(params) {
    const { job_id } = params;
    const response = await postRequest(`project/chat/id/${job_id}`, params);
    return response;
}

async function getStarChat(params) {
    const response = await postRequest(`chat_hub/starList`, params);
    return response;
}


//uploading data to DB
async function onUploadingData(params) {
    const response = await postRequest(`project/chat/onuploading`, params);
    return response;
}

//upload project(job) chat files
async function uploadFiles(params) {
    const response = await postRequest(`project/chat/upload`, params);
    return response;
}

//upload project(job) chat files
async function getSearchMessages(params) {
    const response = await postRequest("project/chat/search", params);
    return response;
}

//upload project(job) chat files
async function getMessageMedia(params) {
    const response = await postRequest("project/chat/media", params);
    return response;
}

//save project(job) chat messages
async function saveNewMessage(params) {
    const response = await postRequest("project/chat/saveMessage", params);
    return response;
}

//Edit projects message
async function editProjectTextMessage(params) {
    const response = await postRequest("project/chat/editMessage", params);
    return response;
}
/* 
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
#    Project Chat Section End
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
*/

/* 
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
    Client Chat Section Start
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
//get client (job) Chats
async function getClientChat(params) {
    const { client_id } = params;
    const response = await postRequest(`clients/chat/id/${client_id}`, params);
    return response;
}

//upload client(job) chat files
async function uploadClientChatFiles(params) {
    const response = await postRequest("clients/chat/upload", params);
    return response;
}

//upload client(job) chat files
async function getClientChatSearchMessages(params) {
    const response = await postRequest("clients/chat/search", params);
    return response;
}

//upload client(job) chat files
async function getClientChatMedia(params) {
    const response = await postRequest("clients/chat/media", params);
    return response;
}

//save client(job) chat messages
async function saveClientNewMessage(params) {
    const response = await postRequest("clients/chat/saveMessage", params);
    return response;
}

//Edit clients message
async function editClientTextMessage(params) {
    const response = await postRequest("clients/chat/editMessage", params);
    return response;
}

/*
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
#    Client Chat Section End
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
*/


/* 
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
    Team Chat Section Start
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
*/
//get Team Chats
async function getTeamChat(params) {
    const { group_id } = params;
    const response = await postRequest(`chat_hub/team/chat/id/${group_id}`, params);
    return response;
}

//upload Team chat files
async function uploadTeamChatFiles(params) {
    const response = await postRequest("chat_hub/team/chat/upload", params);
    return response;
}

//upload Team chat files
async function getTeamChatSearchMessages(params) {
    const response = await postRequest("chat_hub/team/chat/search", params);
    return response;
}

//upload Team chat files
async function getTeamChatMedia(params) {
    const response = await postRequest("chat_hub/team/chat/media", params);
    return response;
}

//save group and direct chat messages
async function saveTeamNewMessage(params) {
    const response = await postRequest("chat_hub/team/chat/saveMessage", params);
    return response;
}

//Edit team message
async function editTeamTextMessage(params) {
    const response = await postRequest("chat_hub/team/chat/editMessage", params);
    return response;
}

/*
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
#    Team Chat Section End
    !!!!!!!!!!!!!!!!!!!!!!!!!!!
*/

async function getAllChatRooms(params) {
    const response = await getRequest(`chat_hub/chat_rooms`);
    return response;
}

async function getMutedChats(params) {
    const response = await postRequest(`chat_hub/get_muted_chat`);
    return response;
}


async function gets3Url(params){
    const response= await postRequest("chat_hub/get-presigned-urls", params);
    return response;
}


async function deleteChatMessage(params) {
    const response = await deleteRequest(`chat_hub/message`, params);
    return response;
}

/** Star chat */
async function starMessage(params) {
    const response = await postRequest(`chat_hub/star_message`, params);
     return response;
}

async function updateEmojiReactions(params) {
    const response = await postRequest(`chat_hub/update-emoji-reactions`, params);
    return response;
}

async function forwardMultipleMesssage(params) {
    const response = await postRequest("chat_hub/team/chat/forwardMessage", params);
    return response;
}