import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { KeyboardDatePicker as DatePicker } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Icon, IconButton } from "@material-ui/core";
import { withStyles } from '@material-ui/core';
// Import Images
import Style from "./styles";

//SVG 
import { CalendarIconNew } from "../../assets/svg";

/**
 *DatePicker Component
 *
 * @class DatePicker
 * @extends {Component}
 */
const DatePickerComponent = forwardRef((props, ref) => {
  // Handle TextField Events
  const [open, setOpen] = useState(false);

  const handleChange = (event, value) => {
    if (event) {
      let d = event._d;

      if (d != "Invalid Date") {
        d = new Date(new Date(d).setHours(13, 0, 0, 0))
      }
      // let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      props.handleChange({ name: props.name, value: d });
    } else {
      props.handleChange({ name: props.name, value: null });
    }
  };

  /**
     *render html
     *
     * @returns
     * @memberof DatePicker
     */
  const { value, name, mask, placeholder, classes, disableFuture, disablePast, fullWidth, label, disabled, margin, size, className, validators, errorMessages, validations, onBlur, format, style, helperText, notRequired, inputVariant, variant, panel, minDate, isSchedule, maxDate } = props;
  const customClass = validators && validators.length > 0 && !notRequired ? className + " required" : className;
  // const warningClass = hasWarnings(fieldErrors, warningFieldName);

  return (
    <DatePicker
      label={label}
      name={name}
      value={value || null}
      onChange={handleChange}
      fullWidth={fullWidth}
      format={format}
      inputVariant={inputVariant}
      variant={variant}
      margin={margin}
      placeholder={placeholder}
      mask={mask}
      disableFuture={disableFuture}
      disablePast={disablePast}
      maskChar="_"
      autoOk={true}
      onClick={(e) => !disabled && setOpen(true)}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      className={`${classes.datePickerRoot} ${customClass} date-picker-custom`}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      keyboardIcon={isSchedule ? false : <CalendarIconNew className={`${classes.datePickerIcon} date-picker-icon`} />}
      InputProps={
        {
          startAdornment: (
            < InputAdornment position="start" style={{ cursor: "pointer" }}>
              {isSchedule &&
                <IconButton onClick={e => { !disabled && setOpen(true) }} disabled={disabled} position="center">
                  <CalendarIconNew />
                </IconButton>
              }
            </InputAdornment>
          ),
          endAdornment: (
            < InputAdornment position="end" style={{ cursor: "pointer" }}>
              <Icon position="end" style={{ padding: "5px" }}>
                <CalendarIconNew />
                {/* <img src={CalendarIcon} alt="calender" style={{ height: "16px", weight: "16px", position: "relative", top: "3px", left: "5px" }} /> */}
              </Icon>
            </InputAdornment>
          ),
          disableUnderline: true
        }
      }
      invalidDateMessage={errorMessages}
      validators={validators}
      // errorMessages={errorMessages}
      onBlur={onBlur}
      // onOpen={onOpen}
      // onClose={onClose}
      style={style}
      size={size}
      {...validations}
      panel={panel}
      open={open}
      helperText={helperText}
      KeyboardButtonProps={{
        "aria-label": "change date"
      }}
      inputRef={ref}
    />
  );
})

// default props
DatePickerComponent.defaultProps = {
  name: "",
  value: "",
  label: "",
  fullWidth: "",
  disabled: false,
  variant: '',
  inputVariant: "outlined",
  className: "cursor-pointer",
  isSchedule: false,
  errorMessages: [],
  validators: [],
  onOpen: () => { },
  onClose: () => { },
  handleChange: () => { },
  validations: {},
  onBlur: () => { },
  format: "DD/MM/YYYY",
  fieldErrors: [],
  warningFieldName: "",
  style: {},
  helperText: "",
  notRequired: false,
  margin: "dense",
  size: "small",
  placeholder: "__/__/____",
  mask: "__/__/____",
  disableFuture: false,
  disablePast: false,
  maxDate: undefined,
  minDate: undefined,
};

// prop types
DatePickerComponent.propTypes = {
  name: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  inputVariant: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
  isSchedule: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.array,
  validators: PropTypes.array,
  helperText: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
};

// Export Component
export const DatePickers = withStyles(Style)(DatePickerComponent)
