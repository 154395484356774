import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { Grid, withStyles } from "@material-ui/core";

// Imports Styles
import styles from "./styles";

//import icons
import { CloudUploadIcon } from '../../assets/svg';

// import components
import { Typography } from "../Typography";

const FileInputComponent = props => {
    const { name, label = name, classes, fileNotes } = props
    const { register, unregister, setValue, watch } = useFormContext()
    const files = watch(name);
    
    const onDrop = useCallback(
        droppedFiles => {
            setValue(name, droppedFiles, { shouldValidate: true })
        },
        [setValue, name]
    )
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: props.accept,
    })
    useEffect(() => {
        register(name)
        return () => {
            unregister(name)
        }
    }, [register, unregister, name])

    return (
        <>
            <Grid
                {...getRootProps({ className: [classes.uploadCard] })}
                type="file"
                role="button"
                aria-label="File Upload"
                id={name}
            >
                {<input {...props}  {...getInputProps()} />}
                <Grid align="center" className={`${classes.fileInputDropZone} ${isDragActive ? "dargFileActive" : " "}`}>
                    {files?.length > 0 ? (
                        <div className="thumb_box">
                            {files.map(file => {
                                return (
                                    <div key={file.name} className="thumb_box_item">
                                        <img
                                            src={URL.createObjectURL(file)}
                                            alt={file.name}
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    ) :
                        <>
                            <CloudUploadIcon />
                            <Typography variant="body1" color="textSecondary" align="center" className="pt-1 pb5">Upload Here</Typography>
                            <Typography variant="body1" className="primary1 fs-10" align="center">{fileNotes ? fileNotes : ""}</Typography>
                        </>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default FileInputComponent

// export component
export const FileInput = withStyles(styles)(FileInputComponent);