import { docsConstant } from "../constants";
import { addOrRemoveDoc, updateDoc, updateDocCount } from '../helpers/docHelpers'

const initialState = {
    loading: false,
    selected_root_folder: {
        id: null,
        path: null
    },
    // it will store the 
    // selected folders history
    history: [],
    selected_folders: {},
    details: {},
    // based on this editor document will get updated
    editor: {
        id: null,
        content: ''
    },
    shared_users: [],
    team_members: [],
    // doc id scroll to
    scroll_to: null,
    publicDoc: {},
    sharedDocs: [],
    currentFolderPath: {},
    folderList: [],
    sort: "0",
    search: "",
    filterValues: {}
}

export default function docs(state = { ...initialState }, action) {
    switch (action.type) {

        case docsConstant.RESET_DOC_STATE:
            return {
                ...initialState
            }

        case docsConstant.CREATE_DOC_R:
            return {
                ...state,
                scroll_to: null,
                loading: true
            }

        case docsConstant.CREATE_DOC_S:
            return {
                ...(addOrRemoveDoc(state, action.data, 1)),
                selected_folders: {
                    ...state.selected_folders,
                    ...action.data.selected_folder
                },
                loading: false
            }

        case docsConstant.CREATE_DOC_F:
            return {
                ...state,
                loading: false
            }

        case docsConstant.GET_DOCS_LIST_R:
            return {
                ...state,
                scroll_to: null,
                listLoader: true
            }

        case docsConstant.GET_DOCS_LIST_S:
            return {
                ...state,
                details: { ...action.data },
                listLoader: false
            }

        case docsConstant.GET_DOCS_LIST_F:
            return {
                ...state,
                listLoader: false
            }
        case docsConstant.PIN_DOC_R:
            return {
                ...state,
            }

        case docsConstant.PIN_DOC_S:

            let details = { ...state.details };

            const folderRoot = Object.keys(details).filter((k) => !k.includes('root'));

            const oldData = details[folderRoot[0]];

            const responseData = action?.data;

            const path = responseData.path;
            const regex = /^root\/\d+\/(\d+)\/$/;
            const match = path.match(regex);

            let updatedData = {}

            updatedData = oldData.map((doc) => {
                if (doc.id === responseData.id) {
                    doc["pinned"] = action?.data?.pinned
                    doc["created_on"] = action?.data?.modified_on
                }

                if (match) {
                    if (match) {
                        const secondNumber = match[1];
                        const docsInsideFolder = details[secondNumber];

                        if (docsInsideFolder) {
                            const updateDocs = docsInsideFolder.map((document) =>
                                document.id === responseData.id
                                    ? {
                                        ...document,
                                        pinned: responseData.pinned,
                                        created_on: responseData.modified_on,
                                    }
                                    : document
                            );

                            details[secondNumber] = updateDocs;
                        }
                    }
                }
                return doc
            })

            details = { [folderRoot[0]]: updatedData, ...details };

            return {
                ...state,
                details: details,
            };


        case docsConstant.PIN_DOC_F:
            return {
                ...state,
            }
        // on root folder change
        case docsConstant.SET_ROOT_FOLDER:
            return {
                ...state,
                selected_root_folder: action.data,
                details: {
                    'root': [...(state.details['root'] || [])],
                    'root_0': [...(state.details['root_0'] || [])],
                    'root_1': [...(state.details['root_1'] || [])],
                    'root_2': [...(state.details['root_2'] || [])]
                },
                // reset selected folders
                selected_folders: {},
                // editor: {
                //     id: null,
                //     content: ""
                // },
                scroll_to: null
            }

        // on sub folder open
        case docsConstant.OPEN_SUB_FOLDER:
            return {
                ...state,
                selected_folders: action.data,
                history: [
                    ...state.history,
                    {
                        ...action.data
                    }
                ]
            }

        // set editor doc id
        case docsConstant.SET_EDITOR_DOC_ID:
            return {
                ...state,
                editor: {
                    id: action.data,
                    content: '',
                },
                // reset share members
                shared_users: [],
                team_members: [],
            }

        // get doc content
        case docsConstant.GET_DOC_CONTENT_R:
            return {
                ...state,
                loading: true,
                editor: {
                    ...state.editor,
                    content_status: null
                }
            }

        case docsConstant.GET_DOC_CONTENT_S:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    content_status: 'loaded',
                    id: Number(action.data.id),
                    title: action.data.title,
                    content: action.data.content,
                    token: action.data.public_access_token,
                    properties: action.data.properties,
                    short_description: action.data.short_description,
                    created_by: action.data.created_by,
                    path: action.data.path,
                    pinned: action.data.pinned,
                    access: action.data.access,
                    shared_status: action.data.shared_status
                },
                loading: false,
            }

        case docsConstant.GET_DOC_CONTENT_F:
            return {
                ...state,
                loading: false,
                // reset so user can able to click again
                editor: {
                    id: null,
                    content: '',
                    content_status: null
                }
            }

        case docsConstant.UPDATE_DOC_CONTENT_S:
            return {
                ...(updateDoc(state, action.data)),
                editor: {
                    ...state.editor,
                    title: action.data.title,
                    content: action.data.content
                }
            }

        case docsConstant.DELETE_DOC_R:
            return {
                ...state,
                loading: true
            }

        case docsConstant.DELETE_DOC_S: {
            if ('id' in action.data) {
                return {
                    ...(addOrRemoveDoc(state, action.data, 0)),
                    loading: false
                }
            } else {
                return {
                    ...state,
                    loading: false
                }
            }
        }
        case docsConstant.DELETE_DOC_F:
            return {
                ...state,
                loading: false
            }
        case docsConstant.ADD_DOC_PUBLIC_ACCESS_TOKEN_S:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    token: action.data.token
                }
            }
        case docsConstant.REMOVE_DOC_PUBLIC_ACCESS_TOKEN_S:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    token: null
                }
            }
        case docsConstant.GET_SHARE_MEMBERS_S:
            return {
                ...state,
                ...action.data
            }

        case docsConstant.SCROLL_TO_DOC:
            return {
                ...state,
                scroll_to: action.data
            }

        case docsConstant.ADD_DOC_ACCESS_S:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    shared_status: action.data.shared_status !== undefined ?
                        action.data.shared_status : state.editor.shared_status
                }
            }
        case docsConstant.GET_PUBLIC_DOCUMENT_R:
        case docsConstant.GET_PUBLIC_DOCUMENT_F:
            return {
                ...state,
                publicDoc: {
                    content_status: null
                }
            }
        case docsConstant.GET_PUBLIC_DOCUMENT_S:
            return {
                ...state,
                publicDoc: {
                    ...action.data,
                    content_status: 'loaded'
                }
            }

        case docsConstant.GET_SHARED_DOCS_LIST_R:
            return {
                ...state,
                sharedDocs: initialState.sharedDocs
            }
        case docsConstant.GET_SHARED_DOCS_LIST_S:
            return {
                ...state,
                sharedDocs: action.data
            }
        case docsConstant.GET_SHARED_DOCS_LIST_F:
            return {
                ...state,
                publicDoc: initialState.sharedDocs
            }
        case docsConstant.GET_CUSTOM_FOLDERS_R:
            return {
                ...state,
                folderList: []
            }
        case docsConstant.GET_CUSTOM_FOLDERS_S:
            return {
                ...state,
                folderList: action.data
            }
        case docsConstant.GET_CUSTOM_FOLDERS_F:
            return {
                ...state,
                folderList: []
            }
        case docsConstant.UPDATE_CURRENT_PATH_S:
            return {
                ...state,
                currentFolderPath: action.data
            }
        case docsConstant.COPY_DOC_S:
            if ('id' in action.data) {
                return {
                    ...(updateDocCount(state, action.data, 0)),
                    loading: false
                }
            } else {
                return {
                    ...state,
                    loading: false
                }
            }
        case docsConstant.MOVE_DOC_S:
            if ('id' in action.data) {
                return {
                    ...(updateDocCount(state, action.data, 1)),
                    loading: false
                }
            } else {
                return {
                    ...state,
                    loading: false
                }
            }

        // on root folder change
        case docsConstant.UPDATE_ROOT_FOLDER:
            return {
                ...state,
                selected_root_folder: action.data,
                loading: false
            }

        case docsConstant.UPDATE_SORT:
            return {
                ...state,
                sort: action.data,
            }

        case docsConstant.UPDATE_SEARCH:
            return {
                ...state,
                search: action.data,
            }
        case docsConstant.FILTER_DOC_COUNT_R:
            return {
                ...state,
            }

        case docsConstant.FILTER_DOC_COUNT_S:
            console.log("Response :", action.data)
            return {
                ...state,
                filterValues: {
                    ...state.filterValues,
                    not_deleted_count: action?.data?.not_deleted_count,
                    deleted_count: action?.data?.deleted_count
                }
            }

        case docsConstant.FILTER_DOC_COUNT_S:
            return {
                ...state,
            }

        case docsConstant.UPDATE_FILTER_STATE_S:
            return {
                ...state,
                filterValues: {
                    ...state.filterValues,
                    filterStateVal: action.data
                }
            }

        default:
            return state
    }
}

