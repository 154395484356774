import moment from "moment";
import { eventsConstant } from "../constants/eventConstant";

const initialState = {
    eventList: [],
    tasksList: [],
    bookingEventsList: [],
    googleEventsList: [],
    googleAgendaEventsList: [],
    eventData: {},
    eventView: 'day',
    dateRange: {
        start_date: new Date(),
        end_date: new Date()
    },
    emailAddress: '',
    currentView: "",
    authURL: '',
    tokens: {},
    googleMeetLink: null,
    event_date: null,
    agendaList: [],
    isLoading: false
}

export default function event(state = initialState, action) {
    switch (action.type) {
        /** GET EVENTS */
        case eventsConstant.GET_EVENT_R:
            return {
                ...state,
                googleMeetLink: initialState.googleMeetLink,
                eventList: initialState.eventList
            }
        case eventsConstant.GET_EVENT_S:
            return {
                ...state,
                googleMeetLink: initialState.googleMeetLink,
                eventList: (action?.data?.data?.events) || [],
                tasksList: (action?.data?.data?.tasks) || [],
                bookingEventsList: (action?.data?.data?.bookings) || [],
            }
        case eventsConstant.GET_EVENT_F:
            return {
                ...state,
                eventList: initialState.eventList,
                googleMeetLink: initialState.googleMeetLink,
                tasksList: initialState.tasksList,
                bookingEventsList: initialState.bookingEventsList
            }
        case eventsConstant.GET_AGENDA_EVENT_R:
            return {
                ...state,
                agendaList: initialState.agendaList,
                isLoading: true
            }
        case eventsConstant.GET_AGENDA_EVENT_S:
            return {
                ...state,
                agendaList: [
                    ...action?.data?.data?.events,
                    ...action?.data?.data?.tasks,
                    ...action?.data?.data?.bookings
                ] || [],
                isLoading: false
            }
        case eventsConstant.GET_AGENDA_EVENT_F:
            return {
                ...state,
                agendaList: initialState.agendaList,
                isLoading: false
            }
        case eventsConstant.GET_SELECTED_EVENT_R:
            return {
                ...state,
                eventData: initialState.eventData
            }
        case eventsConstant.GET_SELECTED_EVENT_S:
            return {
                ...state,
                eventData: action?.data?.data
            }
        case eventsConstant.GET_SELECTED_EVENT_F:
            return {
                ...state,
                eventData: initialState.eventData
            }
        case eventsConstant.GET_GOOGLE_ACCOUNT_R:
            return {
                ...state,
                googleEventsList: initialState.googleEventsList
            }
        case eventsConstant.GET_GOOGLE_ACCOUNT_S:
            // handle google disconnect on accounts page
            const respData = (action?.data?.data) || [];
            if (respData && Array.isArray(respData)) {
                return {
                    ...state,
                    googleEventsList: (action?.data?.data) || [],
                }
            } else if (respData?.error && respData?.error === "UNAUTHENTICATED") {
                localStorage.setItem('emailtoken', '')
                localStorage.setItem('emailAddress', '')
                return {
                    ...state,
                    googleEventsList: [],
                    emailAddress: ''
                }
            } else {
                return {
                    ...state,
                    googleEventsList: [],
                }
            }
        case eventsConstant.GET_GOOGLE_ACCOUNT_F:
            return {
                ...state,
                googleEventsList: initialState.googleEventsList
            }
        case eventsConstant.GET_GOOGLE_EMAIL_R:
            return {
                ...state,
                emailAddress: initialState.emailAddress
            }
        case eventsConstant.GET_GOOGLE_EMAIL_S:
            return {
                ...state,
                emailAddress: (action?.data) || '',
            }
        case eventsConstant.GET_GOOGLE_EMAIL_F:
            return {
                ...state,
                emailAddress: initialState.emailAddress
            }

        // update event
        case eventsConstant.UPDATE_EVENT_REDUCER:
            return {
                ...state,
                dateRange: action.data,
                eventData: action.data
            }

        case eventsConstant.UPDATE_EVENTVIEW_REDUCER:
            return {
                ...state,
                currentView: action.data
            }

        // update event view
        case eventsConstant.GET_EVENTVIEW_R:
            return {
                ...state,
                // eventView: initialState.eventView,
                // event_date:initialState.event_date
            }
        case eventsConstant.GET_EVENTVIEW_S:
            return {
                ...state,
                eventView: action?.data?.data.view,
                event_date: action?.data?.data.date,
            }
        case eventsConstant.GET_EVENTVIEW_F:
            return {
                ...state,
                // eventView: initialState.eventView,
                // event_date: initialState.event_date
            }

        // update Google Auth URL
        case eventsConstant.GET_GOOGLE_AUTH_URL_R:
            return {
                ...state,
                authURL: initialState.authURL
            }
        case eventsConstant.GET_GOOGLE_AUTH_URL_S:
            return {
                ...state,
                authURL: action?.data?.data?.authUrl
            }
        case eventsConstant.GET_GOOGLE_AUTH_URL_F:
            return {
                ...state,
                authURL: initialState.authURL
            }

        // update Google Token
        case eventsConstant.GET_GOOGLE_TOKEN_R:
            return {
                ...state,
                tokens: initialState.authURL
            }
        case eventsConstant.GET_GOOGLE_TOKEN_S:
            return {
                ...state,
                tokens: action?.data?.tokens
            }
        case eventsConstant.GET_GOOGLE_TOKEN_F:
            return {
                ...state,
                tokens: initialState.authURL
            }

        // Disconnect Google Account
        case eventsConstant.DISCONNECT_GOOGLE_ACCOUNT_S:
            return {
                ...state,
                googleEventsList: initialState.googleEventsList,
                emailAddress: initialState.emailAddress
            }

        case eventsConstant.GET_MEET_LINK:
            return {
                ...state,
                googleMeetLink: action.data
            }

        case eventsConstant.DELETE_EVENT_S:
            return {
                ...state,
                agendaList: state.agendaList.filter(item => (item.id !== action.data))
            }
        case eventsConstant.GET_AGENDA_GOOGLE_EVENTS_R:
            return {
                ...state,
                googleAgendaEventsList: initialState.googleAgendaEventsList
            }
        case eventsConstant.GET_AGENDA_GOOGLE_EVENTS_S:
            // handle google disconnect on accounts page
            const resData = (action?.data?.data) || [];
            if (resData && Array.isArray(resData)) {
                return {
                    ...state,
                    googleAgendaEventsList: (action?.data?.data) || [],
                }
            } else if (resData?.error && resData?.error === "UNAUTHENTICATED") {
                localStorage.setItem('emailtoken', '')
                localStorage.setItem('emailAddress', '')
                return {
                    ...state,
                    googleAgendaEventsList: [],
                    emailAddress: ''
                }
            } else {
                return {
                    ...state,
                    googleAgendaEventsList: [],
                }
            }
        case eventsConstant.GET_AGENDA_GOOGLE_EVENTS_F:
            return {
                ...state,
                googleAgendaEventsList: initialState.googleAgendaEventsList
            }
        case eventsConstant.GET_AGENDA_EVENT_TITLE:
            const updated_obj = action?.data?.data?.updated_data;
            const index = state.agendaList.findIndex(item => item?.id === action?.data?.data?.id);
            if (index !== -1) {
                const updatedAgendaList = [...state.agendaList];
                updatedAgendaList[index] = { ...updatedAgendaList[index], ...updated_obj };
                return {
                    ...state,
                    agendaList: updatedAgendaList,
                };
            }
            return state 

        case eventsConstant.UPDATE_INLINE_TASK_S:
            let updatedTask = state.tasksList?.find(task => task.id === action.data.data.id);
            const getDate = (start_date, start_time) => {
                const dateInLocalTimeZone = moment( `${start_date}T${moment(start_time, 'hh:mm A').format('HH:mm')}`).tz(moment.tz.guess());
                const formattedDate = dateInLocalTimeZone.format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
                return formattedDate
            }
            const start = getDate(action.data.newValue.start_date, action.data.newValue.start_time)
            const end = getDate(action.data.newValue.due_date, action.data.newValue.end_time)
            updatedTask = { ...updatedTask, ...action.data.newValue, start, end };
            const taskIndex = state.tasksList?.findIndex(obj => obj.id === action.data.data.id);
            const updatedTasksList = [...state.tasksList];
            if (taskIndex !== -1) {
                updatedTasksList[taskIndex] = updatedTask; 
            }
            return {
                ...state,
                tasksList: updatedTasksList
            };

        default:
            return state
    }
}