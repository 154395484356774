import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";

//import components
import { TextField } from "../../components";
import { SearchCloseIcon, SearchIcon3 } from "../../assets/svg";
import styles from "./styles";
import { InputAdornment } from "@material-ui/core";

const SearchBarComponent = (props) => {
  const { 
      id, fullWidth, classes, grow, 
      className, placeholder, initSearch, autoFocus 
  } = props;

  //Toggle button for list and chord view
  const toggleSearch = false
  const [value, setValue] = useState(toggleSearch === false ? '' : props.value);

  const handleSearchClear = () => {
    setValue("");
    initSearch("");
  }

  //update search and timeout
  useEffect(() => {
    const delaySet = setTimeout(() => {
      if (value !== props.value) {
        initSearch(value)
      }
    }, 600)
    return () => clearTimeout(delaySet)
  }, [value]);

  //update search value
  useEffect(() => {
    setValue(props.value)
  }, [props.value]);


  return (
    <>
      <Grid className={`${"searchBoxGrid"} ${classes.searchBoxGrid}`}>
        <TextField
          id={id}
          variant="outlined"
          size="small"
          margin="none"
          style={{ grow: grow }}
          autoFocus={autoFocus}
          fullWidth={fullWidth}
          placeholder={placeholder}
          className={`${className} ${"searchBox"}`}
          value={value}
          onChange={(e) => { 
            setValue(e.target.value);
            if (!e.target.value) {
              handleSearchClear()
            } 
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end" onClick={() => handleSearchClear()}>
              <IconButton  className="p5" edge="end">
                {value ? <SearchCloseIcon className={"seachCloseIcon"} /> : <SearchIcon3 className={classes.seachIcon} />}
              </IconButton>
            </InputAdornment>,
          }}
        />
      </Grid>
    </>
  );
};

// default props
SearchBarComponent.defaultProps = {
  id: "dxzc",
  fullWidth: true,
  autoFocus: false,
  classes: {},
  grow: '1',
  placeholder: "search",
  initSearch: () => { },
  clearSearch: () => { },
};

// prop types
SearchBarComponent.propTypes = {
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.object,
  grow: PropTypes.string,
  placeholder: PropTypes.string,
  initSearch: PropTypes.func,
  clearSearch: PropTypes.func,
};

export const SearchBar = withStyles(styles)(SearchBarComponent);
