import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles, CircularProgress, Grid, Dialog, Typography, Box, IconButton, Tooltip } from '@material-ui/core';
import WorkSpaceLobbyStyles from "./style";
import { WorkSpaceThunder, CloseIconNew, CompleteIcon, CompleteThunder } from "../../assets/svg/index";
import { workspaceCloningActions } from "../../redux/actions/workspaceAction"
import SkipButton from './SkipButton';
import { returnSvg } from "./IconsMap"
const StyledLinearProgress = withStyles({
  root: {
    height: 8,
  },
  colorPrimary: {
    backgroundColor: '#D3D3D3',
  },
  bar: {
    background: 'linear-gradient(90deg, #2563EB 14.49%, #8361FD 91.36%)',
    borderRadius: 5,
  },
})(CircularProgress);


const InfoBox = (props) => {
  const { classes, cloningProgress, socketEventData } = props;
  const actionType = socketEventData?.action_type?.toLowerCase() || "flow"; // Ensure lowercase for consistency
  const IconComponent = returnSvg(actionType);
  return (
    <>
      <Grid className={classes.videoFooter} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Grid className={classes.header}>
          <div style={{ display: "flex", gap: "10px" }}>
            {
              cloningProgress === 100 ? <CompleteThunder /> : <WorkSpaceThunder />
            }
            <Typography >{cloningProgress === 100 ? "Workspace ready, let's get started" : "Powering Up"}</Typography>
          </div>
          <div style={{ fontSize: "12px" }}>{cloningProgress === 100 ? "Time to take control and get productive!" : `Almost there! We're busy building your custom workspace and setting up ${socketEventData?.action_type ? socketEventData?.action_type[0]?.toUpperCase() + socketEventData?.action_type.slice(1) + '...' : ''}`}</div>
        </Grid>

        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: "44px", height: "48px" }}>
          {
            cloningProgress === 100 ?
              <CompleteIcon /> :
              (
                <>
                  <div className={classes.loader}></div>
                  <div className={classes.text}>
                    {/* Render the dynamic icon */}
                    {IconComponent ? <IconComponent /> : null}
                  </div>
                </>
              )
          }

        </Grid>
      </Grid>
    </>
  )
}

function WorkSpaceLobby(props) {
  const dispatch = useDispatch();
  const { classes } = props;
  const { cloningProgress, clonedSuccessStatus, socketEventData,introVideo } = useSelector(state => state.workSpaceCloning);

  const videoRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false); // Tooltip is initially hidden
  const [timeLeft, setTimeLeft] = useState(30); // Timer for 30 seconds
  const [open, setOpen] = useState(true); // Dialog state
  const [isPlaying, setIsPlaying] = useState(false); // Video playing state

  const item = {
    id: 1,
    file_url: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/WhatCarCanYouGetForAGrand.mp4",
    subtitle: "By Garage419",
    thumb: "images/WhatCarCanYouGetForAGrand.jpg",
    name: "What car can you get for a grand?",
    category: "VIDEO"
  };

  const handleClose = () => {
    if(cloningProgress === 100){
      completeCloning()
    }else{
      setOpen(false); // Close the dialog
    }
  };

  const openHandler = () => {
    // Show tooltip if the user tries to close before the timer hits 0
    if (timeLeft > 0) {
      setShowTooltip(true);
    } else if (cloningProgress === 100 && open) {
      handleClose()
    } else if(cloningProgress === 100){
      completeCloning()
    }
    else {
      handleClose(); 
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    const timer = setInterval(() => {
      setTimeLeft(prev => {
        if (prev > 1) return prev - 1;
        clearInterval(timer);
        setShowTooltip(false);
        return 0;
      });
    }, 1000);


    if (videoElement) {
      videoElement.play();
      setIsPlaying(true);
    }

    return () => clearInterval(timer); // Cleanup timer when the component unmounts
  }, []);

  useEffect(() => {
    dispatch(workspaceCloningActions.cloneWorkSpace())
  }, [])

  function completeCloning() {
    dispatch(workspaceCloningActions.completeClonningProcess())
    window.location.reload()
  }
  useEffect(() => {
    let timeoutId;

    if (cloningProgress === 100 && !open) {
      timeoutId = setTimeout(() => {
        completeCloning();
      }, 4000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [cloningProgress]);

  return (
    <React.Fragment>
      {open ? (
        <Dialog
          fullScreen
          open={true}
          onClose={handleClose}
          PaperProps={{
            style: {
              backgroundColor: '#000000aa',
              boxShadow: 'none',
              // zIndex:1
            },
          }}
          className={classes.previewDialogContainer}
        >
          <Grid className={classes.galleryContainer}>
            <Grid className={classes.videoContainer}>

              {
                timeLeft < 1  ? 
                <SkipButton onSkip={openHandler} skipDuration={timeLeft} /> : null
              }
              
              {/* <Grid onClick={openHandler} style={{ zIndex: 1 }}>
                <SkipButton onSkip={()=> openHandler()}/>

                <IconButton aria-label="close" className={classes.closeButton} onClick={openHandler} style={{ display: "block" }}>
                    <CloseIconNew />
                  
                    <Box style={{ zIndex: 1 }} className='customTooltip'>
                      You can close this video in 30s
                    </Box>
                  <Tooltip title={showTooltip ? "You can close this video in 30s" : ""} open={showTooltip}>
                    
                    {
                      showTooltip ? 
                      <Box style={{ zIndex: 1 }} className='customTooltip'>
                    You can close this video in 30s
                    </Box> : <div></div>
                    }

                  </Tooltip>
                </IconButton>
              </Grid> */}

              <video
                ref={videoRef} // Reference to video element
                src={introVideo}
                width="100%"
                height="auto"
                preload="auto"
                id="videoPlayer"
                playsInline
                loop
                controls
                autoPlay // Auto-play the video
                style={{ zIndex: 10 }}
              />
              <InfoBox classes={{ ...classes }} cloningProgress={cloningProgress} socketEventData={socketEventData} />
            </Grid>
          </Grid>
        </Dialog>
      ) : (
        <Grid className={classes.previewSmallContainer}>
          <InfoBox classes={{ ...classes }} cloningProgress={cloningProgress} socketEventData={socketEventData} />
        </Grid>
      )}
    </React.Fragment>
  );
}

WorkSpaceLobby.defaultProps = {
  classes: {},
};

WorkSpaceLobby.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(WorkSpaceLobbyStyles)(WorkSpaceLobby);


// const [gif,setGif] = useState(Checking)
// useEffect(()=>{
//   if(cloningProgress <=70){
//     setGif(Checking)
//   }else if(cloningProgress === 90){
//     setGif(Finish)
//   }
// },[cloningProgress])

// useEffect(()=>{
//   dispatch(workspaceCloningActions.cloneWorkSpace())
// },[])