import React, { Fragment, useEffect, useRef, useState } from "react";
import { Avatar, Box, Grid,  Tooltip,Typography, withStyles } from "@material-ui/core";
import style from "./style";
import { useSelector } from "react-redux";
import { UserAudioMuteIcon } from "../../../assets/svg";
import SoundWaveSticks from "./SoundWaveSticks";
import AvatarRipple from "./AvatarRipple"


function VCParticipant({
  user,
  classes,
  joined,
  localVideoTrack,
  attendees,
  isVideoMuted,
  screenTrack,
  screenShare,
  user_id,
  profile,
  videoWidth,
  videoHeight,
  allUsers,
  channelStatus,
  localUser,
  userMetaData,
  talkingAttendees,
  validationTime,
  channelName,
  remoteAttendees,
  resubscribe,
  audioMuted,
  username,
  volume,
  parentWidth,
  videoStyle
}) {

  const videoRef = useRef();
  const screenShareRef = useRef();
  const [isAttendeeTalking, setIsAttendeeTalking] = useState(false);
  const [userInfo,setUserInfo] = useState(null);
  // const videoContainer = useRef(null);

  const screenShareVideo = useSelector(
    (state) => state.videocall.screenShareVideo
  );

  const playVideoTrack = async (videoTrack, ref, contain=false) => {
    try{      
      let videoConfig = {fit: `${contain ? 'contain' : 'cover'}`, mirror : screenTrack ? false : true}
      if (ref.current && videoTrack && !videoTrack.closed) {
        if (typeof videoTrack.play === 'function') {
          videoTrack.play(`participant_track_${user?.uid}`, videoConfig);
        }
      }
    } catch(e){
      setTimeout(() => {
        playVideoTrack(videoTrack, `participant_track_${user?.uid}`, contain)
      }, 1000)
    }
  };

  useEffect(() => {
    if(talkingAttendees && talkingAttendees.includes(`${user?.uid}`)){
      setIsAttendeeTalking(true);
    } else {
      setIsAttendeeTalking(false);
    }
  }, [talkingAttendees])

  useEffect(() => {
    if(user.hasVideo && !user.videoTrack){
      resubscribe(user.uid, 'video')
    }
    if(user.hasAudio && !user.audioTrack){
      resubscribe(user.uid, 'audio')
    }
  }, [user?.videoTrack, user?.hasVideo, user?.audioTrack, user?.hasAudio])

  useEffect(async () => {
    if (screenShare) {
      if (screenTrack && !screenTrack.closed) {
       await playVideoTrack(screenTrack, videoRef, true);
      } else {
        await playVideoTrack(user.videoTrack, videoRef, true);
      }
    } else if (user && user.videoTrack) {
      await playVideoTrack(user.videoTrack, videoRef, false);
    } else if (localVideoTrack && !localVideoTrack.closed && !screenTrack && !screenShare) {
      await playVideoTrack(localVideoTrack, videoRef, false);
    } else if (localVideoTrack && !screenTrack && !screenShare) {
      await playVideoTrack(localVideoTrack, videoRef, false);
    }

    return () => {
      if (screenShare) {
        if (screenTrack && !screenTrack.closed) {
          screenTrack.stop();
          screenTrack.close();
        } else {
          if (localVideoTrack && !localVideoTrack.closed) {
            localVideoTrack.stop();
            if (localVideoTrack.setEnabled) {
              localVideoTrack.setEnabled(false);
            }
            localVideoTrack.close();
          }
        }

        if (Array.isArray(screenShareVideo) && screenShareVideo.length > 0) {
          screenShareVideo.forEach((shareUser) => {
            if (shareUser.screenTrack && !shareUser.screenTrack.closed) {
              shareUser.screenTrack.stop();
              shareUser.screenTrack.close();
            }
          });
        }
      }
    };

  }, [user, user?.videoTrack, isVideoMuted, localVideoTrack, screenShareVideo, screenShare, screenTrack, validationTime]);

  let matchedUsers = [];

  if (attendees && attendees.length > 0 && allUsers && allUsers.length > 0) {
    attendees.forEach((attendee) => {
      const matchedUser = allUsers.find((user) => user.id === attendee.user.uid);
      if (matchedUser) {
        matchedUsers.push(matchedUser);
      }
    });
  }

useEffect(() => {
  if(channelStatus && channelStatus[channelName]){
    const channelInfo = channelStatus[channelName]
    const userObj = channelInfo[user.uid]
    setUserInfo(userObj);
  }
}, [user, channelStatus])

  let userVolume
  if (joined) {
    userVolume = volume?.find((vol) => vol.uid === String(user.uid))?.level || 0;
  }


return (
  
  <div
    className={`${!screenShare ? classes.RemoteVideoStyle : classes.ScreenshareStyle}`}
    style={{
      width:videoWidth || "auto",
      height: videoHeight || "100%",
      ...(!screenShare && userVolume > 55 ?  {border: '2px solid #2563EB', borderRadius : '10px'} :  { border: 'none' }),
      ...(joined ? {} : videoStyle)
    }}
    id={user.uid}
    ref={videoRef}
  >
    {/* Agora adds video track when track.play() function is called */}
    <video
      className={screenShare || screenTrack ? classes.screenShareVideo : classes.attendee}
      id={`participant_track_${user?.uid}`}
    />

    {
      userInfo && userInfo.name && userInfo.name !== '' &&
      <div className={remoteAttendees ? classes.username : classes.remoteUserName}>
        <Tooltip title={userInfo.name}>
          <Typography style={{ color: "white" }} >
            { parentWidth < 768 ? userInfo.name.length > 10 ? `${userInfo.name.substring(0, 13)}...`: userInfo.name: userInfo.name.length > 30 ? remoteAttendees ? `${userInfo.name.substring(0, 10)}...`: `${userInfo.name.substring(0, 30)}...`: userInfo.name}
          </Typography>
        </Tooltip>
      </div>
    }

    {
      // Show only Avatar when video is muted and screen share is not enabled
      !screenShare && !screenTrack && 
      ("hasVideo" in user && !user?.hasVideo) && //check if hasVideo is in prototype. hasOwnProperty will not work if the field is in prototype
      <Grid className={classes.MuteVideoContainer}>
          <Fragment>        
          <Grid className={classes.avatarContainer}>
              {userVolume > 55 && <AvatarRipple remoteAttendees={remoteAttendees} /> }
              <Avatar
                className={`${remoteAttendees ? classes.userAvatar : classes.UnPinUserMuteAvatar}`}
                src={userInfo?.avatar || null}
              >
                {!userInfo?.avatar && userInfo?.name?.charAt(0)?.toUpperCase()}
              </Avatar>
            </Grid>
          </Fragment>
      </Grid>
    }
    {
      !joined &&  (user?.videoTrack?._enabled === false || isVideoMuted) &&
        <Grid className={classes.MuteVideoContainer}>
          <Grid className={classes.avatarContainer}>
          <Avatar
                className={classes.UnPinUserMuteAvatar}
              src={profile?.avatar_filename}
              
            >   
            {!profile?.avatar_filename && username?.charAt(0)?.toUpperCase()}
          
          </Avatar>
          </Grid>
        </Grid>
    }
    { 
      //check if hasAudio is in prototype. hasOwnProperty will not work if the field is in prototype
      (("hasAudio" in user && !user?.hasAudio) || audioMuted) &&
      <Grid className={classes.AudioMuteIcon}><UserAudioMuteIcon /></Grid>
    }
    {
      !audioMuted && userVolume > 55 &&
      <Grid className={classes.AudioPlayIcon}>
        <SoundWaveSticks />
      </Grid>
    }
  </div>
  )
}

export default withStyles(style)(VCParticipant);
