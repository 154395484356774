import { baseAction } from "./baseAction";
import { scheduleConstant, loaderConstant, alertConstant } from "../constants";
import { scheduleService } from "../services/scheduleService";

const { request, success, failure } = baseAction;

export const scheduleAction = {
    getScheduleMessageList,
    getSearchFlowList,
    getSearchClientList,
    getSearchTeamList,
    getMessageDetail,
    saveScheduleGroup,
    getSelectedGroupIds,
    getAddedMessage,
    getScheduleFilter,
    saveSchedule,
    saveAddMessageInput,
    deleteAddedMessage,
    uploadMessageFiles,
    updateScheduleMessage,
    updateMessageStatus,
    deleteMessageItem
}

/** Get Schedule Message List */
function getScheduleMessageList(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.GET_SCHEDULE_MESSAGE_LIST_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
    
        scheduleService.getScheduleMessageList(params).then(data => {
            dispatch(success(scheduleConstant.GET_SCHEDULE_MESSAGE_LIST_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack()
        }, error => {
            dispatch(failure(scheduleConstant.GET_SCHEDULE_MESSAGE_LIST_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        })
    }
}

/** Get Search Flow List */

function getSearchFlowList(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.GET_SEARCH_FLOW_LIST_R, params));
        scheduleService.getSearchFlowList(params).then(data => {
            dispatch(success(scheduleConstant.GET_SEARCH_FLOW_LIST_S, data));
            callBack()
        }, error => {
            dispatch(failure(scheduleConstant.GET_SEARCH_FLOW_LIST_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

/** Get Search Client List */

function getSearchClientList(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.GET_SEARCH_CLIENT_LIST_R, params));
        scheduleService.getSearchClientList(params).then(data => {
            dispatch(success(scheduleConstant.GET_SEARCH_CLIENT_LIST_S, data));
            callBack()
        }, error => {
            dispatch(failure(scheduleConstant.GET_SEARCH_CLIENT_LIST_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

/** Get Search Teams List */

function getSearchTeamList(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.GET_SEARCH_TEAM_LIST_R, params));
        scheduleService.getSearchTeamList(params).then(data => {
            dispatch(success(scheduleConstant.GET_SEARCH_TEAM_LIST_S, data));
            callBack()
        }, error => {
            dispatch(failure(scheduleConstant.GET_SEARCH_TEAM_LIST_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

/** Get Message Details */

function getMessageDetail(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.GET_MESSAGE_DETAIL_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        scheduleService.getMessageDetail(params).then(data => {
            dispatch(success(scheduleConstant.GET_MESSAGE_DETAIL_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack()
        }, error => {
            dispatch(failure(scheduleConstant.GET_MESSAGE_DETAIL_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

/** Save Flows, Client, Direct Schedule Group */

function saveScheduleGroup(body, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.SAVE_SCHEDULE_GROUP_R, body));
        dispatch(request(loaderConstant.LOADING_TRUE));
        scheduleService.saveScheduleGroup(body).then(data => {
            dispatch(success(scheduleConstant.SAVE_SCHEDULE_GROUP_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack(data.data)
        }, error => {
            dispatch(failure(scheduleConstant.SAVE_SCHEDULE_GROUP_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        )
    }
}

/** Update Flows, Client, Direct Schedule Group */

function saveSchedule(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.SAVE_SCHEDULE_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        scheduleService.saveSchedule(params).then(data => {
            dispatch(success(scheduleConstant.SAVE_SCHEDULE_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack(data.data)
        }, error => {
            dispatch(failure(scheduleConstant.SAVE_SCHEDULE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        )
    } 
}

/** Get Selected Schedule Group Ids */

function getSelectedGroupIds(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.GET_SELECTED_SCHEDULE_IDS_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        scheduleService.getSelectedGroupIds(params).then(data => {
            dispatch(success(scheduleConstant.GET_SELECTED_SCHEDULE_IDS_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack()
        }, error => {
            dispatch(failure(scheduleConstant.GET_SELECTED_SCHEDULE_IDS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        })
    }
}

/** Get Selected Schedule Group Ids */

function getAddedMessage(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.GET_ADDED_SCHEDULE_MESSAGE_R, params));
        scheduleService.getAddedMessage(params).then(data => {
            dispatch(success(scheduleConstant.GET_ADDED_SCHEDULE_MESSAGE_S, data));
            callBack()
        }, error => {
            dispatch(failure(scheduleConstant.GET_ADDED_SCHEDULE_MESSAGE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

/** Save Added Message */

function saveAddMessageInput(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.SAVE_ADD_SCHEDULE_MESSAGE_INPUT_R, params));
        scheduleService.saveAddMessageInput(params).then(data => {
            dispatch(success(scheduleConstant.SAVE_ADD_SCHEDULE_MESSAGE_INPUT_S, data));
            callBack(data.data)
        }, error => {
            dispatch(failure(scheduleConstant.SAVE_ADD_SCHEDULE_MESSAGE_INPUT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

/** Schedule Filters **/
function getScheduleFilter(params, callBack = () => { }) {
    return (dispatch) => {
        
        dispatch(request(loaderConstant.LOADING_TRUE));
        dispatch(request(scheduleConstant.GET_FILTER_LIST_R, params));
        scheduleService.getScheduleFilter(params).then(data => {
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(success(scheduleConstant.GET_FILTER_LIST_S, data));
            callBack()
        }, error => {
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(failure(scheduleConstant.GET_FILTER_LIST_F, error));
        }
        );
    };
}

/** Update Schedule Message */

function updateScheduleMessage(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.UPDATE_SCHEDULE_MESSAGE_R, params));
        scheduleService.updateScheduleMessage(params).then(data => {
            dispatch(success(scheduleConstant.UPDATE_SCHEDULE_MESSAGE_S, data));
            callBack(data.data)
        }, error => {
            dispatch(failure(scheduleConstant.UPDATE_SCHEDULE_MESSAGE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

/** Update Schedule Message Status */
function updateMessageStatus(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.UPDATE_SCHEDULE_MESSAGE_STATUS_R, params));
        scheduleService.updateMessageStatus(params).then(data => {
            dispatch(success(scheduleConstant.UPDATE_SCHEDULE_MESSAGE_STATUS_S, data));
            callBack(data.data)
        }, error => {
            dispatch(failure(scheduleConstant.UPDATE_SCHEDULE_MESSAGE_STATUS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

/** Upload Message Files */
function uploadMessageFiles(payload, params, paramsIds, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.UPLOAD_MESSAGE_FILES_R, params));
        scheduleService.uploadMessageFiles(payload, paramsIds).then(data => {
            data["temp_id"] = params?.preview_msg?.tempMessage?.id || 0;
            if (Array.isArray(data.data)) {
                dispatch(success(scheduleConstant.UPLOAD_MESSAGE_FILES_S, data));
            } else {
                dispatch(success(scheduleConstant.UPLOAD_MESSAGE_FILES_S, { ...data, data: [data.data] }));
            }
            callBack();
        }, error => {
            dispatch(failure(scheduleConstant.UPLOAD_MESSAGE_FILES_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

/** Delete Added Message */

function deleteAddedMessage(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.DELETE_ADDED_SCHEDULE_MESSAGE_R, params));
        scheduleService.deleteAddedMessage(params).then(data => {
            dispatch(success(scheduleConstant.DELETE_ADDED_SCHEDULE_MESSAGE_S, data));
            callBack()
        }, error => {
            dispatch(failure(scheduleConstant.DELETE_ADDED_SCHEDULE_MESSAGE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        })
    }
}

/** Delete Message Item */

function deleteMessageItem(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(scheduleConstant.DELETE_MESSAGE_ITEM_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        scheduleService.deleteMessageItem(params).then(data => {
            dispatch(success(scheduleConstant.DELETE_MESSAGE_ITEM_S, params));
            dispatch(request(loaderConstant.LOADING_FALSE));
            callBack()
        }, error => {
            dispatch(failure(scheduleConstant.DELETE_MESSAGE_ITEM_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        })
    }
}