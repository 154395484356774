const style = (theme) => ({
    cardHeader: {
        padding: '16px 16px 0px 16px',
        '& .MessageIcon': {
            width: 20,
            marginBottom: -4
        },
        '& .CloseIcon':{
            width: 12
        }
    },
    notificationCard: {
        maxWidth: 400,
        minWidth:312
    },
    replyThumb:{
        maxHeight: "5rem",
    }
});

export default style