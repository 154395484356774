let API_POINTS = {
  url: "http://localhost:8080/api/v1",
  socketUrl: "http://localhost:8080/",
  socketv2Url: "http://localhost:8081/",
  docSocketURL: "ws://localhost:1234",
  pageBuilder: "http://localhost:8000/api/v1",
  appUrl: "http://localhost:3000",
  puppeter:"http://localhost:8090",
  ai:"https://devinfiniti.flozy.com",
  iconApi: "https://assets.agenciflow.com"
};

const SERVER_POINT = process.env.REACT_APP_BUILD_TO || "LOCAL"; // QA, UAT, PROD, LOCAL

switch (SERVER_POINT) {
  case "PROD":
    API_POINTS = {
      url: "https://server.flozy.com/api/v1",
      socketUrl: "wss://server.flozy.com/",
      socketv2Url: "http://localhost:8081/",
      appUrl: "https://app.flozy.com",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://site.flozy.com/api/v1",
      customDomain: "https://host.flozy.com/api/v1",
      widgetUrl: "https://app.agenciflow.com/widget/embed-app.js",
      puppeter:"https://puppeterserver.agenciflow.com",
      ai:"https://prodinfiniti.flozy.com/",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "TEST":
    API_POINTS = {
      url: "https://test-server.agenciflow.com/api/v1",
      socketUrl: "wss://test-server.agenciflow.com",
      socketv2Url: "http://localhost:8081/",
      appUrl: "https://test.agenciflow.com",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://qa-pages.agenciflow.com/api/v1",
      customDomain: "https://uat-hostapp.agenciflow.com/api/v1",
      widgetUrl: "https://qa.agenciflow.com/widget/embed-qa.js",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "UAT":
    API_POINTS = {
      url: "https://uat-server.agenciflow.com/api/v1",
      socketUrl: "wss://uat-server.agenciflow.com",
      socketv2Url: "http://localhost:8081/",
      appUrl: "https://uat.agenciflow.com",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://uat-pages.agenciflow.com/api/v1",
      customDomain: "https://uat-hostapp.agenciflow.com/api/v1",
      widgetUrl: "https://uat.agenciflow.com/widget/embed-uat.js",
      puppeter:"https://puppeterserver.agenciflow.com",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "STAGE":
    API_POINTS = {
      url: "https://stage-server.agenciflow.com/api/v1",
      socketUrl: "wss://stage-server.agenciflow.com",
      socketv2Url: "http://localhost:8081/",
      appUrl: "https://stage.agenciflow.com",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://stage-pages.agenciflow.com/api/v1",
      customDomain: "https://qa-host.agenciflow.com/api/v1",
      widgetUrl: "https://stage.agenciflow.com/widget/embed-stage.js",
      puppeter:"https://puppeterserver.agenciflow.com",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "STAGE-QA":
    API_POINTS = {
      url: "https://stage-qaserver.agenciflow.com/api/v1",
      socketUrl: "wss://stage-qaserver.agenciflow.com",
      socketv2Url: "http://localhost:8081/",
      appUrl: "https://stage-qa.agenciflow.com",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://stage-pages.agenciflow.com/api/v1",
      customDomain: "https://qa-host.agenciflow.com/api/v1",
      widgetUrl: "https://stage-qa.agenciflow.com/widget/embed-stage-qa.js",
      puppeter:"https://puppeterserver.agenciflow.com",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "DEMO":
    API_POINTS = {
      url: "https://demo-server.flozy.com/api/v1",
      socketUrl: "wss://demo-server.flozy.com",
      socketv2Url: "http://localhost:8081/",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://demo-pages.flozy.com/api/v1",
      customDomain: "https://demo-hostapp.flozy.com/api/v1",
      appUrl: "https://demo.flozy.com",
      widgetUrl: "https://qa.agenciflow.com/widget/embed-qa.js",
      puppeter:"https://puppeterserver.agenciflow.com",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "QA":
    API_POINTS = {
      url: "https://qa-server.agenciflow.com/api/v1",
      socketUrl: "wss://qa-server.agenciflow.com",
      socketv2Url: "http://localhost:8081/",
      appUrl: "https://qa.agenciflow.com",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://qa-pages.agenciflow.com/api/v1",
      customDomain: "https://qa-host.agenciflow.com/api/v1",
      widgetUrl: "https://qa.agenciflow.com/widget/embed-qa.js",
      puppeter:"https://puppeterserver.agenciflow.com",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "DEV":
    API_POINTS = {
      url: "https://dev-server.agenciflow.com/api/v1",
      socketUrl: "wss://dev-server.agenciflow.com",
      socketv2Url: "http://localhost:8081/",
      appUrl: "https://dev.agenciflow.com",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://dev-pages.agenciflow.com/api/v1",
      customDomain: "https://qa-host.agenciflow.com/api/v1",
      widgetUrl: "https://dev.agenciflow.com/widget/embed-dev.js",
      puppeter:"https://puppeterserver.agenciflow.com",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "APP_PROD":
    API_POINTS = {
      url: "https://server.flozy.com/api/v1",
      socketUrl: "wss://server.flozy.com/",
      socketv2Url: "http://localhost:8081/",
      appUrl: "https://app.flozy.com",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://site.flozy.com/api/v1",
      customDomain: "https://hostapp.flozy.com/api/v1",
      widgetUrl: "https://qa.agenciflow.com/widget/embed-qa.js",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "APP_STAGE":
    API_POINTS = {
      url: "https://stage-server.agenciflow.com/api/v1",
      socketUrl: "wss://stage-server.agenciflow.com",
      socketv2Url: "http://localhost:8081/",
      appUrl: "https://stage.agenciflow.com",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://stage-pages.agenciflow.com/api/v1",
      customDomain: "https://stage-hostapp.agenciflow.com/api/v1",
      widgetUrl: "https://qa.agenciflow.com/widget/embed-qa.js",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "APP_UAT":
    API_POINTS = {
      url: "https://uat-server.agenciflow.com/api/v1",
      socketUrl: "wss://uat-server.agenciflow.com",
      socketv2Url: "http://localhost:8081/",
      appUrl: "https://uat.agenciflow.com",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://uat-pages.agenciflow.com/api/v1",
      customDomain: "https://uat-hostapp.agenciflow.com/api/v1",
      widgetUrl: "https://qa.agenciflow.com/widget/embed-qa.js",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "DEMO":
    API_POINTS = {
      url: "https://demo-server.flozy.com/api/v1",
      socketUrl: "wss://demo-server.flozy.com/",
      socketv2Url: "http://localhost:8081/",
      widgetUrl: "https://qa.agenciflow.com/widget/embed-qa.js",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  case "TEST":
    API_POINTS = {
      url: "https://test-server.agenciflow.com/api/v1",
      socketUrl: "wss://test-server.agenciflow.com",
      socketv2Url: "http://localhost:8081/",
      appUrl: "https://test.agenciflow.com",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "https://qa-pages.agenciflow.com/api/v1",
      customDomain: "https://uat-hostapp.agenciflow.com/api/v1",
      widgetUrl: "https://qa.agenciflow.com/widget/embed-qa.js",
      puppeter:"https://puppeterserver.agenciflow.com",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
  default:
    API_POINTS = {
      url: "http://localhost:8080/api/v1",
      socketUrl: "http://localhost:8080/",
      socketv2Url: "http://localhost:8080/",
      appUrl: "http://localhost:3000",
      docSocketURL: "ws://localhost:1234",
      pageBuilder: "http://localhost:8000/api/v1",
      customDomain: "http://localhost:8888/api/v1",
      widgetUrl: "http://localhost:3000/widget/embed.js",
      puppeter:"http://localhost:8090",
      ai:"https://devinfiniti.flozy.com",
      iconApi: "https://assets.agenciflow.com"
    };
    break;
}
console.log(API_POINTS)

export const _api = API_POINTS;
