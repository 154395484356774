import React from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import iconImage from '../../assets/images/emoji/party-popper.png'

import styles from './styles'

const canvasStyles = {
    position: 'absolute',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 99
}

class Realistic extends React.Component {
    constructor(props) {
        super(props);
        this.animationInstance = null;
    }

    componentDidMount() {
        this.counter = setInterval(this.fire, 1000);;

        this.timer = setTimeout(
            () => this.stopFire(),
            10000,
        );
    }

    makeShot = (particleRatio, opts) => {
        this.animationInstance && this.animationInstance({
            ...opts,
            origin: { y: 0.7 },
            particleCount: Math.floor(200 * particleRatio),
            zIndex: 9,
        });
    }

    fire = () => {
        this.makeShot(0.25, {
            spread: 26,
            startVelocity: 55,
        });

        this.makeShot(0.2, {
            spread: 60,
        });

        this.makeShot(0.35, {
            spread: 100,
            decay: 0.91,
            scalar: 0.8,
        });

        this.makeShot(0.1, {
            spread: 120,
            startVelocity: 25,
            decay: 0.92,
            scalar: 1.2,
        });

        this.makeShot(0.1, {
            spread: 120,
            startVelocity: 45,
        });
    }

    stopFire = () => {
        clearInterval(this.counter)
    };

    getInstance = (instance) => {
        this.animationInstance = instance;
    };

    render() {
        const { classes, text_message } = this.props;
        return (
            <>
                <div className={classes.completeMsgContainer}>
                    <span className={classes.completeBox}>
                        {text_message}
                        <img src={iconImage} alt={text_message} />
                    </span>
                </div>
                <ReactCanvasConfetti refConfetti={this.getInstance} style={canvasStyles} />
            </>
        );
    }
}


// default props
Realistic.defaultProps = {
    classes: {},
    text_message: "",
};

// prop types
Realistic.propTypes = {
    classes: PropTypes.object,
    text_message: PropTypes.string,
};

export default withStyles(styles)(Realistic)