/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react'
import PropTypes from "prop-types";
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { Grid, withStyles } from "@material-ui/core";
import { Tooltip } from '@material-ui/core';

// Imports Styles
import styles from "./styles";

//Import Components 
import { DeleteDialogComponent } from '..';

import addLinkPlugin from './plugins/addLinkPlugin';
import BlockStyleControls from './BlockStyleControls';
import InlineStyleControls from './InlineStyleControls';

const linkifyPlugin = createLinkifyPlugin({
  target: '_blank',
  component: (props) => (
    <a {...props} onClick={() => window.open(props.href, '_blank')} />
  ),
});

/**
 * TextField Component
 * @param {*} props
 */
function TextEditor(props) {

  const { classes, deleteNote, linkButton, notesText, handleEditorState, handleDeleteNotes, blurEvent } = props;

  const notesContent = notesText ? convertFromRaw(JSON.parse(notesText)) : "";
  const initialState = notesContent ? EditorState.createWithContent(notesContent) : EditorState.createEmpty();
  const [editorState, setEditorState] = useState(initialState);
  const [clearText, setClearText] = useState(false);
  const [textCleared, setTextCleared] = useState(true);

  const onChange = (newEditorState) => {
    const contentState = newEditorState.getCurrentContent();
    const editorContent = convertToRaw(contentState);
    const prevState = notesText ? JSON.parse(notesText) : null;

    if (prevState && prevState.blocks[0].text.length > 5 && editorContent.blocks[0].text.length === 0 && !textCleared) {
      setClearText(true);
    } else {
      const notesContent = JSON.stringify(editorContent);
      handleEditorState(notesContent)
      setEditorState(newEditorState);
      setTextCleared(false);
    }
  }

  const onTab = (e) => {
    const maxDepth = 4;
    onChange(RichUtils.onTab(e, editorState, maxDepth))
  }

  const toggleBlockType = (blockType) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  }

  const toggleInlineStyle = (inlineStyle) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  }

  const handleKeyCommand = useCallback((command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return true;
    }
    return false;
  });

  //if Add link button enabled
  const onAddLink = () => {
    const selection = editorState.getSelection();
    const link = window.prompt('Paste the link -');
    if (!link) {
      onChange(RichUtils.toggleLink(editorState, selection, null));
      return 'handled';
    }
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity('LINK', 'MUTABLE', {
      url: link,
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      'create-entity'
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
    return 'handled';
  }

  const handleClearNotes = () => {
    const initialState = EditorState.createEmpty();
    setClearText(false);
    setTextCleared(true);
    setEditorState(initialState);
  }

  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case 'blockquote':
        return 'editor-blockquote';
      default:
        return null;
    }
  }

  const styleMap = {
    CODE: {
      backgroundColor: '#111',
      color: '#fff',
      fontSize: '1.5rem',
      padding: '2px',
    },
  };

  const editorPlugins = [linkifyPlugin, addLinkPlugin]
  return (
    <React.Fragment>
      <Grid className={classes.notesEditArea}>
        <Editor
          customStyleMap={styleMap}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={onChange}
          onTab={onTab}
          onBlur={blurEvent}
          placeholder="Start typing..."
          spellCheck={true}
          plugins={editorPlugins}
          blockStyleFn={getBlockStyle}
        />
      </Grid>

      <Grid className={classes.notesToolBar}>
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />

        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />

        {deleteNote ? (
          <button
            className={`${classes.customButtons} ${classes.deleteButton}`}
            title="delete"
            alt="delete"
            onClick={() => handleDeleteNotes()}
          >
            <Tooltip title="Delete"><i className="icon-garbage" /></Tooltip>
          </button>
        ) : null}

        {linkButton ? <button
          onClick={onAddLink}
          className={classes.customButtons}
        >
          <i className="icon-link" />
        </button> : null}
      </Grid>

      <DeleteDialogComponent
        open={clearText}
        content={"Do you really want to clear the notes text?"}
        handleDeleteAction={() => { handleClearNotes() }}
        handleCloseAction={() => setClearText(false)}
      />

    </React.Fragment>
  );
}

// default props
TextEditor.defaultProps = {
  variant: "outlined",
  deleteNote: false,
  linkButton: false,
  notesText: "",
  blurEvent: () => { }
};

// prop types
TextEditor.propTypes = {
  variant: PropTypes.string,
  deleteNote: PropTypes.bool,
  linkButton: PropTypes.bool,
  notesText: PropTypes.any,
  blurEvent: PropTypes.func
};

// export component
export const NotesTextEditor = withStyles(styles)(TextEditor);
