/* eslint-disable no-constant-condition */
/* eslint-disable no-self-compare */
/* eslint-disable prefer-const */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Button as Core } from "@material-ui/core";

// Imports Styles
import styles from "./styles";

/**
 * Button Component
 * @param {*} props
 */
function ButtonComponent(props) {

  const { className, classes, startIcon, endIcon, fullWidth, type, title, color, disableElevation, size, variant, component, href, disabled, onClick, children, style, target } = props;
  return (
    <Core
      type={type}
      color={color}
      disableElevation={disableElevation}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      variant={variant}
      component={component}
      disabled={disabled}
      classes={classes}
      fullWidth={fullWidth}
      className={className}
      href={href}
      style={style || {}}
      onClick={onClick} target={target}>{title}{children}</Core>
  );
}

// Default props
ButtonComponent.defaultProps = {
  type: "button",
  title: "",
  color: "default",
  classes: "",
  className: "",
  href: "",
  fullWidth: false,
  disableElevation: true,
  size: "small",
  variant: "outlined",
  component: "button",
  disabled: false,
  onClick: () => { },
};

// Prop types
ButtonComponent.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  variant: PropTypes.string,
  href: PropTypes.any,
  component: PropTypes.elementType,
  disabled: PropTypes.bool,
  disableElevation: PropTypes.bool,
  onClick: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string,
  fullWidth: PropTypes.bool
};

/** Export Component */
export const Button = withStyles(styles)(ButtonComponent);
