import { pushHistory, customNodeProps } from "./utils"

export const drawBlur = async ({ stage }) => {
    const layer = stage.findOne('#baseLayer')
    const blurImage = stage.findOne('#baseBlurImage')
    const tr = stage.findOne('#transformer')
    const x = stage.width() / 2 - (200 / 2)
    const y = stage.height() / 2 - (100 / 2)

    const onUndo = (type = 1) => {
        const { history_id } = blurCrop?.attrs
        const history_data = blurCrop?.attrs?.history[history_id - (1 * (type))]
        if (history_data) {
            blurCrop.x(history_data.x)
            blurCrop.y(history_data.y)
            blurCrop.cropX(history_data.cropX)
            blurCrop.cropY(history_data.cropY)
            blurCrop.cropWidth(history_data.width * history_data.scaleX)
            blurCrop.cropHeight(history_data.height * history_data.scaleY)
            blurCrop.attrs.history_id = history_id - (1 * (type))
            tr.show()
            tr.nodes([blurCrop])
        }
    }

    const onChangeAttrs = async (attrs) => {
        blurImage.pixelSize(attrs?.pixelSize)
        blurCrop.setAttrs({
            image: await blurImage.toImage()
        })
    }

    const nodeId = `blur_${new Date().getTime()}`
    const blurCrop = new window.Konva.Image({
        image: await blurImage.toImage(),
        x,
        y,
        cropX: x,
        cropY: y,
        cropWidth: 200,
        cropHeight: 50,
        width: 200,
        height: 50,
        draggable: true,
        name: 'blur',
        id: nodeId,
        pixelSize: blurImage.pixelSize(),
        ...customNodeProps({ stage }),
        undo: onUndo,
        nodeType: 'drawBlur',
        onChangeAttrs: onChangeAttrs
    });
    pushHistory(blurCrop, 'drawBlur')
    blurCrop.addName('need-tr')
    layer.add(blurCrop)

    const updateClip = () => {
        blurCrop.cropX(blurCrop.x())
        blurCrop.cropY(blurCrop.y())
        blurCrop.cropWidth(blurCrop.width() * blurCrop.scaleX())
        blurCrop.cropHeight(blurCrop.height() * blurCrop.scaleY())
    }

    blurCrop.on('dragmove', function () {
        updateClip();
    });

    blurCrop.on('dragend', function () {
        pushHistory(blurCrop, 'drawBlur')
        blurCrop.attrs.mdt = new Date().getTime()
    })

    blurCrop.on('transform', function () {
        updateClip();
    });

    tr.show()
    tr.nodes([blurCrop])
    stage.attrs.onUpdate(stage)
}