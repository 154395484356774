export const taskConstant = {
    //tasks
    GET_ALL_TASKS_R: "GET_ALL_TASKS_R",
    GET_ALL_TASKS_S: "GET_ALL_TASKS_S",
    GET_ALL_TASKS_F: "GET_ALL_TASKS_F",

    //filter
    GET_ALL_TASKS_FILTER_R: "GET_ALL_TASKS_FILTER_R",
    GET_ALL_TASKS_FILTER_S: "GET_ALL_TASKS_FILTER_S",
    GET_ALL_TASKS_FILTER_F: "GET_ALL_TASKS_FILTER_F",

    //complete task
    COMPLETE_TASK_R: "COMPLETE_TASK_R",
    COMPLETE_TASK_S: "COMPLETE_TASK_S",
    COMPLETE_TASK_F: "COMPLETE_TASK_F",

    // update completed task count
    UPDATE_COMPLETED_TASK_COUNT_S: "UPDATE_COMPLETED_TASK_COUNT_S",
    UPDATE_TASK_ANIMATION_STATUS_S: "UPDATE_TASK_ANIMATION_STATUS_S",

    // Task Create Form Status
    UPDATE_TASK_FORM_STATUS_S: "UPDATE_TASK_FORM_STATUS_S",

    //complete task
    GET_ALL_JOB_DATA_R: "GET_ALL_JOB_DATA_R",
    GET_ALL_JOB_DATA_S: "GET_ALL_JOB_DATA_S",
    GET_ALL_JOB_DATA_F: "GET_ALL_JOB_DATA_F",

    // update new task data 
    UPDATE_T_CREATE_TASK: "UPDATE_T_CREATE_TASK",

    //create new task
    CREATE_ONE_NEW_TASK_R: "CREATE_ONE_NEW_TASK_R",
    CREATE_ONE_NEW_TASK_S: "CREATE_ONE_NEW_TASK_S",
    CREATE_ONE_NEW_TASK_F: "CREATE_ONE_NEW_TASK_F",

    //complete task
    GET_ACTIVE_MEMBERS_R: "GET_ACTIVE_MEMBERS_R",
    GET_ACTIVE_MEMBERS_S: "GET_ACTIVE_MEMBERS_S",
    GET_ACTIVE_MEMBERS_F: "GET_ACTIVE_MEMBERS_F",
};
