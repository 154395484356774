import React, { useState } from "react";
import PropTypes from 'prop-types';
import { withStyles, Button,IconButton } from '@material-ui/core';
import SkipButtonStyles from "./SkipButtonStyles.jsx";
import {CloseIconNew} from "../../assets/svg/index.jsx"
const SkipButton = (props) => {
    const {classes,  onSkip, skipDuration } = props;
    const [seconds, setSeconds] = useState(skipDuration); // Countdown before skipping

    // Handle countdown timer
    React.useEffect(() => {
        const timer = setInterval(() => {
            setSeconds((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
        }, 1000);

        // Clear timer when component unmounts
        return () => clearInterval(timer);
    }, []);

    const handleClose = () => {
        if (seconds > skipDuration) {
            onSkip()
        }
    }

    return (
        <div className={classes.skipButtonContainer}>
            {/* <Button className={classes.skipButton} onClick={() => onSkip()}>
                {seconds > 0 ? `Skip In ${seconds < 10 ? `0${seconds}` : seconds} ` : `Skip Intro`}
            </Button> */}
              
              <IconButton aria-label="close" className={classes.closeButton} onClick={() => onSkip()} style={{ display: "block" }}>
              <CloseIconNew />
            </IconButton>
         
        </div>
    );
};

SkipButton.defaultProps = {
    classes: {},
    skipDuration: 30,
  };
  
  SkipButton.propTypes = {
    classes: PropTypes.object,
    skipDuration: PropTypes.number
  };

export default withStyles(SkipButtonStyles)(SkipButton);
