/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";
import { isMobile, isIOS, isBrowser, mobileModel } from "react-device-detect";
import Styles from "./Styles";

const CustomAuthLayout = (props) => {
  const { classes } = props;
  let safeAreaTopHeight = getComputedStyle(document.documentElement).getPropertyValue("--sat");

  return (
    <Grid container className={`${isIOS && isMobile && !isBrowser && mobileModel === "iPhone" ? "notchContainer" : null} h-100`} direction="column" wrap="nowrap">
      {isIOS && isMobile && !isBrowser && mobileModel === "iPhone" ? (
        <Grid
          item
          xs={12}
          style={{ height: `${safeAreaTopHeight}` }}
          className="notchGrid flexBasis0 "
        />
      ) : null}

      <Grid item xs={12} className={`dflex alignSelfCenter alignCenter alignContentCenter textCenter ${classes.container}`}>
        {props.children}
      </Grid>
    </Grid>
  );
};

// Define Props Types
CustomAuthLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired,
};

// Default props
CustomAuthLayout.defaultProps = {
  classes: {},
  children: {},
  user: {},
  access: false,
};

export default withStyles(Styles)(CustomAuthLayout);
