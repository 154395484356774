import React from 'react';
import PropTypes from 'prop-types';

const ShowTimer = ({ dateString, hours, minutes, seconds, meridian }) => {
    return (
        <div className="show-timer">
            <div className="digi-clock">
                {hours}<b>:</b>{minutes}{seconds ? <><b>:</b>{seconds}</> : null}{meridian ? `${meridian}` : null}
            </div>
            <div className="digi-clock-date">{dateString}</div>
        </div>
    );
};


// default props
ShowTimer.defaultProps = {
    classes: {},
};

// prop types
ShowTimer.propTypes = {
    classes: PropTypes.object,
};

/** Export Component */
export default ShowTimer;