import Mentions from "./Elements/Mentions/Mentions";

export const getBorderColor = (color, borderWidth = 3) => {
  if (color?.indexOf("linear") > -1) {
    return {
      borderImage: `${color} ${borderWidth}`,
      borderWidth: `0px 0px 0px ${borderWidth}px`,
      borderStyle: "solid",
    };
  } else {
    return {
      borderColor: color || "transparent",
    };
  }
};

export const getBlock = (props) => {
  const { element, viewType } = props;
  const isEmpty = false
  let view = viewType === "listView" ? "chat-editor" : "chat-editorspace"

  const renderChildren = (children) => {
    return children.map((child, index) => {
      if (child.type === 'mention') {
        return (
          <Mentions
            key={index}
            attributes={props.attributes}
            element={child}
            msgComponentType={props.msgComponentType}
            viewType={props.viewType}
          />
        );
      }

      if (child.type === "headingOne") {
        return (
          <h1
            {...element.attr}
            className={`${view} content-editable ${isEmpty ? "empty" : ""}`}
            placeholder="Heading 1"
          >
            {renderChildren(child.children)}
          </h1>
        )
      }

      if (child.type === "headingTwo") {
        return (
          <h2
            {...element.attr}
            className={`${view} content-editable ${isEmpty ? "empty" : ""}`}
            placeholder="Heading 2"
          >
            {renderChildren(child.children)}
          </h2>
        )
      }

      if (child.type === "headingThree") {
        return (
          <h3
            {...element.attr}
            className={`${view} content-editable ${isEmpty ? "empty" : ""}`}
            placeholder="Heading 3"
          >
            {renderChildren(child.children)}
          </h3>
        )
      }

      if (child.type === "headingFour") {
        return (
          <h4
            {...element.attr}
            className={`${view} content-editable ${isEmpty ? "empty" : ""}`}
            placeholder="Heading 4"
          >
            {renderChildren(child.children)}
          </h4>
        )
      }

      if (child.type === "headingFive") {
        return (
          <h5
            {...element.attr}
            className={`${view} content-editable ${isEmpty ? "empty" : ""}`}
            placeholder="Heading 5"
          >
            {renderChildren(child.children)}
          </h5>
        )
      }

      if (child.type === "headingSix") {
        return (
          <h6
            {...element.attr}
            className={`${view} content-editable ${isEmpty ? "empty" : ""}`}
            placeholder="Heading 6"
          >
            {renderChildren(child.children)}
          </h6>
        )
      }

      if (child.type === "list-item") {
        return (
          <li
            {...element.attr}
            className={`content-editable ${isEmpty ? "empty" : ""}`}
          >
            {renderChildren(child.children)}
          </li>
        )
      }

      if (child.type === "orderedList") {
        return <ol
          {...element.attr}
          className={`content-editable ${isEmpty ? "empty" : ""}`}
        >
          {renderChildren(child.children)}
        </ol>
      }

      if (child.type === "unorderedList") {
        return <ul
          {...element.attr}
          className={`content-editable ${isEmpty ? "empty" : ""}`}
        >
          {renderChildren(child.children)}
        </ul>
      }

      if (child.type === 'link') {
        return (
          <a
            style={{
              color: props?.isSender && props?.viewType !== 'listView' ? '#FFFF' : '#2563EB',
              cursor: props?.clickable ? 'default' : 'pointer',
            }}
            key={index}
            onTouchEnd={()=> window.location.href=`${child?.url}`}
            href={props?.clickable ? null : child?.url}
            target={props?.clickable ? null : "_blank"}
            rel={props?.clickable ? null : "noopener noreferrer"}
          >
            {child.children.map((linkChild, linkIndex) => (
              <span key={linkIndex}>{linkChild.text}</span>
            ))}
          </a>

        );
      }
      if (child.type === 'paragraph') {
        return renderChildren(child.children)
      }

      if (child.text !== undefined) {
        const textContent = child.text.split('\n').flatMap((part, partIndex) => {
          const urlPattern = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

          if (urlPattern.test(part)) {
            const parts = part.split(urlPattern);
            const urls = part.match(urlPattern) || [];

            const renderedElements = [];
            parts.forEach((textPart, textPartIndex) => {
              if (textPart.trim().length > 0) {
                renderedElements.push(<span key={`text-${partIndex}-${textPartIndex}`}>{textPart}</span>);
              }

              if (textPartIndex < urls.length) {
                const url = urls[textPartIndex];
                renderedElements.push(
                  <a
                    key={`url-${partIndex}-${textPartIndex}`}
                    style={{
                      color: props?.isSender && props?.viewType !== 'listView' ? '#FFFF' : '#2563EB',
                      cursor: props?.clickable ? 'default' : 'pointer',
                    }}
                    onTouchEnd={()=> window.location.href=`${url}`}
                    href={props?.clickable ? null : url}
                    target={props?.clickable ? null : "_blank"}
                    rel={props?.clickable ? null : "noopener noreferrer"}
                  >
                    {url}
                  </a>
                );
              }
            });

            return renderedElements;
          }

          return partIndex === 0 ? part : [<br key={`${index}-${partIndex}`} />, part];
        });
        if (child.code) {
          return <code key={index}>{textContent}</code>;
        }

        if (child.bold && child.italic && child.underline) {
          return <em key={index}><u><strong>{textContent}</strong></u></em>;
        } else if (child.bold && child.italic) {
          return <em key={index}><strong>{textContent}</strong></em>;
        } else if (child.underline && child.italic) {
          return <em key={index}><u>{textContent}</u></em>;
        } else if (child.bold && child.underline) {
          return <u key={index}><strong>{textContent}</strong></u>;
        } else if (child.bold) {
          return <strong key={index}>{textContent}</strong>;
        } else if (child.italic) {
          return <em key={index}>{textContent}</em>;
        } else if (child.underline) {
          return <u key={index}>{textContent}</u>;
        } else {
          return <span key={index}>{textContent.length === 1 && textContent[0] === '' ? <span>&nbsp;</span> : textContent}</span>;
        }
      }
      return null;
    });
  };


  switch (element.type) {
    case "paragraph":
      return (
        <p
          {...element.attr}
          className={`${view} content-editable ${isEmpty ? "empty" : ""}`}
          placeholder="paragraph"
        >
          {renderChildren(element.children)}
        </p>
      );
    case "headingOne":
      return (
        <h1
          {...element.attr}
          className={`${view} content-editable ${isEmpty ? "empty" : ""}`}
          placeholder="Heading 1"
        >
          {renderChildren(element.children)}
        </h1>
      );
    case "headingTwo":
      return (
        <h2
          {...element.attr}
          className={`${view} content-editable ${isEmpty ? "empty" : ""}`}
          placeholder="Heading 2"
        >
          {renderChildren(element.children)}
        </h2>
      );
    case "headingThree":
      return (
        <h3
          {...element.attr}
          className={`${view} content-editable ${isEmpty ? "empty" : ""}`}
          placeholder="Heading 3"
        >
          {renderChildren(element.children)}
        </h3>
      );

    case "orderedList":
      return (
        <ol>
          {renderChildren(element.children)}
        </ol>
      );
    case "list-item":
      return (
        <li
          {...element.attr}
          className={`content-editable ${isEmpty ? "empty" : ""}`}
        >
          {renderChildren(element.children)}
        </li>
      );

    case "unorderedList":
      return (
        <ul
          {...element.attr}
          className={`content-editable ${isEmpty ? "empty" : ""}`}>
          {renderChildren(element.children)}
        </ul>
      )
    // case "blockquote":
    //   return (
    //     <blockquote
    //       {...element.attr}
    //       className="chat-editor"
    //       style={{
    //         // borderColor: element?.color || "transparent",
    //         ...getBorderColor(element?.color || "transparent", 3),
    //         background: element?.bgColor || "none",
    //         padding: `${element?.bgColor ? "16px" : "0px"} 8px`,
    //         borderRadius: `${element?.color ? "0px" : "12px"} 12px 12px ${element?.color ? "0px" : "12px"
    //           }`,
    //         margin: `${element?.bgColor ? "16px" : "0px"} 0px`,
    //         width: element?.bgColor ? "calc(100% - 16px)" : "100%",
    //         borderWidth: element?.color ? "0px 0px 0px 3px" : "0px",
    //       }}
    //     >
    //       {children}
    //     </blockquote>
    //   );
    case "mention":
      return <Mentions {...props} />;
    default:
      return null;
  }
};
