import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { videoCallAction } from "../../redux/actions/videoCallAction";
import Page404 from "../ErrorPage/Page404/Page404";
import { PageLoader } from "../../components";
import VideoCall from './index';

function VideoCallRoute(props) {
    const { match } = props;
    const dispatch = useDispatch();
    const channelData = useSelector((state) => state.videocall.channelData);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        dispatch(videoCallAction.checkFlozyRoom({ channel_name: match.params.meetingId }, () => {
            setIsLoading(false);
        }));
    }, [dispatch, match.params.meetingId]);

    if (isLoading) {
        return <PageLoader isLoading={true} />;
    }

    if (channelData && Object.keys(channelData).length > 0) { 
        return <VideoCall match={match} />;
    }

    if(!isLoading && !(channelData && Object.keys(channelData).length)){
        return <Page404 />
    }

    return <></>

}

export default VideoCallRoute;
