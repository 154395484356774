import { baseAction } from "./baseAction";
import { chatConstant, alertConstant, uiConstant, chatHubConstant } from "../constants";
import { chatService } from "../services";

// import chatPage constants
import { chatPageConstants } from '../../config/constants';

const { request, success, failure } = baseAction;

const CHAT_TYPE_MAP = {
    'project': 'projects',
    'client': 'clients',
    'groupId': 'team'
}

// All login based actions
export const chatAction = {
    getJobChat,
    saveNewMessage,
    updateNewTempMessage,
    updateNewOwnMessage,
    updateNewMessage,
    updateJobMessageStatus,
    updateOnLoadMessageStatus,
    updateMessageReactions,
    uploadJobFiles,
    getSearchMessages,
    getMessageMedia,

    getClientChat,
    saveClientNewMessage,
    updateClientNewTempMessage,
    updateClientNewOwnMessage,
    updateClientNewMessage,
    updateClientMessageStatus,
    updateClientOnLoadMessageStatus,
    updateClientMessageReactions,
    uploadClientChatFiles,
    getClientChatSearchMessages,
    getClientChatMedia,

    updateChatNotificationMessage,
    deleteChatMessage,
    updateOnlineUsers,
    starMessage
};

/* 
  Project Chat Section
*/
// get job chat on load inside project detail page
function getJobChat(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id } = params;
        let { chat, chat_room, projects } = getState().chat.project;
        let projectData = getState().project.projectDetail.project;
        let projectChatRoom = getState().project.projectDetail.chat_room;
        dispatch(request(uiConstant.LAZY_LOADING_TRUE))
        dispatch(request(chatConstant.GET_JOB_CHAT_R, params));
        chatService.getJobChat(params).then(data => {
            const page = data.data && data.data.page ? data.data.page : { skip: 0, limit: 20, count: 0 }
            chat = { ...chat, [`${job_id}`]: data.data && data.data.data ? data.data.data : [] };
            chat_room = { ...chat_room, [`${job_id}`]: { ...projectChatRoom } }
            projects = { ...projects, [`${job_id}`]: { ...projectData } }
            const result = {
                chat,
                chat_room,
                projects,
                activeProject: job_id,
                page: page,
                readMessagesOnLoad: data.data && data.data.readMessagesOnLoad ? data.data.readMessagesOnLoad : null,
                channelName : data.data && data.data.channelName,
            };

            dispatch(success(chatConstant.GET_JOB_CHAT_S, result));
            dispatch(request(uiConstant.LAZY_LOADING_FALSE))
            callBack(data.data.readMessagesOnLoad)
        }, error => {
            dispatch(failure(chatConstant.GET_JOB_CHAT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// Save Flow Messages in to database
function saveNewMessage(params, callBack = () => { }) {
    return (dispatch) => {
        let { chatMessageObj } = params;
        chatService.saveNewMessage(chatMessageObj).then(data => {
            // successfully saved messages 
            callBack(data.data)
            // Note: Need to handle if messages not saved successfully
        }, error => {
        });
    }
}

// update message list with temp message before received the message from socket emit
function updateNewTempMessage(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id, message } = params;
        let { chat, chat_room, projects } = getState().chat.project;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];
        let jobChat = preChatList.length > 0 ? preChatList.filter(msg => msg.id === message.id).length > 0 ? preChatList : [message, ...preChatList] : [message];
        chat[`${job_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            projects,
            job_id: job_id
        };
        callBack();
        dispatch(success(chatConstant.UPDATE_NEW_MESSAGE_S, result));
    };
}

// when chat message received on project message
function updateNewOwnMessage(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id, message, tempMessageId } = params;
        let { chat, chat_room, projects } = getState().chat.project;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];

        const findIndex = preChatList.findIndex(msg => msg.id === tempMessageId);
        const msgFoundIndex = preChatList.findIndex(msg => msg.id === message.id);

        const getChatData = () => {
            preChatList[findIndex] = message
            return preChatList
        }

        chat[`${job_id}`] = preChatList.length > 0 ? msgFoundIndex >= 0 ? preChatList : getChatData() : [message];

        const result = {
            chat,
            chat_room,
            projects,
            job_id: job_id
        };
        dispatch(success(chatConstant.UPDATE_NEW_MESSAGE_S, result));
        callBack(params)
    };
}

// when chat message received on project message
function updateNewMessage(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id, message } = params;
        let { chat, chat_room, projects } = getState().chat.project;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];

        if (preChatList.filter(msg => msg.id === message.id).length > 0) {
            // no need to update the message may be its second time or receiving own message
            callBack(params)
        } else {
            chat[`${job_id}`] = preChatList.length > 0 ? [message, ...preChatList] : [message];
            const result = {
                chat,
                chat_room,
                projects,
                job_id: job_id
            };

            dispatch(success(chatConstant.UPDATE_NEW_MESSAGE_S, result));
            callBack(params)
        }
    };
}

// when chat message received on project message
function updateOnLoadMessageStatus(params, callBack = () => { }) {
    return (dispatch, getState) => {

        let { job_id, messageIds } = params;
        let { chat, chat_room, projects } = getState().chat.project;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];

        let jobChat = preChatList.map((obj) => {
            if (messageIds.includes(String(obj.id))) {
                obj = {
                    ...obj,
                    delivered_status: 1,
                    read_status: 1
                }
            }
            return obj;
        })

        chat[`${job_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            projects,
            job_id: job_id
        };
        dispatch(success(chatConstant.UPDATE_NEW_MESSAGE_S, result));
        callBack(params)
    };
}


// update message delivered and read status
function updateJobMessageStatus(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id, message } = params;
        let { chat, chat_room, projects } = getState().chat.project;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];
        let jobChat = preChatList.length > 0 ? preChatList.map(obj => obj.id === message.id ? message : obj) : [message];
        chat[`${job_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            projects,
            job_id: job_id
        };
        dispatch(success(chatConstant.UPDATE_NEW_MESSAGE_S, result));
        callBack(params)
    };
}


// update message reactions
function updateMessageReactions(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id, message } = params;
        let { chat, chat_room, projects } = getState().chat.project;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];
        let jobChat = preChatList.length > 0 ? preChatList.map(obj => obj.id === message.id ? message : obj) : [message];
        chat[`${job_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            projects,
            job_id: job_id
        };
        dispatch(success(chatConstant.UPDATE_NEW_MESSAGE_S, result));
        callBack(params)
    };
}


/** upload chat files*/
function uploadJobFiles(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(chatConstant.UPLOAD_JOB_FILE_R, params));
        chatService.uploadJobFiles(params).then(data => {
            dispatch(success(chatConstant.UPLOAD_JOB_FILE_S, data));
            callBack(data.data)
        }, error => {
            dispatch(failure(chatConstant.UPLOAD_JOB_FILE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// get job chat on load inside project detail page
function getSearchMessages(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let job_id = getState().chat.project.activeProject;
        let searchData = { search_query: params, job_id: job_id };
        dispatch(request(chatConstant.GET_JOB_SEARCH_MESSAGES_R, searchData));
        chatService.getSearchMessages(searchData).then(data => {
            dispatch(success(chatConstant.GET_JOB_SEARCH_MESSAGES_S, data));
            callBack()
        }, error => {
            dispatch(failure(chatConstant.GET_JOB_SEARCH_MESSAGES_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// get job Medias (link, docs, media_files)
function getMessageMedia(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { projectLinks, projectMedias, projectDocs } = getState().chat.project;
        let { job_id } = params;

        dispatch(request(chatConstant.GET_JOB_MEDIA_MESSAGES_R, params));
        chatService.getMessageMedia(params).then(data => {
            let result = '';
            if (data.data.media_type === "links") {
                result = { projectLinks: { ...projectLinks, [`${job_id}`]: data.data.result } }
            } else if (data.data.media_type === "docs") {
                result = { projectDocs: { ...projectDocs, [`${job_id}`]: data.data.result } }
            } else {
                result = { projectMedias: { ...projectMedias, [`${job_id}`]: data.data.result } }
            }

            dispatch(success(chatConstant.GET_JOB_MEDIA_MESSAGES_S, result));
        }, error => {
            dispatch(failure(chatConstant.GET_JOB_MEDIA_MESSAGES_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

/*
  Project Chat Section End
*/


/* 
  Client Chat Section Start
*/
// get job chat on load inside project detail page
function getClientChat(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { client_id } = params;
        let { chat, chat_room, clients } = getState().chat.client;
        let clientData = getState().clients.clientDetail.client;
        let clientChatRoom = getState().clients.clientDetail.chat_room;

        dispatch(request(chatConstant.GET_CLIENT_CHAT_R, params));
        dispatch(request(uiConstant.LAZY_LOADING_TRUE))
        chatService.getClientChat(params).then(data => {
            const page = data.data && data.data.page ? data.data.page : { skip: 0, limit: chatPageConstants.MESSAGE_LIMIT_COUNT, count: 0 }
            chat = { ...chat, [`${client_id}`]: data.data && data.data.data ? data.data.data : [] };
            chat_room = { ...chat_room, [`${client_id}`]: { ...clientChatRoom } }
            clients = { ...clients, [`${client_id}`]: { ...clientData } }
            const result = {
                chat,
                chat_room,
                clients,
                activeClient: client_id,
                page: page,
                readMessagesOnLoad: data.data && data.data.readMessagesOnLoad ? data.data.readMessagesOnLoad : null,
                channelName : data.data && data.data.channelName,
            };

            dispatch(success(chatConstant.GET_CLIENT_CHAT_S, result));
            dispatch(request(uiConstant.LAZY_LOADING_FALSE))
            callBack(data.data && data.data.readMessagesOnLoad ? data.data.readMessagesOnLoad : null)
        }, error => {
            dispatch(failure(chatConstant.GET_CLIENT_CHAT_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// Save Client Messages in to database
function saveClientNewMessage(params, callBack = () => { }) {
    return (dispatch) => {
        let { chatMessageObj } = params;
        chatService.saveClientNewMessage(chatMessageObj).then(data => {
            // successfully saved messages 
            callBack(data.data)
            // Note: Need to handle if messages not saved successfully
        }, error => {
        });

    }
}

// when chat message received on client message
function updateClientNewTempMessage(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { client_id, message } = params;
        let { chat, chat_room, clients } = getState().chat.client;
        let preChatList = chat && chat.hasOwnProperty(client_id) ? chat[client_id] : [];

        let jobChat = preChatList.length > 0 ? preChatList.filter(msg => msg.id === message.id).length > 0 ? preChatList : [message, ...preChatList] : [message];
        chat[`${client_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            clients,
            client_id
        };

        callBack();

        dispatch(success(chatConstant.UPDATE_CLIENT_NEW_MESSAGE_S, result));
    };
}

// when chat message received on project message
function updateClientNewOwnMessage(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { client_id, message, tempMessageId } = params;

        let { chat, chat_room, clients } = getState().chat.client;
        let preChatList = chat && chat.hasOwnProperty(client_id) && chat[client_id] ? chat[client_id] : [];

        const findIndex = preChatList.findIndex(msg => msg.id === tempMessageId);
        const msgFoundIndex = preChatList.findIndex(msg => msg.id === message.id);

        const getChatData = () => {
            preChatList[findIndex] = message
            return preChatList
        }
        chat[`${client_id}`] = preChatList.length > 0 ? msgFoundIndex >= 0 ? preChatList : getChatData() : [message];

        const result = {
            chat,
            chat_room,
            clients,
            client_id
        };

        dispatch(success(chatConstant.UPDATE_CLIENT_NEW_MESSAGE_S, result));
        callBack(params);
    };
}

// when chat message received from other chatroom members in client
function updateClientNewMessage(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { client_id, message } = params;

        let { chat, chat_room, clients } = getState().chat.client;
        let preChatList = chat && chat.hasOwnProperty(client_id) && chat[client_id] ? chat[client_id] : [];

        if (preChatList.filter(msg => msg.id === message.id).length > 0) {
            // no need to update the message may be its second time or receiving own message
            callBack(params);
        } else {
            chat[`${client_id}`] = preChatList.length > 0 ? [message, ...preChatList] : [message];

            const result = {
                chat,
                chat_room,
                clients,
                client_id
            };
            dispatch(success(chatConstant.UPDATE_CLIENT_NEW_MESSAGE_S, result));
            callBack(params);
        }
    };
}

// when chat message received on project message
function updateClientOnLoadMessageStatus(params, callBack = () => { }) {
    return (dispatch, getState) => {

        let { job_id, messageIds } = params;
        let { chat, chat_room, clients } = getState().chat.client;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];

        let jobChat = preChatList.map((obj) => {
            if (messageIds.includes(String(obj.id))) {
                obj = {
                    ...obj,
                    delivered_status: 1,
                    read_status: 1
                }
            }
            return obj;
        })

        chat[`${job_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            clients,
            job_id: job_id
        };
        dispatch(success(chatConstant.UPDATE_CLIENT_NEW_MESSAGE_S, result));
        callBack(params)
    };
}

// update client message delivery status
function updateClientMessageStatus(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id, message } = params;
        let { chat, chat_room, clients } = getState().chat.client;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];
        let jobChat = preChatList.length > 0 ? preChatList.map(obj => obj.id === message.id ? message : obj) : [message];
        chat[`${job_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            clients,
            job_id: job_id
        };
        let data = JSON.stringify(result);
        dispatch(success(chatConstant.UPDATE_CLIENT_NEW_MESSAGE_S, JSON.parse(data)));
        callBack(params)
    };
}

// update message reactions
function updateClientMessageReactions(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id, message } = params;
        let { chat, chat_room, clients } = getState().chat.client;
        let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];
        let jobChat = preChatList.length > 0 ? preChatList.map(obj => obj.id === message.id ? message : obj) : [message];
        chat[`${job_id}`] = jobChat;

        const result = {
            chat,
            chat_room,
            clients,
            job_id: job_id
        };
        let data = JSON.stringify(result);
        dispatch(success(chatConstant.UPDATE_CLIENT_NEW_MESSAGE_S, JSON.parse(data)));
        callBack(params)
    };
}

/** upload chat files*/
function uploadClientChatFiles(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(chatConstant.UPLOAD_CLIENT_FILE_R, params));
        chatService.uploadClientChatFiles(params).then(data => {
            dispatch(success(chatConstant.UPLOAD_CLIENT_FILE_S, data));
            callBack(data.data)
        }, error => {
            dispatch(failure(chatConstant.UPLOAD_CLIENT_FILE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// get job chat on load inside project detail page
function getClientChatSearchMessages(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let client_id = getState().chat.client.activeClient;
        let searchData = { search_query: params, client_id: client_id };
        dispatch(request(chatConstant.GET_CLIENT_SEARCH_MESSAGES_R, searchData));
        chatService.getClientChatSearchMessages(searchData).then(data => {
            dispatch(success(chatConstant.GET_CLIENT_SEARCH_MESSAGES_S, data));
            callBack()
        }, error => {
            dispatch(failure(chatConstant.GET_CLIENT_SEARCH_MESSAGES_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// get job Medias (link, docs, media_files)
function getClientChatMedia(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { clientLinks, clientMedias, clientDocs } = getState().chat.client;
        let { client_id } = params;

        dispatch(request(chatConstant.GET_CLIENT_MEDIA_MESSAGES_R, params));
        chatService.getClientChatMedia(params).then(data => {
            let result = '';
            if (data.data.media_type === "links") {
                result = { clientLinks: { ...clientLinks, [`${client_id}`]: data.data.result } }
            } else if (data.data.media_type === "docs") {
                result = { clientDocs: { ...clientDocs, [`${client_id}`]: data.data.result } }
            } else {
                result = { clientMedias: { ...clientMedias, [`${client_id}`]: data.data.result } }
            }

            dispatch(success(chatConstant.GET_CLIENT_MEDIA_MESSAGES_S, result));
        }, error => {
            dispatch(failure(chatConstant.GET_CLIENT_MEDIA_MESSAGES_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

/*
  Client Chat Section End
*/

// when chat message received on project message
function updateChatNotificationMessage(params, callBack = () => { }) {
    return (dispatch, getState) => {
        let { job_id, message, type } = params;
        let chatData = getState().chat.chatNotifications[type];
        let preChatList = chatData && chatData.hasOwnProperty(job_id) && chatData[job_id] ? chatData[job_id] : [];
        let jobChat = preChatList.length > 0 ? preChatList.filter(msg => msg.id === message.id).length > 0 ? preChatList : [message, ...preChatList] : [message];
        chatData[`${job_id}`] = jobChat;
        const result = {
            [type]: chatData
        };
        dispatch(success(chatConstant.UPDATE_CHAT_NOTIFICATION_MESSAGE_S, result));
    };
}

/** delete chat files **/
function deleteChatMessage(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(chatConstant.DELETE_CHAT_MESSAGES_R, params));
        chatService.deleteChatMessage(params).then(data => {// 
            dispatch(success(chatConstant.DELETE_CHAT_MESSAGES_S, data));
            dispatch(success(chatHubConstant.UPDATE_CHATHUB_MESSAGE, {
                type: CHAT_TYPE_MAP[params?.type],
                message_id: params?.message_id,
                job_id: params.job_id,
                value: {
                    undo: params.deleteStatus === 0 ? false : true
                }
            }));
            callBack(params)
        }, error => {
            dispatch(failure(chatConstant.DELETE_CHAT_MESSAGES_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

// update userOnline Active 
function updateOnlineUsers(params, callBack = () => { }) {
    return (dispatch) => {
        let { type, data } = params;
        if (type === "project") {
            dispatch(success(chatConstant.UPDATE_PROJECT_ONLINE_USER_S, data));
        } else if (type === "client") {
            dispatch(success(chatConstant.UPDATE_CLIENT_ONLINE_USER_S, data));
        } else if (type === "team") {
            dispatch(success(chatConstant.UPDATE_TEAM_ONLINE_USER_S, data));
        }
    };
}
/** Star chat Action  */
function starMessage(params, callBack = () => { }) {
    return (dispatch, getState) => {
        const { job_id, type, star, message_id, userId } = params;
        if (type === "groupId") {
            let { chat, chat_room, teams } = getState().chat.teamChat;
            let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];
            chat[`${job_id}`] = preChatList.map(item => {
                if (item.id == message_id) {
                    item = { ...item, ['star']: star, ['star_user_id']: userId }
                }
                return item;
            });
            const result = {
                chat,
                chat_room,
                teams,
                job_id: job_id,
            };
            dispatch(success(chatConstant.STAR_CHAT_T_MESSAGES_R, result));
            chatService.starMessage(params).then(data => {
                dispatch(success(chatConstant.STAR_CHAT_T_MESSAGES_S, result));
                callBack(params)
            }, error => {
                dispatch(failure(chatConstant.STAR_CHAT_T_MESSAGES_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            });
        }
        else if (type === "project") {
            let { chat, chat_room, projects } = getState().chat.project;
            let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];
            chat[`${job_id}`] = preChatList.map(item => {
                if (item.id == message_id) {
                    item = { ...item, ['star']: star, ['star_user_id']: userId }
                }
                return item;
            });
            const result = {
                chat,
                chat_room,
                projects,
                job_id: job_id,
            };
            dispatch(success(chatConstant.STAR_CHAT_P_MESSAGES_R, result));
            chatService.starMessage(params).then(data => {
                dispatch(success(chatConstant.STAR_CHAT_P_MESSAGES_S, result));
                callBack(params)
            }, error => {
                dispatch(failure(chatConstant.STAR_CHAT_P_MESSAGES_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            });
        }
        else if (type === "client") {
            let { chat, chat_room, clients } = getState().chat.client;
            let preChatList = chat && chat.hasOwnProperty(job_id) && chat[job_id] ? chat[job_id] : [];
            chat[`${job_id}`] = preChatList.map(item => {
                if (item.id == message_id) {
                    item = { ...item, ['star']: star, ['star_user_id']: userId }
                }
                return item;
            });
            const result = {
                chat,
                chat_room,
                clients,
                job_id: job_id,
            };
            dispatch(success(chatConstant.STAR_CHAT_C_MESSAGES_R, result));
            chatService.starMessage(params).then(data => {
                dispatch(success(chatConstant.STAR_CHAT_C_MESSAGES_S, result));
                callBack(params)
            }, error => {
                dispatch(failure(chatConstant.STAR_CHAT_C_MESSAGES_F, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
            });
        }
        else {
            // let { chat, chat_room, projects } = [];
            return false;
        }
    }
}
