import React from "react"
import { RenderNodeOptions } from "./RenderNodeOptions"
export const ToolOptions = (props) => {
    const { classes, stageData, onChange } = props
    const { selectedNode } = stageData || {}
    const { nodeType } = selectedNode?.attrs || {}
    const isDrawMode = (nodeType == true || stageData?.drawMode)
    const NodeOptions = isDrawMode ? RenderNodeOptions['drawPen'] : RenderNodeOptions[nodeType]

    return (
        <div style={{ display: 'flex', flexDirection: 'row', height: '40px', justifyContent: 'center' }}>
            <div style={{ display: 'flex' }}>
                {NodeOptions ?
                    <NodeOptions
                        onChange={onChange}
                        classes={classes}
                        selectedNode={selectedNode}
                    /> :
                    null
                }
            </div>
        </div>
    )
}