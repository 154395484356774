const styles = (theme) => ({
    searchBoxGrid: {
      width: '100%',
      "& .searchBox": {
        transition: "0.5s",
        width: '100%',
        '& .MuiOutlinedInput-adornedEnd': {
          paddingRight: '8px',
          background: theme.palette.greyshades.light7,
        }
      },
      "& .MuiOutlinedInput-root": {
        height: "40px",
        minHeight: "32px",
      },
      "& .MuiInputAdornment-positionStart":{
        margin: "0px !important",
        padding: "0px 10px 0px 0px !important",
      },
      "& .MuiInputAdornment-positionEnd":{
        margin: "0px !important",
        padding: "0px !important",
      },
      "& .PrivateNotchedOutline-root .PrivateNotchedOutline":{
        borderWidth: "0px !important"
      },
    },
    searchBoxGridPages: {
      width: '100%',
      "& .searchBox": {
        transition: "0.5s",
        width: '100%',
        '& .MuiOutlinedInput-adornedEnd': {
          paddingRight: '8px',
          background: theme.palette.greyshades.light7,
        }
      },
      "& .MuiOutlinedInput-root": {
        height: "36px",
        minHeight: "32px",
      },
      "& .MuiInputAdornment-positionStart":{
        margin: "0px !important",
        padding: "0px 10px 0px 0px !important",
      },
      "& .MuiInputAdornment-positionEnd":{
        margin: "0px !important",
        padding: "0px !important",
      },
      "& .PrivateNotchedOutline-root .PrivateNotchedOutline":{
        borderWidth: "0px !important"
      },
    },
    padding6: {
      padding: 6
    }
  });
  export default styles;
  