import React, { useRef } from 'react'
import { useSelector } from 'react-redux'

const UserOnline = (props) => {
    const { isUserOnline } = props
    const onlineEleRef = useRef()
    const { onlineUsers } = useSelector(state => state.chatv2)
    const isOnline = onlineUsers[isUserOnline] ? 'chat-online' : 'chat-offline'

    // useEffect(() => {
    //     if (inViewport) {
    //         getUserOnlineStatus({
    //             isUserOnline,
    //             userId
    //         })
    //     }
    // }, [inViewport])

    return (
        <i ref={onlineEleRef} className={`${isOnline} rt-0 lap from-hook`} />
    )
}

export default UserOnline