import { BorderBottom, BorderTop } from "@material-ui/icons";

const styles = (theme) => ({
    chipInput: {
        '& .MuiInputBase-root': {
            paddingTop: '0px !important'
        },
        '& .MuiInputBase-input': {
            paddingBottom: '4px !important'
        },
        '& .MuiChip-root': {
            margin: 2
        }
    },
    emailError: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
        },
        border: '1px solid red !important',
        borderRadius: "7px",
        borderBottom: '3px solid red !important',
    }
});

export default styles;
