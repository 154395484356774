
const style = (theme) => ({

"boxContainer": {
  display: "flex",
  justifyContent: "center",
  height: "32px",
  width: "32px",
  gap: "2px",
  background: "#2563eb",
  borderRadius: "50%"
},

"box": {
  transform: "scaleY(.4)",
  height: "100%",
  width: "3px",
  background: "#FFFFFF",
  animationDuration: "1.2s",
  animationTimingFunction: "ease-in-out",
  animationIterationCount: "infinite",
  borderRadius: "30%"
},

"box1": {
  animationName: "quiet"
},

"box2": {
  animationName: "normal"
},

"box3": {
  animationName: "quiet"
}

})

export default style;