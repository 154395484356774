import React from 'react';
import { getAppHost } from "../../utils";

import { _api } from "../../config/environment";
import { _envParams } from "../../config/env_params";

import AuthLayout from './AuthLayout/AuthLayout';
import CustomAuthLayout from './CustomAuthLayout/CustomAuthLayout';

const AuthenticationLayout = (props) => {

    const appHost = getAppHost();
    const defaultHost = _api.appUrl;
    const isSignedIn = localStorage.getItem('staySignInAG');
    const hasToken = localStorage.getItem("key");


    return (
        <>
            {
                // !((isSignedIn && hasToken) || hasToken) ?
                _envParams.REACT_APP_BUILD_FOR === "WEB" ?
                    (appHost === defaultHost ? <AuthLayout {...props} isPublic={true} /> : <CustomAuthLayout {...props} isPublic={true} />) : <AuthLayout {...props} isPublic={true} />
                // : <></>
            }
        </>
    )
}

export default AuthenticationLayout 