import { userAgencyConstant } from "../constants";

const initialState = {
    loading: false,
    userAgencies: [],

    planDetails: {},
    payHistory: [],
    pInvoice: {},
    nInvData: {},
    autoPay: {},
    myBillsUserInfo: {
        billingAddress: '',
        billingPostcode: '',
        billingCity: '',
        billingCountry: ''
    },
    cardDetails: {},
    agencyData: {},
    feedbackForm: {},
    userLimits: {
        projectUsers: [],
        clientUsers: [],
        planData: {}
    },
    allPlans: [],
    availablePlans: [],
    plansList: [],
    planAddOn: [],
    upgradePlan: {
        currentPlan: ''
    },
    planMembers: {
        teamMembers: 0,
        clientMembers: 0
    }
}

export default function userAgency(state = initialState, action) {
    switch (action.type) {

        //get all user agencies
        case userAgencyConstant.GET_USER_AGENCY_R:
            return {
                ...state,
            }
        case userAgencyConstant.GET_USER_AGENCY_S:
            return {
                ...state,
                userAgencies: action.data.data
            }
        case userAgencyConstant.GET_USER_AGENCY_F:
            return {
                ...state,
            }

        //update user agencies
        case userAgencyConstant.UPDATE_USER_AGENCY_R:
            return {
                ...state,
            }
        case userAgencyConstant.UPDATE_USER_AGENCY_S:
            return {
                ...state,
                userAgencies: action.data.data
            }
        case userAgencyConstant.UPDATE_USER_AGENCY_F:
            return {
                ...state,
            }

        //updateSignUpUserData
        case userAgencyConstant.UPDATE_AG_DATA_R:
            return {
                ...state,
            }
        case userAgencyConstant.UPDATE_AG_DATA_S:
            return {
                ...state,
                userData: action.data.data
            }
        case userAgencyConstant.UPDATE_AG_DATA_F:
            return {
                ...state,
            }

        //update payment
        case userAgencyConstant.UPDATE_PAYMENT_AG_R:
            return {
                ...state,
            }
        case userAgencyConstant.UPDATE_PAYMENT_AG_S:
            return {
                ...state,
            }
        case userAgencyConstant.UPDATE_PAYMENT_AG_F:
            return {
                ...state,
            }

        /****************************************************************MY BILLS************************************************************************************** */


        //GET_PLAN_DETAILS
        case userAgencyConstant.GET_PLAN_DETAILS_R:
            return {
                ...state,
                planDetails: {}
            }
        case userAgencyConstant.GET_PLAN_DETAILS_S:
            return {
                ...state,
                planDetails: action.data.data
            }
        case userAgencyConstant.GET_PLAN_DETAILS_F:
            return {
                ...state,
                planDetails: {}
            }

        //update payment
        case userAgencyConstant.GET_PAYMENT_HISTORY_R:
            return {
                ...state,
                payHistory: []
            }
        case userAgencyConstant.GET_PAYMENT_HISTORY_S:
            return {
                ...state,
                payHistory: action.data.data
            }
        case userAgencyConstant.GET_PAYMENT_HISTORY_F:
            return {
                ...state,
                payHistory: []
            }

        //update payment
        case userAgencyConstant.GET_INV_DETAILS_R:
            return {
                ...state,
                pInvoice: {}
            }
        case userAgencyConstant.GET_INV_DETAILS_S:
            return {
                ...state,
                pInvoice: action.data.data
            }
        case userAgencyConstant.GET_INV_DETAILS_F:
            return {
                ...state,
                pInvoice: {}
            }

        //update payment
        case userAgencyConstant.DOWNLOAD_INVOICE_R:
            return {
                ...state,
            }
        case userAgencyConstant.DOWNLOAD_INVOICE_S:
            return {
                ...state,
            }
        case userAgencyConstant.DOWNLOAD_INVOICE_F:
            return {
                ...state,
            }

        //update payment
        case userAgencyConstant.NEXT_INVOICE_DATE_R:
            return {
                ...state,
                nInvData: {}
            }
        case userAgencyConstant.NEXT_INVOICE_DATE_S:
            return {
                ...state,
                nInvData: action.data.data
            }
        case userAgencyConstant.NEXT_INVOICE_DATE_F:
            return {
                ...state,
                nInvData: {}
            }


        //update payment
        case userAgencyConstant.GET_AUTO_PAY_R:
            return {
                ...state,
                autoPay: {}
            }
        case userAgencyConstant.GET_AUTO_PAY_S:
            return {
                ...state,
                autoPay: action.data.data
            }
        case userAgencyConstant.GET_AUTO_PAY_F:
            return {
                ...state,
                autoPay: {}
            }

        //get card details
        case userAgencyConstant.GET_CARD_DETAILS_R:
            return {
                ...state,
                cardDetails: {}
            }
        case userAgencyConstant.GET_CARD_DETAILS_S:
            return {
                ...state,
                cardDetails: action.data.data
            }
        case userAgencyConstant.GET_CARD_DETAILS_F:
            return {
                ...state,
                cardDetails: {}
            }
        //get myBills User Info
        case userAgencyConstant.GET_MYBILLS_USER_INFO_R:
            return {
                ...state,
                myBillsUserInfo: {}
            }
        case userAgencyConstant.GET_MYBILLS_USER_INFO_S:
            return {
                ...state,
                myBillsUserInfo: action.data.data
            }
        case userAgencyConstant.GET_MYBILLS_USER_INFO_F:
            return {
                ...state,
                myBillsUserInfo: {}
            }

        //get card details
        case userAgencyConstant.GET_AG_NAME_LOGO_R:
            return {
                ...state,
                agencyData: {}
            }
        case userAgencyConstant.GET_AG_NAME_LOGO_S:
            return {
                ...state,
                agencyData: action.data.data
            }
        case userAgencyConstant.GET_AG_NAME_LOGO_F:
            return {
                ...state,
                agencyData: {}
            }

        case userAgencyConstant.GET_FEEDBACK_FORM_R:
            return {
                ...state,
            }
        case userAgencyConstant.GET_FEEDBACK_FORM_S:
            return {
                ...state,
                feedbackForm: action.data.data
            }
        case userAgencyConstant.GET_FEEDBACK_FORM_F:
            return {
                ...state,
            }

        case userAgencyConstant.CANCEL_AGENCY_FEEDBACK_R:
        case userAgencyConstant.CANCEL_AGENCY_FEEDBACK_S:
        case userAgencyConstant.CANCEL_AGENCY_FEEDBACK_F:
            return {
                ...state,
            }

        case userAgencyConstant.UPDATE_TO_SOLO_R:
        case userAgencyConstant.UPDATE_TO_SOLO_S:
        case userAgencyConstant.UPDATE_TO_SOLO_F:
            return {
                ...state,
            }

        case userAgencyConstant.PAY_UNPAID_R:
        case userAgencyConstant.PAY_UNPAID_S:
        case userAgencyConstant.PAY_UNPAID_F:
            return {
                ...state,
            }

        case userAgencyConstant.AGENCY_USER_LIMITS_R:
            return {
                ...state,
            }
        case userAgencyConstant.AGENCY_USER_LIMITS_S:
            return {
                ...state,
                userLimits: action.data.data
            }
        case userAgencyConstant.AGENCY_USER_LIMITS_F:
            return {
                ...state,
            }

        case userAgencyConstant.AGENCY_PLANS_R:
            return {
                ...state,
            }
        case userAgencyConstant.AGENCY_PLANS_S:
            return {
                ...state,
                allPlans: action.data.data
            }
        case userAgencyConstant.AGENCY_PLANS_F:
            return {
                ...state,
            }

        case userAgencyConstant.UPGRADE_PLAN_R:
        case userAgencyConstant.UPGRADE_PLAN_S:
        case userAgencyConstant.UPGRADE_PLAN_F:
            return {
                ...state,
            }

        case userAgencyConstant.CREATE_SUB_ON_ERR_R:
            return {
                ...state,
            }
        case userAgencyConstant.CREATE_SUB_ON_ERR_S:
            return {
                ...state,
            }
        case userAgencyConstant.CREATE_SUB_ON_ERR_F:
            return {
                ...state,
            }

        case userAgencyConstant.GET_AVAILABLE_PLAN_DETAILS_R:
            return {
                ...state,
                availablePlans: []
            }
        case userAgencyConstant.GET_AVAILABLE_PLAN_DETAILS_S:
            return {
                ...state,
                availablePlans: action.data.data,
            }
        case userAgencyConstant.GET_AVAILABLE_PLAN_DETAILS_F:
            return {
                ...state,
                availablePlans: []
            }

        // get all plans new
        case userAgencyConstant.PLANS_LIST_R:
            return {
                ...state,
                plansList: []
            }
        case userAgencyConstant.PLANS_LIST_S:
            const curPlan = (action.data.data || []).find(f => (f.is_current === 1))
            return {
                ...state,
                plansList: action.data.data,
                upgradePlan: {
                    currentPlan: curPlan ? curPlan.id : ''
                }
            }
        case userAgencyConstant.PLANS_LIST_F:
            return {
                ...state,
                plansList: []
            }

        // get all plans new
        case userAgencyConstant.PLANS_MEMBERS_R:
            return {
                ...state,
                planMembers: initialState.planMembers
            }
        case userAgencyConstant.PLANS_MEMBERS_S:
            return {
                ...state,
                planMembers: action.data.data
            }
        case userAgencyConstant.PLANS_MEMBERS_F:
            return {
                ...state,
                planMembers: initialState.planMembers
            }

        // get all plans new
        case userAgencyConstant.PLANS_ADD_ON_LIST_R:
            return {
                ...state,
                planAddOn: initialState.planAddOn
            }
        case userAgencyConstant.PLANS_ADD_ON_LIST_S:
            return {
                ...state,
                planAddOn: action.data.data
            }
        case userAgencyConstant.PLANS_ADD_ON_LIST_F:
            return {
                ...state,
                planAddOn: initialState.planAddOn
            }

        case userAgencyConstant.UPDATE_PLAN_TYPE:
            return {
                ...state,
                upgradePlan: {
                    currentPlan: action.data
                }
            };

        case userAgencyConstant.UPGRADE_PLANS_NEW_R:
        case userAgencyConstant.UPGRADE_PLANS_NEW_S:
        case userAgencyConstant.UPGRADE_PLANS_NEW_F:
            return {
                ...state,
            }

        case userAgencyConstant.DELETE_USER_AGENCY_S:
            return {
                ...state,
                userAgencies: initialState.userAgencies
            }

        default:
            return state
    }
}
