import { compose, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from '@redux-devtools/extension';
import { logger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import {saveState, loadState, removeState} from './localStorage';

// Import Reducers and Constants Files
import appReducer from "../redux/reducers";
import { authConstant, boardsConstant, docsConstant, userAgencyConstant } from "../redux/constants";
import {
  setUserBoardFilterPreferences,
  setUserDocPreferences,
  isFlozyMobileApp
} from "../utils";

const rootReducer = (state, action) => {
  if (action.type === authConstant.LOGOUT_S) {
    state = undefined;
    // remove reducer data on in local storage
    removeState()
  } else if (action.type === authConstant.USER_LOGIN_R) {
    // remove reducer data on in local storage
    removeState()
  }else if (action.type === userAgencyConstant.UPDATE_USER_AGENCY_S) {
    removeState()
  } else if (action.type === "RESET") {
    // Reset state values
  } else { }

  return appReducer(state, action);
};

const preferenceMiddleware = ({ getState }) => {
  return (next) => (action) => {
    // Save the reducer data on all dispatch
    //remove loading state
    const store_data = getState()
    const updated_store = {}
    if(!isFlozyMobileApp()) {
      // Add the reducer here to use the persist store
      updated_store["auth"] = store_data?.auth || {}
      updated_store["chatv2"] = store_data?.chatv2 || {}
      // updated_store["clients"] = store_data?.clients || {}
      // updated_store["project"] = store_data?.project || {}
      updated_store["schedule"] = store_data?.schedule || {}
      updated_store["docs"] = store_data?.docs || {}
      updated_store["media"] = store_data?.media || {}
      updated_store["clientMedia"] = store_data?.clientMedia || {}
      saveState(updated_store);
    }

    const result = next(action);
    if (action.type === boardsConstant.SAVE_BOARD_FILTER
      || action.type === boardsConstant.UPDATE_BOARD_FILTER) {
      const { boards: { saved_filters, board_id } } = getState()
      setUserBoardFilterPreferences(board_id, saved_filters)
    }

    if (action.type === docsConstant.GET_DOC_CONTENT_S) {
      const { docs: { editor: { id } } } = getState()
      if (id) {
        setUserDocPreferences({
          editor: {
            id
          }
        })
      }
    }
    return result;
  };
};

//remove redux logger in prod env
const isProd = process.env.REACT_APP_BUILD_TO && process.env.REACT_APP_BUILD_TO === 'PROD'
const enhancer = composeWithDevTools(
  applyMiddleware(
    thunkMiddleware,
    ...(!isProd ? [logger] : []),
    preferenceMiddleware
  )
);

// Added persistant store to save the reducer data into the local storage
const persistedState = loadState() || {}

export default function store(initialState) {
  return createStore(
    rootReducer,
    isFlozyMobileApp() ? initialState : persistedState,
    enhancer
  );
}
