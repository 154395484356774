import { customNodeProps, pushHistory } from "./utils";

export const textNode = ({ text: value, stage, canvasWrapperRef }) => {

    const layer = stage.findOne('#baseLayer')
    const tr = stage.findOne('#transformer')
    stage.add(layer);

    const onUndo = (type = 1) => {
        const { history_id } = text?.attrs
        const nextHistoryId = history_id - (1 * (type))
        const history_data = text?.attrs?.history[nextHistoryId]
        if (history_data) {
            text.x(history_data.x)
            text.y(history_data.y)
            text.text(history_data.text)
            text.fill(history_data.fill)
            text.fontSize(history_data.fontSize)
            tr.show()
            tr.nodes([text])
            tr.zIndex(layer.getChildren().length)
            text.attrs.history_id = nextHistoryId
        }
    }

    const onChangeAttrs = (attrs) => {
        text.setAttrs({
            fill: attrs.fill,
            fontFamily: attrs.fontFamily || text.fontFamily,
            fontStyle: attrs.fontStyle || text.fontStyle,
            fontSize: attrs.fontSize || text.fontSize
        })
        pushHistory(text, 'textNode')
        stage.attrs.onUpdate(stage)
    }

    const text = new window.Konva.Text({
        text: value,
        fontSize: 20,
        draggable: true,
        width: 200,
        fontFamily: 'sans-serif',
        fontStyle: 'normal',
        fill: '#000',
        name: 'text need-tr',
        id: `text_${new Date().getTime()}`,
        ...customNodeProps({ stage }),
        undo: onUndo,
        nodeType: 'textNode',
        onChangeAttrs: onChangeAttrs
    });

    text.x(stage.width() / 2 - text.width() / 2)
    text.y(stage.height() / 2 - text.height() / 2)

    layer.add(text);
    pushHistory(text, 'textNode')

    text.on('dragend', function () {
        pushHistory(text, 'textNode')
        stage.attrs.onUpdate(stage)
    });

    text.on('transform', function () {
        // reset scale, so only with is changing by transformer
        text.setAttrs({
            width: text.width() * text.scaleX(),
            scaleX: 1,
        });
        pushHistory(text, 'textNode')
        stage.attrs.onUpdate(stage)
    });

    tr.nodes([text])
    tr.show()
    stage.attrs.onUpdate(stage)

    text.on('dblclick dbltap', () => {
        text.hide();
        tr.hide();
        const textPosition = text.absolutePosition();
        const areaPosition = {
            x: textPosition.x,
            y: textPosition.y,
        };
        const textarea = document.createElement('textarea');
        const kcWrpr = canvasWrapperRef.getElementsByClassName('konvajs-content')[0]
        kcWrpr.appendChild(textarea);
        textarea.value = text.text();
        textarea.style.position = 'absolute';
        textarea.style.top = areaPosition.y + 'px';
        textarea.style.left = areaPosition.x + 'px';
        textarea.style.width = text.width() - text.padding() * 2 + 'px';
        textarea.style.height =
            text.height() - text.padding() * 2 + 5 + 'px';
        textarea.style.fontSize = text.fontSize() + 'px';
        textarea.style.border = 'none';
        textarea.style.padding = '0px';
        textarea.style.margin = '0px';
        textarea.style.overflow = 'hidden';
        textarea.style.background = 'none';
        textarea.style.outline = 'none';
        textarea.style.resize = 'none';
        textarea.style.lineHeight = text.lineHeight();
        textarea.style.fontFamily = text.fontFamily();
        textarea.style.transformOrigin = 'left top';
        textarea.style.textAlign = text.align();
        textarea.style.color = text.fill();
        const rotation = text.rotation();
        let transform = '';
        if (rotation) {
            transform += 'rotateZ(' + rotation + 'deg)';
        }

        let px = 0;
        let isFirefox =
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
        if (isFirefox) {
            px += 2 + Math.round(text.fontSize() / 20);
        }
        transform += 'translateY(-' + px + 'px)';
        textarea.style.transform = transform;
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 3 + 'px';
        textarea.focus();

        function removeTextarea() {
            if (textarea) {
                textarea.parentNode.removeChild(textarea);
                text.show();
                tr.show();
                tr.forceUpdate();
            }
        }

        function setTextareaWidth(newWidth) {
            if (!newWidth) {
                // set width for placeholder
                newWidth = text.placeholder.length * text.fontSize();
            }
            // some extra fixes on different browsers
            let isSafari = /^((?!chrome|android).)*safari/i.test(
                navigator.userAgent
            );
            let isFirefox =
                navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if (isSafari || isFirefox) {
                newWidth = Math.ceil(newWidth);
            }

            let isEdge =
                document.documentMode || /Edge/.test(navigator.userAgent);
            if (isEdge) {
                newWidth += 1;
            }
            textarea.style.width = newWidth + 'px';
        }

        textarea.addEventListener('blur', function () {
            if (textarea.value != text.text()) {
                text.text(textarea.value);
                pushHistory(text, 'textNode')
                stage.attrs.onUpdate(stage)
            }
            removeTextarea();
        })

        // textarea.addEventListener('keydown', function (e) {
        //     // hide on enter
        //     // but don't hide on shift + enter
        //     if (e.keyCode === 13 && !e.shiftKey) {
        //         if (textarea.value != text.text()) {
        //             text.text(textarea.value);
        //             removeTextarea();
        //             pushHistory(text, 'textNode')
        //             stage.attrs.onUpdate(stage)
        //         }
        //     }
        //     // on esc do not set value back to node
        //     if (e.keyCode === 27) {
        //         removeTextarea();
        //     }
        // });

        textarea.addEventListener('keydown', function (e) {
            const scale = text.getAbsoluteScale().x;
            setTextareaWidth(text.width() * scale);
            textarea.style.height = 'auto';
            textarea.style.height =
                textarea.scrollHeight + text.fontSize() + 'px';
        });
    });
}