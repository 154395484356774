import { baseAction } from "./baseAction";
import { mediaConstant, alertConstant, loaderConstant } from "../constants";
import { mediaService } from "../services";

const { request, success, failure } = baseAction;

// All login based actions
export const mediaAction = {
  createFolder,
  getAll,
  updateFolderName,
  deleteFolder,
  deleteFile,
  updateFile,
  updateFolderFile,
  uploadFiles,
  editorUploadFiles,
  editorUploadDocuments
};

/** create new folder */
function createFolder(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(mediaConstant.CREATE_FOLDER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    mediaService.createFolder(params).then(data => {
      dispatch(success(mediaConstant.CREATE_FOLDER_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(mediaConstant.CREATE_FOLDER_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** create new folder */
function getAll(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(mediaConstant.GET_ALL_MEDIA_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    mediaService.getAll(params).then(data => {
      dispatch(success(mediaConstant.GET_ALL_MEDIA_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(mediaConstant.GET_ALL_MEDIA_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update folder name*/
function updateFolderName(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(mediaConstant.UPDATE_FOLDER_NAME_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    mediaService.updateFolderName(params).then(data => {
      dispatch(success(mediaConstant.UPDATE_FOLDER_NAME_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(mediaConstant.UPDATE_FOLDER_NAME_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** delete folder*/
function deleteFolder(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(mediaConstant.DELETE_FOLDER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    mediaService.deleteFolder(params).then(data => {
      dispatch(success(mediaConstant.DELETE_FOLDER_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(mediaConstant.DELETE_FOLDER_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


/** delete file*/
function deleteFile(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(mediaConstant.DELETE_FILE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    mediaService.deleteFile(params).then(data => {
      dispatch(success(mediaConstant.DELETE_FILE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(mediaConstant.DELETE_FILE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


/** update file*/
function updateFile(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(mediaConstant.UPDATE_FILE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    mediaService.updateFile(params).then(data => {
      dispatch(success(mediaConstant.UPDATE_FILE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(mediaConstant.UPDATE_FILE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update folder file*/
function updateFolderFile(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(mediaConstant.UPDATE_FILE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    mediaService.updateFolderFile(params).then(data => {
      dispatch(success(mediaConstant.UPDATE_FILE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(mediaConstant.UPDATE_FILE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update folder file*/
function uploadFiles(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(mediaConstant.UPLOAD_FILE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    mediaService.uploadFiles(params).then(data => {
      dispatch(success(mediaConstant.UPLOAD_FILE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(mediaConstant.UPLOAD_FILE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** Upload files for editor*/
function editorUploadFiles(params, callBack = () => { }) {
  return (dispatch) => {
    mediaService.editorUploadFiles(params).then(data => {
      callBack(data?.data)
    }, error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      callBack(null)
    }
    );
  };
}

function editorUploadDocuments(params, callBack = () => { }) {
  return (dispatch) => {
    mediaService.editorUploadDocuments(params).then(data => {
      callBack(data?.data)
    }, error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}