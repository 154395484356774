import React from 'react';
import StyleButton from './StyleButton';

function InlineStyleControls(props) {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  var INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD', icon: 'icon-format_bold', tooltip:"Bold" },
    { label: 'Italic', style: 'ITALIC', icon: 'icon-format_italic', tooltip:"Italic" },
    { label: 'Underline', style: 'UNDERLINE', icon: 'icon-format_underlined', tooltip:"Underline" },
    { label: 'Monospace', style: 'CODE', icon: 'icon-highlight', tooltip:"Monospace" },
    { label: 'STRIKETHROUGH', style: 'STRIKETHROUGH', icon: 'icon-format_strikethrough', tooltip:"Strikethrough" },
  ];
  return (
    <div className="editor-controls">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
          tooltip={type.tooltip}
        />
      ))}
    </div>
  );
}

export default InlineStyleControls;
