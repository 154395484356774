import moment from "moment"
export const formatDate = (date, format) => {
    if (date && moment(date).isValid()) {
        const dateDiff = moment(moment(date).format("YYYY-MM-DD")).diff(moment().format("YYYY-MM-DD"), 'days')
        if (dateDiff == 0) {
            return "Ends today"
        } else if (dateDiff == 1) {
            return "1 day left"
        }
        return moment(date).format(format)
    } else {
        return ""
    }
}
export const formatMediaDate = (date, showTime = true) => moment(date).format(`MMM D, YYYY ${showTime ? "h:mm A" : ""}`)
export function convertBytes(bytes) {
    if (bytes >= 1073741824) {
        return (bytes / 1073741824).toFixed(2) + " GB";
    } else if (bytes >= 1048576) {
        return (bytes / 1048576).toFixed(2) + " MB";
    } else {
        return (bytes / 1024).toFixed(2) + " KB";
    }
}
export function toTitleCase(word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}
export const getMediaDuration = (url) => new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const source = document.createElement('source');

    source.src = url; // Assuming file is defined elsewhere
    video.appendChild(source);

    video.addEventListener('loadedmetadata', () => {
        const duration = video.duration;
        document.body.removeChild(video);
        resolve(duration);
    });

    video.addEventListener('error', (error) => {
        document.body.removeChild(video);
        reject(error);
    });

    video.autoplay = false;
    video.style.display = 'none';

    document.body.appendChild(video);
});
export function secondsToTime(durationInSeconds) {
    const duration = moment.duration(durationInSeconds, 'seconds');
    const format = duration.asHours() >= 1 ? 'HH:mm:ss' : 'mm:ss';
    return moment.utc(duration.asMilliseconds()).format(format);
}
// convert json string attachment to display type
export const formatAttachments = (attachmentsString, format_type = 1) => {
    let attachments = ""
    try {
        attachments = JSON.parse(attachmentsString)
        if (format_type == 1) {
            if (Array.isArray(attachments) && attachments.length > 0) {
                // return `${attachments.length} file(s)`
                return `${attachments.length}`
            } else {
                return ""
            }
        } else if (format_type == 2) {
            if (Array.isArray(attachments) && attachments.length > 0) {
                return attachments
            } else {
                return []
            }
        }
    } catch (err) {
        return ""
    }
}

export const parseUsersArray = (usersList) => {
    try {
        return JSON.parse(usersList)
    } catch (err) {
        return []
    }
}

export const convertToFormData = (jsonData) => {
    // convert to formData for attachments
    let formData = new FormData();
    Object.keys(jsonData).forEach(data => {
        formData.set(data, jsonData[data])
    })
    // send old attachments
    if (jsonData['attachments'] && jsonData['attachments'].length > 0) {
        formData.set("attachments", JSON.stringify(jsonData['attachments']));
    }
    // new attachments
    if (jsonData['attachmentsNew'] && jsonData['attachmentsNew'].length > 0) {
        formData.set("files_data", JSON.stringify(jsonData['attachmentsNew']));
        jsonData['attachmentsNew'].forEach(file => {
            formData.append("file", file);
        });
    }

    return formData
}

export const filePreview = (file) => {

    if (!file) return null

    // for uploaded file
    if (typeof file === "string") return file

    if (file.url) return file.url

    switch (file.type) {
        case "image/png":
        case "image/jpeg":
        case "image/jpg":
            return URL.createObjectURL(file)
        default: return null
    }
}

export const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

export const formatCoverColor = (cover_color) => {
    if (!cover_color) return null
    try {
        if (typeof cover_color === 'string') {
            return JSON.parse(cover_color)
        } else {
            return cover_color
        }
    } catch (err) {
        return { r: 255, g: 255, b: 255, a: 1 }
    }
}

export const getUrl = (url) => {
    const isUrl = url.startsWith("https://") || url.startsWith("http://");
    return isUrl ? url : `https://${url}`;
}

export const decodeIdToken = (token) => {
    try {
        return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())['sub'];
    } catch (e) {
        return null;
    }
}
export const decodeIdTokenForEmail = (token) => {
    try {
        return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())['email'];
    } catch (e) {
        return null;
    }
}