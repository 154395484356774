import '@draft-js-plugins/emoji/lib/plugin.css';
const styles = (theme) => ({
  roots: {
    height: 36,
    '& .MuiOutlinedInput-root': {
      height: 36,
      minHeight: 36
    }
  },
  replyTile: {
    // position: 'absolute',
    bottom: 0,
    left: 0,
    background: theme.palette.containers.bg5,
    zIndex: 1
  },
  replyTileLeft: {
    width: "calc(100% - 80px)",
    padding: "10px",
    margin: "7px 0px 7px 20px",
    marginRight: "0",
    background: theme.palette.containers.bg24,
    borderLeft: "4px solid #2563EB",
    borderRadius: "6px",
    '& .tasksText': {
      color: '#7F83A0 !important'
    }
  },
  replyTileFile: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  replyTileRight: {
    width: "50px",
  },
  closeIcon: {
    width: 24,
    height: 24
  },
  replyThumb: {
    width: "100px",
    height: "auto",
    maxHeight: "100px",
    display: "flex",
    objectFit: "cover"
  },
  replyCloseBtn: {
    width: "100%",
    height: "50px",
  }
});

export default styles;
