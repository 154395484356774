import { workSpaceCloningService } from "../services/workSpaceCloningService";
import { baseAction } from "./baseAction";
import { alertConstant, loaderConstant } from "../constants";

const { request, success, failure } = baseAction;
const completeClonningProcess = ()=>({
    type:"CLONING_PROGRESS_UPDATE_C",
})
const updateCloningProgress = (progress,data={}) => ({
    type: 'CLONING_PROGRESS_UPDATE',
    progress,
});

const getSocektEventData = (data)=>({
    type:"CLONING_NOTIFY_DATA",
    data
})
export const workspaceCloningActions = {
    cloneWorkSpace,
    getCollabUser,
    completeClonningProcess,
    updateCloningProgress,
    getSocektEventData
};

function cloneWorkSpace(params) {
    return (dispatch) => {
        dispatch(request("CLONE_WORKSPACE_R", ""));
        dispatch(request(loaderConstant.LOADING_TRUE));

        let currentStep = 0;
        const totalSteps = 5;

        // First interval to simulate progress from 0% to 55%
        const firstInterval = setInterval(() => {
            currentStep += 1;
            const progress = Math.min((currentStep / totalSteps) * 55, 55); // Simulate up to 55%
            dispatch({ type: "CLONING_PROGRESS_UPDATE", progress });

            if (progress >= 55) {
                clearInterval(firstInterval); // Stop the first interval

                // API request starts after progress reaches 55%
                workSpaceCloningService.cloneWorkspace(params).then(data => {
                    let apiProgressStep = 0;
                    const remainingSteps = 5;

                    // Second interval to simulate progress from 55% to 80%
                    const secondInterval = setInterval(() => {
                        apiProgressStep += 1;
                        const progress = Math.min(55 + (apiProgressStep / remainingSteps) * 25, 80); // Simulate up to 80%
                        dispatch({ type: "CLONING_PROGRESS_UPDATE", progress });

                        if (progress >= 80) {
                            clearInterval(secondInterval);
                        }
                    }, 1000); // Update every second

                    // When the API response is received, clear any intervals and set progress to 100%
                    // dispatch({ type: "CLONING_PROGRESS_UPDATE", progress: 100 });
                    clearInterval(secondInterval);
                    dispatch(success("CLONED_STATUS_S", data.data)); // API Success Action
                    dispatch(request(loaderConstant.LOADING_FALSE));

                }).catch(error => {
                    // API call failure
                    clearInterval(firstInterval); // Clear the intervals in case of failure
                    dispatch(failure("CLONE_WORKSPACE_F", error));
                    dispatch(failure(alertConstant.ALERT_ERROR, error));
                    dispatch(request(loaderConstant.LOADING_FALSE));
                });
            }
        }, 1000); // Update every second
    };
}




// Action to update the cloning progress


function getCollabUser(params, cb = () => { }) {
    return (dispatch) => {
        dispatch(request("COLLAB_USER_R", ""));
        dispatch(request(loaderConstant.LOADING_TRUE));
        workSpaceCloningService.getCollabUser({email:params}).then(data => {
            dispatch(success("COLLAB_USER_S", data.data));
            dispatch(request(loaderConstant.LOADING_FALSE));
            dispatch(updateCloningProgress(100,{}));
            cb(data.data)
        }, error => {
            dispatch(failure("COLLAB_USER_F", error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
            // Stop the loading spinner
        });
    };
}
