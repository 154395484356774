import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Typography,
  withStyles,
} from "@material-ui/core";
import style from "./style";

import AgoraRTC from "agora-rtc-sdk-ng";
import VCParticipant from "../VCParticipant";
import VCLobby from "../VCLobby";
import VCMiniParticipantList from "../VCMiniParticipantList";

import { useDispatch, useSelector } from "react-redux";
import history from "../../../config/history";
import { chatHubAction, chatv2Action, profileAction, userAgencyAction } from "../../../redux/actions";
import { app_credentials } from "../../../config/app_credentials";
import { videoCallAction } from "../../../redux/actions/videoCallAction";
import { alertAction } from "../../../redux/actions/alertAction";
import { joinVideoCall, muteAudio, muteVideo, shareScreen, stopScreenShare, stopShareScreen } from "../../../utils/socketv2/index";
import { getDimentions } from "../../../utils/videoHelper";
import axios from "axios";
import { _api } from "../../../config/environment";
import VCMenu from "../VCMenu"
import { CallDurationIcon, CarouselIconLeft, CarouselIconRight } from "../../../assets/svg/videocall";
import VCLeavePage from "../VCLeave";
import VideoCallRecordingPopUp from "../VCRecordPopup/index"
import StopRecordPopup from "../VCRecordStopPopUp"
// Do not mutate or spread(...) any objects from the agora client maually. Always use the exsisting agora client methods to do any actions.
const agoraClient = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8",
});

function RecordingRoom(props) {
  const { classes, id, match, type } = props;
  const [joined, setJoined] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [talkingAttendees, setTalkingAttendees] = useState([])
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [audioMuted, setAudioMuted] = useState(false);
  const [localVideoTracks, setLocalVideoTracks] = useState({ videoTrack: null, audioTrack: null });
  const [videoElements, setVideoElements] = useState({});
  const [pinnedUserVideo, setPinnedUserVideo] = useState(false);
  const [requestSend, setRequestSend] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [screenShare, setScreenShare] = useState(null);
  const [screenTrack, setScreenTrack] = useState([]);
  const { videoCallRequest, remoteUsers,videoCallRecordStartResponse } = useSelector((state) => state.videocall);
  const [screenShareUsers, setScreenShareUsers] = useState([]);
  const [screenVideo, setScreenVideo] = useState(false);
  const [mutedUsers, setMutedUsers] = useState([]);
  const [screen, setScreen] = useState(null)
  const [pinnedUsers, setPinnedUsers] = useState([])
  const [unpinnedUsers, setUnpinnedUsers] = useState([])
  const [unpinnedUserHide, setUnpinnedUserHide] = useState(true)
  const [token, setToken] = useState()
  const [optimalVideoDimention, setOptimalVideoDimention] = useState({ width: "100", height: "100" })
  const [showChat, setShowChat] = useState(false);
  const [userId, setUserId] = useState()
  const [username, setUsername] = useState('recording_user')
  const [avatar, setAvatar] = useState('')
  const [localUser, setLocalUser] = useState([])
  const [error, setError] = useState('');
  const [showStopSharing, setShowStopSharing] = useState(false);
  const [primaryAttendeeList, setPrimaryAttendeeList] = useState([])
  const [secondaryAttendeeList, setSecondaryAttendeeList] = useState([])
  const [validationTime, setValidationTime] = useState(null);
  const [showVCLeavePage, setShowVCLeavePage] = useState(false);
  const [networkErrPage, setNetworkErrorPage] = useState(false);
  const [isOpenRecordDialog,setIsOpenRecordDialog] = useState(false)
  const [openDialog,setOpenDialog] = useState(false)
  const [volume, setVolume] = useState()
  const {
    VideoCall: { APP_ID, APP_CERTIFICATE },
  } = app_credentials;

  const { team } = useSelector((state) => state.chatHub);
  const { project } = useSelector((state) => state.chat);
  const { client } = useSelector((state) => state.chat);
  const user_id = useSelector((s) => s.auth.loginUser.id);
  const dispatch = useDispatch();
  const { requestId, channelStatus,videoCallStartState } = useSelector((state) => state.videocall);
  const { profile } = useSelector((s) => s.profile);
  const { channel } = useSelector((state) => state.chatv2);
  const { remoteuser } = useSelector((state) => state.videocall.remoteUsers);
  const screenShareVideo = useSelector(
    (state) => state.videocall.screenShareVideo
  );
  const { planDetails } = useSelector(s => (s.userAgency))
  const { selected_channel } = useSelector((state) => state.chatv2);

  const video = useSelector((state) => state.videocall.screenTrack);
  const { allUsers } = useSelector((s) => s.chatv2);
  const { stopShareScreen, togglePV } = useSelector((s) => s.videocall)
  let userName = '';
  let userAvatar = ''

  // Debounce Implementation
  let debounceTimer = null;

  const setDebounceValidator = () => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setValidationTime(new Date());
    }, 5000);
  }

  function generateThreeDigitId() {
    return (Math.floor(Math.random() * 900) + 100)
  }

  useEffect(() => {
    getChannelInfo();
    dispatch(chatv2Action.setChannel({ type, ChannelId }));
    if (user_id) {
      dispatch(profileAction.getProfile())
    }
  }, [dispatch, type]);

  useEffect(() => {
    if (user_id) {
      setUserId(user_id);
      dispatch(chatHubAction.getAllUsers());
    } else {
      const newId = `recording_${generateThreeDigitId()}`
      dispatch(videoCallAction.setRecorderUserId(newId))
      setUserId(newId);
    }
  }, [user_id]);

  const generateAgoraToken = async () => {
    try {
      let uid = user_id
      if (!user_id) {
        uid = userId;
      }
      const response = await axios.post(_api.url + "/videocall/generateAgoraToken", {
        channelName: CHANNEL,
        uid,
        APP_ID: APP_ID,
        APP_CERTIFICATE: APP_CERTIFICATE,
        role: "publisher",
        tokentype: "userAccount",
      });
      const token = response.data.message.rtcToken;
      setToken(token)
      if(token){

          await joinCall(token)
          
      }
      return token;
    } catch (error) {
      return null;
    }
  };

  //getInfo for join channel
  const channelId = () => {
    const idInfo = {
      teams: team.activeProject,
      clients: client.activeClient,
      projects: project.activeProject,
    };
    return idInfo[selected_channel.type] || "";
  };
  const ChannelId = channelId();

  const getChannelInfo = () => {
    const channelInfo = {
      teams: team.channelName && team.channelName[0]?.uuid,
      clients: client.channelName && client.channelName[0]?.uuid,
      projects: project.channelName && project.channelName[0]?.uuid,
    };
    return channelInfo[selected_channel.type] || "";
  };

  const getGroupName = () => {
    const groupNames = {
      teams: team.channelName && team.channelName[0]?.name,
      clients: client.channelName && client.channelName[0]?.client_name,
      projects: project.channelName && project.channelName[0]?.job_title,
    };
    return groupNames[selected_channel.type] || "";
  };

  const call_type = "meeting"
  const groupName = getGroupName();



  const channelInfo = channel[type] && channel[type];

  let CHANNEL = match.params.meetingId

  if (type) {
    CHANNEL = getChannelInfo()
  }

  const chatId = id;

  let matchingUserIds = [];
  let users = [];

  if (channel && channelInfo?.length > 0) {
    const matchingChannel = channelInfo.find(
      (item) => String(item.id) === String(chatId)
    );

    if (matchingChannel) {
      matchingUserIds = matchingChannel.users?.map((user) => user.id) || [];

      matchingUserIds = matchingChannel.users.map((user) => user.id) || [];
      users = matchingUserIds.filter((id) => id !== user_id);
    }
  }

  const [waitingMessageDisplayed, setWaitingMessageDisplayed] = useState(false);

  //user joined
  const handleUserJoined = async (user, mediaType) => {

    if (attendees.some((attendee) => attendee.user.uid === user.uid)) {
      return;
    }
    if (userId !== user.uid && mediaType) {
      await agoraClient.subscribe(user, mediaType);
    }

    let localAudioTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'audio');
    let localVideoTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'video');

    const currentUser = {
      uid: user_id || userId,
      videoTrack: localVideoTrack,
      audioTrack: localAudioTrack,
      hasVideo: localVideoTrack?.enabled,
      hasAudio: localAudioTrack?.enabled
    };

    setAttendees([...agoraClient.remoteUsers.filter(u => u.uid !== user_id && u.uid !== userId).map(u => {
      return {
        user: u,
        metadata: {}
      }
    }), {
      user: currentUser,
      metadata: {}
    }]);

    if (mediaType === "video") {
      if (!videoElements[user.uid]) {
        setVideoElements((prevVideoElements) => ({
          ...prevVideoElements,
        }));
      }
    }

    if (mediaType === "audio") {
      if (user.hasAudio) {
        if (!user.audioTrack) {
          await agoraClient.subscribe(user, 'audio');
        }
        user.audioTrack.play();
      }
    }

    if (mediaType === "video" && user?.videoTrack?._enabled === false) {
      setIsVideoMuted(true);
    }
  };


  const handleUserLeft = (user, reason) => {
    if (user) {
      setAttendees((previousAttendees) =>
        previousAttendees.filter((attendee) => attendee?.user?.uid !== user.uid)
      );
    }
    if (user?.videoTrack && user?.videoTrack.isScreen) {
      setScreenShareUsers((previousAttendees) =>
        previousAttendees.filter((attendee) => attendee?.user?.uid !== user.uid)
      );
    }
  };

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      if (!e.target.value.trim()) {
        setError('Please enter your name.');
      } else if (validateInput(e.target.value)) {
        setUsername(e.target.value);
        await joinCall();
      }
    }
  };


  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length > 60) {
      setError('Please enter 60 characters only.');
    } else {
      setError('');
      setUsername(value);
    }
  };


  const validateInput = (input) => {
    const regex = /^[\w\s\S]{1,60}$/;
    return regex.test(input);
  };


  //request send
  
  const joinCall = async (token) => {
    dispatch(videoCallAction.getChannelStatus(CHANNEL))

        if (!user_id && username.length === 0) {
          setError('Please enter your name.');
          return;
        }

        if (!user_id && username.length > 60) {
          setError('Please enter 60 characters only.');
          return;
        } else {

          setJoined(true);
          setRequestSend(true);

          await agoraClient.join(APP_ID, CHANNEL, token, `${user_id || userId}`);


        //   if (audioMuted && isVideoMuted) {
        //     // await agoraClient.publish()
        //   } else if (isVideoMuted) {
        //     await agoraClient.publish([localVideoTracks.audioTrack])
        //   } else if (audioMuted) {
        //     await agoraClient.publish([localVideoTracks.videoTrack])
        //   } else {
        //     await agoraClient.publish([localVideoTracks.audioTrack, localVideoTracks.videoTrack]);
        //   }

          const localVideoTrack = localVideoTracks.videoTrack;
          const localAudioTrack = localVideoTracks.audioTrack;

          if (user_id && profile) {
            userName = profile?.username;
            userAvatar = profile?.avatar_filename
          } else if (username) {
            userName = username;
          }

          setAttendees((previousAttendees) => [
            ...previousAttendees.filter(attendee => attendee.user.uid !== user_id && attendee.user.uid !== userId),
            {
              user: {
                uid: user_id || userId,
                videoTrack: localVideoTrack,
                audioTrack: localAudioTrack,
                hasVideo: false,
                hasAudio: false
              },
              metadata: {
                videoMuted: false,
                audioMuted: false
              }
            },
          ]);

          dispatch(
            videoCallAction.setRemoteUsers([
              {
                uid: userId || userId,
                videoTrack: localVideoTrack,
                audioTrack: localAudioTrack,
                videoMuted: localVideoTrack?.enabled,
                audioMuted: localAudioTrack?.enabled
              },
            ])
          );

          dispatch(
            videoCallAction.joinRequestSuccess(requestId)
          );

          joinVideoCall({
            userId: user_id || userId,
            channelName: CHANNEL,
            type: type,
            callType: "meeting",
            isScreenShared: false,
            username: userName,
            avatar: userAvatar,
            // 1 - private 2 - public
            userType: user_id ? 1 : 2
          });

          dispatch(
            videoCallAction.setRemoteUsers([
              {
                uid: userId || userId,
                videoTrack: localVideoTrack,
                audioTrack: localAudioTrack,
                videoMuted: localVideoTrack?.enabled,
                audioMuted: localAudioTrack?.enabled
              },
            ])
          );
          // history.push(`/recording/${CHANNEL}`)
        }
      }
    
  

  const handleException = async (evt) => {
    if (evt.downlinkNetworkQuality === 6 && evt.uplinkNetworkQuality === 6) {

      setTimeout(async () => {
        agoraClient.localTracks.forEach((track) => {
          if (track.trackMediaType === 'video' || track.trackMediaType === 'audio') {
            track.setEnabled(false);
          }
        });

        // Unsubscribe from remote users
        agoraClient.remoteUsers.forEach(async (user) => {
          user.audioTrack.setVolume(0)
        });

        setNetworkErrorPage(true);
        setShowVCLeavePage(true)
      }, 5000)

    }

  }


  useEffect (() => {


    if (profile && profile.username) {
      setUsername(profile?.username)
    }

    if (CHANNEL && userId) {
      generateAgoraToken()
    }


    agoraClient.on('user-joined', handleUserJoined)
    agoraClient.on("user-published", handleUserJoined);
    agoraClient.on("user-left", handleUserLeft);
    agoraClient.on("user-unpublished", handleUnPublish);
    agoraClient.on('network-quality', handleException);

    return () => {
      agoraClient.off('user-joined', handleUserJoined)
      agoraClient.off("user-published", handleUserJoined);
      agoraClient.off("user-left", handleUserLeft);
      agoraClient.off("user-unpublished", handleUnPublish);
      agoraClient.off("volume-indicator", setTalkingAttendees);
      agoraClient.off('network-quality', handleException);
    };
  }, [CHANNEL, userId, profile]);


  useEffect(() => {

    // Using this to find the person talking
    agoraClient.enableAudioVolumeIndicator();

    agoraClient.on("volume-indicator", volumes => {
      setVolume(volumes)
    })
    return () => {
      agoraClient.off("volume-indicator");
    };
  },[audioMuted])


  const handleUnPublish = (user) => {
    updateUsers();
  };

  const updateUsers = () => {
    const localVideoTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'video');
    const localAudioTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'audio');
    const currentUser = {
      uid: user_id || userId,
      videoTrack: localVideoTrack,
      audioTrack: localAudioTrack,
      hasVideo: localVideoTrack?.enabled,
      hasAudio: localAudioTrack?.enabled
    };

    const updatedAttendees = [...agoraClient.remoteUsers.filter(u => u.uid !== user_id && u.uid !== userId).map(u => {
      return {
        user: u,
        metadata: {}
      }
    }), {
      user: currentUser,
      metadata: {
        videoMuted: !localVideoTrack?.enabled,
        audioMuted: !localAudioTrack?.enabled
      }
    }]
    console.log("watch ===> set Attendee 5", attendees)
    setAttendees(updatedAttendees);
  }

  //leave call
  const handleLeave = async () => {

    if (joined) {
      agoraClient.localTracks.forEach((track) => {
        if (track.trackMediaType === 'video' || track.trackMediaType === 'audio') {
          track.setEnabled(false);
        }
      });

      if (localVideoTracks?.audioTrack?._enabled === true) {
        localVideoTracks.audioTrack.setEnabled(false)
      }

      if (localVideoTracks?.videoTrack?._enabled === true) {
        localVideoTracks.videoTrack.setEnabled(false)
      }

      if (attendees.length === 1) {
        await dispatch(videoCallAction.deleteFlozyRoom({ channel_name: CHANNEL }))
      }

      await agoraClient.unpublish()
      await agoraClient.leave()

      const filteredAttendees = attendees.filter(attendee => attendee?.user?.uid !== user_id && attendee?.user?.uid !== userId);
      setAttendees(filteredAttendees);

      if(screenShare){
        await handleStopSharing()
      }
      setLocalUser([])
      setShowVCLeavePage(true)

    } else {

      localUser?.videoTrack?.close()
      localUser?.audioTrack?.close()
      setJoined(false)

      user_id ? history.push('/calendar') : history.push('/login')
    }



  };




  useEffect(() => {
    if (joined && attendees.length >= 2 && !startTime) {
      setStartTime(new Date());

      const id = setInterval(() => {
        setCallDuration((prevDuration) => prevDuration + 1);
      }, 1000);

      setIntervalId(id);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [joined, startTime, intervalId, attendees]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleScreenShare = async (uid) => {
    if (screenShare) {
      handleStopSharing()
    } else {
      setScreenShare(true);
      setPinnedUserVideo(true)

      const existingVideoTrack = localVideoTracks.videoTrack

      if (existingVideoTrack) {
        await agoraClient.unpublish(existingVideoTrack);
      }

      try {
        const screenShareTrack = await AgoraRTC.createScreenVideoTrack();
        await agoraClient.publish([screenShareTrack]);

        if (screen === false && stopShareScreen !== null && video !== null) {
          setScreen(null)
        }

        shareScreen({
          uid: user_id || userId,
          receiver_id: null,
          channelName: CHANNEL,
          type,
          call_type,
          isScreenShared: true
        });

        setScreenTrack([screenShareTrack]);

        dispatch(
          videoCallAction.setScreenShareVideo([
            {
              uid: user_id || userId,
              screenTrack: screenShareTrack,
            },
          ])
        );


        const stopSharingButton = Array.from(document.querySelectorAll("button")).find(
          (button) => button.textContent.trim() === "Stop Sharing"
        );

        if (stopSharingButton) {
          stopSharingButton.addEventListener("click", handleStopSharing);
        }

        screenShareTrack.getMediaStreamTrack().addEventListener("ended", handleStopSharing);
        handleUserJoined({ uid: user_id || userId, screenTrack: screenShareTrack }, "video");

      } catch (err) {
        if (err.message.includes("Permission denied")) {
          setScreenShare(false);
          if (!isVideoMuted) {
            agoraClient.publish(existingVideoTrack);
          }
        }
      }
    }
  };



  const handleStopSharing = async () => {
    try {
      let localScreenTrack = agoraClient.localTracks.find(track => track.trackMediaType === 'video') || localVideoTracks.videoTrack

      setScreenShare(false)

      if (stopShareScreen !== null) {
        setScreen(false);
      }

      if (localScreenTrack) {
        await localScreenTrack.close();
        await agoraClient.unpublish(localScreenTrack);
      }

      await stopScreenShare({
        sender_id: user_id || userId,
        receiver: users,
        channelName: CHANNEL,
        call_type
      })

      if (!isVideoMuted) {
        await agoraClient.publish([localVideoTracks.videoTrack])
      }

      UnPinUser(user_id || userId);

      updateUsers();
    } catch (error) {
    }
  };


  useEffect(() => {

    if (video !== null) {
      PinUser(video.uid)
    }

    if (stopShareScreen) {
      setPinnedUserVideo(false);
    }


  }, [stopShareScreen, video,]);

  useEffect(() => {
    console.log("watch ===> agoraClient ", agoraClient)
  }, [agoraClient]);

  let userAvatars = [];

  if (attendees && attendees.length > 0) {
    attendees.filter((participant) => {
      const matchedUser = allUsers.find(
        (user) => user.id === participant.uid
      );
      if (matchedUser) {
        userAvatars.push({ avatar: matchedUser.avatar_filename, id: matchedUser.id });
      }
    })

  }
  const [parentWidth, setParentWidth] = useState()

  // Hard coded should be dynamically calculated in future
  let itemsPerPage ;
  parentWidth > 768 ? itemsPerPage = 25 : itemsPerPage = 8

  const  totalPages = Math.ceil(attendees.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(0);

  const [secondaryCurrentPage, setSecondaryCurrentPage] = useState(0);
  const [newItemsPerPage, setNewItemsPerPage] = useState(1)

  const videoContainer = useRef(null);
  const parentContainer = useRef(null);
  const attendeeListContainer = useRef(null);

  const setItemsPerPage = () => {
    if (attendeeListContainer.current) {
      const containerWidth = attendeeListContainer.current.offsetWidth;
      // Added additional pixels to compensate paddings
      const elementWidth = 190;
      const itemsPerPage = Math.floor(containerWidth / elementWidth);
      setNewItemsPerPage(itemsPerPage)
    }
  }

  useEffect(() => {
    const observer = new ResizeObserver(setItemsPerPage);
    if (attendeeListContainer.current) {
      observer.observe(attendeeListContainer.current);
    }
    return () => {
      observer.disconnect();
    };
  }, []);


  function showPage(page) {
    let start, end
    if (secondaryAttendeeList?.length > 0) {
      setCurrentPage(0);
      start = 0;
      end = itemsPerPage;
    } else {
      start = page * itemsPerPage;
      end = start + itemsPerPage;
    }
    const videoContainerArray = Array.from(videoContainer.current.children);
    videoContainerArray.forEach((video, index) => {
      if (index >= start && index < end) {
        video.style.display = 'block';
      } else {
        video.style.display = 'none';
      }
    });
  }
  const start = currentPage * itemsPerPage;
  const end = Math.min((currentPage + 1) * itemsPerPage, attendees.length);


  const handleNextPage = () => {
    const nextPage = currentPage + 1 >= totalPages ? 0 : currentPage + 1;
    setCurrentPage(nextPage);
    showPage(nextPage);
  };

  const handlePrevPage = () => {
    const prevPage = currentPage - 1 < 0 ? totalPages - 1 : currentPage - 1;
    setCurrentPage(prevPage);
    showPage(prevPage);
  };

  useEffect(() => {
    showPage(currentPage);
  }, [secondaryAttendeeList, attendees]);

 // start record acquire function

 const recordDialogHandler = ()=>{
  setIsOpenRecordDialog(true)
  setOpenDialog(true)
 }

 const createRecordAcquire = ()=>{
  let acquireStateValues;
  if(channelStatus[CHANNEL] &&
    channelStatus[CHANNEL][user_id])
    acquireStateValues = channelStatus[CHANNEL][user_id]
    console.log(acquireStateValues,token,"see acquire values")
    console.log(token,"see acquire values 11111")

  dispatch(videoCallAction.getRecordingAcquire({cname:acquireStateValues?.channelId,uid:acquireStateValues?.userId,token:token,app_id:APP_ID, apiUrl:_api.appUrl}))
  setIsOpenRecordDialog(false)
 }
//  const startRecording = ()=>{

//   dispatch(videoCallAction.getRecordingAcquire({cname:acquireStateValues?.channelId,uid:acquireStateValues?.userId,token:token,app_id:APP_ID}))

//  }
console.log(isOpenRecordDialog,"see see here")
 const stopRecording = ()=>{
  let acquireStateValues
   if(videoCallRecordStartResponse){
    
    acquireStateValues = channelStatus[CHANNEL][user_id]
    dispatch(videoCallAction.stopRecording(
      {authorizationField:videoCallRecordStartResponse?.authorizationField,
        app_id:APP_ID,
        resourceId:videoCallRecordStartResponse?.start?.resourceId,
        sid:videoCallRecordStartResponse?.start?.sid,
        cname:acquireStateValues?.channelId,
        uid:acquireStateValues?.userId
      }))
   }
   setIsOpenRecordDialog(false)
 }
  function renderPaginationButtonRight() {
    return (
      attendees.length > itemsPerPage && currentPage !== totalPages - 1 && secondaryAttendeeList?.length === 0 && (
        <div className={classes.pagination} id="pagination" onClick={handleNextPage}>
         <CarouselIconRight />
        </div>
      )
    );
  }

  function renderPaginationButtonLeft() {
    return (
      attendees.length > itemsPerPage && currentPage !== 0 && secondaryAttendeeList?.length === 0 &&(
        <div className={classes.paginationLeft} id="pagination" onClick={handlePrevPage}>
          <CarouselIconLeft /> 
        </div>
      )
    );
  }

  const PinUser = (uid) => {
    setPinnedUserVideo(true);
    if (pinnedUsers.includes(uid)) {
      // Remove the user from the pinned list
      setPinnedUsers((prevPinnedUsers) => prevPinnedUsers.filter((user) => user !== uid));
    } else {
      // Pin the user by adding them to the pinned list
      setPinnedUsers((prevPinnedUsers) => [...prevPinnedUsers, uid]);
    }

    setUnpinnedUsers((prevUnpinnedUsers) => {
      // Remove the pinned user from the unpinned list
      const updatedUnpinnedUsers = prevUnpinnedUsers.filter((user) => user !== uid);
      // Add all other remote users who are not pinned to the unpinned list
      const otherRemoteUsers = attendees.filter((user) => user.uid !== uid).map((user) => user.uid);

      return [...updatedUnpinnedUsers, ...otherRemoteUsers];
    });
  };

  const UnPinUser = (uid) => {
    setPinnedUsers((prevPinnedUsers) => prevPinnedUsers.filter((user) => user !== uid));
    if (pinnedUsers.length <= 1) {
      setPinnedUserVideo(false)
    }
  }
  function expandUsers() {
    setUnpinnedUserHide(!unpinnedUserHide);
  }

  const resubscribe = async (uid, mediaType = 'video') => {
    console.log("revived ===> uid ", uid)
    const targetUser = agoraClient.remoteUsers.find((user) => user.uid === uid)
    await agoraClient.subscribe(targetUser, mediaType);
    updateUsers();
  }

  const handleResize = () => {
    if (parentContainer.current?.offsetWidth) {
      let attendeeSize = 0;
      const parentDimentions = {
        width: parentContainer.current?.offsetWidth,
        height: parentContainer.current?.offsetHeight - attendeeSize,
      }

      setParentWidth(parentDimentions.width)
      if(parentWidth > 768){
        const { width, height } = getDimentions(parentDimentions, "16:9", videoContainer.current.children.length || 0);
        setOptimalVideoDimention({ width, height });
      }else if(parentWidth <= 768 && videoContainer.current.children.length === 1){
        const { width, height } = getDimentions(parentDimentions, "9:16", videoContainer.current.children.length || 0);
        setOptimalVideoDimention({ width, height });
      } else{
        const { width, height } = getDimentions(parentDimentions, "1:1", videoContainer.current.children.length || 0);
        setOptimalVideoDimention({ width, height });
      }

    }
  }

  useEffect(() => {
    const observer = new ResizeObserver(() => { handleResize() });
    const attendeeObserver = new ResizeObserver(() => { handleResize() });
    observer.observe(parentContainer.current)
    attendeeObserver.observe(attendeeListContainer.current)
    if(parentWidth <= 768){
      observer.observe(videoContainer.current)
    }
    return () => {
      observer.disconnect();
      attendeeObserver.disconnect();
    };
  }, [parentWidth]);

  useEffect(() => {
    handleResize()
  }, [attendees, joined, primaryAttendeeList, secondaryAttendeeList, totalPages])

  useEffect(() => {
    let primaryList = [];
    let secondaryList = [];

    console.log("watch ===> attendees ", attendees)
    console.log("watch ===> agoraClient ", agoraClient)
    console.log(attendees,"see primary list")
    attendees.forEach((attendee) => {
      let user = attendee.user;
      let modifiedAttendee = attendee;
    
      let screenShareProps = {
        screenShare: true
      }
      if (channelStatus[CHANNEL] &&
        channelStatus[CHANNEL][user.uid] &&
        channelStatus[CHANNEL][user.uid]['isScreenShared']
      ) {
        if (user.uid === user_id || user.uid === userId) {
          let targetScreenShare = screenShareVideo.filter(u => u.uid === user_id || u.uid === userId);
          targetScreenShare = targetScreenShare[0];
          if (!user.screenTrack && targetScreenShare['screenTrack']) {
            screenShareProps['screenTrack'] = targetScreenShare['screenTrack'];
          }
          modifiedAttendee.metadata = { ...modifiedAttendee.metadata, ...screenShareProps }
          console.log(modifiedAttendee.user,"modifed user")
          primaryList.push(modifiedAttendee);
        } else {
          modifiedAttendee.metadata = { ...modifiedAttendee.metadata, ...screenShareProps }
          console.log("watch ===> modifiedAttendee ", modifiedAttendee)
          primaryList.push(modifiedAttendee);
        }
      } else {
        screenShareProps.screenShare = false;
        screenShareProps['screenTrack'] = null;
        console.log("watch ===> modifiedAttendee.metadata ", modifiedAttendee.metadata)
        modifiedAttendee.metadata = { ...modifiedAttendee.metadata, ...screenShareProps }
        secondaryList.push(attendee);
      }
    })

    if (primaryList.length === 0) {
      primaryList = [...secondaryList];
      secondaryList = [];
    }
    console.log(primaryList,"primary list codedddd")
    console.log(secondaryList,"secondary list")
   
    setPrimaryAttendeeList(()=>{
     return primaryList?.filter((list)=>{
       return  !list?.user?.uid.toString().includes('recording')
      })
    });
    setSecondaryAttendeeList(()=>{
     return secondaryList?.filter((list)=>{
        return  !list?.user?.uid.toString().toLowerCase().includes('recording')
       })
    });

    // check attendee status after 5mins
    setDebounceValidator();
  }, [channelStatus, attendees])

  return (
    <>
      {!showVCLeavePage && <Grid
        container
        className={classes.VideoCallContainer}
      >
        <Grid className={classes.CallHeader}>
          <Typography variant="h4" className={classes.userName}>
            {videoCallRequest?.groupName || groupName}
          </Typography>
          {openDialog
             ? 
            <StopRecordPopup
            openDialog = {openDialog}
            onStopRecording={stopRecording}
            videoCallStartState={videoCallStartState}
            /> : null
          }
         
        </Grid>
        <Grid
          className={`${classes.participantWrapper} ${secondaryAttendeeList?.length > 0 ? (unpinnedUserHide ? classes.listExpandHeight : classes.listCollapseHeight) : classes.fullHeight}`}
          ref={parentContainer}
        >
          <Grid
            item
            className={classes.VideoContainer}
            ref={videoContainer}
          >
            {true ? (
              <>
                {primaryAttendeeList?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)?.map((attendee, i) => (
                  
                  <VCParticipant
                    key={`${attendee.user.uid}_${i}`}
                    user={attendee.user}
                    userMetaData={attendee.metadata}
                    ref={(ref) => (videoElements[attendee.user.uid] = ref)}
                    isVideoMuted={attendee.user.videoMuted}
                    user_id={user_id || userId}
                    joined={true}
                    avatar={profile.avatar_filename}
                    mutedUsers={mutedUsers}
                    userAvatar={userAvatars && userAvatars[0] && userAvatars[0].id === attendee.user.uid ? userAvatars[0].avatar : ""}
                    allUsers={allUsers}
                    attendees={attendees}
                    profile={profile}
                    videoWidth={optimalVideoDimention.width}
                    videoHeight={optimalVideoDimention.height}
                    PinUser={PinUser}
                    audioMuted={attendee.user.audioMuted}
                    screenTrack={attendee.metadata['screenTrack']}
                    screenShare={attendee.metadata['screenShare']}
                    channelStatus={channelStatus}
                    talkingAttendees={talkingAttendees}
                    validationTime={validationTime}
                    channelName={CHANNEL}
                    resubscribe={resubscribe}
                    parentWidth={parentWidth}
                    volume={volume}
                  />
                ))}
              </>
            ) : null}{" "}
          </Grid>

          {/* <VCMenu
            handleVideoToggle={handleVideoToggle}
            isVideoMuted={isVideoMuted}
            handleLeave={handleLeave}
            handleAudioMute={handleAudioMute}
            handleScreenShare={handleScreenShare}
            audioMuted={audioMuted}
            user_id={userId}
            joined={joined}
            token={token}
            joinCall={joinCall}
            remoteUser={attendees?.users?.find((user) => user.uid === userId)}
            localUser={localUser}
            screenShare={screenShare}
            getAcquireFun={recordDialogHandler}
            stopRecording= {stopRecording}
            // isOpenRecordDialog={isOpenRecordDialog}
            openDialog={openDialog}
          /> */}
        </Grid>
        <Grid
          className={secondaryAttendeeList?.length > 0 && `${classes.attendeeListContainer} ${classes.attendeeListContainer}${!unpinnedUserHide ? "__hide" : "__open"}`}
          ref={attendeeListContainer}
        >
          {/* Bottom mini list when screen is shared or user is pinned */}
          {secondaryAttendeeList?.length > 0 && (
            <VCMiniParticipantList
              showChat={showChat}
              unpinnedUserHide={unpinnedUserHide}
              expandUsers={expandUsers}
              remoteAttendee={secondaryAttendeeList}
              unpinnedUsers={unpinnedUsers}
              videoElements={videoElements}
              joined={joined}
              profile={profile}
              mutedUsers={mutedUsers}
              allUsers={allUsers}
              PinUser={PinUser}
              UnPinUser={UnPinUser}
              user_id={userId}
              channelStatus={channelStatus}
              itemsPerPage={newItemsPerPage}
              channelName={CHANNEL}
              resubscribe={resubscribe}
              parentWidth={parentWidth}
              volume={volume}
            />
          )}
        </Grid>
        {renderPaginationButtonRight()}
        {renderPaginationButtonLeft()}
      </Grid>}

      {showVCLeavePage &&
        <VCLeavePage
          user_id={user_id}
          classes={classes}
          setShowVCLeave={setShowVCLeavePage}
          showVCLeave={showVCLeavePage}
          handleUserLeft={handleUserLeft}
          isVideoMuted={isVideoMuted}
          setIsVideoMuted={setIsVideoMuted}
          audioMuted={audioMuted}
          setAudioMuted={setAudioMuted}
          agoraClient={agoraClient}
          networkErrPage={networkErrPage}
          joinCall={joinCall}
          channel={CHANNEL}
          setNetworkErrorPage={setNetworkErrorPage}
          localVideoTracks={localVideoTracks}
          token={token}
        />}

            <VideoCallRecordingPopUp
                open={isOpenRecordDialog}
                handleStartAction={createRecordAcquire}
                handleCloseAction={()=>{
                  setOpenDialog(false)
                  setIsOpenRecordDialog(false)
                }}
            />
    </>
  );
}

export default withStyles(style)(RecordingRoom);