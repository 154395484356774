import React, { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import { useTheme, withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// import { Select as Core } from '@material-ui/core';
import { FormHelperText, Select as Core, Grid } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './styles'


/**
 * SwitchComponent
 * @param {*} props
 */

function SelectComponent(props) {
  const { variant, value, className, options, label,
    defaultVal, fullWidth, disabled, classes, svgIcon, validationMsg, showValidate, style, customStyles, popEvents, disableDefault, selectClass, placeholder = "", iconStyle, fontColor, defaultValue, maxHeight,MenuProps, ...rest } = props;

  const theme = useTheme();

  const [selectBoxWidth, setSelectBoxWidth] = useState(null)

  const selectBoxRef = useRef(null);


  /**
   * Handle
   * Selectbox paper width
   */
  useEffect(() => {
    if (selectBoxRef.current) {
      setSelectBoxWidth(selectBoxRef.current.clientWidth);
    }

    const handleResize = () => {
      if (selectBoxRef.current) {
        setSelectBoxWidth(selectBoxRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  /**
    * Handle
    * Select Field Events
    */
  const handleChange = (event) => {
    props.handleChange({ value: event.target.value !== "0" ? event.target.value : "" });
  };
  //.+Select.+components'
  let { selectBox, ...classWithoutSelectBox } = classes;

  const menuProps = {
    classes: {
      paper: classes.popverClass, // Add your custom class here
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        minWidth: selectBoxWidth, // Set the paper width
        maxHeight: maxHeight
      },
    },
  };

  const renderIcon = (Icon) => {
    return Icon ? <img className={classes.iconClass} src={Icon} /> : null
  }
  const renderSvgIcon = (Icon) => {
    return Icon ? Icon : null
  }


  const defaultStyles = {
    margin: "10px",
    fontWeight: '700',
    fontSize: '13px',
    borderRadius: "6px",
    justifyContent: 'center',
  }

  const CustomArrowIcon = () => (
    <ExpandMoreIcon style={{ marginRight: '6px' , color:"#d3d3d3" }} fontSize="small" /> // Customize size as needed
  );

  return (
    <FormControl
      variant={variant}
      className={`${className} ${classes.selectBox}`}
      classes={classWithoutSelectBox}
      error={showValidate}
      ref={selectBoxRef}
    >
      <Core
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        IconComponent={CustomArrowIcon}
        value={value}
        onChange={handleChange}
        label={label}
        disabled={disabled ? disabled : false}
        fullWidth={fullWidth}
        style={style}
        MenuProps={MenuProps ? MenuProps : menuProps}
        className={selectClass}
        displayEmpty={true}
        renderValue={
          (!defaultValue && value !== "") ? undefined : value == 0 ? () => <><div className={classes.placeholder}>{defaultValue}</div></> :
            () => <><div className={classes.placeholder}>{placeholder}</div></>
        }
        {...rest}

      >
        {defaultVal && <MenuItem value="0">{defaultVal}</MenuItem>}
        {options.map((item, keyIndex) => {
          return (
            <MenuItem
              className={`${item.bgColor === "#E5EAF1" && "todoColor"} ${classes.selectOption}`}
              disabled={item.comingSoon}
              style={(item.color || item.bgColor) ? {
                ...defaultStyles,
                backgroundColor: item.bgColor || theme.palette.containers.card,
                color: item.color || theme.palette.primary.main,
                ...(item?.styles || {})
              }
                : customStyles.color ? customStyles : {}
              }
              key={keyIndex}
              value={item.value}
            >
              <Grid className={`dflex alignCenter menuText ${fontColor}`} >
             {/* <Grid className={`dflex alignCenter menuText ${fontColor ? fontColor : classes.fontColor}`} > */}
                <Grid component={"span"}>
                  {
                    !svgIcon ? (item?.icon && renderIcon(item?.icon)) : (item?.icon && renderSvgIcon(item?.icon))
                  }
                </Grid>
                {item.label}
                {/* <Typography variant="body1" color="primary" className="pl5">{item.label}</Typography> */}
              </Grid>
            </MenuItem>
          )
        }
        )}
      </Core>
      {validationMsg && <FormHelperText>{validationMsg}</FormHelperText>}
    </FormControl>
  );
}

// default props
SelectComponent.defaultProps = {
  variant: "outlined",
  value: "0",
  label: "",
  margin: "dense",
  className: "",
  classes: {},
  options: [],
  fullWidth: false,
  customStyles: {},
  handleChange: () => { },
  disabled: false,
  showValidate: false,
  validationMsg: "",
  style: {},
  disableDefault: false,
  svgIcon: false,
  defaultVal: null,
};

// prop types
SelectComponent.propTypes = {
  variant: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.any,
  classes: PropTypes.object,
  customStyles: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  defaultVal: PropTypes.string,
  disableDefault: PropTypes.bool,
  maxHeight: PropTypes.string
};

export const Select = withStyles(styles)(SelectComponent);
