import React, { useState, useEffect, useRef, useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import PropTypes from "prop-types";
import { EditorState, RichUtils, convertToRaw, getDefaultKeyBinding, KeyBindingUtil, ContentState } from 'draft-js';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter, defaultTheme as mentionDefaultTheme } from '@draft-js-plugins/mention';
import createLinkifyPlugin, { extractLinks } from '@draft-js-plugins/linkify';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import createHashtagPlugin, { extractHashtagsWithIndices } from '@draft-js-plugins/hashtag';
import { Grid, IconButton, withStyles, Popover, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { useLocation } from "react-router-dom";

import '../../../node_modules/@draft-js-plugins/mention/lib/plugin.css'
import useCapacitorKeyboard from "../../hooks/keyboard_listener";


// Imports Styles
import styles from "./styles";
import "./tenor.css";

//Icons
import { InfinityLogo, MobileSend, InputPlusIcon, ChatSendIconNew, ChatGifNew, ChatAttachmentIcon, ChatEmojiIcon, ChatMicIcon, BlueRecordIcon, CloseIcon } from '../../assets/svg'

//Import Components
import EntryComponent from "./plugins/EntryComponent";
import GiphyComponent from "../GiphyComponent";
import { InfoDialogComponent } from "../InfoDialogComponent";
import { AudioRecorder } from "../AudioRecorder";
import { MobileAudioRecorder } from '../MobileComponents/MobileAudioRecorder';
import { OutsideClickHandler } from '../../hoc';
import { getDraftMessages, isFlozyMobileApp, setDraftMessage } from '../../utils';
import { commonAction } from '../../redux/actions/commonAction';
import { Skeleton } from '@material-ui/lab';
import Tribute from "tributejs";

const emojiTheme = {
  ...defaultTheme,
  emoji: 'chatEmoji',
  emojiSuggestions: `${defaultTheme.emojiSuggestions} chatEmojiSuggestions`,
  emojiSuggestionsEntry: `${defaultTheme.emojiSuggestionsEntry} chatEmojiSuggestionsEntry`,
  emojiSelect: `${defaultTheme.emojiSelect} chatEmojiSelect ml-1 floatOption`,
  emojiSelectButton: `${defaultTheme.emojiSelectButton} chatEmojiSelectButton MuiButtonBase-root MuiIconButton-root`,
  emojiSelectButtonPressed: `${defaultTheme.emojiSelectButtonPressed} chatEmojiSelectButtonPressed MuiButtonBase-root MuiIconButton-root`,
  emojiSelectPopover: `${defaultTheme.emojiSelectPopover} chatEmojiSelectPopover`,
  emojiSelectPopoverClosed: `${defaultTheme.emojiSelectPopoverClosed} chatEmojiSelectPopoverClosed`,
}

const mentionTheme = {
  ...mentionDefaultTheme,
  mention: 'chatMention',
  mentionSuggestions: 'chatMentionsSuggestions',
  mentionSuggestionsEntry: 'chatMentionSuggestionsEntry',
  mentionSuggestionsEntryFocused: 'chatMentionSuggestionsEntryFocused',
  mentionSuggestionsEntryText: 'chatMentionSuggestionsEntryText',
  mentionSuggestionsEntryAvatar: 'chatMentionSuggestionsEntryAvatar',
  mentionSuggestionsEntryContainer: 'chatMentionSuggestionsEntryContainer',
  mentionSuggestionsEntryContainerLeft: 'chatMentionSuggestionsEntryContainerLeft',
  mentionSuggestionsEntryContainerRight: 'chatMentionSuggestionsEntryContainerRight'
}

const emojiPlugin = createEmojiPlugin({
  // imagePath: 'http://cdn.jsdelivr.net/emojione/assets/svg/',
  useNativeArt: true,
  theme: emojiTheme,
  selectButtonContent: <div id="myChickooo"><ChatEmojiIcon /></div>,
  selectPopperOptions: {
    placement: 'top-end',
    modifiers: [{ name: 'preventOverflow', enabled: false }]
  }
});

const { EmojiSelect } = emojiPlugin;

const hashtagPlugin = createHashtagPlugin({
  theme: {
    hashtag: 'chatHashTag'
  }
});

/**
 * TextField Component
 * @param {*} props
 */
const TextEditor = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { id, type, classes, handleChatSend, handleAttachment, isMediaSent, handleChatGif, userSuggestions, handleTyping, handleVoiceNoteMessage, placeHolder, infinityModeHandler, inputfocus, typingUsers, userId, unsendMessages, updateDraftMessages, handleChatEditMessage, actionIcons, editChatData, backgroundColor, handleChatChange } = props;
  const notesContent = getInitial();
  const [editorState, setEditorState] = useState(notesContent);
  const [open, setOpen] = useState(false);
  const [gifOpen, setGifOpen] = useState(null);
  const [suggestions, setSuggestions] = useState(userSuggestions);
  const [allMentions, setAllMentions] = useState([]);
  const [tinyUrl, setTinyUrl] = useState(null);
  const [tinySelectedUrl, setTinySelectedUrl] = useState(null)
  const [tinyUrlMeta, setTinyUrlMeta] = useState([]);
  const [tinyFetchedUrls, setTinyFetchedUrls] = useState([]);
  const [chatInputText, setChatInputText] = useState("");
  const [chatHashTags, setChatHashTags] = useState([]);
  const [chatRawContent, setChatRawContent] = useState(convertToRaw(
    notesContent.getCurrentContent()
  ));
  const [recordingStatus, setRecordingStatus] = useState(false)
  const [audioPermission, setAudioPermission] = useState(false);
  const [audioDevice, setAudioDevice] = useState(false);
  const [audioDeviceWaring, setAudioDeviceWarning] = useState(false);
  const { channel } = useSelector(s => s.chatv2)
  const { profile } = useSelector(s => s.profile)
  const [unsend_messages, setunsend_messages] = useState(unsendMessages)
  const activeHubData = (channel[type] || []).find(f => f.id == id)
  const [infinityButtonOn, setInfinityButtonOn] = useState(false)
  const location = useLocation();
  const editorRef = useRef(null);
  const [linkPoperLoading, setLinkPoperLoading] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const { planDetails } = useSelector(s => s.userAgency)

  const isKeyboardVisible = useCapacitorKeyboard();

  const linkifyPlugin = createLinkifyPlugin({
    target: '_blank',
    component: (props) => {
      return <a {...props} onClick={() => window.open(props.href, '_blank')} />
    },
  });
  useImperativeHandle(ref, () => ({

    // Expose parent function to parent component
    onThumbnailClick: (message_text) => {
      let newState = createEditorStateWithText(message_text ? message_text?.trim() : '');
      setEditorState(newState);
    },
    clearEditorState: (msgId) => {
      setDraftMessage(msgId, "");
      setEditorState(createEditorStateWithText(""));
    }
  }));
  const pasteListenerAdded = useRef(false);
  useEffect(() => {
    const handlePaste = async (event) => {
      const items = event.clipboardData.items;
      let isTextPasted = false;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.kind === 'file' && item.type.includes('image')) {
          const blob = item.getAsFile();
          const url = URL.createObjectURL(blob);
          handleAttachment({ isPaste: true, blob: blob, url: url });
        }
      }
      if (isMobile) {
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        const plainText = clipboardData.getData('text/plain');
        const editor = document.getElementById(`chat-msg-editor_${actionIcons.length}`);
        if (editor) {
          const selection = window.getSelection();
          if (!selection.rangeCount) return;
          const range = selection.getRangeAt(0);
          range.deleteContents();
          const textNode = document.createTextNode(plainText);
          range.insertNode(textNode);
          range.setStartAfter(textNode);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
          const eventInput = new Event('input', { bubbles: true });
          editor.dispatchEvent(eventInput);
        }
        isTextPasted = true;
      }
    };

    if (!pasteListenerAdded.current) {
      window.addEventListener('paste', handlePaste);
      pasteListenerAdded.current = true;
    }

    return () => {
      if (pasteListenerAdded.current) {
        window.removeEventListener('paste', handlePaste);
        pasteListenerAdded.current = false;
      }
    };
  }, [handleAttachment]);

  // const pasteListenerAdded = useRef(false);
  // useEffect(() => {
  //   const handlePaste = async (event) => {
  //     const items = event.clipboardData.items;
  //     for (let i = 0; i < items.length; i++) {
  //       const item = items[i];
  //       if (item.kind === 'file' && item.type.includes('image')) {
  //         const blob = item.getAsFile();
  //         const url = URL.createObjectURL(blob);
  //         handleAttachment({ isPaste: true, blob: blob, url: url });
  //       }
  //     }
  //   };

  //   if (!pasteListenerAdded.current) {
  //     window.addEventListener('paste', handlePaste);
  //     pasteListenerAdded.current = true;
  //   }

  //   return () => {
  //     if (pasteListenerAdded.current) {
  //       window.removeEventListener('paste', handlePaste);
  //       pasteListenerAdded.current = false;
  //     }
  //   };
  // }, [handleAttachment]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      theme: mentionTheme,
      supportWhitespace: true,
      mentionPrefix: '@',
      entityMutability: 'IMMUTABLE',
      positionSuggestions: (settings) => {
        return {
          left: settings.decoratorRect.left + 'px',
          top: settings.decoratorRect.top + 'px', // change this value (40) for manage the distance between cursor and bottom edge of popover
          display: 'block',
          transform: 'scale(1) translateY(-100%)', // transition popover on the value of its height
          transformOrigin: '1em 0% 0px',
          transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)',
          position: 'fixed',
          maxWidth: 'max-content',
          minWidth: 200
        };
      },
      mentionComponent: (mentionProps) => {
        return (
          <span
            className={mentionProps.className}
          >
            {mentionProps.children}
          </span>
        );
      },
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);


  useEffect(() => {
    // Deactivates infiniti mode when user is in Infiniti chat room.
    if (activeHubData?.isInfiniti === 1) {
      setInfinityButtonOn(false)
      infinityModeHandler(true)
    }
  }, [activeHubData?.isInfiniti])

  useEffect(() => {
    if (userSuggestions && userSuggestions.length > 0 && suggestions !== userSuggestions) {
      setSuggestions(userSuggestions)
    } else {
      setSuggestions([])
    }
  }, [userSuggestions])

  useEffect(() => {
    if (chatInputText && chatInputText.length > 0) {
      let urls = extractLinks(chatInputText)
      let hashTags = extractHashtagsWithIndices(chatInputText);
      if (urls && tinyUrl !== urls) {
        setTinyUrl([...urls])
      }
      else {
        setTinyUrl(null);
      }

      if (hashTags !== chatHashTags) {
        setChatHashTags(hashTags)
      }
    } else {
      setTinyUrl(null);
      setTinyFetchedUrls([])
      setTinyUrlMeta([]);
    }
  }, [chatInputText])

  useEffect(() => {
    if (tinyUrl && tinyUrl.length > 0) {
      let url = tinyUrl.filter((val) => val.schema === "https:");
      if (url.length > 0) {

        let urlSelect = url[0]["url"];
        if (urlSelect !== tinySelectedUrl) {
          setTinySelectedUrl(urlSelect)
        }
        const val = url[0];
        if (!tinyFetchedUrls.includes(val.url)) {
          setLinkPoperLoading(true);
          getMetadataInfo({ url: val?.url?.trim() })
        }
      }
    } else {
      setLinkPoperLoading(false);
      setTinySelectedUrl(null);
      setTinyUrlMeta([]);
      setTinyUrl(null);
    }
  }, [tinyUrl])


  function getInitial() {
    if (id) {
      const draftMessages = getDraftMessages();
      if (draftMessages[id] && actionIcons.includes('send')) {
        let newState = createEditorStateWithText(draftMessages[id].trim());
        return newState
      } else if (editChatData?.message_text) {
        let newState = createEditorStateWithText(editChatData?.message_text);
        return newState
      }
      else if (draftMessages[id]) {
        let newState = createEditorStateWithText(draftMessages[id].trim());
        return newState
      }
      else {
        return (createEditorStateWithText(""))
      }
    } else {
      return createEditorStateWithText("")
    }
  }

  useEffect(() => {
    const newState = getInitial();
    const draftMessages = getDraftMessages();
    if (draftMessages[id] && actionIcons.includes('send') && isMobile) {
      let draftTxt = draftMessages[id].trim()
        const plainText = draftTxt
        const editor = document.getElementById(`chat-msg-editor_${actionIcons.length}`);
        if (editor) {
          editor.innerText = plainText
        }
    }
    setEditorState(newState);
    setChatRawContent(convertToRaw(newState.getCurrentContent()));
  }, [location, id])

  useEffect(() => {
    let chatMsgText = editorState?.getCurrentContent().getPlainText()
    setChatInputText(chatMsgText);
  }, [editorState])

  async function getMedia() {
    try {
      const audioMedia = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      if (isFlozyMobileApp()) {
        setRecordingStatus(true);
      } else {
        if (audioMedia.active && audioMedia.id) {
          setAudioDevice(true)
          setAudioPermission(true)
          setRecordingStatus(true);
        } else {
          setAudioDevice(true)
          setAudioPermission(false)
        }
      }
    } catch (err) {
      setAudioDevice(false)
      setAudioDeviceWarning(true)
    }
  }

  const handleClose = () => {
    setGifOpen(null)
  };

  const getMetadataInfo = (params) => {
    dispatch(commonAction.getUrlMetadata(params, (resp) => {
      if (chatInputText) {
        setTinyUrlMeta([resp]);
      }
      setLinkPoperLoading(false);
    }, () => {
      setTinyUrlMeta([]);
      setLinkPoperLoading(false);
    }))
  }

  const onChangeMobile = async () => {
    let processedData = getMobileChatText();

    if (!typingUsers?.[`${type}_${id}_${userId}`] && processedData[0]?.length >= 1 && !infinityButtonOn) {
      handleTyping(true)
    }

    if (typingTimeout) clearTimeout(typingTimeout)
    setTypingTimeout(setTimeout(() => {
      handleTyping(false)
    }, 3000))
  }

  const onChange = async (newEditorState, is_edit = false) => {
    let rawContent = await convertToRaw(newEditorState.getCurrentContent());
    const blockContent = rawContent.blocks;
    let chatMsgText = await blockContent.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    const currentContentTextLength = editorState?.getCurrentContent().getPlainText().length;
    let newContentTextLength = newEditorState.getCurrentContent().getPlainText().length;
    let unsend_Messages = {}
    if (chatMsgText !== '' && newEditorState._immutable._map.size > 10) {
      unsend_Messages[`${id}`] = chatMsgText
      let unSendMessages = { ...unsend_messages, ...unsend_Messages }
      setunsend_messages(unSendMessages)

      if (unSendMessages !== undefined) {
        Object.entries(unSendMessages).map((ele) => {
          if (ele[1] == "\n" && ele[0]) {
            delete unSendMessages[`${ele[0]}`];
          }
        })
      }
    }
    if (((currentContentTextLength > 0 || newContentTextLength > 0) && newEditorState._immutable._map.size == 10 && unsendMessages && Object.entries(unsendMessages).length == 0 && currentContentTextLength === 0) || (currentContentTextLength === 0 && newContentTextLength !== 0)) {
      setEditorState(EditorState.moveFocusToEnd(newEditorState));
    } else {
      if (currentContentTextLength > 0 || chatMsgText == '@' || (chatMsgText == '\n' && !unsendMessages[`${id}`])) {
        setEditorState(newEditorState);
      }
    }
    if (!typingUsers?.[`${type}_${id}_${userId}`] && newContentTextLength >= 1 && !infinityButtonOn) {
      handleTyping(true)
    }
    // Below implementation works as a debounce
    // Always triggers settimeout to set typing to become false but if user types it will again move the timeout 4seconds by deleting old timeout and creating a new one.
    if (typingTimeout) clearTimeout(typingTimeout)
    setTypingTimeout(setTimeout(() => {
      handleTyping(false)
    }, 3000))
    if (currentContentTextLength >= 1 && !is_edit && actionIcons.includes('send')) {
      localStorage.setItem("id", `${id}_${type}`)
      setInputFocus(true);
    }
    else {
      setInputFocus(false);
    }

    // handleChatText(chatMsgText);
    setChatRawContent(rawContent)
    setChatInputText(chatMsgText);
    if (!actionIcons.includes('send')) {
      const messageUrls = tinyUrl && tinyUrl.length > 0 ? tinyUrl.map((val) => { return val.url }) : [];
      const urlMetaData = tinyUrlMeta && tinyUrlMeta.length > 0 ? tinyUrlMeta.filter(val => val && val.hasOwnProperty("url") && messageUrls.includes(val.url)) : []
      let dataObj = {
        chatText: chatMsgText,
        chatRawContent: chatRawContent,
        hashtags: chatHashTags,
        mentions: allMentions,
        links: {
          url: tinyUrl,
          urlData: urlMetaData
        }
      }
      handleChatChange(dataObj)
    }
  };

  const keyBindingFn = (e) => {
    e.stopPropagation();
    if (KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 66) { return 'bBold'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 85) { return 'uLine'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.keyCode === 73) { return 'iItalic'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 55) { return 'oLIST'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 56) { return 'uLIST'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 57) { return 'cBLOCK'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 48) { return 'bQUOTE'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 49) { return 'hONE'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 50) { return 'hTWO'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 51) { return 'hTHREE'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 52) { return 'hFOUR'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 53) { return 'hFIVE'; }
    else if (KeyBindingUtil.hasCommandModifier(e) && e.shiftKey && e.keyCode === 54) { return 'hSIX'; }
    else if (e.keyCode === 13) {
      if (e.nativeEvent.shiftKey) {
        return "shift-enter"
      } else {
        if (isKeyboardVisible || isMobile) {
          return "split-block"
        }
        return "handle-submit"
      }
    }

    return getDefaultKeyBinding(e);
  }

  const handleKeyCommand = (command) => {
    let newState;
    if (command === 'bBold') {
      newState = RichUtils.toggleInlineStyle(editorState, 'BOLD');
    }
    else if (command === 'uLine') {
      newState = RichUtils.toggleInlineStyle(editorState, 'UNDERLINE');
    }
    else if (command === 'iItalic') {
      newState = RichUtils.toggleInlineStyle(editorState, 'ITALIC');
    }
    else if (command === 'oLIST') {
      newState = RichUtils.toggleBlockType(editorState, 'ordered-list-item');
    }
    else if (command === 'uLIST') {
      newState = RichUtils.toggleBlockType(editorState, 'unordered-list-item');
    }
    else if (command === 'cBLOCK') {
      newState = RichUtils.toggleBlockType(editorState, 'code-block');
    }
    else if (command === 'bQUOTE') {
      newState = RichUtils.toggleBlockType(editorState, 'blockquote');
    }
    else if (command === 'hONE') {
      newState = RichUtils.toggleBlockType(editorState, 'header-one');
    }
    else if (command === 'hTWO') {
      newState = RichUtils.toggleBlockType(editorState, 'header-two');
    }
    else if (command === 'hTHREE') {
      newState = RichUtils.toggleBlockType(editorState, 'header-three');
    }
    else if (command === 'hFOUR') {
      newState = RichUtils.toggleBlockType(editorState, 'header-four');
    }
    else if (command === 'hFIVE') {
      newState = RichUtils.toggleBlockType(editorState, 'header-five');
    }
    else if (command === 'hSIX') {
      newState = RichUtils.toggleBlockType(editorState, 'header-six');
    }
    else if (command === 'shift-enter') {
      newState = RichUtils.insertSoftNewline(editorState);
    } else if (command === "handle-submit") {
      if (editChatData?.message_text) {
        handleEditChat()
      } else {
        localStorage.setItem("id", "")
        if (!actionIcons.includes('send')) {
          handleChatSend();
        } else {
          handleChatSubmit();
        }
      }
    } else if (open && command === 'split-block') {
      localStorage.setItem("id", "")
      handleChatSubmit();
    }

    if (newState) {
      onChange(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ value }) => {
    setSuggestions(defaultSuggestionsFilter(value, userSuggestions));
  }, [userSuggestions]);

  const onAddMention = (val) => {
    setAllMentions([...allMentions, val])
  };

  // Edit message
  const handleEditChat = async () => {
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
    const chatMsgBlocks = blocks.map(block => (!block.text.trim() && '\n') || block.text);
    let chatMsgText = chatMsgBlocks.length > 0 && chatMsgBlocks[0] !== "\n" ? chatMsgBlocks.join('\n') : "";
    let chatRawContentCopy = chatRawContent
    const urlMetaData = tinyUrlMeta && tinyUrlMeta.length > 0 ? tinyUrlMeta.filter(val => val && val.hasOwnProperty("url")) : []

    if (chatMsgText && chatMsgText.length > 0) {
      let dataObj = {
        message_id: editChatData?.id,
        chatText: chatMsgText,
        chatRawContent: chatRawContentCopy,
        hashtags: chatHashTags,
        mentions: allMentions,
        links: {
          url: tinyUrl,
          urlData: urlMetaData
          // urlData: tinyUrlMeta
        }
      }

      setEditorState(createEditorStateWithText(""));
      setDraftMessage(id, "");
      setTinySelectedUrl(null);
      setTinyUrlMeta([]);
      setTinyFetchedUrls([]);
      dataObj["isEdited"] = false
      if (editChatData.message_text != chatMsgText) {
        dataObj["isEdited"] = true
      }
      handleChatEditMessage(dataObj)
      handleTyping(false)

    }
    else
      return false;
  }

  const getEntityRanges = (string, array) => {
    const matches = [];
    const map = {};
    for (let i = 0; i < array.length; i++) {
      const prefix = '@';
      const entry = array[i].name;
      const index = string.indexOf(prefix + entry);

      if (index !== -1) {
        matches.push({
          offset: index,
          length: entry.length + 1,
          key: i
        });
        map[`${i}` + ''] = {
          type: 'mention',
          mutability: 'SEGMENTED',
          data: {
            mention: {
              ...array[i]
            }
          }
        }
      }
    }

    return [matches, map];
  }

  const getMobileChatText = () => {
    const elementHTML = document.getElementById(`chat-msg-editor_${actionIcons.length}`).innerText.trim();
    const contentState = ContentState.createFromText(elementHTML);
    const editorState = EditorState.createWithContent(contentState);
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const chatMsgText = contentState.getPlainText();
    if (chatMsgText.trim().length === 0) {
      return undefined
    }
    return [removeTrailingNbsp(chatMsgText), rawContentState];
  }

  const removeTrailingNbsp = (str) => {
    return str.replace(/&nbsp;/g, ' ');
  }

  const inputted = (evt) => {
    const divElement = document.getElementById(`chat-msg-editor_${actionIcons.length}`)
    const content = divElement.textContent.trim();
    setChatInputText(content);
    if (!actionIcons.includes('send')) {
      let processedData = getMobileChatText();
      const urlMetaData = tinyUrlMeta && tinyUrlMeta.length > 0 ? tinyUrlMeta.filter(val => val && val.hasOwnProperty("url")) : []
      let dataObj = {
        chatText: processedData[0],
        chatRawContent: processedData[1],
        hashtags: chatHashTags,
        mentions: allMentions,
        links: {
          url: tinyUrl,
          urlData: urlMetaData
          // urlData: tinyUrlMeta
        }
      }
      handleChatChange(dataObj)
    }
    if (content === '') {
      divElement.style.lineHeight = 'unset';
      divElement.setAttribute('data-placeholder', 'Start typing...');
    } else {
      divElement.removeAttribute('data-placeholder');
    }
  }

  useEffect(() => {
    if (isMobile) {
      setUpTribute();
    }
  }, [suggestions])
  useEffect(() => {
    if (isMediaSent) {
      const newState = createEditorStateWithText("")
      setEditorState(newState);
    }
  }, [isMediaSent]);

  const setUpTribute = () => {
    const divElement = document.getElementById(`chat-msg-editor_${actionIcons.length}`)
    if (!divElement ||
      userSuggestions === undefined ||
      userSuggestions === null ||
      userSuggestions.length === 0) {
      return;
    }
    const tribute = new Tribute({
      values: userSuggestions || [],
      allowSpaces: true,
      lookup: 'name',
      fillAttr: 'name',
      requireLeadingSpace: true
    })
    tribute.attach(divElement);
    divElement.addEventListener("tribute-replaced", function (e) {
      if (e.detail.item.string && e.detail.item.string.length > 1) {
        onAddMention(e.detail.item.original);
      }
    });
  }

  useEffect(() => {
    const divElement = document.getElementById(`chat-msg-editor_${actionIcons.length}`)
    if (!divElement) {
      return;
    }
    setUpTribute();
    divElement.addEventListener('input', inputted);
    divElement.addEventListener('focus', () => {
      divElement.classList.add('focused');
      if (divElement.textContent === null || divElement.textContent === undefined) {
        divElement.style.lineHeight = 'unset';
      }
    });
    divElement.addEventListener('focusout', (event) => {
      if (event?.relatedTarget?.id === 'submit-chat-button') {
        divElement.focus();
        event.relatedTarget.blur();
      }
    });
    divElement.addEventListener('blur', (event) => {
      if (event?.relatedTarget?.id === 'mobile-send-button') {
        divElement.focus();
      }
      divElement.classList.remove('focused');
      divElement.style.lineHeight = 'unset';
    });
    return () => {
      divElement.removeEventListener('input', inputted);
    }
  }, [actionIcons]);

  const handleChatSubmit = async (get_welcome_message = false) => {
    let chatMsgText = undefined;
    let rawContent_input = chatRawContent;
    rawContent_input = JSON.parse(JSON.stringify(rawContent_input));
    rawContent_input.blocks = rawContent_input.blocks.map(block => {
      return {
        ...block,
        text: block.text.trim()
      };
    });
    if (isMobile) {
      let processedData = getMobileChatText();
      if (processedData === undefined) return;
      chatMsgText = processedData[0];
      rawContent_input = processedData[1];
      document.getElementById(`chat-msg-editor_${actionIcons.length}`).innerText = ''
    } else {
      const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
      const chatMsgBlocks = blocks.map(block => (!block.text.trim() && '\n') || block.text);
      chatMsgText = chatMsgBlocks.length > 0 && chatMsgBlocks[0] !== "\n" ? chatMsgBlocks.join('\n') : "";
    }
    const urlMetaData = tinyUrlMeta && tinyUrlMeta.length > 0 ? tinyUrlMeta.filter(val => val && val.hasOwnProperty("url")) : []

    if ((chatMsgText && chatMsgText.length > 0) || get_welcome_message) {
      let obj = JSON.parse(JSON.stringify(rawContent_input));
      let ranges = getEntityRanges(chatMsgText, allMentions)
      obj["blocks"][0]["entityRanges"] = ranges[0];
      obj["entityMap"] = ranges[1]

      let is_infiniti = 0
      if (activeHubData?.isInfiniti == 1 || get_welcome_message) {
        is_infiniti = 1
      }

      let dataObj = {
        chatText: chatMsgText,
        chatRawContent: obj,
        hashtags: chatHashTags,
        mentions: allMentions,
        links: {
          url: tinyUrl,
          urlData: urlMetaData
          // urlData: tinyUrlMeta
        }
      }

      if (get_welcome_message) {
        dataObj['get_welcome_message'] = get_welcome_message
      }

      setEditorState(createEditorStateWithText(""));
      setDraftMessage(id, "");
      setTinySelectedUrl(null);
      setTinyUrlMeta([]);
      setTinyFetchedUrls([]);
      handleChatSend(dataObj, is_infiniti)
      let sendMessages = {}
      sendMessages[`${id}`] = ''
      let messages = unsendMessages
      delete messages[`${id}`];
      setunsend_messages(messages)
      updateDraftMessages(messages)
      handleTyping(false)
    }
    else
      return false;
  }

  const onGifSelect = (data) => {
    if (isMobile) {
      setTimeout(() => {
        handleChatGif(data)
        setGifOpen(null)
      }, [1000])
    } else {
      handleChatGif(data)
      setGifOpen(null)
    }
  }

  const [inputFocus, setInputFocus] = useState(false);

  const stopAudioRecording = () => {
    setRecordingStatus(false);
  }

  const sendVoiceNote = (data) => {
    handleVoiceNoteMessage(data)
    setRecordingStatus(false);
  }

  const styleMap = {
    CODE: {
      backgroundColor: '#111',
      color: '#fff',
      fontSize: '1.5rem',
      padding: '2px',
    },
  };

  const editorPlugins = [linkifyPlugin, emojiPlugin, ...plugins, hashtagPlugin]


  const [openMore, setOpenMore] = useState(false);

  const infinityButtonHandler = () => {
    setInfinityButtonOn(!infinityButtonOn)
    infinityModeHandler(infinityButtonOn)
  }

  useEffect(() => {
    if (infinityButtonOn && profile.show_ai_welcome_msg) {
      // To trigger a welcome message
      handleChatSubmit(true);
    }
  }, [infinityButtonOn])

  useEffect(() => {
    if (!isMobile) {
      editorRef?.current?.focus();
    }
  }, [inputfocus])

  const getLinkPoper = () => {
    if (tinyUrlMeta?.length > 0 && chatInputText.trim().length > 0) {
      return (
        <div className={classes.linkPoper}>
          <Grid container alignItems='center'>
            <Grid item xs={11} style={{ background: '#E4EAF2' }}>
              <Grid container className='poper-card'>
                <Grid item xs={4} md={2} className='dflex alignCenter'>
                  <img src={tinyUrlMeta[0]?.thumbnail_url || tinyUrlMeta[0]?.icon} alt="" />
                </Grid>
                <Grid item xs={8} md={10} className='pl-1 pr-1'>
                  <Typography variant='h6' className={tinyUrlMeta[0]?.description ? 'twoLineEllip' : 'threeLineEllip'}>{tinyUrlMeta[0]?.title || tinyUrlMeta[0]?.meta?.title}</Typography>
                  <Typography variant='body2' className='twoLineEllip'>{tinyUrlMeta[0]?.description || tinyUrlMeta[0]?.meta?.description}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={1} className='closeContainer'>
              <IconButton
                onClick={() => {
                  setLinkPoperLoading(false);
                  setTinySelectedUrl(null);
                  setTinyUrlMeta([]);
                  setTinyUrl(null);
                }}
                size='small'
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      )
    } else {
      if (linkPoperLoading && chatInputText.trim().length > 0) {
        return (
          <div className={classes.linkPoper}>
            <Grid container>
              <Grid item xs={5} md={3}>
                <Skeleton variant="rectangular" width={124} height={88} />
              </Grid>
              <Grid item xs={7} md={9}>
                <Grid container>
                  <Grid item xs={12}>
                    <Skeleton variant="text" style={{ fontSize: '1.5rem' }} />
                    <Skeleton variant="text" style={{ fontSize: '.75rem' }} />
                    <Skeleton variant="text" style={{ fontSize: '.75rem' }} />
                    <Skeleton variant="text" style={{ fontSize: '.75rem' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )
      }
    }
    return null
  }

  return (
    <>
      {
        actionIcons.includes('send') && getLinkPoper()
      }
      <Grid className={`chatInputBox ${classes.chatInputBox} ${isAndroid && !actionIcons.includes('send') ? 'p0' : ''}`} ref={ref}>
        <Grid container alignItems="center" className={`${classes.textOverallCon} textOverallCon`} style={{ background: backgroundColor }}>
          {
            actionIcons.includes('plus') &&
            <IconButton onClick={handleAttachment} className="mShow"><InputPlusIcon /></IconButton>
          }
          {
            !isMobile ?
              <Editor
                customStyleMap={styleMap}
                editorState={editorState}
                keyBindingFn={!open && keyBindingFn}
                handleKeyCommand={handleKeyCommand}
                onBlur={() => {
                  handleTyping(false);
                  if (Object.keys(editChatData).length === 0) {
                    setDraftMessage(id, editorState?.getCurrentContent().getPlainText());
                  }
                }}
                onChange={onChange}
                placeholder={placeHolder}
                spellCheck={true}
                plugins={[...editorPlugins]}
                ref={editorRef}
              />
              :
              <React.Fragment key={"mobile-wrapper"}><div id={`chat-msg-editor_${actionIcons.length}`}
                onInput={onChangeMobile}
                onBlur={() => {
                  handleTyping(false);
                  if (Object.keys(editChatData).length === 0) {
                    let processedData = getMobileChatText();
                    processedData = processedData?.[0] || ""
                    setDraftMessage(id, processedData);
                  }
                }}
                style={{
                  display: 'flex',
                  padding: '10px',
                  flexGrow: 1,
                  minHeight: '42px',
                  marginBottom: 0,
                  marginTop: 0,
                  outline: 'none',
                  flexDirection: 'column',
                  overflowWrap: 'anywhere',
                  maxHeight: '120px',
                  overflow: 'auto',
                  lineClamp: 5,
                  wordWrap: 'break-word',
                  wordBreak: 'break-word',
                  maxWidth: 'calc(100vw - 151px)',
                  pointerEvents: recordingStatus ? 'none' : 'unset',
                  fontSize: '0.875rem'
                }} contenteditable="true" data-placeholder='Start typing...'
              ></div>
              </React.Fragment>
          }
          {isMobile && actionIcons.includes('gif') && chatInputText?.trim()?.length === 0 &&
            <IconButton
              onClick={(event) => {
                if (gifOpen) {
                  setGifOpen(null);
                } else {
                  setGifOpen(event.currentTarget);
                }
              }}
            >
              <ChatGifNew />
            </IconButton>
          }
        </Grid>
        <MentionSuggestions
          className={classes.chatMentions}
          open={open}
          suggestions={suggestions}
          onOpenChange={onOpenChange}
          onSearchChange={onSearchChange}
          onAddMention={onAddMention}
          entryComponent={EntryComponent}
        />
      </Grid>
      {openMore && <Grid className={`${classes.chatTypAttach} chatTypAttach`}>
      </Grid>
      }

      <Grid className={`${classes.chatTypAttach} chatTypAttach dFlexShow`}>
        {(planDetails?.plan_code?.includes("educate") || planDetails?.plan_code === 'pro_plan_v2' || planDetails?.plan_code === 'pro_plan') && type !== "teams" && type !== "teams" && Object.keys(editChatData)?.length === 0 &&
          activeHubData?.isInfiniti != 1 && actionIcons.includes('infinity') && <IconButton onClick={infinityButtonHandler} className={`floatOption ${classes.infinityLogo} ${infinityButtonOn && 'active'}`}><InfinityLogo /></IconButton>
        }
        {
          actionIcons.includes('plus') &&
          <OutsideClickHandler onOutsideClick={() => handleAttachment(false)}>
            <IconButton onClick={handleAttachment} className="floatOption ml-1 p0"><ChatAttachmentIcon /></IconButton>
          </OutsideClickHandler>
        }
        {
          actionIcons.includes('audio') &&
          <IconButton onClick={() => getMedia()} className="floatOption ml-1 p0">
            <ChatMicIcon />
            {recordingStatus && <span className={classes.recording}></span>}
          </IconButton>
        }
        {
          actionIcons.includes('emoji') &&
          <EmojiSelect className="emojiIcon floatOption ml-1" />
        }
        {/* Gif Container Start */}
        {
          actionIcons.includes('gif') &&
          <>
            <IconButton onClick={(event) => setGifOpen(event.currentTarget)} className="floatOption gif ml-1 p0"><ChatGifNew /></IconButton>
            <Grid>
              <Popover
                id={"gif"}
                anchorEl={gifOpen}
                className={`${classes.popoverIcon} ${isKeyboardVisible ? 'bottomWithKeyboard' : ''}`}
                open={Boolean(gifOpen)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                style={isKeyboardVisible ? { top: 'env(safe-area-inset-top)', bottom: '70px' } : {}}
              >
                <GiphyComponent
                  handleSelect={onGifSelect}
                />

              </Popover>

            </Grid>
          </>
        }
        {/* Gif Container  End */}

        {
          actionIcons.includes('send') &&
          <IconButton onClick={() => handleChatSubmit()} className="floatOption p0 ml-1"><ChatSendIconNew /></IconButton>
        }

      </Grid>
      {
        (isMobile || isAndroid || isIOS) && (actionIcons.includes('send')) && chatInputText?.trim()?.length > 0 &&
        <IconButton id="mobile-send-button" onClick={() => handleChatSubmit()} className={`${classes.mobileSendIcon}`}>
          <MobileSend />
        </IconButton>
      }
      {
        (isMobile || isAndroid || isIOS) && (actionIcons.includes('audio')) && chatInputText?.trim()?.length === 0 &&
        <IconButton className={classes.BlueRecordIcon} onClick={() => getMedia()}>
          <BlueRecordIcon />
          {recordingStatus && <span className={classes.recording}></span>}
        </IconButton>
      }
      {
        audioPermission && audioDevice && recordingStatus && !isFlozyMobileApp() && actionIcons.includes('audio') && (
          <AudioRecorder handleClose={stopAudioRecording} handleSendVoiceNote={sendVoiceNote} />
        )
      }

      {
        isFlozyMobileApp() && recordingStatus && actionIcons.includes('audio') && (
          <MobileAudioRecorder handleClose={stopAudioRecording} handleSendVoiceNote={sendVoiceNote} />
        )
      }
      <InfoDialogComponent
        open={audioDeviceWaring}
        title="Microphone not found"
        content="You can’t record a Voice Message because it looks like your computer doesn’t have a microphone. Try connecting one or if you have one connected, try restarting your browser."
        handleCloseAction={() => setAudioDeviceWarning(false)}
      />
    </>
  );
})

// default props
TextEditor.defaultProps = {
  variant: "outlined",
  editChatData: {},
  // notesText: ``,
  // handleChatText: () => { },
  handleChatSend: () => { },
  handleAttachment: () => { },
  handleChatGif: () => { },
  handleTyping: () => { },
  infinityModeHandler: () => { },
  activateInfinity: false,
  placeHolder: "Start Typing...",
  unsendMessages: {},
  actionIcons: ['plus', 'audio', 'emoji', 'gif', 'send', 'infinity'],
  backgroundColor: 'F0F0F0',
  handleChatChange: () => { },
};

// prop types
TextEditor.propTypes = {
  variant: PropTypes.string,
  editChatData: PropTypes.object,
  handleChatSend: PropTypes.func,
  handleAttachment: PropTypes.func,
  infinityModeHandler: PropTypes.func,
  handleChatGif: PropTypes.func,
  handleTyping: PropTypes.func,
  placeHolder: PropTypes.string,
  activateInfinity: PropTypes.bool,
  unsendMessages: PropTypes.object,
  actionIcons: PropTypes.array,
  handleChatChange: PropTypes.func,
};

// export component
export const ChatInputComponent = withStyles(styles)(TextEditor);
