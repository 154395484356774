import React, { useEffect, useState } from "react";
import { Grid, IconButton, withStyles } from "@material-ui/core";
import { CarouselIconLeft, CarouselIconRight, UserExpandIcon2, UsersExpandIcon } from "../../../assets/svg/videocall";
import VCParticipant from "../VCParticipant";
import style from './style'
import Slider from "react-slick";

function VCMiniParticipantList({
    classes,
    showChat,
    unpinnedUserHide,
    expandUsers,
    remoteAttendee,
    unpinnedUsers,
    videoElements,
    joined,
    profile,
    mutedUsers,
    allUsers,
    PinUser,
    UnPinUser,
    user_id,
    currentPage,
    setPage,
    itemsPerPage,
    channelStatus,
    channelName,
    resubscribe,
    parentWidth,
    volume
}) {

    const [showCarousel, setShowCarousel] = useState(false)

    useEffect(() => {
        if (itemsPerPage) {
            if (remoteAttendee.length < itemsPerPage) {
                setShowCarousel(false);
            } else if (parentWidth <= 768) {
                setShowCarousel(false)
            } else {
                setShowCarousel(true)
            }
        }
    }, [itemsPerPage, remoteAttendee, parentWidth])
    return (
        <>
            <IconButton onClick={() => expandUsers()} className={`${classes.ExpandIcon} ${classes.ExpandIcon}${unpinnedUserHide ? "__collapse" : "__open"}`}>
                <UsersExpandIcon />
            </IconButton>
            {unpinnedUserHide && (
                <div
                    className={`${classes.ParticipantsList} vc-hide-arrows`}
                >

                    {
                        showCarousel &&
                        <div className={classes.ParticipantsListFullWidth}>
                            <div className={classes.carouselContainer} >
                                <Slider
                                    dots={false}
                                    infinite={false}
                                    speed={500}
                                    arrows={true}
                                    slidesToScroll={2}
                                    prevArrow={<IconButton className={classes.slickIcons}><CarouselIconLeft /></IconButton>}
                                    nextArrow={<IconButton className={classes.slickIcons}><CarouselIconRight /></IconButton>}
                                    variableWidth={true}
                                    initialSlide={0}
                                    slidesToShow={itemsPerPage}
                                >
                                    {remoteAttendee.map((attendee) => (
                                        <VCParticipant
                                            user={attendee.user}
                                            userMetaData={attendee.metadata}
                                            ref={(ref) => (videoElements[attendee.user.uid] = ref)}
                                            isVideoMuted={attendee.user.videoMuted}
                                            user_id={user_id}
                                            joined={joined}
                                            avatar={profile.avatar_filename}
                                            showChat={showChat}
                                            mutedUsers={mutedUsers}
                                            allUsers={allUsers}
                                            remoteAttendees={remoteAttendee}
                                            profile={profile}
                                            videoWidth={180}
                                            videoHeight={100}
                                            PinUser={PinUser}
                                            unpinnedUsers={unpinnedUsers}
                                            UnPinUser={UnPinUser}
                                            audioMuted={attendee.user.audioMuted}
                                            key={attendee.user.uid}
                                            channelStatus={channelStatus}
                                            channelName={channelName}
                                            resubscribe={resubscribe}
                                            volume={volume}
                                        />
                                    ))}
                                    {/* <div style={{width: "200px"}}></div> */}
                                </Slider>
                            </div>
                        </div>
                    }
                    {
                        !showCarousel &&
                        <div className={classes.ParticipantsListFullWidth}>
                            {
                                remoteAttendee.map((attendee) => (
                                    <VCParticipant
                                        user={attendee.user}
                                        userMetaData={attendee.metadata}
                                        ref={(ref) => (videoElements[attendee.user.uid] = ref)}
                                        isVideoMuted={attendee.user.videoMuted}
                                        user_id={user_id}
                                        joined={joined}
                                        avatar={profile.avatar_filename}
                                        showChat={showChat}
                                        mutedUsers={mutedUsers}
                                        allUsers={allUsers}
                                        remoteAttendees={remoteAttendee}
                                        profile={profile}
                                        videoWidth={180}
                                        videoHeight={100}
                                        PinUser={PinUser}
                                        unpinnedUsers={unpinnedUsers}
                                        UnPinUser={UnPinUser}
                                        audioMuted={attendee.user.audioMuted}
                                        key={attendee.user.uid}
                                        channelStatus={channelStatus}
                                        channelName={channelName}
                                        resubscribe={resubscribe}
                                        volume={volume}
                                        parentWidth={parentWidth}
                                    />
                                ))
                            }
                        </div>
                    }
                </div>
            )}
        </>
    );
}

export default withStyles(style)(VCMiniParticipantList)
