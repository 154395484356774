const columnsCount = 4;
const style = (theme) => ({
    VideoCallContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        background: '#000000',
        gap: '10px',
        alignItems: 'center',
        position: "absolute",
        justifyContent: "center",
        top: "0",
        '@media only screen and (max-width: 768px)': {
            width: '100%',
            overflow: 'hidden',
            height:'100%'
        }

    },
    CallHeader: {
        position: "absolute",
        top: 20,
        width: '100%',
        display: 'flex',
        // justifyContent: "space-between",
        flexDirection:"column",
        // alignItems: 'center',
        padding: "0 30px",
        background: 'transparent',
        zIndex: 9999,

    },
    AvatarContainer: {
        width: '100%',
        height: '500px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center"

    },

    VideoCallAvatar: {

        display: 'flex',
        width: '140px',
        height: '140px',
        justifyContent: 'center',
        alignItems: 'center',

        flexDirection: "column",
        gap: '10px'
    },


    CallOptionsContainer: {
        position: 'absolute',
        top: '90%',
        bottom: '0',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },

    VideoCallIcon: {
        width: '10px',
        height: '10px',
    },

    VideoCallCloseIcon: {
        width: '19px',
        height: '19px',
        marginRight: '20px',
        position: 'fixed',
        left: '96%'

    },

    userName: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '125%'
    },

    CallContainer: {

        width: '596.463px',
        height: '350px',
        flexShrink: 0,
    },

    Container: {

        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        padding: '10px',
        marginTop: '40px'
    },

    LocalCallContainer: {
        width: '596.463px',
        height: '350px',
        flexShrink: 0,
    },

    s: {
        width: '700px',
        height: '600px'
    },

    video: {
        width: '700px',
        height: '450px',
        borderRadius: "10px",
        position: 'fixed',
        top: "27%",
        left: "27%"

    },
    emptyVideoContainer: {
        width: '100%',
        height: '700px'
    },
    Button: {
        color: "white",
        width: '116px',
        height: '50px',
        borderRadius: "12px",
        marginLeft: '10px',
        padding: '8px',
        background: '#2563EB'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },


    VideoContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '1px',
        justifyContent: "center",
        alignItems: "center",
        position: "relative"
    },

    participants: {
        width: '100%',
        height: "300px",
        color: 'white',
        position: 'absolute',
        top: '10%',
        bottom: '0',
        padding: theme.spacing(2),
    },
    ParticipantsList: {
        width: '157.24px',
        height: '157.24px',
        display: 'flex',
        flexDirection: 'row-reverse',
    },
    ParticipantsIcon: {
        top: '35px',
        right: '30px',
        zIndex: 2,
        position: 'relative',
        display: 'flex',
        flexDirection: 'row-reverse',
        gap: "8px"
    },
    ParticipantsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        bottom: '30px',
        position: 'absolute',
        zIndex: 999,
        padding: "15px",
        /* align-items: flex-end; */
        /* flex-direction: row; */
        /* justify-content: space-between; */
        top: '90%',
        left: '20px'

    },
    ListOption: {
        height: '100px'
    },
    ParticipantsListOptionsContainer: {
        width: '133px',
        height: '26px',
        flexShrink: 0,
        backgroundColor: '#2563EB',
        backdropFilter: 'blur(7.5px)',
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        position: 'relative',
        left: '30px',
        padding: '4px',
        alignItems: 'center',
        bottom: '41px'

    },
    CircleAvatar1: {
        width: '9px',
        height: '9px',
    },
    CloseIcon: {
        display: 'flex',
        flexDirection: 'column',
        height: '150px',
        justifyContent: 'center',
        top: '40px',
        position: 'relative'
    },
    ChatIcon: {
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        width: ' 40px',
        marginLeft: "10px",
        position: 'relative',
        top: '10px'
    },
    ChatIconContainer: {
        width: ' 65.296px',
        height: '43px',
        borderRadius: '21.5px',
        background: '#FFF',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        flexDirection: 'row',
    },
    Icon: {
        display: 'flex',
        alignItems: "center",
        marginLeft: "15px"
    },
    Avatar: {
        width: '46px',
        height: '46px',
        position: 'relative',
        right: '66px',
        bottom: '25px'
    },
    CircleAvatar: {
        display: "flex",
        height: '146px',
        alignItems: 'flex-end'
    },
    Avatar2: {
        width: '100px',
        height: '97px',
        position: 'absolute',
        top: '28px',
        right: '25px'
    },
    Avatar3: {
        height: '147px',
        width: '150px',
        position: 'absolute',
        right: '36px',
        bottom: '40px'
    },
    CircleAvatarContainer: {
        position: 'relative',
        bottom: '2px'
    },
    CircleAvatar3: {
        height: '200px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "flex-end",
        width: "90px"
    },
    PinUser: {
        display: 'flex',
        position: 'relative',
        right: '25px',
        top: '10px'
    },
    PinAvatar: {
        position: 'absolute',
        top: '8px',
        right: '12px'
    },
    PinCircleAvatar: {
        position: "relative",
        top: 0,
        right: '20px',
        display: "flex"
    },
    ParticipantsListOptionsProfile2: {
        width: '120px',
        height: '26px',
        flexShrink: 0,
        backgroundColor: '#2563EB',
        backdropFilter: 'blur(7.5px)',
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        position: 'relative',
        left: "77px",
        bottom: '53px',
        padding: '4px',
        alignItems: 'center'
    },
    ParticipantsListOptionsProfile3: {
        width: '133px',
        height: '26px',
        flexShrink: 0,
        backgroundColor: '#2563EB',
        backdropFilter: 'blur(7.5px)',
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        position: 'relative',
        left: '90px',
        bottom: '62px',
        padding: '4px',
        alignItems: 'center'
    },
    fullWidthVideo: {
        width: '950px',
        height: '600px',
        objectFit: 'cover',
        position: 'relative',
        zIndex: 1200,
        left: '20%'
    },
    PinIcon: {
        position: 'absolute',
        top: '50%',
        right: '50%',
        zIndex: '999',
    },
    AvatarCloseIcon: {
        marginTop: '2px'
    },
    FullWidthContainer: {
        width: '100%'
    },

    hiddenVideo: {
        visibility: 'hidden',
        height: 0,
    },
    DeclineBtn: {
        display: 'flex',
        width: '191px',
        height: ' 56px',
        padding: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '12px',
        background: '#FC5D5B'
    },
    JoinBtn: {
        display: 'flex',
        width: '191px',
        height: '56px',
        padding: '8px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '12px',
        background: ' var(--Primary-600-base, #2563EB)'
    },
    JoinRequestText: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '125%',
        height: '100px',
        marginTop: "20px",
        position: "absolute",
        top: 0
    },
    callingText: {
        alignItems: "center",
        display: 'flex',
        flexDirection: 'column'
    },
    CallRequestContainer: {
        textAlign: "center",
        position: "absolute",
        zIndex: 9999,
        bottom: "37%",
        width: '409.427px',
        height: '259.94px',
        left: '72%',
        top: "10%",
    },
    requestVideo: {
        height: "259.94px",
        width: "409.94px",
        filter: 'blur(1.5px)',
        borderRadius: "10px"

    },
    RequstOptionsContainer: {
        width: "409.94px",
        height: '50px',
        position: 'absolute',
        top: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '15px'
    },
    answerBtn: {
        display: 'flex',
        width: '84.122px',
        height: '33.674px',
        padding: '3.523px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4.404px',
        borderRadius: '5.285px',
        background: ' var(--Primary-600-base, #2563EB)'
    },
    RequestHeader: {
        position: 'absolute',
        top: '10%',
        left: '5%',
        zIndex: 999
    },
    RemoteVideo: {
        // objectFit: 'contain',
        // width: '100%',

        // width: '100%',
        // height: '100%',
        // position: 'absolute',
        // left: '0px',
        // top: '0px',
        // objectFit: 'contain'
        width: '100%',
        height: '100%',
        objectFit: 'contain'


    },

    RemoteVideoMute: {
        position: 'relative',
        right: '70%'
    },

    MutedAvatarContainer: {
        height: '445px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: "715px"
    },
    MutedAvatar: {
        width: '182px',
        height: '182px',

    },
    RequestMutedAvatarContainer: {
        height: "259.94px",
        width: "409.94px",
    },
    VideoCallChatIcon: {
        width: '65.296px',
        height: '43px',
        borderRadius: '21.5px',
        background: '#FFF',
        boxShadow: ' 0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
    },
    VideocallChatIconContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: 'row',
        position: 'absolute',
        zIndex: 999,
        top: '50%',
        left: '98%',
    },
    RemoteUsersVideo: {
        width: ' 481px',
        height: '426px',

    },
    ChatVideoContainer: {
        width: "500px",
        height: "100%",
        display: "flex",
        flexDirection: "row"
    },
    ChatVideo: {
        width: "500px",
        height: "303px"
    },
    remoteuserAvatar: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        top: '29px',
        display: "flex"
    },
    remoteAvatar: {
        display: 'flex',
        width: '49.139px',
        height: '49.139px',
        justifyContent: 'center',
        alignItems: 'center',

    },
    callDuration: {
        padding: '1px 8px',
        display: 'flex',
        zIndex: '1',
        background: 'linear-gradient(104deg, rgba(0, 0, 0, 0.29) 38.7%, rgba(0, 0, 0, 0.22) 80.45%)',
        boxShadow: '0px 4px 4px 0px rgba(255, 255, 255, 0.01)',
        borderRadius: '20px',
        backdropFilter: 'blur(27.5px)',
        justifyContent: 'center',
        position: "fixed",
        right: "50%"
    },
    callDurationText: {
        color: "white",
        textAlign: "center"
    },
    callDurationIcon: {
        display: 'flex',
        width: '12.63px',
        height: '12.63px',
        justifyContent: 'space-between',
        marginTop: '4px',
        marginRight: '5px'
    },

    AudioPlayIcon: {
        top: '10%',
        left: '80%',
        zIndex: 999,
        position: 'absolute'
    },
    UserAvatar: {
        display: 'flex',
        height: '180px',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    ChatPageContainer: {
        Width: '614px',
        Height: '807px',
        backgroundColor: 'white'
    },
    RemoteUserVideoContainer: {
        display: "flex",
        flexWrap: "nowrap",
        gap: "1px"
    },
    ChatVideoUsersContainer: {
        display: "flex",
        flexDirection: 'column'
    },
    ScreenShareContainer: {
        width: "100%",
        height: '100%',
        display: ' flex',
        zIndex: ' 9999',
        position: 'absolute',
        justifyContent: 'center',
        flexDirection: 'column',
        alignContent: 'center'

    },
    ScreenShareOptionContainer: {
        width: '338px',
        height: '182px',
        flexShrink: 0,
        borderRadius: '12px',
        background: 'var(--Additional-white, #FFF)',
        position: 'absolute',
        zIndex: 9999,
        right: '70%',
        top: '50%'
    },
    ScreenOptions: {
        display: "flex",
        flexDirection: "column",
        padding: "10px"
    },
    ScreenBtn: {
        width: '327px',
        height: '49px',
        display: 'flex',
        justifyContent: 'flex-start'
    },
    WindowBtn: {
        width: '327px',
        height: '49px',
        display: 'flex',
        justifyContent: ' flex-start'
    },
    PresentScreentxt: {
        color: '#000',
        fontFamily: 'Inter',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: ' 160% '/* 32px */,
        padding: '10px',
        marginLeft: '15px'
    },
    ScreenIcon: {
        display: "flex",
        gap: "10px"
    },
    ScreenOptionContainer: {
        width: '961px',
        height: '503px',
        flexShrink: 0,
        borderRadius: '12px',
        background: 'var(--Additional-white, #FFF)'
    },
    ScreenShareVideo: {
        width: '90vw',
        height: '90vh',
        position: 'fixed',
        objectFit: "contain"

    },
    ScreenOptionHeader: {
        width: '900px',
        height: "20px",
        padding: "10px"
    },
    RemoteVideoStyle: {
        // display: 'flex',
        // width: '50%',
        // justifyContent: 'center',
        /* text-align: center; */
        // alignItems: 'center',

        // // background:#aca;
        // // border:1px solid green;
        // flex: '1',
        // minWidth: '400px',
        // maxWidth: '50%'
        // flex: 1,
        // minWidth:'400px',
        // // maxWidth:'50%',
        // width: '100%',
        // height: '100%',
        // position: 'relative',
        // overflow: 'hidden',
        // // textAlign: "center",
        // display: "flex",
        // justifyContent: "center"
        position: 'relative',
        overflow: 'hidden',


    },
    VideoRemoteUserContainer: {
        // background: "black",
        // width: "100vw",
        // height: "100%",
        // display: "flex",
        // flexWrap: "wrap",
        // justifyContent: "center",
        // textAlign: "center"
        // width: '100%',
        // height: '100%',
        // display: 'grid',
        // gridTemplateColumns: ' minmax(0px, 1fr) minmax(0px, 1fr)'

        // background: 'black', width: '100vw', height: '100vh', display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '1px'

        // display: 'grid',
        // gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        // padding: '10px',
        // height: '100vh',
        // overflow: 'auto',
        // maxWidth: '1200px',
        // maxHeight: '800px'


        display: 'grid',
        gridTemplateColumns: `repeat(${columnsCount}, minmax(240px, 1fr))`,
        padding: '10px',
        height: 'calc(100vh - 60px)', /* Subtract the height of the header or any other element on top */
        overflow: 'auto',
        maxWidth: '100vw',
        maxHeight: '85vh', /* Set the maximum height */
        gridGap: '1px',

    },
    localVideoContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        alignItems: 'center',
        top: '15%'
    },
    localVideo: {
        width: '596.463px',
        height: '378.687px',
        borderRadius: '12px'
    },
    MuteVideoAvatar: {
        width: '140px',
        height: '140px',

    },
    ScreenshareStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    MuteVideoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '12px',
        width: "100%",
        height: "100%",
        zIndex: 1
    },
    StopShareBtn: {
        display: 'inline-flex',
        height: '31px',
        padding: ' 8px 12px',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '8px',
        background: '#a39e9e'
    },
    LobbyMuteVideo: {
        width: '523px',
        height: '402.69px',
        borderRadius: "10px",
        position: 'absolute',
        top: "27%"
    },
    RemoteScreenShareVideo: {
        width: '90vw',
        height: '90vh',
        position: 'fixed',
        objectFit: "contain",
        right: "3%"
    },
    pagination: {
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        top: "47%",
        right: "0",
        width: "40px",
        zIndex:99,
        '@media only screen and (max-width: 768px)': {
            right: "0",
            zIndex: 999
        }
    },
    button: {
        padding: "10px",
        margin: "0 5px",
        cursor: "pointer",
        border: "none",
        borderRadius: "5px",
        backgroundColor: "#f0f0f0",
        color: "#333",
    },
    active: {
        backgroundColor: "#007bff",
        color: "#fff",
    },

    unpinnedUser: {
        width: "119.97px",
        height: "106.256px"
        // height: "500px"
    },
    hide: {
        display: "none"
    },
    ExpandIcon: {
        position: 'absolute',
        zIndex: 9,
        top: "-15px",
        right: '48%',
        justifyContent: "center",
        display: "flex"
    },
    ExpandIcon2: {
        position: 'absolute',
        top: '95%',
        zIndex: 9,
    },
    UnpinUserPinIcon: {
        top: '35%',
        position: 'absolute',
        right: '35%',
        zIndex: 9
    },
    RemoteVideoUser: {
        width: "1200px",
        height: "100%",
        position: "absolute",
        left: '7%'
    },
    remoteUserName: {
        top: '85%',
        /* width: auto; */
        /* height: 50px; */
        zIndex: 999,
        position: 'absolute',
        backgroundColor: '#0000004A',
        borderRadius: '12px',
        padding: '4px 8px',
        left: '10px'
    },
    UnpinUserMuteContainer: {
        width: "119.97px",
        height: "106.256px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        /* border: 1px solid black; */
        background: '#574141'
    },
    UnPinUserMuteAvatar: {
        width: '52px',
        height: '46px',

    },
    unpinRemoteUserName: {
        // width: '41.17px',
        // height: '17px',
        position: 'absolute',
        top: '70%',
        left: '30%',
        backgroundColor: ' #0000004A',
        borderRadius: '12px',
        padding: '4px',
    },
    AudioMuteIcon :{
        position : "absolute",
        top: 0,
        left:'90%'
    },
    paginationLeft :{
        display: "flex",
        justifyContent: "center",
        position: "absolute",
        top: "47%",
        left: 0,
        width: "40px",
        zIndex:99,
        '@media only screen and (max-width: 768px)': {
            left: "0",
            zIndex:999
        }
    },
    JoinRequesInputBox: {
        position: 'fixed',
        top: '10%',
        gap: '10px',
        display: 'flex !important',
        flexDirection: 'column',
        '@media only screen and (max-width: 768px)': {
            width: '70%',
            background: 'black',
            padding: '8px'
        },
        '@media only screen and (max-width: 425px)':{
            width: '100%',
            background: 'black',
            padding: '8px'
        }

    },
    attendeeListContainer: {
        position: "relative",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        transition: "height 0.5s",

        "&__hide":{
            height: "20px",
        },
        "&__open":{
            height: "110px",
        }
    },
    participantWrapper:{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        transition: "height 0.5s"
    },
    fullHeight: {
        height: "100%"
    },
    listExpandHeight: {
        height: "calc(100% - 120px)"
    },
    listCollapseHeight: {
        height: "calc(100% - 30px)"
    },

    NameText: {
        color: 'white',
    },

    InputBox: {
        border: "0.5px solid #484848",
        borderRadius: '10px',
        height: '40px',
        width: '700px',
        backgroundColor: 'transparent',
        color: 'white',
        padding:'12px',
        '@media only screen and (min-width: 430px) and (max-width: 768px)': {
            width: '525px'
        },
        '@media only screen and (max-width: 425px)': {
            width: '100%',
        },
        
        

    },
    JoinTextContainer :{
        position: 'fixed',
        top: '15%',
        gap: '10px',
        '@media only screen and (max-width: 768px)': {
            width: '100%',
            display: 'flex !important',
            justifyContent: 'center'
        }
    },
    bottomLoader:{
        color:'#3C4043',
        left: 0,
    },
    topLoader:{
        color:'#fff',
        animationDuration: '550ms',
        position: 'absolute',
        left: 0,
    },
    circleLoader:{
        strokeLinecap: 'round',
    },
    CallHeaderWithLoader: {
        position: "absolute",
        top: 0,
        width: '100%',
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        padding: "0 10px",
        background: '#282828',
        zIndex: 99999,
        height:'40px',
        '@media only screen and (max-width: 768px)' :{
            bottom : 0,
            top: 'auto'
        }
    },
    userNameLengthContainer:{
        color: 'white', 
        display: 'flex',
        width: "100%",
        justifyContent: 'flex-end',
        '@media only screen and (max-width: 768px)':{
            position: 'fixed',
            right:'10px'
        }
    },
    ScreenSharePopupContainer :{
        width: 'auto',
        height: 'auto',
        background: 'white',
        padding: '12px',
        position: 'absolute',
        top: '74%',
        zIndex: 9,
        borderRadius: '12px',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        left: '61px'
    },

    ScreenSharePopupContent:{
        fontSize: '12px',
        display: 'flex',
        gap: '7px',
        aligIitems: 'center'
    },
    PermissionPopup:{
        position: "absolute",
        top: '10px',
        width: '100%',
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        padding: "0 10px",
        zIndex: 99999,
        height:'40px',
        gap: '10px',
        '@media only screen and (max-width: 768px)' :{
            bottom : 0,
            top: 'auto'
        }
    }
})


export default style
