import React from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import iconImage from '../../assets/images/emoji/unicorn-face.png';

import styles from './styles'

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const canvasStyles = {
  position: 'absolute',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 99,
}

class Snow extends React.Component {
  constructor(props) {
    super(props);
    this.isAnimationEnabled = false;
    this.animationInstance = null;
    this.colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722'];
    this.count = 0;
  }

  componentDidMount() {
    this.startAnimation();

    this.timer = setInterval(
      () => this.stopAnimation(),
      10000,
    );
  }

  getAnimationSettings(color) {
    return {
      particleCount: 1,
      startVelocity: 0,
      ticks: 2000,
      gravity: 0.7,
      origin: {
        x: Math.random(),
        y: (Math.random() * 0.999) - 0.2,
      },
      colors: [color],
      shapes: ['circle', 'square'],
      scalar: randomInRange(0.2, 1),
      zIndex: 9,
    };
  }

  nextTickAnimation = () => {
    var color = this.colors[this.count++ % this.colors.length];
    this.animationInstance && this.animationInstance(this.getAnimationSettings(color));
    if (this.isAnimationEnabled) requestAnimationFrame(this.nextTickAnimation);
  }

  startAnimation = () => {
    if (!this.isAnimationEnabled) {
      this.isAnimationEnabled = true;
      this.nextTickAnimation();
    }
  }

  pauseAnimation = () => {
    this.isAnimationEnabled = false;
  }

  stopAnimation = () => {
    this.isAnimationEnabled = false;
    this.animationInstance && this.animationInstance.reset();
  }

  handlerClickStart = () => {
    this.startAnimation();
  };

  handlerClickPause = () => {
    this.pauseAnimation();
  };

  handlerClickStop = () => {
    this.stopAnimation();
  };

  getInstance = (instance) => {
    this.animationInstance = instance;
  };

  componentWillUnmount() {
    this.isAnimationEnabled = false;
  }

  render() {
    const { classes, text_message } = this.props;
    return (
      <>
        <div className={classes.completeMsgContainer}>
          <span className={classes.completeBox}>
            {text_message}
            <img src={iconImage} alt={text_message} />
          </span>
        </div>
        <ReactCanvasConfetti refConfetti={this.getInstance} style={canvasStyles} />
      </>
    );
  }
}

// default props
Snow.defaultProps = {
  classes: {},
  text_message: "",
};

// prop types
Snow.propTypes = {
  classes: PropTypes.object,
  text_message: PropTypes.string,
};

export default withStyles(styles)(Snow)