const styles = (theme) => ({
    emptyContainer: {
        '@media only screen and (min-width: 768px)': {
            '&.emptyContainer': {
                height: 'calc(100vh - 177px)'
            }
        },
        '@media only screen and (max-width: 768px)': {
            '&.emptyContainer': {
                height: 'calc(100vh - 230px)'
            }
        }
    },
})

export default styles;