import { renderToString } from "react-dom/server";
import { getBlock } from "./chatEditor_UtilityFunctions";

const serializeToHTML = (nodes, msgComponentType,viewType,isSender,clickable) => {
  try {
    const htmlString = (nodes || []).map((node) => {
      const reactElement = getBlock({ element: node, msgComponentType,viewType ,isSender,clickable});
      return renderToString(reactElement);
    }).join('');

    return htmlString;
  } catch (err) {
    console.error('Error serializing to HTML:', err);
    return '';
  }
};

export default serializeToHTML;
