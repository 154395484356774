import React, { useEffect, useState } from "react"
import { Popover, Select, MenuItem } from "@material-ui/core";
import { BlockPicker } from "react-color";
const FONT_SIZES = [12, 14, 16, 18, 20, 24, 32, 46]

export const TextNodeOptions = (props) => {
    const { classes, selectedNode } = props
    const { id, onChangeAttrs, ...otherProps } = selectedNode?.attrs || {}
    const [attrs, setAttrs] = useState({
        colorPicker: false,
        pickerEle: null,
        ...(otherProps || {})
    })

    useEffect(() => {
        if (id) {
            setAttrs({
                ...attrs,
                ...(otherProps || {})
            })
        }
    }, [id])

    const toggleColorPicker = (e) => {
        setAttrs({
            ...attrs,
            colorPicker: !attrs.colorPicker,
            pickerEle: attrs.colorPicker ? null : e.currentTarget
        })
    }

    const onChange = (val) => {
        const updatedAttrs = {
            ...attrs,
            ...val
        }
        setAttrs(updatedAttrs)
        if (onChangeAttrs) {
            onChangeAttrs(updatedAttrs)
        }
    }

    const renderColorPicker = () => {
        return (
            <Popover
                open
                anchorEl={attrs.pickerEle}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={toggleColorPicker}
            >
                <BlockPicker
                    color={attrs?.fill}
                    onChangeComplete={(c) => {
                        onChange({ 'fill': c?.hex })
                    }}
                />
            </Popover>
        )
    }

    return (
        <div className={classes.toolOptions}>
            <div className={classes.optionsWrpr}>
                <div
                    role='button'
                    className={classes.colorBox}
                    onClick={toggleColorPicker}
                    style={{
                        backgroundColor: attrs?.fill
                    }}
                />
                <div className={classes.colorHex}>{attrs?.fill}</div>
            </div>
            <div className={classes.optionsWrpr}>
                <Select
                    value={attrs?.fontSize}
                    label={'Font Size'}
                    onChange={(e) => {
                        onChange({ 'fontSize': e.target.value })
                    }}
                >
                    {
                        FONT_SIZES.map((m, i) => {
                            return (
                                <MenuItem key={`${m}_${i}`} value={m}>{m}</MenuItem>
                            )
                        })
                    }
                </Select>
            </div>
            <div className={classes.optionsWrpr} style={{width:"150px"}}>
                <Select
                    value={attrs?.fontFamily}
                    label={'Font Family'}
                    onChange={(e) => {
                        onChange({ 'fontFamily': e.target.value })
                    }}
                >
                    <MenuItem value={'sans-serif'}>Sans-Serif</MenuItem>
                    <MenuItem value={'Arial'}>Arial</MenuItem>
                    <MenuItem value={'Verdana'}>Verdana</MenuItem>
                    <MenuItem value={'Times New Roman'}>Times New Roman</MenuItem>
                </Select>
            </div>
            <div className={classes.optionsWrpr}>
                <Select
                    value={attrs?.fontStyle}
                    label={'Font Style'}
                    onChange={(e) => {
                        onChange({ 'fontStyle': e.target.value })
                    }}
                >
                    <MenuItem value={'normal'}>Normal</MenuItem>
                    <MenuItem value={'italic'}>Italic</MenuItem>
                    <MenuItem value={'bold'}>Bold</MenuItem>
                </Select>
            </div>
            {attrs.colorPicker ? renderColorPicker() : null}
        </div>
    )
}