import { loadImageAsync } from "./loadImage"
import { customNodeProps, pushHistory } from "./utils"

export const crop = async ({ stage, canvasWrapperRef }) => {
    const layer = stage.findOne('#baseLayer')
    const baseImage = stage.findOne('#baseImage')
    let cropNode = stage.findOne('#cropNode')
    const tr = stage.findOne('#transformer')
    let canvasImage = stage.findOne('#canvasImage')
    let backDrop = stage.findOne('#backDrop')
    const { scale, upScale } = stage.attrs.containerData
    const kcWrpr = canvasWrapperRef.getElementsByClassName('konvajs-content')[0]
    const { width: maxWidth, height: maxHeight } = kcWrpr.getBoundingClientRect()

    const onUndo = () => {
        baseImage.attrs.onChangeAttrs({
            stageProps: {
                width: baseImage.width(),
                height: baseImage.height()
            }
        }, 'crop')
        baseImage.show()
        cropNode.hide()
        hideCrop()
    }

    const onChangeAttrs = async (attrs) => {
        if (attrs.save) {
            const url = await cropNode.toDataURL({ pixelRatio: upScale })
            const image_base_new = await loadImageAsync(url)
            const cropProps = {
                x: -cropNode.x(),
                y: -cropNode.y(),
                cropWidth: cropNode.width(),
                cropHeight: cropNode.height(),
                width: image_base_new.width * scale,
                height: image_base_new.height * scale
            }
            baseImage.attrs.onChangeAttrs({
                stageProps: {...cropProps}
            }, 'crop')
            cropNode.setAttrs({
                x: 0,
                y: 0,
                img: image_base_new
            })
            pushHistory(cropNode, 'crop')
        } else {
            cropNode.hide()
        }
        hideCrop()
    }

    const hideCrop = () => {
        backDrop.hide()
        tr.nodes([])
        tr.hide()
        cropNode.hide()
        canvasImage.hide()
        stage.attrs.onUpdate(stage)
    }

    const url = await stage.toDataURL({ pixelRatio: upScale })
    const image_base = await loadImageAsync(url)
    const extWidth = (image_base.width / stage.width())
    const extHeight = (image_base.height / stage.height())

    if (!canvasImage) {
        canvasImage = new window.Konva.Image({
            image: image_base,
            id: 'canvasImage'
        });
        canvasImage.cache()
    } else {
        canvasImage.clearCache()
        canvasImage.setAttrs({
            image: image_base
        })
        canvasImage.cache()
        canvasImage.show()
    }

    if (!cropNode) {
        const cropX = (stage.width() * 0.25)
        const cropY = (stage.height() * 0.25)
        const width = (stage.width() * 0.5)
        const height = (stage.height() * 0.5)
        const x = stage.width() / 2 - width / 2
        const y = stage.height() / 2 - height / 2

        cropNode = new window.Konva.Image({
            image: image_base,
            x: x,
            y: y,
            cropX: cropX * extWidth,
            cropY: cropY * extHeight,
            cropWidth: width * extWidth,
            cropHeight: height * extHeight,
            width: width,
            height: height,
            id: `cropNode`,
            name: 'crop need-tr',
            nodeType: 'crop',
            draggable: true,
            ...customNodeProps({ stage }),
            onChangeAttrs: onChangeAttrs,
            undo: onUndo
        });

        // create vertical drag and drop
        cropNode.dragBoundFunc(function(pos){
            // important pos - is absolute position of the node
            // you should return absolute position too
            const endX = pos.x + (cropNode.width() * cropNode.scaleX())
            const endY = pos.y + (cropNode.height() * cropNode.scaleY())
            return {
                ...pos,
                x: pos.x < 0 ? 0 : endX > maxWidth ? this.absolutePosition().x : pos.x,
                y: pos.y < 0 ? 0 : endY > maxHeight ? this.absolutePosition().y : pos.y,
            };
        });

        backDrop = new window.Konva.Rect({
            id: 'backDrop',
            x: 0,
            y: 0,
            width: stage.width(),
            height: stage.height(),
            fill: 'rgba(0,0,0,0.5)',
            listening: false
        });

        layer.add(backDrop)
        tr.nodes([cropNode])
        tr.show()
        layer.add(cropNode)
    } else {
        cropNode.setAttrs({
            image: await canvasImage.toImage()
        })
        cropNode.show()
        backDrop.show()
        tr.nodes([cropNode])
        tr.show()
    }

    const updateClip = () => {
        cropNode.cropX(cropNode.x() * extWidth)
        cropNode.cropY(cropNode.y() * extHeight)
        cropNode.cropWidth(cropNode.width() * cropNode.scaleX() * extWidth)
        cropNode.cropHeight(cropNode.height() * cropNode.scaleY() * extHeight)
    }

    cropNode.on('dragmove', function () {
        updateClip();
    });

    cropNode.on('dragend', function () {
        pushHistory(cropNode, 'crop')
        cropNode.attrs.mdt = new Date().getTime()
    })

    cropNode.on('transform', function () {
        updateClip();
    });

    stage.attrs.onUpdate(stage)
}