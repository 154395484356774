import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { initiateSocket, disconnectSocketv2, initiatePublicSocket, updateVisibilityStatus } from '../../utils/socketv2'
import { authAction, chatv2Action, invoiceAction, profileAction, workspaceCloningActions } from '../../redux/actions'
import { videoCallAction } from '../../redux/actions/videoCallAction'
import { meetingAction } from '../../redux/actions/meetingAction'
import useVisibility from '../../hooks/useVisibilty'
import { FunnelActions } from '../../redux/actions/funnelAction'

const Socketv2 = () => {

    const dispatch = useDispatch()
    const { loginUser: { id, agency_pref } } = useSelector(s => s.auth)
    const { publicUserId, recorderId } = useSelector(s => s.videocall)
    const [visible] = useVisibility()

    useEffect(() => {
        if (id && agency_pref) {
            initiateSocket({ user_id: id, agency_id: agency_pref }, onEvent)
            dispatch(chatv2Action.getMutedChats())
            dispatch(chatv2Action.getUnReadChat())
            dispatch(videoCallAction.joinRequestSuccess(id))
        } else if(publicUserId) {
            initiatePublicSocket(publicUserId || recorderId, onPublicEvent);
        }
        return () => {
            disconnectSocketv2()
        }
    }, [id, agency_pref, publicUserId, recorderId])

    useEffect(() => {
        if (visible === "hidden") {
            updateVisibilityStatus({ user_id: id, visibility: false })
        } else {
            if (id && agency_pref) {
                initiateSocket({ user_id: id, agency_id: agency_pref }, onEvent)
            }
            updateVisibilityStatus({ user_id: id, visibility: true })
        }
    }, [visible])

    const onPublicEvent = (type, msgData) => {
        switch (type) {
            case 'call:notify':
                dispatch(videoCallAction.receiveVideoCallRequest(msgData));
                return dispatch(videoCallAction.joinRequest(msgData))
            case 'video:user_join':
                return dispatch(videoCallAction.sendRequest(msgData));
            case 'call:screen_share':
                return dispatch(videoCallAction.getScreenTrack(msgData))
            case 'call:stop_share':
                return dispatch(videoCallAction.stopScreenShare(msgData))
            case 'call:user_reject':
                return dispatch(videoCallAction.rejectRequest(msgData))
            case 'call:notify_user':
                return dispatch(videoCallAction.notifyUsers(msgData))
            case 'call:reject_call':
                return dispatch(videoCallAction.rejectCall(msgData))
            case 'call:callEnded':
                return dispatch(videoCallAction.callEnded(msgData))
            case 'meeting:update_channel':
                return dispatch(meetingAction.updateChannelStatus(msgData))
            case 'meeting:record_status_update':
                return dispatch(meetingAction.recordStartedStatus(msgData))
            case 'meeting:stopped_recording':
                return dispatch(meetingAction.recordingStopped(msgData))
            case 'meeting:activate_channel':
                return dispatch(meetingAction.activateChannel(msgData))
            default: return
        }

    }

    const onEvent = (type, msgData) => {

        switch (type) {
            case 'chat:notify':
                return dispatch(chatv2Action.onReceiveMessage(msgData))
            case 'chat:notify_bulk':
                return dispatch(chatv2Action.readStatusUpdateBulk(msgData))
            case 'chat:group_delete':
                return dispatch(chatv2Action.socketGroupDelete(msgData))
            case 'chat:user_delete':
                return dispatch(chatv2Action.socketUserDelete(msgData))
            case 'chat:users_add':
                return dispatch(chatv2Action.socketUsersAdd(msgData))
            case 'chat:user_online':
                return dispatch(chatv2Action.socketUserOnline(msgData))
            case 'chat:user_online_join':
                return dispatch(chatv2Action.socketUserOnline(msgData, true))
            case 'chat:user_typing':
                return dispatch(chatv2Action.socketUserTyping(msgData))
            case 'call:notify':
                dispatch(videoCallAction.receiveVideoCallRequest(msgData));
                return dispatch(videoCallAction.joinRequest(msgData))
            case 'video:user_join':
                return dispatch(videoCallAction.sendRequest(msgData));
            case 'call:screen_share':
                return dispatch(videoCallAction.getScreenTrack(msgData))
            case 'call:stop_share':
                return dispatch(videoCallAction.stopScreenShare(msgData))
            case 'call:user_reject':
                return dispatch(videoCallAction.rejectRequest(msgData))
            case 'call:notify_user':
                return dispatch(videoCallAction.notifyUsers(msgData))
            case 'call:reject_call':
                return dispatch(videoCallAction.rejectCall(msgData))
            case 'call:callEnded':
                return dispatch(videoCallAction.callEnded(msgData))
            case 'events:notify':
                return dispatch(profileAction.getInvites());
            case 'invoice:statusUpdate':
                return dispatch(invoiceAction.updatePaymentStatus(msgData))
            case 'tasks:notify':
                return dispatch(profileAction.getTasks());
            case 'chat:is_edited':
                return dispatch(chatv2Action.onEditChat(msgData))
            case 'chat:delivered_bulk':
                return dispatch(chatv2Action.deliveredStatusUpdateBulk(msgData))
            case 'meeting:update_channel':
                return dispatch(meetingAction.updateChannelStatus(msgData))
            case 'invoice:notify':
                return dispatch(invoiceAction.getInvoiceNewNotification())
            case 'funnel:notify':
                return dispatch(FunnelActions.getFunnelNewNotification())
            case 'meeting:record_status_update':
                return dispatch(meetingAction.recordStartedStatus(msgData))
            case 'meeting:stopped_recording':
                return dispatch(meetingAction.recordingStopped(msgData))
            case 'payment:statusUpdate':
                return dispatch(authAction.updatePaymentProcessingStatus(msgData))
            case 'wSCloning:notify':
                return dispatch(workspaceCloningActions.getSocektEventData(msgData))
            case 'meeting:activate_channel':
                return dispatch(meetingAction.activateChannel(msgData))
            default: return
        }

    }


    return (
        <div />
    )
}

export default Socketv2