export const dashboardConstant = {

    UPDATE_REM_TEXT: "UPDATE_REM_TEXT",

    UPDATE_R_TEXT_R: "UPDATE_R_TEXT_R",
    UPDATE_R_TEXT_S: "UPDATE_R_TEXT_S",
    UPDATE_R_TEXT_F: "UPDATE_R_TEXT_F",

    GET_D_TASKS_R: "GET_D_TASKS_R",
    GET_D_TASKS_S: "GET_D_TASKS_S",
    GET_D_TASKS_F: "GET_D_TASKS_F",

    GET_D_PROJECTS_R: "GET_D_PROJECTS_R",
    GET_D_PROJECTS_S: "GET_D_PROJECTS_S",
    GET_D_PROJECTS_F: "GET_D_PROJECTS_F",

    GET_D_CHATS_R: "GET_D_CHATS_R",
    GET_D_CHATS_S: "GET_D_CHATS_S",
    GET_D_CHATS_F: "GET_D_CHATS_F",

    GET_C_TIME_ZONE_OPTIONS_R: "GET_C_TIME_ZONE_OPTIONS_R",
    GET_C_TIME_ZONE_OPTIONS_S: "GET_C_TIME_ZONE_OPTIONS_S",
    GET_C_TIME_ZONE_OPTIONS_F: "GET_C_TIME_ZONE_OPTIONS_F",


    GET_D_WORLD_CLOCK_R: "GET_D_WORLD_CLOCK_R",
    GET_D_WORLD_CLOCK_S: "GET_D_WORLD_CLOCK_S",
    GET_D_WORLD_CLOCK_F: "GET_D_WORLD_CLOCK_F",

    GET_D_SAVE_WORLD_CLOCK_R: "GET_D_SAVE_WORLD_CLOCK_R",
    GET_D_SAVE_WORLD_CLOCK_S: "GET_D_SAVE_WORLD_CLOCK_S",
    GET_D_SAVE_WORLD_CLOCK_F: "GET_D_SAVE_WORLD_CLOCK_F",

    GET_QUICK_NOTE_R: 'GET_QUICK_NOTE_R',
    GET_QUICK_NOTE_S: 'GET_QUICK_NOTE_S',
    GET_QUICK_NOTE_F: 'GET_QUICK_NOTE_F',

    UPDATE_QUICK_NOTE_R: 'UPDATE_QUICK_NOTE_R',
    UPDATE_QUICK_NOTE_S: 'UPDATE_QUICK_NOTE_S',
    UPDATE_QUICK_NOTE_F: 'UPDATE_QUICK_NOTE_F',
};
