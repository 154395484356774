export const fileHubConstants = {
  GET_METRICS_S: "GET_METRICS_S",
  GET_METRICS_F: "GET_METRICS_F",
  RESET_METRICS_S: "RESET_METRICS_S",
  GET_ALLFILES_S: "GET_ALLFILES_S",
  GET_ALLFILES_F: "GET_ALLFILES_F",
  RESET_ALLFILES_S: "RESET_ALLFILES_S",

  GET_FOLDER_CONTENTS_S: "GET_FOLDER_CONTENTS_S",
  GET_FOLDER_CONTENTS_F: "GET_FOLDER_CONTENTS_F",
  RESET_FOLDER_CONTENTS_S: "RESET_FOLDER_CONTENTS_S",
  CREATE_FOLDER_S: "CREATE_FOLDER_S",
  CREATE_FOLDER_F: "CREATE_FOLDER_F",

  DELETE_FOLDER_S: "DELETE_FOLDER_S",
  DELETE_FOLDER_F: "DELETE_FOLDER_F",

  DELETE_FILE_S: "DELETE_FILE_S",
  DELETE_FILE_F: "DELETE_FILE_F",

  RENAME_FOLDER_S: "RENAME_FOLDER_S",
  RENAME_FOLDER_F: "RENAME_FOLDER_F",

  RENAME_FILE_S: "RENAME_FILE_S",
  RENAME_FILE_F: "RENAME_FILE_F",
  RESET: "RESET",
};

export const FILEHUB_RESOURCE_TYPES = {
  CLIENTS: "CLIENTS",
  FLOWS: "FLOWS",
};
