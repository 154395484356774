import React from "react";
import PropTypes from "prop-types";
import { Grid, TextField, withStyles } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import styles from './style'
import { DownArrowIcon } from "../../assets/svg";

/**
 * Autocomplete Component
 */
function AutoCompleteComponent(props) {
  /**
   * render html
   */
  const {
    suggestions,
    name,
    disabled,
    value,
    extraProps,
    classes,
    hasValue,
    showValidate,
    validationMsg,
    className,
    freeSolo,
    disableClearable,
    fullWidth,
    variant,
    ...rest
  } = props;

  return (
    <Grid className={`${classes.autoComplete} w-100`}>
      <Autocomplete
        blurOnSelect
        id={`ac-id${name}`}
        name={`${name}`}
        variant={"outlined"}
        options={suggestions}
        disabled={disabled}
        classes={{
          option: classes.option,
          paper: classes.popoverPaper
        }}
        fullWidth={fullWidth}
        className={className}
        hiddenLabel="true"
        disableClearable={disableClearable}
        popupIcon={<DownArrowIcon/>}
        autoHighlight={true}
        value={value && value.value ? value : { label: '', value: '' }}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        onChange={(event, newValue) => { props.handleAutoComplete(newValue || { label: '', value: '' }, name) }}
        onInputChange={(value, eValue, reason) => { props.onInputChange(value, name, reason) }}
        freeSolo={freeSolo}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              value={value}
              variant={variant}
              autoComplete= "new-password"
              {...rest}
              error={showValidate ? true : false}
              helperText={showValidate ? validationMsg : ""}
            />
          )
        }}
        autoComplete= "new-password"
        {...rest}
        {...extraProps}
      />
    </Grid>
  );
}

// default props
AutoCompleteComponent.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  name: "autocomplete",
  variant: "outlined",
  handleAutoComplete: () => { },
  onKeyDown: () => { },
  extraProps: {},
  hasValue: true,
  onInputChange: () => { },
  showValidate: false,
  validationMsg: '',
  freeSolo: false,
  disableClearable: true
};

// prop types
AutoCompleteComponent.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  variant: PropTypes.string,
  handleAutoComplete: PropTypes.func,
  extraProps: PropTypes.object,
  onInputChange: PropTypes.func,
  hasValue: PropTypes.bool,
  showValidate: PropTypes.bool,
  validationMsg: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  freeSolo: PropTypes.bool,
  disableClearable: PropTypes.bool,
  fullWidth: PropTypes.bool
};

/** Export Component */
export const AutoComplete = withStyles(styles)(AutoCompleteComponent);
