const styles = (theme) => ({

  selectBox: {
    width: "100%",
    '& .MuiSelect-outlined': {
      padding: 10,
      '&:focus': {
        background: 'transparent'
      }
    },
    '& .MuiOutlinedInput-root': {
      height: 40,
      minHeight: 40,
      marginTop: 0
    },
    '& legend': {
      maxWidth: '0 !important'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      top: '-6px !important',
    }
  },
  // fontColor:{
  //   color:'#778599 !important'
  // },
  popverClass: {
    // boxShadow: `${theme.palette.shadows.shadow7} !important`,
    maxHeight: "300px",
    overflowY: "auto",
    marginTop: '8px',
    '& .MuiMenuItem-root': {
      color: theme.palette.primary.main,
      '@media only screen and (max-width: 599px)': {
        minHeight: "30px"
      },
      '&.todoColor': {
        background: `${theme.palette.greyshades.light6} !important`
      },
      '& svg': {
        // marginRight: '8px',
        height: '12px'
      }
    }
  },
  // '& .MuiOutlinedInput-notchedOutline legend': {
  //   display: "none !important"
  // },
  // '& .MuiOutlinedInput-notchedOutline ': {
  //   '&legend': {
  //     display: "none !important",
  //   },
  //     marginTop: '5px !important'
  // },
  iconClass: {
    width: "15px",
    height: "15px",
    marginRight: "10px",
    objectFit: 'contain'
  },

  menuItem: {
    margin: '0px 10px',
    marginTop: '5px',
    borderRadius: '7px',
    backgroundColor:'none',
    '&:hover': {
      backgroundColor: '#EEF2F7'
    }
  },
  selectOption: {
    margin: "5px 0px",
    borderRadius: 7,
    // color:'#64748B !important',
    // '&.Mui-selected': {
    //   backgroundColor: '#EEF2F7'
    // }
  }
})
export default styles