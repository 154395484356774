import { clientConstant } from "../constants";

const initialState = {
  clientsList: {
    clients: [],
    tags: [],
    page: {
      sort: "",
      skip: 0,
      limit: 20,
      search: "",
      tag: [],
      status: ""
    },
  },
  clientsCount: {
    internal: 0,
    external: 0
  },
  clientDetail: {
    client: null,
    client_users: [],
    user_detail: null,
    chat_room: {
      chatRoom: "",
      userId: "",
      name: '',
      userName: ""
    },
    board_detail: null,
  },
  newClient: {
    client_type: "1",
    settings: {
      brief: 1,
      task: 1,
      boards: 1,
      brain: 1,
      media: 1,
      teams: 1
  }
  },
  clientsFilter: {
    completed: 0,
    inProgress: 0,
    tags: [],
    deleted: 0,
    isLoading: false
  },

  //clone page
  clientClone: {
    title: "",
    brief: "",
    short_description: "",
    team: [],
    task: "all",
    notes: "all",
    boards: "all"
  },

  //brief
  brief: {},
  briefEdit: {},

  //Notes
  notes: [],
  note: {
    content_status: null
  },
  newNote: {},

  //teams
  teamMembers: [],
  activeTeamMembers: [],
  invitation: false,
  clientTeam: [],
  inviteUser: {
    existing: [],
    new: []
  },

  //task
  newTask: {},
  tasks: {
    data: [],
    page: {}
  },
  taskTags: [],
  currentTask: {},
  currentTaskNotes: [],
  filters: { completed: 0, inProgress: 0, dueDate: [], assigned: [], total: 0, tags: [] },
  viewMany: false,
  current_type: 1,
  clientOpenInvites: {
    inviteList: {
      loading: true,
      invites: [],
      page: {
        sort: {
          isAcending: false,
          key: "created_on"
        },
        skip: 0,
        limit: 10,
        count: 0,
        search: "",
        name: [],
        client_space: [],
        created_on: [],
        client_users: []
      }
    },
    editInvite: {},
    agencyInfo: {},
    filters: {
      clients: [],
      invites: [],
      users: []
    }
  }
}

export default function client(state = initialState, action) {
  switch (action.type) {

    case clientConstant.CREATE_CLIENT_R:
      return {
        ...state,
      }
    case clientConstant.CREATE_CLIENT_S:
      return {
        ...state,
        newClient: initialState.newClient
      }
    case clientConstant.CREATE_CLIENT_F:
      return {
        ...state,
      }

    //tags
    case clientConstant.GET_TAGS1_R:
      return {
        ...state,
      }
    case clientConstant.GET_TAGS1_S:
      return {
        ...state,
        tags: action.data.data,
      }
    case clientConstant.GET_TAGS1_F:
      return {
        ...state,
      }

    //update create client data
    case clientConstant.UPDATE_CREATE_CLIENT:
      return {
        ...state,
        newClient: action.data
      }

    //GET_CLIENTS
    case clientConstant.GET_CLIENTS_R:
      return {
        ...state,
      }
    case clientConstant.GET_CLIENTS_S:
      return {
        ...state,
        clientsList: action.data.data,
      }
    case clientConstant.GET_CLIENTS_F:
      return {
        ...state,
        clientsList: initialState.clientsList
      }

    //GET_CLIENTS_COUNT
    case clientConstant.GET_CLIENTS_COUNT_R:
      return {
        ...state,
      }
    case clientConstant.GET_CLIENTS_COUNT_S:
      return {
        ...state,
        clientsCount: action.data.data,
      }
    case clientConstant.GET_CLIENTS_COUNT_F:
      return {
        ...state,
        clientsCount: initialState.clientsCount
      }

    //GET_CLIENTS_DETAIL
    case clientConstant.GET_CLIENTS_FILTER_R:
      return {
        ...state,
        clientsFilter: { ...initialState.clientsFilter, isLoading: true }
      }
    case clientConstant.GET_CLIENTS_FILTER_S:
      return {
        ...state,
        clientsFilter: { ...action.data.data, isLoading: false },
      }
    case clientConstant.GET_CLIENTS_FILTER_F:
      return {
        ...state,
        clientsFilter: { ...initialState.clientsFilter, isLoading: false }
      }

    case clientConstant.UPATE_C_FILTER_DATA:
      return {
        ...state,
        clientsList: { ...state.clientsList, page: action.data }
      }

    //update client list order
    case clientConstant.UPDATE_C_DND_R:
      return {
        ...state,
        clientsList: { ...state.clientsList, clients: action.params }
      }
    case clientConstant.UPDATE_C_DND_S:
      return {
        ...state,
        clientsList: { ...state.clientsList, page: { ...state.clientsList.page, sort: "" } }
      }
    case clientConstant.UPDATE_C_DND_F:
      return {
        ...state,
      }

    //GET_CLIENT_DETAIL
    case clientConstant.GET_CLIENT_DETAIL_R:
      return {
        ...state,
      }
    case clientConstant.GET_CLIENT_DETAIL_S:
      return {
        ...state,
        clientDetail: action.data.data,
      }
    case clientConstant.GET_CLIENT_DETAIL_F:
      return {
        ...state,
        clientDetail: initialState.clientDetail
      }

    /***********************************************BRIEF DETAILS************************************************************************************** */

    //GET_BRIEF
    case clientConstant.GET_CLIENT_BRIEF_R:
      return {
        ...state,
        brief: {
          client_status: null
        }
      }
    case clientConstant.GET_CLIENT_BRIEF_S:
      let { client_name, client_brief, short_description } = action.data.data;
      return {
        ...state,
        brief: {
          content_status: 'loaded',
          ...action.data.data
        },
        briefEdit: action.data.data,
        clientDetail: {
          ...state.clientDetail,
          client: action.data.data
        },
        clientClone: {
          ...state.clientClone,
          title: `${client_name ? client_name : ""} Copy`,
          description: client_brief ? client_brief : "",
          short_description: short_description ? short_description : "",
        },
      }
    case clientConstant.GET_CLIENT_BRIEF_F:
      return {
        ...state,
        brief: initialState.brief,
        briefEdit: initialState.briefEdit,
        clientDetail: initialState.clientDetail,
      }

    case clientConstant.UPATE_CARD_VIEW:
      return {
        ...state,
        viewMany: action.data.val
      }

    //UPDATE_BRIEF
    case clientConstant.UPDATE_CLIENT_BRIEF_R:
      return {
        ...state,
      }
    case clientConstant.UPDATE_CLIENT_BRIEF_S:
      return {
        ...state,
        brief: { ...state.briefEdit }
      }
    case clientConstant.UPDATE_CLIENT_BRIEF_F:
      return {
        ...state,
      }

    //UPDATE_BRIEF REDUCER
    case clientConstant.UPDATE_CLIENT_BRIEF_REDUCER:
      return {
        ...state,
        briefEdit: action.data,
      }



    //UPDATE Team Members in Project Clone Data   
    case clientConstant.UPDATE_CLIENT_CLONE_ACTIVE_TEAM_S:
      return {
        ...state,
        clientClone: {
          ...state.clientClone,
          team: action.data
        },
      }

    //UPDATE Client Clone Data
    case clientConstant.UPDATE_CLIENT_CLONE_REDUCER:
      return {
        ...state,
        clientClone: action.data,
      }

    /***********************************************NOTES DETAILS************************************************************************************** */

    //UPDATE_NOTE
    case clientConstant.UPDATE_C_NOTE_R:
      return {
        ...state,
      }
    case clientConstant.UPDATE_C_NOTE_S:
      return {
        ...state,
        newNote: initialState.newNote
      }
    case clientConstant.UPDATE_C_NOTE_F:
      return {
        ...state,
      }

    //ADD_NOTE
    case clientConstant.ADD_C_NOTE_R:
      return {
        ...state,
      }
    case clientConstant.ADD_C_NOTE_S:
      return {
        ...state,
        note: {}
      }
    case clientConstant.ADD_C_NOTE_F:
      return {
        ...state,
      }

    //UPDATE_NOTE REDUCER
    case clientConstant.UPDATE_C_NOTE_REDUCER:
      return {
        ...state,
        newNote: action.data,
        note: { ...action.data }
      }


    //GET_ALL NOTES
    case clientConstant.GET_ALL_C_NOTES_R:
      return {
        ...state,
        note: {
          content_status: null
        }
      }
    case clientConstant.GET_ALL_C_NOTES_S:
      return {
        ...state,
        notes: action.data.data,
      }
    case clientConstant.GET_ALL_C_NOTES_F:
      return {
        ...state,
        notes: initialState.notes,
      }

    //GET_ONE NOTE
    case clientConstant.GET_ONE_C_NOTES_R:
      return {
        ...state,
        note: {
          content_status: null
        }
      }
    case clientConstant.GET_ONE_C_NOTES_S:
      return {
        ...state,
        note: {
          content_status: 'loaded',
          ...action.data.data
        }
      }
    case clientConstant.GET_ONE_C_NOTES_F:
      return {
        ...state,
        note: initialState.note,
      }

    //Share Notes
    case clientConstant.SHARE_C_NOTE_R:
    case clientConstant.SHARE_C_NOTE_S:
    case clientConstant.SHARE_C_NOTE_F:
      return state

    case clientConstant.CLEAR_C_SELECTED_NOTE:
      return {
        ...state,
        note: initialState.note,
      }

    case clientConstant.UPDATE_C_NOTE_REDUCER_TITLE:
      return {
        ...state,
        newNote: { ...state.newNote, notes_title: action.data },
      }

    /****************************************************************TEAMS DETAILS************************************************************************************** */

    //GET PROJECT TEAM USERS
    case clientConstant.GET_C_TEAM_R:
      return {
        ...state,
      }
    case clientConstant.GET_C_TEAM_S:
      return {
        ...state,
        clientTeam: action.data.data,
      }
    case clientConstant.GET_C_TEAM_F:
      return {
        ...state,
        clientTeam: initialState.clientTeam,
      }

    case clientConstant.USER_C_EXISTING_INVITE_R:
    case clientConstant.USER_C_EXISTING_INVITE_S:
    case clientConstant.USER_C_EXISTING_INVITE_F:
      return state

    case clientConstant.USER_C_NEW_INVITE_R:
    case clientConstant.USER_C_NEW_INVITE_S:
    case clientConstant.USER_C_NEW_INVITE_F:
      return state

    case clientConstant.REMOVE_C_TEAM_USER_R:
    case clientConstant.REMOVE_C_TEAM_USER_S:
    case clientConstant.REMOVE_C_TEAM_USER_F:
      return state

    case clientConstant.DELETE_C_USER_FROM_AGENCY_R:
    case clientConstant.DELETE_C_USER_FROM_AGENCY_S:
    case clientConstant.DELETE_C_USER_FROM_AGENCY_F:
      return state

    // Update team users
    case clientConstant.GET_CA_TEAM_R:
      return {
        ...state,
      }
    case clientConstant.GET_CA_TEAM_S:
      return {
        ...state,
        teamMembers: action.data.data,
      }
    case clientConstant.GET_CA_TEAM_F:
      return {
        ...state,
        teamMembers: initialState.teamMembers
      }

    // update active team members
    case clientConstant.GET_CA_ACTIVE_TEAM_R:
      return {
        ...state,
      }
    case clientConstant.GET_CA_ACTIVE_TEAM_S:
      return {
        ...state,
        activeTeamMembers: action.data.data,
      }
    case clientConstant.GET_CA_ACTIVE_TEAM_F:
      return {
        ...state,
        activeTeamMembers: initialState.teamMembers
      }


    //UPDATE INVITE USR
    case clientConstant.UPDATE_C_TEAM_USERS:
      return {
        ...state,
        inviteUser: action.data,
      }

    //USER INVITATION ACCEPT
    case clientConstant.NEW_C_USER_ACCEPT_R:
      return {
        ...state,
      }
    case clientConstant.NEW_C_USER_ACCEPT_S:
      return {
        ...state,
        invitation: action.data.data,
      }
    case clientConstant.NEW_C_USER_ACCEPT_F:
      return {
        ...state,
        invitation: initialState.invitation
      }

    /*********************************************TASK DETAILS**************************************************************************** */
    //create new task
    case clientConstant.CREATE_NEW_C_TASK_R:
      return state
    case clientConstant.CREATE_NEW_C_TASK_S:
      return {
        ...state,
        newTask: {}
      }
    case clientConstant.CREATE_NEW_C_TASK_F:
      return state

    //get all tasks
    case clientConstant.GET_ALL_C_TASK_R:
      return {
        ...state,
      }
    case clientConstant.GET_ALL_C_TASK_S:
      return {
        ...state,
        tasks: action.data.data
      }
    case clientConstant.GET_ALL_C_TASK_F:
      return {
        ...state,
        tasks: initialState.tasks
      }

    //get current tasks
    case clientConstant.GET_CURRENT_C_TASK_R:
      return {
        ...state,
      }
    case clientConstant.GET_CURRENT_C_TASK_S:
      return {
        ...state,
        currentTask: action.data.data
      }
    case clientConstant.GET_CURRENT_C_TASK_F:
      return {
        ...state,
        currentTask: initialState.currentTask
      }

    case clientConstant.DEL_CURRENT_C_TASK_R:
    case clientConstant.DEL_CURRENT_C_TASK_S:
    case clientConstant.DEL_CURRENT_C_TASK_F:
      return state

    case clientConstant.UPDATE_CURRENT_C_TASK_R:
    case clientConstant.UPDATE_CURRENT_C_TASK_S:
    case clientConstant.UPDATE_CURRENT_C_TASK_F:
      return state


    case clientConstant.UPDATE_CURRENT_C_TASK:
      return {
        ...state,
        currentTask: action.data
      }

    //UPDATE TASk REDUCER
    case clientConstant.UPDATE_C_CREATE_TASK:
      return {
        ...state,
        newTask: action.data,
      }

    case clientConstant.GET_C_TASKS_FILTER_R:
      return {
        ...state,
      }
    case clientConstant.GET_C_TASKS_FILTER_S:
      return {
        ...state,
        filters: action.data.data
      }
    case clientConstant.GET_C_TASKS_FILTER_F:
      return {
        ...state,
        filters: initialState.filters
      }

    case clientConstant.COMPLETE_TASK_R:
    case clientConstant.COMPLETE_TASK_S:
    case clientConstant.COMPLETE_TASK_F:
      return state

    case clientConstant.GET_C_TASK_TAGS_R:
      return {
        ...state,
        taskTags: []
      }
    case clientConstant.GET_C_TASK_TAGS_S:
      return {
        ...state,
        taskTags: action.data.data
      }
    case clientConstant.GET_C_TASK_TAGS_F:
      return {
        ...state,
        taskTags: []
      }

    case clientConstant.CREATE_C_TASK_NOTE_R:
      return {
        ...state
      }
    case clientConstant.CREATE_C_TASK_NOTE_S:
      return {
        ...state,
        currentTaskNotes: action.data.data
      }
    case clientConstant.CREATE_C_TASK_NOTE_F:
      return {
        ...state
      }
    case clientConstant.GET_C_TASK_NOTE_R:
      return {
        ...state,
        currentTaskNotes: []
      }
    case clientConstant.GET_C_TASK_NOTE_S:
      return {
        ...state,
        currentTaskNotes: action.data.data
      }
    case clientConstant.GET_C_TASK_NOTE_F:
      return {
        ...state,
        currentTaskNotes: []
      }

    case clientConstant.DELETE_TASK_NOTE_R:
      return {
        ...state
      }
    case clientConstant.DELETE_TASK_NOTE_S:
      return {
        ...state,
        // currentTaskNotes: action.data.data
      }
    case clientConstant.DELETE_TASK_NOTE_F:
      return {
        ...state
      }

    case clientConstant.UPATE_CLIENT_TYPE:
      return {
        ...state,
        current_type: action.data.type
      }
    case clientConstant.GET_CLIENT_INVITE_LIST_R:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          inviteList: {
            ...state.clientOpenInvites.inviteList,
            loading: true,
            invites: []
          }
        }
      }
    case clientConstant.GET_CLIENT_INVITE_LIST_S:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          inviteList: {
            ...state.clientOpenInvites.inviteList,
            loading: false,
            invites: [
              ...(state.clientOpenInvites.inviteList.invites || []),
              ...(action.data.data.invites || [])
            ],
            page: action?.data?.data?.page || initialState?.clientOpenInvites?.inviteList?.page
          }
        }
      }
    case clientConstant.GET_CLIENT_INVITE_LIST_F:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          inviteList: {
            ...state.clientOpenInvites.inviteList,
            loading: false,
            invites: [],
          }
        }
      }
    case clientConstant.GET_CLIENT_INVITE_R:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          editInvite: {}
        }
      }
    case clientConstant.GET_CLIENT_INVITE_S:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          editInvite: action?.data?.data
        }
      }
    case clientConstant.GET_CLIENT_INVITE_F:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          editInvite: {}
        }
      }
    case clientConstant.GET_INVITE_AGENCY_INFO_R:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          agencyInfo: {}
        }
      }
    case clientConstant.GET_INVITE_AGENCY_INFO_S:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          agencyInfo: action?.data?.data
        }
      }
    case clientConstant.GET_INVITE_AGENCY_INFO_F:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          agencyInfo: {}
        }
      }
    case clientConstant.GET_FILTER_LIST_R:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          filters: {
            clients: [],
            invites: [],
            users: []
          }
        }
      }
    case clientConstant.GET_FILTER_LIST_S:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          filters: action?.data?.data
        }
      }
    case clientConstant.GET_FILTER_LIST_F:
      return {
        ...state,
        clientOpenInvites: {
          ...state.clientOpenInvites,
          filters: {
            clients: [],
            invites: [],
            users: []
          }
        }
      }
    default:
      return state
  }
}