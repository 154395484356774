import React from 'react';
import PropTypes from 'prop-types';
import DateTimeDisplay from './DateTimeDisplay';

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
        <div className="show-counter">
            <DateTimeDisplay value={days} type={days > 1 ? 'Days' : 'Day'} isDanger={false} />
            <p>:</p>
            <DateTimeDisplay value={hours} type={hours > 1 ? 'Hours' : 'Hour'} isDanger={false} />
            <p>:</p>
            <DateTimeDisplay value={minutes} type={minutes > 1 ? 'Mins' : 'Min'} isDanger={false} />
            <p>:</p>
            <DateTimeDisplay value={seconds} type={seconds > 1 ? 'Seconds' : 'Second'} isDanger={false} />
        </div>
    );
};


// default props
ShowCounter.defaultProps = {
    classes: {},
};

// prop types
ShowCounter.propTypes = {
    classes: PropTypes.object,
};

/** Export Component */
export default ShowCounter;