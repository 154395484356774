// Firebase Cloud Messaging Configuration File. 
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

import { app_credentials } from "./config/app_credentials";
const { firebase: fireBaseConfig, fcm_vapidKey } = app_credentials;

// Initialize Firebase
const app = initializeApp(fireBaseConfig);
const messaging = getMessaging(app);


// export const requestFirebaseNotificationPermission = () =>
//   new Promise((resolve, reject) => {
//     Notification.requestPermission().then((permission) => {
//       if (permission === "granted") {
//         getToken(messaging, { vapidKey: fcm_vapidKey })
//           .then((firebaseToken) => {
//             if (firebaseToken) {
//               resolve(firebaseToken);
//             } else {
//               resolve(null);
//             }
//           })
//           .catch((err) => {
//             reject(err);
//           });
//       } else {
//         console.log("Do not have permission!");
//       }
//     });
//   });

// Handle incoming messages. Called when:
// - a message is received while the app has focus
export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    })
  });

export async function requestFirebaseNotificationPermission() {
  console.log('Requesting notification permission...!')
  const permission = await Notification.requestPermission();

  if (permission === "granted") {
    const firebaseToken = await getToken(messaging, { vapidKey: fcm_vapidKey });
    return firebaseToken;
  } else {
    console.log("Do not have permission!");
  }
}