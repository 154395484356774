export function addOrRemoveDoc(cur_state, payload, isAdd = 1) {
    const { path, type, job_id, count } = payload
    let new_state = { ...cur_state, details: { ...cur_state.details } }
    let folder_ids = path?.split('/')

    // push to flow or clients
    if (job_id != 3) {
        folder_ids?.splice(
            folder_ids.indexOf('root') + 1,
            0,
            `root_${job_id}`
        )
    }

    // remove last empty one
    folder_ids?.pop()
    const total_ids = folder_ids?.length
    const isDoc = type === 1 || type === 0

    // for delete
    let deletedCount = type == 1 ? 1 : count

    for (let i = 0; i < total_ids; i++) {
        const folder_id = folder_ids[i]
        if (!new_state.details[folder_id]) {
            new_state.details[folder_id] = []
        }

        // increase or decrease count
        // if isAdd 1 - increase, 0 - decrease
        if (isDoc) {
            new_state.details[folder_id] = new_state.details[folder_id].map(m => {
                if ((m.type === 0 && folder_ids.indexOf(`${m.id}`) > -1)
                    || m.id === 'alldocs') {
                    if (isAdd && type === 1) {
                        m.count = (m.count || 0) + 1
                    } else {
                        m.count = (m.count || 0) - (deletedCount || 0)
                    }
                    if (m.count < 0) {
                        m.count = 0
                    }
                }
                return m
            })
        }

        // push to last
        if (i === (total_ids - 1)) {
            if (isAdd) {
                // must add in alldocs if doc added in alldocs
                let fid = folder_id === 'root' && payload.type === 1 ? 'alldocs' : folder_id
                if (payload.type === 1 || payload.parent_doc_id) {
                    new_state.details[fid] =
                    new_state.details[fid]!==undefined?[
                            { ...payload },
                            ...new_state.details[fid]
                        ]:[{...payload}]
                } else {
                    new_state.details[fid] =
                        [
                            ...new_state.details[fid],
                            { ...payload }
                        ]
                }

            } else {
                
                new_state.details[folder_id] = (new_state.details[folder_id] || []).filter(
                    f => `${f.id}` != `${payload.id}`
                )

                if (new_state.details['alldocs']) {
                    new_state.details['alldocs'].splice(
                        new_state.details['alldocs'].findIndex(f => `${f.id}` === `${payload.id}`),
                        1
                    )
                }
            }
        }
    }

    // if delete editor remove content from editor
    
    if (isAdd === 0) {
        if (new_state && new_state.editor) {
            if (`${new_state.editor.id}` == `${payload.id}`) {
                new_state.editor = {
                    id: null,
                    content: ""
                }
            }
        }

        // delete root selection
        if (new_state.selected_root_folder
            && new_state.selected_root_folder.id) {
            if (`${new_state.selected_root_folder.id}` === `${payload.id}`) {
                new_state.selected_root_folder = {
                    id: null,
                    path: null
                }
            }
        }

        // delete selected sub folder selection
        if (new_state.selected_folders
            && new_state.selected_folders[payload.id]) {
            delete new_state.selected_folders[payload.id]
        }
    }

    return new_state
}

export function updateDocCount(cur_state, payload, isMove = 0) {
    const { path, type, job_id, current_docPath = '', current_job_id } = payload
    let new_state = { ...cur_state }
    let folder_ids = path?.split('/')

    // push to flow or clients
    if (job_id != 3) {
        folder_ids?.splice(
            folder_ids.indexOf('root') + 1,
            0,
            `root_${job_id}`
        )
    }

    // remove last empty one
    folder_ids?.pop()
    const total_ids = folder_ids?.length
    const isDoc = type === 1 || type === 0


    for (let i = 0; i < total_ids; i++) {
        const folder_id = folder_ids[i]
        if (!new_state.details[folder_id]) {
            new_state.details[folder_id] = []
        }

        // increase or decrease count
        // if isAdd 1 - increase, 0 - decrease
        if (isDoc) {
            new_state.details[folder_id] = new_state.details[folder_id].map(m => {
                if ((m.type === 0 && folder_ids.indexOf(`${m.id}`) > -1)
                    || m.id === 'alldocs') {
                        m.count = (m.count || 0) + 1
                        if (m.count < 0) {
                            m.count = 0
                        }
                }
                return m
            })
        }
    }

    //If move docs have to add and subtract
    if(isMove) {
        let current_folderids = current_docPath?.split('/')
        // remove last empty one
        current_folderids?.pop()
        const current_total_ids = current_folderids?.length
        const isDoc = type === 1 || type === 0

            // push to flow or clients
        if (current_job_id != 3) {
            current_folderids?.splice(
                current_folderids.indexOf('root') + 1,
                0,
                `root_${current_job_id}`
            )
        }

        for (let i = 0; i < current_total_ids; i++) {
            const folder_id = current_folderids[i]
            if (!new_state.details[folder_id]) {
                new_state.details[folder_id] = []
            }
    
            // increase or decrease count
            // decrease the folder count after moved
            if (isDoc) {
                new_state.details[folder_id] = new_state.details[folder_id].map(m => {
                    if ((m.type === 0 && current_folderids.indexOf(`${m.id}`) > -1)
                        || m.id === 'alldocs') {
                            m.count = (m.count || 0) - 1
                            if (m.count < 0) {
                                m.count = 0
                            }
                    }
                    return m
                })
            }
        }
    }

    return new_state
}

export function handleDelete(cur_state, payload) {
    const { id, path, type, job_id } = payload;
    let new_state = { ...cur_state };
    const updateParentCount = (ids, childId, count) => {
        ids.pop();
        let parentId = ids[ids.length - 1];
        let targetIndex = new_state.details[parentId].findIndex(e => `${e.id}` === childId);
        if (targetIndex > -1) {
            new_state.details[parentId][targetIndex].count -= count;
        }
        if (ids.length > 1) {
            updateParentCount(ids, parentId, count);
        }
    }
    if (path) {
        const folder_ids = path.split('/')

        // push to flow or clients
        if (job_id != 3) {
            folder_ids.splice(
                folder_ids.indexOf('root') + 1,
                0,
                `root_${job_id}`
            )
        }

        folder_ids.pop()
        const parentId = folder_ids[folder_ids.length - 1]
        const targetIndex = (new_state.details[parentId] || [])
            .findIndex(e => `${e.id}` === `${id}`);
        if (targetIndex > -1) {
            let count = 1;
            if (new_state.details[parentId][targetIndex].type === 0) {
                count = new_state.details[parentId][targetIndex].count;
            }
            new_state.details[parentId] = new_state.details[parentId]
                .filter(e => `${e.id}` !== `${id}`);
            updateParentCount(folder_ids, parentId, count);
        }
    }
    return new_state;
}

/**
 * A helper method to update doc or folder title
 * @param {*} cur_state 
 * @param {*} payload 
 */
export function updateDoc(cur_state, payload) {
    const { id, path, title, modified_on } = payload
    let new_state = { ...cur_state }
    const { selected_root_folder } = new_state
    const folder_ids = path.split('/')
    // remove last empty one
    folder_ids.pop()
    const folder_id = folder_ids[folder_ids.length - 1]
    new_state.details[folder_id] = (new_state.details[folder_id] || [])
        .map((m) => {
            if (`${m.id}` === `${id}`) {
                m.title = title;
                m['created_on'] = modified_on;
            }
            return m
        })

    // change the doc name in all docs
    if (selected_root_folder && selected_root_folder?.id === "alldocs") {
        if (new_state.details['alldocs']) {
            new_state.details['alldocs'] = (new_state.details['alldocs'] || [])
                .map((m) => {
                    if (`${m.id}` === `${id}`) {
                        m.title = title;
                        m['created_on'] = modified_on;
                    }
                    return m
                })
        }
    }

    return new_state
}