import React, { useEffect, useState } from 'react'
import { Avatar, Grid, IconButton, InputAdornment, Button } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SendIcon from '@material-ui/icons/Send';
import { CloseIcon, EditIcon, DownloadIcon, TimeBlockIcon, BoardTaskClockIcon, BoardsCommentSectionSendIcon } from '../../assets/svg';
import DescriptionIcon from '@material-ui/icons/Description';

import { Typography, TextField } from '../';

import './style.css';

import { formatAttachments, convertToFormData } from '../../utils'
import { iconSetArray } from '../../config/constants';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateAction } from '../../redux/actions';
import { slideShowConstant } from '../../redux/constants';


const AttachmentDisplay = ({ img, index, handleRemoveAttachment, fileType, isClose, handlePreviewOpen }) => {
    let supportMediaType = ["jpeg", "jpg", "png", "gif"];

    return (
        <Grid className='taskNotesLinks CAttachBox' item xs={12} sm={4} key={index} onClick={() => {
           !isClose && handlePreviewOpen(index)
        }}>
            <div className='CAttachmentInner'>
                {
                    supportMediaType.includes(fileType) ?
                        <img
                            alt={"preview"}
                            src={img.previewFile}
                            className={"CAttachImg"}
                        /> :
                        iconSetArray.includes(fileType) ?
                            <a href={img.previewFile}>
                                <img className='docImage' src={`./icons/${fileType}.svg`} alt={fileType} />
                            </a> :
                            <a href={img.previewFile}>
                                <DescriptionIcon className='docImage' />
                            </a>
                }
                {
                    isClose && (
                        <IconButton style={{ top: 0, right: 0, zIndex: 1 }} className='CCloseIcon'
                            onClick={handleRemoveAttachment}
                        >
                            <CloseIcon />
                        </IconButton>
                    )
                }

               
                {/* {<IconButton
                    className="CDownloadIcon"
                    onClick={() => {
                        window.open(img.previewFile)
                    }}
                >
                    <DownloadIcon />
                </IconButton>} */}
            </div>
        </Grid>
    )
}

export function CommentBox(props) {
    const { classes, label, comment, attachmentExt, onSend, onError,
        comment_id, data, noaccess, onDelete } = props
    const [noteMsg, setNoteMsg] = useState("");
    const [newAttachments, setNewAttachments] = useState([])
    const [edit, setEdit] = useState(comment_id ? false : true)
    const [openEdit, setOpenEdit] = useState(false)

    const dispatch = useDispatch()


    const [fileType, setFileType] = useState();

    const attachments = formatAttachments(data.attachments, 2) || []
    const hasAttachments = newAttachments.length > 0 || attachments.length > 0
    const canCommentSent = hasAttachments || noteMsg != ""

    const [prevAttachments, setPrevAttachments] = useState(attachments)


    useEffect(() => {
        setNoteMsg(comment)
    }, [comment])

    const onChange = (e) => {
        e.preventDefault();
        const files = e.target.files;
        let invalidCount, maxSizeCount = 0;
        let acceptedFiles = [];

        if (files && files.length > 0) {
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                console.log(file,"file")
                if (!file.type.includes('video') && !file.type.includes('image') && !file.type.includes('application')) { invalidCount++ }
                else {
                    file["previewFile"] = URL.createObjectURL(file);
                    acceptedFiles.push(file);
                }

                //Doctype
                let fileExt = file.name.split('.').pop();
                setFileType(fileExt);
            }

            if (acceptedFiles.length > 0) {
                setNewAttachments([...newAttachments, ...acceptedFiles])
                handleSubmit(acceptedFiles)
            }
        }
    }

    const handleRemoveAttachment = (index) => {
        const newFiles = prevAttachments.map(it => it) || []
        newFiles.splice(index, 1)
        setPrevAttachments(newFiles)
        const formData = convertToFormData({
            attachments: newFiles
        })
        onSend(formData, comment_id)
    }

    const handleSubmit = (newFiles = null) => {
        const formData = convertToFormData({
            comment: noteMsg,
            attachments: attachments,
            attachmentsNew: newFiles ? newFiles : newAttachments,
        })
        onSend(formData, comment_id)
        if (!comment_id) {
            setNoteMsg("")
            
        } else {
            setEdit(false)
            setOpenEdit(false)
        }

        setNewAttachments([])
        setPrevAttachments([])
    }

    /**task attachment preview */
    const handlePreviewOpen = (index) => {
        const Medias = prevAttachments
        let MediaArr = [];
        Medias.forEach(ele => {
            let data = ele;
            MediaArr = data && data.type && data.type.includes("application") ? MediaArr : [...MediaArr, data];
        });
        dispatch(updateAction.update(slideShowConstant.SLIDE_SHOW_FILE_UPDATE, { mediaArray: MediaArr, index, directDownload: true }))
    }



    return (
        <Grid className='mt-1'>
            {
                label && (
                    <Typography variant="h6" className={"pb5"}>
                        {label}
                    </Typography>
                )
            }
            <Grid container>
                <Grid item xs={12} className={"commentsInput pl-2 pr-1"} style={{ display: 'flex' }}>
                    <Avatar
                        alt={data.user_name}
                        src={data && data.hasOwnProperty("avatar_filename") && data.avatar_filename ? data.avatar_filename : "/broken-image.jpg"}
                    />
                    {
                        edit ?

                            <TextField
                                id={`job_status`}
                                name={`job_status`}
                                margin="none"
                                className="pl-2 w-100 test"
                                rowsMin={4}
                                multiline
                                placeholder={'Type comments here....'}
                                value={noteMsg}
                                disabled={noaccess || !edit}
                                onChange={(e) => setNoteMsg(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {
                                            edit && (
                                                <IconButton variant="contained" component="label">
                                                    <AttachFileIcon fontSize={"small"} style={{ fill: '#7e84a3' }} />
                                                    <input
                                                        type="file"
                                                        accept={attachmentExt}
                                                        id="notesAttachment"
                                                        name="notesAttachments[]"
                                                        onChange={onChange}
                                                        style={{ display: 'none' }}
                                                        multiple
                                                    />
                                                </IconButton>
                                            )
                                        }
                                        {
                                            edit && (
                                                <IconButton
                                                    onClick={handleSubmit}
                                                    disable={true}
                                                    disabled={!canCommentSent}
                                                >
                                                    <BoardsCommentSectionSendIcon style={noteMsg === "" ? { fill: '#d3d3d3' } : { fill: '#7e84a3' }} />
                                                </IconButton>
                                            )
                                        }
                                        {
                                            !edit && noaccess === false && (
                                                <IconButton
                                                    onClick={() => {
                                                        setEdit(true)
                                                    }}
                                                    disable={true}
                                                >
                                                    <EditIcon style={{ fill: '#7e84a3' }} />
                                                </IconButton>
                                            )
                                        }
                                    </InputAdornment>
                                }}
                            />
                            :
                            <Grid container direction='column' xs={12}>
                                <Grid style={{
                                    gap: '25px'
                                }} item className='dflex alignCenter'>
                                    <Typography color="#0F172A" className='pl-2 fw-700'>{data.user_name}</Typography>
                                    <Typography className='alignCenter'><BoardTaskClockIcon /><span className='pl-1'>{(data.modified_on && data.modified_on !== '0000-00-00 00:00:00') ? moment(data?.modified_on).fromNow() : moment(data?.created_on).fromNow()}</span></Typography>
                                    <Grid>
                                        {
                                            !openEdit ? <Typography onClick={() => {
                                                setOpenEdit(!openEdit)
                                            }} color="textSecondary" className='pointer fw-500'>Edit</Typography> :
                                                <Typography onClick={handleSubmit} color="textSecondary" className='pointer fw-500'>Save</Typography>
                                        }
                                    </Grid>
                                    <Grid>
                                        {
                                            !openEdit ?
                                                <Typography onClick={() => {
                                                    onDelete(comment_id)
                                                }} color="textSecondary" className='pointer fw-500'>Delete</Typography> :
                                                <Typography onClick={() => {
                                                    setOpenEdit(false)
                                                }} color="textSecondary" className='pointer fw-500'>Cancel</Typography>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid>
                                    {
                                        !openEdit ? <Typography color="textSecondary" className='pl-2 pt-1 fw-400'>{noteMsg}</Typography> :
                                            <TextField
                                                id={`job_status`}
                                                name={`job_status`}
                                                margin="none"
                                                className="pl-2 w-100 mt-1"
                                                rowsMin={4}
                                                multiline
                                                placeholder={'Type comments here....'}
                                                value={noteMsg}
                                                disabled={noaccess || !openEdit}
                                                onChange={(e) => setNoteMsg(e.target.value)}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">

                                                        <IconButton variant="contained" component="label">
                                                            <AttachFileIcon style={{ fill: '#7e84a3' }} />
                                                            <input
                                                                type="file"
                                                                accept={attachmentExt}
                                                                id="notesAttachment"
                                                                name="notesAttachments[]"
                                                                onChange={onChange}
                                                                style={{ display: 'none' }}
                                                                multiple
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }}
                                            />
                                    }

                                </Grid>
                            </Grid>

                    }
                </Grid>
                <Grid item xs={12} className="mt-1" style={{ paddingLeft: '60px' }}>
                    <Grid container>
                        {
                            newAttachments.map((img, index) => {
                                return (
                                    <AttachmentDisplay
                                        fileType={img?.name ? img?.name.split('.').pop() : ""}
                                        key={index}
                                        classes={classes}
                                        img={img}
                                        index={index}
                                        handleRemoveAttachment={() => {
                                            handleRemoveAttachment(index)
                                        }}
                                        isClose={true}
                                    />
                                )
                            })
                        }
                        {
                            attachments.map((img, index) => {
                                return (
                                    <AttachmentDisplay
                                        fileType={img.url.split('.').pop()}
                                        key={index}
                                        classes={classes}
                                        img={{
                                            previewFile: img.url
                                        }}
                                        index={index}
                                        handleRemoveAttachment={() => {
                                            handleRemoveAttachment(index)
                                        }}
                                        isClose={openEdit}
                                        handlePreviewOpen={handlePreviewOpen}
                                    />
                                )
                            })
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

CommentBox.defaultProps = {
    comment: "",
    attachmentExt: "image/*,video/*,.pdf,.csv,.doc,.docx,.xls,.xlsx",
    comments: [],
    onSend: () => { },
    onError: () => { },
    onDelete: () => { }
}