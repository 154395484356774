const style = (theme) => ({
    attendee: {
        width: "100%",
        height: "100%"
    },
    cover:{
        objectFit: "cover"
    },
    contain:{
        // Added important as agora adds style tag throught js
        objectFit: "contain !important",
    },
    RemoteVideoStyle: {
        position: 'relative',
        overflow: 'hidden'
    },
    ScreenshareStyle: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        position: 'relative',
        padding: "4px",
        "@media screen and (max-width: 768px)": {
            width: "100% !important"
        }
    },
    RemoteScreenShareVideo: {
        width: '90vw',
        height: '90vh',
        position: 'fixed',
        objectFit: "contain",
        right: "3%"
    },
    UnpinUserMuteContainer: {
        width: "119.97px",
        height: "106.256px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        /* border: 1px solid black; */
        background: '#574141'
    },
    UnPinUserMuteAvatar: {
        width: "100px",
        height: "100px",
        position: "absolute",
        background: "#2563EB",
        fontWeight: 600,
        fontSize: "50px",
        lineHeight: "100px"
    },
    AudioMuteIcon: {
        position: "absolute",
        top: "10px",
        right: '10px',
        background: "rgb(0 0 0 / 32%)",
        borderRadius: "10px",
        padding: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1
    },
    AudioPlayIcon:{
        position: "absolute",
        top: "10px",
        right: '10px',
        zIndex: 1
    },
    remoteUserName: {
        bottom: '6px',
        /* width: auto; */
        /* height: 50px; */
        position: 'absolute',
        backgroundColor: '#0000004A',
        borderRadius: '12px',
        padding: '4px 8px',
        right: '10px',
        zIndex: 999
    },
    MuteVideoContainer: {
        position: "absolute",
        left: "0",
        top: "0",
        height: "100%",
        width: "100%",
        padding: "2px",
        backgroundColor: "black",
        zIndex: 1
    },
    avatarContainer: {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#ffffff20",
        borderRadius : "10px"
    },
    highlight: {
        border: "2px solid blue"
    },
    username :{
        bottom: '6px',
        /* width: auto; */
        /* height: 50px; */
        position: 'absolute',
        backgroundColor: ' #0000004A',
        borderRadius: '12px',
        padding: '4px',
        right: '10px',
        zIndex: 999
    },
    userAvatar :{
        width :"46px",
        height: "46px",
        position: 'absolute'
    },
    screenShareVideo:{
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        transform: 'none !important'
    },
    "@media screen and (max-width: 900px)": {
        UnPinUserMuteAvatar: {
            width: "100px !important",
            height: "100px !important",
            fontSize: '30px !important'
        }
    },
    "@media screen and (max-width: 599px)": {
        UnPinUserMuteAvatar: {
            width: "64px !important",
            height: "64px !important",
            fontSize: '30px !important'
        }
    }

})

export default style