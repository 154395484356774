import React from "react";
import { Avatar, Grid, Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import AvatarGroup from '@material-ui/lab/AvatarGroup';

// Style
import Style from './style'
import { CalendarIconNew, ChartBoardIcon, ExternalClientIcon, TaskIdICon } from "../../assets/svg";
import { TitleAvatar } from "../TitleAvatar";
import { Button } from "../Button";

function ChatTaskComp(props) {

    const { classes } = props;
  return (
    <Grid className={`${classes.cardRoot} cardRoot`}>
      <Grid className="dflex alignCenter justifyFlexEnd">
        <TaskIdICon />
      <Typography variant="h6" className="fs-10 pl5" align="right">Task ID : 234566</Typography>
      </Grid>
      <Grid className="tagColor" style={{background: '#FFA043'}}></Grid>
        <Typography variant="body1" className="fw-500 pb8" align="left">Design Review</Typography>
        <Grid align="left">
          <Grid className="statusChip" style={{ background: '#2563EB21', color: '#2563EB' }}>In progress</Grid>
        </Grid>
        <Grid className="dflex alignCenter justifyBetween mb-1">
          <Grid>
            <Typography variant="body1" className="fs-10 pb-1 secondary2" color="textSecondary">ASSIGNEED TO</Typography>
            <AvatarGroup max={3}>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
              <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
              <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
            </AvatarGroup>
          </Grid>
          <Grid>
          <Typography variant="body1" className="fs-10 pb-1 secondary2" color="textSecondary">PRIORITY :</Typography>
          <Grid className="priorityChip" style={{ background: '#FBAD3E' }}>In progress</Grid>
          </Grid>
        </Grid>
        <Grid className="dateShow">
          <span className="pl5">Due on : </span>
          <CalendarIconNew />
          <span className="pl5">Nov 20</span>
        </Grid>
        <Grid className="dflex alignCenter pb-1">
          <Typography variant="body1" className="fs-12 fw-500 pr-1 secondary2" color="textSecondary">GROUP : </Typography>
          <Typography variant="body1" className="fs-12 " color="">Clients<span className="pl5"><ExternalClientIcon /></span></Typography>
        </Grid>
        <Grid className="dflex alignCenter pb-1">
          <Typography variant="body1" className="fs-12 fw-500 secondary2" color="textSecondary">NAME : </Typography>
          <Typography variant="body1" className="fs-12 dflex alignCenter" color=""><span className="pl-1"><TitleAvatar /></span>AF Product</Typography>
        </Grid>
        <Grid className="dflex alignCenter pb15">
          <Typography variant="body1" className="fs-12 fw-500 pr-1 secondary2" color="textSecondary">TYPE : </Typography>
          <Typography variant="body1" className="fs-12" color=""><ChartBoardIcon /><span className="pl5">Boards</span></Typography>
        </Grid>
        <Button variant="contained" color="primary" className="clickHereBtn" fullWidth>Click Here</Button>
    </Grid>
  )
}

ChatTaskComp.defaultProps = {
};
ChatTaskComp.propTypes = {
    classes: PropTypes.object,
};

// export component
export const ChatTask = withStyles(Style)(ChatTaskComp);