const styles = (theme) => ({
  uploadCard: {
    borderRadius: '6px',
    border: `1px dashed ${theme.palette.secondary.main}`,
    height: '180px',
    background: theme.palette.containers.bg26,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fileInputDropZone: {
    display: "flex",
    flexDirection: "column",
    width: "80% !important",
    height: "65% !important",
    alignItems: "center",
    justifyContent: "center",

    "& .thumb_box": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      width: "100%",
      height: "100%",
      "& .thumb_box_item": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        "& img": {
          width: "auto", height: "100%"
        }
      }
    }
  }
});

export default styles;
