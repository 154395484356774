// Import API Helper
import { deleteRequest, getRequest, postRequest, putRequest } from "../../utils/apiHelper";

async function getWorkspaces() {
    const response = await getRequest("wsm");
    return response;
}

async function searchAgencies(params) {
    const response = await postRequest(`wsm/search/${params.id}`, params);
    return response;
}

async function inviteAgency(params) {
    const response = await postRequest("wsm", params);
    return response;
}

async function acceptShareInvite(invitation_id, body){
    const response = await putRequest(`wsm/${invitation_id}/accept`, body);
    return response;
}

async function rejectShareInvite(invitation_id, body) {
    const response = await putRequest(`wsm/${invitation_id}/reject`, body);
    return response;
}

async function getSharedUsers(shared_id, shared_type, body) {
    const response = await getRequest(`wsm/share/${shared_id}/${shared_type}`,body);
    return response;
}

async function updateFlowUsers(invitation_id, body) {
    const response = await putRequest(`wsm/${invitation_id}/update_user_permission`, body);
    return response;
}

async function stopSharing(invitation_id, body){
    const response = await putRequest(`wsm/${invitation_id}/stop_share`,body);
    return response
}

async function cancelShareInvite(invitation_id){
    const response = await putRequest(`wsm/${invitation_id}/cancel`);
    return response
}

async function deleteUsers(userId,invitation_id) {
    const response = await deleteRequest(`wsm/${invitation_id}/${userId}`);
    return response;
}

export const workspaceService = {
    getWorkspaces,
    searchAgencies,
    inviteAgency,
    acceptShareInvite,
    rejectShareInvite,
    getSharedUsers,
    stopSharing,
    updateFlowUsers,
    cancelShareInvite,
    deleteUsers
};