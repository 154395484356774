import { profileConstant, chatv2Constant, pageBuilderConstant } from "../constants";

const initialState = {
  loading: false,
  appConfig: {
    isCustomDomain: false,
    title: "Flozy",
    description: "",
    favicon: "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/1711086718231_favicon.ico",
    logo: "",
    icon: "",
    theme: "light",
    light: {
      logo: "",
      icon: ""
    },
    dark: {
      logo: "",
      icon: ""
    },
    loading: false
  },
  profile: {},
  profileDetailCount: {},
  profileNameList: [],

  teamUsers: [],
  teamClients: [],
  inviteUser: [],
  copyTeamInviteLink: "",
  invitation: false,
  invitationData: null,

  notifications_n: [],
  notifications_count: {
    allNewMsgCount: 0,
    newMsgData: {
      jobMsgCount: null,
      clientMsgCount: null,
      teamMsgCount: null,
    },
    notificationMsgCount: 0,
  },
  invites_n: [],
  tasks_n: [],
  invoice_n: [],
  funnel_n: [],
  agency_notification: {},
  global: [],
  newMessage: {},
  newInvite: {
    inviteUser: [],
    userRole: null,
    inviteFlows: [],
    inviteInternalClients: [],
    inviteExternalClients: [],
    flowFullAccess: false,
    internalClientFullAccess: false,
    externalClientFullAccess: false,
    inviteMessage: null,
  },
  newClientInvite: {
    inviteUser: [],
    inviteInternalClients: [],
    inviteExternalClients: [],
    internalClientFullAccess: false,
    externalClientFullAccess: false,
    inviteMessage: null,
  },
  projects: [],
  internal_clients: [],
  external_clients: [],
  current_payment_link: [],
  user_payment_links: [],

  domainDetails: {},

  agencyEmailConfig: {
    authentication_type: 2,
    service: "other"
  },
  authURL: '',
  configuredApps: [],
  configuredAppsInfo: [],
  applicationApps: [],
};

export default function profile(state = initialState, action) {
  switch (action.type) {

    //Application Config For White Labeled Agencies
    case profileConstant.GET_APP_CONFIG_R:
      return {
        ...state,
        appConfig: {
          ...initialState.appConfig,
        },
      };

    //Application Config For White Labeled Agencies
    case profileConstant.GET_APP_CONFIG_S:
      return {
        ...state,
        appConfig: {
          ...action.data,
          loading: true
        },
      };

    //Application Config For White Labeled Agencies
    case profileConstant.GET_APP_CONFIG_F:
      return {
        ...state,
        appConfig: {
          ...initialState.appConfig,
          loading: false
        },
      };

    //update profile reducer
    case profileConstant.UPDATE_CREATE_PROFILE:
      return {
        ...state,
        profile: action.data,
      };

    case profileConstant.UPDATE_PROFILE_PUBLIC_PAGE:
      return{
        ...state,
        profile: action.data,
      }

    case profileConstant.CLEAR_PROFILE:
      return {
        ...state,
        profile: {},
      };

    case profileConstant.CREATE_PROFILE_R:
      return {
        ...state,
      };
    case profileConstant.CREATE_PROFILE_S:
      return {
        ...state,
        // profile: {}
      };
    case profileConstant.CREATE_PROFILE_F:
      return {
        ...state,
      };

    case profileConstant.GET_PROFILES_R:
      return {
        ...state,
      };
    case profileConstant.GET_PROFILES_S:
      return {
        ...state,
        profile: action.data.data,
      };
    case profileConstant.GET_PROFILES_F:
      return {
        ...state,
      };

    case profileConstant.UPDATE_AGENCY_DETAILS_R:
      return{
        ...state
      }
    case profileConstant.UPDATE_AGENCY_DETAILS_S:
      return{
        ...state,
        profile: action.data.data
      }
      case profileConstant.UPDATE_AGENCY_DETAILS_F:
      return {
        ...state,
      };

    case profileConstant.GET_PROFILES_NAMES_R:
      return {
        ...state,
        profileNameList: [],
      };
    case profileConstant.GET_PROFILES_NAMES_S:
      return {
        ...state,
        profileNameList: action.data.data,
      };
    case profileConstant.GET_PROFILES_NAMES_F:
      return {
        ...state,
        profileNameList: [],
      };

    case profileConstant.NAME_REDUCER_UPDATE:
      return {
        ...state,
        profileNameList: action.data,
      };

    //**************************************************TEAMS************************************************************* */

    case profileConstant.GET_PROJECT_USERS_R:
      return {
        ...state,
      };
    case profileConstant.GET_PROJECT_USERS_S:
      return {
        ...state,
        teamUsers: action.data.data,
      };
    case profileConstant.GET_PROJECT_USERS_F:
      return {
        ...state,
        teamUsers: [],
      };

    case profileConstant.GET_PROJECT_CLIENTS_R:
      return {
        ...state,
      };
    case profileConstant.GET_PROJECT_CLIENTS_S:
      return {
        ...state,
        teamClients: action.data.data,
      };
    case profileConstant.GET_PROJECT_CLIENTS_F:
      return {
        ...state,
        teamClients: [],
      };

    case profileConstant.UPDATE_ROLE:
      return {
        ...state,
        teamUsers: action.data,
      };

    case profileConstant.UPDATE_INVITE_USER:
      return {
        ...state,
        newInvite: action.data,
      };

    case profileConstant.UPDATE_CLIENT_INVITE:
      return {
        ...state,
        newClientInvite: action.data,
      };

    case profileConstant.INVITE_N_USERS_R:
      return {
        ...state,
        newInvite: initialState.newInvite,
      };
    case profileConstant.INVITE_N_USERS_S:
      return {
        ...state,
        inviteUser: [],
        newInvite: {
          ...initialState.newInvite,
          inviteMessage: action.data.inviteMessage,
        },
      };
    case profileConstant.INVITE_N_USERS_F:
      return {
        ...state,
        inviteUser: [],
        newInvite: initialState.newInvite,
      };

    case profileConstant.INVITE_N_CLIENT_USERS_R:
      return {
        ...state,
        newClientInvite: initialState.newClientInvite,
      };
    case profileConstant.INVITE_N_CLIENT_USERS_S:
      return {
        ...state,
        newClientInvite: {
          ...initialState.newInvite,
          inviteMessage: action.data.inviteMessage,
        },
      };
    case profileConstant.INVITE_N_CLIENT_USERS_F:
      return {
        ...state,
        newClientInvite: initialState.newClientInvite,
      };

    //User Invitation Details
    case profileConstant.G_NEW_USER_U_ACCEPT_R:
      return {
        ...state,
      };
    case profileConstant.G_NEW_USER_U_ACCEPT_S:
      return {
        ...state,
        invitationData: action.data.data,
      };
    case profileConstant.G_NEW_USER_U_ACCEPT_F:
      return {
        ...state,
        invitationData: initialState.invitation,
      };

    //USER INVITATION ACCEPT
    case profileConstant.NEW_USER_U_ACCEPT_R:
      return {
        ...state,
      };
    case profileConstant.NEW_USER_U_ACCEPT_S:
      return {
        ...state,
        invitation: action.data.data,
      };
    case profileConstant.NEW_USER_U_ACCEPT_F:
      return {
        ...state,
        invitation: initialState.invitation,
      };

    //**************************************************NOTIFICATIONS************************************************************* */

    case profileConstant.GET_NOTIFICATIONS_R:
      return {
        ...state,
      };
    case profileConstant.GET_NOTIFICATIONS_S:
      const prev_notification_ids = (state.notifications_n || []).map(
        (m) => `${m.id}`
      );
      return {
        ...state,
        notifications_n: [
          ...state.notifications_n,
          ...(action.data.data.result || []).filter((f) => {
            return prev_notification_ids.indexOf(`${f.id}`) < 0;
          }),
        ],
        notifications_count: action?.data?.data?.count || 0,
      };
    case profileConstant.GET_NOTIFICATIONS_F:
      return {
        ...state,
        notifications_n: [],
      };

    case profileConstant.GET_INVITES_R:
      return {
        ...state,
      };
    case profileConstant.GET_INVITES_S:
      return {
        ...state,
        invites_n: action.data.data,
      };
    case profileConstant.GET_INVITES_F:
      return {
        ...state,
        invites_n: [],
      };

    case profileConstant.GET_TASKS_R:
      return {
        ...state,
      };
    case profileConstant.GET_TASKS_S:
      return {
        ...state,
        tasks_n: action.data.data,
      };
    case profileConstant.GET_TASKS_F:
      return {
        ...state,
        tasks_n: [],
      };
    case profileConstant.UPDATE_AGENCY_NOTIFICATION_S:
      return {
        ...state,
        agency_notification: {
          ...state.agency_notification,
          [action.data.agency_id]: {
            ...action.data.notificationData,
          },
        },
      };

    case profileConstant.GET_TEAM_INVITE_LINK_R:
      return {
        ...state,
      };
    case profileConstant.GET_TEAM_INVITE_LINK_S:
      return {
        ...state,
        copyTeamInviteLink: action.data.data,
      };
    case profileConstant.GET_TEAM_INVITE_LINK_F:
      return {
        ...state,
        copyTeamInviteLink: "",
      };

    //**************************************************COMMON************************************************************* */
    case profileConstant.GET_GLOBAL_R:
      return {
        ...state,
        global: [],
      };
    case profileConstant.GET_GLOBAL_S:
      return {
        ...state,
        global: action.data.data,
      };
    case profileConstant.GET_GLOBAL_F:
      return {
        ...state,
        global: [],
      };

    //**************************************************File Constant************************************************************* */
    case profileConstant.GET_INVITES_FLOWS_AND_CLIENTS_R:
      return {
        ...state,
        projects: initialState.projects,
        internal_clients: initialState.internal_clients,
        external_clients: initialState.external_clients,
      };
    case profileConstant.GET_INVITES_FLOWS_AND_CLIENTS_S:
      return {
        ...state,
        projects: action.data.projects,
        internal_clients: action.data.internal_clients,
        external_clients: action.data.external_clients,
      };
    case profileConstant.GET_INVITES_FLOWS_AND_CLIENTS_F:
      return {
        ...state,
        projects: initialState.projects,
        internal_clients: initialState.internal_clients,
        external_clients: initialState.external_clients,
      };

    case profileConstant.GET_AGENCCY_DETAIL_COUNT_R:
      return {
        ...state,
        profileDetailCount: initialState.profileDetailCount,
      };
    case profileConstant.GET_AGENCCY_DETAIL_COUNT_S:
      return {
        ...state,
        profileDetailCount: action.data,
      };
    case profileConstant.GET_AGENCCY_DETAIL_COUNT_F:
      return {
        ...state,
        profileDetailCount: initialState.profileDetailCount,
      };

    case profileConstant.UPDATE_NEW_MESSAGE:
      return {
        ...state,
        newMessage: action.data,
      };

    case profileConstant.CLEAR_NOTIFICATIONS_S:
      let { invites_n, tasks_n, notifications_n, invoice_n,funnel_n } = state;
      if (action.data.type === "invite") {
        invites_n = [];
      }
      if (action.data.type === "task") {
        tasks_n = [];
      }
      if (action.data.type === "message") {
        notifications_n = [];
      }
      if (action.data.type === "invoice") {
        invoice_n = [];
      }
      if (action.data.type === "funnel") {
        funnel_n = [];
      }

      return {
        ...state,
        invites_n,
        tasks_n,
        invoice_n,
        notifications_n,
        funnel_n
      };

    // case profileConstant.UPDATE_SHOW_LOGO_S:
    // return {
    //   ...state,
    //   profile: {
    //     ...state.profile,
    //     profile: {
    //       ...state.profile.profile,
    //       custom_app_logo: action.data
    //     }
    //   }
    // };
    case chatv2Constant.ON_RECEIVE_MESSAGE_S:
      let is_infiniti_res = action?.data?.result?.chats[0]["is_infiniti_res"];
      let modifiedProfile = { ...state.profile };
      if (is_infiniti_res) {
        modifiedProfile["show_ai_welcome_msg"] = 0;
      }
      return {
        ...state,
        profile: { ...modifiedProfile },
      };
    case profileConstant.UPDATE_NOTIFICATIONS_S:
      const { id, ids } = action.data;
      const updatedCount = state.notifications_count
      let updatedNotificationsUpdate = state.notifications_n;
      if (id === "all") {
        updatedNotificationsUpdate = [];
        if (updatedCount?.allNewMsgCount) {
          updatedCount["allNewMsgCount"] = 0
          updatedCount["notificationMsgCount"] = 0
        }
      } else if (id) {
        updatedNotificationsUpdate = updatedNotificationsUpdate.filter(
          (notification) => notification.id !== id
        );
        if (updatedCount?.allNewMsgCount) {
          updatedCount["allNewMsgCount"] = updatedCount?.allNewMsgCount > 0 ? updatedCount.allNewMsgCount - 1 : 0
          updatedCount["notificationMsgCount"] = updatedCount?.notificationMsgCount > 0 ? updatedCount?.notificationMsgCount - 1 : 0
        }
      } else if (ids && ids.length > 0) {
        updatedNotificationsUpdate = updatedNotificationsUpdate.filter(
          (notification) => !ids.includes(notification.id)
        );
        if (updatedCount?.allNewMsgCount) {
          updatedCount["allNewMsgCount"] = updatedCount?.allNewMsgCount - ids.length || 0
          updatedCount["notificationMsgCount"] = updatedCount?.notificationMsgCount - ids.length || 0
        }
      }
      return {
        ...state,
        notifications_n: updatedNotificationsUpdate,
      };
    case profileConstant.DELETE_NOTIFICATION_S:
      const { id: deletedId, ids: deletedIds } = action.data;
      let updatedNotifications = state.notifications_n;
      if (deletedId) {
        updatedNotifications = updatedNotifications.filter(
          (notification) => notification.id !== deletedId
        );
      } else if (deletedIds && deletedIds.length > 0) {
        updatedNotifications = updatedNotifications.filter(
          (notification) => !deletedIds.includes(notification.id)
        );
      }
      return {
        ...state,
        notifications_n: updatedNotifications,
      };

    case profileConstant.UPDATE_CUSTOM_DOMAIN_S:
    case profileConstant.UPDATE_CUSTOM_DOMAIN_F:
    case profileConstant.UPDATE_CUSTOM_DOMAIN_R:
      return { ...state }

    case profileConstant.GET_CUSTOM_DOMAIN_DETAILS_R:
      return {
        ...state,
        domainDetails: initialState.domainDetails
      };
    case profileConstant.GET_CUSTOM_DOMAIN_DETAILS_S:
      return {
        ...state,
        domainDetails: action.data.data
      };
    case profileConstant.GET_CUSTOM_DOMAIN_DETAILS_F:
      return {
        ...state,
        domainDetails: initialState.domainDetails
      };

    case profileConstant.PUBLISH_DOMAIN_PAGE_R:
    case profileConstant.PUBLISH_DOMAIN_PAGE_S:
    case profileConstant.PUBLISH_DOMAIN_PAGE_F:
      return { ...state }
    case pageBuilderConstant.CLEAR_DOMAIN_DATA_ON_UNPUBLISH:
      return {
        ...state,
        domainDetails: {}
      };
    case profileConstant.GET_AGENCY_EMAIL_CONFIG_R:
      return {
        ...state,
      };
    case profileConstant.GET_AGENCY_EMAIL_CONFIG_S:
      return {
        ...state,
        agencyEmailConfig: action.data.data || state.agencyEmailConfig,
      };
    case profileConstant.GET_AGENCY_EMAIL_CONFIG_F:
      return {
        ...state,
      };
    case profileConstant.UPDATE_AGENCY_EMAIL_CONFIG: {
      const emailConfig = state.agencyEmailConfig || {};
      return {
        ...state,
        agencyEmailConfig: { ...emailConfig, ...action.data },
      };
    }
    // update Google Auth URL
    case profileConstant.GET_GOOGLE_AUTH_URL_R:
      return {
        ...state,
        authURL: initialState.authURL
      }
    case profileConstant.GET_GOOGLE_AUTH_URL_S:
      return {
        ...state,
        authURL: action?.data?.data?.authUrl
      }
    case profileConstant.GET_GOOGLE_AUTH_URL_F:
      return {
        ...state,
        authURL: initialState.authURL
      }

    case profileConstant.GET_APPLICATION_APPS_R:
      return {
        ...state,
        applicationApps: initialState.applicationApps
      };
    case profileConstant.GET_APPLICATION_APPS_S:
      return {
        ...state,
        applicationApps: action.data.data
      };
    case profileConstant.GET_APPLICATION_APPS_F:
      return {
        ...state,
        applicationApps: initialState.applicationApps
      };

    case profileConstant.GET_USER_CONFIGURED_APPS_R:
      return {
        ...state,
        configuredApps: initialState.configuredApps,
        configuredAppsInfo: initialState.configuredAppsInfo,
      };
    case profileConstant.GET_USER_CONFIGURED_APPS_S:
      return {
        ...state,
        configuredApps: action.data.data.configuredApps,
        configuredAppsInfo: action.data.data.configuredAppsInfo
      };
    case profileConstant.GET_USER_CONFIGURED_APPS_F:
      return {
        ...state,
        configuredApps: initialState.configuredApps,
        configuredAppsInfo: initialState.configuredAppsInfo,
      };

    case profileConstant.GET_INVOICE_NOTIFICATION_R:
      return {
        ...state
      };
    case profileConstant.GET_INVOICE_NOTIFICATION_S:
      return {
        ...state,
        invoice_n: action.data.data,
      };
    case profileConstant.GET_FUNNEL_NOTIFICATION_S:
      return {
        ...state,
        funnel_n: action.data.data,
      };
    case profileConstant.GET_INVOICE_NOTIFICATION_F:
      return {
        ...state
      };

    case profileConstant.UPDATE_NOTIFICATIONS_R:
      return {
        ...state,
        notifications_count: {
          ...state.notifications_count,
          allNewMsgCount: 0
        },
        notifications_n: []
      };
      // update Google Auth URL
      case profileConstant.GET_GOOGLE_AUTH_URL_R:
        return {
            ...state,
            authURL: initialState.authURL
        }
    case profileConstant.GET_GOOGLE_AUTH_URL_S:
        return {
            ...state,
            authURL: action?.data?.data?.authUrl
        }
    case profileConstant.GET_GOOGLE_AUTH_URL_F:
        return {
            ...state,
            authURL: initialState.authURL
        }

        case profileConstant.PUBLIC_BOARD_LOGO:
          return {
              ...state,  
             profile: action.data,
          }

    default:
      return state;
  }
}
