import { baseAction } from "./baseAction";
import { clientMediaConstant, alertConstant, loaderConstant } from "../constants";
import { clientMediaService } from "../services";

const { request, success, failure } = baseAction;

// All login based actions
export const clientMediaAction = {
  createFolder,
  getAll,
  updateFolderName,
  deleteFolder,
  deleteFile,
  updateFile,
  updateFolderFile,
  uploadFiles
};

/** create new folder */
function createFolder(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(clientMediaConstant.CREATE_FOLDER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientMediaService.createFolder(params).then(data => {
      dispatch(success(clientMediaConstant.CREATE_FOLDER_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(clientMediaConstant.CREATE_FOLDER_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** create new folder */
function getAll(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(clientMediaConstant.GET_ALL_MEDIA_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientMediaService.getAll(params).then(data => {
      dispatch(success(clientMediaConstant.GET_ALL_MEDIA_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(clientMediaConstant.GET_ALL_MEDIA_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update folder name*/
function updateFolderName(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(clientMediaConstant.UPDATE_FOLDER_NAME_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientMediaService.updateFolderName(params).then(data => {
      dispatch(success(clientMediaConstant.UPDATE_FOLDER_NAME_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(clientMediaConstant.UPDATE_FOLDER_NAME_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** delete folder*/
function deleteFolder(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(clientMediaConstant.DELETE_FOLDER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientMediaService.deleteFolder(params).then(data => {
      dispatch(success(clientMediaConstant.DELETE_FOLDER_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(clientMediaConstant.DELETE_FOLDER_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


/** delete file*/
function deleteFile(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(clientMediaConstant.DELETE_FILE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientMediaService.deleteFile(params).then(data => {
      dispatch(success(clientMediaConstant.DELETE_FILE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(clientMediaConstant.DELETE_FILE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


/** update file*/
function updateFile(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(clientMediaConstant.UPDATE_FILE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientMediaService.updateFile(params).then(data => {
      dispatch(success(clientMediaConstant.UPDATE_FILE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(clientMediaConstant.UPDATE_FILE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update folder file*/
function updateFolderFile(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(clientMediaConstant.UPDATE_FILE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientMediaService.updateFolderFile(params).then(data => {
      dispatch(success(clientMediaConstant.UPDATE_FILE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(clientMediaConstant.UPDATE_FILE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update folder file*/
function uploadFiles(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(clientMediaConstant.UPLOAD_FILE_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientMediaService.uploadFiles(params).then(data => {
      dispatch(success(clientMediaConstant.UPLOAD_FILE_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(clientMediaConstant.UPLOAD_FILE_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}