import { workspaceConstant } from "../constants";

const initialState = {
  shared_resources: [],
  search: {},
  sharedUsers:[],
};

export default function workspace(state = initialState, action) {
  switch (action.type) {
    case workspaceConstant.GET_WORKSPACES_S:
      return {
        ...state,
        workspaces: action.data
      };

    case workspaceConstant.SEARCH_AGENCY_R:
      return {
        ...state,
        search: { }
      };
    case workspaceConstant.SEARCH_AGENCY_S:
      return {
        ...state,
        search: {
          ...state.search,
          [action.data.key]: action.data.data
        }
      };
    case workspaceConstant.SEARCH_AGENCY_F:
      return {
        ...state,
        search: {}
      };

    case workspaceConstant.SEARCH_AGENCY_REMOVE:
      return {
        ...state,
        search: {
          ...action.data.data
        }
      };

    case workspaceConstant.SEARCH_AGENCY_F:
      return {
        ...state,
        search: state.search
      };
    case workspaceConstant.SEARCH_AGENCY_RESET:
      return {
        ...state,
        search: initialState.search
      };
    case workspaceConstant.GET_SHARED_RESOURCES_S:
      return {
        ...state,
        shared_resources: action.data
      };
    case workspaceConstant.ACCEPT_SHARE_INVITE_S:
      return {
        ...state,
        shared_resources: (state.shared_resources || []).map(m => {
          if (m.id == action.data.invite_id) {
            m.need_response = 0
            m.status = 2
          }
          return m
        })
      }
    case workspaceConstant.REJECT_SHARE_INVITE_S:
      return {
        ...state,
        shared_resources: (state.shared_resources || []).map(m => {
          if (m.id == action.data.invite_id) {
            m.need_response = 0
            m.status = 3
          }
          return m
        })
      }
      case workspaceConstant.GET_SHARE_INVITE_S:
      return {
        ...state,
        sharedUsers: action.data
      }
    
    case workspaceConstant.STOP_SHARING_FLOW_S:
      return{
        ...state,
        shared_resources: (state.shared_resources || []).map(m => {
          if (m.id == action.data.invite_id) {
            m.need_response = 0
            m.status = 4
          }
          return m
        })
      }

    case workspaceConstant.CANCEL_SHARE_INVITE_S:
      return {
        ...state,
        shared_resources: (state.shared_resources || []).map(m => {
          if (m.id == action.data.invite_id) {
            m.need_response = 0
            m.status = 0
          }
          return m
        })
      }
    default:
      return state;
  }
}
