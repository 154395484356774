import React, { useRef, useState, createRef, useEffect, memo } from 'react';
import AliceCarousel from 'react-alice-carousel';
import PropTypes from 'prop-types';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import 'react-alice-carousel/lib/alice-carousel.css';
import { ChatReaction } from '../ChatReaction';
import { PageLoader } from '../PageLoader';
import { MapInteractionCSS } from 'react-map-interaction';
import { useMediaQuery } from '@material-ui/core';

const ItemComponent = memo((props) => {

    const { classes, url, index, items, handleClick, type, videoType,inVoicePreviewItem} = props

    const [isZoomed, setIsZoomed] = useState(false);
    const [zoomIntractions, setZoomIntractions] = useState({
        scale: 1,
        translation: { x: 0, y: 0 }
    });

    const parentElementRef = useRef(items.map(() => createRef()));
    const itemElementRef = useRef(items.map(() => createRef()));
    const imageRef = useRef(null);
    // const [timer, setTimer] = useState(null)
    // let timer = null

    const isMobile = useMediaQuery('(max-width: 899px')

    // useEffect(() => {
    //     console.log("mount >>>", index);
    //     return () => {
    //         console.log("unmount");
    //         setZoomIntractions({
    //             scale: 1,
    //             translation: { x: 0, y: 0 }
    //         })
    //     }
    // })
    useEffect(() => {
        console.log("mount");
        setZoomIntractions({
            scale: 1,
            translation: { x: 0, y: 0 }
        })
    }, [index])


    // const onClickHandler = event => {
    //     event.preventDefault();
    //     console.log("🚀 ~ onClickHandler ~ event:", event)
    //     console.log("🚀 ~ onClickHandler ~ event:", timer)

    //     if (!timer) {
    //         console.log("single");
    //         timer = setTimeout(() => { clearTimeout(timer); }, 200)
    //     } else {
    //         console.log("double");
    //         clearTimeout(timer);
    //         if (zoomIntractions?.scale !== 1) {
    //             setZoomIntractions({
    //                 scale: 1,
    //                 translation: { x: 0, y: 0 }
    //             })
    //         } else {
    //             setZoomIntractions({
    //                 scale: 2,
    //                 translation: { x: 0, y: 0 }
    //             })
    //         }
    //     }
    // }

    const handleParentClick = (event) => {
        if (event?.target?.className?.indexOf('video-element') === -1) {
            handleClick(parentElementRef.current[index], itemElementRef.current[index], event)
        }
    }

    const handleImageClick = () => {
        setIsZoomed(!isZoomed);
    };

    const handleMouseMove = (e) => {
        if (isZoomed) {
            const { left, top, width, height } = imageRef.current.getBoundingClientRect();
            const x = (e.clientX - left) / width;
            const y = (e.clientY - top) / height;

            imageRef.current.style.transformOrigin = `${x * 100}% ${y * 100}%`;
        }
    };

    if (type == 'video' || inVoicePreviewItem?.type === 'VIDEO') {
        return (
            <>
                <div className={`item ${classes.slideItem}`} data-value={url} key={`${url}_${index}`}>
                    <div className={`${classes.itemContainer} itemContainer`} ref={parentElementRef.current[index]}
                        onClick={handleParentClick}
                    >
                        <video className='video-element' width="100%" height="auto" preload="auto" playsInline controls>
                            <source src={`${url}`} type={videoType} ref={itemElementRef.current[index]} />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </>
        )
    }
    else if (type == 'image') {
        return (
            <>
                <div className={`item ${classes.slideItem}`} data-value={url} key={`${url}_${index}`} ref={parentElementRef.current[index]} onClick={(e) => handleParentClick(e)} >
                    {
                        isMobile ?
                            <MapInteractionCSS
                                showControls={false}
                                value={zoomIntractions}
                                minScale={1}
                                maxScale={3}
                                translationBounds={{
                                    xMax: 400,
                                    yMax: 200
                                }}
                                onChange={(value) => {
                                    // setIsZoomed(true);
                                    if (value?.scale > 1) {
                                        setZoomIntractions(value)
                                    } else if (value?.scale === 1) {
                                        setZoomIntractions({
                                            scale: 1,
                                            translation: { x: 0, y: 0 }
                                        })
                                    }
                                }}
                            >
                                <img
                                    alt={"url"}
                                    src={url}
                                    className={`${classes.zoomableImage} ${isZoomed ? classes.zoomed : ''}`}
                                    ref={imageRef}
                                // onTouchEnd={onClickHandler}
                                // onTouchMove={(e) => {
                                //     e.preventDefault();
                                // }}
                                />
                            </MapInteractionCSS>
                            :
                            <div className={`${classes.itemContainer} itemContainer`} ref={itemElementRef.current[index]} onMouseMove={handleMouseMove}>
                                <img alt={"url"} src={url} onClick={handleImageClick} className={`${classes.zoomableImage} ${isZoomed ? classes.zoomed : ''}`} ref={imageRef} />
                            </div>
                    }
                </div>
            </>
        )
    }
    else if (type == 'application') {
        return (
            <>

                <iframe title={'pdf'} src={url} style={{ width: "80%", height: "80%", zIndex: 10 }} frameBorder="0"></iframe>

            </>
        )
    }

    else {
        return null;
    }
})

const Carousel = (props) => {
    const { classes, items, index, getCurrentItem, handleClose, previewData, userId, chatData, onClickReactions, inVoicePreviewItem, newAttachments } = props;

    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const delay = setTimeout(() => {
            setIsVisible(true);
        }, 1000); // Adjust the delay time in milliseconds as needed

        return () => clearTimeout(delay); // Cleanup the timer when the component unmounts
    }, []);


    const renderSlideInfo = ({ item, itemsCount }) => {
        return `${item}\\${itemsCount}`;
    };

    const renderDotsItem = (data) => {
        let { isActive, activeIndex } = data;
        if (isActive) {
            const elementToScroll = document.getElementById(activeIndex);
            if (elementToScroll) {
                elementToScroll.scrollIntoView({ behavior: "smooth", inline: "center" });
            }
        }
        let image;
        if (invoicePreviewItems?.length > 0) {
            image = invoicePreviewItems[activeIndex]?.props?.children?.props;

        } else {
            image = items[activeIndex];
        }

        return image?.type !== 'application' ?
            <span
                className={`${classes.sliderThumb} ${isActive ? classes.activeThumb : ''}`}
                id={items?.[activeIndex]?.unique_id}
            >
                <img alt={"thumb"} src={image && image?.thumb ? image?.thumb : (image?.url || image?.previewFile)} />
            </span>
            :
            null;

        // return isActive ? 'x' : 'o';
    };
    const renderPrevButton = ({ isDisabled }) => {
        return <span style={{ opacity: isDisabled ? '0.5' : 1 }}><NavigateBeforeIcon className={classes.navigationButtons} /></span>;
    };

    const renderNextButton = ({ isDisabled }) => {
        return <span style={{ opacity: isDisabled ? '0.5' : 1 }}><NavigateNextIcon className={classes.navigationButtons} /></span>;
    };

    const renderPlayPauseButton = ({ isPlaying }) => {
        return isPlaying ? <PauseCircleOutlineIcon className={classes.playPauseButton} /> : <PlayCircleOutlineIcon className={classes.playPauseButton} />;
    };

    const onSlideChanged = ({ item }) => {
        getCurrentItem(item);
    };

    const handleClick = (parentref, itemref, event) => {
        if (event.target.contains(itemref.current) || event.target.contains(parentref.current)) {
            handleClose()
        }

    }

    const sideShowItems = items.map((val, i) => {
        let type = val?.type?.split("/")[0];
        let previewType = items.length > 1 ? previewData[index] : chatData
        let message_reaction = items.length > 1 ? previewData[index]?.reactions || {} : JSON.parse(chatData?.reactions || '{}')
        return (
            <div className={classes.previewContainer}>
                <ItemComponent url={val.url} index={i} classes={classes} items={items} handleClick={handleClick}
                    type={type} videoType={val.type ? val.type : ''}

                />
                {previewData &&
                    <div className={`${classes.reaction}`}>
                        {Object?.keys(previewData[index]?.reactions)?.length > 0 &&
                            <ChatReaction
                                reactionRight
                                userId={userId}
                                messageReactions={message_reaction}
                                message_id={chatData?.id || previewType.message_id}
                                moduleType={previewType.moduleType}
                                onClickReactions={onClickReactions}
                            />
                        }
                    </div>
                }
            </div>
        )
    })

    const invoicePreviewItems = newAttachments?.map((val, i) => {
        let type = val?.type?.split("/")[0];

        return (
            <>
                <ItemComponent url={val.url || val?.previewFile} index={i} classes={classes} items={items} handleClick={handleClick}
                    type={type} videoType={val.type ? val.type : ''}
                    inVoicePreviewItem={inVoicePreviewItem}
                />
            </>
        )
    });
    /* let optionProps = {};
    if (playButton) {
        optionProps["renderPlayPauseButton"] = renderPlayPauseButton();
    } */

    return (
        <>
            {isVisible ?
                <AliceCarousel
                    mouseTracking
                    items={newAttachments?.length > 0 ? invoicePreviewItems : sideShowItems}
                    activeIndex={index}
                    autoPlayControls={false}
                    autoPlayInterval={800}
                    disableSlideInfo={false}
                    renderSlideInfo={renderSlideInfo}
                    renderDotsItem={renderDotsItem}
                    renderPrevButton={renderPrevButton}
                    renderNextButton={renderNextButton}
                    renderPlayPauseButton={renderPlayPauseButton}
                    onSlideChanged={onSlideChanged}
                    keyboardNavigation={true}
                />
                :
                <PageLoader isLoading={!isVisible} />
            }

        </>
    )
}

// Default props
Carousel.defaultProps = {
    classes: "",
    items: [],
    index: 0,
    playButton: false
};

// Prop types
Carousel.propTypes = {
    classes: PropTypes.object,
    items: PropTypes.array.isRequired,
    index: PropTypes.number,
    playButton: PropTypes.bool,
};

/** Export Component */
export default Carousel;