import { workflowConstants } from "../constants";

const initialState = {
  workflows: [],
  workflowDrafts: []
};

export default function workspace(state = initialState, action) {
  switch (action.type) {
    case workflowConstants.CREATE_WORKFLOW_S:
      let { workflows } = state;
      workflows.push(action.data)
      return {
        ...state,
        workflows
      };
    case workflowConstants.UPDATE_WORKFLOW_S:
      let modifiedWorkflow = [...state.workflows];
      let targetIndex = modifiedWorkflow.findIndex(workflow => workflow.id === action.data.id);
      if (targetIndex > -1) {
        modifiedWorkflow[targetIndex]['action_data'] = action.data.action_data;
      }
      return {
        ...state,
        workflows: [...modifiedWorkflow]
      };
    case workflowConstants.CLEAR_WORKFLOW_R:
      return {
        ...state,
        workflows: []
      };
    case workflowConstants.GET_WORKFLOWS_S:
      const updateData = {
        workflows: [...action.data]
      }
      if(action.data.length > 0){
        updateData['workflowDrafts'] = []
      }

      return {
        ...state,
        ...updateData
      };
    case workflowConstants.DELETE_WORKFLOW_S:
      return {
        ...state,
        workflows: state.workflows.filter(e => `${e.id}` !== `${action.data.workflow_id}`)
      };
    case workflowConstants.EXECUTE_WORKFLOW_S:
      return {
        ...state
      };
    case workflowConstants.SAVE_WORKFLOW_DRAFT_S:
      return {
        ...state,
        workflowDrafts: [action.data]
      };
    case workflowConstants.CLEAR_WORKFLOW_DRAFT_S:
      return {
        ...state,
        workflowDrafts: []
      };
    case workflowConstants.UPDATE_DRAFT_WORKFLOW_S:
      return {
        ...state,
        workflowDrafts: [ {...state.workflowDrafts[0], ...action.data} ]
      };
    default:
      return state;
  }
}
