const lightPalette = {
	primary: {
		// #2563EB
		dary: '#12A8DE',
		main: '#2F3349',
		light: '#64748B',
		contrastText: "#fff",
		textColor: "#2A2D33",
		bgColor: "#fff",
		bodyBgColor: '#EFF9FF',
		bgColorGray: '#F6F7FB',
		border1: '#D7DBEC',
		border2: '#ecebeb',
		disabled: '#d7dbec',
		gradientBtn: 'linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)',
		gradientBtn180: 'linear-gradient(180deg, #5351FC 0%, #19A9FC 100%)',
		gradientBtnSelectedBg: 'linear-gradient(180deg, rgba(83, 81, 252, 0.04) 0%, rgba(25, 169, 252, 0.04) 100%)',
		gradientBtnDisabled: 'linear-gradient(90deg, #7E84A3 0%, #7E84A3 100%)'
	},
	secondary: {
		main: '#7E84A3',
	},
	greyshades: {
		main: '#110308',
		dark: '#878083',
		dark2: '#9DA9BA',
		light1: '#C3C3C3',
		light2: '#E6E5E5',
		light3: '#F7F7F7',
		light4: '#FFFFFF',
	},
	text: {
		primary: '#2F3349',
		seocondary: '#64748B',
		secondary2: '#9DA9BA',
		secondary3: '#A9A9A9'
	}
	
}

const darkPalette = {
	primary: {
		// #2563EB
		dary: '#12A8DE',
		main: '#2F3349',
		light: '#64748B',
		contrastText: "#fff",
		textColor: "#2A2D33",

		bgColor: "#fff",
		bodyBgColor: '#EFF9FF',
		bgColorGray: '#F6F7FB',
		border1: '#D7DBEC',
		border2: '#ecebeb',
		disabled: '#d7dbec',
		gradientBtn: 'linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)',
		gradientBtn180: 'linear-gradient(180deg, #5351FC 0%, #19A9FC 100%)',
		gradientBtnSelectedBg: 'linear-gradient(180deg, rgba(83, 81, 252, 0.04) 0%, rgba(25, 169, 252, 0.04) 100%)',
		gradientBtnDisabled: 'linear-gradient(90deg, #7E84A3 0%, #7E84A3 100%)'
		// gradientBtn: 'transparent linear-gradient(90deg, #5351FC 0%, #19A9FC 100%) 0% 0% no-repeat padding-box'

		
	},
	secondary: {
		// dary: '#12A8DE',
		main: '#7E84A3',
		// light: '#1d2c2f',
		// contrastText: "#fff"
	},
	greyshades: {
		main: '#110308',
		dark: '#878083',
		dark2: '#9DA9BA',
		light1: '#C3C3C3',
		light2: '#E6E5E5',
		light3: '#F7F7F7',
		light4: '#FFFFFF',
	},
	text: {
		primary: '#2F3349',
		seocondary: '#64748B',
		secondary2: '#9DA9BA',
		secondary3: '#A9A9A9'
		// primary: '#0F172A',
		// secondary: '#64748B',
		// secondary: '#ACB9CC'
	}
	
}

export { lightPalette, darkPalette};