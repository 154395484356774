export const undo = ({ stage }) => {
    const layer = stage.findOne('#baseLayer')
    const tr = stage.findOne('#transformer')
    const nodes = layer.getChildren()
    const historyElements = nodes.filter(f =>
        f.attrs.history
        && f.attrs.visible != false
    ).sort((a, b) => b.attrs.mdt - a.attrs.mdt)
    const lastElement = historyElements[0]
    const needTr = lastElement?.attrs?.name?.indexOf('need-tr') >= 0
    if (lastElement) {
        if (lastElement.attrs.visible == false) {
            lastElement.show()
            if (needTr) {
                tr.nodes([lastElement])
                tr.show()
            }
        } else if (lastElement?.attrs?.history_id === 0) {
            lastElement.hide()
            tr.hide()
            tr.nodes([])
        } else {
            if (needTr) {
                tr.nodes([lastElement])
                tr.show()
            }
            lastElement.attrs.undo()
        }
        stage.attrs.lastUndo = {
            id: lastElement.attrs.id
        }
    }
    stage.attrs.onUpdate(stage)
}
