import React from 'react';
import PropTypes from 'prop-types';

const ExpiredNote = ({ text }) => {
    return (
        <div className="expired-notice">
            <span>{text}</span>
        </div>
    );
};


// default props
ExpiredNote.defaultProps = {
    classes: {},
    text: ""
};

// prop types
ExpiredNote.propTypes = {
    classes: PropTypes.object,
    text: PropTypes.string,
};

/** Export Component */
export default ExpiredNote;