import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';

// import credential
import { app_credentials } from '../../config/app_credentials';

//Import Component
import { Button } from '../Button';

// Imports Styles
import styles from "./styles";

//Import Capacitor
import { SignInWithApple } from '@capacitor-community/apple-sign-in';

const { apple: { client_id, redirect_url } } = app_credentials;

let options = {
    clientId: `${client_id}`,
    redirectURI: `${redirect_url}`,
    scopes: 'email name',
    state: 'state',
    nonce: 'nonce',
};


/**
 * Apple Login Button Component
 * @param {*} props
 */
function AppleSignInButtonComponent(props) {
    const { handleAppleLogin } = props;

    const signInWithApple = async () => {
        const { response } = await SignInWithApple.authorize(options)
        const data={
            ...response,
            uid: response.user
        }
        handleAppleLogin(data)
    }


    return (
        <>
            <Button variant="outlined" color="secondary" size="small" className="mb-1 loginBaseBtns" startIcon={<AppleIcon className='loginAppleIcon' />} {...props} onClick={signInWithApple}><span className={"loginBtnSpan"}>Apple ID</span></Button>
        </>
    );
}

// Default props
AppleSignInButtonComponent.defaultProps = {
    type: "button",
    title: "",
    classes: "",
    className: "",
    href: "",
    fullWidth: false,
    size: "small",
    variant: "outlined",
    component: "button",
    disabled: false,
    disableElevation: false,
    onClick: () => { },
    handleAppleLogin: () => { },
};

// Prop types
AppleSignInButtonComponent.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    href: PropTypes.any,
    fullWidth: PropTypes.bool,
    variant: PropTypes.string,
    component: PropTypes.elementType,
    disabled: PropTypes.bool,
    disableElevation: PropTypes.bool,
    onClick: PropTypes.func,
    handleAppleLogin: PropTypes.any,
};

/** Export Component */
export const AppleIOSAppSignIn = withStyles(styles)(AppleSignInButtonComponent);
