import React, { useEffect, useState } from 'react'

import { Box, Typography, withStyles } from '@material-ui/core';
import Styles from './styles';
import { NoInternetSnail } from '../../assets/svg/common/NoInternetSnail';
function NoNetworkConnection({ classes }) {
    const [hasNetwork, setHasNetwork] = useState(true);
    useEffect(() => {
        window.addEventListener('online', () => setHasNetwork(true));
        window.addEventListener('offline', () => setHasNetwork(false));
    }, [])

    return (
        hasNetwork ? null : <div className={classes.noNetworkContainer}>
            <Box display={"flex"} flexDirection={"column"} pb={2} justifyContent={"center"} alignItems={"center"}>
                <NoInternetSnail/>
            </Box>
            <Box>
                <Typography variant="h2" gutterBottom align='center' >Oops!</Typography>
                <Typography variant="subtitle1" className='fs-18' align='center'>
                    No internet connection. Please check
                    your internet settings</Typography>
            </Box>
        </div>
    )
}

export default withStyles(Styles)(NoNetworkConnection)