import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const NotificationComponent = (props) => {
    const { audioUrl } = props;
    let audio = new Audio(audioUrl);
    audio.volume = 0.5;
    // audio.muted = true;
    // const [audio] = useState(new Audio(audioUrl));
    const [playing, setPlaying] = useState(true);


    useEffect(() => {
        playing ? audio?.play() : audio?.pause();
        // audio.oncanplaythrough = (event) => {
        //     var playedPromise = playing ? audio.play() : audio.pause();
        //     if (playedPromise) {
        //         playedPromise.catch((e) => {
        //             
        //             if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') {
        //                 
        //             }
        //         }).then(() => {
        //             
        //             audio.muted = true;
        //         });
        //     }
        // }
    }, [playing]);


    // audio.addEventListener("canplaythrough", event => {
    //     
    //     audio.muted = true;
    //     audio.play();
    // });

    useEffect(() => {
        audio.addEventListener('ended', () => setPlaying(false));
        return () => {
            audio.removeEventListener('ended', () => setPlaying(false));
        };
    }, []);

    return (
        <></>
    )
}

// default props
NotificationComponent.defaultProps = {
    classes: {},
    taskCount: 1,
};

// prop types
NotificationComponent.propTypes = {
    classes: PropTypes.object,
    taskCount: PropTypes.any,
};

/** Export Component */
export const NotificationSound = NotificationComponent;
