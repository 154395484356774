import { DropCrop, DropGradient, DropPen, DropText, DropUndo, Dropredo } from '../../../assets/svg'
const disableFor = ['crop']

export const MenuList = [
    {
        label: 'Text',
        method: 'textNode',
        MenuIcon: DropText,
        isActive: ({ nodeType }) => {
            return nodeType == 'textNode'
        },
        isDisabled: ({ nodeType }) => {
            return disableFor.indexOf(nodeType) > -1
        }
    },
    // {
    //     label: 'Sticker',
    //     method: 'drawSticker',
    //     MenuIcon: DropDocsIcon,
    //     isDisabled: ({ nodeType }) => {
    //         return disableFor.indexOf(nodeType) > -1
    //     }
    // },
    {
        label: 'Pen',
        method: 'drawPen',
        MenuIcon: DropPen,
        isActive: ({ stageData }) => {
            return stageData?.drawMode
        },
        isDisabled: ({ nodeType }) => {
            return disableFor.indexOf(nodeType) > -1
        }
    },
    {
        label: 'Blur',
        method: 'drawBlur',
        isActive: ({ nodeType }) => {
            return nodeType == 'drawBlur'
        },
        MenuIcon: DropGradient,
        isDisabled: ({ nodeType }) => {
            return disableFor.indexOf(nodeType) > -1
        }
    },
    {
        label: 'Crop',
        method: 'crop',
        MenuIcon: DropCrop,
        isActive: ({ nodeType }) => {
            return nodeType == 'crop'
        },
        isDisabled: ({ nodeType }) => {
            return disableFor.indexOf(nodeType) > -1
        }
    },
    {
        label: 'Undo',
        method: 'undo',
        MenuIcon: DropUndo,
        isDisabled: ({ stageData, nodeType }) => {
            return stageData?.undo || disableFor.indexOf(nodeType) > -1
        }
    },
    {
        label: 'Redo',
        method: 'redo',
        MenuIcon: Dropredo,
        isDisabled: ({ stageData, nodeType }) => {
            return stageData?.redo || disableFor.indexOf(nodeType) > -1
        }
    },
    // {
    //     label: 'Save',
    //     method: 'save',
    //     MenuIcon: DropSaveIcon,
    //     isDisabled: ({ nodeType }) => {
    //         return disableFor.indexOf(nodeType) > -1
    //     }
    // }
]