import { baseAction } from "./baseAction";
import { boardsConstant, alertConstant, loaderConstant, authConstant, profileConstant } from "../constants";
import { boardsService } from "../services";

import { getUserPreferences, setUserPreferences } from "../../utils";

//import history
import history from "../../config/history";
import { alertAction } from "./alertAction";

const { request, success, failure } = baseAction;

// All login based actions
export const boardsAction = {
  createBoard,
  createBoardList,
  editBoardList,
  deleteBoardList,
  getBoards,
  getBoardDetail,
  changeBoard,
  createBoardTask,
  cloneBoardTask,
  getBoardTask,
  updateBoardTaskOrder,
  updateBoardListOrder,
  getBoardTaskDetail,
  updateBoardTaskDetail,
  filterOnChange,
  getAssignedUsers,
  reloadBoardTaskCard,
  deleteBoardTask,
  getBoardsTaskTags,
  getBoardMembers,
  addBoardMembers,
  modifyBoardMembers,
  deleteBoardMembers,
  getBoardTaskHistory,
  getBoardTaskComments,
  addBoardTaskComments,
  updateBoardTaskComments,
  deleteBoardTaskComments,
  saveBoardFillter,
  updateBoardFillter,
  setBoardFillter,
  resetBoard,
  updateTaskCard,
  updateBoardSettings,
  deleteBoard,
  getPublicKey,
  getBoardId,
  setIsPublic,
  uploadSheet,
  importBoard,
  exportBoardAction,
  updateBoardTaskDetailEveryField,
  updateBoardTaskDetailDnD,
  updateBoardTaskAttachment,
  addBoardsCustomField,
  getBoardsCustomField,
  updateBoardsCustomField,
  listBoardsCustomField,
  cloneBoard

};

/*****************************COMMON DATA********************************* */


/** create new board */
function createBoard(params, callBack = () => { }) {
  return (dispatch, getState) => {
    // check if flow or client has board alredy
    if (params.board_type !== 0) {
      const { boards } = getState().boards
      const hasBoardAlready = boards.find(f =>
        f.board_type === params.board_type &&
        f.job_id === params.job_id
      )
      const duplicate_title = boards.find(f => (f.label || "").trim().toLowerCase()
        === (params.board_title).trim().toLowerCase())
      if (duplicate_title) {
        dispatch(failure(alertConstant.ALERT_ERROR,
          `Board Already Exists for ${params.board_title}`));
        return
      }
    }
    dispatch(request(boardsConstant.CREATE_BOARD_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.createBoard(params).then(data => {
      dispatch(success(boardsConstant.CREATE_BOARD_S, data));
      // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      if (data.data) {
        let user_data = getUserPreferences()
        user_data.board_id = data.data.value;
        setUserPreferences(user_data)

        // set board to get board detail
        dispatch({
          type: boardsConstant.SET_BOARD_ID,
          data: data.data.value
        })

        dispatch({
          type: authConstant.UPDATE_USER_PREFERENCE,
          data: data.data.preference
        })
      }
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data?.data)
    }, error => {
      dispatch(failure(boardsConstant.CREATE_BOARD_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** create new board */
function createBoardList(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.CREATE_BOARD_LIST_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.createBoardList(params).then(data => {
      dispatch(success(boardsConstant.CREATE_BOARD_LIST_S, data));
      // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(boardsConstant.CREATE_BOARD_LIST_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** edit board list */
function editBoardList(list_id, params) {
  return (dispatch) => {
    dispatch(request(boardsConstant.EDIT_BOARD_LIST_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.editBoardList(list_id, params).then(data => {
      dispatch(success(boardsConstant.EDIT_BOARD_LIST_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(boardsConstant.EDIT_BOARD_LIST_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** delete board list */
function deleteBoardList(list_id, params = {}, cb = () => { }) {
  return (dispatch, getState) => {
    dispatch(request(boardsConstant.DELETE_BOARD_LIST_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    params.board_id = getState().boards.board_id
    boardsService.deleteBoardList(list_id, params).then(data => {
      dispatch(success(boardsConstant.DELETE_BOARD_LIST_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      cb()
    }, error => {
      dispatch(failure(boardsConstant.DELETE_BOARD_LIST_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** get boards list name and id */
function getBoards(params, callBack = () => { }) {
  return (dispatch, getState) => {
    dispatch(request(boardsConstant.GET_BOARDS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.getBoards(params).then(data => {
      dispatch(success(boardsConstant.GET_BOARDS_S, data));
      if (data.data && data.data[0]) {
        const { boards } = getState()?.auth?.loginUser?.preference || {};
        const lastSelected = boards && boards.hasOwnProperty("lastSelected") ? boards.lastSelected : null
        let user_data = getUserPreferences()
        const { board, project } = params
        const board_data = project ? data.data.find(f => f.job_id == project) : board ? data.data.find(f => f.value == board) : lastSelected ? data.data.find(f => f.value == lastSelected) : data.data.find(f => f.value == user_data.board_id)
        if (project) {
          if (board_data) {
            // set board to get board detail
            // based by project
            dispatch({
              type: boardsConstant.SET_BOARD_ID,
              data: board_data.value
            })
          }
        } else {
          user_data.board_id = board_data?.value || data.data[0].value
          setUserPreferences(user_data)
          dispatch({
            type: boardsConstant.SET_BOARD_ID,
            data: user_data.board_id
          })
        }
      }
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(boardsConstant.GET_BOARDS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** change board from select option */
function changeBoard(board_id) {
  return (dispatch) => {
    dispatch({
      type: boardsConstant.SET_BOARD_ID,
      data: board_id
    })
  }
}

/** get board detail list and the tasks */
function getBoardDetail(board_id, params = {}, callBack = () => { }) {
  return (dispatch, getState) => {
    dispatch(request(boardsConstant.GET_BOARD_DETAIL_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    const { board_access_type } = getState().boards
    const serviceName = !board_access_type ? 'getBoardDetail' : 'getPublicBoardDetail'
    boardsService[serviceName](board_id, params).then(data => {

      // store board_id in localstorage
      const user_data = getUserPreferences()
      user_data.board_id = board_id
      setUserPreferences(user_data)
      dispatch(success(boardsConstant.GET_BOARD_DETAIL_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      // get tags
      dispatch(getBoardsTaskTags(board_id));
      callBack()
    }, error => {
      dispatch(failure(boardsConstant.GET_BOARD_DETAIL_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** create new board */
function createBoardTask(listId, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.CREATE_BOARD_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.createBoardTask(listId, params).then(data => {
      dispatch(success(boardsConstant.CREATE_BOARD_TASK_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(boardsConstant.CREATE_BOARD_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** get board detail list and the tasks */
function getBoardTask(listId, params = {}, callBack = () => { }) {
  return (dispatch, getState) => {
    dispatch(request(boardsConstant.GET_BOARD_TASK_R, params));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    const { filter, tasks, board_access_type } = getState().boards
    const serviceName = !board_access_type ? 'getBoardTask' : 'getPublicBoardTask'
    params.skip = tasks[listId] ? tasks[listId].length : 0
    params.last_task_id = tasks[listId] ? (tasks[listId] || []).at(-1)?.id : null
    params.filter = (Object.values((filter || {}))).filter(f => f.selected)
    boardsService[serviceName](listId, params).then(data => {
      dispatch(success(boardsConstant.GET_BOARD_TASK_S, data));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(boardsConstant.GET_BOARD_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      // dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update task order accross list */
function updateBoardTaskOrder(boardId, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.UPDATE_BOARD_TASK_ORDER_R, params));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.updateBoardTaskOrder(boardId, params.postData).then(data => {
      dispatch(success(boardsConstant.UPDATE_BOARD_TASK_ORDER_S, data));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(boardsConstant.UPDATE_BOARD_TASK_ORDER_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update task order accross list */
function updateBoardListOrder(boardId, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.UPDATE_BOARD_LIST_ORDER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.updateBoardListOrder(boardId, params.postData).then(data => {
      dispatch(success(boardsConstant.UPDATE_BOARD_LIST_ORDER_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(boardsConstant.UPDATE_BOARD_LIST_ORDER_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** get board task detail edit mode */
function getBoardTaskDetail(taskId, params, callBack = () => { }) {
  return (dispatch, getState) => {
    dispatch(request(boardsConstant.GET_BOARD_TASK_DETAIL_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    const { board_access_type } = getState().boards
    const serviceName = !board_access_type ? 'getBoardTaskDetail' : 'getPublicBoardTaskDetail'
    boardsService[serviceName](taskId, params).then(data => {
      dispatch(success(boardsConstant.GET_BOARD_TASK_DETAIL_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(boardsConstant.GET_BOARD_TASK_DETAIL_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** update board task detail edit mode */
function updateBoardTaskDetail(taskId, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.UPDATE_BOARD_TASK_DETAIL_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.updateBoardTaskDetail(taskId, params).then(data => {
      dispatch(success(boardsConstant.UPDATE_BOARD_TASK_DETAIL_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      // update task detail in card
      if (data.data.id) {
        dispatch(reloadBoardTaskCard(data.data))
        callBack()
      }
    }, error => {
      dispatch(failure(boardsConstant.UPDATE_BOARD_TASK_DETAIL_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** Filter on change */
function filterOnChange(params) {
  return {
    type: boardsConstant.FILTER_ON_CHANGE,
    params
  }
}

/** get board task detail edit mode */
function getAssignedUsers(params) {
  return (dispatch) => {
    dispatch(request(boardsConstant.GET_ASSIGNED_USERS_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.getAssignedUsers(params).then(data => {
      dispatch(success(boardsConstant.GET_ASSIGNED_USERS_TASK_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(boardsConstant.GET_ASSIGNED_USERS_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// refresh board task card after edit task popup save
function reloadBoardTaskCard(params) {
  return {
    type: boardsConstant.RELOAD_BOARD_TASK_CARD,
    params
  }
}

/** get board task detail edit mode */
function deleteBoardTask(listId, taskId, taskDetail) {
  return (dispatch) => {
    dispatch(request(boardsConstant.DELETE_BOARD_TASK_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.deleteBoardTask(listId, taskId).then(data => {
      if (data && data.data.id == taskId) {
        dispatch(success(boardsConstant.DELETE_BOARD_TASK_S, {
          taskId,
          listId,
          taskDetail
        }));
      }
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(boardsConstant.DELETE_BOARD_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** get board task tags */
function getBoardsTaskTags(listId, taskId) {
  return (dispatch, getState) => {
    dispatch(request(boardsConstant.BOARDS_TASK_TAGS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    const { board_access_type } = getState().boards
    const serviceName = !board_access_type ? 'getBoardsTaskTags' : 'getPublicBoardsTaskTags'
    boardsService[serviceName](listId, taskId).then(data => {
      dispatch(success(boardsConstant.BOARDS_TASK_TAGS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(boardsConstant.BOARDS_TASK_TAGS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** get board members based on agency_id */
function getBoardMembers(board_id) {
  return (dispatch) => {
    dispatch(request(boardsConstant.GET_BOARD_MEMBERS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.getBoardMembers(board_id).then(data => {
      dispatch(success(boardsConstant.GET_BOARD_MEMBERS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(boardsConstant.GET_BOARD_MEMBERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    });
  };
}

/** Add board members */
function addBoardMembers(board_id, data) {
  return (dispatch) => {
    dispatch(request(boardsConstant.ADD_BOARD_MEMBERS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.addBoardMembers(board_id, data).then(data => {
      dispatch(success(boardsConstant.ADD_BOARD_MEMBERS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(boardsConstant.ADD_BOARD_MEMBERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**  Update board members */
function modifyBoardMembers(board_id, data) {
  return (dispatch) => {
    dispatch(request(boardsConstant.MODIFY_BOARD_MEMBERS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.modifyBoardMembers(board_id, data).then(data => {
      dispatch(success(boardsConstant.MODIFY_BOARD_MEMBERS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(boardsConstant.MODIFY_BOARD_MEMBERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


/** Delete board members */
function deleteBoardMembers(board_id, data, cb = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.DELETE_BOARD_MEMBERS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.deleteBoardMembers(board_id, data).then(data => {
      dispatch(success(boardsConstant.DELETE_BOARD_MEMBERS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      cb()
    }, error => {
      dispatch(failure(boardsConstant.DELETE_BOARD_MEMBERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * Get task activity history
 * @param {*} task_id 
 * @returns 
 */
function getBoardTaskHistory(task_id) {
  return (dispatch) => {
    dispatch(request(boardsConstant.GET_BOARD_TASK_HISTORY_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.getBoardTaskHistory(task_id).then(data => {
      dispatch(success(boardsConstant.GET_BOARD_TASK_HISTORY_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(boardsConstant.GET_BOARD_TASK_HISTORY_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * Get task comments
 * @param {*} task_id 
 * @returns 
 */
function getBoardTaskComments(task_id) {
  return (dispatch) => {
    dispatch(request(boardsConstant.GET_BOARD_TASK_COMMENTS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.getBoardTaskComments(task_id).then(data => {
      dispatch(success(boardsConstant.GET_BOARD_TASK_COMMENTS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(boardsConstant.GET_BOARD_TASK_COMMENTS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * Add task comments
 * @param {*} task_id 
 * @returns 
 */
function addBoardTaskComments(task_id, params, cb) {
  return (dispatch) => {
    dispatch(request(boardsConstant.ADD_BOARD_TASK_COMMENTS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.addBoardTaskComments(task_id, params).then(data => {
      dispatch(success(boardsConstant.ADD_BOARD_TASK_COMMENTS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data.data && data.data.data) {
        const { task_card_data } = data.data.data
        dispatch(updateTaskCard(task_card_data))
      }
      cb()
    }, error => {
      dispatch(failure(boardsConstant.ADD_BOARD_TASK_COMMENTS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * Update task comments
 * @param {*} task_id 
 * @returns 
 */
function updateBoardTaskComments(task_id, comment_id, params, cb) {
  return (dispatch) => {
    dispatch(request(boardsConstant.UPDATE_BOARD_TASK_COMMENTS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.updateBoardTaskComments(task_id, comment_id, params).then(data => {
      dispatch(success(boardsConstant.UPDATE_BOARD_TASK_COMMENTS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      cb()
    }, error => {
      dispatch(failure(boardsConstant.UPDATE_BOARD_TASK_COMMENTS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/**
 * Delete task comments
 * @param {*} task_id 
 * @returns 
 */
function deleteBoardTaskComments(task_id, comment_id, cb) {
  return (dispatch) => {
    dispatch(request(boardsConstant.DELETE_BOARD_TASK_COMMENTS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.deleteBoardTaskComments(task_id, comment_id).then(data => {
      dispatch(success(boardsConstant.DELETE_BOARD_TASK_COMMENTS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (data.data && data.data.data) {
        const { task_card_data } = data.data.data
        dispatch(updateTaskCard(task_card_data))
      }
      cb()
    }, error => {
      dispatch(failure(boardsConstant.DELETE_BOARD_TASK_COMMENTS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    });
  };
}

function saveBoardFillter(data) {
  return {
    type: boardsConstant.SAVE_BOARD_FILTER,
    data: data
  }
}

function updateBoardFillter(data) {
  return {
    type: boardsConstant.UPDATE_BOARD_FILTER,
    data: data
  }
}

function setBoardFillter(template_value) {
  return {
    type: boardsConstant.SET_BOARD_FILTER,
    data: template_value
  }
}

function resetBoard() {
  return {
    type: boardsConstant.RESET_BOARD
  }
}

function updateTaskCard(data) {
  return {
    type: boardsConstant.UPDATE_TASK_CARD,
    data
  }
}

/**
 * Update Board Settings
 * @param {*} board_id 
 * @returns 
 */
function updateBoardSettings(board_id, params) {
  return (dispatch) => {
    dispatch(request(boardsConstant.UPDATE_BOARD_SETTINGS_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.updateBoardSettings(board_id, params).then(data => {
      dispatch(success(boardsConstant.UPDATE_BOARD_SETTINGS_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(boardsConstant.UPDATE_BOARD_SETTINGS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    });
  };
}

/** Clone Board Task */
function cloneBoardTask(listId, taskId, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.CLONE_BOARD_TASK_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.cloneBoardTask(listId, taskId, params).then(data => {
      dispatch(success(boardsConstant.CLONE_BOARD_TASK_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(boardsConstant.CLONE_BOARD_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** Delete Board */
function deleteBoard(board_id, next_board_id) {
  return (dispatch) => {
    dispatch(request(boardsConstant.DELETE_BOARD_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.deleteBoard(board_id).then(data => {
      dispatch(success(boardsConstant.DELETE_BOARD_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (next_board_id) {
        history.push(`/boards?board_id=${next_board_id}`)
        dispatch(changeBoard(next_board_id))
      } else {
        history.push(`/boards`)
        window?.location?.reload()
      }
    }, error => {
      dispatch(failure(boardsConstant.DELETE_BOARD_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getPublicKey(board_id, callBack = () => { }) {
  return (dispatch) => {
    boardsService.getPublicKey(board_id).then(res => {
      dispatch(success(boardsConstant.PUBLIC_LINK, res.data));
      callBack(res.data);
    }
    )
  }
}

function getBoardId(key) {
  return (dispatch) => {
    dispatch(request(boardsConstant.PUBLIC_BOARD_R));
    boardsService.getBoardId(key).then(res => {
      dispatch(success(profileConstant.PUBLIC_BOARD_LOGO, res.data))
      dispatch(success(boardsConstant.PUBLIC_BOARD_ID, res.data))
      dispatch(request(boardsConstant.PUBLIC_BOARD_S));
    }, error => {
      dispatch(request(boardsConstant.PUBLIC_BOARD_F));
    }
    )
  }
}

function setIsPublic(board_id, param) {
  return (dispatch) => {
    boardsService.setIsPublic(board_id, param).then(res => {
      dispatch(success(boardsConstant.SET_PUBLIC, res.data))
    }
    )
  }
}
/** read spreadsheet */
function uploadSheet(board_id, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(success(boardsConstant.UPLOAD_SHEET_DATA_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.uploadSheet(board_id, params).then(data => {
      dispatch(success(boardsConstant.UPLOAD_SHEET_DATA_S, data.data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(success(boardsConstant.UPLOAD_SHEET_DATA_R, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/** import board */
function importBoard(board_id, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.importBoard(board_id, params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


function exportBoardAction(board_id, exportData, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.EXPORT_BOARD_R));
    boardsService.exportBoard(board_id, exportData).then(data => {
      dispatch(success(boardsConstant.EXPORT_BOARD_S, data));
      callBack(data);
    }, error => {
      dispatch(failure(boardsConstant.EXPORT_BOARD_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

/** update board task detail every single field edit mode */
function updateBoardTaskDetailEveryField(taskId, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.UPDATE_BOARD_TASK_DETAIL__FIELD_R, params));
    dispatch(request(boardsConstant.TASK_FIELD_LOADING_TRUE));
    boardsService.updateBoardTaskDetailEveryField(taskId, params).then(data => {
      dispatch(success(boardsConstant.UPDATE_BOARD_TASK_DETAIL__FIELD_S, data));
      dispatch(request(boardsConstant.TASK_FIELD_LOADING_FALSE));
      if (data) {
        callBack(data)
      }
    }, error => {
      dispatch(failure(boardsConstant.UPDATE_BOARD_TASK_DETAIL__FIELD_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(boardsConstant.TASK_FIELD_LOADING_FALSE));

    }
    );
  };
}

/** update board task detail every single field edit mode */
function updateBoardTaskAttachment(taskId, params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.UPDATE_BOARD_TASK_DETAIL__FIELD_R, params));
    dispatch(request(boardsConstant.TASK_FIELD_LOADING_TRUE));
    boardsService.updateBoardTaskAttachment(taskId, params).then(data => {
      dispatch(success(boardsConstant.UPDATE_BOARD_TASK_DETAIL__FIELD_S, data));
      dispatch(request(boardsConstant.TASK_FIELD_LOADING_FALSE));
      if (data) {
        callBack(data)
      }
    }, error => {
      dispatch(failure(boardsConstant.UPDATE_BOARD_TASK_DETAIL__FIELD_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(boardsConstant.TASK_FIELD_LOADING_FALSE));

    }
    );
  };
}

// update DND in preference
function updateBoardTaskDetailDnD(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.UPDATE_BOARD_TASK_DETAIL__DND_R, params));
    boardsService.updateBoardTaskDetailDnD(params).then(data => {
      dispatch(success(boardsConstant.UPDATE_BOARD_TASK_DETAIL__DND_S, data));
      dispatch({
        type: authConstant.UPDATE_USER_PREFERENCE,
        data: data.data.preference
      })
    }, error => {
      dispatch(failure(boardsConstant.UPDATE_BOARD_TASK_DETAIL__DND_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  }
}

// Add custom field
function addBoardsCustomField(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.CREATE_BOARD_CUSTOM_FIELD_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.createBoardCustomField(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (!data?.data?.["isExists"]) {
        dispatch(success(boardsConstant.CREATE_BOARD_CUSTOM_FIELD_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, "Custom field has been added successfully"));
      }
      callBack(data.data)
    }, error => {
      dispatch(failure(boardsConstant.CREATE_BOARD_CUSTOM_FIELD_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// get custom field
function getBoardsCustomField(board_id) {
  return (dispatch) => {
    dispatch(request(boardsConstant.GET_BOARD_CUSTOM_FIELD_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.getBoardCustomField(board_id).then(data => {
      dispatch(success(boardsConstant.GET_BOARD_CUSTOM_FIELD_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }, error => {
      dispatch(failure(boardsConstant.GET_BOARD_CUSTOM_FIELD_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}


// Update custom field
function updateBoardsCustomField(fieldId,params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.UPDATE_BOARD_CUSTOM_FIELD_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.updateBoardCustomField(fieldId,params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      if (!data?.data?.["isExists"]) {
        dispatch(success(boardsConstant.UPDATE_BOARD_CUSTOM_FIELD_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, "Custom field has been updated successfully"));
      }
      callBack(data.data)
    }, error => {
      dispatch(failure(boardsConstant.UPDATE_BOARD_CUSTOM_FIELD_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// List custom field
function listBoardsCustomField(callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(boardsConstant.GET_BOARD_CUSTOM_FIELD_LIST_R));
    dispatch(request(loaderConstant.LOADING_TRUE));
    boardsService.listBoardCustomField().then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(boardsConstant.GET_BOARD_CUSTOM_FIELD_LIST_S, data));
    }
      , error => {
        dispatch(failure(boardsConstant.GET_BOARD_CUSTOM_FIELD_LIST_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}
function cloneBoard(params, callBack = ()=>{}) {
  return (dispatch) => {
    boardsService.cloneBoard(params).then(data => {
    callBack(data.data.boardId)
    }
    , error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  }
}