import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles'
import { Dialog, DialogContent, DialogActions, DialogTitle, IconButton, withStyles } from '@material-ui/core';
import { Typography } from '../Typography';
import { CloseIcon } from '../../assets/svg';
import { Button } from '../Button';

function Confirm(props) {
    const { classes, open, handleClose, title, description, actionBtns, content } = props

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                className: classes.dialogPaper,
            }}
        >
            <div className={classes.titleContainer}>
                <DialogTitle className={classes.title}>
                    {title}
                </DialogTitle>
                <IconButton className={classes.closeIcon} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>

            <DialogContent>
                {
                    description ? <Typography variant="h6" className={`${classes.description} fw-500 fs-14`}>
                        {description}
                    </Typography> : null
                }

                {
                    content ? content : null
                }

                <DialogActions>
                    {actionBtns.map((btn, i) => {
                        const { label, onClick = () => { }, className = "", ...rest } = btn;

                        return (
                            <React.Fragment key={i}>
                                <Button onClick={onClick} className={`${classes.actionBtns} ${className}`} {...(rest || {})}>
                                    {label}
                                </Button>
                            </React.Fragment>
                        )
                    })}
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}

// default props
Confirm.defaultProps = {
    classes: {},
    open: false,
    handleClose: () => { },
    title: "Confirmation!",
    description: "",
    descriptionArray: [],
    actionBtns: []
};

// prop types
Confirm.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.any,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    descriptionArray: PropTypes.array,
    actionBtns: PropTypes.array
};

export const ConfirmDialog = withStyles(styles)(Confirm);