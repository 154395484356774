const styles = (theme) => ({
    clockListContainer: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1.35rem 1rem",
        "& h2":{
            fontSize: "1.35rem !important",
            fontWeight: "bolder",
            transition: "all 0.25s ease-in"
        }
    }
})


export default styles