import { useState ,useEffect} from "react";
import { createTheme } from "@material-ui/core";
import React from "react";
import { getPalette } from "./index";
import typography from "./typography";
import overrides from "./overrides";
import { getSystemThemePref } from "../../utils";

export const useColorTheme = () => {
  const initialThemeType = localStorage.getItem('themeType') || getSystemThemePref();
  if (!localStorage.getItem('themeType')) {
    localStorage.setItem('themeType', 'system');
  }
  const [mode, setMode] = useState(initialThemeType === 'system' ? getSystemThemePref() : initialThemeType);
  const [isPendingChanges, setIsPendingChanges] = useState(false);
  const [selectedThemeType, setSelectedThemeType] = useState(initialThemeType);
  const handleSystemThemeChange = (event) => {
    if (selectedThemeType === 'system') {
      const newMode = event.matches ? 'dark' : 'light';
      setMode(newMode);
    }
  };

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    darkModeMediaQuery.addListener(handleSystemThemeChange);
    return () => {
      darkModeMediaQuery.removeListener(handleSystemThemeChange);
    };
  }, [selectedThemeType]);

  const toggleColorMode = (newType) => {
    setSelectedThemeType(newType);
    setIsPendingChanges(true);
  };
    const saveThemeChanges = () => {
    localStorage.setItem('themeType', selectedThemeType);
    setMode(selectedThemeType === 'system' ? getSystemThemePref() : selectedThemeType);
    setIsPendingChanges(false);
  };
  
  const changeMode = (theme) => {
    setMode(theme);
  }

  const modifiedTheme = React.useMemo(
    () => {
      return createTheme({
        palette: getPalette(mode),
        overrides,
        typography,
      })
    },
    [mode]
  );

  return {
    theme: modifiedTheme,
    mode,
    toggleColorMode,
    isPendingChanges,
    saveThemeChanges,
    selectedThemeType,
    changeMode
  };
};
