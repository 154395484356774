import { featureFlagsConstant } from "../constants";
import { getUserBoardFilterPreferences } from "../../utils";
import { addOrRemoveDoc, updateDoc, handleDelete } from '../helpers/docHelpers'

const initialState = {
    flags: {},
    appConfig: {}
}

export default function featureFlagsReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case featureFlagsConstant.FEATURE_FLAGS_GET_S:
            return {
                ...state,
                flags: action.data
            }
        case featureFlagsConstant.FEATURE_APP_CONFIG_GET_S:
            return {
                ...state,
                appConfig: action.data
            }
        default:
            return state
    }
}
