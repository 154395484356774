import { getRequest } from "../../utils/apiHelper";

export const flagService = {
    getFlags
};

async function getFlags() {
    const response = await getRequest('feature_flags');
    return response;
}
