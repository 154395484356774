import { dashboardConstant } from "../constants";

const initialState = {
    rText: "",
    tasks: [],
    projects: [],
    allCities: [
        { name: "Los Angeles", timeZone: "America/Los_Angeles" },
        { name: "New York", timeZone: "America/New_York" },
        { name: "London", timeZone: "Europe/London" },
        { name: "Dubai", timeZone: "Asia/Dubai" },
        { name: "Sydney", timeZone: "Australia/Sydney" }
    ],
    cityTimeZoneOptions: [],
    chats: [],
    isTaskLoading: false,
    isProjectsLoading: false,
    isChatLoading: false,
    quick_note: {
        content_status: null
    }
}

export default function dashboard(state = initialState, action) {
    switch (action.type) {
        case dashboardConstant.UPDATE_REM_TEXT:
            return {
                ...state,
                rText: action.data
            }

        case dashboardConstant.UPDATE_R_TEXT_R:
            return {
                ...state,
            }
        case dashboardConstant.UPDATE_R_TEXT_S:
            return {
                ...state,
            }
        case dashboardConstant.UPDATE_R_TEXT_F:
            return {
                ...state,
            }

        case dashboardConstant.GET_D_TASKS_R:
            return {
                ...state,
                tasks: [],
                isTaskLoading: true
            }
        case dashboardConstant.GET_D_TASKS_S:
            return {
                ...state,
                tasks: action.data.data && action.data.data.data ? action.data.data.data : [],
                isTaskLoading: false
            }
        case dashboardConstant.GET_D_TASKS_F:
            return {
                ...state,
                tasks: [],
                isTaskLoading: false
            }

        case dashboardConstant.GET_D_CHATS_R:
            return {
                ...state,
                chats: [],
                isChatLoading: true
            }
        case dashboardConstant.GET_D_CHATS_S:
            return {
                ...state,
                chats: action.data.data,
                isChatLoading: false
            }
        case dashboardConstant.GET_D_CHATS_F:
            return {
                ...state,
                chats: [],
                isChatLoading: false
            }

        case dashboardConstant.GET_D_PROJECTS_R:
            return {
                ...state,
                projects: [],
                isProjectsLoading: true
            }
        case dashboardConstant.GET_D_PROJECTS_S:
            return {
                ...state,
                projects: action.data.data,
                isProjectsLoading: false
            }
        case dashboardConstant.GET_D_PROJECTS_F:
            return {
                ...state,
                projects: [],
                isProjectsLoading: false
            }

        // World Clock Data
        case dashboardConstant.GET_D_WORLD_CLOCK_R:
            return {
                ...state
            }
        case dashboardConstant.GET_D_WORLD_CLOCK_S:
            return {
                ...state,
                allCities: action.data.data && action.data.data.clock ? action.data.data.clock : initialState.allCities
            }
        case dashboardConstant.GET_D_WORLD_CLOCK_F:
            return {
                ...state,
                allCities: initialState.allCities

            }
        // Time Zone Options
        case dashboardConstant.GET_C_TIME_ZONE_OPTIONS_R:
            return {
                ...state
            }
        case dashboardConstant.GET_C_TIME_ZONE_OPTIONS_S:
            return {
                ...state,
                cityTimeZoneOptions: action.data.data ? action.data.data : []
            }
        case dashboardConstant.GET_C_TIME_ZONE_OPTIONS_F:
            return {
                ...state,
                cityTimeZoneOptions: initialState.cityTimeZoneOptions
            }

        // Update World Clock Data
        case dashboardConstant.GET_D_SAVE_WORLD_CLOCK_R:
            return {
                ...state
            }
        case dashboardConstant.GET_D_SAVE_WORLD_CLOCK_S:
            return {
                ...state
            }
        case dashboardConstant.GET_D_SAVE_WORLD_CLOCK_F:
            return {
                ...state,
                allCities: initialState.allCities

            }
        case dashboardConstant.GET_QUICK_NOTE_R:
            return {
                ...state,
                quick_note: {
                    id: null,
                    content: null,
                    content_status: null
                }
            }
        case dashboardConstant.GET_QUICK_NOTE_S:
            return {
                ...state,
                quick_note: {
                    ...action?.data,
                    content_status: true
                }
            }

        default:
            return state
    }
}
