import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { Box, Card } from "@material-ui/core";

// Import Actions

// Import Styles
import style from "./style";


function CardsSkeleton(props) {
    
    return (
        <Box margin={1} width="24%">
            <Card className={"p-2"}>
                <Skeleton variant="text" className="mb-2" />
                <Skeleton variant="rect" height={118} className="mb-2"/>
                <Skeleton variant="circle" width={40} height={40} className="mb-1" />
                <Skeleton variant="text" />
            </Card>
        </Box>
    )
}

// default props
CardsSkeleton.defaultProps = {
    classes: {}
};

// prop types
CardsSkeleton.propTypes = {
    classes: PropTypes.object
};

// Export component
export default withStyles(style)(CardsSkeleton)
