import _ from 'lodash'
import { Typography, Link } from '../../components'

/**
 * A method to validate the project creation against userlimitations
 * @param {*} params payload to api create project
 * @param {*} cur_state current state details
 */
export const validateUserPlan = (params, cur_state, type = 'project') => {
    const { userLimits } = cur_state.userAgency
    const { planData } = userLimits
    const { user_count } = planData
    const { team_users, new_users } = params

    const total_requested_users = [
        ...(team_users || []).map(m => m.value),
        ...(new_users || [])
    ]

    if (total_requested_users.length > 0) {
        const exists_users = (userLimits[`${type}Users`] || [])
        const newly_req_users = _.difference(
            total_requested_users,
            exists_users
        )
        const projected_user_count = exists_users.length + newly_req_users.length;
        
        return {
            isValid: true,
            message: ''
        };
        
        // if (projected_user_count > user_count) {
        //     return {
        //         isValid: false,
        //         message: <Typography variant="body2" color="textSecondary" className="errorText">
        //         You have exceeds the user limit. Please <Link href={`#/upgradePlan`}>upgrade your plan</Link> to continue this action.
        //     </Typography>
        //     }
        // } else {
        //     return {
        //         isValid: true,
        //         message: ''
        //     }
        // }
    } else {
        return {
            isValid: true,
            message: ''
        }
    }
}