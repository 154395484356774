import React, { Fragment, useEffect, useState, useRef, useMemo } from "react";
import { Grid, IconButton, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { isIOS } from "react-device-detect";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import CloseIcon from "@material-ui/icons/Close";
import { useLocation } from "react-router-dom";

//Import component
import Popover from "@material-ui/core/Popover";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  Button,
  CountdownTimer,
  NotificationTile,
  Typography
} from "../../components";
import WorkspacePopUpError from "../Popups/WorkspacePopupError";
import ExcessUsrPopup from "../UpdatePlan/ExcessUsrPopup";
import GlobalSearch from "./GlobalSearch";
import Notification from "./Notification";
import Settings from "./Settings";
//import Images
import Logo from "../../assets/images/Icon_LightBG.png";

//import Styles
import HeaderStyles from "./HeaderStyles";

//import history
import history from "../../config/history";

//Import Actions
import { updateAction, userAgencyAction } from "../../redux/actions";
import { profileConstant } from "../../redux/constants";

// import constants
import { infoBarsContent, infoBarsLinks } from "../../config/constants";

import {
  BoardMenuIconNew,
  BrainIcon,
  CalendarIconNew,
  ChatMenuIcon,
  ClientMenuIcon,
  DashboardIcon,
  FlowsMenuIcon,
  FunnelIcon,
  InvoicesMenuIcon,
  MyTasksIcon,
  PagebuilderIcon,
  BookingIcon,
  LoginICon,
  CloseIcon2,
  CalendarMenuIcon,
} from "../../assets/svg";
// import{globalSearchHeader} from '../../../assets/svg'
import { getDefaultChatRoomPreference, getLocalStorage } from "../../utils";
import { NotificationTilev2 } from "../Chatv2/NotificationTilev2";
import "./CountdownTimer.css";
import { _envParams } from "../../config/env_params";
import { pageBuilderAction } from "../../redux/actions/pageBuilderAction";
import customButtonStyles from "../../assets/customButton";

moment.locale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "now",
    ss: "%ds",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1M",
    MM: "%dM",
    y: "1Y",
    yy: "%dY",
  },
});

function Header(props) {
  const dispatch = useDispatch();
  //get props
  const { classes, isPublic, showMenu } = props;
  const { pathname: locate } = useLocation();
  const { userAgencies } = useSelector((s) => s.userAgency);
  const { profile } = useSelector((s) => s.profile);
  const { channel } = useSelector(s => (s.chatv2));
  const { is_client } = useSelector(s => s.auth.loginUser)
  const headerContainer = useRef(null);
  const navBarIcons = useRef(null);
  const logoContainer = useRef(null);
  const hiddenTabs = useRef(null);

  const {
    loginUser: {
      ag_owner,
      id,
      user_role,
      signupData,
      agency_pref,
      status,
      is_client: isClient,
    },
  } = useSelector((s) => s.auth);
  const [tabList, setTabList] = useState([]);
  const [page, setPage] = useState("");
  const [tabPopoverList, setTabPopoverList] = useState([]);
  const [hideBookingBanner, setHideBookingBanner] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const themeType = getLocalStorage("themeType") || "light";
  const [openSearch, setOpenSearch] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const openSiteUpdateBar = JSON?.parse(getLocalStorage("updateSiteBar"));
  const [openBar, setOpenBar] = useState(openSiteUpdateBar)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const moreOptionsPopupId = open ? "more-options-popover" : undefined;

   // validating signUp process track
   const [signUpInComplete, setSignUpInComplete] = useState(null)

    useEffect(()=>{
        if(signupData && (signupData["step_2"] === 0 || signupData['step_3'] === 0)){
            setSignUpInComplete(true)
        } else{
            setSignUpInComplete(false)
        }
    },[signupData])

   // handling tab change only when signUp is completed
  const handleTabClick = (route)=>{
    if(!signUpInComplete){
      history.push(route);
    }
  }

  const menuMap = {
    "dash": [
      {
        label: "Dashboard",
        Icon: <DashboardIcon />,
        onClick: () => {
          handleTabClick("/dashboard");
        },
        route: "dashboard",
        order: 0
      },
      {
        label: "Tasks",
        Icon: <MyTasksIcon />,
        route: "tasks",
        onClick: () => {
          handleTabClick("/tasks");
        },
        order: 4
      }
    ],
    "cHub": [
      {
        label: "Chat",
        Icon: <ChatMenuIcon />,
        route: "chat",
        onClick: () => {
          handleTabClick(`/chat/${getDefaultChatRoom()}`);
        },
        order: 1
      }
    ],
    "pjt": [
      {
        label: "Flows",
        Icon: <FlowsMenuIcon />,
        route: "projects",
        onClick: () => {
          handleTabClick(`/projects`);
        },
        order: 2
      }
    ],
    "cli": [
      {
        label: "Clients",
        Icon: <ClientMenuIcon />,
        route: "clients",
        onClick: () => {
          handleTabClick(`/clients`);
        },
        order: 3
      },
      {
        label: "Brain",
        Icon: <BrainIcon />,
        route: "brain",
        onClick: () => {
          handleTabClick("/brain");
        },
        order: 6
      }
    ],
    "boards": [
      {
        label: "Boards",
        Icon: <BoardMenuIconNew />,
        route: "boards",
        onClick: () => {
          handleTabClick("/boards");
        },
        order: 5
      },
    ],
    "booking":[{
      label: "Booking",
      Icon: <BookingIcon />,
      route: "booking",
      onClick: () => {
        history.push(`/booking`);
      },
      order: 10
    }],
    "invoice": [{
      label: "Invoices",
      Icon: <InvoicesMenuIcon />,
      route: "invoices",
      onClick: () => {
        handleTabClick("/invoices");
      },
      order: 8
    }],
    "client_default": [
      {
        label: "Dashboard",
        Icon: <DashboardIcon />,
        route: "clients",
        onClick: () => {
          handleTabClick("/clients");
        },
        order: 1
      },
      {
        label: "Chat",
        Icon: <ChatMenuIcon />,
        route: "chat",
        onClick: () => {
          handleTabClick(`/chat/${getDefaultChatRoom()}`);
        },
        order: 2
      }
    ],
    "default": [
      {
        label: "Calendar",
        Icon: <CalendarMenuIcon />,
        route: "calendar",
        onClick: () => {
          handleTabClick("/calendar");
        },
        order: 9
      },
    ],
    "emp_admin": [
      {
        label: "Pages",
        Icon: <PagebuilderIcon height={18} width={18} />,
        route: "page-builder",
        onClick: () => {
          handleTabClick("/page-builder");
        },
        order: 7
      },
      {
        label: "Funnel",
        Icon: <FunnelIcon />,
        route: "funnel",
        onClick: () => {
          handleTabClick("/funnel");
        },
        order: 11
      },
    ]
  }

  const handleResize = () => {
    setDimensions({
      width: hiddenTabs.current?.offsetWidth,
      height: hiddenTabs.current?.offsetHeight,
    });
  }

  const getOptions = () => {
    let capacity = 0;
    let total_width = 0;
    let allOptions = [...menuOptions]
    let menuItems = [allOptions, []];
    if (
      hiddenTabs &&
      hiddenTabs.current &&
      hiddenTabs.current.childNodes
    ) {

      let availableSpace = headerContainer.current.offsetWidth - (logoContainer.current.offsetWidth + navBarIcons.current.offsetWidth + 160)
      hiddenTabs.current.childNodes.forEach((child, index) => {
        total_width = total_width + child.offsetWidth;
        if (total_width > availableSpace && capacity === 0) {
          capacity = index - 1;
        }
      })
      if (capacity !== 0) {
        const outsideOptions = allOptions.slice(0, capacity);
        const moreOptions = allOptions.slice(capacity, allOptions.length);
        menuItems = [outsideOptions, moreOptions]
      }
    }
    return menuItems;
  }

  const getAllOptions = () => {
    let options = [];
    if (user_role && JSON.stringify(user_role) != '{}') {
      if (!isClient) {
        Object.keys(user_role || {}).forEach(key => {
          if (menuMap[key]) {
            options = [...options, ...menuMap[key]]
          }

        })
      } else {
        options = [...options, ...menuMap['client_default']];
      }
      options = [...options, ...menuMap['default']]
    }
    if (user_role?.type === "admin" || user_role?.type === "emp_admin") {
      options = [...options, ...menuMap['emp_admin']];
    }
    options.sort((a, b) => a.order - b.order);
    return options;
  }

  useEffect(() => {
    const observer = new ResizeObserver(() => { handleResize() });
    observer.observe(hiddenTabs.current)
    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (hiddenTabs.current && user_role) {
      const [options, moreOptions] = getOptions()
      if (options && moreOptions) {
        setTabList(options);
        setTabPopoverList(moreOptions);
      }
    }
  }, [isClient, dimensions, openSearch, menuOptions])

  useEffect(() => {
    const options = getAllOptions();
    setMenuOptions(options);
  }, [user_role])

  useEffect(() => {
    let currentRoute = locate.split('/');
    // agenciflow.com/#/{current_route} = currentRoute[1]
    if (currentRoute.length > 1 && currentRoute[1] != '') {
      setPage(currentRoute[1])
    } else {
      setPage('dashboard')
    }
  }, [locate]);

  // useEffect(() => {
  //   if (signupData && signupData["step_2"] === 0) {
  //     history.push("/agencyAdd");
  //   } else if (
  //     status === 1 &&
  //     ag_owner === id &&
  //     signupData &&
  //     signupData["step_3"] === 0
  //   ) {
  //     localStorage.setItem("newID1", agency_pref);
  //     history.push("/agencyAdd/payment");
  //   }
  // }, [dispatch, signupData, locate]);

  const handleHomePage = () => {
    const page = isPublic
      ? "/"
      : user_role && user_role["dash"]
        ? "/dashboard"
        : "/clients";
    if(!signUpInComplete){
      history.push(page);
    }
  };

  const viewMessageClick = (message) => {
    dispatch(updateAction.update(profileConstant.UPDATE_NEW_MESSAGE, {}));
    if (
      message &&
      message.agency_id &&
      agency_pref === parseInt(message.agency_id)
    ) {
      const { moduleType } = message;
      const pageName =
        moduleType === "project"
          ? "projects"
          : moduleType === "client"
            ? "clients"
            : "team";
      const columnName =
        moduleType === "project"
          ? "job_id"
          : moduleType === "client_id"
            ? "clients"
            : "group_id";
      const roomId = message[`${columnName}`];

      history.push(`/chat/${pageName}${roomId ? `?id=${roomId}` : ""}`);
    } else {
      // switch to another workspace and redirect to chat page
      dispatch(
        userAgencyAction.updateUserAgencies(
          { agency_id: message.agency_id },
          (res) => {
            if (res && res.message === "Updated Successfully") {
              const { moduleType } = message;
              const pageName =
                moduleType === "project"
                  ? "projects"
                  : moduleType === "client"
                    ? "clients"
                    : "team";
              const columnName =
                moduleType === "project"
                  ? "job_id"
                  : moduleType === "client_id"
                    ? "clients"
                    : "group_id";
              const roomId = message[`${columnName}`];
              history.push(`/chat/${pageName}${roomId ? `?id=${roomId}` : ""}`);
              window.location.reload();
            }
          }
        )
      );
    }
  };

  const getDefaultChatRoom = () => {
    const { type } = getDefaultChatRoomPreference(is_client);
    const chatRoomId = channel && channel[type] && channel[type][0] && channel[type][0].hasOwnProperty("id") ? channel[type][0].id : null;
    if (chatRoomId) {
      return `${type}?id=${chatRoomId}`;
    }
    return type
  }


  const projectScaleInfoBar = localStorage.getItem("projectScaleInfoBar");
  const [showNotify, setShowNotify] = useState(
    projectScaleInfoBar === "1" ? false : true
  );

  const handleNotificationClick = () => {
    setShowNotify(!showNotify);
    localStorage.setItem("projectScaleInfoBar", "1");
  };

  const memoSettings = useMemo(() => (
    <Settings user_role={user_role} isPublic={isPublic} is_Client={isClient} />
  ), [user_role])

  const endDateTime = new Date("May 16, 2022 12:00:00").getTime();

  // chat hub page route
  let showExpBar = profile.expiry
    ? moment().format("YYYY-MM-DD") > profile.expiry
    : false;

  return (
    <>
      {showNotify && !isPublic && endDateTime >= new Date().getTime() ? (
        <Grid container className={classes.mentorShipBar}>



          <Link
            href={infoBarsLinks.projectScale}
            target="_blank"
            className="growOne"
          >
            <Grid className={`growOne ${classes.proScaleInfoBar}`}>
              <b>{infoBarsContent.projectScale}</b>
              <CountdownTimer targetDate={endDateTime} />
              {/* <b>Click here</b> */}
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                title={"Click Here"}
              ></Button>
            </Grid>
          </Link>
          <IconButton onClick={() => handleNotificationClick()}>
            <CloseIcon />
          </IconButton>
        </Grid>
      ) : null}


      {/* Book call  */}
      {!isClient && _envParams.REACT_APP_BUILD_FOR !== 'MOBILE' && !isPublic && !((localStorage.getItem("hide_booking_banner")) || hideBookingBanner) &&
        <Grid container justifyContent="center" alignItems="center" className={classes.onboardingBar}>
          <Typography variant="h6" className="pr-3" style={{ color: '#000 !important' }}>Welcome to Flozy! Book Your Onboarding Call</Typography>
          {/* <Typography variant="h6" className="pr-3">Unlock exponential growth for your agency with cutting-edge tech integration. Join our session and propel your business to new heights!</Typography> */}
          <Button href={"https://calendly.com/isardgz/flozy-onboarding"} target="_blank" className={"btn-fw-600"} variant={"contained"} size={"small"} color={"primary"}>Book Your Call</Button>
          <IconButton className={classes.onboardingBarClose} onClick={() => {
            localStorage.setItem("hide_booking_banner", "true");
            setHideBookingBanner(true);
          }}>
            <CloseIcon2 />
          </IconButton>
        </Grid>}
      {/* Book call  */}


      {!isPublic &&
        !locate.includes("mybills") &&
        user_role === "Admin" &&
        showExpBar ? (
        <Grid container className={classes.mentorShipBar}>
          <Grid className={`growOne ${classes.proScaleInfoBar}`}>
            <b>{infoBarsContent.expiry}</b>
            <Button
              color="secondary"
              size="small"
              onClick={() => {
                history.push(`/mybills`);
              }}
              variant="outlined"
              title={"Click Here"}
            ></Button>
          </Grid>
        </Grid>
      ) : null}

      <Grid
        container
        justifyContent="space-between"
        wrap="nowrap"
        alignItems="center"
        className={`${classes.headerContainer} headerContainer`}
        ref={headerContainer}
      >
        <Grid item className={classes.Logo} ref={logoContainer}>
          <Grid container alignItems="center">
            {profile.custom_app_logo === 1 ? (
              <Avatar
                className={isPublic ? classes.publicLogo : classes.logo}
                alt="Logo"
                src={themeType === "dark" ? (profile.dark_logo ? profile.dark_logo : profile.agency_logo || Logo) : (profile.agency_logo ? profile.agency_logo : profile.dark_logo || Logo)}
                onClick={() => handleHomePage()}
              />
            ) : (
              <img
                className={isPublic ? classes.publicLogo : classes.logo}
                src={isPublic ? (themeType === "dark" ? profile?.dark_logo || Logo : profile?.agency_logo || Logo) : Logo}
                alt="Logo"
                onClick={() => handleHomePage()}
              />
            )}
          </Grid>
        </Grid>
        <Grid item className={classes.navlinkContainer}>
          <Grid className={classes.referenceContainer} ref={hiddenTabs}>
            { !signUpInComplete && menuOptions?.map((tab) => {
              return (
                <Button
                  startIcon={tab.Icon}
                  variant="default"
                  className={page === tab.route ? "active_menu" : ""}
                  color="secondary"
                  title={tab.label}
                  onClick={tab.onClick}
                ></Button>
              );
            })}
          </Grid>
          {!signUpInComplete &&!isPublic && userAgencies?.length > 0 && (
            <Grid
              container
              alignItems="center"
              className={`${classes.navbarLinks} ${"navbarLinks ml-2"}`}
            >
              {tabList.map((tab) => {
                return (
                  <Button
                    startIcon={tab.Icon}
                    variant="default"
                    className={page === tab.route ? "active_menu" : ""}
                    color="secondary"
                    title={tab.label}
                    onClick={tab.onClick}
                  ></Button>
                );
              })}
              {tabPopoverList.length > 0 && (
                <IconButton onClick={handleClick}>
                  <MoreHorizIcon />
                </IconButton>
              )}
              <div>
                <Popover
                  id={moreOptionsPopupId}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  className={classes.PopoverTabs}
                  PaperProps={{
                    style: {
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      padding: "10px",
                      gap: "8px"
                    }
                  }}
                >
                  {tabPopoverList.map((tab) => (
                    <Button
                      startIcon={tab.Icon}
                      variant="default"
                      className={page === tab.route ? "PopoverActive" : ""}
                      color="secondary"
                      title={tab.label}
                      onClick={() => {
                        tab.onClick();
                        handleClose()
                      }}
                    ></Button>
                  ))}
                </Popover>
              </div>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Grid
            container
            alignItems="center"
            className={isPublic ? classes.publicNavBarIcons : classes.navBarIcons}
            ref={navBarIcons}
          >
            {!isPublic && (
              <Fragment>
                { !signUpInComplete && userAgencies && userAgencies.length > 0 ? (
                  <>
                    {/* <globalSearchHeader user_role={user_role} /> */}
                    <GlobalSearch user_role={user_role} />
                    <Notification user_role={user_role} />
                  </>
                ) : null}
                {/* <Options /> */}
                {memoSettings}
                <ExcessUsrPopup />
              </Fragment>
            )}
            {/** commented as per requirement */}
            {isPublic && showMenu && (
              <Fragment>
                <Button
                  size="small"
                  variant="outlined"
                  className="borderWhite pr-1"
                  title="Login"
                  startIcon={<LoginICon />}
                  onClick={() => {
                    history.push("/login");
                  }}
                />
                {!isIOS ? (
                  <Button
                    size="small"
                    variant="outlined"
                    className="borderWhite"
                    title="Start Free Trial"
                    onClick={() => {
                      // history.push("/signup");
                      window.open('https://www.flozy.com/', '_blank');
                    }}
                  ></Button>
                ) : null}
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid className={`${classes.notifcationContainer} notifcationContainer`}>
        <NotificationTile viewMessageClick={viewMessageClick} />
      </Grid>
      <Grid className={`${classes.notifcationContainer} notifcationContainer`}>
        <NotificationTilev2 />
      </Grid>
      {!isPublic ? <WorkspacePopUpError /> : null}
      {/* Remove below code after all site domain are updated **/}
    </>
  );
}

// default props
Header.defaultProps = {
  isPublic: true,
};

// prop types
Header.propTypes = {
  isPublic: PropTypes.bool,
};

export default withStyles(HeaderStyles)(Header);
