
const AuthLayoutStyles = (theme) => ({
    container: {
        width: "100%",
        overflowY: 'auto',
        height: '100%',
        '@media only screen and (max-width: 768px)': {
          '&.MuiGrid-root': {
        height: 'calc(100% - 66px)'
          }
        },
        '& .MuiOutlinedInput-root': {
          background: theme.palette.containers.card
        },
        '& .MuiInputBase-root': {
          color: theme.palette.text.text1
        },
        '& .MuiFormControlLabel-root': {
          '& .MuiTypography-root': {
          color: theme.palette.text.text1
          }
        },
        '& .dontHaveText': {
          color: theme.palette.text.text1
      }
      },
      FooterContainer: {
        width: "100%",
      },
      authBodyCotainer: {
        height: 'calc(100vh - 0px)',
        overflowY: 'auto',
        background: theme.palette.containers.body
      },
      loginLeftContainer: {
        background: '#2563EB',
        height: '100%',
        padding: '30px',
        '& .slick-slider': {
          display: 'flex',
          alignItems: 'center',
          height: 'calc(100vh - 130px)'
        },
        '& .slick-slide img': {
          margin: 'auto',
          paddingBottom: '24px',
          paddingTop: '30px',
        },
        '& .slick-list': {
          width: '100%'
        },
        '& .slick-dots li button:before': {
          fontSize: '10px',
          color: '#fff'
        },
        '& .slick-dots li': {
          margin: '0 2px'
        },
        '& .MuiTypography-root': {
          color: '#fff !important'
        },
        '& .description': {
          paddingTop: 10,
          color: '#F8FAFC !important'
        },
        '@media only screen and (max-width: 768px)': {
          '&.MuiGrid-root': {
            display: 'none'
          }
        },
        
      },
      logo: {
        maxWidth: '100%',
        height: 34,
        width: 34,
        objectFit: 'cover',
        cursor: 'pointer',
    },
      mobileLogo: {
        display: 'none',
        padding: '16px 0px',
        '@media only screen and (max-width: 768px)': {
          '&.MuiGrid-root': {
            display: 'flex !important'
          }
        }
      }
});

export default AuthLayoutStyles;
