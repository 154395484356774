// Alert reducer types
export const chatConstant = {
    GET_JOB_CHAT_R: "GET_JOB_CHAT_R",
    GET_JOB_CHAT_S: "GET_JOB_CHAT_S",
    GET_JOB_CHAT_F: "GET_JOB_CHAT_F",

    UPDATE_NEW_MESSAGE_S: "UPDATE_NEW_MESSAGE_S",

    //update file
    UPLOAD_JOB_FILE_R: "UPLOAD_JOB_FILE_R",
    UPLOAD_JOB_FILE_S: "UPLOAD_JOB_FILE_S",
    UPLOAD_JOB_FILE_F: "UPLOAD_JOB_FILE_F",

    //Get Job Search Messages
    GET_JOB_SEARCH_MESSAGES_R: "GET_JOB_SEARCH_MESSAGES_R",
    GET_JOB_SEARCH_MESSAGES_S: "GET_JOB_SEARCH_MESSAGES_S",
    GET_JOB_SEARCH_MESSAGES_F: "GET_JOB_SEARCH_MESSAGES_F",

    //Get Job Media Messages
    GET_JOB_MEDIA_MESSAGES_R: "GET_JOB_MEDIA_MESSAGES_R",
    GET_JOB_MEDIA_MESSAGES_S: "GET_JOB_MEDIA_MESSAGES_S",
    GET_JOB_MEDIA_MESSAGES_F: "GET_JOB_MEDIA_MESSAGES_F",


    //Get Client Chat Message
    GET_CLIENT_CHAT_R: "GET_CLIENT_CHAT_R",
    GET_CLIENT_CHAT_S: "GET_CLIENT_CHAT_S",
    GET_CLIENT_CHAT_F: "GET_CLIENT_CHAT_F",

    UPDATE_CLIENT_NEW_MESSAGE_S: "UPDATE_CLIENT_NEW_MESSAGE_S",

    //update client chat file
    UPLOAD_CLIENT_FILE_R: "UPLOAD_CLIENT_FILE_R",
    UPLOAD_CLIENT_FILE_S: "UPLOAD_CLIENT_FILE_S",
    UPLOAD_CLIENT_FILE_F: "UPLOAD_CLIENT_FILE_F",

    //Get Client chat Search Messages
    GET_CLIENT_SEARCH_MESSAGES_R: "GET_CLIENT_SEARCH_MESSAGES_R",
    GET_CLIENT_SEARCH_MESSAGES_S: "GET_CLIENT_SEARCH_MESSAGES_S",
    GET_CLIENT_SEARCH_MESSAGES_F: "GET_CLIENT_SEARCH_MESSAGES_F",

    //Get Client chat Media Messages
    GET_CLIENT_MEDIA_MESSAGES_R: "GET_CLIENT_MEDIA_MESSAGES_R",
    GET_CLIENT_MEDIA_MESSAGES_S: "GET_CLIENT_MEDIA_MESSAGES_S",
    GET_CLIENT_MEDIA_MESSAGES_F: "GET_CLIENT_MEDIA_MESSAGES_F",


    //Get Team Chat Message
    GET_TEAM_CHAT_R: "GET_TEAM_CHAT_R",
    GET_TEAM_CHAT_S: "GET_TEAM_CHAT_S",
    GET_TEAM_CHAT_F: "GET_TEAM_CHAT_F",

    UPDATE_TEAM_NEW_MESSAGE_S: "UPDATE_TEAM_NEW_MESSAGE_S",

    //update Team Chat file
    UPLOAD_TEAM_FILE_R: "UPLOAD_TEAM_FILE_R",
    UPLOAD_TEAM_FILE_S: "UPLOAD_TEAM_FILE_S",
    UPLOAD_TEAM_FILE_F: "UPLOAD_TEAM_FILE_F",

    //Get Team Chat Search Messages
    GET_TEAM_SEARCH_MESSAGES_R: "GET_TEAM_SEARCH_MESSAGES_R",
    GET_TEAM_SEARCH_MESSAGES_S: "GET_TEAM_SEARCH_MESSAGES_S",
    GET_TEAM_SEARCH_MESSAGES_F: "GET_TEAM_SEARCH_MESSAGES_F",

    //Get Team Chat Media Messages
    GET_TEAM_MEDIA_MESSAGES_R: "GET_TEAM_MEDIA_MESSAGES_R",
    GET_TEAM_MEDIA_MESSAGES_S: "GET_TEAM_MEDIA_MESSAGES_S",
    GET_TEAM_MEDIA_MESSAGES_F: "GET_TEAM_MEDIA_MESSAGES_F",

    //Update New Chat Notifications
    UPDATE_CHAT_NOTIFICATION_MESSAGE_S: "UPDATE_CHAT_NOTIFICATION_MESSAGE_S",

    //Delete Chat Message
    DELETE_CHAT_MESSAGES_R: "DELETE_CHAT_MESSAGES_R",
    DELETE_CHAT_MESSAGES_S: "DELETE_CHAT_MESSAGES_S",
    DELETE_CHAT_MESSAGES_F: "DELETE_CHAT_MESSAGES_F",

    // Update chat after deleting
    UPDATE_PROJECT_CHAT_DELETE: "UPDATE_PROJECT_CHAT_DELETE",
    UPDATE_CLIENT_CHAT_DELETE: "UPDATE_CLIENT_CHAT_DELETE",
    UPDATE_TEAM_CHAT_DELETE: "UPDATE_TEAM_CHAT_DELETE",

    //update online user list
    UPDATE_PROJECT_ONLINE_USER_S: "UPDATE_PROJECT_ONLINE_USER_S",
    UPDATE_CLIENT_ONLINE_USER_S: "UPDATE_CLIENT_ONLINE_USER_S",
    UPDATE_TEAM_ONLINE_USER_S: "UPDATE_TEAM_ONLINE_USER_S",

    // Star Job Message
    STAR_CHAT_T_MESSAGES_R: "STAR_CHAT_T_MESSAGES_R",
    STAR_CHAT_T_MESSAGES_S: "STAR_CHAT_T_MESSAGES_S",
    STAR_CHAT_T_MESSAGES_F: "STAR_CHAT_T_MESSAGES_F",

    //Star Project Messages
    STAR_CHAT_P_MESSAGES_R: "STAR_CHAT_P_MESSAGES_R",
    STAR_CHAT_P_MESSAGES_S: "STAR_CHAT_P_MESSAGES_S",
    STAR_CHAT_P_MESSAGES_F: "STAR_CHAT_P_MESSAGES_F",

    //Star Client Messages
    STAR_CHAT_C_MESSAGES_R: "STAR_CHAT_C_MESSAGES_R",
    STAR_CHAT_C_MESSAGES_S: "STAR_CHAT_C_MESSAGES_S",
    STAR_CHAT_C_MESSAGES_F: "STAR_CHAT_C_MESSAGES_F",

    UPDATE_JOB_READ_MESSAGE_ON_LOAD: "UPDATE_JOB_READ_MESSAGE_ON_LOAD",
    UPDATE_CLIENT_READ_MESSAGE_ON_LOAD: "UPDATE_CLIENT_READ_MESSAGE_ON_LOAD",
    UPDATE_TEAM_READ_MESSAGE_ON_LOAD: "UPDATE_TEAM_READ_MESSAGE_ON_LOAD",
};
