/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Grid, withStyles } from "@material-ui/core";
import { isMobile, isIOS, isBrowser, mobileModel } from 'react-device-detect';
import { PushNotifications } from '@capacitor/push-notifications';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';

// Layout Style
import PrivateLayoutStyles from "./PrivateLayoutStyles";

// Import Child Container
import Header from "../../containers/Header/Header";
import MobileFooter from '../../containers/Footer/MobileFooter'
import NotificationPermission from "../../components/NotificationPermissionComponent";
import CookiesComponent from "../../components/CookiesComponent";

// Import Config Layout
// import { getPrivateContainerHeight } from "../../config/layout";

//Import Action
import { profileAction, authAction } from "../../redux/actions"

import { requestFirebaseNotificationPermission, onMessageListener } from '../../firebaseInit';
import Shortcuts from "../../components/Shortcuts/KeyboardShortcuts";


const PrivateLayoutMobile = (props) => {
  const { match, classes } = props;
  const dispatch = useDispatch();

  let safeAreaTopHeight = getComputedStyle(document.documentElement).getPropertyValue("--sat");

  const setStatusBarStyleDark = async () => {
    try {
      await StatusBar.setStyle({ style: Style.Dark });
    } catch (err) {
      console.log(err)
    }
  };



  // Set Container Height
  // let height = getPrivateContainerHeight();

  if (window.innerWidth <= 768) {
    // height = height - 50;
  }

  if (isIOS && isMobile && !isBrowser && mobileModel === "iPhone") {
    // height = height - parseInt(safeAreaTopHeight.replace("px", ""));
  }

  requestFirebaseNotificationPermission()
    .then((firebaseToken) => {

      //save token into db
      dispatch(profileAction.saveWebBrowserToken({ token: firebaseToken }, () => {
        //save isTokenSet or Not in LocalStorage;
        localStorage.setItem('AF_Token_SentToServer', 1);
        localStorage.setItem('notificationToken', firebaseToken);
      }))
    })
    .catch((err) => {
      return err;
    });

  onMessageListener()
    .then((payload) => {

      const { title, body, icon, image, tag } = payload.notification;
      const notificationTitle = title;
      const notificationOptions = {
        body: body,
        icon: icon,
        image: image,
        tag: tag,
        title: title
      };
      // alert(`${title}=>${body}`);
      new Notification(notificationTitle, notificationOptions);
    })
    .catch((err) => {
      // alert(JSON.stringify(err));

    });


  //Notification
  const [allowCookie, setAllowCookie] = useState(false);
  const [allowNotify, setAllowNotify] = useState(false);
  const [keyboardOpen, setKeyboardOpen] = useState(false);

  let cookie = localStorage.getItem('myPolicy');
  if (cookie !== "PrivacyPolicy") {
    setTimeout(() => {
      setAllowCookie(true);
    }, 2000);
  }

  const onAcceptCookie = () => {
    localStorage.setItem('myPolicy', 'PrivacyPolicy');
    setAllowCookie(false);
  }

  const onAcceptNotify = () => {
    // cookies.set('myPolicy', 'PrivacyPolicy', { path: '/' });
    setAllowNotify(false);
  }

  const onNotAcceptNotify = () => {
    setAllowNotify(false);
  }

  //Capacitor Push notification
  useEffect(() => {
    setKeyboardEvents();
    PushNotifications.checkPermissions().then((res) => {
      if (res.receive !== 'granted') {
        PushNotifications.requestPermissions().then((res) => {
          if (res.receive === 'denied') {
            alert('Push Notification permission denied');
          }
          else {
            // alert('Push Notification permission granted');
            register();
          }
        });
      }
      else {
        register();
      }
    });
  }, [])

  const setKeyboardEvents = () => {
    Keyboard.addListener('keyboardDidShow', info => {
      setKeyboardOpen(true);
    });
    Keyboard.addListener('keyboardDidHide', () => {
      setKeyboardOpen(false);
    });
  }

  const register = () => {

    // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token) => {

        if (token && token.value) {
          //save token into db
          dispatch(profileAction.saveIosAppToken({ token: token.value }, () => {
            //save isTokenSet or Not in LocalStorage;
            localStorage.setItem('AF_IOS_Token_SentToServer', 1);
            localStorage.setItem('notificationToken', token.value);
          }))
        }
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error) => {
        // dispatch(profileAction.saveIosAppToken({ token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c" }));
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      async (notificationResult) => {
        // alert(notification);
        //setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      async (notificationResult) => {
        // alert('Push action performed: ' + JSON.stringify(notificationResult));
        // alert("data => action type: " + notificationResult.notification.data.actionType+", jobId =: "+ notificationResult.notification.data.jobId+ ", url=>"+ notificationResult.notification.data.url)
        const data = notificationResult.notification.data;
        // alert("Data=> " + data.actionType + ", url=>"+ data["url"]);
        const page = data && data.actionType === "project" ? "projects" : (data.actionType === "client" ? "clients" : "team");
        const launchUrl = data.url || `/chat/${page}?id=${data.jobId}`;
        const launchUrlArr = launchUrl.split('/#')
        const redirectUrl = launchUrlArr?.length >= 2 ? launchUrlArr[1] : launchUrl;

        // alert("redirect url =>"+ redirectUrl);
        // alert('Push action performed: ' + JSON.stringify(notificationResult));

        dispatch(authAction.pageRedirect({ redirectUrl }, (res) => {
          // console.log(res)
        }))
        // history.push(redirectUrl);
        //setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
      }
    );
  }
  //Capacitor Push notification End

  /**
  * Handle Header Menu Action
  */
  return (
    <div className={`${classes.mobilePrivateLayout} h-100 ${keyboardOpen ? "keyboard-open" : ''}`}>
      <Shortcuts />
      <div className="h-100">
        <div className="mobileHeader">
          <Header match={match} isPublic={false} />
        </div>
        <div
          className={`${classes.container} mobileBody`}
          id="privateLayoutDiv"
        >
          {props.children}
        </div>
        <div className="mobileFooter">
          <MobileFooter />
        </div>
      </div>
      <CookiesComponent
        openStatus={allowCookie}
        message={
          <>
            <span>
              By clicking “ACCEPT”, you agree to the storing of first- and third-party cookies.{' '}
              <a href="https://www.flozy.com/privacy-policy" target="_blank" rel="noopener noreferrer">
                Read our cookie policy
              </a>
            </span>
          </>
        }
        actionMessage="Accept"
        onActionClick={onAcceptCookie}
      />
      <NotificationPermission
        openStatus={allowNotify}
        message={"We'd like to show you notifications."}
        actionMessage="ALLOW"
        actionMessage2="NO THANKS"
        onActionClick={onAcceptNotify}
        onActionClick2={onNotAcceptNotify}
      />
    </div>
    // <Grid container className={`${isIOS && isMobile ? "notchContainer" : null} h-100 flexBasis0 ${keyboardOpen ? "keyboard-open" : ''}`} direction="column" wrap="nowrap">
    //   <Shortcuts />
    //   {
    //     isIOS && isMobile && !isBrowser && mobileModel === "iPhone" ?
    //       <Grid item xs={12} style={{ height: `${safeAreaTopHeight}` }} className="notchGrid flexBasis0 " /> : null
    //   }
    //   <Grid item xs={12} className="flexBasisAuto">
    //     <Header match={match} isPublic={false} />
    //   </Grid>
    //   <Grid item xs={12} className="growOne overflowYAuto w-100" id="privateLayoutDiv">
    //     <Grid
    //       container
    //       className={`${classes.container} h-100`}
    //     // style={{ height: height }}
    //     // alignContent="baseline"
    //     >
    //       {props.children}
    //     </Grid>
    //   </Grid>
    //   <Grid item xs={12} className="mobile_overall flexBasisAuto">
    //     <MobileFooter />
    //   </Grid>
    //   <CookiesComponent
    //     openStatus={allowCookie}
    //     message={
    //       <>
    //         <span>
    //           By clicking “ACCEPT”, you agree to the storing of first- and third-party cookies.{' '}
    //           <a href="https://www.flozy.com/privacy-policy" target="_blank" rel="noopener noreferrer">
    //             Read our cookie policy
    //           </a>
    //         </span>
    //       </>
    //     }
    //     actionMessage="Accept"
    //     onActionClick={onAcceptCookie}
    //   />
    //   <NotificationPermission
    //     openStatus={allowNotify}
    //     message={"We'd like to show you notifications."}
    //     actionMessage="ALLOW"
    //     actionMessage2="NO THANKS"
    //     onActionClick={onAcceptNotify}
    //     onActionClick2={onNotAcceptNotify}
    //   />
    // </Grid>
  );
}

// Define Props Types
PrivateLayoutMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired
};

// Default props
PrivateLayoutMobile.defaultProps = {
  classes: {},
  children: {},
  user: {},
  access: false
};

export default withStyles(PrivateLayoutStyles)(PrivateLayoutMobile);
