const ChatStyles = (theme) => ({
  NotificationIcon: {
    width: "16px",
    height: "18px",
    flexShrink: "0",
    fontSize: 14,
  },
  ChatNotificationsBox: {
    // background: "black",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    background: theme.palette.containers.card,
    // border: `1px solid ${theme.palette.primary.border1}`,
    width: "100%",
    maxWidth: "435px",
    // height: "551px",
    borderRadius: "10px",
    boxShadow:
      "8px 4px 55px 0px rgba(37, 99, 235, 0.04), -8px 25px 55px 0px rgba(37, 99, 235, 0.04)",
    "& .boxHeader": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "16px 16px 0px",
      width: "100%",
    },
    "& .boxBody": {
      // marginLeft: "-1%",
      padding: '0px 10px 10px',
      overflow: "hidden",
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },

    "& .boxFooter": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "1rem",
      width: "100%",
    },
    "& ::before": {
      content: "abc",
      background: "orange",
    },
    '@media only screen and (min-width: 768px)': {
      '&.MuiGrid-root': {
        // minWidth: '435px'
        minWidth:'100%',
        overflowX:'hidden'
      }
    },
    '@media only screen and (min-width: 599px)': {
      overflowY:'auto',
      height:'100%',
      overflowX:'hidden'
    }
  },

  headpop: {
    marginTop: "22px",
    marginLeft: "16px",
    color: "var(--greyscale-900, #0F172A)",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    letterSpacing: "0.2px",
  },
  onCloseicon: {
    // marginLeft: "93%",
    flexShrink: " 0",
    // marginTop: "-25px",
    width: "28px",
    height: "28px",
    border: "black",
    justifyContent: " center",
    alignItems: " center",
    flexShrink: " 0",
    borderRadius: "8px",
    background: theme.palette.containers.bg7,
    padding: '8px'
  },
  buttoncard: {
    marginBottom: "2.5%",
    alignItems: "center",
    width: "398%",
    height: "10px",
    background: "grey",
    borderRadius: "12px",
    background: " #F5F6F9",
    margin: "auto",
  },
  chatbtn: {
    marginLeft: "1.2%",
    marginTop: "2px",
    height: " 32px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: " 0",
    borderRadius: "8px",
    ackground: theme.palette.containers.bg44,
    boxShadow:
      "0px 0px 12px 0px rgba(107, 114, 128, 0.04), 0px 0px 1px 0px rgba(75, 85, 99, 0.04)",
    color: theme.palette.text.text9,
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: " 700",
    lineHeight: "160%",
  },
  taskbtn: {
    width: "fit-content",
    height: "32px",
    padding: "6px 12px",
    marginTop: "3.5px",
    color: theme.palette.text.text10,
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: " 500",
    lineHeight: "160%",
  },
  otherbtn: {
    width: "fit-content",
    height: "31px",
    padding: "6px 12px",
    marginTop: "3.5px",
    color: "#333333",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: " 500",
    lineHeight: "160%",
    marginLeft: "10%",
  },
  // unreadchat: {
  //   marginLeft: "10%",
  //   marginTop: "2px",
  //   width: "100px",
  //   height: "72px",
  //   flexShrink: "0",
  //   background: " #F0F5FF",
  // },
  readchat: {
    width: "100%",
    // height: " 72px",
    padding: '10px',
    flexShrink: "0",
    border: `1px solid ${theme.palette.containers.card}`,
    background: theme.palette.containers.card,
  },
  chatline: {
    marginTop: "-2px",
    marginLeft: "10px",
    width: "402px",
    height: " 1px",
    background: " rgba(0, 0, 0, 0.07)",
  },
  markread: {
    display: "flex",
    marginTop: "18px",
    marginLeft: "5px",
  },
  read: {
    color: "#2563EB",
    fontFamily: " Inter",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "160%",
    '@media only screen and (max-width: 599px)': {
      fontSize: " 10px",
    }
  },
  markreadicon: {
    marginLeft: "3%",
    marginTop: "84%",
    fontWeight: "700px",
  },
  notifybutton: {
    display: "flex",
    width: "177px",
    height: " 35px",
    justifyContent: " center",
    alignItems: " center",
    gap: " 8px",
    flexShrink: " 0",
    borderRadius: " 8px",
    background: "#2563EB",
    boxShadow: "0px 8px 24px 0px rgba(30, 64, 175, 0.08)",
    color: "white",
    fontFamily: " Inter",
    fontSize: "12.5px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    "&:hover": {
      background: "#2563EB",
    },
    '@media only screen and (max-width: 599px)': {
      fontSize: " 10px",
    }
  },
  avatar: {
    border: `2px solid ${theme.palette.containers.card}`,
    // marginLeft: "-36px",
    // marginTop: "10px",
    width: "45px",
    height: "45px",
    borderRadius: "50%",
  },
  message: {
    color: theme.palette.primary.main,
    fontFamily: " Inter",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: " 160%",
    // marginTop:"10px",
    // marginLeft:"37px"
  },
  Imagedot: {
    // marginTop: "-15px",
    width: " 9px",
    height: " 9px",
    flexShrink: "0",
    fill: " #24D164",
    strokeWidth: " 1px",
    stroke: " #FFF",
    position: 'absolute',
    top: '-2px',
    left: '0px'
  },
  unreadchatothers: {
    marginTop: "2px",
    width: "465px",
    height: " 72px",
    flexShrink: "0",
    background: " #F0F5FF",
  },

  Imagedotothers: {
    width: " 9px",
    height: " 9px",
    flexShrink: "0",
    fill: " #24D164",
    strokeWidth: " 1px",
    stroke: " #FFF",
    marginLeft: "-38px",
    marginTop: "-5px",
  },

  msgtime: {
    color: " #64748B",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: " 160%",
    // marginLeft: "37px",
  },
  popup_head: {
    color: theme.palette.primary.main,
    fontFamily: "Inter",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "600",
    letterSpacing: "0.2px",
  },
  ChatNotificationsBox2: {
    width: "100%",
    maxWidth: "435px",
    width: '100%',
    // height: "710px",
    background: theme.palette.containers.card,
    // border: `1px solid ${theme.palette.primary.border1}`,
    flexShrink: 0,
    borderRadius: " 12px",
    borderRadius: "10px",
    // boxShadow: "8px 4px 55px 0px rgba(37, 99, 235, 0.04), -8px 25px 55px 0px rgba(37, 99, 235, 0.04)",
    "& .boxHeader2": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "16px 16px 10px",
      width: "100%",
    },
    "& .boxBody2": {
      // marginLeft: "-1.1%",
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "flex-start",
    },
  },
  popup_head2: {
    color: theme.palette.primary.main,
    fontFamily: "Inter",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: "600",
    letterSpacing: "0.2px",
  },
  onCloseicon2: {
    // marginLeft: "97%",
    flexShrink: " 0",
    // marginTop: "-25px",
    width: "28px",
    height: "28px",
    border: "black",
    justifyContent: " center",
    alignItems: " center",
    flexShrink: " 0",
    borderRadius: "8px",
    background: theme.palette.containers.bg7,
    padding: '8px'
  },
  buttoncard2: {
    marginBottom: "10px",
    alignItems: "center",
    // marginLeft: "1%",
    marginTop: "0.1px",
    width: "100%",
    height: "14px",
    background: "grey",
    borderRadius: "12px",
    background: theme.palette.containers.bg4,
    margin: "auto",
  },
  unreadchat2: {
    // marginTop: "1px",
    flexShrink: "0",
    background: theme.palette.containers.bg4,
    borderBottom: '1px solid #00000012'
  },
  notificationsContainer: {
    maxHeight: "710px",
    width: '100%',
    overflowY: "auto",
  },
  unreadchatothers2: {
    marginTop: "2px",
    // width: "545px",
    height: " 72px",
    flexShrink: "0",
    background: theme.palette.containers.bg4,
  },
  customBadge: {
    color: " #fff",
    marginRight: "45px",
    marginTop: "22%",
    width: " 15px",
    height: "20px",
    fontSize: " 10px",
    background: " #F178B6",
    alignItems: "center",
    borderRadius: "100%",
  },
  customBadgeothers: {
    color: " #fff",
    marginRight: "58px",
    marginTop: "16%",
    width: " 15px",
    height: "20px",
    fontSize: " 10px",
    background: " #F178B6",
    alignItems: "center",
    borderRadius: "100%",
  },
  centeredContainer: {
    marginTop: "15%"
  },
  ChatNotificationsBoxPoper: {
    '& .MuiPaper-elevation8': {
      boxShadow: '-8px 25px 55px 0px #2563EB12 !important'
    }
  }
});

export default ChatStyles;
