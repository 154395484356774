import { baseAction } from "./baseAction";
import { alertConstant, loaderConstant, referralsConstant } from "../constants";
import { referralsService } from "../services";

const { request, success, failure } = baseAction;

// All login based actions
export const referralsAction = {
    getReferrals,
    getReferralData,
    createReferral,
    deleteReferral,
    getPaymentCode,
};

/** Get Referrals */
function getReferrals() {
    return (dispatch) => {
        dispatch(request(referralsConstant.GET_REFERRALS_R));
        dispatch(request(loaderConstant.LOADING_TRUE));
        referralsService.getReferrals().then(response => {
            dispatch(success(referralsConstant.GET_REFERRALS_S, response));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(referralsConstant.GET_REFERRALS_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}


/** Get single referral  */
function getReferralData(id) {
    return (dispatch) => {
        dispatch(request(referralsConstant.GET_ONE_REFERRAL_DATA_R, id));
        dispatch(request(loaderConstant.LOADING_TRUE));
        referralsService.getReferralData(id).then(response => {
            dispatch(success(referralsConstant.GET_ONE_REFERRAL_DATA_S, response));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }, error => {
            dispatch(failure(referralsConstant.GET_ONE_REFERRAL_DATA_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

/** Update Referral */

// /** Delete Referral */
function deleteReferral(params, callBack = () => { }) {
    return (dispatch) => {
        dispatch(request(referralsConstant.DELETE_REFERRAL_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        referralsService.deleteReferral(params).then(data => {
            dispatch(success(referralsConstant.DELETE_REFERRAL_S, data));
            dispatch(request(loaderConstant.LOADING_FALSE));

            if (data.status === "success") {
                dispatch(success(referralsConstant.DELETE_REFERRAL_F, data));
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
            } else {
                dispatch(success(alertConstant.ALERT_ERROR, data.message));
            }
            callBack()
        }, error => {
            // dispatch(failure(referralsConstant.DELETE_USER_ROLE_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        }
        );
    };
}

function createReferral(params) {
    return (dispatch) => {
        dispatch(request(referralsConstant.CREATE_REFERRAL_R, params));
        dispatch(request(loaderConstant.LOADING_TRUE));
        referralsService.createReferral(params).then(data => {
            if (data && data.status === "success") {
                dispatch(success(referralsConstant.CREATE_REFERRAL_S, data))
                dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                dispatch(request(loaderConstant.LOADING_FALSE));
            } else {
                dispatch(failure(alertConstant.ALERT_ERROR, data.message));
                dispatch(request(loaderConstant.LOADING_FALSE));
            }
        }, error => {
            dispatch(failure(referralsConstant.CREATE_REFERRAL_F, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(loaderConstant.LOADING_FALSE));
        })
    }
}

function getPaymentCode(params) {
    return (dispatch) => {
        dispatch(request(referralsConstant.GET_PAYMENT_CODE_R, params));
        referralsService.getPaymentCode(params).then(data => {
            if (data && data.status === "success") {
                dispatch(success(referralsConstant.GET_PAYMENT_CODE_S, data))
            }
        }, error => {
            dispatch(failure(referralsConstant.GET_PAYMENT_CODE_F, error))
        })
    }
}