// Import API Helper
import { getRequest, postRequest } from "../../utils/apiHelper";
export const authService = {
    login,
    socialLogin,
    appleLogin,
    forgetPassword,
    resetPassword,
    logout,
    signUp,
    socialSignUp,
    updateSignUpUserData,
    updatePayment,
    getProfileData,
    getUpdatedStatus,
    signUpKeyValidation,
    getProductsList,
    getGoogleAuthUrl,
    getPaymentStatus
};

//login
async function login(params) {
    const response = await postRequest("auth/login", params);
    return response;
}

//social login (google & facebook)
async function socialLogin(params) {
    const response = await postRequest("auth/socialLogin", params);
    return response;
}

//apple login 
async function appleLogin(params) {
    const response = await postRequest("auth/appleLogin", params);
    return response;
}

//forget password
async function forgetPassword(params) {
    const response = await postRequest("auth/forgetPassword", params);
    return response;
}

//reset password
async function resetPassword(params) {
    const response = await postRequest("auth/resetPassword", params);
    return response;
}

//logout
async function logout(data) {
    const response = await postRequest("auth/logout", data);
    return response;
}

//signup
async function signUp(params) {
    const response = await postRequest("auth/signUp", params);
    return response;
}

//google & facebook signup
async function socialSignUp(params) {
    const response = await postRequest("auth/socialSignUp", params);
    return response;
}

//updateSignUpUserData
async function updateSignUpUserData(params) {
    const response = await postRequest("auth/updateSignUpUserData", params);
    return response;
}

//update paymemt
async function updatePayment(params) {
    if (params && params.data && params.data.newAPI) {
        const response = await postRequest("auth/updatePaymentNew", params);
        return response;
    } else {
        const response = await postRequest("auth/updatePayment", params);
        return response;
    }
}

//update paymemt
async function getProfileData(params) {
    const response = await postRequest("auth/profile", params);
    return response;
}

async function signUpKeyValidation(params) {
    const response = await postRequest("auth/signUpValid", params);
    return response;
}

//getUpdatedStatus
async function getUpdatedStatus(params) {
    const response = await postRequest("auth/getUpdatedStatus", params);
    return response;
}

//getProductsList
async function getProductsList(params) {
    const response = await postRequest("auth/productsList", params);
    return response;
}

async function getGoogleAuthUrl(params) {
    const response = await getRequest(`events/getGoogleAuthUrl/${params}`)
    return response
}

// get payment status
async function getPaymentStatus(params) {
    const response = await postRequest("auth/getPaymentStatus", params)
    return response
}