import { pageBuilderConstant } from "../constants";
import moment from "moment";

const initialState = {
  sites: [],
  site: {},
  page: {},
  templates: [],
  categories: [],
  compresedThumbnials: [],
  page_templates: [],
  analytics: {
    site_id: null,
    filter: {
      from: {
        startDate: new Date(moment().startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(moment().endOf('month').format('YYYY-MM-DD')),
        startDateOnly: moment().startOf('month').format('YYYY-MM-DD'),
        endDateOnly: moment().endOf('month').format('YYYY-MM-DD'),
        key: 'selection'
      },
      to: {
        startDate: new Date(moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')),
        endDate: new Date(moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')),
        startDateOnly: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        endDateOnly: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
        key: 'selection'
      }
    }
  },
  publishedSites: [],
  loading: false,
  siteCustomCode: {
    head: "",
    startOfBody: "",
    endOfBody: ""
  },
  emailConfig: {
    authentication_type: 2,
    service: "other"
  },
  authURL: "",
  siteSeoSettings: {},
  responseList: {
    filter:{}
  },
  responseData: {}
};

export default function pageBuilder(state = initialState, action) {
  switch (action.type) {
    case pageBuilderConstant.GET_SITES_LIST_R:
      return {
        ...state,
        site: {},
        page: {},
        loading: true,
      };
    case pageBuilderConstant.GET_SITES_LIST_S:
      return {
        ...state,
        sites: action.data,
        loading: false,
      };
    case pageBuilderConstant.GET_SITES_LIST_F:
      return {
        ...state,
        sites: [],
        loading: false,
      };
    case pageBuilderConstant.CREATE_SITE_R:
      return {
        ...state,
        loading: true,
      };
    case pageBuilderConstant.CREATE_SITE_S:
      return {
        ...state,
        loading: false,
      };
    case pageBuilderConstant.CREATE_SITE_F:
      return {
        ...state,
        loading: false,
      };
    case pageBuilderConstant.GET_SITE_DETAIL_R:
      return {
        ...state,
        loading: true,
        site: {},
        page: {},
      };
    case pageBuilderConstant.GET_SITE_DETAIL_S:
      return {
        ...state,
        loading: false,
        site: action.data,
      };
    case pageBuilderConstant.GET_SITE_DETAIL_F:
      return {
        ...state,
        site: {},
        loading: false,
      };
    case pageBuilderConstant.CREATE_PAGE_R:
      return {
        ...state,
        page: {},
        loading: true,
      };
    case pageBuilderConstant.CREATE_PAGE_S:
      return {
        ...state,
        loading: false,
      };
    case pageBuilderConstant.CREATE_PAGE_F:
      return {
        ...state,
        loading: false,
      };
    case pageBuilderConstant.GET_PAGE_R:
      return {
        ...state,
        loading: true,
      };
    case pageBuilderConstant.GET_PAGE_S:
      return {
        ...state,
        page: { ...action.data, isPageContentLoaded: true },
        loading: false,
      };
    case pageBuilderConstant.GET_PAGE_F:
      return {
        ...state,
        loading: false,
      };
    case pageBuilderConstant.GET_FORM_LIST_R:
      return {
        ...state,
        loading: true,
      };
    case pageBuilderConstant.GET_FORM_LIST_S:
      return {
        ...state,
        responseList: action?.data,
        loading: false,
      };
    case pageBuilderConstant.GET_FORM_LIST_F:
      return {
        ...state,
        loading: false,
      };
    case pageBuilderConstant.GET_FORM_RESPONSE_R:
      return {
        ...state,
        loading: true,
      };
    case pageBuilderConstant.GET_FORM_RESPONSE_S:
      return {
        ...state,
        responseData: action?.data,
        loading: false,
      };
    case pageBuilderConstant.GET_FORM_RESPONSE_F:
      return {
        ...state,
        loading: false,
      };
    case pageBuilderConstant.CHANGE_PAGE:
      return {
        ...state,
        page: { id: action.data, isPageContentLoaded: false },
        loading: false,
      };
    case pageBuilderConstant.LIST_TEMPLATE_S:
      return {
        ...state,
        templates: action.data || [],
      };
    case pageBuilderConstant.UPDATE_COMPRESSED_DATA:
      // Create a map for new data by ID
      const newDataMap = action.data.reduce((map, template) => {
        map[template.id] = template;
        return map;
      }, {});
      // Filter out existing templates that are updated or replaced
      const filteredTemplates = state.templates.filter(template => !newDataMap[template.id]);
      // Add the new templates to the filtered list
      const updatedTemplates = [...filteredTemplates, ...action.data];
      return {
        ...state,
        templates: updatedTemplates,
      };
    case pageBuilderConstant.COMPRESS_THUMBNAIL_S:
      return {
        ...state,
        compresedThumbnials: action.data || [],
      }
    case pageBuilderConstant.LIST_CATEGORY_S:
      return {
        ...state,
        categories: action.data || [],
      };
    case pageBuilderConstant.DELETE_SITE_R:
      return {
        ...state,
      };
    case pageBuilderConstant.DELETE_SITE_S:
      let filteredSite = state?.sites.filter(
        (ele) => ele.id !== Number(action.params.data)
      );
      return {
        ...state,
        sites: filteredSite,
      };
    case pageBuilderConstant.DELETE_SITE_F:
      return {
        ...state,
      };
    case pageBuilderConstant.LIST_PAGE_TEMPLATE_S:
      return {
        ...state,
        page_templates: !action?.data.reset
          ? [...state.page_templates, ...(action?.data?.list || [])]
          : [...(action?.data?.list || [])],
        templateDataLength: action?.data?.templateDataLength,
      };
    case pageBuilderConstant.GET_PAGE_ANALYTICS_S:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          site_id: action?.data?.site_id,
        }
      };
    case pageBuilderConstant.SET_PAGE_ANALYTICS_FILTER_S:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          filter: action?.data?.filter,
        }
      };
    case pageBuilderConstant.GET_PAGE_ANALYTICS_CHART_S:
      return {
        ...state,
        analytics: {
          ...state.analytics,
          [action?.data?.key]: action?.data?.data,
        }
      };
    case pageBuilderConstant.GET_ACTIVE_SITE_LIST_S:
      return {
        ...state,
        publishedSites: action?.data
      };
    case pageBuilderConstant.GET_CUSTOM_CODE_SITE_R:
      return {
        ...state,
        loading: true,
        siteCustomCode: initialState.siteCustomCode
      };
    case pageBuilderConstant.GET_CUSTOM_CODE_SITE_S:
      return {
        ...state,
        loading: false,
        siteCustomCode: action.data
      };
    case pageBuilderConstant.GET_CUSTOM_CODE_SITE_F:
      return {
        ...state,
        loading: false,
        siteCustomCode: initialState.siteCustomCode
      };
    case pageBuilderConstant.UPDATE_CUSTOM_CODE_SITE_R:
    case pageBuilderConstant.UPDATE_CUSTOM_CODE_SITE_S:
      return {
        ...state,
        loading: false,
        siteCustomCode: { ...state.siteCustomCode, ...(action.data || {}) }
      };
    case pageBuilderConstant.UPDATE_CUSTOM_CODE_SITE_F:
    case pageBuilderConstant.GET_SITE_SEO_SETTINGS_R:
      return {
        ...state,
        loading: true,
        siteSeoSettings: initialState.siteSeoSettings
      };
    case pageBuilderConstant.GET_SITE_SEO_SETTINGS_S:
      return {
        ...state,
        loading: false,
        siteSeoSettings: action.data
      };
    case pageBuilderConstant.GET_SITE_SEO_SETTINGS_F:
      return {
        ...state,
        loading: false,
        siteSeoSettings: initialState.siteSeoSettings
      };
    case pageBuilderConstant.UPDATE_SITE_SEO_SETTINGS_R:
    case pageBuilderConstant.UPDATE_SITE_SEO_SETTINGS_S:
      return {
        ...state,
        loading: false,
        siteSeoSettings: { ...state.siteSeoSettings, ...(action.data || {}) }
      };
    case pageBuilderConstant.UPDATE_SITE_SEO_SETTINGS_F:
    case pageBuilderConstant.GET_EMAIL_CONFIG_S:
      return {
        ...state,
        emailConfig: action.data.data || state.emailConfig,
      };
    case pageBuilderConstant.UPDATE_EMAIL_CONFIG: {
      const emailConfig = state.emailConfig || {};
      return {
        ...state,
        emailConfig: { ...emailConfig, ...action.data },
      };
    }
    default:
      return state;
  }
}
