import React from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import iconImage from '../../assets/images/emoji/person-raising-both-hands-in-celebration.png'

import styles from './styles'

const canvasStyles = {
  position: 'absolute',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 99
}

class SchoolPride extends React.Component {
  constructor(props) {
    super(props);
    this.isAnimationEnabled = false;
    this.animationInstance = null;
    this.nextTickAnimation = this.nextTickAnimation.bind(this);
  }

  componentDidMount() {
    
    this.startAnimation();

    this.timer = setInterval(
      () => this.stopAnimation(),
      10000,
    );
  }

  makeShot = (angle, originX) => {
    this.animationInstance && this.animationInstance({
      particleCount: 3,
      angle,
      spread: 55,
      origin: { x: originX },
      colors: ['#5264f8', '#239bfa'],
      zIndex: 9,
      // colors: ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B', '#FFC107', '#FF9800', '#FF5722'],
    });
  }

  nextTickAnimation = () => {
    this.makeShot(60, 0);
    this.makeShot(120, 1);
    if (this.isAnimationEnabled) requestAnimationFrame(this.nextTickAnimation);
  }

  startAnimation = () => {
    if (!this.isAnimationEnabled) {
      this.isAnimationEnabled = true;
      this.nextTickAnimation();
    }
  }

  pauseAnimation = () => {
    this.isAnimationEnabled = false;
  }

  stopAnimation = () => {
    this.isAnimationEnabled = false;
    this.animationInstance && this.animationInstance.reset();
  }

  handlerClickStart = () => {
    this.startAnimation();
  };

  handlerClickPause = () => {
    this.pauseAnimation();
  };

  handlerClickStop = () => {
    this.stopAnimation();
  };

  getInstance = (instance) => {
    this.animationInstance = instance;
  };

  componentWillUnmount() {
    this.isAnimationEnabled = false;
    clearInterval(this.timer);
  }

  render() {
    const { classes, text_message } = this.props;
    return (
      <>
        <div className={classes.completeMsgContainer}>
          <span className={classes.completeBox}>
            {text_message}
            <img src={iconImage} alt={text_message} />
          </span>
        </div>
        <ReactCanvasConfetti refConfetti={this.getInstance} style={canvasStyles} />
      </>
    );
  }
}

// default props
SchoolPride.defaultProps = {
  classes: {},
  text_message: "",
};

// prop types
SchoolPride.propTypes = {
  classes: PropTypes.object,
  text_message: PropTypes.string,
};

export default withStyles(styles)(SchoolPride)