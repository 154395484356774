const style = () => ({
    cardHeader: {
        padding: '16px 16px 0px 16px',
        '& .MessageIcon': {
            width: 20,
            marginBottom: -4
        },
        '& .CloseIcon':{
            width: 12
        }
    },
    closeIconMobile:{
        position:'absolute',
        right:'5px'
    },
    notificationCard: {
        marginBottom:'10px',
        maxWidth: 400,
        minWidth:312,
        paddingBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0px 1px 16px #cccccc96',
        '& .MuiCardContent-root': {
            paddingBottom: '0px'
        }
    },
    replyThumb:{
        maxHeight: "5rem",
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        fontSize: '16px',
        '& .strokeFill': {
            stroke:'#fff'
        },
        '& .iconWrapper': {
            background: '#fb5eaf',
            width: '30px',
            height: '30px',
            display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '30px'
        },
        '& .nowtext': {
            marginLeft: '10px',
            background: '#FFEAE5',
            fontSize: '12px',
            padding: '1px 6px',
            borderRadius: '4px'
        }
    },
    closeBtn: {
        width: '30px',
        height: '30px'
    }
});

export default style