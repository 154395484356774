const styles = (theme) => ({
    soloPlanCard: {
        padding: '24px 30px 5px',
        borderRadius: 16,
        background: '#F8F9FC',
        maxWidth: '278px',
        margin: 'auto',
        marginTop: 20,
        '& .planDetails': {
            padding: '10px 30px',
            '& .helpText': {
                fontSize: '10px',
                margin: '0 30px'
            },
            '& .optionText': {
                fontSize: '13px'
            }
        },
        '& .planTitle': {
            fontWeight: 700,
            paddingBottom: 5
        },
        '& .planTitleBorderBtm': {
            height: '5px',
            width: '70px',
            background: 'linear-gradient(to right, #5154F8 , #1AA5FB )'
        },
        '& .planPriceContainer': {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '12px',
            alignItems: 'baseline'
        },
        '& .planPrice': {
            fontSize: '32px',
            fontWeight: 600
        },
        '& .planYear': {
            fontSize: '16px',
        },
        '& sup': {
            fontSize: '20px'
        }
    },
    paymenyWhiteDiv: {
        background: theme.palette.containers.card,
        padding: '25px',
        borderRadius: '7px',
        height: '100%',
        '&.paymenyWhiteDiv': {
            padding: '4px !important',
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
            backgroundImage: 'linear-gradient(white, white), linear-gradient(to right, #e3f2fd , #e1e1ed)',

        },
        '&.selected': {
            // border: '4px solid',
            // borderImage: 'linear-gradient(to right, #5154F8 , #1AA5FB ) 1',
            borderRadius: 7,
            backgroundImage: 'linear-gradient(white, white), linear-gradient(to right, #5154F8 , #1AA5FB )',
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
            padding: '4px !important'
        },
        '& .planTitle': {
            fontWeight: 700,
            paddingBottom: 5
        },
        '& .planTitleBorderBtm': {
            height: '5px',
            width: '70px',
            background: 'linear-gradient(to right, #5154F8 , #1AA5FB )'
        },
        '& .paymenyWhitePlan': {
            padding: '12px',
        },
        '& .planDetails': {
            padding: '10px 30px',
            '& .MuiSvgIcon-root': {
                fill: '#5055f8',
                fontSize: '1.2rem',
                marginRight: '5px'
            }

        },
        '& .planPriceContainer': {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '16px',
            alignItems: 'baseline'
        },
        '& .planPrice': {
            fontSize: '32px',
            fontWeight: 800
        },
        '& .planYear': {
            fontSize: '20px',
        },
        '& sup': {
            fontSize: '20px'
        },
        '& .selectedColor': {
            color: '#5055f8 !important'
        },
        '& .filledCheck': {
            background: 'linear-gradient(to right, #5154F8 , #1AA5FB)',
            borderRadius: 30,
            fill: 'transparent !important',
            fontSize: '1.2rem !important'
        },
        '& .filledunCheck': {
            background: 'transparent !important',
            borderRadius: 30,
            fill: 'transparent !important',
            fontSize: '1.2rem !important'
        },
        '& .filledCheckLabel .MuiIconButton-label': {
            padding: '3px',
            border: '1px solid',
            borderRadius: '30px'
        },
        '& .filledCheckLabel': {
            justifyContent: 'center'
        },
        '& .cancelText': {
            color: theme.palette.text.text3
        }
        // '& .periodContainer .MuiSvgIcon-root': {
        //     fontSize: '2rem',
        //     fill: 'linear-gradient(to right, #5154F8 , #1AA5FB)'
        // }
    },
    popupContainer: {
        padding: 30
    }
});

export default styles;
