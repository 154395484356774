import React, { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { Pause, PlayArrow } from '@material-ui/icons';


const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [playing]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div>
      <div className="playerControls">
        <IconButton className="iconBtn" onClick={toggle}>{playing ? <Pause /> : <PlayArrow />}</IconButton>
      </div>
    </div>
  );
};

export default Player;