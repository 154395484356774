import { postRequest, getRequest ,putRequest,deleteRequest} from "../../utils/apiHelper";

export const workSpaceCloningService = {
    cloneWorkspace,
    getCollabUser,
}
async function cloneWorkspace() {
    const response = await postRequest("profile/cloneWorkspace");
    return response;
  }

async function getCollabUser(data) {
    const response = await postRequest("auth/checkCollabUser",data);
    return response;
  }
 