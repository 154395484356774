const initialState = {
  newState: false,
  workSpaceDetail: [],
  cloningProgress: 0, 
  clonedSuccessStatus: false, 
  isCollabUser:false,
  isCollabPlan:false,
  socketEventData:{action_type:'clients'},
  introVideo:""
};

export default function workSpaceCloning(state = initialState, action) {
  switch (action.type) {
    case "SET_SPECIAL_USER":
      return {
          ...state,
          newState:action?.data?.data?.checkSpecialFlag?.special_flagged_agencies,
          introVideo:action?.data?.data?.planOptions?.intro_video
      };
      case "SET_SPECIAL_USER_RESET":
        return {
            ...state,
            newState:0
        };
        case "CLONE_WORKSPACE_R":
          return {
              ...state,
              workSpaceDetail: action.data,
              cloningProgress: 0, 
          };
      case "CLONE_WORKSPACE_F":
          return {
              ...state,
              workSpaceDetail: action.data,
              cloningProgress: 100, 
          };
          case "CLONE_WORKSPACE_S_70":
            return {
                ...state,
                cloningProgress: 70, 
            };

      case "CLONED_STATUS_S":
          return {
              ...state,
              cloningProgress: 100, 
          };
      case "CLONING_PROGRESS_UPDATE":
          return {
              ...state,
              cloningProgress:action?.progress
          };
        case "CLONING_PROGRESS_UPDATE_C":
          return {
              ...state,
              newState:0,
          };
       case "COLLAB_USER_S":
            return {
                ...state,
                isCollabUser:action.data.result
            };
        case "COLLAB_USER_F":
            return {
                ...state,
                isCollabUser:false,
                newState:0
            }; 
        case "CLONING_NOTIFY_DATA":
            return {
                ...state,
                socketEventData:action?.data?.message
            }  
      default:
          return state;
  }
}
