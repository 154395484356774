import React from "react";
import { Avatar, Grid, Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";

//image
import sampleImage from '../../assets/images/chatTeam.jpg'

// Style
import Style from './style'
import { AttachmentIcon3, CalendarIconNew, CommentsIcon } from "../../assets/svg";

function ChatBoardComp(props) {

    const { classes } = props;
  return (
    <Grid className={`${classes.cardRoot} cardRoot`}>
        <img className="boardImg" src={sampleImage} alt="Card Banner" />
        <Typography variant="h6" className="fw-600 pb5" align="left">John Smith</Typography>
        <Grid className="tagItem"><Grid className="dot" style={{background: '#38BDF8'}}></Grid><Typography varitan="body2" className="fs-12 fw-600" style={{color: '#38BDF8'}}>Facebook Ads</Typography></Grid>
        <Typography variant="body1" color="secondary" className="mt15 mb15 twoLineEllip fs-12" align="left">Add Forgot password option when login & send email to reset password...</Typography>
        <Grid className="footerContainer">
          <Grid className="dateShow">
            <CalendarIconNew />
            <span className="pl5">Nov 20</span>
          </Grid>
          <Grid className="dflex alignCenter">
          <Grid className="dflex alignCenter pr15"><AttachmentIcon3 /><span className="pl5">2</span></Grid>
          <Grid className="dflex alignCenter pr15"><CommentsIcon /><span className="pl5">5</span></Grid>
          <Grid className="dflex alignCenter"><Avatar /></Grid>
          </Grid>
        </Grid>
    </Grid>
  )
}

ChatBoardComp.defaultProps = {
};
ChatBoardComp.propTypes = {
    classes: PropTypes.object,
};

// export component
export const ChatBoard = withStyles(Style)(ChatBoardComp);