import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// Import Actions

// Import Styles
import style from "./style";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

function AvatarListSkeleton(props) {

    return (
        <div className="pl-1 pr-1">
            <Box display="flex" alignItems="center">
                <Box margin={1}>
                    <Skeleton variant="circle" width={40} height={40} />
                </Box>
                <Box width="100%">
                    <Skeleton width="60%" variant="text" />
                    <Skeleton width="60%" variant="text" />
                </Box>
            </Box>
        </div>
    )
}

// default props
AvatarListSkeleton.defaultProps = {
    classes: {}
};

// prop types
AvatarListSkeleton.propTypes = {
    classes: PropTypes.object
};

// Export component
export default withStyles(style)(AvatarListSkeleton)
