import React from 'react';
import PropTypes from "prop-types";

import AvatarListSkeleton from "./AvatarListSkeleton";
import CardsSkeleton from "./CardsSkeleton";
import ChatSkeleton from "./ChatSkeleton";
import ListSkeleton from './ListSkeleton';
import MediaSkeleton from './MediaSkeleton';
import TaskSkeleton from './TaskSkeleton';


const SkeletonComponent = (props) => {
    const { count, type } = props;

    let components = {
        media: MediaSkeleton,
        list: ListSkeleton,
        chat: ChatSkeleton,
        card: CardsSkeleton,
        avatarList: AvatarListSkeleton,
        task: TaskSkeleton,

    }

    const CurrentComponent = components[type];

    let indents = [];
    for (var i = 0; i < count; i++) {
        indents.push(<CurrentComponent key={i} />);
    }

    return (
        <>
            {indents}
        </>
    )
}


// Default props
SkeletonComponent.defaultProps = {
    count: 3,
    type: "",
};

// Prop types
SkeletonComponent.propTypes = {
    count: PropTypes.number,
    type: PropTypes.string.isRequired,
};

export const Skeleton = SkeletonComponent
