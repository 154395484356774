/* eslint-disable dot-notation */
import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography as Core } from "@material-ui/core";

// Imports Styles
import styles from "./styles";

/**
 * Typography Component
 * @param {*} props
 */
function TypographyComponent(props) {
  const { className, color, variant, children, align, onClick, noWrap, title, contentEditable, onBlurChange, style, placeHolder, ...rest } = props;
  const accessProps = contentEditable && onBlurChange ? {
    contentEditable, onBlur: (e) => onBlurChange(e.target.textContent), "data-placeholder": placeHolder
  } : {}

  // disable empty
  /* if (contentEditable && onBlurChange) {
    accessProps['onKeyDown'] = (e) => {
      if (e.target.textContent.length === 1 && e.code == "Backspace") {
        e.preventDefault()
        return false
      }
    }
  } */

  return (
    <Core
      variant={variant}
      className={className}
      align={align}
      color={color}
      onClick={onClick}
      noWrap={noWrap}
      style={style || {}}
      {...accessProps}
      {...rest}
    >{title}{children}</Core>
  );
}

// default props
TypographyComponent.defaultProps = {
  classes: {},
  variant: "body1",
  className: "",
  children: "",
  color: "primary",
  align: "left",
  noWrap: false,
  required: false,
  placeHolder: "Enter Here",
  onClick: () => { }
};

// prop types
TypographyComponent.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.string,
  align: PropTypes.string,
  noWrap: PropTypes.bool,
  required: PropTypes.bool,
  onClick: PropTypes.func,
  placeHolder: PropTypes.string,
  contentEditable: false,
  onBlurChange: null
};

/** Export Component */
export const Typography = withStyles(styles)(TypographyComponent);
