import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

import { Badge, Avatar as MuiAvatar, Typography } from "@material-ui/core";

// Imports Styles
import styles from "./style";

// Import constant 
import { DEFAULT_USER_IMAGE } from "../../config/constants";
/**
 * 
 * @param {classes}   
 * @param {image} props  // image source to be placed inside avatar
 * @param {primaryText} props // string of user name 
 * @param {secondaryText} props // string of user email or text to me displaced below name
 * @param {size} props // string size of the avatar to be render x-small, small, medium, large, x-large
 * @param {showBadge} props // boolean to display badge component 
 * @param {badgeStatus} props // boolean to variant badge status color 
 * @param {showDefaultImage} props // boolean to display default user profile 
 * @param {badgePosition} props // object of badge origin in vertical and horizontal  i.e { vertical: 'bottom',  horizontal: 'right'}
 * @returns 
 */

function AvatarComponent(props){

    const { classes, image, primaryText, secondaryText, size, showBadge, badgeStatus, showDefaultImage, badgePosition, hideText } = props

    const [enableDefaultImage, setEnableDefaultImage] = useState(false)

    useEffect(()=>{
        setEnableDefaultImage(showDefaultImage)
        if(!image && !primaryText){
            setEnableDefaultImage(true)
        } 
    },[])

    return (
        <div className={classes.componentContainer}>
            <div className={`${size} ${enableDefaultImage || image ? 'defaultUser' : ''}`}>
                <Badge 
                    variant="dot" 
                    overlap="circular"  
                    anchorOrigin={badgePosition}
                    invisible={!showBadge}
                    className={badgeStatus && 'greenBadge'}
                >
                    <MuiAvatar alt={primaryText} src={enableDefaultImage ? DEFAULT_USER_IMAGE : image}>
                        {primaryText && primaryText[0].toUpperCase()}
                    </MuiAvatar>
                </Badge>
            </div>
            {
                !hideText && 
                <div className={`${size} ml-1`}>
                    <Typography variant="body1" >{primaryText}</Typography>
                    <Typography variant="body1" color="secondary">{secondaryText}</Typography>
                </div>
            }
        </div>
    )
}

// defaultProps
AvatarComponent.defaultProps = {
    classes: {},
    image:null,
    primaryText:'User Name',
    secondaryText:'User email',
    size:'medium',
    showBadge: false,
    badgeStatus: false,
    showDefaultImage: false,
    badgePosition: {
        vertical: 'bottom',
        horizontal: 'right',
    },
    hideText : false
}

// propTypes
AvatarComponent.propTypes = {
    classes: PropTypes.object,
    image: PropTypes.string,
    primaryText: PropTypes.string,
    secondaryText: PropTypes.string,
    size: PropTypes.string,
    showBadge: PropTypes.bool,
    badgeStatus: PropTypes.bool,
    showDefaultImage: PropTypes.bool,
    badgePosition: PropTypes.object,
    hideText: PropTypes.bool
}

/** Export Component */
export const CustomAvatar = withStyles(styles)(AvatarComponent);