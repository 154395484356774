
const style = (theme) => ({

  circle: {
    height: "100px",
    width: "100px",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0, 
      left: 0,
      border: "1px solid #313131",
      borderRadius: "50%",

      animation: "ripple 2s linear infinite"
    },
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0, 
      left: 0,
      border: "1px solid #313131",
      borderRadius: "50%",

      animation: "ripple 2s linear 1s infinite"
    },
    '@media only screen and (max-width: 768px)':{
      width: '60px',
      height: '60px'
    }
  },
  remoteAttendeesCircle :{
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0, 
      left: 0,
      border: "1px solid #313131",
      borderRadius: "50%",

      animation: "ripple 2s linear infinite"
    },
    "&:after": {
      content: "''",
      display: "block",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0, 
      left: 0,
      border: "1px solid #313131",
      borderRadius: "50%",

      animation: "ripple 2s linear 1s infinite"
    }
  },
  "@media screen and (max-width: 900px)": {
    circle: {
          width: "100px !important",
          height: "100px !important"
      }
  },
  "@media screen and (max-width: 599px)": {
    circle: {
          width: "64px !important",
          height: "64px !important"
      }
  }

})

export default style;