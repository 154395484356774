import { flagService } from "../services";
import { baseAction } from "./baseAction";
import { featureFlagsConstant } from '../constants/featureFlagsConstant';

const { request, success, failure } = baseAction;


function getFlags(cb = () => {}) {
    return (dispatch) => {
      dispatch(request(featureFlagsConstant.FEATURE_FLAGS_GET_R));
      flagService.getFlags().then(data => {
        if(data){
          dispatch(success(featureFlagsConstant.FEATURE_FLAGS_GET_S, data.data));
        } else {
          dispatch(failure(featureFlagsConstant.FEATURE_FLAGS_GET_F, {}));
        }
        cb()
      }, error => {
        dispatch(failure(featureFlagsConstant.FEATURE_FLAGS_GET_F, error));
      }
      );
    };
  }
  export const featureFlagActions = {
    getFlags
  };