import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Grid,
  Tooltip,
  Popover,
  Tab,
  Tabs,
  withStyles,
} from "@material-ui/core";
import style from "./style";
import { Typography } from "../Typography";
import { TitleAvatar } from "../TitleAvatar";
import { getLocalStorage } from '../../utils';

/**
 * Checkbox Component
 * @param {*} props
 */

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    // id: `simple-tab-${index}`,
    // 'aria-controls': `simple-tabpanel-${index}`,
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function ReactionListComponent(props) {
  const { classes, msgEmojis, messageReactions, message_id, moduleType: type, verticalAnchor, horizontalAnchor, onClickReactions, userId, openReactionInfo, handleClose } = props;

  const [value, setValue] = React.useState(0);
  const [allEmojis, setAllEmojis] = React.useState([]);
  const themeType = getLocalStorage("themeType");

  useEffect(() => {
    if (message_id) {
      setValue(`${type}_${message_id}_default`)
      const allList = [];
      msgEmojis.forEach(ele => {
        allList.push(...messageReactions[ele])
      });
      setAllEmojis(allList)
    }
  }, [message_id, msgEmojis, messageReactions])


  const handleReactionClose = () => {
    handleClose();
  };

  const openReaction = Boolean(openReactionInfo);
  const reactionId = openReaction ? "simple-popover" : undefined;


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleReaction = (emoji, actionType) => {
    onClickReactions({ emoji, actionType })
    if (allEmojis.length === 1 && actionType === 'delete') {
      handleClose()
    }
  }

  return (
    <>
      {/* <Grid className={"moreemojis cursorPointer"} onClick={handleReactionInfo} >{allEmojis.length > 3 ? `${allEmojis.length - 3}+` : allEmojis.length}</Grid> */}
      <Popover
        id={reactionId}
        open={openReaction}
        anchorEl={openReactionInfo}
        onClose={handleReactionClose}
        className={classes.reactionPopup}
        anchorOrigin={{
          vertical: "bottom",
          // horizontal: "right",
          horizontal: horizontalAnchor,
        }}
        transformOrigin={{
          vertical: "top",
          // horizontal: "left",
          horizontal: verticalAnchor,
        }}
      >
        <Grid className={classes.reactionWrapper}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            aria-label="scrollable auto tabs example"
          >
            <Tab label={<Typography variant="body2" color="textSecondary" className="fs-12">All <span className="pl5">{allEmojis.length}</span></Typography>} {...a11yProps(`${type}_${message_id}_default`)} value={`${type}_${message_id}_default`} />
            {
              msgEmojis.map((emoji, key) =>
                <Tab
                  label={<Typography variant="body2" className="fs-12"> {emoji} <span className="pl5">{messageReactions[`${emoji}`]?.length}</span></Typography>}
                  {...a11yProps(`${type}_${message_id}_${key}`)}
                  value={`${type}_${message_id}_${key}`}
                  key={`key_${type}_${message_id}_${key}`}
                />
              )
            }
          </Tabs>
          <TabPanel value={value} index={`${type}_${message_id}_default`}>
            <Grid className={classes.emojiReactedDiv}>
              {
                allEmojis.map((val, i) => {
                  return (
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                      className={classes.emojiReactedPerson}
                      key={`key_${type}_${message_id}_default_${i}`}
                    >
                      <Grid item className="nameavatarContainer">
                        <TitleAvatar
                          title={val.name ? val.name : val.username}
                          value={val.avatar_filename ? val.avatar_filename : ""}
                          type="avatar"
                          className={"nameavatar"}
                        />
                        <Typography variant="body1" className="personname fs-12 fw-400"  noWrap>
                          {val.name ? val.name.length > 20 ? val.name.substr(0, 20) + "..." : val.name :val.username}
                        </Typography>
                      </Grid>
                      <Tooltip
                        title={val.user_id === userId ? "Remove your reaction" : "Add same reaction"}
                      >
                        <Grid item
                          className="reactedemoji"
                          onClick={() => {
                            handleReaction(
                              val.emoji,
                              val.user_id === userId ? 'delete' : "add",
                            )
                          }}
                        >
                          {" "} {val.emoji} {" "}
                        </Grid>
                      </Tooltip>
                    </Grid>
                  )
                })
              }
            </Grid>
          </TabPanel>
          {
            msgEmojis.map((emoji, key) => {
              return (
                <>
                  <TabPanel value={value} index={`${type}_${message_id}_${key}`} key={`key_${type}_${message_id}_${key}`}>
                    <Grid className={classes.emojiReactedDiv}>
                      {
                        (messageReactions[emoji] || []).map((val, i) => {
                          return (
                            <Grid
                              container
                              justifyContent="space-between"
                              alignItems="center"
                              className={classes.emojiReactedPerson}
                              key={`key_${type}_${message_id}_${key}_${i}`}
                            >
                              <Grid item className="nameavatarContainer">
                                <TitleAvatar
                                  title={val.name ? val.name : val.username}
                                  value={val.avatar_filename ? val.avatar_filename : ""}
                                  type="avatar"
                                  className={"nameavatar"}
                                />
                                <Typography variant="body1" className="personname fs-12 fw-400" noWrap>
                                {val.name ? val.name.length > 20 ? val.name.substr(0, 20) + "..." : val.name :val.username}
                                </Typography>
                              </Grid>
                              <Tooltip
                                title={val.user_id === userId ? "Remove your reaction" : "Add same reaction"}
                              >
                                <Grid item
                                  className="reactedemoji"
                                  onClick={() => {
                                    handleReaction(
                                      val.emoji,
                                      val.user_id === userId ? 'delete' : "add",
                                    )
                                  }}
                                >
                                  {" "} {val.emoji} {" "}
                                </Grid>
                              </Tooltip>
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  </TabPanel>
                </>
              )
            })
          }
        </Grid>
      </Popover>
    </>
  );
}

// default props
ReactionListComponent.defaultProps = {
  classes: "",
  horizontalAnchor: 'center',
  verticalAnchor: 'center',
};

// prop types
ReactionListComponent.propTypes = {
  className: PropTypes.string,
};

export const ReactionList = withStyles(style)(ReactionListComponent);
