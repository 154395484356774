import { referralsConstant } from "../constants";
import { DuplicateFlows, ShareCircle } from "../../assets/svg";

const initialState = {
    loading: false,
    featureDetails: [
        {
            title: "Clone",
            description: `The flows can be cloned to other agencies with unlimited 
                          users, with or without the referral code`,
            icon: <DuplicateFlows />
        },
        {
            title: "Work Space Sharing",
            description: `The flows can shared with other agencies with unlimited 
                          users, with or without the referral code`,
            icon: <ShareCircle />
        },
    ],
    referralList: [],
    selectReferral: {
        id: '',
        title: '',
        referral_code: '',
        payment_code: '',
        plan_code: '',
        features: {
            wsm: false,
            clone: false
        },
        feature_object: {
            wsm: {
                flows: [],
                fullFlowAccess: false
            },
            clone: {
                flows: [],
                fullFlowAccess: false
            }
        }
    },
    paymentCodeUnique: false,
}

export default function referrals(state = initialState, action) {
    switch (action.type) {

        // Getting Referrals List
        case referralsConstant.GET_REFERRALS_R:
            return {
                ...state
            }
        case referralsConstant.GET_REFERRALS_S:
            return {
                ...state,
                referralList: action.data.data
            }
        case referralsConstant.GET_REFERRALS_F:
            return {
                ...state,
                error: action.message,
            }
        case referralsConstant.UPDATE_REFERRAL_DATA:
            return {
                ...state,
                selectReferral: action.data
            }
        case referralsConstant.CREATE_REFERRAL_R:
            return {
                ...state
            }
        case referralsConstant.CREATE_REFERRAL_S:
            return {
                ...state,
                selectReferral: initialState.selectReferral
            }
        case referralsConstant.CREATE_REFERRAL_F:
            return {
                ...state
            }
        case referralsConstant.GET_PAYMENT_CODE_R:
            return {
                ...state
            }
        case referralsConstant.GET_PAYMENT_CODE_S:
            return {
                ...state,
                selectReferral:{
                    ...state.selectReferral,
                    payment_code: action.data.data.payment_code,
                },
                paymentCodeUnique: action.data.data.status
            }
        case referralsConstant.GET_PAYMENT_CODE_F:
            return {
                ...state
            }
        // Getting single Referral
        case referralsConstant.GET_ONE_REFERRAL_DATA_R:
            return {
                ...state,
                selectReferral: initialState.selectReferral,
                loading: action.params? true: false,
                paymentCodeUnique: false
            }
        case referralsConstant.GET_ONE_REFERRAL_DATA_S:
            return {
                ...state,
                selectReferral: action.data.data,
                paymentCodeUnique: action.data.data? true: false,
                loading: false,
            }
        case referralsConstant.GET_ONE_REFERRAL_DATA_F:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}