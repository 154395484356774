import React from "react"
import { IconButton, Grid } from "@material-ui/core";
import { Typography } from "../../../../components"

export const CropNodeOptions = (props) => {
    const { classes, selectedNode } = props
    const { onChangeAttrs } = selectedNode?.attrs || {}

    const onSave = () => {
        onChangeAttrs({
            save: true
        })
    }

    const oncancel = () => {
        onChangeAttrs({
            save: false
        })
    }

    return (
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" xs={12}>
            <div className={classes.toolOptions}>
                <IconButton onClick={oncancel}>
                    <Typography className={`fw-500 secondary2`}>
                        Reset
                    </Typography>
                </IconButton>
                <IconButton onClick={onSave}>
                    <Typography className={`fw-700 primary1`}>
                        Done
                    </Typography>
                </IconButton>
            </div>
        </Grid>
    )
}