import React, { useEffect, useState } from "react";
import VCParticipant from "../VCParticipant";

function VCLobby({
    optimalVideoDimention,
    AgoraRTC,
    isVideoMuted,
    user_id,
    setLocalUser,
    localUser,
    profile,
    audioMuted,
    username,
    localVideoTracks,
    parentWidth
}) {
    const [localVideoTrack, setLocalVideoTrack] = useState(null);

    const playLocalVideo = async () => {
        setLocalVideoTrack(localVideoTracks.videoTrack);
        setLocalUser({
            uid: user_id,
            videoTrack : localVideoTracks.videoTrack,
            audioTrack : localVideoTracks.audioTrack
        });
    };

    useEffect(() => {
        if (localVideoTracks && localVideoTracks.videoTrack) {
            playLocalVideo();
        }
    }, [localVideoTracks]);

    return (
        <>
            <VCParticipant
                key={`local-${user_id}`}
                user={{
                    uid: user_id,
                    videoTrack: localVideoTracks.videoTrack
                }}
                isVideoMuted={isVideoMuted}
                audioMuted={audioMuted}
                localUser={localUser}
                profile={profile}
                videoWidth={optimalVideoDimention.width}
                videoHeight={optimalVideoDimention.height}
                username={username}
                videoStyle={{marginTop : '40px' }}
            />
        </>
    );
}

export default VCLobby;
