import React from "react";
import PropTypes from "prop-types";
import Avatar from '@material-ui/core/Avatar';

const EntryComponent = ({
    mention,
    theme,
    searchValue, // eslint-disable-line no-unused-vars
    isFocused, // eslint-disable-line no-unused-vars
    ...parentProps
}) => {
    return (
        <>
            <div {...parentProps} key={mention.mentionId}>
                <div className={theme.mentionSuggestionsEntryContainer} key={mention.mentionId}>
                    <div className={theme.mentionSuggestionsEntryContainerLeft}>
                        {mention && mention.avatar_filename ?
                            <Avatar alt={mention.name ? mention.name.toUpperCase() : mention.first_name ? mention.first_name.toUpperCase() : " "} src={mention.avatar_filename} className={theme?.mentionSuggestionsEntryAvatar} />
                             :
                            <Avatar alt={mention.name ? mention.name.toUpperCase() : mention.first_name ? mention.first_name.toUpperCase() : " "} src="/static/images/avatar/1.jpg" className={theme?.mentionSuggestionsEntryAvatar} />
                        }
                    </div>

                    <div
                        data-role-id={`${mention.id}`}
                        className={theme.mentionSuggestionsEntryContainerRight}
                    >
                        <div className={theme.mentionSuggestionsEntryText}>
                            {mention.name ? mention.name : mention.first_name}
                        </div>
                        <div className={theme?.mentionSuggestionsEntryTitle}>
                            {mention.username ? `@${mention.username}` : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

EntryComponent.propTypes = {
    mention: PropTypes.shape({
        name: PropTypes.string,
        mentionId: PropTypes.string
    }).isRequired,
    theme: PropTypes.shape({
        mentionSuggestionsEntryContainer: PropTypes.string,
        mentionSuggestionsEntryContainerRight: PropTypes.string,
        mentionSuggestionsEntryText: PropTypes.string
    }).isRequired,
    searchValue: PropTypes.string,
    isFocused: PropTypes.bool
};

export default EntryComponent;
