import React from 'react';
import PropTypes from 'prop-types';
import { useClock } from './useClock';
import moment from "moment-timezone";
import ShowTimer from './ShowTimer';

import './styles.css'

const DigitalClockTimerComponent = ({ timeZone }) => {
    const [dateTime, hours, minutes, seconds, meridian] = useClock(timeZone);

    return (
        <ShowTimer
            dateString={moment(new Date(dateTime)).format("ddd, MMM Do")}
            hours={hours}
            minutes={minutes}
            seconds={seconds}
            meridian={meridian}
        />
    );
};


// default props
DigitalClockTimerComponent.defaultProps = {
    classes: {},
};

// prop types
DigitalClockTimerComponent.propTypes = {
    classes: PropTypes.object,
};

/** Export Component */
export const DigitalClockTimer = DigitalClockTimerComponent;