import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import React, { useState } from 'react';
// import { Select as Core } from '@material-ui/core';
import { Select as Core, FormHelperText } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './styles';

// import component
import { ConfirmDialog } from '../ConfirmDialog';

/**
 * SwitchComponent
 * @param {*} props
 */

function SelectComponent(props) {
  const { variant, value, className, options, label, defaultVal, fullWidth, disabled, classes, validationMsg, showValidate, style, customStyles, popEvents, disableDefault, placeholder = "", handleSelectedAction, handleRequiredAction, integratedApps, ...rest } = props;

  /**
    * Handle
    * Select Field Events
    */
  const handleChange = (event) => {
    props.handleChange({ value: event.target.value !== "0" ? event.target.value : "" });
  };
  //.+Select.+components'
  let { selectBox, ...classWithoutSelectBox } = classes;

  const menuProps = {
    classes: {
      paper: classes.popverClass, // Add your custom class here
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };


  const [confirmation, setConfirmation] = useState(false);
  const [confirmationData, setConfirmationData] = useState(null);

  const renderIcon = (Icon) => {
    return Icon ? <img className={classes.iconClass} src={Icon} /> : null
  }

  const handleOptionInlineClick = (e, value) => {
    e.stopPropagation();
    e.preventDefault();

    if (value) {
      let item = options.find(ele => ele.value === value)

      if (item && item?.required_confirmation) {
        setConfirmationData(item);
        setConfirmation(true);
        handleRequiredAction(item);
      } else {
        handleSelectedAction(item)
        setConfirmation(false);
        setConfirmationData(null);
      }

    }

    // dispatch(eventAction.getAuthUrl())
    // setGoogleSignInAlert(true)
  }

  const handleStopPropagation = (e) => {
    e.stopPropagation();
    e.preventDefault();
  }


  const handleConfirmationClose = () => {
    setConfirmationData(null);
    setConfirmation(false)
  }

  const handleConfirmation = () => {
    setConfirmation(false);
    // do confirmed action
    handleSelectedAction(confirmationData)
    setConfirmationData(null);
  }

  const cancelBtn = {
    label: "Cancel",
    onClick: handleConfirmationClose,
    disableElevation: true,
    variant: "outlined"
  };

  const primaryBtnProps = {
    disableElevation: true,
    color: "primary",
    variant: "contained",
    className: "blueBtn"
  }

  const actionButtons = [
    cancelBtn,
    {
      label: "Continue",
      onClick: handleConfirmation,
      ...primaryBtnProps
    }
  ];

  const defaultStyles = {
    margin: "10px",
    fontWeight: '500',
    fontSize: '13px',
    borderRadius: "6px",
    justifyContent: 'center'
  }

  const CustomArrowIcon = () => (
    <ExpandMoreIcon style={{ marginRight: '6px', fill: '#64748B' }} fontSize="small" /> // Customize size as needed
  );

  return (
    <>
      <FormControl
        variant={variant}
        className={`${className} ${classes.selectBox} ${classes.customSelectBox}`}
        classes={classWithoutSelectBox}
        error={showValidate}
      >
        <Core
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          IconComponent={CustomArrowIcon}
          value={value}
          onChange={handleChange}
          label={label}
          disabled={disabled ? disabled : false}
          fullWidth={fullWidth}
          style={style}
          className={`${classes.customSelectBoxOptions}`}
          MenuProps={menuProps}
          displayEmpty={true}
          renderValue={
            value !== "" ? undefined : () => <><div className={classes.placeholder}>{placeholder}</div></>
          }
          {...rest}
        >
          {!popEvents && <MenuItem value="0" align="left">{defaultVal}</MenuItem>}
          {/* {
            disableDefault &&
            <MenuItem value="0" align="left">{defaultVal}</MenuItem>
          } */}
          {options.map((item, keyIndex) => {
            if (item.isEnabled) {
              return (
                <MenuItem
                  style={(item.color || item.bgColor) ? {
                    ...defaultStyles,
                    backgroundColor: item.bgColor || "#ffffff",
                    color: item.color || "#000000"
                  }
                    : customStyles.color ? customStyles : {}
                  }
                  key={keyIndex}
                  value={item.value}
                  disabled={item.comingSoon}
                  onClick={!integratedApps.includes(item.key) ? (e) => handleOptionInlineClick(e, item.value) : () => { }}
                >
                  <div className={classes.selectOptionBox}>
                    <div className='option_content'>
                      <span className='item_icon'>{item?.icon && renderIcon(item?.icon)}</span>
                      <span className='item_name'> {item.label} {item.status === 0?"":"" }</span>
                      <span className={`item_status_dot ${(integratedApps.includes(item.key)) ? "online" : "offline"}`}></span>
                    </div>
                    {
                      (!integratedApps.includes(item.key)) ?
                        <div className='option_links'>
                          Please <span>click here</span> to integrate with {item.label}
                        </div> : null
                    }
                    {/* {
                    !item.isIntegrated ?
                      <div className='option_connect_btn ml-1' onClick={(e) => handleOptionInlineClick(e, item.label)}>
                        <Button variant={"contained"} disableElevation className='blueBtn' size={"small"} color={"primary"} >Connect</Button>
                      </div> : null
                  } */}
                    {
                      item.comingSoon ?
                        <div className='option_coming_soon fw-500 pl-1' onClick={(e) => handleStopPropagation(e)}>
                          Coming Soon..!
                        </div> : null
                    }
                  </div>
                </MenuItem>
              )
            }
          })}
        </Core>
        {validationMsg && <FormHelperText>{validationMsg}</FormHelperText>}
      </FormControl>

      <ConfirmDialog
        open={confirmation}
        handleClose={() => handleConfirmationClose()}
        title={confirmationData?.dialog?.title}
        description={confirmationData?.dialog?.description || null}
        content={confirmationData?.dialog?.element || null}
        actionBtns={actionButtons}
      />
    </>
  );
}

// default props
SelectComponent.defaultProps = {
  variant: "outlined",
  defaultVal: "select",
  value: "0",
  label: "",
  margin: "dense",
  className: "",
  classes: "",
  options: [],
  fullWidth: false,
  customStyles: {},
  handleChange: () => { },
  disabled: false,
  showValidate: false,
  validationMsg: "",
  style: {},
  disableDefault: false
};

// prop types
SelectComponent.propTypes = {
  variant: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.any,
  classes: PropTypes.object,
  customStyles: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  defaultVal: PropTypes.string,
  disableDefault: PropTypes.bool
};

export const CustomSelect = withStyles(styles)(SelectComponent);
