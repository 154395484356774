import zIndex from "@material-ui/core/styles/zIndex"

const style = (theme) => ({

    carouselContainer:{
        width: "calc(100% - 100px)",
        '@media only screen and (max-width: 768px)': {
            width: '100%'
        }

    },
    ExpandIcon: {
        position: 'absolute',
        zIndex: 9,
        top: "-30px",
        right: '48%',
        justifyContent: "center",
        display: "flex",
        transition: "transform 0.5s",

        "&__open":{
            transform: "rotate(180deg)"
        },
        "&__collapse":{
            transform: "rotate(0deg)",
        },
        '@media only screen and (max-width: 768px)': {
            position: 'absolute',
            top: '-27px',
            left: '5%',
            width: '100%'
        }
       
    },
    ParticipantsList:{
        width: "100%",
        '@media only screen and (max-width: 768px)': {
            display: 'grid',
            width: '100%',
            overflowX: 'auto',
            scrollbarWidth: 'none',       
        }
        
    },
    ParticipantsListFullWidth: {
        width: '100%',
        height: '106.256px',
        display: "flex",
        justifyContent: "center",
        animationName: `$moveContainer`,
        animationDuration: "5s",
        animationTimingFunction: "ease-in-out",
        animationIterationCount: "infinite",

        "& .slick-arrow": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: 1,
            width: "44px"
        },
        "& .slick-next":{
            right: "-15px",
            top: '20px',
            '@media only screen and (max-width: 768px)': {
                display: 'none'
            }
        },
        "& .slick-prev":{
            left: "-15px",
            top: '20px',
            '@media only screen and (max-width: 768px)': {
                display: 'none'
            }
        }

        // // To override slick carousel styles
        // "& .slick-initialized":{
        //     "& .slick-list":{
        //         display: "flex",
        //         alignItems: "center",
        //         justifyContent: "center"
        //     },
        //     "& .slick-slide":{
        //         width: "180px !important"
        //     }
        // },
        // "& .slick-slider":{
        //     "& .slick-track":{
        //         width: "100%"
        //     }
        // }
    },
    ' @keyframes moveContainer': {
        from: { top: '85%' },
        to: { top: '0' }
    },
    ParticipantsPagination: {
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        position: "absolute",
        top: "10px",
        left: "96%",
        zIndex: 1
    },
    ParticipantsPaginationLeft :{
        display: "flex",
        justifyContent: "center",
        marginTop: "20px",
        position: "absolute",
        top: "10px",
        right: "96%" ,
        zIndex:1
    },
    slickIcons:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        left: "-15px",
        zIndex: 1,
        '@media only screen and (max-width: 768px)': {
            display: 'none'
        }
    }
 
})

export default style