import React, { Fragment, useState } from 'react';
import PropTypes from "prop-types";
import { withStyles, Chip } from "@material-ui/core";
import ChipInput from 'material-ui-chip-input';

//import styles
import styles from "./styles";

//import helper
import { isEmail } from '../../utils'


function EmailChipComponent(props) {

  const {
    name,
    value,
    classes,
    placeholder,
    handleChange,
    showValidate,
    validationMsg,
    classValue,
    disableUnderline,
    variant,
    error,
    setError 
  } = props;

  const [inputValue, setInputValue] = useState("")

  //add emailIDs
  const handleAddChip = (chip) => {
    if (chip && isEmail(chip.trim())) {
      let tempArr = [...value, chip]
      tempArr = tempArr.filter(i => (i != ''))
      handleChange(tempArr)
      setInputValue("")
      setError(false)
    }
    else {
      if (chip) {
        setError(true)
      }
      else {
        setError(false)
      }
    }
  }

  const getEmailChips = (emailArr) => {
    let resultEmails = [];
    emailArr && emailArr.map((i) => {
      if (i) {
        if (isEmail(i.trim())) {
          resultEmails.push({ email: i.trim().toLowerCase(), isValid: true });
        } else {
          resultEmails.push({ email: i.trim().toLowerCase(), isValid: false });
        }
      }
    });
    return resultEmails;
  }

  //delete email Ids
  const handleDeleteChip = (chip, index) => {
    let tempArr = [...value]
    tempArr = tempArr.filter(i => (i != ''))
    tempArr.splice(index, 1)
    handleChange(tempArr)
  }

  return (
    <Fragment >
      <ChipInput
        className={`${classes.chipInput} ${classes.frequencyWidth} chipboderRadius10 emailChipBox ${classValue?.multiGuestEmails} ${error ? classes.emailError : ""}`}
        variant={variant ? variant : "outlined"}
        name={name}
        fullWidth
        inputValue={inputValue}
        onUpdateInput={(e) => setInputValue(e.target.value)}
        value={getEmailChips(value) || []}
        onBlur={(chip) => handleAddChip(chip.target.value)}
        onAdd={(chip) => handleAddChip(chip)}
        onDelete={(chip, index) => handleDeleteChip(chip, index)}
        dataSource={getEmailChips(value) || []}
        placeholder={placeholder}
        disableUnderline={disableUnderline}
        chipRenderer={({ value, handleDelete, className }, key) => {
          return (
            <Chip
              key={key}
              label={value.email}
              onClick={(e) => e.stopPropagation()}
              onDelete={handleDelete}
              className={value.isValid ? "" : `${classes.errorChipReport}`}
            />
          )
        }}
      />
      {
        showValidate && (
          <span style={{ color: 'red' }}>{validationMsg}</span>
        )
      }
    </Fragment>
  );
}

// default props
EmailChipComponent.defaultProps = {
  value: [],
  name: "emailChips",
  placeholder: "Enter email address",
  handleChange: () => { },
  showValidate: false,
  validationMsg: '',
  setError: () => { },
};

// prop types
EmailChipComponent.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  setError: PropTypes.func,
};

/** Export Component */
export const EmailChip = withStyles(styles)(EmailChipComponent);
