import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from 'react-helmet-async';
import { _api } from "../config/environment";
import { profileAction } from "../redux/actions";
import { getAppHost } from "../utils";
import { _envParams } from "../config/env_params";

function Seo() {
    // Define Dispatch
    const dispatch = useDispatch();

    const appHost = getAppHost();
    const defaultHost = _api.appUrl;
    const { appConfig } = useSelector(s => (s.profile));
    const { title, description, logo } = appConfig;

    useEffect(() => {
        if (appHost !== defaultHost && _envParams.REACT_APP_BUILD_FOR === "WEB") {
            dispatch(profileAction.getAppConfig({ host: appHost }, (data) => {
                localStorage.setItem('whiteLabel', true)
                localStorage.setItem('whiteLabelData', JSON.stringify(data))
            }));
        }
    }, []);

    // get local-storage data 
    // const whiteLabelData = JSON.parse(getLocalStorage("whiteLabelData"))

    return (
        <>
            {
                appHost !== defaultHost ?
                    <>
                        <Helmet>
                            { /* Standard metadata tags */}
                            <title id="ag_app_title">{title}</title>
                            {/* <link id="ag_favicon" rel="icon" href={favicon || whiteLabelData?.favicon} /> */}
                            <meta id="ag_description" name='description' content={description} />
                            <link rel="apple-touch-icon" href={logo}></link>
                            { /* End standard metadata tags */}

                            { /* Facebook tags */}
                            {/* <meta property="og:type" content={type} /> */}
                            {/* <meta property="og:title" content={title} /> */}
                            {/* <meta property="og:image" content={logo} /> */}
                            {/* <meta property="og:description" content={description} /> */}
                            { /* End Facebook tags */}

                            { /* Twitter tags */}
                            {/* <meta name="twitter:creator" content={name} /> */}
                            {/* <meta name="twitter:card" content={type} /> */}
                            {/* <meta name="twitter:title" content={title} /> */}
                            {/* <meta name="twitter:description" content={description} /> */}
                            { /* End Twitter tags */}
                        </Helmet>
                    </>
                    : null
            }
        </>
    )
}

export default Seo;