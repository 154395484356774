export const referralsConstant = {
    GET_REFERRALS_R: "GET_REFERRALS_R",
    GET_REFERRALS_S: "GET_REFERRALS_S",
    GET_REFERRALS_F: "GET_REFERRALS_F",

    UPDATE_REFERRAL_DATA : "UPDATE_REFERRAL_DATA",

    CREATE_REFERRAL_R : "CREATE_REFERRAL_R",
    CREATE_REFERRAL_S : "CREATE_REFERRAL_S",
    CREATE_REFERRAL_F : "CREATE_REFERRAL_F",

    GET_PAYMENT_CODE_R : "GET_PAYMENT_CODE_R",
    GET_PAYMENT_CODE_S : "GET_PAYMENT_CODE_S",
    GET_PAYMENT_CODE_F : "GET_PAYMENT_CODE_F",
    
    GET_ONE_REFERRAL_DATA_R: "GET_ONE_REFERRAL_DATA_R",
    GET_ONE_REFERRAL_DATA_S: "GET_ONE_REFERRAL_DATA_S",
    GET_ONE_REFERRAL_DATA_F: "GET_ONE_REFERRAL_DATA_F",

    //update reducer data
    UPDATE_REFERRAL: "UPDATE_REFERRAL",

    DELETE_REFERRAL_R: "DELETE_REFERRAL_R",
    DELETE_REFERRAL_S: "DELETE_REFERRAL_S",
    DELETE_REFERRAL_F: "DELETE_REFERRAL_F",
    
};
