export const paymentConstant = {
    // get invoice payment
    GET_INVOICE_PAYMENT_R: "GET_INVOICE_PAYMENT_R",
    GET_INVOICE_PAYMENT_S: "GET_INVOICE_PAYMENT_S",
    GET_INVOICE_PAYMENT_F: "GET_INVOICE_PAYMENT_F",

    // Update Payment Response
    UPDATE_INVOICE_PAYMENT_R: "UPDATE_INVOICE_PAYMENT_R",
    UPDATE_INVOICE_PAYMENT_S: "UPDATE_INVOICE_PAYMENT_S",
    UPDATE_INVOICE_PAYMENT_F: "UPDATE_INVOICE_PAYMENT_F",

    // Update Payment Error Response
    UPDATE_INVOICE_PAYMENT_ERROR_R: "UPDATE_INVOICE_PAYMENT_ERROR_R",
    UPDATE_INVOICE_PAYMENT_ERROR_S: "UPDATE_INVOICE_PAYMENT_ERROR_S",
    UPDATE_INVOICE_PAYMENT_ERROR_F: "UPDATE_INVOICE_PAYMENT_ERROR_F",

    // Update Payment Status
    UPDATE_INVOICE_PAYMENT_STATUS_R: "UPDATE_INVOICE_PAYMENT_STATUS_R",
    UPDATE_INVOICE_PAYMENT_STATUS_S: "UPDATE_INVOICE_PAYMENT_STATUS_S",
    UPDATE_INVOICE_PAYMENT_STATUS_F: "UPDATE_INVOICE_PAYMENT_STATUS_F",
};
