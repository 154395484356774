import { userConstant } from "../constants";

const initialState = {
    users: [],
    loading: false,
    error: null,
}

export default function users(state = initialState, action) {
    switch (action.type) {
        case userConstant.GET_USER_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case userConstant.GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.users
            }
        case userConstant.GET_USER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.message,
            }
        default:
            return state
    }
}
