import { tools } from './tools'

export const drawCanvas = (nodes) => {
    const lastNode = nodes[nodes.length - 1] || {}
    nodes.forEach(node => {
        const { method, props, painted } = node
        node.props.currentMode = lastNode?.type
        if (props.stage?.attrs) {
            props.stage.attrs.drawMode = false
        }
        if (tools[method] && !painted) {
            tools[method](props)
            node.painted = true
        } else {
            // console.log('Not Exists tool: ', method)
        }
    });
}