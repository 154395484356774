import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Import Actions

// Import Styles
import style from "./style";


function MediaSkeleton(props) {

    return (
        <div>
            <Box margin={1}>
                <Skeleton variant="rect" width={150} height={150} />
                <Skeleton variant="text" className="mt-1"/>
            </Box>
        </div>
    )
}

// default props
MediaSkeleton.defaultProps = {
    classes: {}
};

// prop types
MediaSkeleton.propTypes = {
    classes: PropTypes.object
};

// Export component
export default withStyles(style)(MediaSkeleton)
