import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { isMobile } from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
// import { Select as Core } from '@material-ui/core';

import styles from './styles'
import { Grid, IconButton, Tooltip, Zoom } from '@material-ui/core';
import { CloseIcon2, InfoIcon, YoutubePlayIcon, VideoMaximizeIcon, VideoMinimizeIcon } from '../../assets/svg';
import TutorialDialog from './InfoDialog';

/**
 * SwitchComponent
 * @param {*} props
 */

function InfoVideoComponent(props) {
  const { videoSrc, handleInfoVideo, closeInfoVideo, classes, videoPopup, infoTitle, dialogShown } = props;
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [dimensions, setDimensions] = useState("small");

  const previewSize = {
    "small": { width: '240px', height: '135px' },
    "medium": { width: '560px', height: '315px' },
    "large": { width: '720px', height: '405px' }
  }

  useEffect(() => {
    return () => {
      setIsVideoPlaying(false)
    };
  }, [videoPopup]);

  const toggleVideoPlay = () => {
    setIsVideoPlaying(true);
  };

  const toggleSize = () => {
    let previewTypes = Object.keys(previewSize);
    let index = previewTypes.indexOf(dimensions);
    setDimensions(index === previewTypes.length - 1 ? previewTypes[0] : previewTypes[index + 1]);
  };

  function extractVideoId(url) {
    // Regular expression to match YouTube video URLs
    var youtubeRegex = /(?:youtube\.com\/(?:.*?(?:\?|&)v=|embed\/|v\/)|youtu\.be\/)([^?&]+)/;

    // Use the regex to extract the video ID
    var match = url.match(youtubeRegex);

    // If a match is found, return the video ID; otherwise, return null
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }

  var videoID = extractVideoId(videoSrc);
  const { width, height } = previewSize[dimensions];

  return (
    <>
      {
        !isMobile &&
        <>
        {
            !dialogShown ? < TutorialDialog {...props} videoID={videoID} /> :
              <Grid item className={classes.infoVideo}>
                {
                  videoPopup ?
                    <Zoom in={videoPopup}>
                      <Grid className='infoVideoContainer'>
                        <Grid className='infoVideo' style={{ height: `${height}`, width: `${width}`, maxWidth: '100%', maxHeight: '100vh' }}>
                          <iframe width="100%" height="100%" src={isVideoPlaying ? `${videoSrc}&autoplay=1` : videoSrc} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>

                          {!isVideoPlaying && (<Grid className='customPlayBtn' onClick={toggleVideoPlay} style={{ backgroundImage: `url(https://i.ytimg.com/vi/${videoID}/hqdefault.jpg)` }}>
                            <YoutubePlayIcon />
                          </Grid>
                          )}
                        </Grid>
                        <Grid className="dflex alignCenter controlContainer">
                          <Tooltip title={"Close"} arrow>
                            <IconButton className='infoReSizeBtn' onClick={closeInfoVideo}><CloseIcon2 /></IconButton>
                          </Tooltip>
                          <Tooltip title={dimensions === "large" ? "minimize" : "maximize"} arrow>
                            <IconButton className='infoReSizeBtn' onClick={toggleSize}>{dimensions === "large" ? <VideoMinimizeIcon /> : <VideoMaximizeIcon />}</IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Zoom> :
                    <Tooltip title={infoTitle} arrow>
                      <IconButton className='p0 infoVideoClose' onClick={handleInfoVideo}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                }
              </Grid >
        }
        </>
      }
    </>

  );
}

// default props
InfoVideoComponent.defaultProps = {
  className: "",
  classes: "",
  handleInfoVideo: () => { },
  closeInfoVideo: () => { },
  videoPopup: true,
  infoTitle: 'Info',
  dialogShown: true
};

// prop types
InfoVideoComponent.propTypes = {
  className: PropTypes.string,
  videoSrc: PropTypes.string,
  videoPopup: PropTypes.bool,
  dialogShown: PropTypes.bool,
};

export const InfoVideo = withStyles(styles)(InfoVideoComponent);
