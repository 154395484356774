import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from "react-redux";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Menu, MenuItem } from '@material-ui/core';

//import components
import { Button } from '../../../components'

//import history
import history from "../../../config/history"

//import action
import { alertAction, userAgencyAction } from '../../../redux/actions';


function CancelledErrorPopUp(props) {
    //define dispatch
    const dispatch = useDispatch();

    const { userAgencies } = useSelector(s => (s.userAgency));
    const { agency_pref } = useSelector(s => (s.auth.loginUser));
    const { popupType, loginUser: { user_role, id: user_id } } = useSelector(state => state.auth);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateBills = () => {
        history.push(`/mybills${popupType === "Update Payment" ? "?unpaidInvoice" : ""}`)
    }

    //update agencies
    const updateUserAgency = (id) => {
        if (agency_pref !== id) {
            dispatch(userAgencyAction.updateUserAgencies({ agency_id: id }, (res) => {
                setAnchorEl(null);
                if (res && res.message === "Updated Successfully") {
                    history.push("/dashboard")
                    window.location.reload();
                }
            }))
        } else {
            dispatch(alertAction.error("This agency is currently selected "));
            setAnchorEl(null);
        }
    }

    //logout application


    const workspaceSwitch = () => <>
        <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            size='large'
            color={"primary"}
        >
            Switch to another workspace
        </Button>

        <Menu
            id="long-menu"
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                elevation: 0,
                style: {
                    maxHeight: 200,
                    width: '20ch',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {userAgencies.map((item, key) => {
                if (item.agencyID !== agency_pref) {
                    return (<MenuItem key={key} onClick={() => { updateUserAgency(item.agencyID) }}>
                        {item.agency_name ? item.agency_name : ""}
                    </MenuItem>)
                }
            })}
        </Menu>
    </>


    return (
        <>
            {(user_role.type === 'admin') ?
                <>
                    <Grid style={{ padding: '35px' }}>
                        <Typography variant='h3' align="center" className='fw-600 pb15'>
                            {popupType === "Update Payment" ? "Please Pay Your Invoice" : "You've cancelled your plan"}
                        </Typography>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" align="center" style={{ fontSize: '17px', lineHeight: "40px"}}>
                                <Typography variant='body'>
                                {popupType === "Update Payment" ? "Please pay the last invoice to continue using account" : "We've paused your account for now, please Re-activate it to continue growing your agency"}
                                </Typography>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: 'center' }}>
                            <Button variant="contained" onClick={() => { updateBills() }} color={"primary"} title={popupType === "Update Payment" ? "Pay Invoice" : 'Reactivate Now'} size="large" ></Button>

                            {
                                userAgencies && userAgencies.length > 1 ?
                                    workspaceSwitch()
                                    : null
                            }

                            {/* <Button variant="contained" color={"secondary"} title={'Logout'} size='large' onClick={() => logout()}></Button> */}


                        </DialogActions>
                    </Grid>
                </>
                : <>
                    <Grid style={{ padding: '35px' }}>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" align="center" style={{ fontSize: '17px', lineHeight: "40px", color: '#222', fontWeight: 'bold' }}>
                        <Typography variant='h6' color='primary'>
                                {popupType === "Update Payment" ? "Please contact your agency owner" : "We've paused your account for now, please contact your agency owner"}
                           </Typography>
                            </DialogContentText>
                            <DialogActions style={{ justifyContent: 'center' }}>
                                {
                                    userAgencies && userAgencies.length > 1 ?
                                        workspaceSwitch()
                                        : null
                                }
                            </DialogActions>
                        </DialogContent>
                    </Grid>
                </>}
        </>
    )
};

// default props
CancelledErrorPopUp.defaultProps = {
    classes: {}
};

// prop types
CancelledErrorPopUp.propTypes = {
    classes: PropTypes.object
};

export default CancelledErrorPopUp;
