import { BlurNodeOptions } from "./BlurNodeOptions"
import { TextNodeOptions } from "./TextNodeOptions"
import { CropNodeOptions } from "./CropNodeOptions"
import { BrushNodeOptions } from "./BrushNodeOptions"

export const RenderNodeOptions = {
    textNode: TextNodeOptions,
    drawBlur: BlurNodeOptions,
    crop: CropNodeOptions,
    drawPen: BrushNodeOptions
}