import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

//google login package
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';

// import credential
import { app_credentials } from '../../../config/app_credentials';

//Import Component
import { Button } from '../../Button';

// Imports Styles
import styles from "./styles";
import { GoogleIcon } from '../../../assets/svg';

/**
 * Google Login Button Component
 * @param {*} props
 */



function GoogleLoginButtonComponent(props) {
    const { handleGoogleLogin, title } = props;

    // Get Logged In User Details
    useEffect(() => {
        logDeviceInfo();

        // use hook after platform dom ready
        GoogleAuth.init({
            client_id: `${app_credentials.google.client_id}`,
            scopes: ['profile', 'email'],
            grantOfflineAccess: true,
        });
    });

    const signIn = async () => {
        const result = await GoogleAuth.signIn();

        if (result) {
            let tokenObj = {
                ...result.authentication,
            };
            let profileObj = {
                ...result,
                email: result.email,
                googleId: result.id,
                name: result.name,
                imageUrl: result.imageUrl,
                access_token: result.authentication.accessToken
            };
            handleGoogleLogin({ tokenObj, profileObj })
        }
    }

    const logDeviceInfo = async () => {
    };

    return (
        <>
            <Button onClick={() => signIn()} variant="outlined" color="secondary" size="small" className="mb-2 loginBaseBtns" startIcon={<GoogleIcon />}>
                <span className={"loginBtnSpan"}>{title}</span>
            </Button>
        </>
    );
}

// Default props
GoogleLoginButtonComponent.defaultProps = {
    type: "button",
    title: "LOGIN WITH GOOGLE",
    classes: "",
    className: "",
    href: "",
    fullWidth: false,
    size: "small",
    variant: "outlined",
    component: "button",
    disabled: false,
    disableElevation: false,
    onClick: () => { },
    handleGoogleLogin: () => { },
};

// Prop types
GoogleLoginButtonComponent.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    href: PropTypes.any,
    fullWidth: PropTypes.bool,
    variant: PropTypes.string,
    component: PropTypes.elementType,
    disabled: PropTypes.bool,
    disableElevation: PropTypes.bool,
    onClick: PropTypes.func,
    handleGoogleLogin: PropTypes.any,
};

/** Export Component */
export const GoogleLoginAuth = withStyles(styles)(GoogleLoginButtonComponent);
