import moment from "moment";
import { bookingsConstant } from "../constants/bookingConstant";

const initialState = {
    bookingList: [],
    bookingEvent: {},
    formData: {
        questions: [],
        duration: "30",
        before_time: "10",
        after_time: "10",
        timeline: '4',
        timeline_type: '2',
        timeline_value: '240',
        daily_limit: "0",
        time_interval: "15",
        day_limit: "30",
        day_option: "1",
        selectedDates: [],
        timeSlots: {
            dateType: {},
            dayType: {}
        },
        timezone: moment.tz.guess(),
        timezoneOffset: moment().format('Z'),
        tz_conversion:true,
        remainderToggle: false
    },
    eventNotifications: [],
    editQuestionIndex: null,

    // user booking
    bookingEventDetails: {},
    eventListingData: [],
    count: null,
    bookingForm: {},
    eventTimings: [],
    lazyLoad: false,
    bookingListOptions: {
        filter: {},
        count: 0,
        skip: 0,
        limit: 20
    },
    eventDataDetails: {},
    blockedUsersList:[],
    bookingListLoading: true,
    eventData:{},
    availableTime: [],
    preview_slot_dates:[],

    // booking template
    bookingTemplate: {}
}

export default function booking(state = initialState, action) {
    switch (action.type) {
        /** GET BOOKINGS */
        case bookingsConstant.GET_BOOKING_R:
            return {
                ...state,
                bookingList: initialState.bookingList
            }
        case bookingsConstant.GET_BOOKING_S:
            const { skip, limit, bookingList } = action?.data?.data || {};

            return {
                ...state,
                bookingList: skip === 0 ? bookingList : [...state.bookingList, ...bookingList],
                bookingListOptions: {
                    ...state.bookingListOptions,
                    skip, 
                    limit
                },
                bookingListLoading: false
            }
        case bookingsConstant.GET_BOOKING_F:
            return {
                ...state,
                bookingList: initialState.bookingList
            }
        case bookingsConstant.UPDATE_BOOKING_REDUCER:
            return {
                ...state,
                formData: { ...state.formData, customLinks:action.data.meeting_link, ...action.data  }
            }
        case bookingsConstant.CLEAR_BOOKING_REDUCER:
            return {
                ...state,
                formData: { ...initialState.formData, ...action.data },
                eventNotifications:[]
            }
        case bookingsConstant.UPDATE_BOOKING_NOTIFICATION_REDUCER:
            return {
                ...state,
                eventNotifications: [...(action.data || [])]
            }
        case bookingsConstant.GET_BOOKING_EVENT_R:
            return {
                ...state,
                bookingEvent: initialState.bookingEvent
            }
        case bookingsConstant.GET_BOOKING_EVENT_S:
            return {
                ...state,
                bookingEvent: action?.data?.data
            }
        case bookingsConstant.GET_BOOKING_EVENT_F:
            return {
                ...state,
                bookingEvent: initialState.bookingEvent
            }

        case bookingsConstant.UPDATE_BOOKING_EVENT_STATUS_R:
        case bookingsConstant.UPDATE_BOOKING_EVENT_STATUS_F:
            return state
        case bookingsConstant.UPDATE_BOOKING_EVENT_STATUS_S:
            const { params: { id, status } } = action.data;
            let tmpBookingEvents = [...(state.bookingList || [])]
            const eventIndex = tmpBookingEvents.findIndex(f => (f.id === id))
            if (eventIndex > -1) {
                tmpBookingEvents[eventIndex] = { ...tmpBookingEvents[eventIndex], status: Number(status) }
                return {
                    ...state,
                    bookingList: tmpBookingEvents
                }
            } else {
                return state
            }

        // edit question index
        case bookingsConstant.UPDATE_QUESTION_INDEX:
            return {
                ...state,
                editQuestionIndex: action.data
            }

        // get booking event details for end user
        case bookingsConstant.GET_BOOKING_EVENT_DETAILS_R:
            return {
                ...state,
                bookingEventDetails: initialState.bookingEventDetails
            }
        case bookingsConstant.GET_BOOKING_EVENT_DETAILS_S:
            return {
                ...state,
                bookingEventDetails: action?.data?.data
            }
        case bookingsConstant.GET_PREVIEW_EVENT_DETAILS_S:
                return {
                    ...state,
                    preview_slot_dates: action?.data
         }
        case bookingsConstant.GET_BOOKING_EVENT_DETAILS_F:
            return {
                ...state,
                bookingEventDetails: initialState.bookingEventDetails
            }
        case bookingsConstant.GET_BOOKING_EVENT_LIST:
            return {
                ...state,
                count: action.data.count,
                eventListingData: action.data.result
            }

        case bookingsConstant.UPDATE_BOOKING_QUESTIONS:
            return {
                ...state,
                bookingEventDetails: {
                    ...state.bookingEventDetails,
                    questions: [...action.data]
                }
            }

        case bookingsConstant.GET_BOOKING_SLOTS_R:
        case bookingsConstant.GET_BOOKING_SLOTS_F:
            return {
                ...state,
                lazyLoad: true
            }
        case bookingsConstant.GET_BOOKING_SLOTS_S:
            return {
                ...state,
                eventTimings: action.data?.data || [],
                lazyLoad: false
            }

        case bookingsConstant.UPDATE_BOOKING_LIST_FILTER:
            return {
                ...state,
                bookingListOptions: {
                    filter: action?.data || {},
                }
            }

        case bookingsConstant.GET_BOOKING_EVENT_DATA:
            return {
                ...state,
                eventDataDetails: action.data
            }

        case bookingsConstant.GET_BOOKINGS_EVENT:
            return {
                ...state,
                eventData: action.data
            }
        case bookingsConstant.GET_BOOKINGS_EVENT_R:
                return {
                    ...state,
                    eventData: initialState.eventData
                }

        case bookingsConstant.GET_BOOKING_NOTIFICATION_R:
        case bookingsConstant.GET_BOOKING_NOTIFICATION_F:
            return {
                ...state
            }
        case bookingsConstant.GET_BOOKING_NOTIFICATION_S:
            return {
                ...state,
                eventNotifications: action.data?.data || [],
            }
        case bookingsConstant.BLOCKED_USERS_LIST:
            return {
               ...state,
               blockedUsersList:action.data.result,
               blockedUsersCount:action.data.count
            }

        case bookingsConstant.GET_BOOKING_COUNT_R:
            const { skip: skipValue, searchKey } = action?.params || {};

            return {
                ...state,
                bookingListLoading: skipValue || searchKey ? false : true
            }
        case bookingsConstant.GET_BOOKING_COUNT_F:
            return {
                ...state
            }
        case bookingsConstant.GET_BOOKING_COUNT_S:
            return {
                ...state,
                bookingListOptions:{
                    ...state.bookingListOptions,
                    count: action.data?.data || 0
                }
            }
        case bookingsConstant.GET_BOOKING_EVENT_LIST_S:
            return {
                ...state,
                count: action.data.count,
                eventListingData: [
                    ...state.eventListingData,
                    ...action.data.result
                ]
            }
        case bookingsConstant.GET_AVAILABLE_TIME_R:
            return {
                ...state,
                availableTime: initialState.availableTime
            }
        case bookingsConstant.GET_AVAILABLE_TIME_S:
            return {
                ...state,
                availableTime: action?.data?.data
            }
        case bookingsConstant.GET_AVAILABLE_TIME_F:
            return {
                ...state,
                availableTime: initialState.availableTime
            }
        case bookingsConstant.GET_BOOKING_TEMPLATES_R:
            return {
                ...state,
                bookingTemplate: initialState.bookingTemplate
            }
        case bookingsConstant.GET_BOOKING_TEMPLATES_S:
            return {
                ...state,
                bookingTemplate: action?.data?.data
            }
        case bookingsConstant.GET_BOOKING_TEMPLATES_F:
            return {
                ...state,
                bookingTemplate: initialState.bookingTemplate
            }
        case bookingsConstant.UPDATE_BOOKING_TEMPLATE_REDUCER:
            return {
                ...state,
                formData: { ...state.formData, ...action.data, isTemplateUsing: true }
            }

        default:
            return state
    }
}