import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';

//import history
import history from "../../../config/history"

// import child components
import UserErrorPopUp from './UserErrorPopUp';
import PaymentErrorPopUp from './PaymentErrorPopUp';
import CancelledErrorPopUp from './CancelledErrorPopUp';
import { useLocation } from 'react-router-dom';

function WorkspacePopUpError(props) {
    // Define Dispatch

    const { location: { search } } = history;
    const { pathname } = useLocation();
    const isTrue = search === "?plan=1" ? true : false

    const { popupType, loginUser: { status, ag_owner, id: user_id } } = useSelector(s => s.auth);

    const { planDetails } = useSelector(s => s.userAgency);

    useEffect(()=>{
        if(popupType && (!pathname.toLowerCase().includes("agencyadd") && !pathname.includes("mybills") && !pathname.includes("upgradePlan"))){
            history.push("/settings/profile")
        }
    },[pathname])

    // Status => 3  (No Card Details)
    if (!pathname.toLowerCase().includes("agencyadd") && !pathname.includes("upgradePlan") && !isTrue && (popupType === "Your access has been expired" || status === 3)) {
        return (
            <React.Fragment>
                <Dialog
                    open={true}
                    onClose={() => { }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="alertDialogue"
                >
                    {
                        (ag_owner === user_id || !planDetails.stripe_id) ?
                            <PaymentErrorPopUp /> :
                            <UserErrorPopUp />
                    }


                </Dialog>
            </React.Fragment>
        )
    } // Status => 2  (Cancelled Subscription)
    else if ((!pathname.toLowerCase().includes("agencyadd") && !pathname.includes("mybills") && !pathname.includes("upgradePlan")) && (popupType === "Paused Subscription" || popupType === "Update Payment" || status === 2)) {
        return (
            <React.Fragment>
                <Dialog
                    open={true}
                    onClose={() => { }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="alertDialogue"
                >
                    {
                        ag_owner === user_id ?
                            <CancelledErrorPopUp popupType={popupType} /> :
                            <UserErrorPopUp />
                    }

                </Dialog>
            </React.Fragment>
        )
    } else {
        return null;
    }
}

// default props
WorkspacePopUpError.defaultProps = {
    classes: {}
};

// prop types
WorkspacePopUpError.propTypes = {
    classes: PropTypes.object
};

export default WorkspacePopUpError
