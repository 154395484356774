/* eslint-disable react/prop-types */
import React, {forwardRef} from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { TextField as Core } from '@material-ui/core';

// Imports Styles
import styles from "./styles";
/**
 * TextField Component
 * @param {*} props
 */
const TextFieldComponent = forwardRef(({ id, name, placeholder, autoFocus, autoComplete, 
  margin, min, variant, fullWidth, withImage, imageType, passShow, ...rest }, ref) => {


  return (
    <React.Fragment>
      <Core
        id={id}
        placeholder={placeholder}
        name={name}
        autoComplete={autoComplete}
        margin={margin}
        variant={variant}
        autoFocus={autoFocus}
        fullWidth={fullWidth}
        inputRef={ref}
        {...rest}
        // label={<span className="focus-border"><i></i></span>}
      />
    </React.Fragment>
  );
})

// default props
TextFieldComponent.defaultProps = {
  id: "",
  name: "",
  type: "text",
  autoComplete: "new-password",
  min: "0",
  margin: "dense",
  variant: "outlined",
  fullWidth: true,
  withImage: false,
  passShow: false,
};

// prop types
TextFieldComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  min: PropTypes.string,
  autoComplete: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  withImage: PropTypes.bool,
  passShow: PropTypes.bool
};

// export component
export const TextField = withStyles(styles)(TextFieldComponent);
