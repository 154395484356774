export const scheduleConstant = {

    //Get Schedule Message List 
    GET_SCHEDULE_MESSAGE_LIST_R: "GET_SCHEDULE_MESSAGE_LIST_R",
    GET_SCHEDULE_MESSAGE_LIST_S: "GET_SCHEDULE_MESSAGE_LIST_S",
    GET_SCHEDULE_MESSAGE_LIST_F: "GET_SCHEDULE_MESSAGE_LIST_F",
    
    //Preview Added Schedule Message
    UPDATE_ADDED_SCHEDULE_MESSAGE_R: "UPDATE_ADDED_SCHEDULE_MESSAGE_R",
    UPDATE_ADDED_SCHEDULE_MESSAGE_S: "UPDATE_ADDED_SCHEDULE_MESSAGE_S",
    UPDATE_ADDED_SCHEDULE_MESSAGE_F: "UPDATE_ADDED_SCHEDULE_MESSAGE_F",

    //UPDATE SCHEDULE REDUCER
    UPDATE_SCHEDULE_REDUCER: "UPDATE_SCHEDULE_REDUCER",

    //UPDATE SCHEDULE REDUCER
    UPDATE_SCHEDULE_GROUP_REDUCER: "UPDATE_SCHEDULE_GROUP_REDUCER",

    //CLEAR SCHEDULE REDUCER
    CLEAR_SCHEDULE_REDUCER:"CLEAR_SCHEDULE_REDUCER",

    //Get Search Flow List
    GET_SEARCH_FLOW_LIST_R: "GET_SEARCH_FLOW_LIST_R",
    GET_SEARCH_FLOW_LIST_S: "GET_SEARCH_FLOW_LIST_S",
    GET_SEARCH_FLOW_LIST_F: "GET_SEARCH_FLOW_LIST_F",

    //Get Search Client List
    GET_SEARCH_CLIENT_LIST_R: "GET_SEARCH_CLIENT_LIST_R",
    GET_SEARCH_CLIENT_LIST_S: "GET_SEARCH_CLIENT_LIST_S",
    GET_SEARCH_CLIENT_LIST_F: "GET_SEARCH_CLIENT_LIST_F",

    //Get Search Teams List
    GET_SEARCH_TEAM_LIST_R: "GET_SEARCH_TEAM_LIST_R",
    GET_SEARCH_TEAM_LIST_S: "GET_SEARCH_TEAM_LIST_S",
    GET_SEARCH_TEAM_LIST_F: "GET_SEARCH_TEAM_LIST_F",

    //Get Selected Schedule Group List
    GET_SELECTED_SCHEDULE_IDS_R: "GET_SELECTED_SCHEDULE_IDS_R",
    GET_SELECTED_SCHEDULE_IDS_S: "GET_SELECTED_SCHEDULE_IDS_S",
    GET_SELECTED_SCHEDULE_IDS_F: "GET_SELECTED_SCHEDULE_IDS_F",

    //Get Added Schedule Message
    GET_ADDED_SCHEDULE_MESSAGE_R: "GET_ADDED_SCHEDULE_MESSAGE_R",
    GET_ADDED_SCHEDULE_MESSAGE_S: "GET_ADDED_SCHEDULE_MESSAGE_S",
    GET_ADDED_SCHEDULE_MESSAGE_F: "GET_ADDED_SCHEDULE_MESSAGE_F",

    //Get Message Details

    GET_MESSAGE_DETAIL_R: "GET_MESSAGE_DETAIL_R",
    GET_MESSAGE_DETAIL_S: "GET_MESSAGE_DETAIL_S",
    GET_MESSAGE_DETAIL_F: "GET_MESSAGE_DETAIL_R",

    //Get Schedule Filter
    GET_FILTER_LIST_R: "GET_FILTER_LIST_R",
    GET_FILTER_LIST_S: "GET_FILTER_LIST_S",
    GET_FILTER_LIST_F: "GET_FILTER_LIST_F",

    //UPDATE SELECTED REDUCER
    UPDATE_SELECTED_REDUCER: "UPDATE_SELECTED_REDUCER",

    //UPDATE SELECTED ID REDUCER
    UPDATE_SELECTED_ID_REDUCER: "UPDATE_SELECTED_ID_REDUCER",

    //UPDATE PAGE FILTER REDUCER
    UPDATE_PAGE_FILTER_REDUCER: "UPDATE_PAGE_FILTER_REDUCER",

    //Save Flows, Clients, Direct Schedule Groups
    SAVE_SCHEDULE_GROUP_R: "SAVE_SCHEDULE_GROUP_R",
    SAVE_SCHEDULE_GROUP_S: "SAVE_SCHEDULE_GROUP_S",
    SAVE_SCHEDULE_GROUP_F: "SAVE_SCHEDULE_GROUP_F",
    
    //Update Flows, Clients, Direct Schedule Groups
    SAVE_SCHEDULE_R: "SAVE_SCHEDULE_R",
    SAVE_SCHEDULE_S: "SAVE_SCHEDULE_S",
    SAVE_SCHEDULE_F: "SAVE_SCHEDULE_F",

    // Save added Schedule Message
    SAVE_ADD_SCHEDULE_MESSAGE_INPUT_R: "SAVE_ADD_SCHEDULE_MESSAGE_INPUT_R",
    SAVE_ADD_SCHEDULE_MESSAGE_INPUT_S: "SAVE_ADD_SCHEDULE_MESSAGE_INPUT_S",
    SAVE_ADD_SCHEDULE_MESSAGE_INPUT_F: "SAVE_ADD_SCHEDULE_MESSAGE_INPUT_F",

    //Update Schedule Message
    UPDATE_SCHEDULE_MESSAGE_R: "UPDATE_SCHEDULE_MESSAGE_R",
    UPDATE_SCHEDULE_MESSAGE_S: "UPDATE_SCHEDULE_MESSAGE_S",
    UPDATE_SCHEDULE_MESSAGE_F: "UPDATE_SCHEDULE_MESSAGE_F",

    //Update Schedule Message Status
    UPDATE_SCHEDULE_MESSAGE_STATUS_R: "UPDATE_SCHEDULE_MESSAGE_STATUS_R",
    UPDATE_SCHEDULE_MESSAGE_STATUS_S: "UPDATE_SCHEDULE_MESSAGE_STATUS_S",
    UPDATE_SCHEDULE_MESSAGE_STATUS_F: "UPDATE_SCHEDULE_MESSAGE_STATUS_F",

    //Upload Message File
    UPLOAD_MESSAGE_FILES_R: "UPLOAD_MESSAGE_FILES_R",
    UPLOAD_MESSAGE_FILES_S: "UPLOAD_MESSAGE_FILES_S",
    UPLOAD_MESSAGE_FILES_F: "UPLOAD_MESSAGE_FILES_F",

    // Delete added Schedule Message
    DELETE_ADDED_SCHEDULE_MESSAGE_R: "DELETE_ADDED_SCHEDULE_MESSAGE_R",
    DELETE_ADDED_SCHEDULE_MESSAGE_S: "DELETE_ADDED_SCHEDULE_MESSAGE_S",
    DELETE_ADDED_SCHEDULE_MESSAGE_F: "DELETE_ADDED_SCHEDULE_MESSAGE_F",

    // Delete Message Item
    DELETE_MESSAGE_ITEM_R: "DELETE_MESSAGE_ITEM_R",
    DELETE_MESSAGE_ITEM_S: "DELETE_MESSAGE_ITEM_S",
    DELETE_MESSAGE_ITEM_F: "DELETE_MESSAGE_ITEM_F",


    

}
