import React, { Component, createRef } from "react";
import PropTypes from 'prop-types';

class OutsideClickHandlerComponent extends Component {
  wrapperRef = createRef();

  static defaultProps = {
    onOutsideClick: () => {}
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      this.props.onOutsideClick(event);
    }
  };

  render() {
    const { children } = this.props;

    return <div ref={this.wrapperRef}>{children}</div>;
  }
}

OutsideClickHandlerComponent.propTypes = {
  children: PropTypes.element.isRequired,
  onOutsideClick: PropTypes.func,
};


// export component
export const OutsideClickHandler = OutsideClickHandlerComponent;