import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { getAuth, getRedirectResult, OAuthProvider, signInWithPopup } from "firebase/auth";
import AppleIcon from '@material-ui/icons/Apple';

//Import Component
import { Button } from '../Button';

// Imports Styles   
import styles from "./styles";

/**
 * Apple Login Button Component
 * @param {*} props
 */
function AppleSignInButtonComponent(props) {
    const { handleAppleLogin } = props;

    useEffect(() => {
        // appleSignInRedirectResult();
    })

    const auth = getAuth();


    // Signing with apple with redirect 
    const signInWithApple = () => {
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        signInWithPopup(auth, provider).then(result => {
            handleAppleLogin(result)
        }).catch((error) => {
            handleAppleLogin(error)
        });
    }


    function appleSignInRedirectResult() {
        // [START auth_apple_signin_redirect_result]
        // Result from Redirect auth flow.
        getRedirectResult(auth)
            .then((result) => {
                handleAppleLogin(result)
            })
            .catch((error) => {
                // Handle Errors here.
                // var errorCode = error.code;
                // var errorMessage = error.message;
                // The email of the user's account used.
                // var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                // var credential = error.credential;
                handleAppleLogin(error)
            });
        // [END auth_apple_signin_redirect_result]
    }

    return (
        <>
            <Button variant="outlined" color="secondary" size="small" className="mb-1 loginBaseBtns" startIcon={<AppleIcon className={"loginAppleIcon"} />} {...props} onClick={() => signInWithApple()}><span className={"loginBtnSpan"}>Apple ID</span></Button>
        </>
    );
}

// Default props
AppleSignInButtonComponent.defaultProps = {
    type: "button",
    title: "",
    classes: "",
    className: "",
    href: "",
    fullWidth: false,
    size: "small",
    variant: "outlined",
    component: "button",
    disabled: false,
    disableElevation: false,
    onClick: () => { },
    handleAppleLogin: () => { },
};

// Prop types
AppleSignInButtonComponent.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    classes: PropTypes.object,
    className: PropTypes.string,
    href: PropTypes.any,
    fullWidth: PropTypes.bool,
    variant: PropTypes.string,
    component: PropTypes.elementType,
    disabled: PropTypes.bool,
    disableElevation: PropTypes.bool,
    onClick: PropTypes.func,
    handleAppleLogin: PropTypes.any,
};

/** Export Component */
export const AppleSignIn = withStyles(styles)(AppleSignInButtonComponent);
