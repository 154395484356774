import { baseAction } from "./baseAction";

const { success } = baseAction;

// Export All Base Action Functions
export const updateAction = {
    update
};

/**
 * Update Reducer Data
 */
function update(type, data, successCallback = () => { }) {
    return (dispatch) => {
        dispatch(success(type, data));
        successCallback()
    }
};