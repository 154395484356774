import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// Import Actions

// Import Styles
import style from "./style";
import { Skeleton } from "@material-ui/lab";
import { Box } from "@material-ui/core";

function ListSkeleton(props) {

    return (
        <div className="pr-1 pl-1 pb-2">
            <Box display="flex" alignItems="center">
                <Box width="100%">
                    <Skeleton width="60%" variant="text" />
                </Box>
            </Box>
        </div>
    )
}

// default props
ListSkeleton.defaultProps = {
    classes: {}
};

// prop types
ListSkeleton.propTypes = {
    classes: PropTypes.object
};

// Export component
export default withStyles(style)(ListSkeleton)
