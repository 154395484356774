import React from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';

//import react fb login package
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

//import credential
import { app_credentials } from '../../config/app_credentials';

//Import Component
import { Button } from '../Button';

// Imports Styles
import styles from "./styles";
import { FaceBookIcon } from '../../assets/svg';

const { facebook } = app_credentials;

/**
 * Facebook Login Button Component
 * @param {*} props
 */
function FaceBookLoginButtonComponent(props) {
    const { handleFbLogin, title } = props;

    return (
        <>
            <FacebookLogin
                appId={facebook["appId"]}
                // autoLoad={true}
                fields="name,email,picture"
                callback={handleFbLogin}
                onFailure={handleFbLogin}
                cssClass="my-facebook-button-class"
                icon={<span className="icon-fb icon"></span>}
                render={renderProps => (
                    <Button onClick={renderProps.onClick} color="secondary" variant="outlined" size="small" className="mb-2 loginBaseBtns" startIcon={<FaceBookIcon />}><span className={"loginBtnSpan"}>{title}</span></Button>
                )}
            />
        </>
    );
}

// Default props
FaceBookLoginButtonComponent.defaultProps = {
    type: "button",
    className: "",
    disabled: false,
    title: "LOGIN WITH FACEBOOK", 
    onClick: () => { },
    faceBookLoginResponse: () => { }
};

// Prop types
FaceBookLoginButtonComponent.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
    fullWidth: PropTypes.bool,
    faceBookLoginResponse: PropTypes.func
};

/** Export Component */
export const FaceBookLoginButton = withStyles(styles)(FaceBookLoginButtonComponent);
