import React from "react"
import { IconButton } from '@material-ui/core'
import { MenuList } from "./MenuList"

export const EditorMenus = (props) => {
    const { classes, stageData, onMenuClick,type } = props
    const { selectedNode } = stageData || {}
    const { nodeType } = selectedNode?.attrs || {}

    const isActive = (m) => {
        return m.isActive ? m.isActive({ nodeType, stageData }) : false
    }

    return (
        <div className={`${classes.ciTools} ciTools`}>
            <div className="ciToolsInner" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginTop: type ? '60px' : ""}}>
                {
                    MenuList.map(({ MenuIcon, ...m }, i) => {
                        const isCurrent = isActive(m)
                        return (
                            <IconButton
                                key={`ci_menu_${i}`}
                                className={`${classes.menuIcons} ${isCurrent ? 'active' : ''}`}
                                title={m.label}
                                onClick={onMenuClick(m.method)}
                                disabled={m.isDisabled({ stageData, nodeType })}
                            >
                                <MenuIcon
                                    className={isCurrent ? 'active' : ''}
                                    color={isCurrent ? 'blue' : null}
                                />
                            </IconButton>
                        )
                    })
                }
            </div>
        </div>
    )
}