
import React, { Fragment } from "react";
import { Grid, withStyles } from "@material-ui/core";
import PropTypes from 'prop-types';

// images
import { EmptyTask, PlusIconCode} from "../../assets/svg";
import styles from "./styles";

// components
import { Button, Typography } from "../../components";

// constants

const EmptyListing = (props) => {
    // props
    const { classes, description, buttonLabel, onButtonClick, customImage  } = props;

    return (
        <Fragment>
            <Grid container className={`${classes.emptyContainer} emptyContainer`} justifyContent='center' alignItems='center'>
                <Grid item align="center" className='textCenter'>
                    {customImage}
                    <Typography variant="h6" className="secondary2 mt-2 p-1" align="center">{description}</Typography>
                    <Button onClick={onButtonClick} variant={"contained"} color={"primary"} startIcon={<PlusIconCode />} className={"mt-2"}>{buttonLabel}</Button>
                </Grid>
            </Grid>
        </Fragment>
    );
};

EmptyListing.defaultProps = {
    classes: {},
    description: '',
    buttonLabel: "Create New",
    customImage: <EmptyTask />,
    onButtonClick: () => {},
};

EmptyListing.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.string,
    description: PropTypes.string,
    buttonLabel: PropTypes.string,
    customImage: PropTypes.any,
    onButtonClick: PropTypes.func
}

export const EmptyListingPage = withStyles(styles)(EmptyListing)