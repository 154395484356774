import { chatHubConstant } from "../constants/chatHubConstant";

const initialState = {
    projects: [],
    clients: [],
    team: [],
    activeHub: {
        type: null,
        id: null,
        title: "",
        users: [],
        chat_room: null,
        newUsers: [],
        // muteNotification: 0
    },
    newGroup: {
        grpName: "",
        group_avatar: "",
        grpUsers: [],
    },
    allUsers: [],
    mobileChatScreenStatus: false,
    newChat: [],
    associatedusers: {
        client: [],
        project: []
    },
    mutedChat: {
        projects: [],
        clients: [],
        team: []
    }
};

const chatHubReducer = (state = initialState, action) => {
    switch (action.type) {
        // Projects Data
        case chatHubConstant.GET_CHAT_PROJECTS_R:
            return {
                ...state,
                projects: []
            };
        case chatHubConstant.GET_CHAT_PROJECTS_S:

            return {
                ...state,
                projects: action.data.data
            };
        case chatHubConstant.GET_CHAT_PROJECTS_F:
            return {
                ...state,
                projects: []
            };

        //Client Data
        case chatHubConstant.GET_CHAT_CLIENTS_R:
            return {
                ...state,
                clients: []
            };
        case chatHubConstant.GET_CHAT_CLIENTS_S:
            return {
                ...state,
                clients: action.data.data
            };
        case chatHubConstant.GET_CHAT_CLIENTS_F:
            return {
                ...state,
                clients: []
            };

        //Teams Data
        case chatHubConstant.GET_CHAT_TEAM_R:
            return {
                ...state,
                // team: initialState.team
            };
            case chatHubConstant.GET_CHAT_TEAM_S:
                return {
                    ...state,
                    team: action.data 
                };
            
        case chatHubConstant.GET_CHAT_TEAM_F:
            return {
                ...state,
                team: initialState.team
            };
        case chatHubConstant.UPDATE_CHAT_HUB_TYPE:
            return {
                ...state,
                activeHub: {
                    ...initialState.activeHub,
                    type: action.data
                }
            };
        case chatHubConstant.UPDATE_CHAT_HUB_ROOM_R:
            return {
                ...state,
                activeHub: initialState.activeHub,
                mobileChatScreenStatus: true
            };
        case chatHubConstant.UPDATE_CHAT_HUB_ROOM_S:
            return {
                ...state,
                activeHub: {
                    ...action.data
                },
                mobileChatScreenStatus: true
            };
        case chatHubConstant.UPDATE_CHAT_HUB_ROOM:
            return {
                ...state,
                activeHub: {
                    ...action.data
                },
                mobileChatScreenStatus: true
            };

        case chatHubConstant.UPDATE_NEW_TEAM:
            return {
                ...state,
                newGroup: action.data
            }

        //Teams Data
        case chatHubConstant.GET_ALL_USERS_R:
            return {
                ...state,
                allUsers: []
            };
        case chatHubConstant.GET_ALL_USERS_S:
            return {
                ...state,
                allUsers: action.data.data
            };
        case chatHubConstant.GET_ALL_USERS_F:
            return {
                ...state,
                allUsers: []
            };

        //Teams Data
        case chatHubConstant.CREATE_NEW_GROUP_R:
            return {
                ...state,
            };
        case chatHubConstant.CREATE_NEW_GROUP_S:
            return {
                ...state,
                newGroup: initialState.newGroup
            };
        case chatHubConstant.CREATE_NEW_GROUP_F:
            return {
                ...state,
            };

        //All Chat Room Data
        case chatHubConstant.GET_ALL_CHAT_ROOMS_R:
            return {
                ...state,
                projects: state.projects,
                clients: state.clients,
                team: state.team,
            };
        case chatHubConstant.GET_ALL_CHAT_ROOMS_S:

            return {
                ...state,
                projects: action.data.projects,
                clients: action.data.clients,
                team: action.data.team
            };
        case chatHubConstant.GET_ALL_CHAT_ROOMS_F:
            return {
                ...state,
            };
        case chatHubConstant.UPDATE_MOBILE_CHAT_SCREEN:
            return {
                ...state,
                mobileChatScreenStatus: action.data
            };

        //New Chat Messages
        case chatHubConstant.GET_NEW_CHAT_MESSAGE_R:
            return {
                ...state,
            };
        case chatHubConstant.GET_NEW_CHAT_MESSAGE_S:
            return {
                ...state,
                newChat: action.data.data
            };

        case chatHubConstant.UPDATE_NEW_CHAT_MESSAGE_S:
            return {
                ...state,
                newChat: action.data
            };

        case chatHubConstant.UPDATE_PROJECT_SORT:

            let message = action.data;
            let projects = state.projects || [];

            for (let i = 0; i < projects.length; i++) {
                if (projects[i] && message && projects[i]["job_id"] === message["job_id"]) {
                    projects[i]["message_time"] = message["modified_on"]
                    projects[i]["message_category"] = message["message_category"];
                    projects[i]["message_from"] = message["message_from"];
                    projects[i]["message_text"] = message["message_text"];
                    projects[i]["message_id"] = message["id"];
                    projects[i]["undo"] = message["undo"];
                }
            }

            projects.sort(function (a, b) {
                return new Date(b["message_time"]) - new Date(a["message_time"]);
            })

            return {
                ...state,
                projects: [...projects]
            }

        case chatHubConstant.UPDATE_CLIENT_SORT:

            let message1 = action.data;
            let clients = state.clients || [];

            for (let i = 0; i < clients.length; i++) {
                if (clients[i] && message1 && clients[i]["client_id"] === message1["client_id"]) {
                    clients[i]["message_time"] = message1["modified_on"]
                    clients[i]["message_category"] = message1["message_category"];
                    clients[i]["message_from"] = message1["message_from"];
                    clients[i]["message_text"] = message1["message_text"];
                    clients[i]["message_id"] = message1["id"];
                    clients[i]["undo"] = message1["undo"];
                }
            }

            clients.sort(function (a, b) {
                return new Date(b["message_time"]) - new Date(a["message_time"]);
            })

            return {
                ...state,
                clients: [...clients]
            }

        case chatHubConstant.UPDATE_TEAM_SORT:

            let message2 = action.data;
            let grp = state.team || [];

            for (let i = 0; i < grp.length; i++) {
                if (grp[i] && message2 && grp[i]["id"] === message2["group_id"]) {
                    grp[i]["message_time"] = message2["modified_on"]
                    grp[i]["message_category"] = message2["message_category"];
                    grp[i]["message_from"] = message2["message_from"];
                    grp[i]["message_text"] = message2["message_text"];
                    grp[i]["message_id"] = message2["id"];
                    grp[i]["undo"] = message2["undo"];
                }
            }

            grp.sort(function (a, b) {
                return new Date(b["message_time"]) - new Date(a["message_time"]);
            })

            return {
                ...state,
                team: [...grp]
            }

        case chatHubConstant.UPDATE_GROUP_IMAGE:
            return {
                ...state,
                activeHub: action.data
            };


        case chatHubConstant.GET_CLIENT_ASSOC_USERS_R:
            return {
                ...state,
            };
        case chatHubConstant.GET_CLIENT_ASSOC_USERS_S:
            return {
                ...state,
                associatedusers: action.data.data
            };
        case chatHubConstant.GET_CLIENT_ASSOC_USERS_F:
            return {
                ...state,
            };

        // To update muteNotification constants
        // case chatHubConstant.UPDATE_MUTE_NOTIFICATION_S:
        //     return {
        //         ...state,
        //         activeHub: {
        //             ...state.activeHub,
        //             muteNotification: action.data
        //         }
        //     };

        case chatHubConstant.GET_ALL_MUTED_CHAT_R:
            return {
                ...state,
                mutedChat: initialState.mutedChat
            };
        case chatHubConstant.GET_ALL_MUTED_CHAT_S:
            return {
                ...state,
                mutedChat: { ...state.mutedChat, ...action.data }
            };
        case chatHubConstant.GET_ALL_MUTED_CHAT_F:
            return {
                ...state,
                mutedChat: initialState.mutedChat
            };
        
        case chatHubConstant.UPDATE_CHATHUB_MESSAGE:
            return {
                ...state,
                [action.data.type]: (state[action?.data?.type] || []).map(m => {
                    if (m.message_id == action.data.message_id && m.id == action?.data?.job_id) {
                        m = {
                            ...(m || {}),
                            ...(action?.data?.value || {})
                        }
                    }
                    return m
                })
            }

        default:
            return state;
    }
}

export default chatHubReducer;