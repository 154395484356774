const style = (theme) => ({

    CallRequestContainer: {
        textAlign: "center",
        position: "absolute",
        zIndex: 9999,
        bottom: "37%",
        width: '409.427px',
        height: '259.94px',
        left: '72%',
        top: "10%",
        backgroundColor: "white"
    },
    requestVideo: {
        height: "259.94px",
        width: "409.94px",
        filter: 'blur(1.5px)',
        borderRadius: "10px"

    },
    RequestHeader: {
        position: 'absolute',
        top: '10%',
        left: '5%',
        zIndex: 999
    },
    RequstOptionsContainer: {
        width: "409.94px",
        height: '50px',
        position: 'absolute',
        top: '80%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '15px'
    },
    answerBtn: {
        display: 'flex',
        width: '84.122px',
        height: '33.674px',
        padding: '3.523px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4.404px',
        borderRadius: '5.285px',
        background: ' var(--Primary-600-base, #2563EB)'
    },
    


})

export default style