import React from "react";
import { Dialog, DialogActions, DialogContent, Grid, IconButton, InputAdornment, withStyles } from "@material-ui/core";

import Style from "./styles";
import { TextField } from "../TextField";
import { Button } from "../Button";
import { CloseIcon } from "../../assets/svg";




const CopyLink = ({ classes, open, url, dialogContents, buttonTitle, handleClose, handleCopyBtn }) => {
    return(
       <Dialog 
        open={open}
        onClose={handleClose}
        className={classes.dialogWidth}
        > 
        <DialogContent >
            <Grid item className={classes.titleContainer}>
                <IconButton className={classes.closeBtn} onClick={handleClose}><CloseIcon /></IconButton>
            </Grid>
            <Grid item className={classes.dialogContent}>
                {dialogContents}
            </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
                <TextField className={classes.urlContainer} value={`https://${url}`}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button className={classes.primaryButton} title={buttonTitle} onClick={() => {handleCopyBtn(url)}} />
                        </InputAdornment>
                    ),
                }}
             />
        </DialogActions>
       </Dialog>
    )
}

export default withStyles(Style)(CopyLink);

