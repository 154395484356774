const styles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
    },
    resultBox: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "relative",
    },
    titleBox: {
        marginBottom: "1rem",
    },
    previewBox: {
        display: "flex",
        flexDirection: "row",
        "& .itemBox": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "50%",
            padding: "1rem",
        },
        "& .img-preview": {
            border: "2px solid #888",
            overflow: "hidden"
        }
    },
    boxLeft: {

    },
    boxRight: {

    },
    topBar: {
        top: "2%",
        color: "#fff",
        right: "2%",
        background: "#78787891",
        borderRadius: "50%",
        position: "absolute",
        cursor: "pointer",
        "& button": {
            cursor: "pointer",
            margin: "1rem",
        },
    },
    cropperGrid: {
        background: "#f1f1f1",
        padding: "1rem",
        paddingBottom: 0,
        position: "relative",
        "& .cropper-crop-box": {
            borderRadius: "50%"
        },
        "& .cropper-view-box": {
            borderRadius: "50%",
            boxShadow: "0 0 0 1px #39f",
            outline: 0,
        }
    },
    cropperGrid2: {
        background: "#f1f1f1",
        padding: "1rem",
        paddingBottom: 0,
        position: "relative",
        "& .cropper-crop-box": {
            // borderRadius: "50%"
        },
        "& .cropper-view-box": {
            // borderRadius: "50%",
            boxShadow: "0 0 0 1px #39f",
            outline: 0,
        }
    },
    cropperCloseButton:{
        position: "absolute",
        right: "4%",
        top: "6%",
        background: "#ffffff4d",
        borderRadius: "50%",
        cursor: "pointer",
        zIndex: 1,
        "& span": {
            color: "#fff",
            "& .MuiSvgIcon-root":{
                color: "#fff",
            }
        },
    },

    buttonBox: {
        display: "flex",
        justifyContent: "space-evenly",
        margin: "1rem 0",
        "& button": {
            padding: "1rem",
            color: "#222",
            border: "2px solid #222",
            cursor: "pointer",
            "&:hover": {
                background: "#22222240",
            }
        },
        '@media only screen and (max-width: 768px)': {
            "& button": {
                padding: "0.4rem !important",
            },
        },
        '@media only screen and (max-width: 400px)': {
            "& button": {
                padding: "0.2rem !important",
            },
        },
    }
})


export default styles