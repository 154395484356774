export const videoCallConstants = {

    GET_VIDEOCALL_TOKEN_R: "GET_VIDEOCALL_TOKEN_R",
    GET_VIDEOCALL_TOKEN_S: "GET_VIDEOCALL_TOKEN_S",
    GET_VIDEOCALL_TOKEN_F: "GET_VIDEOCALL_TOKEN_F",

    SEND_REQUEST_R: "SEND_REQUEST_R",
    SEND_REQUEST_S: "SEND_REQUEST_S",
    SEND_REQUEST_F: "SEND_REQUEST_F",

    JOIN_REQUEST_R: "JOIN_REQUEST_R",
    JOIN_REQUEST_S: "JOIN_REQUEST_S",
    JOIN_REQUEST_F: "JOIN_REQUEST_F",

    RECEIVE_VIDEO_CALL_REQUEST_R: "RECEIVE_VIDEO_CALL_REQUEST_R",
    RECEIVE_VIDEO_CALL_REQUEST_S: "RECEIVE_VIDEO_CALL_REQUEST_S",
    RECEIVE_VIDEO_CALL_REQUEST_F: "RECEIVE_VIDEO_CALL_REQUEST_F",

    REMOTE_USERS_R: "REMOTE_USERS_R",
    REMOTE_USERS_S: "REMOTE_USERS_S",
    REMOTE_USERS_F: "REMOTE_USERS_F",

    GET_REMOTE_USERS_R: "GET_REMOTE_USERS_R",
    GET_REMOTE_USERS_S: "GET_REMOTE_USERS_S",
    GET_REMOTE_USERS_F: "GET_REMOTE_USERS_F",

    SCREEN_SHARE_R: "SCREEN_SHARE_R",
    SCREEN_SHARE_S: "SCREEN_SHARE_S",
    SCREEN_SHARE_F: "SCREEN_SHARE_F",

    GET_SCREEN_TRACK_R: "GET_SCREEN_TRACK_R",
    GET_SCREEN_TRACK_S: "GET_SCREEN_TRACK_S",
    GET_SCREEN_TRACK_F: "GET_SCREEN_TRACK_F",

    STOP_SCREEN_SHARE_R: "STOP_SCREEN_SHARE_R",
    STOP_SCREEN_SHARE_S: "STOP_SCREEN_SHARE_S",
    STOP_SCREEN_SHARE_F: "STOP_SCREEN_SHARE_F",

    REJECT_REQUEST_R: "REJECT_REQUEST_R",
    REJECT_REQUEST_S: "REJECT_REQUEST_S",
    REJECT_REQUEST_F: "REJECT_REQUEST_F",

    SAVE_CALL_HISTORY_R: "SAVE_CALL_HISTORY_R",
    SAVE_CALL_HISTORY_S: "SAVE_CALL_HISTORY_S",
    SAVE_CALL_HISTORY_F: "SAVE_CALL_HISTORY_F",

    GET_CALL_HISTORY_R: "GET_CALL_HISTORY_R",
    GET_CALL_HISTORY_S: "GET_CALL_HISTORY_S",
    GET_CALL_HISTORY_F: "GET_CALL_HISTORY_F",

    DELETE_VIDEOCALL_R: "DELETE_VIDEOCALL_R",
    DELETE_VIDEOCALL_S: "DELETE_VIDEOCALL_S",
    DELETE_VIDEOCALL_F: "DELETE_VIDEOCALL_F",

    UPDATE_VIDEOCALL_R: "UPDATE_VIDEOCALL_R",
    UPDATE_VIDEOCALL_S: "UPDATE_VIDEOCALL_R",
    UPDATE_VIDEOCALL_F: "UPDATE_VIDEOCALL_F",

    UPDATE_CALLTYPE_R: "UPDATE_CALLTYPE_R",
    UPDATE_CALLTYPE_S: "UPDATE_CALLTYPE_S",
    UPDATE_CALLTYPE_F: "UPDATE_CALLTYPE_F",

    NOTIFY_USER_R: "NOTIFY_USER_R",
    NOTIFY_USER_S: "NOTIFY_USER_S",
    NOTIFY_USER_F: "NOTIFY_USER_F",

    CREATE_CHANNEL_R: "CREATE_CHANNEL_R",
    CREATE_CHANNEL_S: "CREATE_CHANNEL_S",
    CREATE_CHANNEL_F: "CREATE_CHANNEL_F",

    GET_CHANNEL_R: "GET_CHANNEL_R",
    GET_CHANNEL_S: "GET_CHANNEL_S",
    GET_CHANNEL_F: "GET_CHANNEL_F",

    REJECT_CALL_S: 'REJECT_CALL_S',
    REJECT_CALL_F: 'REJECT_CALL_F',
    REJECT_CALL_R: "REJECT_CALL_R",

    CALL_ENDED_R : "CALL_ENDED_R",
    CALL_ENDED_S : "CALL_ENDED_S",
    CALL_ENDED_F : "CALL_ENDED_F",

    UPDATE_CHANNEL_STATUS: "UPDATE_CHANNEL_STATUS",

    CALL_INITIALIZE_R: "CALL_INITIALIZE_R",
    CALL_INITIALIZE_S: "CALL_INITIALIZE_S",
    CALL_INITIALIZE_F: "CALL_INITIALIZE_F",

    CALL_END_R: ' CALL_END_R',
    CALL_END_S: ' CALL_END_S',
    CALL_END_F: ' CALL_END_F',

    MUTE_USER_S: 'MUTE_USER_S',

    REMOTEUSERS_S :'REMOTEUSERS_S',
    REMOTEUSERS_F :"REMOTEUSERS_F",
    REMOTEUSERS_R : 'REMOTEUSERS_R',


    GET_REMOTEUSERS_R : 'GET_REMOTEUSERS_R',
    GET_REMOTEUSERS_S : 'GET_REMOTEUSERS_S',
    GET_REMOTEUSERS_F : 'GET_REMOTEUSERS_F',

    CREATE_FLOZY_ROOM_R : 'CREATE_FLOZY_ROOM_R',
    CREATE_FLOZY_ROOM_S : "CREATE_FLOZY_ROOM_S",
    CREATE_FLOZY_ROOM_F : "CREATE_FLOZY_ROOM_F",

    DELETE_FLOZY_ROOM_R: "DELETE_FLOZY_ROOM_R",
    DELETE_FLOZY_ROOM_S: "DELETE_FLOZY_ROOM_S",
    DELETE_FLOZY_ROOM_F: "DELETE_FLOZY_ROOM_F",
    
    CHANNEL_STATUS_S: "CHANNEL_STATUS_S",
    CHANNEL_STATUS_R: "CHANNEL_STATUS_R",
    CHANNEL_STATUS_F: "CHANNEL_STATUS_F",

    GET_RECORDING_ACQUIRE_S:"GET_RECORDING_ACQUIRE_S",
    GET_RECORDING_ACQUIRE_R:"GET_RECORDING_ACQUIRE_R",
    GET_RECORDING_ACQUIRE_F:"GET_RECORDING_ACQUIRE_F",

    GET_RECORD_START_S:"GET_RECORD_START_S",
    GET_RECORD_START_R:"GET_RECORD_START_R",
    GET_RECORD_START_F:"GET_RECORD_START_F",

    GET_RECORD_STOP_S:"GET_RECORD_STOP_S",
    GET_RECORD_STOP_R:"GET_RECORD_STOP_R",
    GET_RECORD_STOP_F:"GET_RECORD_STOP_F",

    RECORDING_STATUS_UPDATE:"RECORD_STATUS_UPDATE",
    ADD_PUBLIC_USER_S: "ADD_PUBLIC_USER_S",
    ADD_PUBLIC_USER_R: "ADD_PUBLIC_USER_R",
    ADD_PUBLIC_USER_F: "ADD_PUBLIC_USER_F",

    DELETE_PUBLIC_USER_R :"DELETE_PUBLIC_USER_R",
    DELETE_PUBLIC_USER_S :"DELETE_PUBLIC_USER_S",
    DELETE_PUBLIC_USER_F :"DELETE_PUBLIC_USER_F",
    
    CHECK_EXISTING_USER_R :"CHECK_EXISTING_USER_R",
    CHECK_EXISTING_USER_S :"CHECK_EXISTING_USER_S",
    CHECK_EXISTING_USER_F :"CHECK_EXISTING_USER_F" ,
    RECORDING_STOPPED_S :"RECORDING_STOPPED_S",
    SET_PUBLIC_USER_ID: "SET_PUBLIC_USER_ID",

    SET_RECORDER_USER_ID: "SET_RECORDER_USER_ID",

    CHECK_FLOZY_ROOM_R: "CHECK_FLOZY_ROOM_R",
    CHECK_FLOZY_ROOM_S: "CHECK_FLOZY_ROOM_S",
    CHECK_FLOZY_ROOM_F: "CHECK_FLOZY_ROOM_F",

    ACTIVE_CHANNEL_S:"ACTIVE_CHANNEL_S"
};
