import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox as Core, withStyles } from '@material-ui/core';

import styles from './styles'


 


/**
 * Checkbox Component
 * @param {*} props
 */

function CheckboxComponent({ id, classes, className, color, name, label, disabled, checked, labelPosition, handleChange, ...rest }) {
    // 
    // const extraProps = {}
    // if(icon) extraProps.icon = <CheckIcon />
    // if(checkedIcon) extraProps.checkedIcon = <CheckSelectedIcon />
    const customCheckBox = {
        padding: 6
    }

    
    return (
        <FormControlLabel
            control={
                <Core
                    // {...extraProps}
                    id={id}
                    style={customCheckBox}
                    name={name}
                    color={color}
                    className={className}
                    disabled={disabled}
                    checked={checked}
                    onChange={(e) => { handleChange(e) }}
                    {...rest}
                />
            }
            label={label}
            labelPlacement={labelPosition}
            {...rest}
        />
    )
}

// default props
CheckboxComponent.defaultProps = {
    id: "",
    name: "",
    classes: '',
    // icon: false,
    // checkedIcon: false,
    label: "",
    labelPosition : "End",
    margin: 'none',
    className: "mr-1",
    color: "primary",
    disabled: false,
    checked: false,
    handleChange: () => { }
};

// prop types
CheckboxComponent.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelPosition : PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool,
    icon: PropTypes.any,
    checkedIcon: PropTypes.any,
    handleChange: PropTypes.func,
};

export const CheckBox = withStyles(styles)(CheckboxComponent)