import { baseAction } from "./baseAction";
import { alertConstant, loaderConstant, agencyLoginConstant,authConstant } from "../constants";
import { agencyLoginService } from "../services";

const { request, success, failure } = baseAction;

// All login based actions
export const agencyLoginAction = {
  agencySignup,
  getEmailAgencies,
  agencySignupProcess,
  getPlanDetails,
  getPlanAddonDetails,
  getReferralLinkData,
  removeEmailAgency
};

/**
 * agency Signup
 */
function agencySignup(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(agencyLoginConstant.AGENCY_SIGNUP_R, params));
    agencyLoginService.agencySignup(params).then(
      (data) => {
        if(data){
          localStorage.setItem("newID", data && data.data && data.data.id ? data.data.id : 0);
          localStorage.setItem("newID1", data && data.data && data.data.agencyId ? data.data.agencyId : 0)
        }
        dispatch(success(agencyLoginConstant.AGENCY_SIGNUP_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));
        dispatch(success(authConstant.AGENCY_LOGIN_SIGNUP_S,params))
        callBack(data)
      },
      (error) => {
        dispatch(failure(agencyLoginConstant.AGENCY_SIGNUP_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * get Email Agencies
 */
function getEmailAgencies(params, callBack = () => { }) {
  return (dispatch) => {
    // dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(agencyLoginConstant.EMAIL_AGENCY_GET_R, params));
    agencyLoginService.getEmailAgencies(params).then(
      (data) => {
        dispatch(success(agencyLoginConstant.EMAIL_AGENCY_GET_S, data));
        // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        // dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data)
      },
      (error) => {
        dispatch(failure(agencyLoginConstant.EMAIL_AGENCY_GET_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        // dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * agency Signup Process
 */
function agencySignupProcess(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(agencyLoginConstant.AGENCY_SIGNUP_PROCESS_R, params));
 
    agencyLoginService.agencySignupProcess(params).then(
      (data) => {
        dispatch(success(agencyLoginConstant.AGENCY_SIGNUP_PROCESS_S, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(loaderConstant.LOADING_FALSE));

        callBack(data)
      },
      (error) => {
        dispatch(failure(agencyLoginConstant.AGENCY_SIGNUP_PROCESS_F, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * get pricing plan details
 */
function getPlanDetails(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(agencyLoginConstant.GET_PLAN_DATA_R));
    agencyLoginService.getPlanDetails(params).then((data) => {
      dispatch(request(agencyLoginConstant.GET_PLAN_DATA_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data);
    },
      (error) => {
        dispatch(request(agencyLoginConstant.GET_PLAN_DATA_F));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

function getPlanAddonDetails(params){
  return (dispatch) => {
    agencyLoginService.getPlanAddonDetails(params).then((data) => {
      dispatch(request(agencyLoginConstant.GET_PLAN_DATA_FLAG, data));
    },
      (error) => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

// get referral link data
function getReferralLinkData(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(agencyLoginConstant.GET_REFERRAL_DATA_R));
    agencyLoginService.getReferralLinkData(params).then((data) => {
      dispatch(request(agencyLoginConstant.GET_REFERRAL_DATA_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data);
    },
      (error) => {
        dispatch(request(agencyLoginConstant.GET_REFERRAL_DATA_F));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}

// remove email agency 
function removeEmailAgency() {
  return (dispatch) => {
    dispatch(request(agencyLoginConstant.REMOVE_EMAIL_AGENCY_S));
  };
}