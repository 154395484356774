import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, withStyles, Avatar } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import { CameraAttachments, GifAttachments, VideoAttachments, AudioAttachments } from "../../assets/svg/chatIcons";
import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment-duration-format';
import { formatFileSize } from '../../utils/chatHelper';
//Import Component
import { Typography } from '..'

// Imports Styles
import styles from "./styles";
import ChatEditor from '../FlozyEditor/ChatEditor/chatEditor';

const InputReplyComponent = (props) => {
    const { classes, onClose, messageObj } = props;
    const { id: userId } = useSelector((s) => s.auth.loginUser)
    let fileName = ''
    let fileType = null

    function formatDuration(duration) {
        const time = Math.round(duration / 1000)
        return moment.duration(time, 'seconds').format('mm:ss', { trim: false });
    }
    let duration;
    let fileUrl = null; let fileDoc = null; let fileIcon = null;
    let fileObj;
    if (messageObj && messageObj.message_file) {
        fileObj = JSON.parse(messageObj.message_file);
        duration = fileObj?.duration
        if (messageObj.message_category === 1) {
            fileUrl = fileObj && fileObj.thumb ? fileObj.thumb : fileObj.url;
            fileName = fileObj.name
            if (fileObj.type.includes("video")) {
                fileType = "Video"
                fileUrl = fileObj.thumb ? fileObj.thumb : fileObj
                fileIcon = <VideoAttachments />
            } else if (fileObj.type.includes("gif")) {
                fileType = "GIF"
                fileIcon = <GifAttachments />
            } else {
                fileType = "Photo"
                fileIcon = <CameraAttachments />
            }
        }
        else if (messageObj.message_category === 2) {
            fileDoc = fileObj && fileObj.name ? fileObj.name : null;
            fileIcon = fileObj && fileObj.type ? fileObj.type.split("/")[1] : "docs";
            fileName = fileObj.name
        } else if (messageObj.message_category === 3) {
            fileUrl = fileObj && fileObj[0].url ? fileObj[0].url : null;
        }
        else if (messageObj.message_category === 4) {
            fileDoc = fileObj && fileObj.name ? fileObj.name : null;
            fileName = fileObj && fileObj.name ? "voice note" : "";
            fileIcon = fileObj && fileObj.name ? "voice note" : "";
        }
    }
    let fileExtension = fileObj?.type?.includes("video") ? 'docvideo' : fileObj?.type?.includes("image") ? 'docimg' : (fileObj?.name && fileObj?.type) ? fileObj?.name?.split('.').pop() : messageObj.message_category === 3 ? 'docimg' : ''
    const [imageSrc] = useState(`./icons/${fileExtension}.svg`);

    return (
        <>
            {messageObj ? <Grid className={classes.replyTile} container item alignItems="center">
                <Grid className={`${classes.replyTileLeft} ${messageObj.message_file ? classes.replyTileFile : ''} disable-user-select`}>
                    {messageObj.message_file ?
                        <>
                            <Grid className="dflex flexColumn justifyCenter">
                                <Typography variant="subtitle2" className="taskstitle fs-12 fw-500 disable-user-select">{messageObj?.user_id == userId ? "You" : messageObj.name ? messageObj.name : `${messageObj.first_name ? messageObj.first_name : ''} ${messageObj.last_name ? messageObj.last_name : ''}`}</Typography>
                                <div className="dflex">
                                    <img src={imageSrc} width={'20px'} height={'20px'} alt="loading" />
                                    {messageObj.message_text && messageObj.message_text !== '\n' ?
                                        <ChatEditor
                                            className={'chatEditorRoot'}
                                            id={messageObj?.id || 1}
                                            content_status={'loaded'}
                                            content={messageObj?.message_raw_text || messageObj?.message_text || ""}
                                            readOnly={true}
                                            elipis={true}
                                        />
                                        // <Typography variant="body2" className="tasksText threeLineEllip fs-12"> 
                                        // {messageObj?.message_text?messageObj?.message_text:""}</Typography>
                                        :
                                        <span className="dflex alignCenter"><Typography variant="body2" color="textSecondary" className="tasksText oneLineEllip fontSize10 dflex alignCenter pl5"> {messageObj.message_category == 2 && fileObj?.name}  {messageObj.message_category == 3 && 'Group images'} {messageObj.message_category === 4 && 'Audio'}  {(fileObj?.type?.includes("video") && fileObj?.size) && formatFileSize(fileObj?.size)}  {fileType} </Typography></span>}
                                </div >
                            </Grid>
                            {fileUrl ? <img alt={"imageone"} src={fileUrl} className={classes.replyThumb} /> : null}
                        </>
                        :
                        <>
                            <Typography variant="subtitle2" className="taskstitle fs-12 fw-500 disable-user-select">{messageObj.name ? messageObj.name : ""}</Typography>
                            {messageObj?.message_raw_text ?
                                <ChatEditor
                                    className={'chatEditorRoot'}
                                    id={messageObj?.id || 1}
                                    content_status={'loaded'}
                                    content={messageObj?.message_raw_text || messageObj?.message_text || ""}
                                    readOnly={true}
                                    elipis={true}
                                />
                                :
                                <Typography variant="body2" className="tasksText threeLineEllip fs-12">{messageObj.message_text ? messageObj.message_text : ""}</Typography>
                            }
                        </>
                    }
                </Grid>
                <Grid className={classes.replyTileRight}>
                    <IconButton onClick={() => onClose()} className={`p-0 ${classes.replyCloseBtn}`}><CloseIcon className={classes.closeIcon} /></IconButton>
                </Grid>
            </Grid> : null}
        </>
    );
};

// Default props
InputReplyComponent.defaultProps = {
    classes: "",
    onClose: () => { },
    messageObj: { id: "" }
};

// Prop types
InputReplyComponent.propTypes = {
    classes: PropTypes.object,
    onClose: PropTypes.func,
    messageObj: PropTypes.any,
};

/** Export Component */
export const ChatInputReplyComponent = withStyles(styles)(InputReplyComponent);
