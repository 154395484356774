const styles = (theme) => ({
    roots: {
      height: 36,
      '& .MuiOutlinedInput-root': {
        height: 36,
        minHeight: 36
      }
    },
    colorInputPicker:{
      // padding: "10px"
      '& .MuiOutlinedInput-root': {
        paddingLeft: 14
      }
    }
  });
  
  export default styles;
  