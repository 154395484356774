/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Grid, withStyles, Dialog, DialogContent, Switch,useMediaQuery } from "@material-ui/core";

// Imports Styles
import styles from "./style";
import { Button } from "../Button";
import { Typography } from "../Typography";

function ToggleConfirmationComponent(props) {

    const { classes, handleMuteClick, notification } = props;
    const isMobile = useMediaQuery('(max-width: 899px)')

    // Notification Status
    const [notifciationAlert, setNotifciationAlert] = React.useState(false);
    // const [notifciation, setNotifciation] = React.useState(false);

    const handleDialog = () => {
        setNotifciationAlert(!notifciationAlert);
    };

    const handleNotification = (event) => {
        // setNotifciation(!notifciation);
        setNotifciationAlert(!notifciationAlert);
        handleMuteClick()
    };
    
    return (
        <>
            <Switch className={classes.toggleButton} onChange={handleDialog} size="small" checked={notification}></Switch>
            <Dialog
            onClose={handleDialog}
            fullWidth={true}
            maxWidth={"xs"}
                open={notifciationAlert}
            >
                <DialogContent>
                    <Typography variant="h6" className={`${classes.welcomeBanTxt} welcomeBanTxt dflex justifyCenter`}>{notification ? "Are you sure you want to unmute this group ?" : "Are you sure you want to mute this group ?"}</Typography>
                    <Grid container spacing={1} align="right" className='p-0 pb-1 pt-2 justifyCenter'>
                        <Grid item xs={isMobile ? (notification ? 3 : 3) : 3}>
                        <Button variant="contained" fullWidth color={"secondary"} size="small" onClick={handleDialog} className="mr-1">Close</Button>
                        </Grid>
                        <Grid item xs={isMobile ? (notification ? 3 : 3) : 3}>
                        <Button  className="blueBtn" variant="contained" fullWidth  color={"primary"} size="small" onClick={handleNotification} >{!notification ? "Yes" : "Unmute"}</Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

// default props
ToggleConfirmationComponent.defaultProps = {
    classes: {},
    // type: "avatar",
    value: null,
    title: "",
};

// prop types
ToggleConfirmationComponent.propTypes = {
    classes: PropTypes.object
};

// export component
export const ToggleConfirmation = withStyles(styles)(ToggleConfirmationComponent);
