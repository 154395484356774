const styles = (theme) => ({
    userModelPopper: {
        borderRadius: 16,
        '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '360px',
            paddingBottom: '15px',
            overflow: 'hidden'
        },
        '& .addUsersHeader': {
            padding: '20px 20px 0px',
            '& .closeBtn': {
                borderRadius: '4px',
                width: '26px',
                height: '26px',
                background: theme.palette.containers.bg7,
                padding: '7px'
            }
        },
        '& .selectedUserDel': {
            background: '#E7E7E7',
            borderRadius: '30px',
            zIndex: 999,
            position: 'absolute',
            height: '18px',
            width: '18px',
            right: '-8px',
            top: '-3px'
        },
        '& .userImgClose': {
            width: '40px',
            height: '40px',
            position: 'relative',
            margin: "auto"
        },
        '& .selectedUsers': {
            display: 'flex',
            overflow: 'auto',
            maxWidth: 'calc(100% - 40px)',
            margin: 'auto',
            paddingBottom: '8px',
            justifyContent: 'flex-start',
            gap: "10px",
            marginTop: "30px"
        },
        '& .usersList': {
            overflowY: 'auto',
            maxHeight: '400px',
            marginTop: '25px',
            '&::-webkit-scrollbar-thumb': {
                background: theme.palette.type === "dark" ? '#3f3f3f38 !important' : '#bdc0d240'
              },
              '&::-webkit-scrollbar-track': {
                visibility: theme.palette.type === "dark" ? 'hidden' : 'visible'
              },
        }
    },
    fontcolor:{
      color:'#0f172A !important'
    },
    timeZonePopup: {
        '& .MuiPaper-root': {
            maxWidth: '385px'
        }
    },
    tzCont: {
        position: 'relative',
        display: "flex",
        alignItems: "center",
        "& .noItemIcon": {
            height: "32px",
            border: "1px dashed #64748B",
            width: "32px",
            borderRadius: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "12px",
            flexShrink: '0'
        },
        '& .ClockIcon2, & .TimeZoneIcon': {
            position: 'absolute'
        },
        '& .MuiSelect-select': {
            paddingLeft: '25px !important'
        },
        '& .MuiOutlinedInput-root': {
            background: 'transparent'
        }
    },
    marginTop15 :{
        marginTop: 15
    },
    flxCls: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between'
    }


});

export default styles;