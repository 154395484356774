/* eslint-disable dot-notation */
import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Avatar, Grid, IconButton, Select, Switch, FormControl, MenuItem } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';

// Imports Styles
import style from "./style";
import { TextField, TitleAvatar, Typography } from "../";
import {  TrashCan, UserAccepted, UserPending, CircleClose, CircleTick } from "../../assets/svg";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { wsmAction } from "../../redux/actions";

function UserListing(props) {

  const { classes,
          users,
          fieldMap, 
          showLabel, 
          showSearch, 
          className, 
          setSelectedUsers, 
          selectedUsers, 
          resource, 
          agency_id, 
          manage, 
          addUserPopup, 
          ignore_users,
          isFlowsSharedUser,
          toggleAndRoleHandler } = props;
  let [suggestions, setsuggestions] = useState([])
  const [deleteClicked, setDeleteClicked] = useState('')

  const dispatch= useDispatch()

  const user_id = useSelector(s => s.auth.loginUser.id)


  useEffect(() => {
    if (!isFlowsSharedUser){
      setSelectedUsers(resource?.sharedUsers)
    }
  }, [])

  const deleteUser = (userId,invitation_id) => {
    let selectUsers = selectedUsers
    let existingUsersAfterDeletion = selectUsers.filter(ele => ele.id !== userId)
    setSelectedUsers(existingUsersAfterDeletion)
    setDeleteClicked('')
    dispatch(wsmAction.deleteUsers(userId,invitation_id))
    let deltedArray=users.filter(ele=>ele.id==userId)?.map(ele => {
      return {
        "id": ele.id,
        "value": ele.id,
        "label": ele?.username || ele?.name || ele?.first_name ,
        "helperText": ele?.username || ele?.name || ele?.first_name,
        "image": null
      }
    })
    setsuggestions([...suggestions,...deltedArray])
  }
  
    const getSharedMembers = () => {
        return [...(selectedUsers || [])].map((user) => (
          <Grid className="mb15" style={classes.ShareListItem} key={`user_${user.value}`}>
            <Grid container alignItems='center' wrap="nowrap">
              <Grid item className="dflex alignCenter flexOne">
                {
                  user && ((user.hasOwnProperty("avatar_filename") && user.avatar_filename) || (user.hasOwnProperty("avatar") && user.avatar)) ?
                    <TitleAvatar type={'avatar'} userId={user_id} recipientId={user.id} value={user && user.avatar_filename ? user.avatar_filename : user.avatar ? user.avatar : "/static/images/avatar/1.jpg"} /> :
                    <Avatar src="/static/images/avatar/1.jpg" />
                }
                <Grid className='pl15' key={`user_${user.id}`}>
                  <Typography variant="body1" className="fw-500 capitalize" noWrap>{ (user.username || user.name || user.label) }</Typography>
                </Grid>
              </Grid>

          {deleteClicked == user.id ?
            <Grid className={classes.deleteConfirm}>
              <Typography variant="body1" className="uppercase alereText mr5">continue to delete</Typography>
              <IconButton className="p0 mr5" onClick={() => { setDeleteClicked('') }}><CircleClose /></IconButton>
              <IconButton className="p0 " onClick={() => deleteUser(user.id,user?.invitation_id)}><CircleTick /></IconButton>
            </Grid>
            :
            <Grid className="dflex alignCenter flexShrink0">
              <Grid className="mr-2" >
                    {user.status ? <UserAccepted /> : <UserPending />}
              </Grid>
               <IconButton style={{visibility:resource.to_user_id===user.id?'hidden':''}} onClick={() => { setDeleteClicked(user.id) }} className={classes.deleteIconWrapper}><TrashCan /></IconButton>
            </Grid>
          }
        </Grid>
      </Grid>
    ));
  }
  const renderRecords = () => {

    return (
      resource?.sharedUsers?.map((user, index) => {
        return (<TableRow key={`row_${index}_${user.id}`} >
        	{
          	fieldMap.map((field, field_idx) => (
            	<TableCell key={`cell_${index}_${field_idx}_${user.id}`}>
              	<Grid container alignItems="center" wrap="nowrap">
	
                	{
                  	field.column_type === 'avatar_title' &&
                  	<>
                    	<TitleAvatar type={'avatar'} userId={user_id} recipientId={user.id} value={user[`${field.avatar_key}`] ? user[`${field.avatar_key}`] : ""} />
                      <Typography className="pl15 fw-500 capitalize" variant="body1">{user[`${field.column_key}`] ? user[`${field.column_key}`] : user[`${field.alternate_key}`] ? user[`${field.alternate_key}`] : user[`${field.alternate_key1 }`]}</Typography>
                  	</>
                	}
                  
                	{field.column_type === "switch" &&
                  	<Grid xs={5} sm={3} className={classes.selectBox}>
                    	<FormControl >
                      	<Select
                          value={(user[`${field.alternate_key}`] ? user[`${field.alternate_key}`] : 0 )}
                          onChange={(e) => toggleAndRoleHandler(e, user)}
                        	name={user[`${field.column_key}`]}
                          disabled={user[`${field.alternate_key}`] === 0 ? true : false }
                      	>
                        	<MenuItem value={3}>Admin</MenuItem>
                        	<MenuItem value={1}>View Only</MenuItem>
                          <MenuItem value={0}>No Access</MenuItem>
                      	</Select>
                    	</FormControl>
                  	</Grid>
                	}
                	{
                  	field.column_type === 'toggle' &&
                  	<Grid xs={12} className={classes.toggleContainer}>
                        <Switch size="small" onChange={(e) => toggleAndRoleHandler(e)} name={user[`${field.column_key}`]} checked={user[`${field.alternate_key}`] === 0 ? false : true} />
                  	</Grid>
                	}
                  
                  {/* {
                    field.column_type === 'more_option' &&
                    <Grid xs={12} className={classes.toggleContainer}>
                        <HorizontalMoreIcon />
                    </Grid>
                  } */}
              	</Grid>
            	</TableCell>
          	))
        	}
      	</TableRow>)
      })
    )
  }

  useEffect(() => {
    suggestions = users.filter(usr => {
      const targetIndex = ignore_users.findIndex(job_usr => job_usr === usr.id);
      return targetIndex === -1;
    }).map(ele => {
      return {
        "id": ele.id,
        "value": ele.id,
        "label": (ele?.username || ele?.name || ele?.first_name),
        "helperText": ele?.username || ele?.name || ele?.first_name,
        "image": null
      }
    })
    let sharedUserIds=[]
    resource?.sharedUsers?.map(ele=>{
      sharedUserIds.push(ele.id)
    })
    suggestions=suggestions.filter(e=>!sharedUserIds.includes(e.id))
    setsuggestions(suggestions)
  }, [])


  return (
    <Grid xs={12} className={classes.userListSearch}>{
      showSearch && resource?.from_agency_id !== agency_id ?
        <Grid item xs={12} className="searchContainer">
          {(addUserPopup || manage == 'New Users') && <Autocomplete
            multiple
            id={`ac-id`}
            name={`dfg`}
            variant={"outlined"}
            renderTags={() => null}
            // disabled={disabled}
            options={suggestions.filter(e => e?.id && e?.id !== user_id)}
            fullWidth={true}
            className={className}
            hiddenLabel="true"
            classes={{
              paper: {
                minWidth: 'fit-content',
                right: 0
              }
            }}
            disableClearable={true}
            getOptionLabel={option => option.label || option.username || ''}
            renderOption={option => {
              return (
                <Fragment>
                  <Grid style={{
                    display: 'flex',
                    width: '100%',
                    background: "transparent",
                    boxShadow: "none !important",
                    alignItems: "left",
                    justifyContent: "left",

                    "& .MuiCardContent-root": {
                      padding: "4px !important",
                      paddingLeft: "10px !important",
                    }
                  }}>
                    {option && option.image ?
                      <Avatar alt={option.label || option.username} src={option && option.image ? option.image : ""} />
                      :
                      option && option.hasOwnProperty("avatar_filename") && option.avatar_filename ? <Avatar alt={option.label || option.username} src={option && option.avatar_filename ? option.avatar_filename : "/static/images/avatar/1.jpg"} />
                        :
                        <Avatar alt={option.label || option.username} src={`/static/images/avatar/1.jpg`} />
                    }

                    <Grid className="ml-1" style={{ flex: '1 0 auto' }}>
                      <Typography variant="body1" className="fw-500">
                        {option.label || option.username}
                      </Typography>
                      <Typography variant="subtitle1" className="fs-12" color="textSecondary">
                        {option.helperText}
                      </Typography>
                    </Grid>
                  </Grid>
                </Fragment>
              );
            }
            }
            filterSelectedOptions={true}
            // value={value ? value : []}
            onChange={(event, newValue) => {
              let sharedUser = []
                        if('sharedUsers' in resource){
                           sharedUser=resource.sharedUsers
                        }
                        
                        let uniqueArray=selectedUsers?[...selectedUsers]:[]
                        uniqueArray.push(newValue[newValue?.length-1])
                        setSelectedUsers([...uniqueArray]) }}
                        renderInput={(params) => (
                        <>
                       <TextField
                        {...params}
                        variant="outlined"
                       />
                   </>
               )}
            />
          }
          <Grid item xs={12} className={`${classes.sharePeopleContainer} mt-2`}>
            {getSharedMembers()}
          </Grid>

        </Grid>
        :
        <TableContainer className={`${classes.tableBorder_None}`}>
          <Table aria-label="simple table">
            <TableBody>
              {
                showLabel &&
                <TableRow >
                  {fieldMap && fieldMap.length > 0 && fieldMap.map((itm, indx) => {
                    return (
                      <TableCell key={indx}>
                        <Grid container alignItems="center" wrap="nowrap">
                          <Typography className="pl15 fw-500 capitalize" variant="body1">{itm['column_label']}</Typography>
                        </Grid>
                      </TableCell>
                    )
                  })}
                </TableRow>
              }
              {renderRecords()}
            </TableBody>
          </Table>
        </TableContainer>}
    </Grid>
  );
}

// default props
UserListing.defaultProps = {
  classes: {},
  users: [],
  showSearch: false,
  showLabel: false,
  handleToggle: () => { },
  ignore_users: []
};

// prop types
UserListing.propTypes = {
  classes: PropTypes.object,
  users: PropTypes.array,
  showLabel: PropTypes.bool,
  showSearch: PropTypes.bool,
  handleToggle: PropTypes.func,
  ignore_users: PropTypes.array
};

export const UsersListing = withStyles(style)(UserListing);
