import { useEffect, useState } from "react";

const useVisibility = () => {
  const [visible, setVisible] = useState(document.visibilityState);
  const handleChange = () => {
    setVisible(document.visibilityState);
  };
  useEffect(() => {
    document.addEventListener("visibilitychange", handleChange);
    return () => {
      document.removeEventListener("visibilitychange", handleChange);
    };
  });

  return [visible];
};

export default useVisibility;
