import React from 'react';
import StyleButton from './StyleButton';

function BlockStyleControls(props) {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const BLOCK_TYPES = [
    { label: 'H1', style: 'header-one', icon: '', tooltip:"Heading 1" },
    { label: 'H2', style: 'header-two', icon: '', tooltip:"Heading 2" },
    { label: 'Blockquote', style: 'blockquote', icon: 'icon-format_quote', tooltip:"Blockquote" },
    {
      label: 'UL',
      style: 'unordered-list-item',
      icon: 'icon-format_list_bulleted',
      tooltip:"Unordered list"
    },
    {
      label: 'OL',
      style: 'ordered-list-item',
      icon: 'icon-format_list_numbered',
      tooltip:"Ordered list"
    },
    { label: 'Code Block', style: 'code-block', icon: 'icon-code', tooltip:"Code Block" },
  ];

  return (
    <div className="editor-controls">
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          icon={type.icon}
          onToggle={props.onToggle}
          style={type.style}
          tooltip={type.tooltip}
        />
      ))}
    </div>
  );
}

export default BlockStyleControls;
