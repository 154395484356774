import React from "react";
import PropTypes from "prop-types";
import { Link as Core } from "@material-ui/core";

/**
 * Link Component
 * @param {*} props
 */
function LinkComponent({ component, variant, classes, color, href, children, className, onClick, ...rest}) {

  return (
    <Core
      variant={variant}
      component={component}
      color={color}
      classes={classes}
      href={href}
      className={className}
      // onClick={onClick}
    >
      {children}
    </Core>
  );
}

// default props
LinkComponent.defaultProps = {
  classes: {},
  children: "",
  color: "inherit",
  href: "#",
  component: "a",
  variant: "h6",
  className: "",
  onClick: () => { }
};

// prop types
LinkComponent.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  component: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.object,
  className: PropTypes.string
};

/** Export Component */
export const Link = LinkComponent;
