import { chatv2Constant, profileConstant } from "../constants";
import { setChatReadStatus } from "../helpers/chatv2Helper";

const types = {
    projects: {},
    clients: {},
    teams: {}
}

const initialState = {
    listLoadingStatus: 'end',
    mobileChatScreenStatus: false,
    allUsers: [],
    agency_user: [],
    onlineUsers: {},
    typingUsers: {},
    mutedRooms: {
        projects: [],
        clients: [],
        teams: []
    },
    star_messages: {
        projects: {},
        clients: {},
        teams: {}
    },
    progress_bar:{
        projects:{},
        clients:{},
        teams:{}
    },

    notifications: [],
    selected_channel: {},
    channel: {
        projects: [],
        clients: [],
        teams: []
    },
    projects: {},
    clients: {},
    teams: {},
    links: {
        ...types
    },
    media: {
        ...types
    },
    docs: {
        ...types
    },
    search: {
        ...types
    },
    isUploading: false,
    newChat: [],
    unsend_messages: {},
    selected_id: '',
    unreadRooms: {
        projects: [],
        clients: [],
        teams: []
    },
    replyOpen: false,
    dataReply: {},
    forwardBoxOpen: false,
    data: {},
    chatView: { projects: 'bubblewView', clients: 'bubblewView', teams: 'bubblewView' },
    bulkReadUpdate: {
        project: '',
        client: '',
        team: ''
    },
    scrollBtn: false,
    editChatData: null,
    selected_messsages: { openSelect: false, selectedId: [], selectedMsgs: [] },
    reloadChatTabs: null
}

const chatv2Reducer = (state = initialState, action) => {
    switch (action.type) {

        case chatv2Constant.GET_CHATV2_PROJECTS_R:
            return {
                ...state,
                listLoadingStatus: action?.params.forward ? 'data' : 'start',
                channel: {
                    ...state.channel,
                    // projects: []
                }
            }

        case chatv2Constant.GET_ALL_CHATV2_USERS_R:
            return {
                ...state,
                // ...initialState
            }
        case chatv2Constant.GET_CHATV2_PROJECTS_S:
            const currentProjectChat = state.projects
            let filteredProjectList = currentProjectChat
            if(Object.keys(currentProjectChat).length > 7) {
                const updatedList = action.data.data.slice(0, 7).map(l => l?.id)
                filteredProjectList = Object.keys(currentProjectChat).filter(key => updatedList.includes(Number(key)))
                filteredProjectList = filteredProjectList.reduce((res, key) => {
                    res[key] = currentProjectChat[key];
                    return res;
                }, {});
            }
            return {
                ...state,
                listLoadingStatus: action.data.data?.length > 0 || action.data.forward ? 'data' : 'nodata',
                channel: {
                    ...state.channel,
                    projects: action.data.data
                },
                projects: filteredProjectList || state.projects
            }
        case chatv2Constant.GET_CHATV2_PROJECTS_F:
            return {
                ...state,
                listLoadingStatus: 'end',
                channel: {
                    ...state.channel,
                    // projects: []
                }
            }
        
        case chatv2Constant.GET_CHATV2_CLIENTS_R:
            return {
                ...state,
                listLoadingStatus: action?.params.forward ? 'data' : 'start',
                channel: {
                    ...state.channel,
                    // clients: []
                }
            }
        case chatv2Constant.GET_CHATV2_CLIENTS_S:
            const currentClientChat = state.clients
            let filteredClientList = currentClientChat
            if(Object.keys(currentClientChat).length > 7) {
                const updatedList = action.data.data.slice(0, 7).map(l => l?.id)
                filteredClientList = Object.keys(currentClientChat).filter(key => updatedList.includes(Number(key)))
                filteredClientList = filteredClientList.reduce((res, key) => {
                    res[key] = currentClientChat[key];
                    return res;
                }, {});
            }
            return {
                ...state,
                listLoadingStatus: action.data.data?.length > 0 || action.data.forward ? 'data' : 'nodata',
                channel: {
                    ...state.channel,
                    clients: action.data.data
                },
                clients: filteredClientList || state.clients
            }
        case chatv2Constant.GET_CHATV2_CLIENTS_F:
            return {
                ...state,
                listLoadingStatus: 'end',
                channel: {
                    ...state.channel,
                    // clients: []
                }
            }

        case chatv2Constant.GET_CHATV2_TEAMS_R:
            return {
                ...state,
                listLoadingStatus: action?.params.forward ? 'data' : 'start',
                channel: {
                    ...state.channel,
                    // teams: []
                }
            }
        case chatv2Constant.GET_CHATV2_TEAMS_S:
            const currentTeamsChat = state.teams
            let filteredList = currentTeamsChat
            if(Object.keys(currentTeamsChat).length > 7) {
                const updatedList = action.data.data.slice(0, 7).map(l => l?.id)
                filteredList = Object.keys(currentTeamsChat).filter(key => updatedList.includes(Number(key)))
                filteredList = filteredList.reduce((res, key) => {
                    res[key] = currentTeamsChat[key];
                    return res;
                }, {});
            }
            return {
                ...state,
                listLoadingStatus: action.data.data?.length > 0 || action.data.forward ? 'data' : 'nodata',
                channel: {
                    ...state.channel,
                    teams: action.data.data
                },
                teams: filteredList || state.teams
            }
        case chatv2Constant.UPDATE_APPROVE_USERS_S:
            const type = action.data.type
            const resType = [...state.channel[type]]
            resType.forEach((res) => {
                if (action.data.resourceID === res.id) {
                    res.user = res.users.map((ele) => {
                        if (ele.id === action.data.userId) {
                            ele.user_job_status = action.data.status
                        }
                    })
                }
            })
            return {
                ...state,
                channel: {
                    ...state.channel,
                    [type]: resType
                }
            }
        case chatv2Constant.GET_CHATV2_TEAMS_F:
            return {
                ...state,
                listLoadingStatus: 'end',
                channel: {
                    ...state.channel,
                    // teams: []
                }
            }

        case chatv2Constant.GET_ONE_PROJECT_CHAT_S:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    [action.data.room_id]: {
                        ...action.data
                    }
                }
            }

            case chatv2Constant.UPDATE_PROJECT_LOGO_S:
            return {
                ...state,
                channel: {
                    ...state.channel,
                    projects: state.channel.projects.map(project =>
                        project.id === action.data.room_id
                            ? { ...project, 
                                avatar: action.data.avatar || project.avatar,
                                avatar_type: action.data.avatar_type|| project.avatar_type

                             }
                            : project
                    )
                }
            }
    

        case chatv2Constant.GET_ONE_CLIENT_CHAT_S:
            return {
                ...state,
                clients: {
                    ...state.clients,
                    [action.data.room_id]: {
                        ...action.data
                    }
                }
            }
            case chatv2Constant.UPDATE_CLIENT_LOGO_S:
            return {
                ...state,
                channel: {
                    ...state.channel,
                    clients: state.channel.clients.map(client =>
                        client.id === action.data.room_id
                            ? { ...client, avatar: action.data.avatar || client.avatar,
                                avatar_type: action.data.avatar_type|| client.avatar_type
                             }
                            : client
                    )
                }
            }
    

        case chatv2Constant.GET_ONE_TEAM_CHAT_S:
            return {
                ...state,
                teams: {
                    ...state.teams,
                    [action.data.room_id]: {
                        ...action.data
                    }
                }
            }

        case chatv2Constant.GET_SELECTED_ID:
            return {
                ...state,
                selected_id: action.data
            }
        case chatv2Constant.GET_PROGRESS_BAR_R:
            return {
                ...state,
                progress_bar :{
                    ...state?.progress_bar,
                    [action.data.type]:{
                        [action.data.unique_s3key]:action?.data?.progress
                    }
                }
            }
        
        case chatv2Constant.GET_PROGRESS_BAR_S:
            return {
                ...state,
                progress_bar:{
                    ...state?.progress_bar,
                    [action.data.type]:{
                        [action.data.unique_s3key]:0
                    }
                }       
        }

        case chatv2Constant.GET_CHAT_STAR_S:
            return {
                ...state,
                star_messages: {
                    ...state?.star_messages,
                    [action.data.type]: {
                        [action.data.room_id]: action.data.result
                    }
                }
            }

        case chatv2Constant.UPDATE_CHATV2_STARRED_MESSAGE_S:
            const { cur_type, room_id, updateProps } = action.data.params;
            const newStarMessages = { ...state?.star_messages };
            if (updateProps.star === 0) {
                newStarMessages[cur_type][room_id] = newStarMessages[cur_type][room_id].filter(
                    message => message.id !== action.data?.message?.id
                );
            } else {
                if (!newStarMessages[cur_type][room_id]) {
                    newStarMessages[cur_type][room_id] = [];
                }
                const existingIndex = newStarMessages[cur_type][room_id]?.findIndex(
                    message => message.id === action.data?.message?.id
                );
                if (existingIndex !== -1) {
                    newStarMessages[cur_type][room_id][existingIndex] = { ...newStarMessages[cur_type][room_id][existingIndex], ...updateProps?.message };
                } else {
                    newStarMessages[cur_type][room_id].push({ ...updateProps?.message });

                }
            }
            return {
                ...state,
                star_messages: newStarMessages
            };

        case chatv2Constant.GET_CHAT_FILES_S:
            return {
                ...state,
                [action.data.media_type]: {
                    [action.data.type]: {
                        ...(state[action.data.media_type][action.data.type] || {}),
                        [action.data.room_id]: action.data.result
                    }
                }
            }

        case chatv2Constant.GET_CHAT_SEARCH_S:
            return {
                ...state,
                search: {
                    [action.data.type]: {
                        [action.data.room_id]: action.data.result
                    }
                }
            }

        case chatv2Constant.SEND_NEW_MESSAGE_R:
            return {
                ...state,
                channel: {
                    ...action.params.channelData
                },
                [action.params.type]: {
                    ...state[action.params.type],
                    [action.params.room_id]: {
                        ...action.params.result
                    }
                }
            }
            
        case chatv2Constant.SEND_NEW_MESSAGE_S:
            return {
                ...state,
                [action.data.type]: {
                    ...state[action.data.type],
                    [action.data.room_id]: {
                        ...action.data.result
                    }
                }
            }
        case chatv2Constant.UPDATE_CHATV2_EMOJI_R:
            return {
                ...state,
                [action.params.cur_type]: {
                    ...state[action.params.cur_type],
                    [action.params.room_id]: {
                        ...state[action.params.cur_type][action.params.room_id],
                        chats: state[action.params.cur_type][action.params.room_id]?.chats.map((elem) => {
                            if (elem.id === action.params.message_id) {
                                let reactions = elem?.reactions && elem?.reactions !== 'null' ? JSON.parse(elem?.reactions) : {}
                                if (action.params.actionType === 'add') {
                                    reactions[action.params.emoji] = reactions[action.params.emoji] ?? [];
                                    const reactionIndex = reactions[action.params.emoji].findIndex(elem => action?.params?.sentBy && elem.user_id === action.params.sentBy) || -1
                                    if (reactionIndex === -1) {
                                        reactions[action.params.emoji].push({
                                            user_id: action.params.sentBy,
                                            avatar_filename: action.params.avatar_filename,
                                            name: action.params.name,
                                            username: action.params.username,
                                            emoji: action.params.emoji
                                        })
                                    }
                                } else {
                                    reactions[action.params.emoji] = reactions[action.params.emoji]?.filter(item => item?.user_id !== action.params.sentBy) || [];
                                    if (reactions[action.params.emoji]?.length === 0) {
                                        const emojiList = reactions[action.params.emoji] || [];
                                        const userIndex = emojiList.findIndex(elem => elem.user_id === action.params.sentBy);
                                        if (userIndex === -1) {
                                            reactions[action.params.emoji].splice(userIndex, 1);
                                        }
                                        if (reactions[action.params.emoji].length === 0) {
                                            delete reactions[action.params.emoji];
                                        }
                                    }
                                }
                                elem.reactions = JSON.stringify(reactions)
                                return elem;
                            }
                            return elem;
                        })
                    }
                },
                isUploading: false
            }
        case chatv2Constant.ON_RECEIVE_MESSAGE_S:
            return {
                ...state,
                channel: {
                    ...action.data.channelData
                },
                [action.data.type]: {
                    ...state[action.data.type],
                    [action.data.room_id]: {
                        ...action.data.result
                    }
                },
                isUploading: false
            }

        case chatv2Constant.ON_RECEIVE_MESSAGE_CHANNEL_UPDATE:
            return {
                ...state,
                channel: {
                    ...action.data.channelData
                }
            }

        case chatv2Constant.UPLOAD_CHATV2_FILES_R:
            return {
                ...state,
                isUploading: true
            }

        case chatv2Constant.UPLOAD_CHATV2_FILES_S:
        case chatv2Constant.UPLOAD_CHATV2_FILES_F:
            return {
                ...state,
                isUploading: false
            }

        case chatv2Constant.CHATV2_ADD_NOTIFICATION:
            const updatedNotifications = [
                action.data,
                ...state.notifications
            ];
            const uniqueNotifications = [];
            for (const notification of updatedNotifications) {
                const exists = uniqueNotifications.findIndex(n => n.id === notification.id) !== -1;
                if (!exists) {
                    uniqueNotifications.push(notification);
                }
            }

            return {
                ...state,
                notifications: uniqueNotifications
            };


        case chatv2Constant.CHATV2_SHOWN_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.map(m => {
                    if (m.id === action.data.message_id) {
                        m.shown = true
                    }
                    return m
                })
            }

        case chatv2Constant.CHATV2_REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter(f => f.id != action.data)
            }

        case chatv2Constant.SET_CHANNEL:
            return {
                ...state,
                selected_channel: {
                    ...action.data
                },
                selected_messsages: initialState.selected_messsages
            }

        case chatv2Constant.GET_ALL_CHATV2_USERS_S:
            return {
                ...state,
                allUsers: action?.data?.finalResult || [],
                agency_user: action?.data?.agency_usersResult || []
            }

        case chatv2Constant.CHATV2_GET_MUTED_ROOMS_S:
            return {
                ...state,
                mutedRooms: {
                    projects: action.data?.projects || [],
                    clients: action.data?.clients || [],
                    teams: action.data?.team || [],
                }
            }

        case chatv2Constant.CHATV2_UPDATE_GROUP_S:
            return {
                ...state,
                channel: {
                    ...state.channel,
                    teams: (state.channel?.teams || []).map(m => {
                        if (m.id == action.data?.group_id) {
                            m.title = action.data?.group_name || m.title
                            m.group_avatar = action.data?.group_avatar || m.group_avatar
                        }
                        return m
                    })
                }
            }

        case chatv2Constant.CHATV2_ADD_GROUP_USERS_S:
            const updatedList = (state.channel?.teams || []).map(m => {
                if (m.id == action.data?.room_id) {
                    m = {
                        ...m,
                        ...action.data.result
                    }
                    m["users"] = [...m.users, ...(action.data?.newUsers || [])]
                }
                return m
            })

            return {
                ...state,
                channel: {
                    ...state.channel,
                    teams: updatedList
                }
            }

        case chatv2Constant.CHATV2_DELETE_USER_S:
            return {
                ...state,
                channel: {
                    ...state.channel,
                    teams: (state.channel?.teams || []).map(m => {
                        if (m.id == action.data?.id) {
                            m = {
                                ...m,
                                ...action.data
                            }
                        }
                        return m
                    })
                }
            }

        case chatv2Constant.CHATV2_DELETE_GROUP_S:
            return {
                ...state,
                channel: {
                    ...state.channel,
                    [action.data.cur_type]: action.data.result
                }
            }

        case chatv2Constant.CHATV2_RESET_CHAT_S:
            return {
                ...state,
                [action.data.cur_type]: {
                    ...(state[action.data.cur_type] || {}),
                    [action.data.room_id]: {
                        chats: [],
                        page: { limit: 20, skip: 0, count: 0 }
                    }
                },
                channel: {
                    ...state.channel,
                    [action.data.cur_type]: (state.channel[action.data.cur_type] || []).map(m => {
                        if (m.id == action.data.room_id) {
                            delete m.message_category
                            delete m.message_text
                        }
                        return m
                    })
                }
            }

        case chatv2Constant.CHATV2_ONLINE_USER_S:
            if (action?.data?.join) {
                return {
                    ...state,
                    onlineUsers: {
                        ...action.data.result
                    }
                }
            } else {
                return {
                    ...state,
                    onlineUsers: {
                        ...state.onlineUsers,
                        ...action.data.result
                    }
                }
            }

        case chatv2Constant.CHATV2_USER_TYPING_S:
            return {
                ...state,
                typingUsers: {
                    // ...state.typingUsers,
                    ...action.data.result
                }
            }

        case chatv2Constant.CHATV2_GET_NEW_MESSAGES_S:
            return {
                ...state,
                newChat: action.data.data
            }

        case chatv2Constant.SET_CHATV2_READ_STATUS_R:
            return {
                ...state,
                pre_calls: [...(state.pre_calls || []), action?.params]
            }

        case chatv2Constant.SET_CHATV2_READ_STATUS_S:
            return {
                ...state,
                pre_calls: [...(state.pre_calls || [])].filter(f => JSON.stringify(f) !== JSON.stringify(action.data.rm_precall)),
                [action.data.cur_type]: {
                    ...(state[action.data.cur_type]),
                    [action.data.room_id]: {
                        ...action.data.result
                    }
                },
                newChat: (state.newChat || []).filter(
                    f => f.job_id != action?.data?.room_id
                )
            }

        case chatv2Constant.CHATV2_UPDATE_NOTIFICATION_S:
            return {
                ...state,
                newChat: (state.newChat || []).filter(
                    f => f.id != action?.data?.id
                        && f.job_id != action.data?.job_id
                )
            }

        case chatv2Constant.CHATV2_GET_UNSEND_MESSAGES_R:
            return {
                ...state,
                unsendMessages: action.data
            }
        case profileConstant.CLEAR_NOTIFICATIONS_S:
            let { newChat } = state;
            if (action.data.type === "message") {
                newChat = [];
            }
            return {
                ...state,
                newChat
            };

        case chatv2Constant.CHATV2_GET_UNREAD_ROOMS_S:
            return {
                ...state,
                unreadRooms: {
                    projects: action.data?.projects || [],
                    clients: action.data?.clients || [],
                    teams: action.data?.team || [],
                }
            }

        case chatv2Constant.CHAT_V2_REPLY_MESSAGE_OPEN: {
            let open = false
            if (action?.params?.id) {
                open = true
            }
            return {
                ...state,
                replyOpen: open,
                dataReply: action.params
            }
        }

        case chatv2Constant.CHAT_V2_FORWARD_MESSAGE: {
            return {
                ...state,
                forwardBoxOpen: true,
                data: action.params
            }
        }
        case chatv2Constant.UPDATE_CHATVIEW_TYPE: {
            return {
                ...state,
                chatView: action.data
            }
        }
        // bulk onload read
        case chatv2Constant.BULK_ONLOAD_READ_R:
            return {
                ...state,
                bulkReadUpdate: {
                    ...state.bulkReadUpdate,
                    [action.params.type]: 'loading'
                }
            }
        case chatv2Constant.BULK_ONLOAD_READ_S:
            return {
                ...state,
                bulkReadUpdate: {
                    ...state.bulkReadUpdate,
                    [action.data.data.type]: ''
                }
            }
        case chatv2Constant.BULK_ONLOAD_READ_F:
            return {
                ...state,
                bulkReadUpdate: {
                    ...state.bulkReadUpdate,
                    [action.data.type]: ''
                }
            }

        case chatv2Constant.NEW_ROOM_DETAIL_S:
            return {
                ...state,
                channel: {
                    ...state.channel,
                    [action.data.type]: action.data.result
                }
            }

        // Updated scroll button state    
        case chatv2Constant.UPDATE_CHAT_SCROLL_BT:
            return {
                ...state,
                scrollBtn: action.data
            }

        case chatv2Constant.SET_CHATV2_BULK_READ_STATUS_S:
            return {
                ...state,
                [action.data.chat_type]: {
                    ...(state[action.data.chat_type]),
                    [action.data.room_id]: {
                        ...action.data.result
                    }
                },
            }
        case chatv2Constant.CHAT_V2_EDIT_MESSAGE_OPEN: {
            return {
                ...state,
                editChatData: action.data
            }
        }
        case chatv2Constant.EDIT_MESSAGE_R: {
            return {
                ...state,
                editChatData: null
            }
        }
        case chatv2Constant.EDIT_MESSAGE_S: {
            return {
                ...state,
                [action.params.type]: {
                    ...state[action.params.type],
                    [action.params.room_id]: {
                        ...action.params.result
                    }
                }
            }
        }
        case chatv2Constant.OPEN_SELECT_MESSAGE: {
            if (action.data.openSelect) {
                return {
                    ...state,
                    selected_messsages: { ...action.data }
                }
            } else {
                return {
                    ...state,
                    selected_messsages: initialState.selected_messsages
                }
            }
        }
        case chatv2Constant.GROUP_FORWARD_MESSAGE_R: {
            return {
                ...state,
                selected_messsages: initialState.selected_messsages
            }
        }
        case chatv2Constant.CLEAR_CHAT_STATE: {
            return {
                ...initialState
            }
        }

        case chatv2Constant.RELOAD_CHAT_TABS: {
            return {
                ...state,
                reloadChatTabs: new Date().getTime()
            }
        }

        default:
            return {
                ...state
            }
    }
}

export default chatv2Reducer