import React from "react";

const Mentions = ({ attributes, element, msgComponentType,viewType }) => {
  const characterName = element.character?.name || "Unknown";

  const style = {
    padding: "3px 3px 2px",
    margin: "0 1px",
    verticalAlign: "baseline",
    display: "inline-block",
    borderRadius: "4px",
    color: msgComponentType === 'sent' && viewType!=='listView' ? '#fff62d' : '#2563EB',
    fontSize: "0.9em",
  };

  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element?.character?.name?.replace(" ", "-") || "name"}`}
      style={style}
    >
      @{characterName}
    </span>
  );
};

export default Mentions;
