import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SlideShowComponent } from "../../components/SlideShow";
import { updateAction } from '../../redux/actions';
import { slideShowConstant } from '../../redux/constants';

function SlideShow() {
    const dispatch = useDispatch()
    const { files, open, activeFileIndex, star, previewData, data, isFromMedia, mediaFiles, directDownload, loading } = useSelector(s => s.slideShow);
    const { id } = useSelector(s => s.auth.loginUser)

    const handleClose = () => {
        dispatch(updateAction.update(slideShowConstant.DISPLAY_STATUS_FALSE))
    };

    return (
        <>
            <SlideShowComponent
                open={open}
                items={files}
                index={activeFileIndex}
                star={star}
                data={data}
                previewData={previewData}
                userId={id}
                isFromMedia={isFromMedia}
                mediaFiles={mediaFiles}
                directDownload={directDownload}
                loading={loading}
                handleClose={handleClose}
            />
        </>
    )
}

// default props
SlideShow.defaultProps = {
    classes: {}

};

// prop types
SlideShow.propTypes = {
    classes: PropTypes.object
};

export default SlideShow;