export const slideShowConstant = {
    LOADING_TRUE: "LOADING_TRUE",
    LOADING_FALSE: "LOADING_FALSE",
    DISPLAY_STATUS_TRUE: "DISPLAY_STATUS_TRUE",
    DISPLAY_STATUS_FALSE: "DISPLAY_STATUS_FALSE",
    SLIDE_SHOW_FILE_UPDATE: "SLIDE_SHOW_FILE_UPDATE",
    SLIDE_SHOW_EMOJI_UPDATE: "SLIDE_SHOW_EMOJI_UPDATE",
    SLIDE_SHOW_DATA_UPDATE: "SLIDE_SHOW_DATA_UPDATE",
    SLIDE_SHOW_STAR_UPDATE: "SLIDE_SHOW_STAR_UPDATE",
  };
  