import React, { useState } from "react"
import { Slider } from "@material-ui/core";

export const BlurNodeOptions = (props) => {
    const { classes, selectedNode } = props
    const { onChangeAttrs, ...otherProps } = selectedNode?.attrs || {}
    const [attrs, setAttrs] = useState({
        colorPicker: false,
        pickerEle: null,
        ...(otherProps || {})
    })

    const onChange = (val) => {
        const updatedAttrs = {
            ...attrs,
            pixelSize: val
        }
        setAttrs(updatedAttrs)
        if (onChangeAttrs) {
            onChangeAttrs(updatedAttrs)
        }
    }

    return (
        <div className={classes.toolOptions}>
            <Slider 
                style={{ width: '200px' }}
                value={attrs?.pixelSize || 10} 
                min={0} 
                max={100} 
                onChange={(e, val) => onChange(val)} 
            />
        </div>
    )
}