import React from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

// Import css
const loaderWrapperStyle = {
  position: "absolute",
  top: "0px",
  left: "0px",
  bottom: "0px",
  width: "100%",
  height: "100%",
  zIndex: 100000,
  // backgroundColor: "#f3f9fd61",
  color: "#222",
};

const loaderTextStyle = {
  color: "rgb(255, 255, 255)",
  height: "100px",
  position: "absolute",
  width: "100px",
  margin: "auto",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
};

const PageLoaderComponent = (props) => {
  const { isLoading } = props;
  const UI = useSelector((state) => state.UI);

  //get reducer data
  // const UI = useSelector((state) => state.UI);

  /* useEffect(()=>{
    if(UI.loading){
      setLoad(UI.loading)
    }else{
      setTimeout(()=>{
        setLoad(UI.loading)
      },1000)
    }
  },[UI.loading]) */

  if (!UI.loading && !isLoading) { return null; }
  return (
    <div style={loaderWrapperStyle}>
      <div style={loaderTextStyle}>
        <CircularProgress style={{color: '#2563EB'}} />
      </div>
    </div>
  );
};

export const PageLoader = PageLoaderComponent;
