import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// Import Payment Layout
import PaymentLayout from "../layouts/PaymentLayout/PaymentLayout";

// Routes the page without any authentication by the user
const PaymentRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        <PaymentLayout>
            <Component {...props} />
        </PaymentLayout>
    )} />
);

// Define Props Types
PaymentRoute.propTypes = {
    component: PropTypes.elementType
};

export default PaymentRoute;
