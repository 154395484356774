import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../utils/apiHelper";

const fileHubServices = {
  getMetricsAndRecents: async (id, type) => {
    // get doc content
    return await getRequest(`file-hub/${type}/${id}/metrics`);
  },

  getAllFiles: async ({ id, type, offset = 0, search = "", category = "" }) => {
    return await getRequest(
      `file-hub/${type}/${id}/files?offset=${offset}&search=${search}&category=${category}`
    );
  },

  getFolderContents: async (id, offset = 0) => {
    return await getRequest(`file-hub/folders/${id}/contents?offset=${offset}`);
  },

  createFolder: async (data) => {
    return await postRequest(`file-hub/folders`, data);
  },

  deleteFolder: async (folderId) => {
    return await deleteRequest(`file-hub/folders/${folderId}`);
  },

  deleteFile: async (fileId) => {
    return await deleteRequest(`file-hub/files/${fileId}`);
  },

  renameFolder: async (folderId, newName) => {
    return await putRequest(`file-hub/folders/${folderId}`, { name: newName });
  },

  renameFile: async (fileId, newName) => {
    return await putRequest(`file-hub/files/${fileId}`, { name: newName });
  },
  uploadFiles: async (data) => {
    return await postRequest(`file-hub/files`, data);
  },
};
export default fileHubServices;
