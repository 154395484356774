const styles = (theme) => ({
    chipInput: {
        '& .MuiInputBase-root': {
            paddingTop: '10px !important',
            paddingBottom: '10px !important',
        },
        '& .MuiInputBase-input': {
            paddingBottom: '4px !important',
            marginTop: '0px !important',
            marginBottom: '0px !important'
        },
        '& .MuiChip-root': {
            margin: 2
        }
    }
});

export default styles;
