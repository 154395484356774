
const validation = {

    isRequired: (text = "This field is required") => {
        return {
            required: (value) => {
                return value ? true : text
            }
        }
    },

    isEmail: (text = "Please enter valid email address") => {
        return {
            isEmail: (value) => {
                // Regex should match email validation regex in backend
                const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
                const result = regex.test(String(value).toLowerCase());
                return result ? true : text
            }
        }
    },

    isFirstName: (text = "Please enter first name") => {
        return {
            isFirstName: (value) => {
                const regex = /([a-zA-Z]{2,31}\s*)+/
                const result = regex.test(value)
                return result ? true : text
            }
        }
    },

    isLastName: (text = "Please enter last name") => {
        return {
            isLastName: (value) => {
                const regex = /[a-zA-Z]{1,31}/
                const result = regex.test(value)
                return result ? true : text
            }
        }
    },
    isAgencyName: (text = "Please enter agency name") => {
        return {
            isAgencyName: (value) => {
                const regex = /([a-zA-Z',.-]+( [a-zA-Z',.-]+)*){2,125}/
                const result = regex.test(value)
                return result ? true : text
            }
        }
    },
    isUserName: (text = "Please enter username") => {
        return {
            isUserName: (value) => {
                const regex = /^[A-Za-z][A-Za-z0-9_]{2,31}$/
                const result = regex.test(value)
                return result ? true : text
            }
        }
    },
    isPasswordCriteriaMet: (text = "Password is insecure") => {
        return {
            isPasswordCriteria: (value) => {
                if (value.length < 6) {
                    return "password length should be greater than 6";
                } else if (value.length > 50) {
                    return "password length should be less than 50";
                } else if (value.search(/[!@#$^&*()_]/) === -1) {
                    return "password should contain atleast one special character";
                } else if (value.search(/[a-z]/) === -1) {
                    return "password should contain one lower case";
                } else if (value.search(/\d/) === -1) {
                    return "password should contain one number";
                } else if (value.search(/[A-Z]/) === -1) {
                    return "password should contain one upper case";
                }else if (value.search(/[A-Z]/) === -1) {
                    return "password should contain one upper case";
                } else { 
                    return true; 
                }
            }
        }
    },
    isPasswordMatch: (password = "") => {
        return {
            isPasswordMatch: (value) => {
                return value === password || "The passwords do not match"
            }
        }
    },
    isDomain: (text = "Please enter a valid domain") => {
        return {
            isDomain: (value) => {
                const regex =/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
                const result = regex.test(value)
                return result ? true : text
            }
        }
    },
    isDifferentValue: (columnValue = "", columnName) => {
        return {
            isDifferentValue: (value) => {
                return value != columnValue || `${columnName} have the same value. It should not be like that.`
            }
        }
    },
}

export const validations = validation;