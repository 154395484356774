import { baseAction } from "./baseAction";
import { taskConstant, alertConstant, loaderConstant } from "../constants";
import { clientService, projectService, taskService } from "../services";

const { request, success, failure } = baseAction;

// All login based actions
export const taskAction = {
  getAllTasks,
  getTaskFilter,
  completeTask,
  getTaskJobData,
  createNewTask,
  getActiveTeamMembers,
  getActiveClientTeamMembers
};

// /** get All Tasks */
function getAllTasks(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(taskConstant.GET_ALL_TASKS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    taskService.getAllTasks(params).then(data => {
      dispatch(success(taskConstant.GET_ALL_TASKS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(taskConstant.GET_ALL_TASKS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

// /** get All Tasks */
function getTaskFilter(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(taskConstant.GET_ALL_TASKS_FILTER_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    taskService.getTaskFilter(params).then(data => {
      dispatch(success(taskConstant.GET_ALL_TASKS_FILTER_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(taskConstant.GET_ALL_TASKS_FILTER_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* complete task */
function completeTask(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(taskConstant.COMPLETE_TASK_R, params));
    // dispatch(request(loaderConstant.LOADING_TRUE));
    taskService.completeTask(params).then(data => {
      dispatch(success(taskConstant.COMPLETE_TASK_S, data));
      dispatch(success(taskConstant.UPDATE_COMPLETED_TASK_COUNT_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      // dispatch(request(loaderConstant.LOADING_FALSE));
      if (data.message && data.message.toLowerCase() === "updated successfully") {
        callBack()
      }
    }, error => {
      dispatch(failure(taskConstant.COMPLETE_TASK_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      // dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* Get All Jobs and clients data */
function getTaskJobData(params, callBack = () => { }) {
  return (dispatch) => {
    // dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(taskConstant.GET_ALL_JOB_DATA_R, params));
    taskService.getTaskJobData(params).then(data => {
      // dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(taskConstant.GET_ALL_JOB_DATA_S, data));
    }, error => {
      dispatch(request(taskConstant.GET_ALL_JOB_DATA_F, params));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      // dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

/* create & list new task */
function createNewTask(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    taskService.createNewTask(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(taskConstant.CREATE_ONE_NEW_TASK_S, data));
      dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      if (data.status && data.status.toLowerCase() === "success") {
        callBack()
      }
    }, error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}

function getActiveTeamMembers(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(taskConstant.GET_ACTIVE_MEMBERS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    projectService.getActiveTeamMembers(params).then(data => {
      dispatch(success(taskConstant.GET_ACTIVE_MEMBERS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(taskConstant.GET_ACTIVE_MEMBERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}
function getActiveClientTeamMembers(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(taskConstant.GET_ACTIVE_MEMBERS_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    clientService.getActiveTeamMembers(params).then(data => {
      dispatch(success(taskConstant.GET_ACTIVE_MEMBERS_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack(data)
    }, error => {
      dispatch(failure(taskConstant.GET_ACTIVE_MEMBERS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
    }
    );
  };
}