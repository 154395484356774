import React from "react";
import PropTypes from "prop-types";
import { Snackbar as Core } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';


import { Button } from '../index'
/**
 * Link Component
 * @param {*} props
 */

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        top: 0,
        position: 'absolute',
        left: 0,
        transform: 'translate(0px, 0px)',
        zIndex: 99999,
        '& .MuiSnackbarContent-root': {
            width: '100%',
            background: theme.palette.primary.gradientBtn,
            borderRadius: 0,
            height: 55,
        },
        '& .MuiSnackbar-anchorOriginTopCenter': {
            top: "0px",
        },
    },
}));


function InfoBarComponent({ position, open, handleClose, openStatus, message, actionMessage, href, children, className, onAcceptAction, onCloseAction, closeMessage, closeIcon, showCloseBtn, ...rest }) {

    const classes = useStyles();

    return (
        <div className={`${classes.root} connectNotification`} style={{position: position}}>
            <Core
                className={className}
                classes={classes}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={openStatus}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={
                    <React.Fragment>
                        <Button className="enableNotification mr-1" color="secondary" size="small" variant="outlined" onClick={() => onAcceptAction("1")} title={actionMessage}></Button>
                        {/* <Button className="enableNotification mr-1" color="secondary" size="small" variant="outlined" onClick={() => onAcceptAction("2")} title={"Send Invoice Without Payment"}></Button> */}
                        {showCloseBtn ?
                            (!closeIcon ?
                                <Button color="secondary" size="small" variant="outlined" onClick={() => onCloseAction()} title={closeMessage}></Button>
                                :
                                <IconButton size="small" aria-label="close" color="inherit" onClick={() => onCloseAction()}>
                                    <CloseIcon fontSize="small" />
                                </IconButton>) :
                            null
                        }

                        {/* <Button color="secondary" size="small" variant="outlined" onClick={() => onCloseAction()} title={closeMessage}></Button> */}
                        {/* <IconButton size="small" aria-label="close" color="inherit" onClick={onNotificationClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton> */}
                    </React.Fragment>
                }
            />
        </div>
    );
}

// default props
InfoBarComponent.defaultProps = {
    classes: {},
    message: "",
    openStatus: false,
    actionMessage: "",
    closeMessage: "Close",
    closeIcon: false,
    className: "",
    showCloseBtn: true,
    onAcceptAction: () => { },
    onCloseAction: () => { },
};

// prop types
InfoBarComponent.propTypes = {
    children: PropTypes.any,
    openStatus: PropTypes.bool,
    onAcceptAction: PropTypes.func,
    onCloseAction: PropTypes.func,
    classes: PropTypes.object,
    className: PropTypes.string,
    message: PropTypes.string,
    closeIcon: PropTypes.bool,
    actionMessage: PropTypes.string,
    closeMessage: PropTypes.string,
    showCloseBtn: PropTypes.bool,
};

/** Export Component */
export default InfoBarComponent;
