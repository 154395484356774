import React from "react";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// Import Actions

// Import Styles
import style from "./style";
import { Skeleton } from "@material-ui/lab";

function ChatSkeleton(props) {

    return (
        <div>
            <Skeleton
                variant="rect"
                width="70%"
                height="50px"
                style={{ marginLeft: "40px" }}
            ></Skeleton>
            <Skeleton
                width="70%"
                variant="text"
                style={{ marginLeft: "40px" }}
            ></Skeleton>
            <Box display="flex" alignItems="center">
                <Box margin={1}>
                    <Skeleton variant="circle" width="40px" height="40px"></Skeleton>
                </Box>
                <Box width="50%">
                    <Skeleton width="100%" variant="text"></Skeleton>
                </Box>
            </Box>
        </div>
    )
}

// default props
ChatSkeleton.defaultProps = {
    classes: {}
};

// prop types
ChatSkeleton.propTypes = {
    classes: PropTypes.object
};

// Export component
export default withStyles(style)(ChatSkeleton)
