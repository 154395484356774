import React from "react";
import { PauseCircleOutline } from "@material-ui/icons";

export default function Pause(props) {
  const { handleClick } = props;

  return (
    <button className="player__button" onClick={() => handleClick()}>
      <PauseCircleOutline />
    </button>
  );
}
