/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import { _envParams } from "../../config/env_params";

import PrivateLayoutWeb from "./PrivateLayoutWeb";
import PrivateLayoutMobile from "./PrivateLayoutMobile";
import ScriptComponent from "./Component/ScriptComponent";

// import action
import { featureFlagActions } from "../../redux/actions";

const PrivateLayout = (props) => {

  // Define Dispatch
  const dispatch = useDispatch();

  const { profile } = useSelector(s => (s.profile));
  const { loginUser } = useSelector((state) => state.auth);
  const hasKey = localStorage.getItem('key');

  useEffect(() => {
    if (hasKey && loginUser?.id) {
      dispatch(featureFlagActions.getFlags());
    }
  }, [loginUser, hasKey, dispatch]);

  return (
    <>
      {profile && profile.hasOwnProperty('userEmail') && profile.userEmail ? <ScriptComponent /> : null}
      {_envParams.REACT_APP_BUILD_FOR === "WEB" ? <PrivateLayoutWeb {...props} /> : <PrivateLayoutMobile {...props} />}
    </>
  );
}

// Define Props Types
PrivateLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired
};

// Default props
PrivateLayout.defaultProps = {
  classes: {},
  children: {},
  user: {},
  access: false
};

export default PrivateLayout;
