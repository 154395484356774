import { fileHubConstants } from "../constants/fileHubConstant";

const initialState = {
    dashBoardData: {
        metricsData: [],
        recentFolders: null,
        recentFiles: null,
    },
    resourceFiles: null,
    totalResourceCount: 0,
    currentFolderContent: {
        contents: null,
        folderName: "",
    },
};
export default function fileHubReducer(state = { ...initialState }, action) {
    switch (action.type) {
        case fileHubConstants.GET_METRICS_S:
            return {
                ...state, dashBoardData: {
                    metricsData: action.data.metricsData,
                    recentFolders: action.data.recentFolders,
                    recentFiles: action.data.recentFiles,
                }
            };
        case fileHubConstants.RESET_METRICS_S:
            return {
                ...state, dashBoardData: {
                    metricsData: [],
                    recentFolders: null,
                    recentFiles: null,
                }
            };
        case fileHubConstants.GET_ALLFILES_S:
            return {
                ...state,
                resourceFiles: state.resourceFiles && !(action.data.flush) ? [...state.resourceFiles, ...action.data.files] : action.data.files,
                totalResourceCount: action.data.totalFiles
            };

        case fileHubConstants.GET_FOLDER_CONTENTS_S:
            return {
                ...state,
                currentFolderContent: {
                    contents: action.data.contents,
                    folderName: action.data.folder_name,

                },
            };
        case fileHubConstants.RESET_FOLDER_CONTENTS_S:
            return {
                ...state,
                currentFolderContent: {
                    contents: null,
                    folderName: "",
                },
            };
        case fileHubConstants.RESET_ALLFILES_S:
            return { ...state, resourceFiles: null, totalResourceCount: 0 };
        // case fileHubConstants.CREATE_FOLDER_S:
        // return {...state, currentFolderContent:}

        case fileHubConstants.RESET:
            return initialState;
        default:
            return state;
    }
}
