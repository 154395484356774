import React from "react";

import { Button, Grid, withStyles, } from "@material-ui/core";
import PropTypes from "prop-types";


import { NoDataIcon, NosearchResultFound, PlusIconCode } from "../../../assets/svg/index"
import NoDataStyle from "./NoDataStyle";

const Nodata = ({ classes, showIcon, title, description, buttonText, BtnOnClick, enableButton, enableNoSearchResultsFound, disableIcon, sizeParametersImage }) => {

  const handleButtonClick = () => {
    if (BtnOnClick) {
      BtnOnClick();
    }
  };
  return (
    <Grid
      className={`${classes.noDataContainer} customNodataHeight customWidth`}
    >
      {showIcon && enableNoSearchResultsFound ? (
        <NosearchResultFound
          height={sizeParametersImage?.height}
          width={sizeParametersImage?.width}
        />
      ) : enableNoSearchResultsFound ? (
        <NosearchResultFound />
      ) : (
        <NoDataIcon />
      )}
      <Grid className={`${classes.NodataText} customTitle pt-3`}>{title}</Grid>
      <Grid className={`${classes.NoDataContent} cusomDescription`}>
        {description}
      </Grid>
      {enableButton && (
        <Grid style={{paddingTop:"10px"}}>
          <Button
            startIcon={disableIcon ? null : <PlusIconCode />}
            className={"ml-2"}
            variant="contained"
            color="primary"
            size="medium"
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

Nodata.propTypes = {
  classes: PropTypes.object.isRequired,
  showIcon: PropTypes.element,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  BtnOnClick: PropTypes.func,
  enableButton : PropTypes.bool
};

Nodata.defaultProps = {
  showIcon: () => { },
  BtnOnClick: () => { },
  title: "",
  description: "",
  buttonText: "Create",
  enableButton : true
};

export default withStyles(NoDataStyle)(Nodata);
