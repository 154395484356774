/* eslint-disable react/prop-types */
import { Grid, withStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";
import React, { useState, Suspense } from "react";
import { isBrowser, isIOS, isMobile, mobileModel } from "react-device-detect";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css'
import { PageLoader } from "../../../components";


//images
import Logo from "../../../assets/images/Icon_LightBG.png";
import LogoWhite from "../../../assets/images/logoWhite.svg";

// Layout Style
import AuthLayoutStyles from "./AuthLayoutStyles";

// Import Child Container
import { useSelector } from "react-redux";
import { Typography } from "../../../components";
import history from "../../../config/history";

const Slider = React.lazy(() => import("react-slick"));

const AuthLayout = (props) => {
  const { classes, isPublic } = props;
  let safeAreaTopHeight = getComputedStyle(document.documentElement).getPropertyValue("--sat");
  const [isLoading, setIsLoading] = useState(true);

  const { profile } = useSelector((s) => s.profile);
  const {loginUser: { user_role }} = useSelector((s) => s.auth);

  // check location 
  const isEducate = window.location.hash?.includes("educate")

  // Set Container Height
  if (isIOS && isMobile && !isBrowser && mobileModel === "iPhone") {
    // height = height - parseInt(safeAreaTopHeight.replace("px", ""));
  }
  /**
   * Handle Header Menu Action
   */

  const handleHomePage = () => {
    const page = isPublic ? "/" : user_role && user_role["dash"] ? "/dashboard" : "clients"; 
    history.push(page);
  };

  const authBanners = [
    {
      title: "1% better, everyday!",
      desc: "Everything you need in the one place",
    }
  ];

  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleImageLoad = async () => {
    await delay(100); // adjust the delay time as needed
    setIsLoading(false);
  };

  return (
    <Grid
      container
      className={`${isIOS && isMobile && !isBrowser && mobileModel === "iPhone" ? "notchContainer" : null } h-100`} 
      direction="column"
      wrap="nowrap"
    >
      {isIOS && isMobile && !isBrowser && mobileModel === "iPhone" ? (
        <Grid item xs={12} style={{ height: `${safeAreaTopHeight}` }} className="notchGrid flexBasis0 "/>
      ) : null}
      <Grid item xs={12}>
        <Grid container className={classes.authBodyCotainer} justifyContent="center">
          <Grid item xs={12} sm={6} className={classes.loginLeftContainer}>
            <Grid container alignItems="center">
              <img
                className={classes.logo}
                src={LogoWhite}
                alt="Logo"
                onClick={() => handleHomePage()}
              />
              {isEducate &&
                  <>
                    <Typography variant="h5" className="pl-1 pr-1 fw-500">X</Typography>
                    <img className={classes.logo} src={'https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/1951/FLOWS/5604/Educate%20logo%201_1725003749318.png'} alt="Logo" onClick={() => window.open('https://www.flozy.com/educate')} />
                  </>
                }
                {!isEducate && 
                  <Typography variant="h5" className="pl-1 fw-500"> Flozy </Typography>
                }
            </Grid>
            <Suspense fallback={<PageLoader isLoading={true} />}>
            <Slider {...settings}>
              {authBanners.map((item) => (
                <Grid>
                  <LazyLoadImage alt="banner Imag"  height="100%" width="100%" src="https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage/1721644861245_authbanner1.c7c634cd.svg" onLoad={handleImageLoad}/>
                  {!isLoading && (
                    <>
                      <Typography variant="h2" className="fw-700" align="center">{item.title}</Typography>
                      <Typography variant="body1" className="description" align="center">{item.desc}</Typography>
                    </>
                  )}
                </Grid>
              ))}
            </Slider>
            </Suspense>
          </Grid>
          <Grid item xs={12} sm={6} className={`${classes.athunticationContainer} `}>
            <Grid container alignItems="center" justifyContent="center" className={classes.mobileLogo} >
              {profile.custom_app_logo === 1 ? (
                <Avatar className={classes.logo} alt="Logo" src={profile.agency_logo} onClick={() => handleHomePage()}/>
              ) : (
                <img className={classes.logo} src={Logo} alt="Logo" onClick={() => handleHomePage()}/>
              )}
              <Typography variant="h5" className="pl-1 fw-500"> Flozy </Typography>
            </Grid>
            <Grid container className={`${classes.container} `} alignItems="center">
              {props.children}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

// Define Props Types
AuthLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired,
};

// Default props
AuthLayout.defaultProps = {
  classes: {},
  children: {},
  user: {},
  access: false,
};

export default withStyles(AuthLayoutStyles)(AuthLayout);
