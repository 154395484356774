import React, { useEffect, useState, useRef } from 'react';
import FlozyEditor from '../../../components/FlozyEditor/FlozyEditor';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, useMediaQuery, Button,withStyles } from '@material-ui/core';
import { getToken } from '../../../utils';
import { FunnelActions } from "../../../redux/actions/funnelAction";
import { _api } from '../../../config/environment';
import { pdfActions } from "../../../redux/actions/pdfActions";
import funnelStyles from "../styles";
import {NewPdfIcon} from "../../../assets/svg/index"
import { JSONParse } from '../funnelUtils';

const PreviewSignedContracts = ({classes}) => {
  const dispatch = useDispatch();
  const { signedContract } = useSelector((state) => state.funnel);
  const params = useParams();
  const isMobile = useMediaQuery('(max-width:899px)');
  const printRef = useRef();

  const [contractContent, setContractContent] = useState(() => signedContract ? JSONParse(signedContract.content) : []);

  useEffect(() => {
    dispatch(FunnelActions.getSignedContractsByUUID(params?.uuid));
  }, [params?.uuid, dispatch]);

  useEffect(() => {
    if (signedContract?.content) {
      setContractContent(JSONParse(signedContract.content));
    }
  }, [signedContract]);

  const handlePrint = () => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = printRef.current.innerHTML;
    tempDiv.querySelectorAll('.signature-btn-container').forEach(el => el.remove());
    const printContent = tempDiv.innerHTML;
    console.log(printContent,"printContent")
    dispatch(pdfActions.createPdf({ html: printContent,contract_name:signedContract?.user_name, funnel_name:signedContract?.name }));

  };
  if(!contractContent.length){
    return (
      <div style={{with:"100vw",height:"100vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
   <CircularProgress />
      </div>
    )
  }

  return (
    <div className={classes.preview_contract_container}>
      <div>
        <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }} className='.signature-btn-container'>
          <div onClick={handlePrint} className={classes.download_btn} >
          <NewPdfIcon/>
            Download PDF
          </div>
        </div>
        <div className={classes.download_container} ref={printRef}>
          <FlozyEditor
            content_status
            readOnly={"readonly"}
            content={contractContent}
            otherProps={{
              token: getToken(),
              API_HOST: _api.url,
              PAGES_API_HOST: _api.pageBuilder,
              ICON_API:_api.iconApi, 
              fullWidth: "100%",
              fixedWidth: "100%",
              isMobile: isMobile,
              needLayout: false
            }}
          />
        </div>
      </div>
  </div>
  
  );
};

export default withStyles(funnelStyles)(PreviewSignedContracts);
