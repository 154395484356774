const style = (theme) => ({
    selectedEmojiDivContainer: {
        position: 'relative',
        '&.selectedMoreEmojiActive': {
            width: 244
        }
    },
    selectedEmojiDiv: {
        display: 'flex',
        alignItems: 'center',

        '&.receiverEmoji': {
            // marginLeft: 40,
            paddingTop: 3
        },
        '&.senderEmoji': {
            // marginRight: 40,
            // flexDirection: 'row-reverse',
            paddingTop: 3
        },
        '& .selectedEmojis': {
            // boxShadow: '0px 1px 3px #dddddde3',
            padding: '5px 5px',
            borderRadius: 30,
            marginRight: 4,
            border: '1px solid #fff',
            // minWidth: '24px',
            // height: '24px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            // background: '#DEE5F3',
            // boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.13)',
            lineHeight: '0.9rem',
            fontSize: '0.9rem',
            height: '24px',
            width: '24px',
            '& .emojiCount': {
                paddingLeft: 3,
                fontSize: '10px'
            },
            '&.active': {
                border: '1px solid #495dfc14',
                // background: '#495dfc0f',
                // background: 'linear-gradient(90deg, rgb(82 79 252 / 20%) 20%, rgb(25 169 252 / 20%) 100%) !important',
            }
        },
        '& .moreemojis': {
            // padding: '4px 10px',
            boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.13)',
            marginRight: '6px',
            borderRadius: '30px',
            // background: ' #e8e8e8',
            color: '#64748B',
            height: '24px',
            // width: '24px',
            fontSize: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            '&.visibile': {
                opacity: 1,
                width: '24px'
            },
            '&.hidden': {
                opacity: 0,
                width: '1px'
            }
        },
        '&.showmoreRection': {
            maxWidth: '200px',
            alignItems: 'center',
            boxShadow: '0px 1px 8px #dbdbdb5e',
            borderRadius: '10px',
            padding: '10px',
            marginRight: '4px',
            flexWrap: 'wrap',
            position: 'absolute',
            background: '#fff',
            zIndex: 1,
            top: '-10px',
            transition: 'all 0.2s',
            '& .selectedEmojis': {
                marginBottom: 5
            },
            '& .moreEmojiClose': {
                position: 'absolute',
                right: 1,
                top: -3,
                width: 15,
                padding: 0
            }
        }
    },
    moreReactionDiv: {
        padding: 20,
        maxWidth: 300,
        '& .morereaction': {
            flexDirection: 'column'
        }
    },
    popover: {
        pointerEvents: 'none',
        padding: 20
    },
    emojiReactedDiv: {
        // padding: '15px',
        minWidth: '150px',
        maxHeight: '300px',
        overflowY: 'auto',
        padding: '8px 16px 16px'
        // maxWidth: '250px',
    },
    emojiReactedPerson: {
        padding: '2px 0',
        '& .nameavatarContainer': {
            gap:'5px',
            display: 'flex',
            alignItems: 'center'
        },
        '& .avatarDiv, & .MuiAvatar-root': {
            width: 24,
            height: 24,
            fontSize: 12
        },
        '& .personname': {
            marginLeft: 0,
            color:theme.palette.text.text1,
        },
        '& .reactedemoji': {
            fontSize: '24px', 
            cursor: 'pointer'
        }
    },
    reactionWrapper: {
        '& .MuiTab-root': {
            minWidth: 'fit-content'
        },
        '& .MuiTabs-indicator': {
            background: '#2563EB'
        },
        '& .MuiTabs-scrollButtons.Mui-disabled': {
            width: '0px',
            transition: 'all 0.5s'
        },
        '& .MuiTabs-root': {
            height: '40px',
            minHeight: '40px'
        },
        '& .MuiBox-root': {
            padding: '0px'
        }
    },
    reactionPopup: {
        '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '350px',
            borderRadius: '12px'
        }
    }
}
)

export default style