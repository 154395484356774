const styles = (theme) => ({
    roots: {
      height: 36,
      '& .MuiOutlinedInput-root': {
        height: 36,
        minHeight: 36
      }
    },
  });
  
  export default styles;