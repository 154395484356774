const styles = (theme) => ({
  toggleButton: {
    '& .Mui-checked': {
      color: '#378cfb'
    },
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#8b94cd'
    }
  }
});

export default styles;
