import React from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import PropTypes from "prop-types";

// components
import { TextField } from "../TextField";

const PhoneNumberComponent = (props) => {

    // props
    const { value, onChange, name, containerClass ,disabled } = props;

    return (
        <PhoneInput
            value={value}
            onChange={(phone,e1,e2,e3) => {
                onChange(name, phone,e3) }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                    }}
                />
            )}
            disabled={disabled}
            containerClass={containerClass}
        />
    )
}

// default props
PhoneNumberComponent.defaultProps = {
    name: "phoneNumber",
    type: "text",
    margin: "dense",
    variant: "outlined",
    onChange:()=>{},
    containerClass: ""
};

// prop types
PhoneNumberComponent.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string,
    margin: PropTypes.string,
    variant: PropTypes.string,
    onChange: PropTypes.func,
    containerClass: PropTypes.string
};

export const PhoneNumber = PhoneNumberComponent;