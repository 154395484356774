export function getContainerHeight() {
  return window.innerHeight - 50;
}

export function getPrivateContainerHeight() {
  return window.innerHeight - 50;
}

//Table Height

export function getTableHeight(defaultValue = 220) {
  return window.innerHeight - defaultValue;
}

export function getTableRowNumber(defaultValue = 220) {
  return Math.floor((getTableHeight(defaultValue) - 110) / 59);
}

export function getTableShowItem(defaultValue){
  let Items = [10,25,50,100]
  let value = Items.filter(value=>value > defaultValue)
  value.unshift(defaultValue)
  return value
}
