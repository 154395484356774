const ConfirmDialogStyles = (theme) => ({
    dialogPaper: {
        borderRadius: '16px',
        
        padding: "20px 20px 14px 20px",

        '& .MuiDialogTitle-root': {
            padding: "0px"
        },

        '& .MuiDialogContent-root': {
            padding: "0px !important",
            paddingTop: "8px !important"
        },

        '& .MuiDialogActions-root': {
            marginTop: "14px"
        },
        '& a':{
            color: "#2563EB !important"
        }
    },
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: `1px solid ${theme.palette.primary.border5}`,
        paddingBottom: "14px"
    },
    description: {
        color: theme.palette.secondary.main
    },
    title: {
        '& .MuiTypography-h6': {
            fontWeight: "700",
            color: theme.palette.primary.main
        }
    },
    actionBtns: {
        // minWidth: 134,
        padding: "8px 16px",
        color: theme.palette.primary.main
    }
})

export default ConfirmDialogStyles