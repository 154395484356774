import { funnelConstants, loaderConstant, workflowConstants } from "../constants";
import { FunnelService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

function create(body, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(funnelConstants.CREATE_FUNNEL_R, { body }));
    FunnelService.createFunnel(body).then(
      (data) => {
        dispatch(success(funnelConstants.CREATE_FUNNEL_S, { data }));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data.data);
      },
      (error) => {
        dispatch(failure(funnelConstants.CREATE_FUNNEL_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}

function getFunnelTemplateSteps(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(funnelConstants.GET_FUNNEL_STEPS_R, {}));
    FunnelService.getFunnelTemplateSteps(params).then(
      (data) => {
        dispatch(success(funnelConstants.GET_FUNNEL_STEPS_S, data.data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data);
      },
      (error) => {
        dispatch(failure(funnelConstants.GET_FUNNEL_STEPS_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}

function resetFunnelTemplateSteps() {
  return (dispatch) => {
    dispatch(request(funnelConstants.GET_FUNNEL_STEPS_R, {}));
  };
}
function resetFunnelTemplates() {
  return (dispatch) => {
    dispatch(request(funnelConstants.GET_FUNNEL_LIST_R, {}));
  };
}
function resetFunnelData(){
  return (dispatch) => {
    dispatch(request(funnelConstants.GET_SIGNED_CONTRACTS_R, {}));
  };
}
function getFunnelTemplateList(body, callBack = () => {}) {
  return (dispatch) => {
    if(body.clearSkip){
      dispatch(request(funnelConstants.RESET_FUNNEL_LIST_SKIP))
      delete body.clearSkip
    }
    FunnelService.getFunnelTemplateList(body).then(
      (data) => {
        if (!body?.preserveData) {
          dispatch(request(funnelConstants.GET_FUNNEL_LIST_R, ""));
        }
        dispatch(success(funnelConstants.GET_FUNNEL_LIST_S, {...data.data,body}));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data.data);
      },
      (error) => {
        dispatch(failure(funnelConstants.GET_FUNNEL_LIST_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}
function getProposalsTemplates(callBack = () => {}) {
  return (dispatch) => {
    FunnelService.getProposalsTemplates().then(
      (data) => {
        callBack(data.data);
      },
      (error) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}
function getContractTemplates(callBack = () => {}) {
  return (dispatch) => {
    FunnelService.getContractTemplates().then(
      (data) => {
        callBack(data.data);
      },
      (error) => {
        callBack();
      }
    );
  };
}

function getNewFunneltemplates(params,callBack = () => {}) {
  return (dispatch) => {
    FunnelService.getNewtemplates(params).then(
      (data) => {
        callBack(data.data);
      },
      (error) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}

function reorderFunnelSteps(body, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(funnelConstants.REORDER_FUNNEL_STEPS_R, {}));
    FunnelService.reorderFunnelSteps(body).then(
      (data) => {
        dispatch(success(funnelConstants.REORDER_FUNNEL_STEPS_S, data.data));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data.data);
      },
      (error) => {
        dispatch(failure(funnelConstants.REORDER_FUNNEL_STEPS_F, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}

function getPublicInvoiceList(body, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(funnelConstants.GET_PUBLIC_INVOICE_LIST_R, {}));
    FunnelService.getPublicInvoiceList(body).then(
      (data) => {
        dispatch(success(funnelConstants.GET_PUBLIC_INVOICE_LIST_S, data.data));
        callBack(data.data);
      },
      (error) => {
        dispatch(failure(funnelConstants.GET_PUBLIC_INVOICE_LIST_F, error));
        callBack();
      }
    );
  };
}

function resetList() {
  return (dispatch) => {
    dispatch(success(funnelConstants.RESET_PUBLIC_INVOICE_LIST));
  };
}

function createProposalTemplate(body, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    FunnelService.createProposalTemplates(body).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data.data);
      },
      (error) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}
function createContractTemplate(body, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    FunnelService.createContractTemplates(body).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(data.data);
      },
      (error) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}
function deleteFunnel(id, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    FunnelService.deleteFunnel(id).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb();
      },
      (error) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}
function getProposal(id, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    FunnelService.getProposal(id).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb(data.data);
      },
      (error) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}
function getContract(id, cb = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    FunnelService.getContract(id).then(
      (data) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
        cb(data.data);
      },
      (error) => {
        dispatch(request(loaderConstant.LOADING_FALSE));
      }
    );
  };
}
function updateFunnelStepTemplate(params, body, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(funnelConstants.UPDATE_FUNNEL_STEP_TEMPLATE_R, {}));
    FunnelService.updateFunnelStepTemplate(params, body).then(
      (data) => {
        dispatch(success(funnelConstants.UPDATE_FUNNEL_STEP_TEMPLATE_S, data));
        if (data.data) {
          dispatch(getFunnelTemplateSteps(data.data["funnel_template"]));
        }
        callBack();
      },
      (error) => {
        dispatch(failure(funnelConstants.UPDATE_FUNNEL_STEP_TEMPLATE_F, error));
      }
    );
  };
}
function updateFunnelTemplate(params, body, callBack = () => {}) {
  return (dispatch) => {
     FunnelService.updateFunnelTemplate(params, body).then(
      (data) => {
         callBack();
      },
      (error) => {
      }
    );
  };
}

function createFunnelSpace(data, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(funnelConstants.CREATE_FUNNEL_SPACE_REQUEST, { data }));
    FunnelService.createFunnelSpace(data).then(
      (response) => {
        dispatch(success(funnelConstants.CREATE_FUNNEL_SPACE_SUCCESS, { response }));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(response.data);
      },
      (error) => {
        dispatch(failure(funnelConstants.CREATE_FUNNEL_SPACE_FAILURE, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}

function getFunnelSpaceById(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(funnelConstants.GET_FUNNEL_SPACE_REQUEST, { params }));
    FunnelService.getFunnelSpaceById(params).then(
      (response) => {
        dispatch(success(funnelConstants.GET_FUNNEL_SPACE_SUCCESS, { response }));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(response.data);
      },
      (error) => {
        dispatch(failure(funnelConstants.GET_FUNNEL_SPACE_FAILURE, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}

function updateFunnelSpace(params, body, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(funnelConstants.UPDATE_FUNNEL_SPACE_REQUEST, { params, body }));
    FunnelService.updateFunnelSpace(params, body).then(
      (response) => {
        dispatch(success(funnelConstants.UPDATE_FUNNEL_SPACE_SUCCESS, { response }));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(response.data);
      },
      (error) => {
        dispatch(failure(funnelConstants.UPDATE_FUNNEL_SPACE_FAILURE, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}

function deleteFunnelSpace(params, callBack = () => {}) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(funnelConstants.DELETE_FUNNEL_SPACE_REQUEST, { params }));
    FunnelService.deleteFunnelSpace(params).then(
      (response) => {
        dispatch(success(funnelConstants.DELETE_FUNNEL_SPACE_SUCCESS, { response }));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack(response.data);
      },
      (error) => {
        dispatch(failure(funnelConstants.DELETE_FUNNEL_SPACE_FAILURE, error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    );
  };
}

function getSignedContracts(params,callBack = () => {}){
  return(dispatch) => {
    FunnelService.getSignedContracts(params).then(
      (data) => {
        if(!params?.preserveData){
          dispatch(request(funnelConstants.GET_SIGNED_CONTRACTS_R,""));
        }
        dispatch(success(funnelConstants.GET_SIGNED_CONTRACTS_S, {
          ...data.data,
          params,
        }));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack({...data.data})
      },
      (error) => {
        dispatch(failure(funnelConstants.GET_SIGNED_CONTRACTS_F,error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    )
  };



}

function setWorkflows(params) {
  return (dispatch)=>{
    dispatch(success(workflowConstants.GET_WORKFLOWS_S, params));
  }
}
function removeNotification(params) {
  return (dispatch)=>{
    dispatch(success(funnelConstants.REMOVE_FUNNEL_NOTIFICATIONS_S, params));
  }
}
function getFunnelNewNotification(params) {
  return (dispatch)=>{
    FunnelService.getFunnelNewNotification().then((data)=>{
      dispatch(success(funnelConstants.GET_FUNNEL_NOTIFICATIONS_S, 
        data.data
      ));
    })
  }
}

function getSignedContractsByUUID(params,callBack = ()=>{}){
  return(dispatch)=>{
    FunnelService.getSignedContractByUUID(params).then(
      (data)=>{
        if(!params){
          dispatch(request(funnelConstants.GET_SIGNED_CONTRACT_BY_UUID_R,""));
        }
        dispatch(success(funnelConstants.GET_SIGNED_CONTRACT_BY_UUID_S, {
          ...data.data,
          params,
        }));
        
      },
      (error) => {
        dispatch(failure(funnelConstants.GET_SIGNED_CONTRACT_BY_UUID_F,error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    )
  }
}


function getFunnelInstances(params,callBack = ()=>{}){
  return(dispatch)=>{
    FunnelService.getFunnelInstances(params).then(
      (data)=>{
        if(!params?.preserveData){
          dispatch(request(funnelConstants.GET_FUNNEL_USERS_R,""));
        }
        dispatch(success(funnelConstants.GET_FUNNEL_USERS_S, 
          {...data.data, params}))
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack({...data,data})
      },
      (error) => {
        dispatch(failure(funnelConstants.GET_FUNNEL_USERS_F,error));
        dispatch(request(loaderConstant.LOADING_FALSE));
        callBack();
      }
    )
  }
}

function resetFunnelUsers(){
  return (dispatch) => {
    dispatch(request(funnelConstants.GET_FUNNEL_USERS_R, {}));
  };
}

export const FunnelActions = {
  create,
  getFunnelTemplateSteps,
  getFunnelTemplateList,
  getProposalsTemplates,
  resetFunnelTemplates,
  getContractTemplates,
  reorderFunnelSteps,
  getPublicInvoiceList,
  resetList,
  updateFunnelStepTemplate,
  createFunnelSpace,
  getFunnelSpaceById,
  updateFunnelSpace,
  deleteFunnelSpace,
  createProposalTemplate,
  createContractTemplate,
  deleteFunnel,
  getProposal,
  getContract,
  resetFunnelTemplateSteps,
  getSignedContracts,
  updateFunnelTemplate,
  getNewFunneltemplates,
  setWorkflows,
  getSignedContractsByUUID,
  getFunnelInstances,
  resetFunnelData,
  resetFunnelUsers,
  getFunnelNewNotification,
  removeNotification
};
