import { baseAction } from "./baseAction";
import { paymentConstant, alertConstant, loaderConstant } from "../constants";
import { paymentService } from "../services";

const { request, success, failure } = baseAction;

// All login based actions
export const paymentAction = {
  getInvoicePayment,
  updateViewStatus,
  updatePaymentResponse,
  updatePaymentErrorLog,
  updatePaymentStatus
};

/** GET Invoice Payment Data */
function getInvoicePayment(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(loaderConstant.LOADING_TRUE));
    dispatch(request(paymentConstant.GET_INVOICE_PAYMENT_R, params));
    paymentService.getInvoicePayment(params).then(data => {
      dispatch(success(paymentConstant.GET_INVOICE_PAYMENT_S, data));
      dispatch(request(loaderConstant.LOADING_FALSE));
      callBack()
    }, error => {
      dispatch(failure(paymentConstant.GET_INVOICE_PAYMENT_F, error));
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

/** Update Invoice Payment Response*/
function updateViewStatus(params, callBack = () => { }) {
  return (dispatch) => {
    paymentService.updateViewStatus(params).then(data => {
      callBack()
    }, error => {
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

/** Update Invoice Payment Response*/
function updatePaymentResponse(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(paymentConstant.UPDATE_INVOICE_PAYMENT_R, params));
    dispatch(request(loaderConstant.LOADING_TRUE));
    paymentService.updatePaymentResponse(params).then(data => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(success(paymentConstant.UPDATE_INVOICE_PAYMENT_S, data));
      callBack()
    }, error => {
      dispatch(request(loaderConstant.LOADING_FALSE));
      dispatch(failure(paymentConstant.UPDATE_INVOICE_PAYMENT_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

/** Update Invoice Payment Error Logs in Database*/
function updatePaymentErrorLog(params, callBack = () => { }) {
  return (dispatch) => {
    dispatch(request(paymentConstant.UPDATE_INVOICE_PAYMENT_ERROR_R, params));
    paymentService.updatePaymentErrorLog(params).then(data => {
      dispatch(success(paymentConstant.UPDATE_INVOICE_PAYMENT_ERROR_S, data));
      callBack()
    }, error => {
      dispatch(failure(paymentConstant.UPDATE_INVOICE_PAYMENT_ERROR_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  };
}

/** Update Payment Status */

function updatePaymentStatus(params, callBack = () => {}){
  return (dispatch) => {
    dispatch(request(paymentConstant.UPDATE_INVOICE_PAYMENT_STATUS_R, params));
    paymentService.updatePaymentStatus(params).then(data => {
      dispatch(success(paymentConstant.UPDATE_INVOICE_PAYMENT_STATUS_S, data));
      callBack()
    }, error => {
      dispatch(failure(paymentConstant.UPDATE_INVOICE_PAYMENT_STATUS_F, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    }
    );
  }
}